import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ImageUploading from 'react-images-uploading';
import { Icon, Saving } from '@stateless';
import { urlPhoto } from '@utils/api';

const Evidencias = ({ visitaId, evidencias: propEvidencias, comentarios }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [evidencias, setEvidencias] = useState([]);
  const [showModalImg, setShowModalImg] = useState(false);
  const [imgSrcModal, setImgSrcModal] = useState('');

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setEvidencias(
      propEvidencias
        ? propEvidencias.map((x, i) => ({
            imagePreviewUrl: urlPhoto() + x,
            actual: true,
            comentarios: comentarios ? comentarios[i] : '',
          }))
        : []
    );
  };

  const onDescargarEvidencia = async (event) => {
    event.preventDefault();
    if (evidencias.length > 0) {
      for (let i = 0; i < evidencias.length; i++) {
        const evidencia = evidencias[i];
        let imageURL = evidencia.imagePreviewUrl;
        if (!evidencia.actual) imageURL = evidencia.file;
        const response = await fetch(imageURL, { mode: 'cors' });
        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = evidencia.comentarios || `Evidencia #${i + 1}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip id={`${visitaId}-evidencias`}>Evidencias</Tooltip>}>
        <Button className='btn btn-outline-success' onClick={open}>
          <Icon icon='picture' />
        </Button>
      </OverlayTrigger>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar evidencias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <ImageUploading multiple onChange={setEvidencias} value={evidencias} dataURLKey='imagePreviewUrl'>
                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                      <div className='upload__image-wrapper row'>
                        <div {...dragProps} className={`image-item col-sm-${evidencias.length > 0 ? 4 : 12}`}>
                          <div
                            style={{
                              minHeight: '150px',
                              position: 'relative',
                              cursor: 'pointer',
                              border: isDragging ? 'dashed 1px red' : 'dashed 1px black',
                            }}
                            onClick={onImageUpload}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                fontSize: '10px',
                                transform: 'translate(-50%, -50%)',
                                textAlign: 'center',
                              }}
                            >
                              <Icon icon='plus' style={{ fontSize: '4em' }} />
                              <br />
                              {isDragging ? 'Suelte la evidencia aquí porfavor' : 'Click aquí o suelte la evidencia aquí'}
                            </div>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item col-sm-4' style={{ position: 'relative' }}>
                            <div className='image-item__btn-wrapper' style={{ position: 'absolute', top: '10px', right: '30px' }}>
                              <a
                                href='#'
                                onClick={(e) => {
                                  e.preventDefault();
                                  onImageRemove(index);
                                }}
                              >
                                <Icon icon='trash' style={{ fontSize: '1.5em', color: 'red' }} />
                              </a>
                            </div>
                            <img
                              src={image.imagePreviewUrl}
                              alt=''
                              width='100%'
                              className='img-responsive'
                              onClick={() => {
                                setShowModalImg(true);
                                setImgSrcModal(image.imagePreviewUrl);
                              }}
                            />
                            <input
                              type='text'
                              disabled
                              className='form-control'
                              placeholder='Comentario'
                              value={image.comentarios}
                              onChange={(event) => {
                                setEvidencias(evidencias.map((e, i) => (index === i ? { ...e, comentarios: event.target.value } : e)));
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={onDescargarEvidencia} disabled={isLoading} className='btn btn-warning pull-right' type='button'>
            Descargar
          </Button>
          <Button onClick={close} disabled={isLoading} className='btn btn-default pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalImg} onHide={() => setShowModalImg(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Evidencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className='img-responsive' src={imgSrcModal} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModalImg(false)} className='btn btn-default pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Evidencias.propTypes = {
  id: PropTypes.string.isRequired,
  visitaId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  evidencias: PropTypes.array.isRequired,
  comentarios: PropTypes.array.isRequired,
};

export default Evidencias;
