import { DivFormGroup } from '@controls';
import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import List from './List';
import { Col, Row } from 'react-bootstrap';

const Mantenimientos = () => {
  const [catalogCanastas, setCatalogCanastas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');
  const [date2, setDate2] = useState('');

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (params = {}) => {
    const body = {
      search: params.search,
    };
    const { data, count } = await postRequest({ url: `mantenimientos/search`, body });
    setCatalogCanastas(data);
    setCount(count);
    setLoading(false);
    setSearch(params.search);
  };

  const onChange = async (event) => {
    const { name, value } = event.target;

    let body = {};

    if (name === 'date') {
      body = {
        date: value,
        date2,
      };
      setDate(value);
    } else if (name === 'date2') {
      body = {
        date,
        date2: value,
      };
      setDate2(value);
    }

    if (value !== null && value !== '') {
      const { data } = await postRequest({ url: `mantenimientos-date`, body });
      setCatalogCanastas(data);
      setCount(0);
    } else {
      loadData();
    }
  };

  const renderList = () => {
    return <List {...{ catalogCanastas, count, search, date, date2 }} loadData={loadData} />;
  };

  const moduleList = !loading ? renderList() : <Loading />;

  return (
    <Module title='Mantenimientos'>
      <Search onSearch={loadData} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <Col md='4'>
          <DivFormGroup>
            <input
              className='form-control'
              name='date'
              id='date'
              type='date'
              defaultValue={date !== '' && date !== null ? moment(date).format('YYYY-MM-DD') : null}
              onChange={onChange}
            />
          </DivFormGroup>
        </Col>
        <Col md='4'>
          <DivFormGroup>
            <input
              className='form-control'
              name='date2'
              id='date2'
              type='date'
              defaultValue={date2 !== '' && date2 !== null ? moment(date2).format('YYYY-MM-DD') : null}
              onChange={onChange}
            />
          </DivFormGroup>
        </Col>
        <Col md='4'>
          <Link to='/mantenimientos/captura' className='btn btn-success pull-right'>
            <Icon icon='plus' /> Captura
          </Link>
        </Col>
      </Search>

      <Row>
        <Col md='12'>{moduleList}</Col>
      </Row>
    </Module>
  );
};

Mantenimientos.propTypes = {};

export default Mantenimientos;
