import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ id, codigo: initialCodigo, nombre: initialNombre, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [codigo, setCodigo] = useState(initialCodigo);
  const [nombre, setNombre] = useState(initialNombre);
  const [isLoading] = useState(false);

  const close = () => setShowModal(false);
  const open = () => {
    setShowModal(true);
    setCodigo(initialCodigo);
    setNombre(initialNombre);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      codigo: codigo.trim(),
      nombre: nombre.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `dealers/${id}`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='4'>
            <FormInput title='Código' required name='codigo' onChange={(e) => setCodigo(e.target.value)} value={codigo} />
          </Col>
          <Col sm='8'>
            <FormInput title='Nombre' required name='nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Edit;
