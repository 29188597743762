import { DivFormGroup, FilterSelectNuevo, Label, RedirectTooltip } from '@controls';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import RemoverClienteAgente from './RemoverClienteAgente';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const AgentesClientes = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [clienteObj, setClienteObj] = useState(null);
  const [clienteName, setClienteName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { agenteId } = params;
    const { data } = await getRequest({ url: `agentes/${agenteId}` });
    setData(data);
    setLoading(false);
  };

  const onChangeClienteInput = (e) => {
    setClienteName(e);
  };

  const onChangeCliente = async (itemSelect) => {
    if (itemSelect !== null) {
      setClienteObj(itemSelect);
      const body = { clienteId: itemSelect.value._id };
      await postRequest({ url: `agente-cliente/${params.agenteId}`, body });
      loadData();
    }
  };

  const returnRowsClientes = () => {
    const { agenteId } = params;
    return data.listCustomers.map((c, i) => (
      <tr key={i}>
        <td>{renderNombreCliente(c)}</td>
        <td>
          <RemoverClienteAgente id={i} agenteId={agenteId} clienteId={c._id} nombre={renderNombreCliente(c)} loadData={loadData} />
          <RedirectTooltip
            id={1}
            url={`/seguimientos/seguimiento/${c._id}`}
            labelTooltip='Seguimiento'
            icon='screenshot'
            className='btn btn-outline-primary'
          />
        </td>
      </tr>
    ));
  };

  const returnData = () => (
    <>
      <Row>
        <Col>
          <DivFormGroup>
            <Label name='clienteId' title='Busqueda de Clientes' />
            <FilterSelectNuevo
              async={true}
              disabled={false}
              value={clienteObj}
              inputValue={clienteName}
              onInputChange={onChangeClienteInput}
              onChangeOption={onChangeCliente}
              url={`clientes-agentes-autocomplete`}
              fields={['_id', 'name']}
            />
          </DivFormGroup>
        </Col>
      </Row>
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th width='80%'>Clientes</th>
            <th width='20%'></th>
          </tr>
        </thead>
        <CbTableBody colSpan={2}>{returnRowsClientes()}</CbTableBody>
      </CbPanelTableResponsive>
    </>
  );

  return (
    <Module onClickBack={window.history.back} parent='Agentes' title='Clientes asignados'>
      {loading && data === null ? <Loading /> : returnData()}
    </Module>
  );
};

AgentesClientes.propTypes = {};

export default AgentesClientes;
