import { RowItem } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Domicilio from '@components/customers/Domicilio';

const PersonaFisica = ({ customer }) => {
  const { name, paterno, materno, regimen, email, mobile, phone, razon_social, nombre_comercial, calle1, calle2, referencias } = customer;

  return (
    <Row>
      <Col sm='6'>
        <RowItem label='Nombre'>{regimen === 'F' ? `${name} ${paterno} ${materno}` : razon_social}</RowItem>
        <RowItem label='Nombre comercial'>{nombre_comercial}</RowItem>
        <RowItem label='Celular'>{mobile}</RowItem>
        <RowItem label='Teléfono'>{phone}</RowItem>
        <RowItem label='Correo electrónico'>{email}</RowItem>
      </Col>
      <Col sm='6'>
        <Domicilio customer={customer} />

        <RowItem label='Entre calles'>
          {calle1} {calle2}
        </RowItem>
        <RowItem label='Referencias'>{referencias}</RowItem>
      </Col>
    </Row>
  );
};

PersonaFisica.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default PersonaFisica;
