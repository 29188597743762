import PropTypes from 'prop-types';
import React from 'react';

const Concepto = ({ label, valor }) => {
  return (
    <div>
      <span>
        <strong>{label}</strong> {valor}
      </span>
    </div>
  );
};

Concepto.propTypes = {
  label: PropTypes.string.isRequired,
  valor: PropTypes.any,
};

export default Concepto;
