import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteRecipes = ({ _id, recipe, name, refresh }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`recipes/${_id}`}
      description={`¿Confirma que desea eliminar el apartado <strong><u> ${recipe}</u> </strong> de la configuración <strong>${name}</strong>?`}
      loadData={refresh}
    />
  );
};

DeleteRecipes.propTypes = {
  id: PropTypes.number.isRequired,
  recipe: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default DeleteRecipes;
