import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Module, Saving } from '@stateless';
import { FormInput } from '@controls';
import Swal from 'sweetalert2';
import { getRequest, postRequest } from '@utils/api';
import {
  castServicio,
  getTipoCotizacion,
  measuresServicios,
  getHorasCotizacion,
  HORA,
  mapDataDetalles,
  recalcularPrecioDetalle,
  recalcularPrecioServicio,
} from '@utils/cotizaciones';
import { CbTableResponsive } from '@controls';
import CbTableBody from '@cbcomponents/CbTableBody';
import BuscadorDetalles from './BuscadorDetalles';
import BuscadorKits from './BuscadorKits';
import FormEncabezado from './FormEncabezado';
import ModalImprimir from './ModalImprimir';
import RowDetalle from './RowDetalle';
import Totales from './Totales';
import getState from '@utils/state';
import ModalImportarExcel from './ModalImportarExcel';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import TableHeaders from './components/TableHeaders';
import { useStoreSucursales, useStoreTiposLevantamientos, useStoreMonedas, useStoreMedidas, useStoreTipoCambio } from '@stores/catalogs.store';
import useAuth from '@hooks/useAuth';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import { validateSave } from '@validations/cotizaciones';

const Index = () => {
  const { addNotification: notification } = useStoreNotification();
  const params = useParams();
  const navigate = useNavigate();
  const [idCotizacion, setIdCotizacion] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [showModalSubirExcel, setShowModalSubirExcel] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [estatusLevantamientos] = useState([{ value: 'INICIADA', label: 'INICIADA' }]);
  const [showModalKitsComerciales, setShowModalKitsComerciales] = useState(true);
  const { agente, moneda } = useAuth();

  const [state, setState] = useState({
    fecha: moment().format('YYYY-MM-DD'),
    fecha_vencimiento: moment().add(30, 'days').format('YYYY-MM-DD'),
    monedaId: moneda,
    agenteId: null,
    maximo_descuento: 0,
    detalles: [],
    observaciones: '',
    estatus: { value: 'INICIADA', label: 'INICIADA' },
    sucursalId: null,
    cliente: null,
    domicilios: [],
    domicilioId: null,
    anticipo: 0,
    tipoLevantamientoId: null,
    tipoCambio: 1,
    tipoCliente: params?.tipoCliente?.toUpperCase(),
  });
  console.log('state', state.monedaId);

  const getStoreSucursales = useStoreSucursales();
  const getStoreTiposLevantamientos = useStoreTiposLevantamientos();
  const getStoreMonedas = useStoreMonedas();
  const getStoreMedidas = useStoreMedidas();
  const getStoreTipoCambio = useStoreTipoCambio();
  useEffect(() => {
    setState((prevState) => ({ ...prevState, tipoCambio: getStoreTipoCambio.tipoCambio }));
  }, [getStoreTipoCambio.tipoCambio]);

  useEffect(() => {
    getStoreSucursales.execute();
    getStoreTiposLevantamientos.execute();
    getStoreMonedas.execute();
    getStoreMedidas.execute();
    getStoreTipoCambio.execute();
    fetchData();
  }, []);

  const { customerId } = params;
  const fetchData = useCallback(async () => {
    if (customerId) {
      const url = state.tipoCliente == 'CLIENTE' ? 'customer' : 'prospectos';
      const { data } = await getRequest({ url: `${url}/${customerId}/detail` });
      const domicilios = state.tipoCliente == 'CLIENTE' ? data.listDomicilios : [{ ...data.domicilio, _id: 1 }];
      const domicilioId = domicilios.length === 1 ? domicilios[0] : null;
      setState((prevState) => ({
        ...prevState,
        cliente: { ...data },
        domicilioId,
        domicilios,
      }));
    }
    inicializar();
  }, [customerId]);

  const precios = state.detalles.map((detalle) => detalle?.precio).join('-');
  const cantidades = state.detalles.map((detalle) => detalle?.cantidad).join('-');

  useEffect(() => {
    recalcularTipoCambio();
  }, [state.monedaId, state.tipoCambio]);

  useEffect(() => {
    recalcularTipoCambio();
  }, [precios, cantidades]);

  useEffect(() => {
    recalcularServicios();
  }, [state.sucursalId]);

  useEffect(() => {
    actualizarNumeroLevantamiento();
  }, [state.tipoLevantamientoId?._id]);

  const inicializar = useCallback(async () => {
    if (!agente) {
      notification({
        title: 'Información incompleta',
        message: 'Es necesario tener configurado el usuario como agente',
        type: 'error',
      });
      setTimeout(() => {
        navigate('/');
      }, 1000);
      return;
    }
    const tipoLevantamientoId = getStoreTiposLevantamientos.data.find((x) => x.default);
    setState((prevState) => ({
      ...prevState,
      tipoLevantamientoId,
      agenteId: agente,
      maximo_descuento: agente.maximo_descuento,
      sucursalId: agente.sucursalId,
    }));
    setIsLoadingForm(false);
  }, []);

  const actualizarNumeroLevantamiento = useCallback(async () => {
    if (!state.tipoLevantamientoId?._id) return;
    const { data } = await getRequest({
      url: `cotizaciones/siguienteNumero`,
      params: { tipoLevantamientoId: getTipoCotizacion(state.tipoLevantamientoId) },
    });
    setState((prevState) => ({
      ...prevState,
      numeroOrden: data.numero_levantamiento,
    }));
  }, [state.tipoLevantamientoId]);

  const onChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      ...getState(event.target),
    }));
  };

  const onSelectTipoLevantamiento = (value) => {
    setState((prevState) => ({
      ...prevState,
      tipoLevantamientoId: value,
    }));
  };

  const subirExcel = () => {
    setState((prevState) => ({
      ...prevState,
      showModalSubirExcel: true,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      numeroOrden,
      fecha,
      fecha_vencimiento,
      estatus,
      sucursalId,
      monedaId,
      tipoCambio,
      anticipo,
      tipo_cliente,
      domicilioId,
      cliente,
      observaciones,
      detalles,
      agenteId,
      tipoLevantamientoId,
    } = state;
    const data = {
      numero_levantamiento: numeroOrden,
      tipoLevantamientoId: tipoLevantamientoId?._id,
      tipo_cliente: tipo_cliente?.value,
      fecha: moment(fecha, 'YYYY-MM-DD').utc().format(),
      fecha_vencimiento: moment(fecha_vencimiento, 'YYYY-MM-DD').utc().format(),
      estatus_levantamiento: estatus?.value,
      sucursalId: sucursalId?._id,
      monedaId: monedaId?.enumerador,
      tipoCambio,
      anticipo,
      observaciones,
      clienteId: cliente?._id,
      agenteId: agenteId?._id,
      domicilioId: domicilioId ? domicilioId._id : 1,
      detalles: mapDataDetalles(detalles),
    };
    let { horas_cotizadas, horas_sugeridas } = getHorasCotizacion(detalles);
    let sobrepasa_descuento = detalles.some((detalle) => detalle.descuento > agenteId.maximo_descuento);
    if (sobrepasa_descuento) {
      notification({
        title: 'Validaciones',
        message: 'El descuento de un detalle sobrepasa el máximo permitido',
        type: 'error',
      });
      return;
    }
    if (horas_sugeridas > horas_cotizadas) {
      const titulo = `¡Se esta cotizando menos horas de las sugeridas!`;
      const message = `Las horas sugeridas para los equipos cotizados son <b>${Math.ceil(
        horas_sugeridas
      )}</b>. ¿Quiere cotizar debajo de las horas sugeridas?`;
      Swal.fire({
        html: `<div style="font-size: 1.5em!important;">${titulo}</div><span>${message}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#25b003',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, confirmo',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          onSaveCotizacion(data);
        }
      });
      return;
    }
    onSaveCotizacion(data);
  };

  const onSaveCotizacion = async (data) => {
    const { error } = validateSave(data);
    if (error) {
      return notification({
        title: 'Validaciones',
        message: error.details[0].message,
        type: 'error',
      });
    }
    setIsLoadingForm(true);
    setIsLoading(true);
    postRequest({ url: `cotizaciones`, body: data }).then(({ data: cotizacion }) => {
      if (cotizacion) {
        setIdCotizacion(cotizacion._id);
        setShowModalDownload(true);
      }
      setIsLoadingForm(false);
      setIsLoading(false);
    });
  };

  const onChangeDetalle = (index, detalle) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((x, i) => (i === index ? detalle : x))],
    }));
  };

  const recalcularTipoCambio = () => {
    const { monedaId, tipoCambio } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((detalle) => recalcularPrecioDetalle(detalle, monedaId, tipoCambio))],
    }));
  };

  const recalcularServicios = () => {
    const { sucursalId } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((detalle) => recalcularPrecioServicio(detalle, sucursalId))],
    }));
  };

  const onRemoveDetalle = (index) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.filter((x, i) => i !== index)],
    }));
  };

  const closeSubirExcel = () => {
    setShowModalSubirExcel(false);
  };

  const onImportarExcel = (detallesNuevos) => {
    const { detalles } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...detalles, ...detallesNuevos],
    }));
    setShowModalSubirExcel(false);
  };

  const mismoServicio = (detalle, producto) => {
    return detalle.relacionadoId === producto.relacionadoId && detalle.measureId._id === producto.measureId._id;
  };

  const onSelectServicio = ({ servicio, listWorkForces, relacionadoId }) => {
    const { monedaId, tipoCambio, detalles, sucursalId } = state;
    const cantidad = listWorkForces.map((x) => x.cantidad).reduce((a, b) => a + b, 0);
    let listWorkForcesSettedNueva = detalles
      .map((d) => (d.relacionadoId == relacionadoId ? listWorkForces : d.listWorkForcesSetted))
      .filter((d) => d)
      .reduce((a, b) => a.concat(b), []);
    const nuevoValor = listWorkForcesSettedNueva.map((x) => Math.ceil(x.value * x.cantidad)).reduce((a, b) => a + b, 0);
    const measures = measuresServicios(getStoreMedidas.data);
    const producto = castServicio(servicio, monedaId, tipoCambio, measures, sucursalId);
    const hora = measures.find((y) => y.measure.toUpperCase() === HORA);
    if (hora) {
      producto.measureId = hora;
      producto.precio = (producto.listCost.find((y) => y.measureId === hora._id) || { cost: 0 }).cost;
      producto.precio_original = producto.precio;
    }
    let detallesNuevos = [
      ...detalles.map((d) => {
        if (d.relacionadoId === relacionadoId) {
          return {
            ...d,
            cantidad: cantidad,
            listWorkForcesSetted: listWorkForces,
            showModalWorkforce: false,
            tiene_servicio: true,
          };
        } else if (mismoServicio(d, producto)) {
          return {
            ...d,
            showModalWorkforce: false,
            cantidad: Number(nuevoValor),
          };
        }
        return d;
      }),
    ];
    const estaRegistrado = !!detallesNuevos.find((d) => mismoServicio(d, producto));
    if (!estaRegistrado) {
      detallesNuevos.push({
        ...producto,
        tipo_partida: { value: 'SERVICIO', label: 'SERVICIO' },
        cantidad: nuevoValor,
        descuento: 0,
        iva: producto.iva || 16,
        tiene_sustitutos: producto.listAlternos?.length > 0,
        tiene_otro_proveedor: producto.listCostosProveedores?.length > 1,
      });
    }
    setState((prevState) => ({
      ...prevState,
      detalles: [...detallesNuevos],
    }));
  };

  const closeDownload = () => {
    setShowModalDownload(false);
    setTimeout(() => navigate('/cotizaciones'), 1000);
  };

  const { detalles, monedaId, tipoCambio, sucursalId, observaciones } = state;
  return (
    <>
      <BuscadorKits
        onClose={() => setShowModalKitsComerciales(false)}
        sucursalId={sucursalId}
        monedaId={monedaId}
        tipoCambio={tipoCambio}
        showModalKitsComerciales={showModalKitsComerciales}
        onAgregarDetalle={(detalle) => {
          setState((prevState) => ({ ...prevState, detalles: [...detalles, detalle] }));
        }}
        onLoadingTable={(isLoadingTable) => setIsLoadingTable(isLoadingTable)}
      />
      <ModalImprimir showModal={showModalDownload} onClose={closeDownload} idCotizacion={idCotizacion} />
      <ModalImportarExcel showModal={showModalSubirExcel} onClose={closeSubirExcel} tipoCambio={state.tipoCambio} onSave={onImportarExcel} />
      <Module onClickBack={window.history.back} parent='Cotizaciones' title='Nueva cotización' loading={isLoadingForm}>
        <FormEncabezado
          numeroOrden={state.numeroOrden}
          fecha={state.fecha}
          fecha_vencimiento={state.fecha_vencimiento}
          estatus={state.estatus}
          sucursalId={sucursalId}
          monedaId={state.monedaId}
          cliente={state.cliente}
          tipoCambio={state.tipoCambio}
          estatus_levantamientos={estatusLevantamientos}
          domicilioId={state.domicilioId}
          domicilios={state.domicilios}
          agenteId={state.agenteId}
          anticipo={state.anticipo}
          tipoLevantamientoId={state.tipoLevantamientoId}
          onSelectTipoLevantamiento={onSelectTipoLevantamiento}
          setState={(obj) => setState((prevState) => ({ ...prevState, ...obj }))}
        />
        <br />
        <Card>
          <Card.Body>
            <Container fluid>
              <BuscadorDetalles
                {...state}
                onAgregarDetalle={(detalle) => {
                  setState((prevState) => ({ ...prevState, detalles: [...detalles, detalle] }));
                }}
                onLoadingTable={(isLoadingTable) => setIsLoadingTable(isLoadingTable)}
              />
            </Container>
          </Card.Body>
          {state.detalles && (
            <CbTableResponsive>
              <TableHeaders />
              <CbTableBody loading={isLoadingTable} colSpan={8}>
                {detalles.map((detalle, i) => (
                  <RowDetalle
                    key={i}
                    monedaId={monedaId}
                    tipoCambio={tipoCambio}
                    sucursalId={sucursalId}
                    detalle={detalle}
                    onChangeDetalle={(detalle) => onChangeDetalle(i, detalle)}
                    onRemoveDetalle={() => onRemoveDetalle(i)}
                    onSelectServicio={onSelectServicio}
                  />
                ))}
                <Totales colSpan={8} moneda={state.monedaId} detalles={state.detalles} anticipo={state.anticipo} />
              </CbTableBody>
            </CbTableResponsive>
          )}
          <Card.Footer />
        </Card>
        <br />
        <Card>
          <Card.Body>
            <Container fluid>
              <Row>
                <Col>
                  <FormInput type='textarea' title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <Container fluid>
              <Row>
                <Col>
                  <Saving saving={isLoading} />
                  <Button onClick={handleRegister} className='btn btn-primary pull-right' disabled={isLoading}>
                    <Icon icon='floppy-disk' /> Guardar
                  </Button>
                  <Button onClick={subirExcel} className='btn btn-warning pull-right mr-5' disabled={isLoading}>
                    <Icon icon='open-file' /> Importar Excel
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <br />
      </Module>
    </>
  );
};

Index.propTypes = {
  tipoCambio: PropTypes.number,
};

export default Index;
