export const MESSAGES = {
  'any.required': 'El campo {#label} es obligatorio.',
  'any.only': 'El campo {#label} debe tener uno de los siguientes valores: {#valids}.',
  'any.invalid': 'El campo {#label} contiene un valor no permitido.',
  'any.default': 'Ocurrió un error al establecer el valor por defecto en {#label}.',
  'string.base': 'El campo {#label} debe ser una cadena de texto.',
  'string.empty': 'El campo {#label} no puede estar vacío.',
  'string.min': 'El campo {#label} debe tener al menos {#limit} caracteres.',
  'string.max': 'El campo {#label} no puede tener más de {#limit} caracteres.',
  'string.email': 'El campo {#label} debe ser un correo electrónico válido.',
  'string.uri': 'El campo {#label} debe ser una URL válida.',
  'string.regex.base': 'El campo {#label} no tiene un formato válido.',
  'string.alphanum': 'El campo {#label} solo debe contener caracteres alfanuméricos.',
  'string.length': 'El campo {#label} debe tener exactamente {#limit} caracteres.',
  'string.pattern.base': 'El campo {#label} no coincide con el patrón requerido.',
  'number.base': 'El campo {#label} debe ser un número.',
  'number.min': 'El campo {#label} debe ser al menos {#limit}.',
  'number.max': 'El campo {#label} debe ser como máximo {#limit}.',
  'number.integer': 'El campo {#label} debe ser un número entero.',
  'number.positive': 'El campo {#label} debe ser un número positivo.',
  'number.negative': 'El campo {#label} debe ser un número negativo.',
  'number.precision': 'El campo {#label} no puede tener más de {#limit} decimales.',
  'date.base': 'El campo {#label} debe ser una fecha válida.',
  'date.min': 'El campo {#label} debe ser una fecha posterior a {#limit}.',
  'date.max': 'El campo {#label} debe ser una fecha anterior a {#limit}.',
  'array.base': 'El campo {#label} debe ser un arreglo.',
  'array.min': 'El campo {#label} debe tener al menos {#limit} elementos.',
  'array.max': 'El campo {#label} no puede tener más de {#limit} elementos.',
  'array.length': 'El campo {#label} debe contener exactamente {#limit} elementos.',
  'array.unique': 'El campo {#label} contiene valores duplicados.',
  'boolean.base': 'El campo {#label} debe ser verdadero o falso.',
  'object.base': 'El campo {#label} debe ser un objeto.',
  'object.unknown': 'El campo {#label} contiene claves no permitidas.',
};

export const isDecimalValid = (value, countDecimals) => {
  const lastChar = value.substring(value.length - 1);

  if (lastChar === '.') {
    value = value + '0';
  }

  var findDecimals = new RegExp('^\\s*-?(\\d+(\\.\\d{1,' + countDecimals + '})?|\\.\\d{1,' + countDecimals + '})\\s*$', 'g');

  if (countDecimals === -1) {
    findDecimals = new RegExp('^\\s*-?(\\d+(\\.\\d{1,25})?|\\.\\d{1,25})\\s*$', 'g');
  }

  return findDecimals.test(value);
};

export const getValidation = (validacion, condiciones, campos) => {
  return {
    message: validacion.message(...campos),
    validate: validacion.validate.bind(null, ...condiciones),
  };
};
