import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';
import { DOLAR, EURO, PESO } from '@config/constants';
import { CREDITO, DEBITO } from '../../../config/constants';
import SelectCuentaBancaria from '../../../components/forms/SelectCuentaBancaria';


const New = ({ loadData }) => {
    const {addNotification: notification} = useStoreNotification();
    const [nombre, setNombre] = useState('');
    const [tipo_tarjeta, setTipoTarjeta] = useState('');
    const [cuenta_bancaria_id, setCuentaBancaria]= useState('');
    const [numero_tarjeta, setNumeroTarjeta] = useState('');
    const [numero_seguridad, setNumeroSeguridad] = useState('');
    const [anio_vencimiento, setAnioVencimiento] = useState('');
    const [mes_vencimiento, setMesVencimiento] = useState('');
    const [tarjetahabiente, setTarjetaHabiente] = useState('');
    const [moneda, setMoneda] = useState('');
    const [segmento, setSegmento] = useState('');
    const [comentarios, setComentarios] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const close = () =>{
        setShowModal(false);
        setNombre('');
    };

    const open = () => {
        setShowModal(true);
        setIsLoading(false);
    }

    const onChange = (event) =>{
        const {name, value} = event.target;
        if (name === 'nombre') {
            setNombre(value);
        }
        if (name === 'tipo_tarjeta') {
            setTipoTarjeta(value);
        }
        if (name === 'numero_tarjeta') {
            setNumeroTarjeta(value);
        }
        if (name === 'numero_seguridad') {
            setNumeroSeguridad(value);
        }
        if (name === 'anio_vencimiento') {
            setAnioVencimiento(value);
        }
        if (name === 'mes_vencimiento') {
            setMesVencimiento(value);
        }
        if (name === 'tarjetahabiente') {
            setTarjetaHabiente(value);
        }
        if (name === 'moneda') {
            setMoneda(value);
        }
        if (name === 'segmento') {
            setSegmento(value);
        }
        if (name === 'comentarios') {
            setComentarios(value);
        }
    };
    const onChangeCuentaBancaria = (itemSelect) =>{
      if (itemSelect) {
        setCuentaBancaria(itemSelect);   
      } else {
        setCuentaBancaria(null);
      }
    }
    const handleRegister = async(event) =>{
        event.preventDefault();
        const data = {nombre, tipo_tarjeta, numero_tarjeta,cuenta_bancaria_id,numero_seguridad, anio_vencimiento, mes_vencimiento, tarjetahabiente, moneda, segmento,comentarios};
        if(validations(data)){
            await postRequest({url:'tarjetas', body:data});
            loadData();
            close();
        }
    }
    const validations = (params) => {
        let validate = {
          success: true,
          message: '',
        };
    
        if (isEmpty(params.nombre + '')) {
          validate.success = false;
          validate.message = 'Nombre es requerido.';
        } else if (isEmpty(params.tipo_tarjeta + '')) {
          validate.success = false;
          validate.message = 'Tipo tarjeta es requerido.';
        } else if (isEmpty(params.numero_tarjeta + '')) {
          validate.success = false;
          validate.message = 'Número de Tarjeta es requerido.';
        } else if (isEmpty(params.moneda + '')) {
          validate.success = false;
          validate.message = 'Moneda es requerido.';
        }
        if (!validate.success) {
          notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
        }
        return validate.success;
    };
    return(
        <>  
             <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='nombre' title='Nombre' required>
              <input type='text' id='nombre' name='nombre' className='form-control' onChange={onChange} value={nombre} placeholder='Nombre' />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='tipo_tarjeta' title='Tipo Tarjeta' required>
              <select name='tipo_tarjeta' id='tipo_tarjeta' className='form-control' onChange={(e) => setTipoTarjeta(e.target.value)} value={tipo_tarjeta}>
                    <option value=''>Seleccionar...</option>
                    <option value={DEBITO}>Debito</option>
                    <option value={CREDITO}>Credito</option>
                </select>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='numero_tarjeta' title=' Número Tarjeta' required>
              <input type='text' id='numero_tarjeta' name='numero_tarjeta' className='form-control' onChange={onChange} value={numero_tarjeta} placeholder='Número Tarjeta' />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='numero_seguridad' title='Número Seguridad' required>
              <input type='text' id='numero_seguridad' name='numero_seguridad' className='form-control' onChange={onChange} value={numero_seguridad} placeholder='Número Seguridad' />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='anio_vencimiento' title='Año Vencimiento' required>
              <input type='text' id='anio_vencimiento' name='anio_vecimiento' className='form-control' onChange={onChange} value={anio_vencimiento} placeholder='Año vencimiento' />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='mes_vencimiento' title='Mes Vencimiento' required>
              <input type='text' id='mes_vencimiento' name='mes_vencimiento' className='form-control' onChange={onChange} value={mes_vencimiento} placeholder='Mes vencimiento' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
            <Col sm='6'>
                <DivFormGroup name='cuenta_bancaria_id' title='Cuenta Bancaria' required>
                <SelectCuentaBancaria name='cuenta_bancaria_id' title='Cuenta Bancaria' required onChange={onChangeCuentaBancaria} value={cuenta_bancaria_id}/>
                </DivFormGroup>
            </Col>
            <Col sm='6'>
                <DivFormGroup name='tarjetahabiente' title='Tarjeta Habiente' required>
                 <input type='text' id='tarjetahabiente' name='tarjetahabiente' className='form-control' onChange={onChange} value={tarjetahabiente} placeholder='Tarjeta Habiente' />
                </DivFormGroup>
            </Col>
          </Row>
          <Row>
          <Col sm='6'>
                <DivFormGroup name='moneda' title='Moneda' required>
                <select name='moneda' id='moneda' className='form-control' onChange={(e) => setMoneda(e.target.value)} value={moneda}>
                    <option value=''>Seleccionar...</option>
                    <option value={PESO}>Pesos</option>
                    <option value={DOLAR}>Dolares</option>
                    <option value={EURO}>Euros</option>
                </select>
                </DivFormGroup>
              </Col>
          <Col sm='6'>
            <DivFormGroup name='segmento' title='Segmento' required>
              <input type='text' id='segmento' name='segmento' className='form-control' onChange={onChange} value={segmento} placeholder='Segmento' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
        <Col sm='12'>
            <DivFormGroup name='comentarios' title='Comentarios' required>
              <input type='text-area' id='comentarios' name='comentarios' className='form-control' onChange={onChange} value={comentarios} placeholder='Comentarios' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
    )

};
New.propTypes={
    nombre: PropTypes.string.isRequired,
    tipo_tarjeta: PropTypes.string.isRequired,
    numero_tarjeta:PropTypes.string.isRequired,
    cuenta_bancaria_id:PropTypes.string.isRequired,
    numero_seguridad: PropTypes.string.isRequired,
    anio_vencimiento:PropTypes.string.isRequired,
    mes_vencimiento: PropTypes.string.isRequired,
    tarjetahabiente: PropTypes.string.isRequired,
    moneda: PropTypes.string.isRequired,
    comentarios:PropTypes.string.isRequired,
    loadData: PropTypes.func.isRequired
}
export default New;