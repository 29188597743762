import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';
import RowDomicilio from './RowDomicilio';

const TableDomicilios = ({ customer }) => {
  const { listDomicilios } = customer;
  const renderTableDomicilios = () => {
    const rows = listDomicilios.map((d, i) => <RowDomicilio key={i} domicilio={d} />);
    return (
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th width='10%'>Monitoreo</th>
            <th width='10%'>Tipo</th>
            <th width='20%'>Nombre</th>
            <th width='30%'>Domicilio</th>
            <th width='30%'>Referencias</th>
          </tr>
        </thead>
        <tbody>{listDomicilios.length > 0 ? rows : <RowsNotFound colSpan={5} message='No se han agregado domicilios' />}</tbody>
      </table>
    );
  };

  if (!customer || (listDomicilios && listDomicilios.length === 0)) return null;
  const tableDomicilios = renderTableDomicilios();

  return (
    <div className='col-sm-12'>
      <div className='panel panel-primary'>
        <div className='panel-heading'>
          <h4 className='panel-title'>
            <Icon icon='home' /> Domicilios
          </h4>
        </div>
        {tableDomicilios}
      </div>
    </div>
  );
};

TableDomicilios.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default TableDomicilios;
