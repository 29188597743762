import { FormGruopIcon } from '@controls';
import Search from '@controls/Search';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import Select from 'react-select';
import { Module } from '@stateless';
import { postRequest } from '@utils/api';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import { Button, Col, Row } from 'react-bootstrap';

const ListCustomer = () => {
  const [search, setSearch] = useState('');
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState(30);
  const [agrupador, setAgrupador] = useState(null);
  const agrupadores = [
    { value: 'numero_cliente', label: 'Numero de cliente' },
    { value: 'rfc', label: 'RFC' },
    { value: 'razon_social', label: 'Razon social' },
    { value: 'nombre_comercial', label: 'Nombre comercial' },
    { value: 'rfc_razon_social', label: 'Rfc y razon social' },
  ];

  useEffect(() => {
    handleSearch();
  }, [search, activePage, agrupador, pagination]);

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      agrupador: agrupador?.value,
    };
    const { data, count } = await postRequest({ url: `clientes-agrupados`, params: { page, limit: pagination }, body });
    setCustomers(
      data.map((x) => {
        let tienenNombreComercial = x.lista.filter((id) => id.nombre_comercial).length > 0;
        let coincide = x.lista.find(
          (id) => id.rfc && id.razon_social && ((tienenNombreComercial && id.nombre_comercial) || !tienenNombreComercial) && id.status === 'ACTIVO'
        );
        return {
          ...x,
          lista: x.lista.map((y) => {
            return { ...y, checked: coincide && coincide._id === y._id };
          }),
        };
      })
    );
    setCount(count);
    setLoading(false);
  }, [search, activePage, agrupador]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  const onChecked = (indexi, indexj) => {
    setCustomers(
      customers.map((x, i) => {
        if (i === indexi) {
          return {
            ...x,
            lista: x.lista.map((y, j) => {
              y.checked = j === indexj;
              return y;
            }),
          };
        }
        return x;
      })
    );
  };

  const handleUnificar = async (e) => {
    e && e.preventDefault();
    const agrupados = customers.map((x) => {
      const cAltas = x.lista.filter((y) => y.checked);
      const alta = cAltas.length > 0 ? cAltas.map((y) => y._id)[0] : undefined;
      return {
        numero_cliente: x._id.numero_cliente,
        alta,
        bajas: x.lista.filter((y) => !y.checked).map((y) => y._id),
      };
    });
    await postRequest({ url: `clientes-agrupados/unificar`, body: { agrupados } });
    handleSearch();
  };

  const renderRows = () => {
    return customers.map((row, i) => {
      const {
        _id: { numero_cliente },
        lista,
      } = row;
      return (
        <Fragment key={numero_cliente}>
          <tr key={i}>
            <td>{numero_cliente}</td>
            <td className='with-actions'>
              <span className='actions'></span>
            </td>
          </tr>
          <tr>
            <td style={{ padding: '0px', verticalAlign: 'middle' }} colSpan='2'>
              <table className='table table-striped table-condenced table-hover' style={{ margin: '0px' }}>
                <tbody>
                  <tr>
                    <th width='2%'>&nbsp;</th>
                    <th width='12%'>Id</th>
                    <th>#</th>
                    <th>RFC</th>
                    <th>Nombre</th>
                    <th>Nombre comercial</th>
                    <th>Domicilios</th>
                    <th>Contactos</th>
                    <th>Documentos</th>
                    <th>Estatus</th>
                  </tr>
                  {lista.map((id, j) => (
                    <Fragment key={j}>
                      <tr key={j} onClick={() => onChecked(i, j)}>
                        <td>
                          <input type='radio' onChange={() => onChecked(i, j)} name={`cliente-${i}`} checked={id.checked} />
                        </td>
                        <td>
                          <a href={`/clientes/editar/${id._id}`} target='_blank' rel='noreferrer'>
                            {id._id}
                          </a>
                        </td>
                        <td>{id.numero_cliente}</td>
                        <td>{id.rfc}</td>
                        <td>{id.razon_social}</td>
                        <td>{id.nombre_comercial}</td>
                        <td>{id.listDomicilios.length}</td>
                        <td>{id.listContactos.length}</td>
                        <td>
                          {id.countFacturas},{id.countLevantamientos},{id.countNotas_creditos},{id.countOrdenesServicios}
                        </td>
                        <td>{<CbBadge type={id.status === 'ACTIVO' ? 'primary' : 'danger'}>{id.status}</CbBadge>}</td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </Fragment>
      );
    });
  };

  return (
    <Module title='Clientes agrupados'>
      <Search searchOnStopType blockOne='col-sm-8' blockSecond='col-sm-4' onSearch={onSearch}>
        <Row>
          <Col sm='7'>
            <FormGruopIcon title='Agrupador' icon='tag'>
              <Select value={agrupador} isClearable placeholder='Seleccione' options={agrupadores} onChange={(value) => setAgrupador(value)} />
            </FormGruopIcon>
          </Col>
          <Col sm='5'>
            <Button onClick={handleUnificar} className='btn btn-primary pull-right' type='button' disabled={loading}>
              Unificar
            </Button>
          </Col>
        </Row>
      </Search>
      <CbPanelTableResponsive
        loading={false}
        pagination={pagination}
        count={count}
        activePage={activePage}
        onPagination={handlePagination}
        onChangeItemsPerPagina={(page) => setPagination(page)}
      >
        <thead>
          <tr>
            <th width='5%'>#</th>
            <th width='20%'>Duplicados</th>
          </tr>
        </thead>
        <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
      </CbPanelTableResponsive>
    </Module>
  );
};

ListCustomer.propTypes = {};

export default ListCustomer;
