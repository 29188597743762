import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import NewContacto from './NewContacto';
import { Col, Row } from 'react-bootstrap';

const Contactos = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(50);
  const [search, setSearch] = useState('');
  const [tiposContactos, setTiposContactos] = useState([]);

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count } = await postRequest({ url: 'contactos/search', params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  const loadCollections = useCallback(async (callback) => {
    const { tiposContactos } = await getRequest({ url: `catalogo/tiposContactos` });
    setTiposContactos(
      tiposContactos.map((tipo) => ({
        value: tipo._id,
        label: tipo.tipo,
      }))
    );
    if (callback) callback();
  }, []);

  useEffect(() => {
    loadCollections(handleSearch);
  }, [loadCollections, handleSearch]);

  useEffect(() => {
    handleSearch();
  }, [activePage, search]);

  const renderList = () => (
    <div>
      <List {...{ tiposContactos, list, loadContactos: handleSearch }} />
    </div>
  );

  const moduleList = !loading ? renderList() : <Loading />;

  return (
    <Module title='Contactos'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <IfPermission action='crear'>
          <NewContacto tiposContactos={tiposContactos} loadContactos={handleSearch} />
        </IfPermission>
      </Search>
      <Row>
        <Col>
          {moduleList}
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </Col>
      </Row>
    </Module>
  );
};

Contactos.propTypes = {};

export default Contactos;
