import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { FormInput } from '@controls';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import DomicilioEdit from '@components/customers/DomicilioEdit';
import { useStoreNotification } from '@stores/catalogs.store';

const Nuevo = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();

  const [state, setState] = useState({
    showModal: false,
    regimen: 'I',
    rfc: 'XAXX010101000',
    nombre: '',
    nombre_comercial: '',
    paterno: '',
    materno: '',
    razon_social: '',
    estados: [],
    tiposContactos: [],
    listContactos: [],
    listDomicilios: [],
    titulos: [],
    paises: [],
    contacto: {
      nombre: '',
      correo: '',
      telefono: '',
      tipos: [],
    },
    domicilio: {
      fiscal: true,
      calle: '',
      exterior: '',
      interior: '',
      localidad: '',
      colonia: '',
      coloniaId: null,
      codigo_postal: '',
      municipio: '',
      estado: '',
      calle1: '',
      calle2: '',
      referencias: '',
      municipioId: null,
      pais: 'MÉXICO',
      paisId: null,
      estadoId: null,
      coloniaObject: null,
      municipioObject: null,
      isLoading: false,
      isOpen: false,
    },
  });

  const close = () => {
    setState((prevState) => ({ ...prevState, showModal: false }));
  };

  const open = async () => {
    const { tiposContactos } = await getRequest({ url: `catalogos/customers` });
    setState((prevState) => ({
      ...prevState,
      tiposContactos: tiposContactos.map((tipo) => ({
        value: tipo._id,
        label: tipo.tipo,
      })),
      regimen: 'I',
      rfc: 'XAXX010101000',
      nombre: '',
      paterno: '',
      materno: '',
      razon_social: '',
      nombre_comercial: '',
      showModal: true,
      contacto: {
        nombre: '',
        correo: '',
        telefono: '',
        tipos: [],
      },
      domicilio: {
        fiscal: true,
        calle: '',
        exterior: '',
        interior: '',
        localidad: '',
        colonia: '',
        coloniaId: null,
        codigo_postal: '',
        municipio: '',
        estado: '',
        calle1: '',
        calle2: '',
        referencias: '',
        municipioId: null,
        pais: 'MÉXICO',
        paisId: null,
        estadoId: null,
        coloniaObject: null,
        municipioObject: null,
        isLoading: false,
      },
    }));
  };

  const validations = (params) => {
    let validate = {
      success: false,
      message: '',
    };
    if (isEmpty(params.rfc)) {
      validate.message = 'RFC es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.nombre)) {
      validate.message = 'Nombre es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.paterno)) {
      validate.message = 'Apellido paterno es requerido.';
    } else if (isEmpty(params.phone) && isEmpty(params.email)) {
      validate.message = 'Los contactos requieren telefono o correo electronico.';
    } else {
      validate.success = true;
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const handleRegistrar = async (event) => {
    event.preventDefault();
    const {
      regimen,
      rfc,
      nombre,
      paterno,
      materno,
      razon_social,
      nombre_comercial,
      nacionalidad,
      extranjero,
      estado_civil,
      sexo,
      fecha_nacimiento,
      contacto,
      domicilio,
    } = state;
    const data = {
      regimen,
      rfc: rfc.trim(),
      nombre: nombre.trim(),
      name: nombre.trim(),
      paterno: paterno.trim(),
      materno: materno.trim(),
      razon_social: razon_social.trim(),
      nombre_comercial: nombre_comercial.trim(),
      nacionalidad,
      extranjero,
      estado_civil,
      sexo,
      fecha_nacimiento,
      domicilio: {
        calle: domicilio.calle ? domicilio.calle.toString().trim().toUpperCase() : '',
        exterior: domicilio.exterior,
        interior: domicilio.interior,
        colonia: domicilio.colonia ? domicilio.colonia.toString().trim().toUpperCase() : '',
        codigo_postal: domicilio.codigo_postal,
        municipio: domicilio.municipio ? domicilio.municipio.toString().trim().toUpperCase() : '',
        municipioId: domicilio.municipioId,
        estado: domicilio.estado.toString().trim().toUpperCase(),
        estadoId: domicilio.estadoId,
        calle1: domicilio.calle1,
        calle2: domicilio.calle2,
        referencias: domicilio.referencias,
        coloniaId: domicilio.coloniaObject ? domicilio.coloniaObject._id : null,
        paisId: domicilio.paisObject ? domicilio.paisObject._id : null,
        localidad: domicilio.localidad,
      },
      email: contacto.correo,
      phone: contacto.telefono,
      mobile: contacto.telefono,
    };
    if (validations(data)) {
      await postRequest({ url: `prospectos`, body: data });
      close();
      loadData();
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeContacto = (name, e) => {
    setState((prevState) => ({
      ...prevState,
      contacto: {
        ...prevState.contacto,
        [name]: e.target.value,
      },
    }));
  };

  const onChangeDomicilio = (domicilio) => {
    setState((prevState) => ({
      ...prevState,
      domicilio: {
        ...domicilio,
      },
    }));
  };

  const { regimen, showModal, nombre, paterno, materno, razon_social, nombre_comercial, contacto, isLoading } = state;

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} />
      <CbModal show={showModal} onClose={close} title='Nuevo' size='xl' onSave={handleRegistrar} isLoading={isLoading}>
        <Row>
          {regimen === 'F' ? (
            <>
              <Col>
                <FormInput name='nombre' title='Nombre(s)' onChange={onChange} value={nombre} required />
              </Col>
              <Col>
                <FormInput name='paterno' title='Apellido Paterno' onChange={onChange} value={paterno} required />
              </Col>
              <Col>
                <FormInput name='materno' title='Apellido Materno' onChange={onChange} value={materno} />
              </Col>
            </>
          ) : (
            <Col>
              <FormInput name='razon_social' title='Razón Social' onChange={onChange} value={razon_social} required />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <FormInput name='nombre_comercial' title='Nombre comercial' onChange={onChange} value={nombre_comercial} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              name='telefono'
              title='Teléfono/Celular'
              value={contacto.telefono}
              required
              onChange={(e) => onChangeContacto('telefono', e)}
            />
          </Col>
          <Col>
            <FormInput
              name='correo'
              value={contacto.correo}
              title='Correo electrónico'
              type='email'
              required
              onChange={(e) => onChangeContacto('correo', e)}
            />
          </Col>
        </Row>
        <br />
        <div>
          <div className='panel panel-default'>
            <div className='panel-heading'>
              <h2 className='panel-title'>
                <Icon icon='home' style={{ fontSize: '0.8em' }} /> Domicilios
              </h2>
            </div>
            <div className='panel-body'>
              <DomicilioEdit domicilio={state.domicilio} onChange={onChangeDomicilio} />
            </div>
            <div className='panel-footer'></div>
          </div>
        </div>
      </CbModal>
    </>
  );
};

Nuevo.propTypes = {
  loadData: PropTypes.func,
};

export default Nuevo;
