import { MIME_TYPES } from '@config/constants';
import { DivFormGroup, Input, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { postUploadRequest } from '@utils/api';

const AddDocumento = ({ customerId, documentoId, callback }) => {
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const frm = useRef(null);

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nombre') {
      setNombre(value);
    }
  };

  const close = () => {
    setShowModal(false);
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    const form = new FormData(frm.current);
    await postUploadRequest({ url: `adjunto/${customerId}/${documentoId}`, form });
    callback();
    close();
  };

  return (
    <>
      <Button onClick={open} className='btn btn-sm btn-success mr-5 pull-right' type='button'>
        <Icon icon='plus' />
      </Button>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Adjuntar</Modal.Title>
        </Modal.Header>
        <form ref={frm} onSubmit={handleAdd} encType='multipart/form-data'>
          <Modal.Body>
            <Row>
              <Col sm='12'>
                <DivFormGroup>
                  <Label name='nombre' title='Nombre' />
                  <Input name='nombre' onChange={onChange} defaultValue={nombre} />
                </DivFormGroup>
                <DivFormGroup>
                  <Label name='file' title='Archivo' />
                  <input type='file' id='file' name='file' accept={MIME_TYPES.PDF} />
                </DivFormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' className='btn btn-success pull-right'>
              Agregar
            </Button>
            <span className='pull-right'>&nbsp;</span>
            <Button onClick={close} className='btn btn-default pull-right' type='button'>
              Cancelar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

AddDocumento.propTypes = {
  customerId: PropTypes.string.isRequired,
  documentoId: PropTypes.string.isRequired,
  nombreTipoDocumento: PropTypes.string.isRequired,
  listDomicilios: PropTypes.array.isRequired,
  callback: PropTypes.func,
  regimen: PropTypes.string.isRequired,
  datosGenerarDocumento: PropTypes.object,
};

export default AddDocumento;
