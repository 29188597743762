import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';

const ButtonMove = ({ id, title, pullPosition, icon, callback }) => {
  const handleClick = () => {
    callback();
  };

  return (
    <OverlayTrigger placement='top' overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <button onClick={handleClick} className={`btn btn-warning ${pullPosition}`} type='button'>
        <Icon icon={icon} />
      </button>
    </OverlayTrigger>
  );
};

ButtonMove.propTypes = {
  id: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  pullPosition: PropTypes.string,
};

export default ButtonMove;
