import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';

import TableProducts from './TableProducts';

import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';

export const FILTERS = [
  { name: 'ALL', text: 'Todos', className: 'info' },
  { name: 'SERVICE', text: 'Servicios', className: 'primary' },
  { name: 'PRODUCT', text: 'Productos', className: 'warning' },
];

const ListProducts = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(10000);
  const [search, setSearch] = useState('');

  useEffect(() => {
    handleSearch();
  }, [activePage, search]);

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
    };
    const { data, count } = await postRequest({ url: 'precios-equipos/search', params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  };

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  return (
    <Module title='Precios'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'></Search>
      <Loading loading={loading}>
        <TableProducts listProducts={list} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

ListProducts.propTypes = {};

export default ListProducts;
