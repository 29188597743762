import React, { useEffect } from 'react';
import Select from 'react-select';
import { useStoreTiposServiciosSolicitados } from '@stores/catalogs.store';
import PropTypes from 'prop-types';

const MultiSelectSolucionesSolicitadas = ({ onChange, name, value }) => {
  const { data, execute } = useStoreTiposServiciosSolicitados();
  useEffect(() => {
    execute();
  }, []);

  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      value={value}
      name={name}
      placeholder='Seleccione'
      options={data}
      isMulti
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='basic-multi-select'
      getOptionLabel={(x) => x.definicion}
      getOptionValue={(x) => x._id}
      classNamePrefix='select'
    />
  );
};


MultiSelectSolucionesSolicitadas.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
};

export default MultiSelectSolucionesSolicitadas;
