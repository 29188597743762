import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import New from './New';
import { Col, Row } from 'react-bootstrap';

const ModuleCatalog = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async (params = {}) => {
    const page = activePage * pagination - pagination;
    const body = {
      search: params.search,
    };
    const { data, count } = await postRequest({ url: `maquinaria-herramientas/search`, params: { page, limit: pagination }, body });
    setData(data);
    setCount(count);
    setSearch(params.search);
    setLoading(false);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    const page = eventKey * pagination - pagination;
    handleSearch({ page, pagination, search });
  };

  const renderList = () => {
    return <List data={data} loadData={handleSearch} />;
  };

  const moduleList = !loading ? renderList() : <Loading />;

  return (
    <Module title='Maquinaria y Herramientas'>
      <Search onSearch={handleSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <IfPermission action='crear'>
          <New data={data} loadData={handleSearch} />
        </IfPermission>
      </Search>
      <Row>
        <Col>
          {moduleList}
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </Col>
      </Row>
    </Module>
  );
};

ModuleCatalog.propTypes = {};

export default ModuleCatalog;
