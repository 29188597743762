import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const SelectAgente = ({ onChange, name, value, disabled, options }) => {
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      value={value}
      name={name}
      placeholder='Selecciona'
      options={options}
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='basic-multi-select'
      getOptionLabel={(x) => x.label}
      getOptionValue={(x) => x.value}
      classNamePrefix='select'
      isDisabled={disabled}
      isClearable
    />
  );
};

SelectAgente.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  options: PropTypes.array,
};

export default SelectAgente;
