import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import PropTypes from 'prop-types';
import React from 'react';

const Calendario = ({ list }) => {
  const agenda = [];
  list.forEach((empleado, i) => {
    empleado.rangos.forEach((rango, j) => {
      agenda.push({
        id: empleado._id + '-' + i + '-' + j,
        title: empleado.nombre_completo,
        start: rango.fecha_inicial.substring(0, 10),
        end: rango.fecha_final.substring(0, 10),
        description: 'VACACIONES',
      });
    });
  });

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>Calendario</div>
      <div className='panel-body'>
        <FullCalendar locales={[esLocale]} locale='es' defaultView='dayGridMonth' plugins={[dayGridPlugin]} events={agenda} />
      </div>
    </div>
  );
};

Calendario.propTypes = {
  list: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default Calendario;
