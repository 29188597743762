import PropTypes from 'prop-types';
import { Confirmation } from '@controls';
import React from 'react';

const Delete = ({itemId, nombre, loadData}) =>{
    return (
        <Confirmation
          btnIcon ='trash'
          typeRequest='deleteRequest'
          btnType='outline-danger'
          action='Eliminar'
          url={`acreedores/${itemId}`}
          description={`¿Confirma que desea eliminar el acreedor <strong><u>${nombre}</u></strong>?`}
          loadData={loadData}       
        />
    );
};

Delete.propTypes={
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    loadData: PropTypes.func.isRequired
};

export default Delete;