import Joi from 'joi';
import { MESSAGES } from '@utils/validations';
const R_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const compraSchemaUpdate = Joi.object({
  fecha: Joi.date().label('Fecha').required(),
  estatus_solicitud: Joi.string().label('Estatus').required(),
  sucursalId: Joi.string().label('Sucursal').required().regex(R_MONGO_ID),
  agenteId: Joi.string().label('Agente').required().regex(R_MONGO_ID),
  detalles: Joi.array().items(
    Joi.object({
      descripcion: Joi.string().label('Descripción').required(),
      measureId: Joi.string().label('Unidad').required().regex(R_MONGO_ID),
      cantidad: Joi.number().label('Cantidad').required(),
    })
  ),
}).messages(MESSAGES);

export function validateUpdate(compra) {
  return compraSchemaUpdate.validate(compra);
}
