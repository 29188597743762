import { DivFormGroup } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { clean } from '@utils/formatter';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [fecha, setFecha] = useState('');
  const [valor, setValor] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'fecha') setFecha(value);
    if (name === 'valor') setValor(value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      fecha,
      valor: clean(valor),
    };

    if (validations(data)) {
      await postRequest({ url: `tipo-cambio`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    const now = new Date();
    const f = params.fecha.split('-');
    const fechaParse = new Date(f[0], f[1] - 1, f[2]);

    if (isEmpty(params.fecha + '')) {
      validate.success = false;
      validate.message = 'Fecha es requerida.';
    } else if (validate.success && moment(now).isSameOrBefore(fechaParse)) {
      validate.success = false;
      validate.message = 'Fecha no puede ser mayor al día de hoy.';
    } else if (validate.success && isEmpty(params.valor + '')) {
      validate.success = false;
      validate.message = 'Valor es requerido.';
    } else if (validate.success && params.valor <= 0) {
      validate.success = false;
      validate.message = 'Valor debe ser mayor a cero.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='fecha' title='Fecha' required>
              <input type='date' id='fecha' name='fecha' className='form-control' value={fecha} onChange={onChange} />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='valor' title='Valor' required>
              <InputNumber name='valor' placeholder='Valor' value={valor} onChange={onChange} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  loadData: PropTypes.func.isRequired,
};

export default New;
