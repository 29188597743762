import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, RowsNotFound } from '@stateless';
import { getRequest, urlPhoto } from '@utils/api';
import { OverlayTrigger, Tooltip, Modal, Tab, Row, Col, Nav, NavItem } from 'react-bootstrap';

const ModalAdjuntos = ({ seguimientoId }) => {
  const [showModal, setShowModal] = useState(false);
  const [documentoId, setDocumentoId] = useState('');
  const [documentos, setDocumentos] = useState([]);
  const [data, setData] = useState([]);

  const close = () => {
    setShowModal(false);
    setDocumentoId('');
    setDocumentos([]);
    setData([]);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `seguimientos/${seguimientoId}` });
    setDocumentos(data.documentos.map((x) => ({ ...x })));
    setShowModal(true);
  };

  const onSelectColumn = (activeKey) => {
    setDocumentoId(activeKey);
  };

  const documentoSelect = documentos.find((d) => d._id === documentoId);

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip>Documentos</Tooltip>}>
        <button onClick={open} className='btn btn-outline-success' type='button'>
          <Icon icon='file' />
        </button>
      </OverlayTrigger>
      <Modal size='large' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Documentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row>
              <Col sm='12'>
                <Tab.Container id='equipments-services' defaultActiveKey={1} onSelect={onSelectColumn}>
                  <Row className='clearfix'>
                    <Col sm={4}>
                      <Nav bsStyle='pills' stacked>
                        {documentos.map((row, item) => {
                          const { _id, nombre } = row;
                          return (
                            <NavItem eventKey={_id} key={item}>
                              {nombre}

                              <div className='pull-right'>
                                <Icon icon='chevron-right' />
                              </div>
                            </NavItem>
                          );
                        })}
                      </Nav>
                    </Col>
                    <Col sm={8}>
                      {typeof documentoSelect !== 'undefined' ? (
                        <Row>
                          <Col sm='12'>
                            <div className='text-center'>
                              <span className='lead'>{documentoSelect.nombre}</span>
                            </div>
                          </Col>
                        </Row>
                      ) : null}

                      <Tab.Content animation>
                        {documentos.map((doc, doci) => {
                          const filtro = data.filter((a) => a.documentoId.toString() === doc._id.toString());
                          return (
                            <Tab.Pane eventKey={doc._id} key={doci}>
                              <table className='table table-condensed table-hover table-striped'>
                                <thead>
                                  <tr>
                                    <th width='80%'>Adjunto</th>
                                    <th width='20%'></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filtro.length > 0 ? (
                                    filtro.map((adjunto, adjuntoKey) => {
                                      return (
                                        <tr key={adjuntoKey}>
                                          <td>{adjunto.nombre}</td>
                                          <td>
                                            <a
                                              href={`${urlPhoto()}${adjunto.archivo}`}
                                              target='_blank'
                                              rel='noopener noreferrer'
                                              className='btn btn-warning pull-right'
                                            >
                                              <Icon icon='download' />
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <RowsNotFound colSpan={2} />
                                  )}
                                </tbody>
                              </table>
                            </Tab.Pane>
                          );
                        })}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

ModalAdjuntos.propTypes = {
  seguimientoId: PropTypes.string.isRequired,
};

export default ModalAdjuntos;
