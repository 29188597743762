import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest, URL_FILES } from '@utils/api';
import { CbPagination } from '@controls';
import ListAgentes from './ListAgentes';
import NewAgentes from './NewAgentes';
import { Button, ButtonGroup, Col, InputGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Icon } from '@components/application/controls/Stateless';

const Agentes = () => {
  const [catalogAgentes, setCatalogAgentes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(50);
  const [search, setSearch] = useState('');
  const [sucursales, setSucursales] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [status, setStatus] = useState(null);

  const loadCollections = useCallback(async (callback) => {
    const {
      data: { sucursales, almacenes },
    } = await getRequest({ url: `agentes-catalogos` });
    setSucursales(sucursales);
    setAlmacenes(almacenes);
    if (callback) callback();
  }, []);

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search, status: status?.value };
    const { data, count } = await postRequest({ url: `agentes/search`, params: { page, limit: pagination }, body });
    setCatalogAgentes(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search, status]);

  useEffect(() => {
    loadCollections(handleSearch);
  }, [loadCollections, handleSearch]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  const onClickExportar = () => {
    postRequest({ url: `agentes/exportar` }).then(({ file }) => {
      window.open(URL_FILES + 'excel/' + file, '_blank');
    });
  };

  return (
    <Module title='Agentes'>
      <Search onSearch={onSearch} searchOnStopType blockOne='col-sm-4' blockSecond='col-sm-8'>
        <Row>
          <Col sm={8}>
            <InputGroup>
              <InputGroup.Text>
                <Icon icon='tag' />
              </InputGroup.Text>
              <Select
                name='status'
                placeholder='Seleccione una sucursal'
                options={[
                  { value: 'ACTIVO', label: 'Activo' },
                  { value: 'BAJA', label: 'Baja' },
                  { value: 'ELIMINADO', label: 'Eliminado' },
                ]}
                isClearable
                onChange={(selectedOption) => {
                  setStatus(selectedOption);
                  handleSearch();
                }}
              />
            </InputGroup>
          </Col>
          <Col sm={4}>
            <ButtonGroup>
              <Button onClick={onClickExportar} variant='outline-primary'>
                <Icon icon='download-alt' />
              </Button>
              <IfPermission action='crear'>
                <NewAgentes sucursales={sucursales} almacenes={almacenes} loadAgentes={handleSearch} />
              </IfPermission>
            </ButtonGroup>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <ListAgentes sucursales={sucursales} almacenes={almacenes} catalogAgentes={catalogAgentes} loadAgentes={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

Agentes.propTypes = {};

export default Agentes;
