import Joi from 'joi';
import { MESSAGES } from '@utils/validations';
const R_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const equipmentSchemaUpdate = Joi.object({
  code: Joi.string().label('Código').required(),
  short_name: Joi.string().label('Nombre corto').required(),
  name: Joi.string().label('Nombre').required(),
  description: Joi.string().label('Descripción').required(),
  measureId: Joi.string().label('Unidad de medida').required().regex(R_MONGO_ID),
  marcaId: Joi.string().label('Marca').required().regex(R_MONGO_ID),
  clasificacionCotizacionId: Joi.string().label('Linea').required().regex(R_MONGO_ID),
  clasificacionLevantamientoId: Joi.string().label('Levantamientos').required().regex(R_MONGO_ID),
  clave_sat: Joi.string().label('Clave SAT').allow(null, ''),
  codigo_barras: Joi.string().label('Código barras').allow(null, ''),
  usa_series: Joi.boolean().label('Usa series').required(),
  caducidad: Joi.boolean().label('Caducidad').required(),
  tiempo_caducidad: Joi.number().label('Tiempo de caducidad').allow(null, ''),
  serie: Joi.string().label('Serie').allow(null, ''),
  pedimento: Joi.string().label('Serie').allow(null, ''),
  moneda: Joi.string().label('Moneda').required(),
  moneda_compra: Joi.string().label('Moneda de compra').required(),
  tipo_costeo: Joi.string().label('Tipo de costeo').required(),
  last_cost: Joi.number().label('Último costo').required(),
  cost_average: Joi.number().label('Costo promedio').required(),
  cost: Joi.number().label('Último costo').required(),
  tipo_cambio: Joi.number().label('Tipo de cambio').required(),
  margen_ganancia: Joi.number().min(0).max(100).label('Margen (%)').required(),
  precio: Joi.number().label('Precio').required(),
  iva: Joi.number().min(0).max(100).label('IVA (%)').required(),
  listWorkforce: Joi.array().items(
    Joi.object({
      value: Joi.number().label('Valor').required(),
      level: Joi.number().label('Nivel').required(),
    })
  ),
  proveedores: Joi.array().items(
    Joi.object({
      proveedorId: Joi.string().label('Proveedor').required().regex(R_MONGO_ID),
      codigo: Joi.string().label('Código').allow(null, ''),
      codigo_barras: Joi.string().label('Código de barras').allow(null, ''),
      ultimo_costo: Joi.number().label('Último costo').required(),
      date_last_cost: Joi.date().label('Fecha de último costo').required(),
    })
  ),
  alternativos: Joi.array().items(
    Joi.object({
      equipoId: Joi.string().label('Equipo alternativo').required().regex(R_MONGO_ID),
    })
  ),
  date_last_cost: Joi.date().label('Fecha de último costo').required(),
}).messages(MESSAGES);

export function validateUpdate(equipment) {
  console.log(equipment);
  return equipmentSchemaUpdate.validate(equipment);
}
