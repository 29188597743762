import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Icon, Loading, RowsNotFound } from '@stateless';
import { getRequest, postRequest, URL_FILES } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const GenerarSolicitudCompra = ({ pedidoId }) => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [idSolicitudCompra, setIdSolicitudCompra] = useState(null);
  const [equipos, setEquipos] = useState([]);

  useEffect(() => {
    if (showModal) {
      const fetchPedido = async () => {
        const { data } = await getRequest({ url: `pedido/${pedidoId}` });
        setEquipos(
          data.detalle.map((x) => ({
            ...x,
            cantidad_requerida: Number(x.cantidad - x.entregado),
            cantidad_solicitada: Number(x.cantidad - x.entregado) > x.existencia ? Number(x.cantidad - x.entregado) - x.existencia : 0,
            selected: Number(x.cantidad - x.entregado) > x.existencia,
          }))
        );
        setIsLoading(false);
      };
      fetchPedido();
    }
  }, [showModal, pedidoId]);

  const closeModal = () => {
    setShowModal(false);
  };

  const onOpenModal = (e) => {
    e && e.preventDefault();
    setShowModal(true);
    setIsLoading(true);
  };

  const onSolicitar = async () => {
    if (validations(equipos)) {
      setIsLoading(true);
      const body = { detalles: equipos.filter((x) => x.selected) };
      const { data } = await postRequest({ url: `pedidos/solicitud-compra/${pedidoId}`, body });
      setIdSolicitudCompra(data._id);
      setIsLoading(false);
      setShowModal(false);
      setShowModalDownload(true);
    }
  };

  const validations = (equipos) => {
    let validate = {
      success: true,
      message: '',
    };

    if (equipos.filter((x) => x.selected).length === 0) {
      validate.success = false;
      validate.message = 'Detalles son requeridos.';
    } else if (equipos.filter((x) => x.selected && x.cantidad_solicitada === 0).length > 0) {
      validate.success = false;
      validate.message = 'Cantidad solicitada es requerida.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const handleClickCheckboxAgregarDetalle = (index, checked, e) => {
    e.stopPropagation();
    setEquipos(
      equipos.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            selected: checked,
          };
        }
        return x;
      })
    );
  };

  const onChangeDetalle = (name, index, event) => {
    setEquipos(
      equipos.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            [name]: Number(cleanNumber(event.target.value)),
          };
        }
        return x;
      })
    );
  };

  const closeModalDowload = () => {
    setShowModalDownload(false);
    setTimeout(() => navigate('/pedidos'), 1000);
  };

  const onDowload = async (event) => {
    event.preventDefault();
    const { data } = await getRequest({ url: `solicitud-compra/pdf/${idSolicitudCompra}` });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
      closeModalDowload();
    }
  };

  const onOpenSolicitudes = () => {
    setShowModalDownload(false);
    setTimeout(() => navigate('/solicitudes-compras'), 1000);
  };

  const onOpenSolicitud = () => {
    setShowModalDownload(false);
    setTimeout(() => navigate(`/solicitudes-compras/edicion/${idSolicitudCompra}`), 1000);
  };

  const rows =
    equipos && equipos.length > 0 ? (
      equipos.map((x, i) => (
        <tr key={i}>
          <td className='text-center'>
            <input type='checkbox' name='equipos' checked={x.selected} onChange={(e) => handleClickCheckboxAgregarDetalle(i, !x.selected, e)} />
          </td>
          <td className='text-justify'>{x.codigo}</td>
          <td className='text-justify'>{x.descripcion.toUpperCase()}</td>
          <td className='text-center'>{x.unidadMedidaId.measure}</td>
          <td className='text-center'>{Number(x.cantidad_requerida)}</td>
          <td className='text-center'>{Number(x.existencia)}</td>
          <td className='text-center'>
            <InputNumber className='input-sm' value={x.cantidad} onChange={(e) => onChangeDetalle('cantidad', i, e)} style={{ width: '80px' }} />
          </td>
        </tr>
      ))
    ) : (
      <RowsNotFound message='Sin equipos' colSpan={8} />
    );

  return (
    <>
      <Button type='button' onClick={onOpenModal} className='btn btn-primary pull-right mr-5'>
        <Icon icon='file' /> Solicitar faltantes
      </Button>
      <Modal size='large' show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Solicitud de compra</Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <Loading />
        ) : (
          <div className='panel panel-default panel-table'>
            <div className='table-responsive'>
              <table className='table table-condensed table-hover table-striped'>
                <thead>
                  <tr>
                    <th width='2%'>&nbsp;</th>
                    <th width='5%'>Código</th>
                    <th width='45%'>Descripción</th>
                    <th width='5%'>Unidad</th>
                    <th width='5%'>Cantidad Requerida</th>
                    <th width='5%'>Existencia</th>
                    <th width='5%'>Cantidad Solicitada</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        )}
        <Modal.Footer>
          <Button className='btn btn-primary pull-right' onClick={onSolicitar}>
            Solicitar
          </Button>
          <Button onClick={closeModal} className='btn btn-default mr-5 pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalDownload} onHide={closeModalDowload}>
        <Modal.Header closeButton>
          <Modal.Title>Descargar solicitud de compra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className={'col-sm-12'}>
              <div className='row'>
                <div className='col-sm-12'>
                  <Button onClick={onDowload} className='btn btn-block btn-primary mb-5' type='button'>
                    Descargar
                  </Button>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <Button onClick={onOpenSolicitudes} className='btn btn-block btn-primary mb-5' type='button'>
                    Ir a solicitudes de compras
                  </Button>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <Button onClick={onOpenSolicitud} className='btn btn-block btn-primary mb-5' type='button'>
                    Editar solicitud de compra
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ textAlign: 'center' }}>
          <Button onClick={closeModalDowload} className='btn btn-lg btn-default mr-5' type='button'>
            &nbsp;Cerrar&nbsp;
          </Button>
          <Button onClick={onDowload} className='btn btn-lg btn-primary mr-5' type='button'>
            Imprimir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

GenerarSolicitudCompra.propTypes = {
  pedidoId: PropTypes.string.isRequired,
};

export default GenerarSolicitudCompra;
