import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ id, paisId: initialPaisId, pais: initialPais, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [pais, setPais] = useState('');
  const [paisId, setPaisId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => setShowModal(false);

  const open = () => {
    setShowModal(true);
    setPais(initialPais);
    setPaisId(initialPaisId);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      pais: pais.trim().toUpperCase(),
      paisId: paisId.trim(),
    };

    if (validations(data)) {
      setIsLoading(true);
      await putRequest({ url: `paises/${id}`, body: data });
      load();
      close();
      setIsLoading(false);
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.paisId + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.pais + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <FormInput title='Código' placeholder='Código' required name='paisId' onChange={(e) => setPaisId(e.target.value)} value={paisId} />
          </Col>
          <Col sm='6'>
            <FormInput title='Nombre' placeholder='Nombre' required name='pais' onChange={(e) => setPais(e.target.value)} value={pais} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  paisId: PropTypes.string.isRequired,
  pais: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Edit;
