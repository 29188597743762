import { TIPOS_DOMICILIOS } from '@config/constants';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon, RowsNotFound } from '@stateless';
import { getRequest, putRequest } from '@utils/api';

const ModalDomicilios = ({ customerId, contactoId, tipoContactoId, domicilioId, loadList }) => {
  const [showModal, setShowModal] = useState(false);
  const [domicilios, setDomicilios] = useState([]);

  const close = () => {
    setShowModal(false);
    setDomicilios([]);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `cliente/${customerId}/domicilios` });
    setShowModal(true);
    setDomicilios(data);
  };

  const handleDomcilio = async (domicilioId) => {
    const body = {
      tipoContactoId: tipoContactoId ? tipoContactoId._id : null,
      domicilioId,
    };
    await putRequest({ url: `contacto/${contactoId}/domicilios`, body });
    close();
    loadList();
  };

  const hasValue = (field) => {
    return field !== null && field !== '';
  };

  const renderRows = () => {
    return domicilios.map((d, i) => {
      const { _id, monitoreo, nombre, calle, exterior, interior, colonia, codigo_postal, municipio, estado, pais, tipo, entre_calles, referencias } =
        d;

      let entreCalles = '';

      if (hasValue(entre_calles.calle1) && !hasValue(entre_calles.calle2)) {
        entreCalles = entre_calles.calle1;
      }

      if (hasValue(entre_calles.calle1) && hasValue(entre_calles.calle2)) {
        entreCalles = `${entre_calles.calle1} y ${entre_calles.calle2}`;
      }

      if (!hasValue(entre_calles.calle1) && hasValue(entre_calles.calle2)) {
        entreCalles = entre_calles.calle2;
      }

      let ubicacion = '';

      if (hasValue(referencias)) {
        if (entreCalles !== '') {
          ubicacion = (
            <div>
              Entre {entreCalles}
              <br />
              Referencia {referencias}
            </div>
          );
        } else {
          ubicacion = `Referencia ${referencias}`;
        }
      } else if (entreCalles !== '') {
        ubicacion = `Entre ${entreCalles}`;
      }

      let isSelected = false;

      if (domicilioId !== null) {
        if (typeof domicilioId._id !== 'undefined') {
          if (domicilioId._id === d._id) {
            isSelected = true;
          }
        }
      }

      const color = isSelected ? 'success' : '';

      return (
        <tr key={i} className={color}>
          <td>{monitoreo}</td>
          {tipo === TIPOS_DOMICILIOS.SUCURSAL ? (
            <td>
              <span className='label label-default'>Sucursal</span>
            </td>
          ) : (
            <td>
              <span className='label label-primary'>
                <Icon icon='tag' /> Principal
              </span>
            </td>
          )}
          <td>{nombre}</td>
          <td>
            {calle} {exterior}, {interior},
            <br />
            Colonia {colonia} C.P. {codigo_postal},
            <br />
            {municipio} {estado}, {pais}
          </td>
          <td>{ubicacion}</td>
          <td>
            <OverlayTrigger placement='top' overlay={<Tooltip id={i}>{isSelected ? 'Quitar' : 'Asignar'}</Tooltip>}>
              <button onClick={() => handleDomcilio(_id)} className={`btn btn-sm btn-${isSelected ? 'danger' : 'primary'} pull-right`} type='button'>
                <Icon icon={isSelected ? 'remove' : 'check'} />
              </button>
            </OverlayTrigger>
          </td>
        </tr>
      );
    });
  };

  const rows = renderRows();

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Domicilios</Tooltip>}>
        <button onClick={open} className='btn btn-sm btn-default pull-right' type='button'>
          <Icon icon='home' />
        </button>
      </OverlayTrigger>

      <Modal size='large' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>
            Contacto como <strong>{tipoContactoId && tipoContactoId.tipo}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0px' }}>
          <form>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th width='10%'>Monitoreo</th>
                  <th width='10%'>Tipo</th>
                  <th width='20%'>Nombre</th>
                  <th width='30%'>Domicilio</th>
                  <th width='20%'>Referencias</th>
                  <th width='10%'></th>
                </tr>
              </thead>
              <tbody>{domicilios.length > 0 ? rows : <RowsNotFound colSpan={6} message='No se han agregado domicilios' />}</tbody>
            </table>
          </form>
        </Modal.Body>
        <Modal.Footer style={{ marginTop: '-21px' }}>
          <button onClick={close} className='btn btn-default pull-right' type='button'>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ModalDomicilios.propTypes = {
  customerId: PropTypes.string.isRequired,
  contactoId: PropTypes.string.isRequired,
  tipoContactoId: PropTypes.object.isRequired,
  domicilioId: PropTypes.object,
  loadList: PropTypes.func.isRequired,
};

export default ModalDomicilios;
