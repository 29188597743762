import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, DropdownItem, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import CbModal from '@cbcomponents/CbModal';

const PERIODOS_MONITOREO = {
  MENSUAL: 'MENSUAL',
  TRIMESTRAL: 'TRIMESTRAL',
  SEMESTRAL: 'SEMESTRAL',
  ANUAL: 'ANUAL',
};

const OPTIONS_PERIODOS_MONITOREO = Object.values(PERIODOS_MONITOREO).map((key) => ({ label: PERIODOS_MONITOREO[key], value: key }));

const CambioDeFechas = ({ agenda, refresh }) => {
  const [showModal, setShowModal] = useState(false);
  const [periodicidades] = useState(OPTIONS_PERIODOS_MONITOREO);
  const [periodicidad, setPeriodicidad] = useState(null);
  const [ultimaEmision, setUltimaEmision] = useState(null);
  const [siguienteEmision, setSiguienteEmision] = useState(null);
  const [isLoading] = useState(false);

  const close = () => setShowModal(false);

  const open = () => {
    setShowModal(true);
    setUltimaEmision(moment(agenda.ultima_emision).isValid() ? moment(agenda.ultima_emision).utc().format('YYYY-MM-DD') : null);
    setSiguienteEmision(moment(agenda.siguiente_emision).isValid() ? moment(agenda.siguiente_emision).utc().format('YYYY-MM-DD') : null);
    setPeriodicidad(OPTIONS_PERIODOS_MONITOREO.find((item) => item.value === agenda.periodicidad));
  };

  const handleRegister = async () => {
    const data = {
      ultima_emision: moment(ultimaEmision).utc(),
      siguiente_emision: moment(siguienteEmision).utc(),
      periodicidad: periodicidad.value,
    };
    await postRequest({ url: `agendas-monitoreos/${agenda._id}/cambio-fechas`, body: data });
    refresh();
    close();
  };

  const handleSiguienteEmision = () => {
    let Cantidad = 1;
    switch (periodicidad?.value) {
      case PERIODOS_MONITOREO.MENSUAL:
        break;
      case PERIODOS_MONITOREO.TRIMESTRAL:
        Cantidad = 3;
        break;
      case PERIODOS_MONITOREO.SEMESTRAL:
        Cantidad = 6;
        break;
      case PERIODOS_MONITOREO.ANUAL:
        Cantidad = 12;
        break;
    }
    const proxima = moment(siguienteEmision).utc().add(Cantidad, 'month');
    const anterior = moment(siguienteEmision);
    setSiguienteEmision(proxima.format('YYYY-MM-DD'));
    setUltimaEmision(anterior.format('YYYY-MM-DD'));
  };

  const buttons = [{ text: 'Siguiente', onClick: handleSiguienteEmision, type: 'warning' }];

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='calendar' /> Fechas de emisión
      </DropdownItem>
      <CbModal
        show={showModal}
        onClose={close}
        onSave={handleRegister}
        title='Cambios de fechas de emisión'
        extraButtons={buttons}
        isLoading={isLoading}
      >
        <Row>
          <Col>
            <DivFormGroup name='periodicidad' required title='Periodicidad'>
              <Select
                value={periodicidad}
                isClearable
                placeholder='Seleccione'
                options={periodicidades}
                onChange={(value) => setPeriodicidad(value)}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              type='date'
              title='Última Emisión'
              name='ultima_emision'
              required
              onChange={(e) => setUltimaEmision(e.target.value)}
              value={ultimaEmision}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              type='date'
              title='Siguiente Emisión'
              name='siguiente_emision'
              required
              onChange={(e) => setSiguienteEmision(e.target.value)}
              value={siguienteEmision}
            />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

CambioDeFechas.propTypes = {
  agenda: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default CambioDeFechas;
