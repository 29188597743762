import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Loading } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';

const BuscadorRecetas = (props) => {
  const [showModal, setShowModal] = useState(true);
  const [recetas, setRecetas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(50);
  const [search, setSearch] = useState('');
  let timeout = 0;

  useEffect(() => {
    handlePagination(1);
  }, []);

  const agregarReceta = useCallback(
    async (producto) => {
      setLoading(true);
      props.setState({ isLoadingTable: true }, async () => {
        const { data } = await getRequest({ url: `recetas/${producto._id}?almacenId=${props.almacenId}` });
        try {
          props.setState(
            {
              isLoadingTable: false,
              productoName: '',
              producto: '',
              productoObj: '',
              entradas: [
                ...data.entradas.map((e) => ({
                  ...e,
                  equipmentId: e.equipoId._id,
                  descripcion: e.equipoId.name,
                  noIdentificador: e.equipoId.code,
                  precio_original: 0,
                  measure: e.equipoId && e.equipoId.equipmentsMeasureId ? e.equipoId.equipmentsMeasureId.measure : null,
                  measures: [],
                  measureId: e.equipoId && e.equipoId.equipmentsMeasureId ? e.equipoId.equipmentsMeasureId._id : null,
                  precio: 0,
                  cantidad: e.cantidad || 0,
                  descuento: 0,
                  moneda_original: e.moneda,
                  impuesto: e.iva || 16,
                  importe: 0,
                })),
              ],
              salidas: [
                ...data.salidas.map((s) => ({
                  ...s,
                  equipmentId: s.equipoId._id,
                  descripcion: s.equipoId.name,
                  noIdentificador: s.equipoId.code,
                  precio_original: 0,
                  measure: s.equipoId && s.equipoId.equipmentsMeasureId ? s.equipoId.equipmentsMeasureId.measure : null,
                  measures: [],
                  measureId: s.equipoId && s.equipoId.equipmentsMeasureId ? s.equipoId.equipmentsMeasureId._id : null,
                  precio: 0,
                  cantidad: s.cantidad || 0,
                  descuento: 0,
                  moneda_original: s.moneda,
                  impuesto: s.iva || 16,
                  importe: 0,
                })),
              ],
            },
            () => {
              setLoading(false);
              setShowModal(false);
              props.actualizarTotalesDetalles();
            }
          );
        } catch (e) {
          console.log(e);
        }
      });
    },
    [props]
  );

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const onChange = (event) => {
    setSearch(event.target.value);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (!loading && (search.toString().length === 0 || search.toString().length >= 3)) {
        handlePagination(1);
      }
    }, 800);
  };

  const handleSearch = async (params = {}) => {
    const page = typeof params.page === 'undefined' ? 0 : params.page;
    let activePage = activePage;
    if (params.origin !== 'undefined' && params.origin === 'SEARCH') {
      activePage = 1;
    }
    setLoading(true);
    setActivePage(activePage);
    const { data, count } = await postRequest({ url: `recetas/search`, params: { page, limit: pagination }, body: { search } });
    setRecetas(data);
    setCount(count);
    setLoading(false);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    const page = eventKey * pagination - pagination;
    handleSearch({ page });
  };

  const onClickReceta = (producto) => {
    const { tipoCambio, monedaId } = props;
    return agregarReceta(producto, 1, tipoCambio, monedaId);
  };

  const renderRows = (list) => {
    return list.map((x) => (
      <tr key={x._id} onDoubleClick={() => onClickReceta(x)} style={{ cursor: 'pointer' }}>
        <td>&nbsp;</td>
        <td>{x.codigo}</td>
        <td>{x.nombre}</td>
      </tr>
    ));
  };

  const rows =
    !loading && !props.isLoadingTable ? (
      renderRows(recetas)
    ) : (
      <tr>
        <td colSpan={9} style={{ textAlign: 'center' }}>
          <Loading />
        </td>
      </tr>
    );

  return (
    <>
      <Modal show={showModal} onHide={closeModal} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Recetas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <FormInput title='Código o Descripción' placeholder='Buscar...' name='buscar' onChange={onChange} value={search} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='panel panel-default panel-table'>
                <div className='table-responsive'>
                  <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
                    <thead>
                      <tr>
                        <th width='2%'>&nbsp;</th>
                        <th width='5%'>Código</th>
                        <th width='45%'>Descripción</th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </table>
                </div>
              </div>
              {recetas.length > 0 ? (
                <div style={{ textAlign: 'center' }}>
                  <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
                </div>
              ) : (
                false
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} className='btn btn-default pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Button onClick={openModal} className='btn btn-default pull-right' type='button'>
        Recetas
      </Button>
    </>
  );
};

BuscadorRecetas.propTypes = {
  tipo_partida: PropTypes.string,
  isLoadingTable: PropTypes.bool,
  monedaId: PropTypes.string,
  sucursalId: PropTypes.string,
  almacenId: PropTypes.string,
  tipoCambio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entradas: PropTypes.array,
  salidas: PropTypes.array,
  measures: PropTypes.array,
  actualizarTotalesDetalles: PropTypes.func,
  setState: PropTypes.func,
};

export default BuscadorRecetas;
