import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Nav, NavItem, Row, Tab } from 'react-bootstrap';
import { Icon, RowsNotFound } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';

const ListEquipments = ({ serviceIdDefault, catalogEquipments }) => {
  const [serviceId, setServiceId] = useState(serviceIdDefault);

  const renderColums = () => {
    return catalogEquipments.map((row, item) => {
      const { _id, name } = row;
      return (
        <NavItem eventKey={_id} key={item}>
          {name}
          <div className='pull-right'>
            <Icon icon='chevron-right' />
          </div>
        </NavItem>
      );
    });
  };

  const renderColumsDetail = () => {
    return catalogEquipments.map((row, item) => {
      const { _id, listEquipments } = row;
      let detail = null;

      if (listEquipments.length > 0) {
        detail = listEquipments.map((equipment, itemEquiment) => {
          const {
            name,
            code,
            make,
            inventory,
            equipmentsCharacteristicsId: { listFieldsValues },
          } = equipment;

          const totalInventory = inventory.reduce((total, store) => (total += store.stock), 0);

          let recipe = '';

          listFieldsValues.map((field, index) => {
            if (field.stepId !== null) {
              recipe += `${field.stepId.step}: ${field.value}`;

              if (index < listFieldsValues.length - 1) {
                recipe += ', ';
              }
            }
          });

          return (
            <tr key={itemEquiment}>
              <td>
                <h5>
                  <CbBadge type='success'>{totalInventory}</CbBadge>
                </h5>
              </td>
              <td>
                <h5>
                  {name}{' '}
                  <small>
                    Código {code}, Marca {make}
                  </small>
                </h5>
                <small>{recipe}</small>
              </td>
              <td></td>
            </tr>
          );
        });
      } else {
        detail = <RowsNotFound message='No tiene stock de equipos.' colSpan={3} />;
      }

      return (
        <Tab.Pane eventKey={_id} key={item}>
          <h3 className='lead text-center'>
            <u>{row.name}</u>
          </h3>

          <table className='table table-condensed table-hover table-striped'>
            <thead>
              <tr>
                <th width='5%'>BODEGA</th>
                <th width='50%'>EQUIPO</th>
                <th className='text-center' width='20%'>
                  CARROS
                </th>
              </tr>
            </thead>
            <tbody>{detail}</tbody>
          </table>
        </Tab.Pane>
      );
    });
  };

  const onSelectColumn = (activeKey) => {
    setServiceId(activeKey);
  };

  let tabsContainer = { colums: null, detail: null };

  if (catalogEquipments.length > 0) {
    tabsContainer.colums = renderColums();
    tabsContainer.detail = renderColumsDetail();
  }

  return (
    <div>
      <Tab.Container id='equipments-services' defaultActiveKey={serviceId} onSelect={onSelectColumn}>
        <Row className='clearfix'>
          <Col sm={2}>
            <div className='text-center'>
              <span className='lead'>Linea</span>
            </div>
            <Nav bsStyle='pills' stacked>
              {tabsContainer.colums}
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content animation>{tabsContainer.detail}</Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

ListEquipments.propTypes = {
  serviceIdDefault: PropTypes.string,
  catalogEquipments: PropTypes.array.isRequired,
};

export default ListEquipments;
