import React from 'react';
import List from './List';

const Compras = () => {
  return <List />;
};

Compras.propTypes = {};

export default Compras;
