import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteServicios = ({ servicioId, name, labelServicio, loadServicios }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`servicios/${servicioId}`}
      description={`¿Confirma que desea eliminar el ${labelServicio.toLowerCase()} <strong><u> ${name}</u> </strong>?`}
      loadData={loadServicios}
    />
  );
};

DeleteServicios.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  labelServicio: PropTypes.string.isRequired,
  servicioId: PropTypes.string.isRequired,
  loadServicios: PropTypes.func.isRequired,
};

export default DeleteServicios;
