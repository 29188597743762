import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useStoreNotification } from '@stores/catalogs.store.js';

const NotificationComponent = () => {
  const { notificaciones, pullNotification, alertas, pullAlert } = useStoreNotification();
  useEffect(() => {
    if (alertas.length > 0) {
      const latestAlert = pullAlert();
      Swal.fire({
        title: latestAlert.message,
        icon: latestAlert.type,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [alertas]);
  useEffect(() => {
    if (notificaciones.length > 0) {
      const latestNotification = pullNotification();
      toast[latestNotification.type](latestNotification.message, {
        duration: 2000,
        position: 'top-right',
        style: {},
        className: '',
        // icon: '👏',
        // iconTheme: {
        //   primary: '#000',
        //   secondary: '#fff',
        // },
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
        removeDelay: 1000,
      });
    }
  }, [notificaciones]);
  return <ToastContainer />;
};

export default NotificationComponent;
