import { DivFormGroup, InputPassword } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty, isLength } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const ChangePassword = ({ idSellers, loadSellers }) => {
  const { addNotification: notification } = useStoreNotification();

  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setPassword('');
    setRepassword('');
  };

  const open = () => {
    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      password: password.trim(),
      repassword: repassword.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `sellers-password/${idSellers}`, body: data });
      loadSellers();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.password + '')) {
      validate.success = false;
      validate.message = 'Contraseña es requerido.';
    } else if (validate.success && !isLength(params.password + '', { min: 8, max: 10 })) {
      validate.message = 'Contraseña debe contener min 8 y max 10 caracteres.';
      validate.success = false;
    } else if (validate.success && isEmpty(params.repassword + '')) {
      validate.success = false;
      validate.message = 'Favor de confirmar la contraseña.';
    } else if (validate.success && !isLength(params.repassword + '', { min: 8, max: 10 })) {
      validate.message = 'Contraseña debe contener min 8 y max 10 caracteres.';
      validate.success = false;
    } else if (validate.success && params.password !== params.repassword) {
      validate.message = 'Contraseñas no coinciden. favor de verficar.';
      validate.success = false;
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onSave={handleEdit} onClose={close} isLoading={isLoading} title='Modificar contraseña'>
        <Row>
          <Col>
            <DivFormGroup name='password' title='Contraseña'>
              <InputPassword name='password' onChange={(e) => setPassword(e.target.value)} value={password} maxLength={10} />
              <small className='text-center'>Min 8 y Máx 10 caracteres</small>
            </DivFormGroup>
            <DivFormGroup name='repassword' title='Confirmación'>
              <InputPassword name='repassword' onChange={(e) => setRepassword(e.target.value)} value={repassword} maxLength={10} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

ChangePassword.propTypes = {
  idSellers: PropTypes.string.isRequired,
  loadSellers: PropTypes.func.isRequired,
};

export default ChangePassword;
