import React from 'react';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';

const TabClasification = ({ clasificacion, active, onClickClasificacion, onClickQuitarClasificacion }) => {
  const handleClickClasificacion = () => {
    onClickClasificacion(clasificacion);
  };

  const handleClickQuitarClasificacion = () => {
    onClickQuitarClasificacion();
  };

  return (
    <li role='presentation' className={active ? 'active' : ''}>
      <a href='#' onClick={handleClickClasificacion}>
        {clasificacion.nombre}
        <Icon icon='remove' style={{ paddingLeft: '2px', color: 'red', cursor: 'pointer' }} onClick={handleClickQuitarClasificacion} />
      </a>
    </li>
  );
};

TabClasification.propTypes = {
  clasificacion: PropTypes.array.isRequired,
  activeClasificacion: PropTypes.object,
  onClickClasificacion: PropTypes.func.isRequired,
  onClickQuitarClasificacion: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

export default TabClasification;
