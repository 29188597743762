import { Input } from '@controls';
import { Icon } from '@controls/Stateless';
import TextCurrency from '@controls/TextCurrency';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getImporteDetalle } from '@utils/cotizaciones';
import { cleanNumber } from '@utils/formatter';
import { hasPermission } from '@utils/permissions';
import CbTableTd from '@cbcomponents/CbTableTd';
import CbModalImage from '@cbcomponents/CbModalImage';
import ModalAlternativos from './ModalAlternativos';
import { getRequest } from '@utils/api';
import { calcularPrecioYMoneda } from '@utils/cotizaciones';
import { If } from '@controls';
import InputCurrency from '@components/forms/InputCurrency';
import InputNumber from '@components/forms/InputNumber';

const RowDetalleParte = ({ parte, onRemoveParte, onChangeParte, tipoCambio, monedaId }) => {
  const [showModalImagen, setShowModalImagen] = useState(false);
  const [imagen, setImagen] = useState('');

  const handleCloseImagen = () => {
    setShowModalImagen(false);
  };

  const handleChangeParte = (name, event) => {
    if (['cantidad', 'precio'].includes(name)) {
      parte[name] = cleanNumber(event.target.value);
    } else {
      parte[name] = event.target.value;
    }
    onChangeParte({
      ...parte,
    });
  };

  const handleChangePartePrecio = (event) => {
    parte.precio = cleanNumber(event.target.value);
    onChangeParte({
      ...parte,
      modifico_precio: true,
    });
  };

  const handleMostrarImagen = () => {
    setShowModalImagen(true);
    setImagen(parte?.imagen?.thumbnail);
  };

  const onCloseAlternativos = () => {
    onChangeParte({
      ...parte,
      showModalAlternativos: false,
    });
  };

  const handleOpenAlternativo = () => {
    onChangeParte({
      ...parte,
      showModalAlternativos: true,
    });
  };

  const onDoubleClickSeleccionadoAlternativo = async (itemSelect) => {
    const { data } = await getRequest({ url: `equipments/${itemSelect.equipoId._id}` });
    const producto = data;
    try {
      const costo = producto.cost;
      let precio = costo;
      let precio_original = precio;
      let measure = null,
        measureId = null;

      measure = producto?.equipmentsMeasureId?.measure;
      measureId = producto?.equipmentsMeasureId?._id;

      precio = producto.precio;
      precio_original = precio;
      producto.moneda_original = producto.moneda;
      const newDetalle = {
        ...parte,
        measureId: measureId,
        relacionadoId: producto._id,
        descripcion: producto.name.toUpperCase(),
        noIdentificador: producto.code.toUpperCase(),
        precio_original: precio_original,
        measure: measure ? measure.toUpperCase() : null,
        precio,
        cantidad: 1,
        descuento: 0,
        iva: producto.iva || 16,
        importe: precio,
        tiene_sustitutos: producto.listAlternos && producto.listAlternos.length > 0,
        tiene_otro_proveedor: producto.listCostosProveedores && producto.listCostosProveedores.length > 1,
      };

      onChangeParte({
        ...newDetalle,
        ...calcularPrecioYMoneda(newDetalle, monedaId, tipoCambio),
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <tr className={parte.status !== 'ACTIVO' ? 'danger' : ''} title={parte.status !== 'ACTIVO' ? 'Dado de baja o eliminado' : ''}>
        <td>&nbsp;</td>
        <CbTableTd>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                <TextCurrency>{parte.precio_original}</TextCurrency>
                &nbsp; {parte.moneda_original == 'PESO' ? 'MXN' : 'USD'}
              </Tooltip>
            }
          >
            <span>{parte.noIdentificador}</span>
          </OverlayTrigger>
        </CbTableTd>
        <CbTableTd>
          <OverlayTrigger placement='top' overlay={<Tooltip>{parte.descripcion}</Tooltip>}>
            <Input name='descripcion' className='input-sm td-input' value={parte.descripcion} onChange={(e) => handleChangeParte('descripcion', e)} />
          </OverlayTrigger>
        </CbTableTd>
        <CbTableTd>{parte.measureId?.measure}</CbTableTd>
        <CbTableTd>
          <InputNumber
            name='cantidad'
            disabled={!hasPermission('cambiar-cantidades-kits')}
            className='input-sm td-input'
            value={parte.cantidad || 0}
            onChange={(e) => handleChangeParte('cantidad', e)}
          />
        </CbTableTd>
        <CbTableTd isNumber>
          <InputCurrency
            name='precio'
            style={{ textAlign: 'right' }}
            className='input-sm td-input'
            value={parte.precio || 0}
            disabled={!hasPermission('cambiar-precios')}
            prefix='$ '
            decimalLimit={4}
            onChange={(e) => handleChangePartePrecio(e)}
          />
        </CbTableTd>
        <CbTableTd isNumber>
          <TextCurrency>{getImporteDetalle(parte)}</TextCurrency>
        </CbTableTd>
        <CbTableTd>
          <ButtonGroup className='pull-right'>
            <If condition={parte.tiene_sustitutos}>
              <Button variant='outline-primary' size='sm' onClick={handleOpenAlternativo} title='Cambiar alternativo'>
                <Icon icon='retweet' />
              </Button>
            </If>
            <If condition={parte.imagen?.thumbnail}>
              <Button variant='outline-primary' size='sm' onClick={handleMostrarImagen}>
                <Icon icon='eye-open' />
              </Button>
            </If>
            <Button variant='outline-danger' size='sm' onClick={onRemoveParte}>
              <Icon icon='remove' />
            </Button>
          </ButtonGroup>
        </CbTableTd>
      </tr>
      {showModalImagen && <CbModalImage show={showModalImagen} onClose={handleCloseImagen} imagen={imagen} />}
      {parte.showModalAlternativos && (
        <ModalAlternativos
          equipmentId={parte.relacionadoId}
          showModal={parte.showModalAlternativos}
          tipoCambio={tipoCambio}
          equipmentPrincipal={parte}
          onClose={onCloseAlternativos}
          onSelectAlternativo={onDoubleClickSeleccionadoAlternativo}
        />
      )}
    </>
  );
};

RowDetalleParte.propTypes = {
  parte: PropTypes.object,
  onRemoveParte: PropTypes.func,
  onChangeParte: PropTypes.func,
  tipoCambio: PropTypes.number,
  monedaId: PropTypes.string,
};

export default RowDetalleParte;
