import Joi from 'joi';
import { MESSAGES } from '@utils/validations';
// const R_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const cotizacionSchema = Joi.object({
  tipoLevantamientoId: Joi.string().required().label('Tipo de cotización'),
  numero_levantamiento: Joi.number().required().label('Número de cotización'),
  fecha: Joi.date().required().max('now').label('Fecha'),
  fecha_vencimiento: Joi.date().required().min(Joi.ref('fecha')).label('Fecha de vencimiento'),
  estatus_levantamiento: Joi.string().required().label('Estatus'),
  monedaId: Joi.string().required().label('Moneda'),
  tipoCambio: Joi.number().required().label('Tipo de cambio'),
  anticipo: Joi.number().min(0).max(100).required().label('Anticipo'),
  agenteId: Joi.string().required().label('Agente'),
  sucursalId: Joi.string().required().label('Sucursal'),
  clienteId: Joi.string().required().label('Cliente'),
  tipo_cliente: Joi.string().valid('CLIENTE', 'PROSPECTO').required().label('Tipo de cliente'),
  observaciones: Joi.string().allow('').label('Observaciones'),
  domicilioId: Joi.when('tipo_cliente', {
    is: 'CLIENTE',
    then: Joi.string().required().label('Domicilio'),
    otherwise: Joi.any().strip(),
  }),
  detalles: Joi.array()
    .items(
      Joi.object({
        _id: Joi.string().label('Detalle'),
        relacionadoId: Joi.string().required().label('Equipo'),
        tipo_partida: Joi.string().required().label('Tipo partida'),
        descripcion: Joi.string().required().label('Descripción'),
        measureId: Joi.string().required().label('Unidad'),
        precio: Joi.number().label('Precio'),
        cantidad: Joi.number().greater(0).required().label('Cantidad'),
        descuento: Joi.number().min(0).max(100).required().label('Descuento'),
        proveedorId: Joi.string().label('Proveedor'),
        iva: Joi.number().min(0).max(100).required().label('IVA'),
        listWorkForcesSetted: Joi.array().items(
          Joi.object({
            _id: Joi.string().required().label('Workforce'),
            level: Joi.number().required().label('Level'),
            cantidad: Joi.number().required().label('Cantidad'),
            value: Joi.number().required().label('Value'),
            type: Joi.string().required().label('Type'),
          })
        ),
        partes: Joi.array()
          .items(
            Joi.object({
              _id: Joi.string().label('Detalle'),
              relacionadoId: Joi.string().required().label('Equipo'),
              tipo_partida: Joi.string().required().label('Tipo partida'),
              descripcion: Joi.string().required().label('Descripción'),
              measureId: Joi.string().required().label('Unidad'),
              precio: Joi.number().label('Precio'),
              cantidad: Joi.number().greater(0).required().label('Cantidad'),
              iva: Joi.number().min(0).max(100).required().label('IVA'),
              proveedorId: Joi.string().label('Proveedor'),
              listWorkForcesSetted: Joi.array().items(
                Joi.object({
                  _id: Joi.string().required().label('Workforce'),
                  level: Joi.number().required().label('Level'),
                  value: Joi.number().required().label('Value'),
                  cantidad: Joi.number().required().label('Cantidad'),
                  type: Joi.string().required().label('Type'),
                })
              ),
            })
          )
          .when('tipo_partida', { is: 'KIT', then: Joi.required() })
          .label('Partes'),
      })
    )
    .min(1)
    .required()
    .label('Detalles'),
}).messages(MESSAGES);

export function validateSave(cotizacion) {
  return cotizacionSchema.validate(cotizacion);
}
