import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import EditVisita from './EditVisita';
import NewVisita from './NewVisita';

const OrdenesServicioVisitas = (props) => {
  const [state, setState] = useState({
    resources: [],
    list: [],
    loading: true,
    checkEnviarCopia: true,
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
    fechaInicio: moment().format('YYYY-MM-DD 00:00:00'),
    fechaFin: moment().format('YYYY-MM-DD 23:59:59'),
    storehouses: [],
    clientesAEnviar: [],
    showModal: false,
    enviados: false,
    tipos_ordenes_servicios: [],
    agentes: [],
    estatus_ordenes: {
      CANCELADA: 'CANCELADA',
      PAUSADA: 'PAUSADA',
      FINALIZADA: 'FINALIZADA',
      INICIADA: 'INICIADA',
      EN_PROCESO: 'EN PROCESO',
    },
    estatus: [],
    estatus_orden_servicio: '0',
    tipoOrdenServicioId: '0',
    sucursalId: '0',
    agenteId: '0',
    agenteAsignadoId: '0',
    sucursales: [],
    inList: 'CALENDARIO',
    agenda: [],
    ordenServicioId: null,
    visitaId: null,
    showModalNew: false,
    listAgentes: [],
    loadingCatalogos: false,
  });

  const handleSearch = useCallback(async () => {
    const {
      pagination,
      activePage,
      search,
      fechaFin,
      fechaInicio,
      estatus_orden_servicio,
      tipoOrdenServicioId,
      sucursalId,
      agenteId,
      agenteAsignadoId,
    } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
      estatus: estatus_orden_servicio,
      tipoOrdenServicioId,
      sucursalId,
      agenteId,
      agenteAsignadoId,
    };
    const { data, count } = await postRequest({ url: `ordenes-servicios-visitas/search`, params: { page, limit: pagination }, body });
    let agentes = state.listAgentes.map((x) => ({ ...x, color: '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0') }));

    const agenda = data.reduce((a, visita) => {
      const visitas = visita.agentes_asignados
        ? visita.agentes_asignados.map((agnt) => {
            const agentePrincipal = agentes.find((x) => x._id === agnt._id);
            return {
              visitaId: visita._id,
              resourceId: agentePrincipal?._id,
              ordenServicioId: visita.ordenServicioId._id,
              id: visita.ordenServicioId._id + '-' + visita._id,
              backgroundColor: agentePrincipal?.color,
              title:
                'OT-' +
                visita.ordenServicioId.numero_orden_servicio +
                ' ' +
                visita.ordenServicioId.cliente.razon_social +
                ' ' +
                (visita.ordenServicioId.referencia || ''),
              start: moment(visita.fecha_hora).format(),
              end: visita.fecha_hora_fin
                ? moment(visita.fecha_hora_fin).format()
                : moment(visita.fecha_hora).add(visita.horas_servicio, 'hours').format(),
              description: visita.ordenServicioId.cliente.razon_social,
            };
          })
        : [];
      return [...a, ...visitas];
    }, []);

    setState((prevState) => ({
      ...prevState,
      resources: agentes.filter((x) => x.es_tecnico).map((x) => ({ id: x._id, title: x.nombre })),
      agenda,
      list: data,
      count: count,
      loading: false,
    }));
  }, [state]);

  const loadCatalogos = useCallback(async (callback = () => {}) => {
    const {
      data: { sucursales, agente, agentes, tipos_ordenes_servicios },
    } = await getRequest({ url: `ordenes-servicio-catalogos` });
    setState((prevState) => ({
      ...prevState,
      loadingCatalogos: false,
      listAgentes: agentes,
      tipos_ordenes_servicios: [
        {
          value: '0',
          label: 'Todas los tipos ordenes de servicios',
        },
        ...tipos_ordenes_servicios.map((tipo_orden_servicio) => ({
          value: tipo_orden_servicio._id,
          label: `${tipo_orden_servicio.nombre}`,
        })),
      ],
      sucursales: [
        {
          value: '0',
          label: 'Todas las sucursales',
        },
        ...sucursales.map((sucursal) => ({
          value: sucursal._id,
          label: `${sucursal.name}`,
        })),
      ],
      agentes: [
        {
          value: '0',
          label: 'Todos los agentes',
        },
        ...agentes.map((agente) => ({
          value: agente._id,
          label: `${agente.nombre}`,
        })),
      ],
      estatus: [
        {
          value: '0',
          label: 'Todos los estatus',
        },
        ...Object.entries(prevState.estatus_ordenes).map(([key, value]) => ({
          value: key,
          label: value,
        })),
      ],
      agente: agente,
      agenteId: agente ? agente._id : null,
      sucursalId: agente ? agente.sucursalId : null,
      almacenId: agente ? agente.almacenId : null,
    }));
    callback();
  }, []);

  useEffect(() => {
    loadCatalogos(handleSearch);
  }, [loadCatalogos, handleSearch]);

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }));
    handleSearch();
  };

  const handleEvents = (events) => {
    setState((prevState) => ({
      ...prevState,
      currentEvents: events,
    }));
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      showModalNew: false,
      visitaId: null,
      ordenServicioId: null,
    }));
  };

  const handleEventClick = (clickInfo) => {
    const [ordenServicioId, visitaId] = clickInfo.event.id.split('-');
    setState((prevState) => ({
      ...prevState,
      showModal: true,
      visitaId: visitaId,
      ordenServicioId: ordenServicioId,
    }));
  };

  const handleDateSelect = (selectInfo) => {
    const agentes = state.listAgentes.filter((agt) => agt._id === selectInfo.resource.id);
    setState((prevState) => ({
      ...prevState,
      showModalNew: true,
      new_agentes: agentes,
      fecha_hora: selectInfo.startStr,
      fecha_hora_fin: selectInfo.endStr,
    }));
  };

  const handleChangeDate = (params) => {
    if (state.fechaInicio !== params.startStr || state.fechaFin !== params.endStr) {
      setState((prevState) => ({
        ...prevState,
        activePage: 1,
        fechaInicio: params.startStr,
        fechaFin: params.endStr,
      }));
      handleSearch();
    }
  };

  const { agenda, pagination, count, activePage, resources, loadingCatalogos } = state;
  return (
    <Module title='Ordenes de servicio'>
      <Loading loading={loadingCatalogos}>
        <form>
          <>
            <div className='panel'>
              <div className='panel-body'>
                <FullCalendar
                  schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                  locales={[esLocale]}
                  locale='es'
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,resourceTimeGridDay',
                  }}
                  events={agenda}
                  resources={resources}
                  initialView='resourceTimeGridDay'
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={true}
                  select={handleDateSelect}
                  // eventContent={renderEventContent} // custom render function
                  eventClick={handleEventClick}
                  eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                  datesSet={handleChangeDate}
                  // eventAdd={handleEventNew}
                  /* you can update a remote database when these fire:
                  eventRemove={function(){}}
                  */
                />
              </div>
            </div>
          </>
          {state.showModal && <EditVisita {...props} {...state} loadData={handleSearch} close={closeModal} />}
          {state.showModalNew && <NewVisita {...props} {...state} loadData={handleSearch} agentes={state.new_agentes} close={closeModal} />}
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </form>
      </Loading>
    </Module>
  );
};

OrdenesServicioVisitas.propTypes = {};

export default OrdenesServicioVisitas;
