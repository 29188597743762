import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const MultiSelectCuentaMonitoreoCliente = ({ onChange, name, value, options, isDisabled }) => {
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      name={name}
      value={value}
      isDisabled={isDisabled}
      options={options}
      isMulti
      placeholder='Seleccione un contacto'
      getOptionLabel={(x) => `${x.numero} ${x.nombre}`}
      getOptionValue={(x) => x._id}
      onChange={onSelect}
      styles={{ container: (base) => ({ ...base, flex: 'auto' }) }}
      isClearable
    />
  );
};


MultiSelectCuentaMonitoreoCliente.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
};

export default MultiSelectCuentaMonitoreoCliente;
