import PropTypes from 'prop-types';
import { hasPermission } from '@utils/permissions';

const IfPermission = ({ action, path, children }) => {
  if (hasPermission(action, path) && children) return children;
  return null;
};

IfPermission.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.string.isRequired,
  path: PropTypes.string,
};

export default IfPermission;
