import { DivFormGroup } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { clean } from '@utils/formatter';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const Edit = ({ data, itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [fecha, setFecha] = useState('');
  const [valor, setValor] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setFecha('');
    setValor('');
  };

  const open = () => {
    const { fechaString, valor } = data;
    setFecha(fechaString);
    setValor(valor);
    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      fecha,
      valor: clean(valor),
    };

    if (validations(data)) {
      await putRequest({ url: `tipo-cambio/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    const now = new Date();
    const f = params.fecha.split('-');
    const fechaParse = new Date(f[0], f[1] - 1, f[2]);

    if (isEmpty(params.fecha + '')) {
      validate.success = false;
      validate.message = 'Fecha es requerida.';
    } else if (validate.success && moment(now).isSameOrBefore(fechaParse)) {
      validate.success = false;
      validate.message = 'Fecha no puede ser mayor al día de hoy.';
    } else if (validate.success && isEmpty(params.valor + '')) {
      validate.success = false;
      validate.message = 'Valor es requerido.';
    } else if (validate.success && params.valor <= 0) {
      validate.success = false;
      validate.message = 'Valor debe ser mayor a cero.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='fecha' title='Fecha' required>
              <input type='date' id='fecha' name='fecha' className='form-control' value={fecha} disabled />
            </DivFormGroup>
            <DivFormGroup name='valor' title='Valor' required>
              <InputNumber
                name='valor'
                placeholder='Valor'
                className='form-control'
                onChange={(e) => setValor(e.target.value)}
                defaultValue={valor}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Edit;
