import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectCliente from '@components/forms/SelectCliente';
import SelectCreateOrUpdateDomicilio from '@components/forms/SelectCreateOrUpdateDomicilio';
import SelectCreateOrUpdateContacto from '@components/forms/SelectCreateOrUpdateContacto';

const New = ({ refresh }) => {
  const { addNotification: notification } = useStoreNotification();
  const [cliente, setCliente] = useState(null);
  const [domicilio, setDomicilio] = useState(null);
  const [contacto, setContacto] = useState(null);
  const [folio, setFolio] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [domicilios, setDomicilios] = useState([]);
  const [contactos, setContactos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const close = () => {
    setShowModal(false);
    setCliente(null);
    setFolio('');
    setDescripcion('');
    setDomicilio(null);
    setContacto(null);
    setDomicilios([]);
    setContactos([]);
  };

  const open = () => {
    setShowModal(true);
    ObtenerNumeroSolicitudCompra();
  };

  const ObtenerNumeroSolicitudCompra = async () => {
    setLoading(true);
    const { data } = await getRequest({ url: `customers-registros/siguienteNumero` });
    setFolio(data.numero);
    setLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      folio,
      descripcion,
      customerId: cliente?._id,
      domicilioId: domicilio?._id,
      contactoId: contacto?._id,
    };
    setLoading(true);
    if (validations(data)) {
      await postRequest({ url: `customers-registros`, body: data });
      refresh();
      close();
    }
    setLoading(false);
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.folio + '')) {
      validate.success = false;
      validate.message = 'Folio es requerido.';
    } else if (isEmpty(params.descripcion + '')) {
      validate.success = false;
      validate.message = 'Descripción es requerido.';
    } else if (!isMongoId(params.customerId + '')) {
      validate.success = false;
      validate.message = 'Cliente es requerido.';
    } else if (!isMongoId(params.domicilioId + '')) {
      validate.success = false;
      validate.message = 'Domicilio es requerido.';
    } else if (!isMongoId(params.contactoId + '')) {
      validate.success = false;
      validate.message = 'Contacto es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      const listContactos = itemSelect.listContactos.map(x => ({ ...x, tipos: x.tipos.map(x => x.tipoContactoId) }));
      setCliente(itemSelect);
      setDomicilios(itemSelect.listDomicilios);
      setContactos(listContactos);
      setDomicilio(itemSelect.listDomicilios.length === 1 ? itemSelect.listDomicilios[0] : null);
      setContacto(listContactos.length === 1 ? listContactos[0] : null);
    } else {
      setCliente(null);
      setDomicilio(null);
      setContacto(null);
      setDomicilios([]);
      setContactos([]);
    }
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal title='Nuevo' show={showModal} onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col md={4}>
            <DivFormGroup name='folio' title='Folio'>
              <Input name='folio' onChange={(e) => setFolio(e.target.value)} value={folio} placeholder='Folio' />
            </DivFormGroup>
          </Col>
          <Col md={8}>
            <DivFormGroup name='descripcion' title='Descripción'>
              <Input name='descripcion' onChange={(e) => setDescripcion(e.target.value)} value={descripcion} placeholder='Descripción' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='clienteName' required title='Cliente'>
              <SelectCliente value={cliente} onChange={onChangeCliente} name='clienteState' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SelectCreateOrUpdateDomicilio
              value={domicilio}
              name='contacto'
              onChange={(value) => setDomicilio(value)}
              setDomicilios={(value) => setDomicilios(value)}
              customerId={cliente?._id}
              domicilios={domicilios}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectCreateOrUpdateContacto
              value={contacto}
              name='contacto'
              contactos={contactos}
              customerId={cliente?._id}
              setContactos={(value) => setContactos(value)}
              onChange={(value) => setContacto(value)}
            />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  refresh: PropTypes.func.isRequired,
};

export default New;
