import React, { useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import CbTableResponsive from '@controls/CbTableResponsive';
import CbTableBody from '@controls/CbTableBody';
import CbTableTd from '@controls/CbTableTd';
import PropTypes from 'prop-types';
import { DivFormGroup, FormInput } from '@controls';
import { useStoreTiposPedidos, useStoreAlmacenes } from '@stores/catalogs.store';

const FormPedido = ({ tipoPedidoId, numero_pedido, almacen, isLoadingTable, detalles_equipos, onSelectTipoPedido, onChange, onSelect }) => {
  const getStoreTiposPedidos = useStoreTiposPedidos();
  const getStoreAlmacenes = useStoreAlmacenes();
  useEffect(() => {
    getStoreTiposPedidos.execute();
    getStoreAlmacenes.execute();
  }, []);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <h3>Pedido</h3>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <DivFormGroup name='tipoPedidoId' required title='Tipo pedido'>
              <Select
                id='tipoPedidoId'
                value={tipoPedidoId}
                name='tipoPedidoId'
                placeholder='Seleccionar'
                options={getStoreTiposPedidos.data}
                getOptionLabel={(x) => x.nombre}
                getOptionValue={(x) => x._id}
                onChange={onSelectTipoPedido}
              />
            </DivFormGroup>
          </Col>
          <Col sm={4}>
            <FormInput title='Número de pedido' placeholder='Num. de pedido' name='numero_pedido' onChange={onChange} value={numero_pedido} />
          </Col>
          <Col sm={4}>
            <DivFormGroup name='almacen' required title='Almacen'>
              <Select
                id='almacen'
                value={almacen}
                name='almacen'
                placeholder='Seleccionar'
                options={getStoreAlmacenes.data}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x._id}
                onChange={(selectedOption) => onSelect('almacen', selectedOption)}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </Card.Body>
      <CbTableResponsive>
        <thead>
          <tr>
            <th width='10%'>#</th>
            <th>Descripción</th>
            <th width='10%'>Unidad</th>
            <th className='text-right'>Cantidad</th>
            <th className='text-right'>Existencia</th>
          </tr>
        </thead>
        <CbTableBody colSpan={6} loading={isLoadingTable}>
          {detalles_equipos.map((detalle, i) => (
            <tr key={i}>
              <CbTableTd>{detalle.codigo}</CbTableTd>
              <CbTableTd>{detalle.descripcion}</CbTableTd>
              <CbTableTd>{detalle.unidadMedida.nombre}</CbTableTd>
              <CbTableTd
                isNumber
                style={{
                  color: detalle.existencia < detalle.cantidad ? 'red' : 'green',
                }}
              >
                <b>{detalle.cantidad}</b>
              </CbTableTd>
              <CbTableTd isNumber>{detalle.existencia}</CbTableTd>
            </tr>
          ))}
        </CbTableBody>
      </CbTableResponsive>
    </Card>
  );
};

FormPedido.propTypes = {
  tipoPedidoId: PropTypes.object,
  tipos_pedidos: PropTypes.array,
  numero_pedido: PropTypes.string,
  almacen: PropTypes.object,
  almacenes: PropTypes.array,
  isLoadingTable: PropTypes.bool,
  detalles_equipos: PropTypes.array,
  onSelectTipoPedido: PropTypes.func,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
};

export default FormPedido;
