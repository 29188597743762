import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CbDomicilio from '@cbcomponents/CbDomicilio';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';
import { Confirmation } from '@controls';
import useAuth from '@hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const RowVisitasPorAtender = ({ a }) => {
  const navigate = useNavigate();
  const { geolocation } = useAuth();
  const { ordenServicioId } = a;
  const { registro_cliente } = ordenServicioId;
  return (
    <tr>
      <td>
        <span className='badge badge-phoenix badge-phoenix-primary'>Orden #{ordenServicioId.numero_orden_servicio}</span>
        <span className='pull-right'>
          <Icon icon='time' /> {moment(a.fecha_hora).local().format('YYYY-MM-DD HH:mm')}
        </span>
        <br />
        {ordenServicioId.cliente && ordenServicioId.cliente.razon_social}
        <br />
        <b>{a.observaciones}</b>
        <br />
        <Icon icon='user' style={{ color: 'green' }} />{' '}
        {registro_cliente &&
          registro_cliente.contactoId &&
          `${registro_cliente.contactoId.name} ${registro_cliente.contactoId.paterno} ${registro_cliente.contactoId.materno} (${registro_cliente.contactoId.phone})`}
        <br />
        <CbDomicilio address={ordenServicioId.domicilio_entrega} />
        <span className='pull-right'>
          <span className='badge badge-phoenix badge-phoenix-primary'>{a.estatus}</span>
        </span>
        {registro_cliente && registro_cliente.contactoId && (
          <>
            <a
              className='btn btn-success btn-sm mr-5'
              href={`https://api.whatsapp.com/send?phone=52${registro_cliente.contactoId.phone}&text=Voy en camino a realizar el servicio`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Icon icon='comment' /> Enviar a WhatsApp
            </a>
            <a className='btn btn-primary btn-sm mr-5' href={`tel:${registro_cliente.contactoId.phone}`} target='_blank' rel='noopener noreferrer'>
              <Icon icon='phone' /> Llamar
            </a>
          </>
        )}
        {a.estatus !== 'PENDIENTE' ? (
          <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Ir a orden</Tooltip>}>
            <Link className='btn btn-warning btn-sm mr-5' to={`/ordenes-servicios-visitas/observaciones/${a._id}`}>
              <Icon icon='copy' />
            </Link>
          </OverlayTrigger>
        ) : (
          <Confirmation
            id={a._id}
            btnIcon='play'
            btnTitle='Iniciar'
            typeConfirm='success'
            btnType='success btn-sm mr-5'
            action='Iniciar visita'
            url={`ordenes-servicios-visitas/iniciar/${ordenServicioId._id}/${a._id}?latitude=${geolocation?.latitude}&longitude=${geolocation?.longitude}`}
            description={`¿Confirma que desea iniciar la <strong>visita: <u> ${a.observaciones}</u></strong>?`}
            loadData={() => {
              navigate(`/ordenes-servicios-visitas/observaciones/${a._id}`);
            }}
          />
        )}
      </td>
    </tr>
  );
};

RowVisitasPorAtender.propTypes = {
  a: PropTypes.object,
  i: PropTypes.number,
};

export default RowVisitasPorAtender;
