import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [definicion, setDefinicion] = useState('');
  const [clave, setClave] = useState('');
  const [reqPanel, setReqPanel] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setDefinicion('');
    setClave('');
    setReqPanel(true);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === 'definicion') setDefinicion(value);
    if (name === 'clave') setClave(value);
    if (name === 'req_panel') setReqPanel(type === 'checkbox' ? checked : value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      definicion: definicion.trim(),
      clave: clave.trim(),
      req_panel: reqPanel,
    };

    if (validations(data)) {
      await postRequest({ url: 'soluciones_solicitadas', body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.clave + '')) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.definicion + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal title='Agregar' show={showModal} onClose={close} onSubmit={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput title='Clave' placeholder='Clave' required name='clave' onChange={onChange} value={clave} />
          </Col>
          <Col>
            <FormInput title='Nombre' placeholder='Nombre' required name='definicion' onChange={onChange} value={definicion} />
          </Col>
          <Col>
            <div className='checkbox'>
              <label className='form-check-label'>
                <input type='checkbox' onChange={onChange} checked={reqPanel} name='req_panel' className='form-check-input' />{' '}
                <strong>Requiere panel</strong>
              </label>
            </div>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  load: PropTypes.func.isRequired,
};

export default New;
