import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Icon, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';
import Select from '@components/forms/Select';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAlmacen from '@components/forms/SelectAlmacen';

const Edit = ({ id, tipoCompraId, tiposPartidas, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [descripcion, setDescripcion] = useState('');
  const [nombre, setNombre] = useState('');
  const [codigo, setCodigo] = useState('');
  const [tipoPartidaId, setTipoPartidaId] = useState(null);
  const [numeroCompraActual, setNumeroCompraActual] = useState(1);
  const [tieneSucursal, setTieneSucursal] = useState(false);
  const [sucursalId, setSucursalId] = useState(null);
  const [tieneAlmacen, setTieneAlmacen] = useState(false);
  const [almacenId, setAlmacenId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tipoPartida, setTipoPartida] = useState({});

  const close = () => setShowModal(false);

  const open = async () => {
    const {
      data: { descripcion, nombre, tipoPartidaId, numero_compra_actual, tiene_sucursal, sucursalId, tiene_almacen, almacenId, codigo },
    } = await getRequest({ url: `tipo-compra/${tipoCompraId}` });
    setShowModal(true);
    setDescripcion(descripcion);
    setNombre(nombre);
    setCodigo(codigo);
    setTipoPartidaId(tipoPartidaId);
    setNumeroCompraActual(numero_compra_actual);
    setTieneSucursal(tiene_sucursal);
    setSucursalId(sucursalId);
    setTieneAlmacen(tiene_almacen);
    setAlmacenId(almacenId);
    setIsLoading(false);
    setTipoPartida({ ...tiposPartidas.find((x) => x._id === tipoPartidaId) });
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      descripcion: descripcion.trim().toUpperCase(),
      codigo: codigo.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      numero_compra_actual: numeroCompraActual,
      tiene_sucursal: tieneSucursal,
      sucursalId,
      tiene_almacen: tieneAlmacen,
      almacenId,
      tipoPartidaId,
    };

    if (validations(data)) {
      await putRequest({ url: `tipos-compras/${id}`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (params.tipoPartidaId === null) {
      validate.success = false;
      validate.message = 'Tipo de partida es requerido.';
    } else if (isEmpty(params.descripcion + '')) {
      validate.success = false;
      validate.message = 'Descripción es requerida.';
    } else if (params.numero_compra_actual === 0) {
      validate.success = false;
      validate.message = 'Número de compra debe ser mayor a cero.';
    } else if (params.tiene_almacen && !params.almacenId) {
      validate.success = false;
      validate.message = 'Almacén es requerido.';
    } else if (params.tiene_sucursal && !params.sucursalId) {
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onSelect = (campo, value) => {
    let newTieneAlmacen = tieneAlmacen;
    let newAlmacenId = almacenId;
    let newTipoPartida = tipoPartida;

    if (campo === 'tipoPartidaId') {
      if (value) {
        newTipoPartida = { ...tiposPartidas.find((x) => x._id === value) };
        if (newTipoPartida.usa_almacen) {
          newTieneAlmacen = false;
          newAlmacenId = null;
        }
      } else {
        newTipoPartida = {};
      }
    }

    setTieneAlmacen(newTieneAlmacen);
    setAlmacenId(newAlmacenId);
    setTipoPartida(newTipoPartida);
    setTipoPartidaId(value);
  };

  return (
    <Fragment>
      <OverlayTrigger placement='top' overlay={<Tooltip id={id}>Editar</Tooltip>}>
        <Button onClick={open} className='btn btn-sm btn-outline-primary' type='button'>
          <Icon icon='pencil' />
        </Button>
      </OverlayTrigger>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Tipo de Compra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEdit}>
            <Row>
              <Col sm='4'>
                <FormInput title='Código' placeholder='Código' required name='codigo' onChange={(e) => setCodigo(e.target.value)} value={codigo} />
              </Col>
              <Col sm='4'>
                <FormInput title='Nombre' placeholder='Nombre' required name='nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
              </Col>
              <Col sm='4'>
                <DivFormGroup name='tipo_partida' title='Tipo partida' required>
                  <Select
                    name='tipoPartidaId'
                    value={tipoPartidaId}
                    options={tiposPartidas.map((tipoPartida) => ({
                      value: tipoPartida._id,
                      label: `${tipoPartida.nombre}`,
                    }))}
                    onChange={(value) => onSelect('tipoPartidaId', value)}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <FormInput
                  title='Descripción'
                  placeholder='Descripción'
                  required
                  name='descripcion'
                  onChange={(e) => setDescripcion(e.target.value)}
                  value={descripcion}
                />
              </Col>
            </Row>
            {tipoPartida.usa_almacen ? (
              <Row>
                <Col sm='6'>
                  <DivFormGroup name='tiene_sucursal' title='Sucursal' required={tieneSucursal}>
                    <div className='input-group'>
                      <span className='input-group-text'>
                        <input type='checkbox' checked={tieneSucursal} name='tiene_sucursal' onChange={(e) => setTieneSucursal(e.target.checked)} />
                      </span>
                      <SelectSucursal value={sucursalId} onChange={(value) => setSucursalId(value)} disabled={!tieneSucursal} />
                    </div>
                  </DivFormGroup>
                </Col>
                <Col sm='6'>
                  <DivFormGroup name='tiene_almacen' title='Almacén' required={tieneAlmacen}>
                    <div className='input-group'>
                      <span className='input-group-text'>
                        <input
                          type='checkbox'
                          disabled={!tieneSucursal}
                          checked={tieneAlmacen}
                          name='tiene_almacen'
                          onChange={(e) => setTieneAlmacen(e.target.checked)}
                        />
                      </span>
                      <SelectAlmacen
                        name='almacenId'
                        value={almacenId}
                        onChange={(value) => setAlmacenId(value)}
                        disabled={!tieneAlmacen}
                        filter={(x) => !x.sucursalId || x.sucursalId === sucursalId?._id}
                      />
                    </div>
                  </DivFormGroup>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col sm='12'>
                <FormInput
                  type='number'
                  title='Número compra'
                  required
                  name='numero_compra_actual'
                  onChange={(e) => setNumeroCompraActual(e.target.value)}
                  value={numeroCompraActual}
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <button onClick={handleEdit} className='btn btn-primary pull-right' disabled={isLoading}>
            Guardar
          </button>
          <button onClick={close} disabled={isLoading} className='btn btn-default pull-right mr-5' type='button'>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  tipoCompraId: PropTypes.number.isRequired,
  tiposPartidas: PropTypes.array.isRequired,
  almacenes: PropTypes.array.isRequired,
  sucursales: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default Edit;
