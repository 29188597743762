import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import FormRuletaAsesores from './FormRuletaAsesores';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { socketManager } from '../../../../socketManager';
import { useStoreNotification } from '@stores/catalogs.store';

const NuevoAsesor = ({ loadRuleta, agente }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nombre, setNombre] = useState('');
  const [tipo, setTipo] = useState('');
  const [fecha, setFecha] = useState(moment().format('YYYY-MM-DD'));
  const [fechaVisita, setFechaVisita] = useState(moment().format('YYYY-MM-DD'));
  const [asesorAsignado, setAsesorAsignado] = useState(null);
  const [comparteComisionCon, setComparteComisionCon] = useState(null);
  const [solucionesSolicitadasId, setSolucionesSolicitadasId] = useState([]);
  const [telefono, setTelefono] = useState('');
  const [correoElectronico, setCorreoElectronico] = useState('');
  const [asesorAtendio, setAsesorAtendio] = useState(agente);
  const [medioComunicacion, setMedioComunicacion] = useState(null);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setNombre('');
    setTipo('');
    setFecha(moment().format('YYYY-MM-DD'));
    setFechaVisita(moment().format('YYYY-MM-DD'));
    setAsesorAsignado(null);
    setComparteComisionCon(null);
    setSolucionesSolicitadasId([]);
    setTelefono('');
    setCorreoElectronico('');
    setAsesorAtendio(agente);
    setMedioComunicacion(null);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const data = {
      nombre,
      tipo: tipo ? tipo.value : '',
      medio_comunicacion: medioComunicacion ? medioComunicacion.value : null,
      telefono,
      correo_electronico: correoElectronico,
      soluciones_solicitadas: solucionesSolicitadasId.map((x) => x.value),
      asesor_asignado: asesorAsignado ? asesorAsignado.value : null,
      comparte_comision_con: comparteComisionCon ? comparteComisionCon.value : null,
      asesor_atendio: asesorAtendio ? asesorAtendio.value : null,
      fecha: fecha ? moment(fecha, 'YYYY-MM-DD').utc() : '',
      fecha_visita: fechaVisita ? moment(fechaVisita, 'YYYY-MM-DD').utc() : null,
    };

    if (validations(data)) {
      const { notificacion } = await postRequest({ url: 'ruleta-asesores', body: data });
      if (notificacion) {
        socketManager.emit('NOTIFICATION', notificacion);
      }
      setShowModal(false);
      loadRuleta();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.tipo + '')) {
      validate.success = false;
      validate.message = 'Tipo cliente es requerido.';
    } else if (isEmpty(params.soluciones_solicitadas + '')) {
      validate.success = false;
      validate.message = 'Soluciones solicitadas es requerido.';
    } else if (!isMongoId(params.asesor_atendio + '')) {
      validate.success = false;
      validate.message = 'Asesor atendió es requerido.';
    } else if (isEmpty(params.fecha + '')) {
      validate.success = false;
      validate.message = 'Fecha es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onSave={handleRegister} onClose={close} isLoading={isLoading} title='Agregar ruleta'>
        <FormRuletaAsesores
          nombre={nombre}
          tipo={tipo}
          fecha={fecha}
          fechaVisita={fechaVisita}
          asesorAsignado={asesorAsignado}
          comparteComisionCon={comparteComisionCon}
          solucionesSolicitadasId={solucionesSolicitadasId}
          telefono={telefono}
          correoElectronico={correoElectronico}
          asesorAtendio={asesorAtendio}
          medioComunicacion={medioComunicacion}
          setNombre={setNombre}
          setTipo={setTipo}
          setFecha={setFecha}
          setFechaVisita={setFechaVisita}
          setAsesorAsignado={setAsesorAsignado}
          setComparteComisionCon={setComparteComisionCon}
          setSolucionesSolicitadasId={setSolucionesSolicitadasId}
          setTelefono={setTelefono}
          setCorreoElectronico={setCorreoElectronico}
          setAsesorAtendio={setAsesorAtendio}
          setMedioComunicacion={setMedioComunicacion}
        />
      </CbModal>
    </>
  );
};

NuevoAsesor.propTypes = {
  loadRuleta: PropTypes.func.isRequired,
  agente: PropTypes.object,
};

export default NuevoAsesor;
