import { OXXO, SPEI, TARJETA } from '@config/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { RowsNotFound } from '@stateless';
import { parseAmountStripe } from '@utils/parseCost';
import DetailCustomer from '@components/customers/DetailCustomer';

const TableTransations = ({ listTransations }) => {
  const renderConekta = (row, item) => {
    const { order, customerId, source, facturas } = row;
    let logo = '';

    if (source === OXXO || source === 'OXXO - PAY') {
      logo = 'oxxopay_brand.png';
    } else if (source === SPEI) {
      logo = 'spei_brand.png';
    } else if (source === TARJETA) {
      if (order.data.object.payment_method.brand === 'visa') {
        logo = 'visa.png';
      } else if (order.data.object.payment_method.brand === 'american_express') {
        logo = 'americanexpress.png';
      } else if (
        order.data.object.payment_method.brand === 'mastercard' ||
        order.data.object.payment_method.brand === 'master_card' ||
        order.data.object.payment_method.brand === 'mc'
      ) {
        logo = 'mastercard.png';
      }
    }

    let paid_at = null;
    if (source === TARJETA) {
      paid_at = order.data.object.paid_at * 1000;
    } else {
      paid_at = order.data.object.paid_at * 1000;
    }

    return (
      <tr key={item}>
        <td>
          {order.id}
          {typeof facturas !== 'undefined' ? (
            facturas.length > 0 ? (
              <ul className='list-unstyled'>
                <li>
                  Facturas <br />
                  <strong>
                    {facturas.map((f, i) => (
                      <span key={i}>
                        <Link to={`/facturas/detalle/${f._id}`}>
                          {f.serie}
                          {f.numero}{' '}
                        </Link>
                        {i !== facturas.length - 1 ? ', ' : ''}{' '}
                      </span>
                    ))}
                  </strong>
                </li>
              </ul>
            ) : null
          ) : null}
        </td>
        <td>
          <span className='label label-success'>Pagado</span>
        </td>
        <td>
          <DetailCustomer customer={customerId} />
        </td>
        <td>
          <img src={`./img/${logo}`} width='40px' alt='' className='img-thumbnail img-responsive' />
        </td>
        <td>
          <strong>$ {parseAmountStripe(order.data.object.amount)}</strong>
        </td>
        <td>
          <div className='pull-right'>
            <small>{moment(paid_at).local().format('DD/MM/YYYY')}</small>
          </div>
        </td>
      </tr>
    );
  };

  const renderRows = (listTransations) => {
    return listTransations.map((row, item) => renderConekta(row, item));
  };

  let rows = null;

  if (listTransations.length > 0) {
    rows = renderRows(listTransations);
  } else {
    rows = <RowsNotFound message='Aún no hay Movimientos registrados.' colSpan={6} />;
  }

  return (
    <div className='panel panel-default panel-table table-responsive'>
      <table className='table table-condensed table-hover table-striped'>
        <thead>
          <tr>
            <th width='25%'>Clave</th>
            <th width='15%'></th>
            <th width='25%'>Propietario</th>
            <th width='10%'>Tipo</th>
            <th width='10%'>Monto</th>
            <th width='15%'>
              <span className='pull-right'>Fecha</span>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

TableTransations.propTypes = {
  listTransations: PropTypes.array.isRequired,
};

export default TableTransations;
