import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Row, Col, Nav, NavItem } from 'react-bootstrap';
import { RowsNotFound } from '@stateless';

const ZERO = 0;
const MINIMUN = 50;
const MAXIMUN = 100;

const ListEquipments = ({ serviceIdDefault, catalogEquipments }) => {
  const [serviceId, setServiceId] = useState(serviceIdDefault);

  const renderColums = () => {
    return catalogEquipments.map((row, item) => {
      const { _id, name } = row;

      return (
        <NavItem eventKey={_id} key={item}>
          {name}
        </NavItem>
      );
    });
  };

  const switchColorLabelStock = (stock) => {
    let color = 'default';

    if (stock <= ZERO) {
      color = 'danger';
    } else if (stock > ZERO && stock <= MINIMUN) {
      color = 'warning';
    } else if (stock > MINIMUN && stock <= MAXIMUN) {
      color = 'success';
    } else {
      color = 'primary';
    }

    return color;
  };

  const renderColumsDetail = () => {
    return catalogEquipments.map((row, item) => {
      const { _id, listEquipments } = row;

      let detail = null;

      if (listEquipments.length > 0) {
        detail = listEquipments.map((equipment, itemEquiment) => {
          const {
            name,
            code,
            make,
            stock,
            equipmentsCharacteristicsId: { listFieldsValues },
          } = equipment;

          const color = switchColorLabelStock(stock);

          let recipe = '';

          listFieldsValues.map((field, index) => {
            if (field.stepId !== null) {
              recipe += `${field.stepId.step}: ${field.value}`;

              if (index < listFieldsValues.length - 1) {
                recipe += ', ';
              }
            }
          });

          return (
            <tr key={itemEquiment}>
              <td>
                <span className={`label label-${color}`}>{stock}</span>
              </td>
              <td>
                <span className={`text-${color}`}>
                  {name}{' '}
                  <small>
                    Código {code}, Marca {make}
                  </small>
                </span>
                <p>
                  <small>{recipe}</small>
                </p>
              </td>
              <td></td>
            </tr>
          );
        });
      } else {
        detail = <RowsNotFound message='No tiene existencia de equipos.' colSpan={3} />;
      }

      return (
        <Tab.Pane eventKey={_id} key={item}>
          <table className='table table-condensed table-hover table-striped'>
            <thead>
              <tr>
                <th width='20%'>STOCK</th>
                <th width='60%'>EQUIPO</th>
                <th width='20%'></th>
              </tr>
            </thead>
            <tbody>{detail}</tbody>
          </table>
        </Tab.Pane>
      );
    });
  };

  const onSelectColumn = (activeKey) => {
    setServiceId(activeKey);
  };

  let tabsContainer = { colums: null, detail: null };

  if (catalogEquipments.length > 0) {
    tabsContainer.colums = renderColums();
    tabsContainer.detail = renderColumsDetail();
  }

  return (
    <div>
      <Tab.Container id='equipments-services' defaultActiveKey={serviceId} onSelect={onSelectColumn}>
        <Row className='clearfix'>
          <Col sm={3}>
            <Nav bsStyle='pills' stacked>
              {tabsContainer.colums}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content animation>{tabsContainer.detail}</Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

ListEquipments.propTypes = {
  sellerId: PropTypes.string.isRequired,
  serviceIdDefault: PropTypes.string,
  catalogEquipments: PropTypes.array.isRequired,
  equipmentAssinated: PropTypes.array.isRequired,
};

export default ListEquipments;
