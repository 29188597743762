import React from 'react';
import PropTypes from 'prop-types';

const InputPassword = ({ name, className= 'form-control', style, maxLength, placeholder, disabled, readOnly, value, defaultValue, onChange }) => {
  const nameClass = className.match('form-control') ? className : `form-control ${className}`;

  return (
    <input
      type='password'
      id={name}
      name={name}
      ref={name}
      className={nameClass}
      style={style}
      maxLength={maxLength}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};


export default InputPassword;
