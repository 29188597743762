import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import TableTransations from './TableTransations';

const ListTransations = () => {
  const [listTransations, setListTransations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');
  const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    handleSearch();
  }, [activePage, search, fechaInicio, fechaFin]);

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
    };
    const { data, count } = await postRequest({ url: `transations/search`, params: { page, limit: pagination }, body });
    setListTransations(data);
    setCount(count);
    setLoading(false);
  };

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
    setFechaInicio(params.fechaInicio);
    setFechaFin(params.fechaFin);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  const renderList = (listTransations) => {
    return <TableTransations listTransations={listTransations} />;
  };

  return (
    <Module title='Movimientos'>
      <div className='row'>
        <div className='col-sm-12'>
          <Search onSearch={onSearch} withDatetime></Search>
        </div>
      </div>
      <Loading loading={loading}>
        {renderList(listTransations)}
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

ListTransations.propTypes = {};

export default ListTransations;
