import { MONEDA, TIPO_SERVICIOS } from '@config/constants';
import { DivFormGroup, Input, TextArea } from '@controls';
import React, { useState, useEffect } from 'react';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import RowPrecio from './components/RowPrecio';
import { Col, Row } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import InputCurrency from '@components/forms/InputCurrency';

const EditServices = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    configurations: [],
    configurationId: '',
    recipes: [],
    recipeId: '',
    recipe: null,
    codeService: '',
    nameService: '',
    description: '',
    moneda: MONEDA.PESO,
    adicional: false,
    service: null,
    measures: [],
    sucursales: [],
    listas_precios: [],
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { serviceId } = params;
    const { data, measures, sucursales, listas_precios } = await getRequest({ url: `services/${serviceId}` });
    const listasPrecios = data.listasPreciosUnidades || [];
    setState((prevState) => ({
      ...prevState,
      service: {
        ...data,
        listasPreciosUnidades: measures.map((x) => {
          const measure = listasPrecios.find((j) => j.unidadId === x._id) || { listasPrecios: [] };
          return {
            unidad: x,
            precio: measure && measure.precio ? measure.precio : 0,
            descuento: measure && measure.descuento ? measure.descuento : 0,
            listasPrecios: listas_precios.map((y) => {
              const listaPrecio = measure.listasPrecios.find((k) => k.listaPrecioId === y._id);
              return {
                listaPrecio: y,
                precio: listaPrecio?.precio ? listaPrecio.precio : 0,
                descuento: listaPrecio?.descuento ? listaPrecio.descuento : 0,
              };
            }),
          };
        }),
        listCost: measures.map((x) => {
          const measure = data.listCost.find((j) => j.measureId === x._id) || { sucursales: [] };
          return {
            measure: x,
            cost: measure && measure.cost ? measure.cost : 0,
            sucursales: sucursales.map((y) => {
              const sucursal = measure.sucursales.find((k) => k.sucursalId === y._id);
              return {
                sucursal: y,
                cost: sucursal && sucursal.cost ? sucursal.cost : 0,
              };
            }),
          };
        }),
      },
      measures,
      listas_precios,
      sucursales,
    }));
  };

  const resetForm = () => {
    setTimeout(() => navigate('/servicios'), 1000);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { serviceId } = params;
    const {
      service: { name, description, code, tipo, adicional, moneda, listCost, listasPreciosUnidades },
    } = state;
    const data = {
      serviceId,
      code: code,
      name: name,
      description: description,
      tipo,
      moneda,
      adicional,
      listCost: listCost.map((x) => ({
        ...x,
        cost: Number(x.cost),
        sucursales: x.sucursales.map((y) => ({
          ...y,
          cost: Number(y.cost),
        })),
      })),
      listasPreciosUnidades: listasPreciosUnidades.map((x) => ({
        unidadId: x.unidad._id,
        precio: Number(x.precio),
        listasPrecios: x.listasPrecios.map((y) => ({
          listaPrecioId: y.listaPrecio._id,
          precio: Number(y.precio),
        })),
      })),
    };

    await putRequest({ url: `services/${serviceId}`, body: data });
    resetForm();
  };

  const onChangeCosto = (index, e) => {
    setState((prevState) => ({
      ...prevState,
      service: {
        ...prevState.service,
        listCost: prevState.service.listCost.map((x, i) => {
          if (i === index)
            return {
              ...x,
              cost: cleanNumber(e.target.value),
            };
          return x;
        }),
      },
    }));
  };

  const onChangeCostoSucursal = (indexI, indexJ, e) => {
    setState((prevState) => ({
      ...prevState,
      service: {
        ...prevState.service,
        listCost: prevState.service.listCost.map((x, i) => {
          return {
            ...x,
            sucursales: x.sucursales.map((y, j) => {
              if (i === indexI && j === indexJ) {
                return {
                  ...y,
                  cost: cleanNumber(e.target.value),
                };
              }
              return y;
            }),
          };
        }),
      },
    }));
  };

  const onChangePrecioUnidad = (index, e) => {
    setState((prevState) => ({
      ...prevState,
      service: {
        ...prevState.service,
        listasPreciosUnidades: prevState.service.listasPreciosUnidades.map((x, i) => {
          if (i === index)
            return {
              ...x,
              precio: cleanNumber(e.target.value),
            };
          return x;
        }),
      },
    }));
  };

  const onChangePrecioListaPreciosUnidad = (indexI, indexJ, e) => {
    setState((prevState) => ({
      ...prevState,
      service: {
        ...prevState.service,
        listasPreciosUnidades: prevState.service.listasPreciosUnidades.map((x, i) => {
          return {
            ...x,
            listasPrecios: x.listasPrecios.map((y, j) => {
              if (i === indexI && j === indexJ) {
                return {
                  ...y,
                  precio: cleanNumber(e.target.value),
                };
              }
              return y;
            }),
          };
        }),
      },
    }));
  };

  const renderPrecio = (i, s) => {
    return (
      <tr key={i}>
        <td>{s.measure.measure}</td>
        <td>
          <InputCurrency name={`measureId-${s.measure._id}`} className='form-control' value={s.cost} onChange={(e) => onChangeCosto(i, e)} />
        </td>
        {s.sucursales.map((x, j) => (
          <td key={j}>
            <InputCurrency
              name={`measureId-${x._id}-${s._id}`}
              className='form-control'
              value={x.cost}
              onChange={(e) => onChangeCostoSucursal(i, j, e)}
            />
          </td>
        ))}
      </tr>
    );
  };

  const renderTypeServicesRecipe = () => {
    const {
      service: { code, name, description, tipo, moneda, listCost, listasPreciosUnidades },
      isLoading,
      sucursales,
      listas_precios,
    } = state;
    return (
      <Module onClickBack={window.history.back} parent='Servicios' title='Editar Servicio'>
        <form onSubmit={handleRegister}>
          <div className='panel panel-default'>
            <div className='panel-body'>
              <Row>
                <Col sm='6'>
                  <DivFormGroup name='code' required title='Código'>
                    <Input
                      name='code'
                      onChange={(e) => setState({ ...state, service: { ...state.service, code: e.target.value } })}
                      value={code}
                      placeholder='Código'
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='6'>
                  <DivFormGroup name='name' required title='Nombre'>
                    <Input
                      name='name'
                      onChange={(e) => setState({ ...state, service: { ...state.service, name: e.target.value } })}
                      value={name}
                      placeholder='Nombre del servicio'
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='12'>
                  <DivFormGroup name='description' required title='Características'>
                    <small> Descripción complementaria</small>
                    <TextArea
                      name='description'
                      onChange={(e) => setState({ ...state, service: { ...state.service, description: e.target.value } })}
                      value={description}
                      placeholder='Características'
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='6'>
                  <div className='form-group inline'>
                    <label>Tipo de Servicio</label>
                    <div>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='tipo'
                          onChange={(e) => setState({ ...state, service: { ...state.service, tipo: e.target.value } })}
                          value={TIPO_SERVICIOS.MONITOREO}
                          checked={tipo === TIPO_SERVICIOS.MONITOREO}
                        />{' '}
                        Monitoreo
                      </label>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='tipo'
                          onChange={(e) => setState({ ...state, service: { ...state.service, tipo: e.target.value } })}
                          value={TIPO_SERVICIOS.INTERACTIVO}
                          checked={tipo === TIPO_SERVICIOS.INTERACTIVO}
                        />{' '}
                        Interactivo
                      </label>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='tipo'
                          onChange={(e) => setState({ ...state, service: { ...state.service, tipo: e.target.value } })}
                          value={TIPO_SERVICIOS.MANO_OBRA}
                          checked={tipo === TIPO_SERVICIOS.MANO_OBRA}
                        />{' '}
                        Mano de obra
                      </label>
                    </div>
                  </div>
                </Col>
                <Col sm='3'>
                  <div className='form-group inline'>
                    <label>Moneda</label>
                    <div>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='moneda'
                          onChange={(e) => setState({ ...state, service: { ...state.service, moneda: e.target.value } })}
                          value={MONEDA.PESO}
                          checked={moneda === MONEDA.PESO}
                        />{' '}
                        Peso
                      </label>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='moneda'
                          onChange={(e) => setState({ ...state, service: { ...state.service, moneda: e.target.value } })}
                          value={MONEDA.DOLAR}
                          checked={moneda === MONEDA.DOLAR}
                        />{' '}
                        Dolar
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Row>
            <Col sm='12'>
              <div className='panel panel-default'>
                <div className='panel-heading'>
                  <h2 className='panel-title'>
                    <Icon icon='usd' /> Precios
                  </h2>
                </div>
                <table className='table table-condensed table-hover table-striped'>
                  <thead>
                    <tr>
                      <th width='30%'>Medida</th>
                      <th width='20%' className='text-center'>
                        Precio
                      </th>
                      {sucursales.map((x, i) => (
                        <th key={i} width='20%' className='text-center'>
                          {x.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{listCost.map((s, i) => renderPrecio(i, s))}</tbody>
                </table>
                <div className='panel-footer'></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <div className='panel panel-default'>
                <div className='panel-heading'>
                  <h2 className='panel-title'>
                    <Icon icon='usd' /> Listas de Precios
                  </h2>
                </div>
                <table className='table table-condensed table-hover table-striped'>
                  <thead>
                    <tr>
                      <th width='20%'>Medida</th>
                      <th colSpan='2' className='text-center'>
                        Precio
                      </th>
                      {listas_precios.map((x, i) => (
                        <th key={i} colSpan='2' className='text-center'>
                          {x.nombre}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {listasPreciosUnidades.map((s, i) => (
                      <RowPrecio
                        key={i}
                        indexI={i}
                        listaPrecioMeasure={s}
                        onChangePrecioUnidad={onChangePrecioUnidad}
                        onChangePrecioListaPreciosUnidad={onChangePrecioListaPreciosUnidad}
                      />
                    ))}
                  </tbody>
                </table>
                <div className='panel-footer'></div>
              </div>
            </Col>
          </Row>
          <div>
            <div className='panel'>
              <div className='panel-footer'>
                <Row>
                  <Col sm='12'>
                    <Saving saving={isLoading} />
                    <button type='submit' className='btn btn-info pull-right' disabled={isLoading}>
                      <Icon icon='floppy-disk' /> Guardar
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </form>
      </Module>
    );
  };

  const { service } = state;

  return service ? renderTypeServicesRecipe() : <Loading />;
};

EditServices.propTypes = {};

export default EditServices;
