import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { DivFormGroup } from '@controls';
import { useStoreNotification } from '@stores/catalogs.store';

const EditModule = ({ idCatModule, sort: sortInit, icon: iconInit, module: moduleInit, loadCatalog }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    showModal: false,
    sort: sortInit,
    name: moduleInit,
    icon: iconInit,
    isLoading: false,
  });

  const close = () => {
    setState({ ...state, showModal: false });
  };

  const open = () => {
    setState({
      ...state,
      showModal: true,
      sort: sort,
      icon: icon,
      name: moduleInit,
    });
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const { sort, name, icon } = state;

    const data = {
      sort: sort,
      name: name.trim(),
      icon: icon.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `modules/${idCatModule}`, body: data });
      loadCatalog();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.sort.toString())) {
      validate.success = false;
      validate.message = 'Orden es requerido.';
    } else if (params.sort <= 0) {
      validate.success = false;
      validate.message = 'Orden debe ser mayor a cero.';
    } else if (isEmpty(params.name)) {
      validate.success = false;
      validate.message = 'Nombre del módulo es requerido.';
    } else if (isEmpty(params.icon)) {
      validate.success = false;
      validate.message = 'Icono es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  const { name, icon, sort, showModal, isLoading } = state;
  return (
    <>
      <CbButtonOver icon='pencil' onClick={open} title='Editar' type='primary' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='sort' title='Orden' required>
              <input type='number' className='form-control' name='sort' onChange={(e) => setState({ ...state, sort: e.target.value })} value={sort} />
            </DivFormGroup>
            <DivFormGroup name='sort' title='Nombre del módulo' required>
              <input type='text' className='form-control' name='name' onChange={(e) => setState({ ...state, name: e.target.value })} value={name} />
            </DivFormGroup>
            <DivFormGroup name='sort' title='Icono' required>
              <input type='text' className='form-control' name='icon' onChange={(e) => setState({ ...state, icon: e.target.value })} value={icon} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditModule.propTypes = {
  id: PropTypes.number.isRequired,
  sort: PropTypes.number.isRequired,
  idCatModule: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  loadCatalog: PropTypes.func.isRequired,
};

export default EditModule;
