import PropTypes from 'prop-types';
import React from 'react';
import CbCustomPagination from '@cbcomponents/CbCustomPagination';

const CbPanelTableResponsive = ({ children, title, activePage, count, pagination, onPagination, onChangeItemsPerPagina }) => {
  let header = null;
  if (title) {
    header = (
      <div className='panel-heading'>
        <h2 className='panel-title'>{title}</h2>
      </div>
    );
  }
  return (
    <div className='panel panel-default panel-table'>
      {header}
      <div className='table-responsive'>
        <table className='table table-condensed table-hover dataTable table-with-row-buttons'>{children}</table>
      </div>
      {pagination && (
        <div className='panel-footer'>
          <CbCustomPagination
            activePage={activePage}
            count={count}
            pagination={pagination}
            onPagination={onPagination}
            onChangeItemsPerPagina={onChangeItemsPerPagina}
          />
        </div>
      )}
    </div>
  );
};

CbPanelTableResponsive.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  pagination: PropTypes.number,
  activePage: PropTypes.number,
  count: PropTypes.number,
  onPagination: PropTypes.func,
  onChangeItemsPerPagina: PropTypes.func,
};

const CbTableHeader = ({ children }) => (
  <thead>
    <tr>{children}</tr>
  </thead>
);

CbTableHeader.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
};

CbPanelTableResponsive.Header = CbTableHeader;

export default CbPanelTableResponsive;
