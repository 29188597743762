import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';

const Cancel = ({ itemId, loadData, notaCredito }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nota, setNota] = useState('');

  const close = () => {
    setShowModal(false);
    setNota('');
  };

  const open = () => {
    setShowModal(true);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nota') {
      setNota(value);
    }
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    const body = {
      nota,
      notaCreditoId: itemId,
    };

    if (validations(body)) {
      await putRequest({ url: `cancelar-nota-credito/${itemId}`, body });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.notaCreditoId + '')) {
      validate.success = false;
      validate.message = 'Nota de credito es requerida.';
    } else if (validate.success && isEmpty(params.nota + '')) {
      validate.success = false;
      validate.message = 'Observaciones son requeridas.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const { serie, numero } = notaCredito;

  return (
    <>
      <CbButtonOver title='Cancelar' icon='remove' onClick={open} hiddenXs type='danger btn-sm' />
      <CbModal title='Confirmación' show={showModal} onClose={close} onSave={handleCancel}>
        <p className='lead'>
          <Icon icon='warning-sign' />
          &nbsp;¿Confirma que desea <strong>Cancelar</strong> la nota de credito{' '}
          <strong>
            {serie}
            {numero}
          </strong>
        </p>
        <Row>
          <Col>
            <DivFormGroup name='nota' title='Observaciones'>
              <textarea id='nota' name='nota' className='form-control' rows='5' onChange={onChange} value={nota}></textarea>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Cancel.propTypes = {
  id: PropTypes.number.isRequired,
  notaCredito: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Cancel;
