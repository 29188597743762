import React from 'react';
import RowFichaTecnica from './RowFichaTecnica';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import PropTypes from 'prop-types';

const TableFichaTecnica = ({ fichasTecnicas, onChangeFichasTecnicas }) => {
  const onChange = (idx, workforce) => {
    fichasTecnicas[idx] = workforce;
    onChangeFichasTecnicas([...fichasTecnicas]);
  };

  return (
    <CbPanelTableResponsive title='Ficha técnica'>
      <thead>
        <tr>
          <th width='80%'>Ficha</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>
        {fichasTecnicas.map((r, i) => (
          <RowFichaTecnica key={i} fichaTecnica={r} onChange={() => onChange(i, r)} />
        ))}
      </CbTableBody>
    </CbPanelTableResponsive>
  );
};

TableFichaTecnica.propTypes = {
  fichasTecnicas: PropTypes.array,
  onChangeFichasTecnicas: PropTypes.func,
};

export default TableFichaTecnica;
