import { AUTORIZADA, CANCELADA, DECLINADA, FINALIZADA, INICIADA } from '@config/constants';
import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';
import ImprimirLevantamiento from '@pages/clientes/cotizaciones/ImprimirLevantamiento';

const RowCotizacion = ({ cotizacion }) => {
  const switchEstado = (estado) => {
    let text = '';
    let color = 'secondary';
    if (estado === INICIADA) {
      text = 'Iniciada';
      color = 'secondary';
    } else if (estado === AUTORIZADA) {
      text = 'Autorizada';
      color = 'success';
    } else if (estado === FINALIZADA) {
      text = 'Finalizada';
      color = 'success';
    } else if (estado === DECLINADA) {
      text = 'Declinada';
      color = 'warning';
    } else if (estado === CANCELADA) {
      text = 'Cancelada';
      color = 'danger';
    }
    return <CbBadge type={color}>{text}</CbBadge>;
  };

  const { key, _id, numero_levantamiento, importe, fecha, fecha_vencimiento, sucursal, estado } = cotizacion;

  return (
    <tr>
      <td>{numero_levantamiento}</td>
      <td>{sucursal.name}</td>
      <td>{moment(fecha).local().format('DD/MM/YYYY HH:mm')}</td>
      <td>{moment(fecha_vencimiento).local().format('DD/MM/YYYY HH:mm')}</td>
      <td>${format(importe, 2)}</td>
      <td>{switchEstado(estado)}</td>
      <td>
        <ImprimirLevantamiento id={key} numero_levantamiento={numero_levantamiento} levantamientoId={_id} />
        <RedirectTooltip
          id={key}
          labelTooltip='Editar de Cotización'
          url={`/cotizaciones/edicion/${_id}`}
          icon='edit'
          className='btn btn-sm btn-success mr-5'
        />
        <RedirectTooltip id={key} icon='search' url={`/cotizaciones/${_id}`} labelTooltip='Completa' className='btn btn-sm btn-primary ' />
      </td>
    </tr>
  );
};

RowCotizacion.propTypes = {
  cotizacion: PropTypes.object.isRequired,
};

export default RowCotizacion;
