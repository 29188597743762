import React, { useState, useEffect } from 'react';
import { Module, Icon } from '@stateless';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Negociacion from './components/Negociacion';
import { Row, Col, Card } from 'react-bootstrap';
import TextCurrency from '@controls/TextCurrency';
import { useStoreNotification } from '@stores/catalogs.store';
import { getRequest } from '@utils/api';
import useAuth from '@hooks/useAuth';
import { socketManager } from '../../../../socketManager';

// const reorder = (list, startIndex, endIndex) => {
//   const result = Array.from(list);
//   const [removed] = result.splice(startIndex, 1);
//   result.splice(endIndex, 0, removed);
//   return result;
// };

// const move = (source, destination, droppableSource, droppableDestination) => {
//   const sourceClone = Array.from(source);
//   const destClone = Array.from(destination);
//   const [removed] = sourceClone.splice(droppableSource.index, 1);
//   destClone.splice(droppableDestination.index, 0, removed);
//   const result = {};
//   result[droppableSource.droppableId] = sourceClone;
//   result[droppableDestination.droppableId] = destClone;
//   return result;
// };

// const negociacionesInit = [
//   {
//     _id: '5f5b3b3b3b3b3b3b3b3b3b3b',
//     title: 'Negociación 1',
//     content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     step: 1,
//     createdAt: '2020-09-11T00:00:00.000Z',
//     updatedAt: '2020-09-11T00:00:00.000Z',
//     prospecto: 'Jose Perez Leon',
//     agente: 'Juan Rodriguez',
//     amount: 1000,
//     comentarios: [
//       {
//         _id: '5f5b3b3b3b3b3b3b3b3b3b3b',
//         title: 'Actividad 1',
//         content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//         etapa: 1,
//         createdAt: '2020-09-11T00:00:00.000Z',
//         updatedAt: '2020-09-11T00:00:00.000Z',
//       },
//       {
//         _id: '5f5b3b3b3b3b3b3b3b3b3b3b',
//         title: 'Actividad 2',
//         content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//         etapa: 1,
//         createdAt: '2020-09-11T00:00:00.000Z',
//         updatedAt: '2020-09-11T00:00:00.000Z',
//       },
//     ],
//   },
//   {
//     _id: '5f5b3b3b3b3b3b3b3b3b3c',
//     title: 'Negociación 2',
//     content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
//     step: 2,
//     createdAt: '2020-09-12T00:00:00.000Z',
//     updatedAt: '2020-09-12T00:00:00.000Z',
//     prospecto: 'Maria Lopez',
//     agente: 'Juan Rodriguez',
//     amount: 2000,
//     comentarios: [],
//   },
//   {
//     _id: '5f5b3b3b3b3b3b3b3b3d',
//     title: 'Negociación 3',
//     content: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
//     step: 3,
//     createdAt: '2020-09-13T00:00:00.000Z',
//     updatedAt: '2020-09-13T00:00:00.000Z',
//     prospecto: 'Carlos Martinez',
//     agente: 'Juan Rodriguez',
//     amount: 3000,
//     comentarios: [],
//   },
//   {
//     _id: '5f5b3b3b3b3b3b3b3b3e',
//     title: 'Negociación 4',
//     content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
//     step: 4,
//     createdAt: '2020-09-14T00:00:00.000Z',
//     updatedAt: '2020-09-14T00:00:00.000Z',
//     prospecto: 'Ana Gonzalez',
//     agente: 'Juan Rodriguez',
//     amount: 4000,
//     comentarios: [],
//   },
//   {
//     _id: '5f5b3b3b3b3b3b3b3b3f',
//     title: 'Negociación 5',
//     content: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa.',
//     step: 1,
//     createdAt: '2020-09-15T00:00:00.000Z',
//     updatedAt: '2020-09-15T00:00:00.000Z',
//     prospecto: 'Luis Ramirez',
//     agente: 'Juan Rodriguez',
//     amount: 5000,
//     comentarios: [],
//   },
//   {
//     _id: '5f5b3b3b3b3b3b3b3b40',
//     title: 'Negociación 6',
//     content: 'Mollit anim id est laborum. Lorem ipsum dolor sit amet.',
//     step: 2,
//     createdAt: '2020-09-16T00:00:00.000Z',
//     updatedAt: '2020-09-16T00:00:00.000Z',
//     prospecto: 'Laura Fernandez',
//     agente: 'Juan Rodriguez',
//     amount: 6000,
//     comentarios: [],
//   },
//   {
//     _id: '5f5b3b3b3b3b3b3b3b41',
//     title: 'Negociación 7',
//     content: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
//     step: 3,
//     createdAt: '2020-09-17T00:00:00.000Z',
//     updatedAt: '2020-09-17T00:00:00.000Z',
//     prospecto: 'Miguel Torres',
//     agente: 'Juan Rodriguez',
//     amount: 7000,
//     comentarios: [],
//   },
//   {
//     _id: '5f5b3b3b3b3b3b3b3b42',
//     title: 'Negociación 8',
//     content: 'Ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
//     step: 4,
//     createdAt: '2020-09-18T00:00:00.000Z',
//     updatedAt: '2020-09-18T00:00:00.000Z',
//     prospecto: 'Sofia Hernandez',
//     agente: 'Juan Rodriguez',
//     amount: 8000,
//     comentarios: [],
//   },
// ];

const Negociaciones = () => {
  const { agente } = useAuth();
  const { addNotification: notification } = useStoreNotification();
  const generateRandomId = () => Math.random().toString(36).substr(2, 9);

  const [steps, setSteps] = useState([]);
  const [items, setItems] = useState([]);
  const kamban = [
    ...steps.map((step) => ({
      ...step,
      items: items.filter((item) => item.pasoId?._id === step.id),
    })),
  ];
  useEffect(() => {
    loadData();
  }, [agente]);

  const loadData = async () => {
    const { data: stepsData } = await getRequest({ url: 'negociaciones-pasos' });
    const { data: negociaciones } = await getRequest({ url: `negociaciones/agente/${agente?._id}` });
    const stepsInit = stepsData.map((step) => ({ ...step, id: step._id, title: step.titulo, items: [] }));
    // socketManager.emit('negociaciones_pasos:save', { step: 1, id: generateRandomId(), title: 'PROSPECTOS', items: [] });
    // socketManager.emit('negociaciones_pasos:save', { step: 2, id: generateRandomId(), title: 'COTIZANDO', items: [] });
    // socketManager.emit('negociaciones_pasos:save', { step: 3, id: generateRandomId(), title: 'CIERRES', items: [] });
    // socketManager.emit('negociaciones_pasos:save', { step: 4, id: generateRandomId(), title: 'DECLINACIONES', items: [] });
    const items = negociaciones.map((item) => ({
      ...item,
      id: generateRandomId(),
      content: item.contenido,
      title: item.titulo,
      step: item.step,
      amount: item.monto,
      prospecto: item.prospecto,
      comentarios: item.comentarios,
    }));
    setItems(items);
    setSteps([...stepsInit]);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId !== destination.droppableId) {
      let stepKamba = kamban.find((x) => x.id === source.droppableId);
      let step = steps.find((x) => x.id === destination.droppableId);
      let item = stepKamba.items.find((x, idx) => idx === source.index);
      socketManager.emit('negociaciones:update', { negociacionId: item._id, ...item, pasoId: step._id });
      setItems((prevState) => prevState.map((x) => (x.id === item.id ? { ...x, pasoId: step } : x)));
    }
  };

  const onRemoveItem = (idx, index, neg) => {
    socketManager.emit('negociaciones:delete', { negociacionId: neg._id });
    setItems((prevState) => prevState.filter((x) => x.id !== neg.id));
    notification({ title: 'Atención', message: '¡Tarea eliminada!', type: 'success' });
  };

  const onAddItem = (idx, step, e) => {
    e.preventDefault();
    socketManager.emit('negociaciones:save', {
      titulo: 'Algo',
      contenido: 'Algo',
      pasoId: step.id,
      monto: 0,
      agenteId: agente?._id,
    });
    setSteps((prevState) => [
      ...prevState.map((x, i) => {
        if (i === idx) {
          return {
            ...x,
            items: [
              ...x.items,
              {
                id: generateRandomId(),
                title: '',
                content: '',
                step: x.id,
                amount: 0,
                prospecto: '',
                comentarios: [],
              },
            ],
          };
        }
        return x;
      }),
    ]);
    notification({ title: 'Atención', message: '¡Tarea agregada!', type: 'success' });
  };

  const onChange = (idx, index, neg) => {
    socketManager.emit('negociaciones:update', { negociacionId: neg._id, ...neg });
    setItems((prevState) => [
      ...prevState.map((x, i) => {
        if (i === index) {
          return neg;
        }
        return x;
      }),
    ]);
  };

  const onChangeTitle = (idx, e) => {
    const { value } = e.target;
    setSteps((prevState) => [
      ...prevState.map((x, i) => {
        if (i === idx) {
          return { ...x, title: value };
        }
        return x;
      }),
    ]);
  };

  // const onRemoveList = (name, e) => {
  //   e.preventDefault();
  //   const { items, names } = state;
  //   delete items[name];
  //   delete names[name];
  //   setState({
  //     items: { ...items },
  //     names: { ...names },
  //   });
  //   notification({ title: 'Atención', message: '¡Lista eliminada!', type: 'success' });
  // };

  const renderList = (step, idx) => {
    return (
      <Col lg={3} md={4} sm={6} key={step.id}>
        <Card>
          <Card.Header>
            <Card.Title>
              <input
                value={step.title}
                name='title'
                className='form-control'
                style={{
                  background: 'transparent',
                  border: 'none',
                  padding: '0px',
                  boxShadow: 'none',
                  fontSize: '22px',
                  height: '22px',
                  fontWeight: '700',
                }}
                onChange={(e) => onChangeTitle(idx, e)}
              />
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text>{step.items.length} negociaciones</Card.Text>
            <h4>
              <TextCurrency>{step.items.map((x) => x.amount).reduce((a, b) => a + b, 0)}</TextCurrency>
            </h4>
          </Card.Body>
          <Card.Body>
            <Droppable droppableId={step.id}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {step.items.map((item, index) => (
                    <Negociacion
                      key={index}
                      item={item}
                      index={index}
                      onRemoveItem={() => onRemoveItem(idx, index, item)}
                      onChange={(neg) => onChange(idx, index, neg)}
                    />
                  ))}
                  {provided.placeholder}
                  <a href='#' className='btn btn-block btn-default' onClick={(e) => onAddItem(idx, step, e)}>
                    <Icon icon='plus' /> Nueva negociación
                  </a>
                </div>
              )}
            </Droppable>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <Module title='Inicio'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row>{kamban.map(renderList)}</Row>
      </DragDropContext>
    </Module>
  );
};

Negociaciones.propTypes = {};

export default Negociaciones;
