import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';
import { format } from '@utils/parseCost';
import { IfPermission } from '@controls';

const IndicadoresVentas = ({
  loading,
  clientes,
  prospectos,
  cotizacionesCount,
  cotizacionesImporte,
  pagos,
  pagosImporte,
  facturas,
  facturasImporte,
  servicios,
  serviciosImporte,
  documentosPagos,
  documentosPagosImporte,
}) => {
  return (
    <Row>
      <Col>
        <Row>
          <IfPermission action='clientes'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-info'>
                <div className='inner'>
                  <p>CLIENTES / PROSPECTOS</p>
                  <h3>
                    {!loading ? clientes : '-'} / {!loading ? prospectos : '-'}
                  </h3>
                  <h3>&nbsp;</h3>
                </div>
                <div className='icon'>
                  <Icon icon='user' />
                </div>
                <Link to='/clientes' className='small-box-footer'>
                  Ver más <Icon icon='circle-arrow-right' />
                </Link>
              </div>
            </Col>
          </IfPermission>
          <IfPermission action='ventas'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-default'>
                <div className='inner'>
                  <p>COTIZACIONES</p>
                  <h3>{!loading ? cotizacionesCount : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(cotizacionesImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <Icon icon='list' />
                </div>
                <Link to='/cotizaciones' className='small-box-footer'>
                  Ver más <Icon icon='circle-arrow-right' />
                </Link>
              </div>
            </Col>
          </IfPermission>
          <IfPermission action='ventas'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-primary-2'>
                <div className='inner'>
                  <p>PAGOS CONEKTA</p>
                  <h3>{!loading ? pagos : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(pagosImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <Icon icon='user' />
                </div>
                <Link to='/cotizaciones' className='small-box-footer'>
                  Ver más <Icon icon='circle-arrow-right' />
                </Link>
              </div>
            </Col>
          </IfPermission>
        </Row>
        <Row>
          <IfPermission action='ventas'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-success'>
                <div className='inner'>
                  <p>FACTURAS DE EQUIPOS</p>
                  <h3>{!loading ? facturas : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(facturasImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <Icon icon='list' />
                </div>
                <Link to='/facturas' className='small-box-footer'>
                  Ver más <Icon icon='circle-arrow-right' />
                </Link>
              </div>
            </Col>
          </IfPermission>
          <IfPermission action='servicios'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-warning'>
                <div className='inner'>
                  <p>FACTURAS DE SERVICIOS</p>
                  <h3>{!loading ? servicios : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(serviciosImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <Icon icon='wrench' />
                </div>
                <Link to='/facturas' className='small-box-footer'>
                  Ver más <Icon icon='circle-arrow-right' />
                </Link>
              </div>
            </Col>
          </IfPermission>
          <IfPermission action='pagos'>
            <Col xs={12} sm={6} md={4}>
              <div className='small-box bg-danger'>
                <div className='inner'>
                  <p>DOCUMENTOS DE PAGOS</p>
                  <h3>{!loading ? documentosPagos : '-'}</h3>
                  <h3>{!loading ? '$ ' + format(documentosPagosImporte) : <span>&nbsp;</span>}</h3>
                </div>
                <div className='icon'>
                  <Icon icon='usd' />
                </div>
                <Link to='movimientos' className='small-box-footer'>
                  Ver más <Icon icon='circle-arrow-right' />
                </Link>
              </div>
            </Col>
          </IfPermission>
        </Row>
      </Col>
    </Row>
  );
};

IndicadoresVentas.propTypes = {
  loading: PropTypes.bool,
  clientes: PropTypes.number,
  prospectos: PropTypes.number,
  cotizacionesCount: PropTypes.number,
  cotizacionesImporte: PropTypes.number,
  pagos: PropTypes.number,
  pagosImporte: PropTypes.number,
  facturas: PropTypes.number,
  facturasImporte: PropTypes.number,
  servicios: PropTypes.number,
  serviciosImporte: PropTypes.number,
  documentosPagos: PropTypes.number,
  documentosPagosImporte: PropTypes.number,
};

export default IndicadoresVentas;
