import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';
import { DropdownItem, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { Icon } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';

import { Input } from '@controls';
import Required from '@controls/Required';
import { useStoreNotification } from '@stores/catalogs.store';

const Referencias = ({ id, listReferencias: initialListReferencias, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [bancos, setBancos] = useState([]);
  const [listReferencias, setListReferencias] = useState([]);

  useEffect(() => {
    setListReferencias(initialListReferencias);
  }, [initialListReferencias]);

  const close = () => {
    setShowModal(false);
  };

  const open = async () => {
    const { bancos } = await getRequest({ url: `catalogo/bancos` });
    const { data } = await getRequest({ url: `customers/referencias/${id}` });
    setBancos(bancos);
    setListReferencias(
      data
        ? data.map((referencia) => ({
            referencia: referencia.referencia,
            banco: referencia.bancoId,
          }))
        : []
    );
    setShowModal(true);
  };

  const validations = (params) => {
    let validate = {
      success: false,
      message: '',
    };
    if (params.listReferencias.filter((referencia) => !isMongoId(referencia.bancoId + '') || isEmpty(referencia.referencia)).length > 0) {
      validate.message = 'Los referencias requieren tipo referencia y banco.';
    } else {
      validate.success = true;
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  const handleRegistrar = async (event) => {
    event.preventDefault();
    const body = {
      listReferencias: listReferencias.map((x) => ({
        ...x,
        bancoId: x.banco ? x.banco.value : null,
      })),
    };
    if (validations(body)) {
      await postRequest({ url: `customers/${id}/referencias`, body });
      close();
      if (loadData) loadData();
    }
  };

  const addReferencia = () => {
    setListReferencias([
      ...listReferencias,
      {
        referencia: '',
        banco: null,
      },
    ]);
  };

  const removedReferencia = (index, e) => {
    e.preventDefault();
    setListReferencias(listReferencias.filter((_, i) => i !== index));
  };

  const onChangeReferencia = (name, index, e) => {
    setListReferencias(
      listReferencias.map((referencia, i) => {
        if (i === index) {
          return {
            ...referencia,
            [name]: e.target.value,
          };
        }
        return referencia;
      })
    );
  };

  const onChangeBanco = (index, value) => {
    setListReferencias(
      listReferencias.map((referencia, i) => {
        if (i === index) {
          return {
            ...referencia,
            banco: value,
          };
        }
        return referencia;
      })
    );
  };

  const renderRowsReferencias = () => {
    return listReferencias.map((referencia, i) => (
      <tr key={i}>
        <td>
          <Select
            value={referencia.banco}
            name='banco'
            placeholder='Selecciona opciones'
            options={bancos}
            onChange={(value) => onChangeBanco(i, value)}
            getOptionLabel={(option) => option.nombre}
            getOptionValue={(option) => option._id}
            className='basic-multi-select'
            classNamePrefix='select'
          />
        </td>
        <td>
          <Input
            name='referencia'
            className='form-control input-sm'
            value={referencia.referencia}
            placeholder='Referencia'
            onChange={(e) => onChangeReferencia('referencia', i, e)}
            style={{ width: '100%' }}
          />
        </td>
        <td>
          <a href='#' onClick={(e) => removedReferencia(i, e)} className='pull-right mr-5' style={{ color: 'red' }}>
            <Icon icon='remove' />
          </a>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='tags' /> Referencias ({listReferencias.length})
      </DropdownItem>
      <Modal show={showModal} onHide={close} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Referencias</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleRegistrar}>
          <Modal.Body>
            <div>
              <div className='panel panel-default'>
                <div className='panel-heading'>
                  <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Nuevo Referencia</Tooltip>}>
                    <button onClick={addReferencia} className='btn btn-sm btn-outline-success pull-right' type='button'>
                      <Icon icon='plus' />
                    </button>
                  </OverlayTrigger>
                  <h2 className='panel-title'>
                    <Icon icon='user' style={{ fontSize: '0.8em' }} /> Referencias
                  </h2>
                </div>
                <table className='table table-condensed table-hover table-striped'>
                  <thead>
                    <tr>
                      <th width='35%'>
                        Banco <Required />
                      </th>
                      <th width='35%'>
                        Referencia <Required />
                      </th>
                      <th width='10%'></th>
                    </tr>
                  </thead>
                  <tbody>{renderRowsReferencias()}</tbody>
                </table>
                <div className='panel-footer'></div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='btn btn-primary pull-right'>
              Aceptar
            </button>
            <span className='pull-right'>&nbsp;</span>
            <button onClick={close} className='btn btn-default pull-right' type='button'>
              Cancelar
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

Referencias.propTypes = {
  id: PropTypes.number.isRequired,
  listReferencias: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Referencias;
