import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setDescription('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      description: description.trim().toUpperCase(),
      percentage,
    };

    if (validations(data)) {
      await postRequest({ url: `descuentos-pagos`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.description + '')) {
      validate.success = false;
      validate.message = 'Descripción es requerida.';
    } else if (isEmpty(params.percentage + '')) {
      validate.success = false;
      validate.message = 'Porcentaje es requerido.';
    } else if (params.percentage < 0) {
      validate.success = false;
      validate.message = 'Porcentaje debe ser igual o mayor a cero.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' size='lg' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput required name='description' title='Descripción' onChange={(e) => setDescription(e.target.value)} value={description} />
          </Col>
          <Col>
            <FormInput
              required
              type='number'
              name='percentage'
              title='Porcentaje'
              onChange={(e) => setPercentage(e.target.value)}
              value={percentage}
            />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  loadData: PropTypes.func.isRequired,
};

export default New;
