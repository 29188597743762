import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nombre') {
      setNombre(value);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      nombre: nombre.trim(),
    };

    if (validations(data)) {
      await postRequest({ url: `fabricantes`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} type='button' icon='plus' title='Nuevo' />
      <CbModal show={showModal} title='Agregar fabricante' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' placeholder='Nombre' onChange={onChange} defaultValue={nombre} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  loadData: PropTypes.func.isRequired,
};

export default New;
