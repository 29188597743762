import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteTiposContactos = ({ tiposContactoId, tipo, loadTiposContactos }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`tipocontactos/${tiposContactoId}`}
      description={`¿Confirma que desea eliminar el tipo de contacto <strong><u> ${tipo}</u> </strong>?`}
      loadData={loadTiposContactos}
    />
  );
};

DeleteTiposContactos.propTypes = {
  id: PropTypes.number.isRequired,
  tipo: PropTypes.string.isRequired,
  tiposContactoId: PropTypes.string.isRequired,
  loadTiposContactos: PropTypes.func.isRequired,
};

export default DeleteTiposContactos;
