import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ data, ...props }) => {
  const renderRows = () => {
    return data.map((row, i) => {
      const { _id, nombre } = row;
      return (
        <tr key={i}>
          <td>{nombre}</td>
          <td>
            <IfPermission action='eliminar'>
              <Delete id={i} itemId={_id} nombre={nombre} {...props} btnClass='btn btn-outline-danger' />
            </IfPermission>
            <IfPermission action='editar'>
              <Edit id={i} itemId={_id} nombre={nombre} {...props} btnClass='btn btn-outline-info' />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='80%'>Nombre</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
};

export default List;
