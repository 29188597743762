import { MONEDA } from '@config/constants';
import { DivFormGroup, FilterSelectNuevo, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { castEquipo, castServicio } from '@utils/cotizaciones';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import Required from '@controls/Required';
import RowDetalle from './components/RowDetalle';
import { validateUpdate } from '@validations/solicitudCompra';
import SelectAgente from '@components/forms/SelectAgente';
import SelectSucursal from '@components/forms/SelectSucursal';
import Select from '@components/forms/Select';

const New = ({ navigate, notification }) => {
  const [state, setState] = useState({
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    monedaId: MONEDA.DOLAR,
    sucursales: [],
    monedas: [],
    measures: [],
    detalles: [],
    usos_cfdi: [],
    usoCfdiId: null,
    observaciones: '',
    estatus: 'INICIADA',
    sucursalId: null,
    proveedor: null,
    proveedorObj: null,
    proveedorName: '',
    productoName: '',
    producto: null,
    productos: [],
    tipoCambio: 0,
    usa_almacen: false,
    usa_servicios: false,
    usa_gastos: false,
    tipo_partida: 'EQUIPO',
    tipos_partidas: [
      { value: 'EQUIPO', label: 'EQUIPO' },
      { value: 'SERVICIO', label: 'SERVICIO' },
    ],
    estatus_solicitud: [{ value: 'INICIADA', label: 'INICIADA' }],
    agenteObj: null,
    agenteName: '',
  });

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));
  };

  const onChangeProductoInput = (value) => {
    setState((prevState) => ({
      ...prevState,
      productoName: value,
    }));
  };

  const onChangeProducto = (itemSelect) => {
    const { monedaId, tipoCambio, tipo_partida, detalles, sucursalId, measures } = state;
    let detalle = {};
    if (itemSelect) {
      const producto = itemSelect;
      if (producto && producto.listWorkforce && producto.listWorkforce.length > 0)
        setState((prevState) => ({
          ...prevState,
          equipmentWorkForceId: producto._id,
          equipmentWorkForceName: producto.name,
          showModalWorkforce: true,
        }));
      switch (tipo_partida) {
        case 'SERVICIO':
          detalle = castServicio(producto, monedaId, tipoCambio, measures, sucursalId);
          break;
        case 'EQUIPO':
          detalle = castEquipo(producto, monedaId, tipoCambio);
          break;
      }
      setState((prevState) => ({
        ...prevState,
        productoName: '',
        producto: '',
        detalles: [
          ...detalles,
          {
            ...detalle,
            tipo_partida,
          },
        ],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        productoName: '',
        producto: null,
      }));
    }
  };

  const urlBuscarConceptos = () => {
    switch (state.tipo_partida) {
      case 'EQUIPO':
        return 'equipments-autocomplete';
      case 'SERVICIO':
        return 'services-autocomplete';
      case 'KIT':
        return 'kits-autocomplete';
      case 'KIT_COMERCIAL':
        return 'kits-comerciales-autocomplete';
    }
    return '';
  };

  const tituloBuscarConceptos = () => {
    switch (state.tipo_partida) {
      case 'EQUIPO':
        return 'Buscar equipos por código o descripcion ...';
      case 'SERVICIO':
        return 'Buscar servicios por código o descripcion...';
      case 'KIT':
        return 'Buscar kits por código o descripcion...';
      case 'KIT_COMERCIAL':
        return 'Buscar kits comercial por código o descripcion...';
    }
    return '';
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return !error;
  };

  const handleRegisterCompra = async (event) => {
    event.preventDefault();
    const { tipoCompraId, numeroOrden, fecha, estatus, sucursalId, observaciones, detalles, agenteId } = state;

    const data = {
      tipoCompraId: tipoCompraId,
      numero_solicitud: numeroOrden,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      agenteId,
      estatus_solicitud: estatus,
      sucursalId,
      observaciones,
      detalles: detalles.map((detalle) => ({
        ...detalle,
      })),
    };
    if (validations(data)) {
      await postRequest({ url: `solicitudes-compras`, body: data });
      setTimeout(() => navigate('/solicitudes-compras'), 1000);
    }
  };

  const onChangeDetalle = (index, detalle) => {
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => (i === index ? detalle : x)),
    }));
  };

  const onClickRemoveDetalle = (index, event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.filter((x, i) => i !== index),
    }));
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        agente: itemSelect,
        agenteId: itemSelect._id,
        agenteObj: itemSelect,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        agenteName: '',
        agenteId: null,
        agente: null,
        agenteObj: null,
      }));
    }
  };

  const ObtenerNumeroSolicitudCompra = useCallback(async () => {
    const { data } = await getRequest({ url: `solicitudes-compras/siguienteNumero` });
    setState((prevState) => ({
      ...prevState,
      numeroOrden: data.numero_solicitud,
    }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const fecha = moment().format('YYYY-MM-DD');
      const { data } = await getRequest({ url: `tipo-cambio/fecha`, params: { fecha } });
      if (!data?._id) {
        return notification({
          title: 'Advertencia',
          message: 'Se debe capturar el tipo de cambio del dia. Catálogos -> Tipos de cambio',
          type: 'warning',
        });
      }
      const {
        data: { sucursales, measures, agente },
      } = await getRequest({ url: `solicitudes-compras-catalogos` });
      setState((prevState) => ({
        ...prevState,
        sucursales: sucursales,
        measures: measures,
        agenteObj: { value: agente._id, label: agente.nombre },
        agenteId: agente._id,
        sucursalId: agente.sucursalId,
        almacenId: agente.almacenId,
        agenteName: agente.nombre,
        isLoadingForm: false,
        tipoCambio: data.valor,
      }));
      ObtenerNumeroSolicitudCompra();
    };
    fetchData();
  }, [notification, ObtenerNumeroSolicitudCompra]);

  const renderView = () => {
    const { numeroOrden, fecha, estatus, sucursalId, isLoading, productoName, producto, observaciones, estatus_solicitud, tipo_partida, agenteObj } =
      state;
    return (
      <form onSubmit={handleRegisterCompra}>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col sm='4'>
                <FormInput
                  title='Número de solicitud'
                  placeholder='Número de solicitud'
                  disabled
                  name='numeroOrden'
                  onChange={onChange}
                  value={numeroOrden}
                />
              </Col>
              <Col sm='4'>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </Col>
              <Col sm='4'>
                <DivFormGroup name='estatus' required title='Estatus'>
                  <Select name='estatus' value={estatus} options={estatus_solicitud} onChange={(value) => onSelect('estatus', value)} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='agenteName' required title='Agente'>
                  <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                </DivFormGroup>
              </Col>
            </Row>
          </div>
        </div>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col sm='10'>
                <DivFormGroup>
                  <div className='input-group'>
                    <DropdownButton title={tipo_partida} id={`dropdown-basic`}>
                      {state.tipos_partidas.map((x, i) => (
                        <DropdownItem
                          key={i}
                          eventKey={i + 1}
                          active={x.value === tipo_partida}
                          onClick={() => setState((prevState) => ({ ...prevState, tipo_partida: x.value }))}
                        >
                          {x.label}
                        </DropdownItem>
                      ))}
                    </DropdownButton>
                    <FilterSelectNuevo
                      placeholder={tituloBuscarConceptos()}
                      async={true}
                      value={producto}
                      inputValue={productoName}
                      onInputChange={onChangeProductoInput}
                      onChangeOption={onChangeProducto}
                      url={urlBuscarConceptos()}
                      fields={['_id', 'code,name']}
                    />
                  </div>
                </DivFormGroup>
              </Col>
              <Col sm='2'>
                <Link to={`${tipo_partida === 'EQUIPO' ? '/equipos' : '/servicios'}/nuevo`} className='btn btn-success pull-right'>
                  <Icon icon='plus' /> Nuevo
                </Link>
              </Col>
            </Row>
          </div>
          <CbPanelTableResponsive>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  Descripción <Required />
                </th>
                <th>
                  Unidad <Required />
                </th>
                <th>
                  Cantidad <Required />
                </th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {state.detalles.map((detalle, i) => (
                <RowDetalle
                  key={i}
                  detalle={detalle}
                  i={i}
                  onSelectUnidad={onSelect}
                  onChangeDetalle={onChangeDetalle}
                  onClickRemoveDetalle={onClickRemoveDetalle}
                />
              ))}
            </tbody>
          </CbPanelTableResponsive>
          <div className='panel-body'>
            <Row>
              <Col sm='12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </div>
          <div className='panel-footer'>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <button onClick={handleRegisterCompra} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back()} parent='Solicitudes de compras' title='Nueva solicitud de compra'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

New.propTypes = {
  navigate: PropTypes.func.isRequired,
  monedaId: PropTypes.string,
  sucursalId: PropTypes.string,
  tipoCambio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setState: PropTypes.func,
  measures: PropTypes.array,
  tipos_partidas: PropTypes.array,
  actualizarTotalesDetalles: PropTypes.func,
  detalles: PropTypes.array,
  tipo_partida: PropTypes.string,
  notification: PropTypes.func,
};

export default New;
