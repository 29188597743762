import { DivFormGroup, Input, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Saving } from '@stateless';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [nombre, setNombre] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setCodigo('');
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'codigo') setCodigo(value);
    if (name === 'nombre') setNombre(value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      codigo: codigo.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
    };

    if (validations(data)) {
      const { data: responseData } = await postRequest({ url: `departamentos`, body: data });
      if (responseData) {
        load();
        close();
      }
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Departamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleRegister}>
            <div className='row'>
              <div className='col-sm-12'>
                <DivFormGroup>
                  <Label name='codigo' title='Código' required />
                  <Input name='codigo' placeholder='Código' onChange={onChange} value={codigo} />
                </DivFormGroup>
                <DivFormGroup>
                  <Label name='nombre' title='Nombre' required />
                  <Input name='nombre' placeholder='Nombre' onChange={onChange} value={nombre} />
                </DivFormGroup>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <button onClick={handleRegister} className='btn btn-primary pull-right' disabled={isLoading}>
            Guardar
          </button>
          <span className='pull-right'>&nbsp;</span>
          <button onClick={close} disabled={isLoading} className='btn btn-default pull-right' type='button'>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

New.propTypes = {
  load: PropTypes.func.isRequired,
};

export default New;
