import { RedirectTooltip } from '@controls';
import IfPermission from '@controls/IfPermission';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';
import EditarAsesor from './EditarAsesor';
import EliminarAsesor from './EliminarAsesor';

const List = ({ list }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const {
        _id,
        nombre,
        giro,
        tipo,
        fecha,
        fecha_visita,
        asesor_asignado,
        comparte_comision_con,
        soluciones_solicitadas,
        telefono,
        correo_electronico,
        medio_comunicacion,
        comentarios,
        producto_interes,
      } = row;
      return (
        <tr key={item}>
          <td>
            <span className='label label-primary'>{tipo === 'C' ? 'Cliente' : 'Prospecto'}</span> {nombre}
          </td>
          <td>{giro}</td>
          <td>{medio_comunicacion ? medio_comunicacion.nombre : null}</td>
          <td>{telefono}</td>
          <td>{correo_electronico}</td>
          <td>
            {soluciones_solicitadas
              ? soluciones_solicitadas.map((x, key) => (
                  <span key={key} className='label label-success mr-5'>
                    {x.definicion}
                  </span>
                ))
              : null}
            <br />
            {producto_interes ? (
              <span style={{ fontSize: '10px' }}>
                <b>Interes:</b> {producto_interes}
              </span>
            ) : null}
          </td>
          <td>
            {asesor_asignado ? <span>{asesor_asignado.nombre}</span> : null} <br />
            {comparte_comision_con ? (
              <span>
                <b>Comparte con:</b> {comparte_comision_con.nombre}
              </span>
            ) : null}
          </td>
          <td>{moment(fecha).local().format('YYYY/MM/DD')}</td>
          <td>{fecha_visita ? moment(fecha_visita).local().format('YYYY/MM/DD') : null}</td>
          <td>{comentarios}</td>
          <td>
            <IfPermission action='eliminar'>
              <EliminarAsesor id={item} ruletaId={_id} nombre={nombre} {...this.props} btnClass='btn btn-outline-danger' />
            </IfPermission>
            <IfPermission action='editar'>
              <EditarAsesor
                id={item}
                ruletaId={_id}
                soluciones_solicitadas_id={row.soluciones_solicitadas}
                {...row}
                {...this.props}
                btnClass='btn btn-outline-info'
              />
            </IfPermission>
            <IfPermission action='clientes'>
              <RedirectTooltip
                id={1}
                url={`/contactos/clientes/${_id}`}
                labelTooltip='Clientes asignados'
                icon='share'
                className='btn btn-outline-primary'
              />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound message='Aún no hay ruletas registradas.' colSpan={9} />;

  return (
    <div className='panel panel-default panel-table' style={{ overflowX: 'scroll' }}>
      <table className='table table-condensed table-hover table-striped'>
        <thead>
          <tr>
            <th width='15%'>Cliente / Prospecto</th>
            <th width='5%'>Giro</th>
            <th width='10%'>Medio</th>
            <th width='10%'>Teléfono</th>
            <th width='13%'>Correo electrónico</th>
            <th width='10%'>Solución solicitada</th>
            <th width='10%'>Asesor asignado</th>
            <th width='6%'>Fecha</th>
            <th width='6%'>Fecha visita</th>
            <th width='6%'>Comentarios</th>
            <th width='11%'></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
};

export default List;
