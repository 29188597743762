import { DivFormGroup } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { switchEstado } from '@utils/general';
import { isMongoId } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const Register = () => {
  const { addNotification: notification } = useStoreNotification();
  const now = new Date();
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [maquinaria, setMaquinaria] = useState([]);
  const [plan, setPlan] = useState(null);
  const [date, setDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
  const [storeId, setStoreId] = useState('');
  const [maquinariaHerramientaId, setMaquinariaHerramientaId] = useState('');
  const addForm = useRef(null);

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    const { stores, maquinaria } = await getRequest({ url: `catalogo-mantenimientos` });
    setStores(stores);
    setMaquinaria(maquinaria);
    setLoading(false);
  };

  const setupDate = (value) => {
    const d = value.split('-');
    return new Date(d[0], d[1] - 1, d[2]);
  };

  const onChange = async (event) => {
    const { name, value } = event.target;
    if (name === 'date') {
      setDate(setupDate(value));
    } else if (name === 'maquinariaHerramientaId') {
      const maquinariaHerramientaId = value;
      const { data } = await postRequest({ url: `mantenimientos-plan/${maquinariaHerramientaId}` });
      if (data === null) {
        notification({
          title: 'Información incompleta',
          message: 'La maquinaria seleccionada no tiene asignada un plan para realizarle los mantenimientos',
          type: 'warning',
        });
      }
      setPlan(data);
      setMaquinariaHerramientaId(value);
    } else {
      if (name === 'storeId') setStoreId(value);
      if (name === 'maquinariaHerramientaId') setMaquinariaHerramientaId(value);
    }
  };

  const castOption = (rowId) => {
    let option = 0;
    if (typeof addForm.current[`row-${rowId}`] !== 'undefined') {
      if (addForm.current[`row-${rowId}-1`].checked) {
        option = 1;
      } else if (addForm.current[`row-${rowId}-2`].checked) {
        option = 2;
      } else if (addForm.current[`row-${rowId}-3`].checked) {
        option = 3;
      }
    }
    return option;
  };

  const handleAddForm = async (event) => {
    event.preventDefault();
    const {
      date,
      storeId,
      maquinariaHerramientaId,
      plan: { _id, listaKit, listaTerceros },
    } = { date, storeId, maquinariaHerramientaId, plan };
    const kits = [];
    const terceros = [];

    listaKit.map((k) => {
      k.kitServicioId.detalle.map((r) => {
        const rowId = `${k.kitServicioId._id}-${r.piezaId._id}`;
        const option = castOption(rowId);

        kits.push({
          kitServicioId: k.kitServicioId._id,
          piezaId: r.piezaId._id,
          option,
        });
      });
    });

    listaTerceros.map((k) => {
      const { serviciosTercerosId } = k;
      const option = castOption(serviciosTercerosId._id);

      terceros.push({
        serviciosTercerosId: serviciosTercerosId._id,
        option,
      });
    });

    const data = {
      date,
      storeId,
      maquinariaHerramientaId,
      planMantenimientoId: _id,
      kits,
      terceros,
    };

    if (validations(data)) {
      await postRequest({ url: 'mantenimientos', body: data });
      const now = new Date();
      setMaquinaria([]);
      setPlan(null);
      setDate(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
      setStoreId('');
      setMaquinariaHerramientaId('');
    }
  };

  const renderKits = () => {
    const { listaKit } = plan;

    const tables = listaKit.map((k, i) => {
      const { kitServicioId } = k;

      return (
        <div key={i}>
          <span className='lead'>{kitServicioId.nombre}</span>
          <table className='table table-condensed table-hover table-bordered'>
            <thead>
              <tr>
                <th width='60%'>Detalle</th>
                <th width='20%'>Frecuencia</th>
                <th width='20%' className='text-center'>
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {kitServicioId.detalle.map((d, x) => {
                const {
                  piezaId: { nombre, _id },
                  cantidad,
                  temporalidad,
                } = d;
                const rowId = `${kitServicioId._id}-${_id}`;
                const temporalidadLabel = switchEstado(temporalidad);

                return (
                  <tr key={x}>
                    <td>
                      {cantidad > 0 ? cantidad : ''} {nombre}
                    </td>
                    <td>{typeof temporalidadLabel !== 'undefined' ? temporalidadLabel.text : ''}</td>
                    <td className='text-center'>
                      <div className='form-group inline'>
                        <label className='radio-inline'>
                          <input type='radio' name={`row-${rowId}`} id={`row-${rowId}-1`} onChange={onChange} value={1} /> Si
                        </label>
                        <label className='radio-inline'>
                          <input type='radio' name={`row-${rowId}`} id={`row-${rowId}-2`} onChange={onChange} value={2} /> No
                        </label>
                        <label className='radio-inline'>
                          <input type='radio' name={`row-${rowId}`} id={`row-${rowId}-3`} onChange={onChange} value={3} /> No aplica
                        </label>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    });

    return (
      <div>
        <legend>Kit de Servicios</legend>
        {tables}
      </div>
    );
  };

  const renderTerceros = () => {
    const { listaTerceros } = plan;
    const rows = listaTerceros.map((k, i) => {
      const { serviciosTercerosId } = k;
      const rowId = serviciosTercerosId._id;

      return (
        <tr key={i}>
          <td>{serviciosTercerosId.nombre}</td>
          <td>{serviciosTercerosId.proveedorId.name}</td>
          <td className='text-center'>
            <div className='form-group inline'>
              <label className='radio-inline'>
                <input type='radio' name={`row-${rowId}`} id={`row-${rowId}-1`} onChange={onChange} value={1} /> Si
              </label>
              <label className='radio-inline'>
                <input type='radio' name={`row-${rowId}`} id={`row-${rowId}-2`} onChange={onChange} value={2} /> No
              </label>
              <label className='radio-inline'>
                <input type='radio' name={`row-${rowId}`} id={`row-${rowId}-3`} onChange={onChange} value={3} /> No aplica
              </label>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <legend>Servicios de Terceros</legend>

        <table className='table table-condensed table-hover table-bordered'>
          <thead>
            <tr>
              <th width='50%'>Servicio</th>
              <th width='30%'>Proveedor</th>
              <th width='20%' className='text-center'>
                Opciones
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  };

  const renderPlan = () => {
    return (
      <Fragment>
        {renderKits()}
        {renderTerceros()}
      </Fragment>
    );
  };

  const renderForm = () => {
    return (
      <form ref={addForm} onSubmit={handleAddForm}>
        <Row>
          <Col sm='4'>
            <DivFormGroup name='date' title='Fecha'>
              <input
                className='form-control'
                name='date'
                id='date'
                type='date'
                value={date !== '' && date !== null ? moment(date).format('YYYY-MM-DD') : null}
                onChange={onChange}
              />
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='storeId' title='Sucursal'>
              <select
                name='storeId'
                id='storeId'
                className='form-control'
                value={storeId}
                onChange={onChange}
                disabled={stores.length > 0 ? false : true}
              >
                <option value=''>Seleccione</option>
                {stores.map((s, i) => (
                  <option key={i} value={s._id}>
                    {s.name}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='maquinariaHerramientaId' title='Maquinaria / Herramienta'>
              <select
                name='maquinariaHerramientaId'
                id='maquinariaHerramientaId'
                className='form-control'
                defaultValue={maquinariaHerramientaId}
                onChange={onChange}
                disabled={maquinaria.length > 0 ? false : true}
              >
                <option value=''>Seleccione</option>
                {maquinaria.map((s, i) => (
                  <option key={i} value={s._id}>
                    {s.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
        </Row>
        {plan !== null ? renderPlan() : ''}
        {plan !== null ? (
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Body>
                  <Saving saving={loading} />
                  <Button type='submit' className='btn btn-lg btn-primary pull-right' disabled={loading}>
                    Guardar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </form>
    );
  };

  const validations = (data) => {
    let validate = { success: true, message: '' };

    if (!isMongoId(data.storeId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es Requerido.';
    } else if (validate.success && !isMongoId(data.maquinariaHerramientaId + '')) {
      validate.success = false;
      validate.message = 'Maquinaria / Herramienta es Requerido.';
    } else if (validate.success && !isMongoId(data.planMantenimientoId + '')) {
      validate.success = false;
      validate.message = 'Revisión del Mantenimiento es Requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const moduleForm = !loading ? renderForm() : <Loading />;
  return (
    <Module onClickBack={window.history.back} title='Mantenimiento'>
      {moduleForm}
    </Module>
  );
};

Register.propTypes = {};

export default Register;
