import { CbPagination } from '@controls';
import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import List from './List';
import NewUser from './NewUser';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(30);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(async () => {
    setLoading(true);
    const page = activePage * pagination - pagination;
    const body = { search };
    const { data, count } = await postRequest({ url: `users/search`, params: { page, limit: pagination }, body });
    setUsers(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  return (
    <Module title='Usuarios'>
      <Search onSearch={onSearch}>
        <NewUser
          loadUsers={handleSearch}
          users={users}
          activePage={activePage}
          count={count}
          pagination={pagination}
          search={search}
          loading={loading}
        />
      </Search>
      <Loading loading={loading}>
        <List
          loadUsers={handleSearch}
          users={users}
          activePage={activePage}
          count={count}
          pagination={pagination}
          search={search}
          loading={loading}
        />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

Users.propTypes = {};

export default Users;
