import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';


const List = ({data, loadData}) => {
    if (!data) {
        return <p>Cargando datos...</p>;
    }
    const renderRows = () =>{
        return data.map((row, i) =>{
            const {_id,nombre, tipo_tarjeta, numero_tarjeta,numero_seguridad, anio_vencimiento, mes_vencimiento,tarjetahabiente,moneda, segmento, comentarios,cuenta_bancaria_id } = row;

            return (
                <tr key={i}>
                    <td>{nombre}</td>
                    <td>{tipo_tarjeta}</td> 
                    <td>{numero_tarjeta}</td> 
                    <td>{numero_seguridad}</td>
                    <td>{anio_vencimiento}</td> 
                    <td>{mes_vencimiento}</td> 
                    <td>{segmento}</td> 
                    <td>{moneda}</td> 
                    <td>{tarjetahabiente}</td> 
                    <td>{cuenta_bancaria_id.nombre}</td>
                    <td>{comentarios}</td>

                    <td>
                        <IfPermission action='eliminar'>
                            <Delete id={i} itemId={_id} nombre={nombre} loadData={loadData} />
                        </IfPermission>
                        <IfPermission action='editar'>
                            <Edit id={i} itemId={_id} nombre={nombre} tipo_tarjeta={tipo_tarjeta} numero_tarjeta={numero_tarjeta} anio_vencimiento={anio_vencimiento} mes_vencimiento={mes_vencimiento} segmento={segmento} moneda={moneda} tarjetahabiente={tarjetahabiente} cuenta_bancaria_id={cuenta_bancaria_id} comentarios={comentarios} loadData={loadData} />
                        </IfPermission>
                    </td>
                </tr>
            );
        });
    };

    return(
        <CbPanelTableResponsive>
            <thead>
                <tr>
                    <th width='10%'>Nombre</th>
                    <th width='10%'>Tipo tarjeta</th>
                    <th width='10%'>Número de tarjeta</th>
                    <th width='5%'>Número de Seguridad</th>
                    <th width='5%'>Año vencimiento</th>
                    <th width='5%'>Mes vencimiento</th>
                    <th width='5%'>Segmento</th>
                    <th width='5%'>Moneda</th>
                    <th width='10%'>Tarjeta Habiente</th>
                    <th width='10%'>Cuenta Bancaria</th>
                    <th width='10%'>Comentarios</th>
                </tr>
            </thead>
            <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
        </CbPanelTableResponsive>
    )
};
List.propTypes ={
    data: PropTypes.array.isRequired,
    loadData: PropTypes.func.isRequired
};
export default List;