import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import Select from '@components/forms/Select';

const CambiarEstado = ({ itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading] = useState(false);
  const [estatus, setEstatus] = useState(null);
  const estados_servicio = [
    { value: 'EN_SERVICIO', label: 'EN SERVICIO' },
    { value: 'BAJA', label: 'BAJA' },
    { value: 'ACTIVACION_EN_PROCESO', label: 'ACTIVACION EN PROCESO' },
    { value: 'BAJA_EN_PROCESO', label: 'BAJA EN PROCESO' },
    { value: 'SUSPENDIDO', label: 'SUSPENDIDO' },
    { value: 'CUENTA_DUPLICADA', label: 'CUENTA DUPLICADA' },
    { value: 'BAJA_SIN_DESCONEXION', label: 'BAJA SIN DESCONEXION' },
    { value: 'CAMBIO_DE_CUENTA', label: 'CAMBIO DE CUENTA' },
    { value: 'DATOS_PENDIENTES', label: 'DATOS PENDIENTES' },
  ];

  const close = () => {
    setShowModal(false);
    setEstatus(null);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `cuentas-monitoreos/${itemId}` });
    setEstatus(estados_servicio.find((estado) => estado.value === data.estatus) || null);
    setShowModal(true);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const data = {
      estatus: estatus.value,
    };

    if (validations(data)) {
      await putRequest({ url: `cuentas-monitoreos/estatus/${itemId}`, body: data });
      setShowModal(false);
      loadData();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.estatus)) {
      validate.success = false;
      validate.message = 'Estado del servicio es requerida.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver title='Cambiar estado' onClick={open} icon='info-sign' type='warning' size='sm' />
      <CbModal show={showModal} title='Cambiar' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='estatus' required title='Estado del servicio'>
              <Select value={estatus} name='estatus' options={estados_servicio} onChange={(value) => setEstatus(value)} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

CambiarEstado.propTypes = {
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default CambiarEstado;
