import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ id, estadoId: initialEstadoId, estado: initialEstado, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [estado, setEstado] = useState(initialEstado);
  const [estadoId, setEstadoId] = useState(initialEstadoId);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => setShowModal(false);

  const open = () => {
    setShowModal(true);
    setEstado(initialEstado);
    setEstadoId(initialEstadoId);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      estado: estado.trim().toUpperCase(),
      estadoId: estadoId.trim(),
    };

    if (validations(data)) {
      setIsLoading(true);
      await putRequest({ url: `estados/${id}`, body: data });
      load();
      close();
      setIsLoading(false);
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.estadoId + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.estado + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} isLoading={isLoading} title='Editar'>
        <Row>
          <Col sm='6'>
            <FormInput title='Código' placeholder='Código' required name='estadoId' onChange={(e) => setEstadoId(e.target.value)} value={estadoId} />
          </Col>
          <Col sm='6'>
            <FormInput title='Nombre' placeholder='Nombre' required name='estado' onChange={(e) => setEstado(e.target.value)} value={estado} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  estadoId: PropTypes.string.isRequired,
  estado: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Edit;
