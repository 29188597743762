import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import '@styles/autocomplete.css';

const AutoComplete = ({ name, id, data, disabled, innerRef, searchable, defaultValue, callback, clearable }) => {
  const updateValue = (newValue) => {
    callback(newValue.value, newValue.label);
  };

  const obtenerValueSeleccionado = (value) => {
    if (!value) return null;
    if (typeof value === 'object') return { ...value };
    return { ...data.find((item) => item.value === value) };
  };

  const value = obtenerValueSeleccionado(defaultValue);

  return (
    <Select
      name={`autocomplete-${id || name}`}
      options={data}
      isClearable={clearable}
      isDisabled={disabled}
      value={value}
      ref={(input) => {
        if (innerRef) innerRef(input);
      }}
      onChange={updateValue}
      searchable={searchable}
      searchPromptText='Resultados...'
      placeholder='Seleccionar'
      noResultsText='No se encontraron resultados'
    />
  );
};

AutoComplete.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  innerRef: PropTypes.func,
  searchable: PropTypes.string,
  defaultValue: PropTypes.string,
  callback: PropTypes.func,
  clearable: PropTypes.bool,
};

export default AutoComplete;
