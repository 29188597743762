import { DivFormGroup, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAlmacen from '@components/forms/SelectAlmacen';

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [nombre, setNombre] = useState('');
  const [almacenId, setAlmacenId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setNombre('');
    setAlmacenId(null);
  };

  const onSelectAlmacen = (value) => {
    setAlmacenId(value);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nombre') {
      setNombre(value);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      nombre,
      almacenId,
    };

    if (validations(data)) {
      await postRequest({ url: 'ubicaciones', body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (!isMongoId(params.almacenId + '')) {
      validate.success = false;
      validate.message = 'Almacén es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Nueva ubicación' onClose={close} isLoading={isLoading} onSave={handleRegister}>
        <Row>
          <Col>
            <DivFormGroup>
              <Label name='nombre' title='Nombre' required />
              <input type='text' id='nombre' name='nombre' className='form-control' onChange={onChange} value={nombre} placeholder='Nombre ' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup>
              <Label name='almacenId' title='Almacén' required />
              <SelectAlmacen name='almacenId' value={almacenId} onChange={onSelectAlmacen} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  loadData: PropTypes.func.isRequired,
  almacenes: PropTypes.array.isRequired,
};

export default New;
