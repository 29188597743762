import { DivFormGroup, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const CambiarEstado = ({ itemId, loadData, estatus: initialEstatus }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading] = useState(false);
  const [estatus, setEstatus] = useState(null);
  const estados_servicio = [
    { value: 'EN_SERVICIO', label: 'EN SERVICIO', type: 'success' },
    { value: 'BAJA', label: 'BAJA', type: 'danger' },
    { value: 'ACTIVACION_EN_PROCESO', label: 'ACTIVACION EN PROCESO', type: 'secondary' },
    { value: 'BAJA_EN_PROCESO', label: 'BAJA EN PROCESO', type: 'warning' },
    { value: 'SUSPENDIDO', label: 'SUSPENDIDO', type: 'danger' },
    { value: 'CUENTA_DUPLICADA', label: 'CUENTA DUPLICADA', type: 'danger' },
    { value: 'BAJA_SIN_DESCONEXION', label: 'BAJA SIN DESCONEXION', type: 'warning' },
    { value: 'CAMBIO_DE_CUENTA', label: 'CAMBIO DE CUENTA', type: 'warning' },
    { value: 'DATOS_PENDIENTES', label: 'DATOS PENDIENTES', type: 'secondary' },
    { value: 'CANCELADO', label: 'CANCELADO', type: 'secondary' },
    { value: 'PERSONALIZADO', label: 'PERSONALIZADO', type: 'secondary' },
    { value: 'PENDIENTE', label: 'PENDIENTE', type: 'secondary' },
  ];

  const close = () => {
    setShowModal(false);
    setEstatus(null);
  };

  const open = () => {
    setEstatus(estados_servicio.find((estado) => estado.value === initialEstatus) || null);
    setShowModal(true);
  };

  const onSelect = (name, value) => {
    setEstatus(value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const data = {
      estatus: estatus.value,
    };
    if (validations(data)) {
      await putRequest({ url: `agendas-monitoreos/estatus/${itemId}`, body: data });
      setShowModal(false);
      loadData();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.estatus)) {
      validate.success = false;
      validate.message = 'Estado del servicio es requerida.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='info-sign' title='Cambiar estado' type='warning' size='sm' />
      <CbModal show={showModal} onClose={close} onSave={handleRegister} title='Editar estado de monitoreo' isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup>
              <Label name='estatus' required title='Estado del servicio' />
              <Select value={estatus} name='estatus' options={estados_servicio} onChange={(value) => onSelect('estatus', value)} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

CambiarEstado.propTypes = {
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  estatus: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default CambiarEstado;
