import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';

const RowCredito = ({ credito: { limite_credito, plazo } }) => {
  return (
    <tr>
      <td>$ {format(limite_credito, 2)}</td>
      <td>{plazo} días</td>
    </tr>
  );
};

RowCredito.propTypes = {
  credito: PropTypes.object.isRequired,
};

export default RowCredito;
