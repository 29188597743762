import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import InputNumber from '@components/forms/InputNumber';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid,
  borderRadius: '5px',
  background: isDragging ? '#e4e4e4' : '#f4f4f4',
  marginBottom: '2px',
  borderLeft: '2px solid #e6e7e8',
  color: '#444',
  position: 'relative',
  ...draggableStyle,
});

const Negociacion = ({ item, index, onChange }) => {
  const onChangeInput = (e) => {
    const { name, value } = e.target;
    onChange({
      ...item,
      [name]: value,
    });
  };

  const onChangeInputMask = (e) => {
    const { name, value } = e.target;
    onChange({
      ...item,
      [name]: value,
    });
  };

  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontWeight: '700', marginRight: '8px' }}>
              <input
                name='titulo'
                value={item.titulo}
                onChange={onChangeInput}
                style={{ background: 'transparent', border: 'none', padding: '0px', boxShadow: 'none' }}
              />
            </span>
            <Icon icon='calendar' style={{ color: '#555', marginRight: '4px' }} /> {moment(item.createdAt).format('DD/MM/YYYY')}
          </div>
          <p>
            <textarea
              value={item.contenido}
              name='contenido'
              className='form-control'
              rows={Math.ceil(item.contenido.length / 50)}
              style={{ background: 'transparent', border: 'none', padding: '0px', boxShadow: 'none' }}
              onChange={onChangeInput}
            ></textarea>
          </p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon icon='user' style={{ color: '#555', marginRight: '4px' }} />
            <span style={{ fontWeight: '700' }}>{item.prospecto}</span>
          </div>
          <InputNumber
            name='monto'
            style={{ background: 'transparent', border: 'none', padding: '0px', boxShadow: 'none', height: '18px' }}
            value={item.monto}
            placeholder='Monto'
            onChange={onChangeInputMask}
          />
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <Icon icon='comment' style={{ color: '#555', marginRight: '8px' }} />
            <span style={{ color: '#555', marginRight: '8px' }}>{item.comentarios?.length || 0}</span>
            <Icon icon='file' style={{ color: '#555', marginRight: '8px' }} />
            <span style={{ color: '#555', marginRight: '8px' }}>{item.files?.length || 0}</span>
            <Icon icon='user' style={{ color: '#555', marginRight: '8px' }} />
            <span style={{ color: '#555', marginRight: '8px' }}>{item.agenteId?.nombre}</span>
            {/* <Icon icon='pencil' style={{ color: '#555', marginRight: '8px' }} /> */}
          </div>
        </div>
      )}
    </Draggable>
  );
};

Negociacion.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  onRemoveItem: PropTypes.func,
  onChange: PropTypes.func,
  getItemStyle: PropTypes.func,
};

export default Negociacion;
