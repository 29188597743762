import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest, postRequest } from '@utils/api';
import { Col, Row } from 'react-bootstrap';
import SelectPais from '../forms/SelectPais';
import SelectEstado from '../forms/SelectEstado';

const DomicilioEdit = ({ domicilio, onChange }) => {
  const [typingCodigoPostal, setTypingCodigoPostal] = useState(null);
  const refMunicipio = useRef(null);
  const refColonia = useRef(null);
  const refLocalidad = useRef(null);
  const [colonias, setColonias] = useState([]);

  useEffect(() => {}, []);

  const onChangeDomicilio = (e) => {
    const { name, value, checked, type } = e.target;
    onChange({
      ...domicilio,
      [name]: type !== 'checkbox' ? value : checked,
    });
  };

  const onChangePais = (pais) => {
    onChange({
      ...domicilio,
      pais: pais.pais,
      paisObject: pais,
      estado: null,
      municipio: null,
      colonia: null,
      codigo_postal: '',
      localidad: '',
    });
  };

  const onChangeEstado = (estado) => {
    onChange({
      ...domicilio,
      estado: estado.estado,
      estadoObject: estado,
      municipio: null,
      colonia: null,
      codigo_postal: '',
      localidad: '',
    });
    if (refMunicipio.current) setTimeout(() => refMunicipio.current.focus(), 300);
  };

  const onChangeMunicipio = (municipio) => {
    onChange({
      ...domicilio,
      municipioObject: municipio,
      municipio: municipio.nombre,
      coloniaObject: null,
      codigo_postal: '',
      localidad: '',
    });
    if (refColonia.current) setTimeout(() => refColonia.current.focus(), 300);
  };

  const onChangeColonia = (colonia) => {
    onChange({
      ...domicilio,
      codigo_postal: colonia.d_codigo ? colonia.d_codigo : domicilio.codigo_postal ? domicilio.codigo_postal : '',
      coloniaObject: colonia,
      localidad: colonia.d_ciudad,
      colonia: colonia.d_asenta,
      esta_completo: true,
    });
    if (refLocalidad.current) setTimeout(() => refLocalidad.current.focus(), 300);
  };

  const onChangeCodigoPostal = (event) => {
    const { value } = event.target;
    if (typingCodigoPostal) clearTimeout(typingCodigoPostal);
    onChange({
      ...domicilio,
      codigo_postal: value,
    });
    setTypingCodigoPostal(
      setTimeout(() => {
        onSelectCodigoPostal(value);
      }, 300)
    );
  };

  const onSelectCodigoPostal = async (cp) => {
    if (cp.length === 5) {
      const { data } = await postRequest({ url: `colonia/codigo-postal`, body: { search: cp } });
      if (data.length > 0) {
        let colonia = data[0];
        const municipio = colonia && colonia.municipio_id;
        const estado = municipio && municipio.estadoId;
        const pais = estado && estado.paisId;
        onChange({
          ...domicilio,
          calle1: domicilio.entre_calles?.calle1,
          calle2: domicilio.entre_calles?.calle2,
          estadoObject: estado,
          municipioObject: municipio,
          coloniaObject: colonia,
          colonia: colonia.d_asenta,
          municipio: municipio.nombre,
          paisObject: pais,
          localidad: colonia.d_ciudad,
          esta_completo: true,
          codigo_postal: cp,
        });
        setColonias(data);
        if (refColonia.current) setTimeout(() => refColonia.current.focus(), 300);
      }
    }
  };

  const loadOptionsMunicipios = async (inputValue, callback) => {
    const { estadoObject } = domicilio;
    if (!estadoObject) {
      callback([]);
      return;
    }
    const { data } = await getRequest({ url: `municipios/autocomplete/${estadoObject.estadoId}`, params: { query: inputValue } });
    callback(data);
  };

  const loadOptionsColonias = async (inputValue, callback) => {
    const { municipioObject, estadoObject } = domicilio;
    if (!municipioObject) {
      callback([]);
      return;
    }
    const { data } = await getRequest({
      url: `colonias/autocomplete/${municipioObject.clave}/${estadoObject.estadoId}`,
      params: { query: inputValue },
    });
    callback(data);
  };

  if (!domicilio) return null;

  return (
    <>
      <Row>
        <Col sm='2'>
          <DivFormGroup name='es_fiscal' title=''>
            <div className='checkbox'>
              <label>
                <input name='es_fiscal' type='checkbox' checked={domicilio.es_fiscal} onChange={onChangeDomicilio} /> Es Fiscal
              </label>
            </div>
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup ame='nombre' title='Nombre domicilio'>
            <Input
              name='nombre'
              className='form-control input-sm'
              value={domicilio.nombre}
              placeholder='Nombre monitoreo'
              onChange={onChangeDomicilio}
              style={{ width: '100%' }}
            />
          </DivFormGroup>
        </Col>
        <Col sm='4'>
          <DivFormGroup ame='monitoreo' title='Monitoreo'>
            <Input
              name='monitoreo'
              className='form-control input-sm'
              value={domicilio.monitoreo}
              placeholder='Numero monitoreo'
              onChange={onChangeDomicilio}
              style={{ width: '100%' }}
            />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='5'>
          <DivFormGroup ame='calle' title='Calle' required>
            <Input
              name='calle'
              className='form-control input-sm'
              value={domicilio.calle}
              placeholder='Calle'
              onChange={onChangeDomicilio}
              style={{ width: '100%' }}
            />
          </DivFormGroup>
        </Col>
        <Col sm='3'>
          <DivFormGroup name='exterior' title='Numero'>
            <div className='input-group'>
              <Input
                name='exterior'
                className='form-control input-sm'
                value={domicilio.exterior}
                placeholder='Exterior'
                onChange={onChangeDomicilio}
              />
              <Input
                name='interior'
                className='form-control input-sm'
                placeholder='Interior'
                value={domicilio.interior}
                onChange={onChangeDomicilio}
              />
            </div>
          </DivFormGroup>
        </Col>
        <Col sm='4'>
          <DivFormGroup name='exterior' title='Entre calles'>
            <div className='input-group'>
              <Input
                name='calle1'
                className='form-control input-sm'
                value={domicilio.calle1}
                placeholder='Entre calle'
                onChange={onChangeDomicilio}
              />
              <Input name='calle2' className='form-control input-sm' placeholder='Y calle' value={domicilio.calle2} onChange={onChangeDomicilio} />
            </div>
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <DivFormGroup ame='codigo_postal' title='Código Postal' required>
            <Input
              name='codigo_postal'
              className='form-control input-sm'
              value={domicilio.codigo_postal}
              placeholder='Código Postal'
              onChange={onChangeCodigoPostal}
              style={{ width: '100%' }}
            />
          </DivFormGroup>
        </Col>
        <Col sm='3'>
          <DivFormGroup name='pais' title='País' required>
            <SelectPais onChange={onChangePais} value={domicilio.paisObject} name='paisObject' />
          </DivFormGroup>
        </Col>
        <Col sm='3'>
          <DivFormGroup name='Estado' title='Estado' required>
            <SelectEstado
              onChange={onChangeEstado}
              value={domicilio.estadoObject}
              name='estadoObject'
              filter={(x) => x.paisId === domicilio.paisObject?._id}
            />
          </DivFormGroup>
        </Col>
        <Col sm='3'>
          <DivFormGroup name='municipio' title='Municipio' required>
            <AsyncSelect
              disabled={!domicilio.estadoObject}
              value={domicilio.municipioObject}
              loadOptions={(inputValue, callback) => loadOptionsMunicipios(inputValue, callback)}
              onChange={onChangeMunicipio}
              getOptionLabel={(x) => x.nombre}
              getOptionValue={(x) => x._id}
              placeholder='Buscar'
              ref={refMunicipio}
            />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <DivFormGroup name='colonia' title='Colonia' required>
            <AsyncSelect
              disabled={!domicilio.municipioObject || !domicilio.estadoObject}
              value={domicilio.coloniaObject}
              loadOptions={(inputValue, callback) => loadOptionsColonias(inputValue, callback)}
              onChange={onChangeColonia}
              defaultOptions={colonias}
              getOptionLabel={(x) => x.d_asenta}
              getOptionValue={(x) => x._id}
              placeholder='Buscar'
              ref={refColonia}
            />
          </DivFormGroup>
        </Col>
        <Col sm='3'>
          <DivFormGroup ame='localidad' title='Localidad'>
            <Input
              name='localidad'
              className='form-control input-sm'
              value={domicilio.localidad}
              placeholder='Referencias'
              onChange={onChangeDomicilio}
              style={{ width: '100%' }}
              ref={refLocalidad}
            />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup ame='referencia' title='Referencia'>
            <Input
              name='referencias'
              className='form-control input-sm'
              value={domicilio.referencias}
              placeholder='Referencias'
              onChange={onChangeDomicilio}
              style={{ width: '100%' }}
            />
          </DivFormGroup>
        </Col>
      </Row>
    </>
  );
};

DomicilioEdit.propTypes = {
  domicilio: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default DomicilioEdit;
