import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const RowContacto = ({ contacto }) => {
  let extension = '';

  if (contacto.extension !== '') {
    extension = <div>Extensión {contacto.extension}</div>;
  }

  let titulo = '';

  if (typeof contacto.tituloContactoId !== 'undefined') {
    if (contacto.tituloContactoId !== null) {
      titulo = contacto.tituloContactoId.abreviatura;
    }
  }

  return (
    <tr>
      <td>
        <ul>
          {contacto.tipos.map((t, i) => (
            <li key={i}>
              <small>{t.tipoContactoId && t.tipoContactoId.tipo}</small>
            </li>
          ))}
        </ul>
      </td>
      <td>
        {titulo} {contacto.name} {contacto.paterno} {contacto.materno}
        <br />
        {contacto.sexo === 'F' ? 'Femenino' : contacto.sexo === 'M' ? 'Masculino' : ''}
        <br />
        {contacto.fecha_nacimiento !== '' && contacto.fecha_nacimiento !== null
          ? `Fecha de nacimiento ${moment(contacto.fecha_nacimiento).local().format('DD/MM/YYYY')}`
          : ''}
      </td>
      <td>
        {contacto.mobile}
        <br />
        {contacto.mobile2}
      </td>
      <td>
        {contacto.phone}
        {extension}
      </td>
      <td>
        {contacto.email}
        <br />
        {contacto.email2}
      </td>
    </tr>
  );
};

RowContacto.propTypes = {
  contacto: PropTypes.object.isRequired,
};

export default RowContacto;
