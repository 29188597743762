import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import Search from '@controls/Search';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import CbPagination from '@cbcomponents/CbPagination';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import { useParams } from 'react-router-dom';

const ActivityUser = () => {
  const { listadoID } = useParams();
  const [logs, setLogs] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(30);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [fechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin] = useState(moment().format('YYYY-MM-DD'));

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
    };
    const { data, count } = await postRequest({ url: `logs/user/search/${listadoID}`, params: { page, limit: pagination }, body });
    setLogs(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search, fechaFin, fechaInicio, listadoID]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  const renderRows = () => {
    return logs.map((log, index) => (
      <tr key={index}>
        <th>{log.action}</th>
        <td>{moment(log.created).local().format('DD/MM/YYYY HH:mm:ss')}</td>
      </tr>
    ));
  };

  return (
    <Module title='Actividad del usuario'>
      <Search onSearch={onSearch} withDatetime />
      <Loading loading={loading}>
        <CbPanelTableResponsive>
          <thead>
            <tr>
              <th>Acción</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
        </CbPanelTableResponsive>
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

ActivityUser.propTypes = {};

export default ActivityUser;
