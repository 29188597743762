import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import DivFormGroup from './DivFormGroup';
import Required from './Required';

const FormInput = (props) => {
  const {
    innerRef,
    name,
    className = 'form-control',
    style,
    maxLength,
    placeholder,
    disabled,
    readOnly,
    value,
    defaultValue,
    onChange,
    onBlur,
    autocomplete = 'off',
    id,
    title,
    required,
    type,
    withoutLabel,
    rows,
    cols,
  } = props;

  const nameClass = className.match('form-control') ? className : `form-control ${className}`;
  const titleLabel = (
    <>
      {title} {required && <Required />}
    </>
  );

  const onChangeHandler = (e) => {
    if (onChange) {
      e.persist();
      onChange(e);
    }
  };

  return (
    <DivFormGroup>
      {!withoutLabel && <Label name={id || name} title={titleLabel} />}
      {type === 'textarea' ? (
        <textarea
          id={id}
          name={name}
          ref={innerRef}
          className={className}
          rows={rows}
          cols={cols}
          style={style}
          onChange={onChangeHandler}
          disabled={disabled}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
      ) : (
        <input
          type={type || 'text'}
          id={id}
          name={name}
          ref={innerRef}
          className={nameClass}
          style={style}
          maxLength={maxLength}
          placeholder={placeholder || title}
          onChange={onChangeHandler}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={autocomplete}
        />
      )}
    </DivFormGroup>
  );
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  withoutLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  autocomplete: PropTypes.string,
  innerRef: PropTypes.func,
  rows: PropTypes.number,
  cols: PropTypes.number,
};

export default FormInput;
