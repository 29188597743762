import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ domicilioId, nombre, refresh }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`domicilios/${domicilioId}`}
      description={`¿Confirma que desea eliminar el domicilio <strong><u> ${nombre}</u> </strong>?`}
      loadData={refresh}
    />
  );
};

Delete.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  domicilioId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default Delete;
