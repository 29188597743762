import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import Table from './Table';
import { Col, Row } from 'react-bootstrap';
import SelectTipoCompra from '@components/forms/SelectTipoCompra';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import Select from '@components/forms/Select';

const List = () => {
  const [state, setState] = useState({
    list: [],
    loading: true,
    checkEnviarCopia: true,
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
    fechaInicio: moment().format('YYYY-MM-01'),
    fechaFin: moment().format('YYYY-MM-DD'),
    storehouses: [],
    clientesAEnviar: [],
    showModal: false,
    enviados: false,
    tipos_compras: [],
    agentes: [],
    estatus_compras: {
      CANCELADA: 'CANCELADA',
      PAUSADA: 'PAUSADA',
      FINALIZADA: 'FINALIZADA',
      INICIADA: 'INICIADA',
      EN_PROCESO: 'EN PROCESO',
    },
    estatus: [],
    estatus_compra: '0',
    tipoCompraId: '0',
    sucursalId: '0',
    agenteId: '0',
    sucursales: [],
  });

  const loadCatalogos = useCallback(async (callback) => {
    const {
      data: { sucursales, agente, agentes, tipos_compras },
    } = await getRequest({ url: `compras-catalogos` });
    setState((prevState) => ({
      ...prevState,
      tipos_compras: [
        {
          value: '0',
          label: 'Todas los tipos de compras',
        },
        ...tipos_compras.map((tipo_compra) => ({
          value: tipo_compra._id,
          label: `${tipo_compra.nombre}`,
        })),
      ],
      sucursales: [
        {
          value: '0',
          label: 'Todas las sucursales',
        },
        ...sucursales.map((sucursal) => ({
          value: sucursal._id,
          label: `${sucursal.name}`,
        })),
      ],
      agentes: [
        {
          value: '0',
          label: 'Todos los agentes',
        },
        ...agentes.map((agente) => ({
          value: agente._id,
          label: `${agente.nombre}`,
        })),
      ],
      estatus: [
        {
          value: '0',
          label: 'Todos los estatus',
        },
        ...Object.entries(prevState.estatus_compras).map(([key, value]) => ({
          value: key,
          label: value,
        })),
      ],
      agente: agente,
      agenteId: agente ? agente._id : null,
      sucursalId: agente ? agente.sucursalId : null,
      almacenId: agente ? agente.almacenId : null,
    }));
    if (callback) callback();
  }, []);

  useEffect(() => {
    loadCatalogos(handleSearch);
  }, [loadCatalogos]);

  const handleSearch = useCallback(() => {
    const { pagination, activePage, search, fechaFin, fechaInicio, estatus_compra, tipoCompraId, sucursalId } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    (async () => {
      const body = {
        search,
        fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
        fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
        estatus: estatus_compra,
        tipoCompraId,
        sucursalId,
      };
      const { data, count } = await postRequest({ url: 'compras/search', params: { page, limit: pagination }, body });
      setState((prevState) => ({
        ...prevState,
        list: data,
        count: count,
        loading: false,
      }));
    })();
  }, [state]);

  const onSearch = useCallback(
    (params = { search: '' }) => {
      setState((prevState) => ({
        ...prevState,
        search: params.search,
        activePage: 1,
        fechaInicio: params.fechaInicio,
        fechaFin: params.fechaFin,
      }));
      handleSearch();
    },
    [handleSearch]
  );

  const handlePagination = useCallback(
    (eventKey) => {
      setState((prevState) => ({ ...prevState, activePage: eventKey }));
      handleSearch();
    },
    [handleSearch]
  );

  const onSelect = useCallback(
    (name, value) => {
      setState((prevState) => ({ ...prevState, [name]: value }));
      handlePagination(1);
    },
    [handlePagination]
  );

  const { pagination, count, activePage, loading, tipoCompraId, sucursalId, agenteId, estatus, estatus_compra } = state;

  return (
    <Module title='Ordenes de compras'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col sm='2'>
            <div className='input-group'>
              <span className='input-group-text'>
                <Icon icon='tags' title='Tipos de pedidos' />
              </span>
              <SelectTipoCompra value={tipoCompraId} onChange={(value) => onSelect('tipoCompraId', value)} />
            </div>
          </Col>
          <Col sm='2'>
            <IfPermission action='TodasSucursales'>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='home' title='sucursales' />
                </span>
                <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
              </div>
            </IfPermission>
          </Col>
          <Col sm='2'>
            <IfPermission action='TodosAgentes'>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='user' title='Agentes' />
                </span>
                <SelectAgente value={agenteId} onChange={(value) => onSelect('agenteId', value)} />
              </div>
            </IfPermission>
          </Col>
          <Col sm='2'>
            <div className='input-group'>
              <span className='input-group-text'>
                <Icon icon='tags' title='Estatus' />
              </span>
              <Select value={estatus_compra} options={estatus} onChange={(value) => onSelect('estatus_compra', value)} />
            </div>
          </Col>
          <Col sm='4'>
            <IfPermission action='crear'>
              <Link to='/compras/nueva' className='btn btn-success pull-right'>
                <Icon icon='plus' /> Nuevo
              </Link>
            </IfPermission>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <Table {...state} loadData={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

List.propTypes = {};

export default List;
