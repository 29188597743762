import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import IfPermission from '@controls/IfPermission';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, DropdownButton, DropdownItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import IconDropdown from '@controls/IconDropdown';

const ESTATUS_COMPRAS = {
  CANCELADA: 'CANCELADA',
  PAUSADA: 'PAUSADA',
  INICIADA: 'INICIADA',
  EN_PROCESO: 'EN PROCESO',
  FINALIZADA: 'FINALIZADA',
};

const Table = ({ list, loadData }) => {
  const estatus_label = (estatus_compra) => {
    const colorMap = {
      CANCELADA: 'danger',
      PAUSADA: 'secondary',
      FINALIZADA: 'success',
      INICIADA: 'info',
      EN_PROCESO: 'primary',
    };
    const color = colorMap[estatus_compra] || 'primary';
    return <CbBadge type={color}>{ESTATUS_COMPRAS[estatus_compra]}</CbBadge>;
  };

  const onClickDownloadPdf = async (id, e) => {
    e.preventDefault();
    const { data } = await getRequest({ url: `compra/pdf/${id}` });
    if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
  };

  const renderRows = () => {
    return list.map((r, i) => {
      const { _id, fecha, numero_compra, importe, provider, estado, moneda, estatus_compra, tipoCompraId, agente, sucursal } = r;
      let currency = 'MXN';
      if (moneda === 'DOLAR' || moneda === 'DOLLAR' || moneda === 'DÓLAR') currency = 'USD';
      return (
        <tr key={i}>
          <td> {tipoCompraId ? tipoCompraId.nombre : null} </td>
          <td> {numero_compra}</td>
          <td> {moment(fecha).local().format('DD/MM/YYYY')} </td>
          <td> {provider ? provider.name : null} </td>
          <td> {sucursal ? sucursal.name : null} </td>
          <td> {agente ? agente.nombre : null} </td>
          <td>
            ${format(importe, 2)} <CbBadge type='primary'>{currency}</CbBadge>
          </td>
          <td>
            {' '}
            <span>{estatus_label(estatus_compra)}</span>{' '}
          </td>
          <td>
            <div className='btn-group pull-right'>
              <IfPermission action='pdf'>
                <OverlayTrigger placement='top' overlay={<Tooltip id={`${_id}-download`}>Descargar PDF</Tooltip>}>
                  <Button onClick={(e) => onClickDownloadPdf(_id, e)} className='btn btn-sm btn-outline-primary '>
                    <Icon icon='download-alt' />
                  </Button>
                </OverlayTrigger>
              </IfPermission>
              <IfPermission action='editar'>
                <If condition={(estatus_compra === 'INICIADA' || estatus_compra === 'EN_PROCESO') && estado !== 'CANCELADA'}>
                  <If.Then>
                    <RedirectTooltip
                      id={2}
                      icon='edit'
                      url={`/compras/edicion/${_id}`}
                      labelTooltip='Editar'
                      className='btn btn-sm btn-outline-primary'
                    />
                  </If.Then>
                </If>
              </IfPermission>
              <DropdownButton title={<IconDropdown />} pullRight>
                <If condition={(estatus_compra === 'INICIADA' || estatus_compra === 'EN_PROCESO') && estado !== 'CANCELADA'}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='remove'
                      typeConfirm='danger'
                      btnType='danger'
                      isMenu
                      action='Cancelar'
                      url={`compras/cancelar/${_id}`}
                      description={`¿Confirma que desea cancelar la <strong>orden de compra: <u>#${numero_compra}</u></strong> ?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <DropdownItem href={`/compras/${_id}`}>
                  <Icon icon='search' /> Detalles
                </DropdownItem>

                <If condition={estatus_compra === 'EN_PROCESO' && estado !== 'CANCELADA'}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='check'
                      btnType='success'
                      isMenu
                      action='Terminar'
                      url={`compras/terminar/${_id}`}
                      description={`¿Confirma que desea terminar la <strong>orden de compra: <u>#${numero_compra}</u></strong> ?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <If condition={estatus_compra === 'INICIADA' && estado !== 'CANCELADA'}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='share-alt'
                      btnType='primary'
                      isMenu
                      action='Procesar'
                      url={`compras/procesar/${_id}`}
                      description={`¿Confirma que desea procesar la <strong>orden de compra: <u>#${numero_compra}</u></strong>?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
              </DropdownButton>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='10%'>Tipo Compra</th>
          <th width='10%'># Compra</th>
          <th width='13%'>Fecha</th>
          <th width='25%'>Proveedor</th>
          <th width='15%'>Sucursal</th>
          <th width='15%'>Agente</th>
          <th width='10%'>Importe</th>
          <th width='6%'>Estatus</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Table;
