import { DivFormGroup, FormInput, Label } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import Required from '@controls/Required';
import AgregarCompra from './AgregarCompra';
import RowDetalle from '../edit/components/RowDetalle';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';
import SelectTipoCompra from '@components/forms/SelectTipoCompra';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import Select from 'react-select';

const NuevaRecepcion = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [state, setState] = useState({
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fecha_entrega: null,
    estatus: 'INICIADO',
    tipoCompraId: null,
    tipos_compras: [],
    detalles: [],
    sucursales: [],
    almacenes: [],
    ubicaciones: [],
    agente: null,
    agenteObj: null,
    agenteName: null,
    agenteId: null,
    sucursalId: null,
    almacenId: null,
    estatus_recepciones: [
      { value: 'INICIADO', label: 'INICIADO' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'FINALIZADO', label: 'FINALIZADO' },
    ],
    observaciones: '',
  });

  const loadCatalogos = useCallback(async (callback) => {
    const {
      data: { sucursales, agente, agentes, almacenes, tipos_compras, ubicaciones },
    } = await getRequest({ url: `recepcionescompras-catalogs` });
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      tipos_compras,
      listSucursales: sucursales,
      listAlmacenes: almacenes,
      sucursales: sucursales.map((sucursal) => ({
        value: sucursal._id,
        label: `${sucursal.name}`,
      })),
      almacenes,
      ubicaciones,
      agentes: agentes.map((agente) => ({
        value: agente._id,
        label: `${agente.nombre}`,
      })),
      agente: agente,
      agenteObj: agente,
      agenteId: agente ? agente._id : null,
      agenteName: agente ? agente.nombre : null,
      sucursalId: agente ? agente.sucursalId : null,
      almacenId: agente ? agente.almacenId : null,
    }));
    if (callback) callback();
  }, []);

  useEffect(() => {
    loadCatalogos();
  }, [loadCatalogos]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      compraId,
      tipoCompraId,
      estatus,
      numero_compra,
      numero_recepcion,
      fecha,
      fecha_entrega,
      agenteId,
      almacenId,
      sucursalId,
      detalles,
      observaciones,
    } = state;

    const data = {
      compraId,
      tipoCompraId,
      numero_compra,
      numero_recepcion,
      estatus,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      fecha_entrega: moment(fecha_entrega, 'YYYY-MM-DD').utc(),
      agenteId,
      almacenId,
      sucursalId,
      observaciones,
      detalles: detalles.map((x) => ({
        compraDetalleId: x._id,
        equipmentId: x.relacionadoId._id,
        unidadMedidaId: x.unidadMedidaId._id,
        codigo: x.codigo,
        descripcion: x.descripcion,
        ubicacionId: x.ubicacionId._id,
        numero_serial: x.numero_serial,
        pedimento: x.pedimento,
        observaciones: '',
        cantidad: x.cantidad,
        cantidad_comprada: x.cantidad,
      })),
    };

    if (validations(data)) {
      await postRequest({ url: `recepcionescompras`, body: data });
      setTimeout(() => navigate('/recepciones-compras'), 1000);
    }
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));
    if (campo === 'sucursalId') {
      setState((prevState) => ({
        ...prevState,
        almacenId: null,
        detalles: prevState.detalles.map((x) => ({
          ...x,
          ubicacionId: null,
          ubicacion: null,
        })),
      }));
    }
  };

  const onSeleccionarCompra = async (compra) => {
    setState((prevState) => ({
      ...prevState,
      tipoCompraId: compra.tipoCompraId._id,
      numero_compra: compra.numero_compra,
      compraId: compra._id,
      detalles: compra.detalle
        .filter((x) => x.tipo_partida === 'EQUIPO')
        .map((x) => ({
          ...x,
          cantidad_comprada: x.cantidad,
        })),
    }));
    const { data } = await getRequest({ url: `recepcionescompras/siguienteNumero`, params: { tipoCompraId: compra.tipoCompraId._id } });
    setState((prevState) => ({
      ...prevState,
      numero_recepcion: data.numero_recepcion_compra,
      sucursalId: compra.sucursal._id,
      almacenId: compra.almacen._id,
    }));
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        agente: itemSelect,
        agenteId: itemSelect._id,
        agenteObj: itemSelect,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        agenteName: '',
        agenteId: null,
        agente: null,
        agenteObj: null,
      }));
    }
  };

  const onChangeDetalle = (name, index, event) => {
    if (['cantidad', 'descuento', 'impuesto', 'precio'].includes(name)) {
      setState((prevState) => ({
        ...prevState,
        detalles: prevState.detalles.map((x, i) => {
          if (i === index) x[name] = cleanNumber(event.target.value);
          return { ...x };
        }),
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => {
        if (i === index) {
          x[name] = event.target.value;
        }
        return { ...x };
      }),
    }));
  };

  const onClickRemoveDetalle = (index, event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.filter((x, i) => i !== index),
    }));
  };

  const renderDetalles = () => {
    if (state.detalles.length === 0) return <RowsNotFound message='No se han agregado detalles.' colSpan={12} />;
    return state.detalles.map((detalle, i) => (
      <RowDetalle key={i} detalle={detalle} index={i} onChangeDetalle={onChangeDetalle} onClickRemoveDetalle={onClickRemoveDetalle} />
    ));
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    const now = new Date();

    if (!isMongoId(params.tipoCompraId + '')) {
      validate.success = false;
      validate.message = 'Tipo compra es requerido.';
    } else if (!moment(params.fecha).isValid()) {
      validate.success = false;
      validate.message = 'Fecha es requerida.';
    } else if (validate.success && moment(now).isSameOrBefore(params.fecha)) {
      validate.success = false;
      validate.message = 'Fecha no puede ser mayor al día de hoy.';
    } else if (validate.success && moment(params.fecha_entrega).isBefore(params.fecha)) {
      validate.success = false;
      validate.message = 'Fecha de entrega no puede ser menor a fecha.';
    } else if (!isMongoId(params.sucursalId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    } else if (!isMongoId(params.almacenId + '')) {
      validate.success = false;
      validate.message = 'Almacen es requerido.';
    } else if (!isMongoId(params.agenteId + '')) {
      validate.success = false;
      validate.message = 'Agente es requerido.';
    } else if (isEmpty(params.estatus + '')) {
      validate.success = false;
      validate.message = 'Estatus es requerido.';
    } else {
      params.detalles.forEach((detalle) => {
        if (isEmpty(detalle.cantidad + '') || detalle.cantidad <= 0) {
          validate.success = false;
          validate.message = 'Cantidad es requerida.';
        } else if (detalle.cantidad > detalle.cantidad_comprada) {
          validate.success = false;
          validate.message = 'Cantidad no puede ser mayor a cantidad comprada.';
        } else if (!isMongoId(detalle.ubicacionId + '')) {
          validate.success = false;
          validate.message = 'Ubicación es requerida.';
        }
      });
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const {
    isLoading,
    tipos_compras,
    tipoCompraId,
    numero_compra,
    numero_recepcion,
    fecha,
    fecha_entrega,
    observaciones,
    sucursalId,
    almacenId,
    agenteObj,
    estatus,
    estatus_recepciones,
  } = state;

  return (
    <Fragment>
      <Module onClickBack={window.history.back} title='Nueva recepción de compra' loading={isLoading}>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-4 col-xs-6'>
                <DivFormGroup>
                  <Label name='tipoCompraId' required title='Tipo compra' />
                  <SelectTipoCompra value={tipoCompraId} onChange={onSelect} name='tipoCompraId' />
                </DivFormGroup>
              </div>
              <div className='col-sm-2 col-xs-6'>
                <FormInput
                  title='Num. de compra'
                  disabled
                  placeholder='Num. de compra'
                  name='numero_compra'
                  onChange={onChange}
                  value={numero_compra}
                />
              </div>
              <div className='col-sm-2 col-xs-6'>
                <FormInput
                  title='Num. de orden'
                  disabled
                  placeholder='Num. de orden'
                  name='numero_recepcion'
                  onChange={onChange}
                  value={numero_recepcion}
                />
              </div>
              <div className='col-sm-2 col-xs-6'>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </div>
              <div className='col-sm-2 col-xs-6'>
                <FormInput title='Fecha de entrega' type='date' name='fecha_entrega' onChange={onChange} value={fecha_entrega} />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup>
                  <Label name='sucursalId' required title='Sucursal' />
                  <SelectSucursal value={sucursalId} onChange={onSelect} name='sucursalId' />
                </DivFormGroup>
              </div>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup>
                  <Label name='almacenId' required title='Almacen' />
                  <SelectAlmacen value={almacenId} onChange={onSelect} name='almacenId' filter={(x) => x.sucursalId === sucursalId?._id} />
                </DivFormGroup>
              </div>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup>
                  <Label name='agenteName' required title='Agente' />
                  <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                </DivFormGroup>
              </div>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup>
                  <Label name='estatus' required title='Estatus' />
                  <Select name='estatus' value={estatus} options={estatus_recepciones} onChange={(value) => onSelect('estatus', value)} />
                </DivFormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className='panel panel-default panel-table' style={{ overflow: 'scroll' }}>
          <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
            <thead>
              <tr>
                <th width='10%'>#</th>
                <th>Descripción</th>
                <th width='10%'>Unidad</th>
                <th>
                  Cantidad <Required />
                </th>
                <th>Cant. Comprada</th>
                <th>Cant. Recibida</th>
                <th>
                  Ubicación <Required />
                </th>
                <th># Serial</th>
                <th>Pedimento</th>
                <th width='5%'>&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>{renderDetalles()}</tbody>
          </table>
        </div>
        <div className='panel panel-default panel-table'>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </div>
            </div>
          </div>
        </div>
        <div className='panel panel-default'>
          <div className='panel-footer'>
            <div className='row'>
              <div className='col-sm-12'>
                <Saving saving={isLoading} />
                <button type='button' onClick={handleRegister} className='btn btn-success pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Module>
      <AgregarCompra tipos_compras={tipos_compras} onSeleccionarCompra={onSeleccionarCompra} />
    </Fragment>
  );
};

NuevaRecepcion.propTypes = {};

export default NuevaRecepcion;
