import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon, RowsNotFound } from '@stateless';
import { putRequest } from '@utils/api';

const Table = ({ list, loadData, onChangeChecked, onChangeCheckedAll, checkedAll }) => {
  const handleStatus = async (id, estado) => {
    await putRequest({ url: `user/notificaciones/read/${id}`, body: { estado } });
    loadData();
  };

  const renderRows = () => {
    return list.map((r, i) => (
      <tr key={i}>
        <td className='text-center'>
          <input type='checkbox' onChange={(e) => onChangeChecked(e.target.checked, i)} checked={r.checked} />
        </td>
        <td>{r._id.asunto}</td>
        <td>{r._id.mensaje}</td>
        <td>{r._id.userId.name}</td>
        <td>{r._id.estado === 'SIN_LEER' ? 'Sin leer' : 'Leído'}</td>
        <td>{moment(r._id.created).format('DD/MM/YYYY HH:mm:ss')}</td>
        <td>
          {r._id.estado === 'SIN_LEER' ? (
            <OverlayTrigger placement='top' overlay={<Tooltip id={`${i}-download`}>Marcar como leído</Tooltip>}>
              <button onClick={() => handleStatus(r._id._id, 'LEIDO')} className='btn btn-sm btn-primary mr-5'>
                <Icon icon='ok' />
              </button>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger placement='top' overlay={<Tooltip id={`${i}-download`}>Marcar como no leído</Tooltip>}>
              <button onClick={() => handleStatus(r._id._id, 'SIN_LEER')} className='btn btn-sm btn-warning mr-5'>
                <Icon icon='comment' />
              </button>
            </OverlayTrigger>
          )}
        </td>
      </tr>
    ));
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={8} />;

  return (
    <div className='panel panel-default panel-table'>
      <div className='table-responsive'>
        <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
          <thead>
            <tr>
              <th width='1%' className='text-center'>
                <input type='checkbox' onChange={(e) => onChangeCheckedAll(e.target.checked)} checked={checkedAll} />
              </th>
              <th width='5%'>Asunto</th>
              <th width='5%'>Mensaje</th>
              <th width='5%'>Emisor</th>
              <th width='5%'>Estatus</th>
              <th width='5%'>Fecha</th>
              <th width='5%'>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  onChangeChecked: PropTypes.func.isRequired,
  onChangeCheckedAll: PropTypes.func.isRequired,
  checkedAll: PropTypes.bool,
};

export default Table;
