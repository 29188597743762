import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import FormContacto from './FormContacto';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const NewContacto = ({ loadContactos, tiposContactos }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [tipos, setTipos] = useState([]);
  const [paterno, setPaterno] = useState('');
  const [materno, setMaterno] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [sexo, setSexo] = useState('');
  const [fecha_nacimiento, setFechaNacimiento] = useState(null);

  const close = () => {
    setShowModal(false);
    setName('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setName('');
    setTipos([]);
    setPaterno('');
    setMaterno('');
    setPhone('');
    setMobile('');
    setEmail('');
    setSexo('');
    setFechaNacimiento(null);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      name: name.trim(),
      tipos: tipos.map((x) => x.value),
      paterno,
      materno,
      phone,
      mobile,
      email,
      sexo: sexo.value,
      fecha_nacimiento,
    };

    if (validations(data)) {
      await postRequest({ url: `contactos`, body: data });
      setShowModal(false);
      setName('');
      setIsLoading(false);
      loadContactos();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.tipos + '')) {
      validate.success = false;
      validate.message = 'Tipos es requerido.';
    } else if (isEmpty(params.name + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.phone + '')) {
      validate.success = false;
      validate.message = 'Teléfono es requerido.';
    } else if (isEmpty(params.email + '')) {
      validate.success = false;
      validate.message = 'Correo electrónico es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' size='lg' onSave={handleRegister} isLoading={isLoading}>
        <FormContacto
          name={name}
          tipos={tipos}
          paterno={paterno}
          materno={materno}
          phone={phone}
          mobile={mobile}
          email={email}
          sexo={sexo}
          fecha_nacimiento={fecha_nacimiento}
          tiposContactos={tiposContactos}
          setState={(state) => {
            if (state.name !== undefined) setName(state.name);
            if (state.tipos !== undefined) setTipos(state.tipos);
            if (state.paterno !== undefined) setPaterno(state.paterno);
            if (state.materno !== undefined) setMaterno(state.materno);
            if (state.phone !== undefined) setPhone(state.phone);
            if (state.mobile !== undefined) setMobile(state.mobile);
            if (state.email !== undefined) setEmail(state.email);
            if (state.sexo !== undefined) setSexo(state.sexo);
            if (state.fecha_nacimiento !== undefined) setFechaNacimiento(state.fecha_nacimiento);
          }}
        />
      </CbModal>
    </>
  );
};

NewContacto.propTypes = {
  loadContactos: PropTypes.func.isRequired,

  tiposContactos: PropTypes.array.isRequired,
};

export default NewContacto;
