import moment from 'moment';
import { TEMPORALIDAD } from '@config/constants';

export const getPhotoFb = (idFB) => {
  return `https://graph.facebook.com/${idFB}/picture?type=large`;
};

export const clearStr = (str) => {
  return parseFloat(str.replace('"', null));
};

export const getPercentage = (have, should) => {
  return (have * 100) / should;
};

export const sortConversations = (a, b) => {
  if (a.no_read_admin < b.no_read_admin) {
    return 1;
  }

  if (a.no_read_admin > b.no_read_admin) {
    return -1;
  }

  if (moment(a.last_message) < moment(b.last_message) || a.no_read_admin > 0) {
    return 1;
  }

  if (moment(a.last_message) > moment(b.last_message) || b.no_read_admin > 0) {
    return -1;
  }

  return 0;
};

export const printReferenceOxxo = (reference) => {
  const ref1 = reference.substring(0, 4);
  const ref2 = reference.substring(4, 8);
  const ref3 = reference.substring(8, 12);
  const ref4 = reference.substring(12, reference.length);
  return `${ref1}-${ref2}-${ref3}-${ref4}`;
};

export const switchColorStock = (store) => {
  let color = 'default';

  if (typeof store !== 'undefined') {
    if (typeof store.stock !== 'undefined' && typeof store.max !== 'undefined' && typeof store.min !== 'undefined') {
      if (store.stock >= store.max) {
        color = 'success';
      } else if (store.stock <= store.min) {
        color = 'danger';
      } else {
        color = 'primary';
      }
    }
  }

  return color;
};

export const renderNombreCliente = (cliente) => {
  let view = '';
  if (cliente) {
    if (cliente.regimen === 'F') {
      let titulo = '';

      if (cliente.tituloClienteId) {
        titulo = cliente.tituloClienteId.abreviatura;
      }
      view = `${titulo} ${cliente.name} ${cliente.paterno} ${cliente.materno}`;
    } else {
      view = cliente.razon_social;
    }
  }
  return view;
};

export const switchEstado = (temporalidad) => {
  if (temporalidad === TEMPORALIDAD.DIARIO) {
    return { text: 'Diario' };
  } else if (temporalidad === TEMPORALIDAD.SEMANAL) {
    return { text: 'Semanal' };
  } else if (temporalidad === TEMPORALIDAD.MENSUAL) {
    return { text: 'Mensual' };
  } else if (temporalidad === TEMPORALIDAD.ANUAL) {
    return { text: 'Anual' };
  } else if (temporalidad === TEMPORALIDAD.EVENTO) {
    return { text: 'Por evento' };
  }
};
