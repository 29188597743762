import DetailCustomer from '@components/customers/DetailCustomer';
import { RedirectTooltip } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, DropdownButton, DropdownItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableThSort from '@cbcomponents/CbTableThSort';
import Delete from '../delete/Delete';
import LoginCustomers from '../LoginCustomers';
import Contactos from './Contactos';
import Contratos from './Contratos';
import Domicilios from './Domicilios';
import EnviarReferencias from './EnviarReferencias';
import Referencias from './Referencias';
import Seguimientos from './Seguimientos';
import IconDropdown from '@controls/IconDropdown';
import CbTableResponsive from '@components/application/controls/CbTableResponsive';
import useAuth from '@hooks/useAuth';
import OrdenesServicios from './OrdenesServicios';

const TipoRegimenes = {
  I: { label: 'Informal', type: 'secondary' },
  F: { label: 'Física', type: 'warning' },
  M: { label: 'Moral', type: 'primary' },
  E: { label: 'Extranjero', type: 'success' },
};

const List = (props) => {
  const { list, estados, paises, tiposContactos, orden, tipo, refresh, onChangeOrden } = props;
  const { geolocation } = useAuth();
  const mostrarTipo = (regimen) => {
    return <CbBadge type={TipoRegimenes[regimen]?.type}>{TipoRegimenes[regimen]?.label}</CbBadge>;
  };

  const renderRows = () => {
    return list.map((row, key) => {
      const {
        _id,
        numero_cliente,
        mobile,
        email,
        rfc,
        url,
        urlEncode,
        regimen,
        login_activo,
        listDomicilios,
        listContactos,
        listReferencias,
        clasification,
      } = row;
      return (
        <tr key={key}>
          <td>{numero_cliente}</td>
          <td>
            <DetailCustomer customer={row} />
          </td>
          <td>{mostrarTipo(regimen, rfc)}</td>
          <td>{rfc}</td>
          <td>{clasification?.description}</td>
          <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
            <ButtonGroup>
              <RedirectTooltip
                id={key}
                labelTooltip='Editar'
                url={`/clientes/editar/${row._id}`}
                icon='pencil'
                className='btn btn-sm btn-outline-success'
              />
              <Domicilios id={_id} listDomicilios={listDomicilios} estados={estados} paises={paises} loadData={refresh} position={geolocation} />
              <Contactos id={_id} listContactos={listContactos} tiposContactos={tiposContactos} loadData={refresh} />
              <Delete id={_id} {...row} name={numero_cliente} refresh={refresh} />
              <DropdownButton title={<IconDropdown />} as={ButtonGroup} variant='outline-dark' size='sm'>
                <DropdownItem href={`/clientes/detalle/${row._id}`}>
                  <Icon icon='zoom-in' /> Detalle
                </DropdownItem>
                <Contratos id={_id} loadData={refresh} />
                <Referencias id={_id} listReferencias={listReferencias} loadData={refresh} />
                <OrdenesServicios id={_id} />
                <DropdownItem
                  component={Link}
                  href={`https://api.whatsapp.com/send?phone=52${mobile}&text=Liga+de+pago+${urlEncode}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon icon='comment' /> Enviar a WhatsApp
                </DropdownItem>
                <DropdownItem href={`cotizaciones/nuevo/cliente/${row._id}`}>
                  <Icon icon='file' /> Cotizar
                </DropdownItem>
                <DropdownItem component={Link} href={url} target='_blank' rel='noopener noreferrer'>
                  <Icon icon='globe' /> Pago en linea
                </DropdownItem>
                <DropdownItem href={`clientes/ubicaciones/${row._id}/contactos`}>
                  <Icon icon='bookmark' /> Ubicaciones
                </DropdownItem>
                <DropdownItem href={`seguimientos/seguimiento/${row._id}`}>
                  <Icon icon='screenshot' /> Seguimientos
                </DropdownItem>
                <EnviarReferencias cliente={row} refresh={refresh} />
                <LoginCustomers
                  id={_id}
                  {...props}
                  email={email}
                  refresh={refresh}
                  login_activo={login_activo}
                  btnClass='btn btn-outline-danger mr-5'
                />
                <Seguimientos id={_id} loadData={refresh} />
              </DropdownButton>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  const establecerOrden = (orden, tipo, campo) => {
    onChangeOrden(campo, orden === campo ? tipo * -1 : -1);
  };

  return (
    <CbTableResponsive>
      <thead>
        <tr>
          <CbTableThSort width='5%' orden={orden} tipo={tipo} name='numero_cliente' onSort={establecerOrden}>
            #
          </CbTableThSort>
          <CbTableThSort width='35%' orden={orden} tipo={tipo} name='razon_social' onSort={establecerOrden}>
            Cliente
          </CbTableThSort>
          <CbTableThSort width='5%' orden={orden} tipo={tipo} name='regimen' onSort={establecerOrden}>
            Regimen
          </CbTableThSort>
          <CbTableThSort width='10%' orden={orden} tipo={tipo} name='rfc' onSort={establecerOrden}>
            RFC
          </CbTableThSort>
          <CbTableThSort width='5%' orden={orden} tipo={tipo} name='clasification' onSort={establecerOrden}>
            Clasificación
          </CbTableThSort>
          <td width='20%'></td>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  estados: PropTypes.array.isRequired,
  tiposContactos: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  position: PropTypes.object.isRequired,
  onChangeOrden: PropTypes.func.isRequired,
  orden: PropTypes.string.isRequired,
  tipo: PropTypes.number.isRequired,
  paises: PropTypes.array.isRequired,
};

export default List;
