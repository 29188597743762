import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ id, name, loadData }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`recetas/${id}`}
      description={`¿Confirma que desea eliminar la receta <strong><u> ${name}</u> </strong>?`}
      loadData={loadData}
    />
  );
};

Delete.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Delete;
