import { Confirmation } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';

const Delete = ({ itemId, data: { fecha, valor }, loadData }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`tipo-cambio/${itemId}`}
      description={`¿Confirma que desea eliminar el tipo de cambio <strong><u> ${moment(fecha).local().format('DD/MM/YYYY')}</u> </strong> por <strong>$ ${format(valor, 2)}</strong>?`}
      loadData={loadData}
    />
  );
};

Delete.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Delete;
