import React, { useRef, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from '@utils/api';
import { ProductOption } from '@stateless';
import PropTypes from 'prop-types';

function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

const SelectEquipoServicio = ({ onChange, name, value }) => {
  const refCliente = useRef(null);

  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];
    const [equipos, servicios] = await Promise.all([
      getRequest({ url: `equipments-autocomplete`, params: { query: inputValue } }),
      getRequest({ url: `services-autocomplete`, params: { query: inputValue } }),
    ]);
    return [...equipos.data.map((x) => ({ ...x, tipo_cliente: 'EQUIPO' })), ...servicios.data.map((x) => ({ ...x, tipo_cliente: 'SERVICIO' }))];
  };

  const onSelect = (selected) => {
    onChange(selected);
  };

  const debouncedLoadOptions = useCallback(
    debounce((inputValue, callback) => {
      loadOptions(inputValue).then(callback);
    }, 500),
    []
  );

  return (
    <AsyncSelect
      inputId={name}
      styles={{ control: (base) => ({ ...base, height: '38px', width: '100%' }) }}
      value={value}
      loadOptions={debouncedLoadOptions}
      onChange={onSelect}
      getOptionLabel={(x) => `${x.code}`}
      getOptionValue={(x) => x._id}
      isClearable
      placeholder={'Buscar por  código o descripción  '}
      ref={refCliente}
      components={{ Option: ProductOption }}
    />
  );
};


SelectEquipoServicio.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
};

export default SelectEquipoServicio;
