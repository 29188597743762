import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { RowsNotFound } from '@stateless';
import { format } from '@utils/parseCost';

const List = (props) => {
  const renderRows = () => {
    const { list } = props;
    return list.map((row, key) => {
      const {
        numero_levantamiento,
        tipo_cliente,
        customer,
        customerId,
        importe,
        fecha,
        tipo_levantamiento,
        horas_cotizadas,
        importe_horas_cotizadas,
        horas_sugeridas,
        importe_horas_sugeridas,
      } = row;
      const diferencia = Math.ceil(horas_cotizadas) - Math.ceil(horas_sugeridas);
      const horas_diferencia =
        diferencia > 0 ? Math.ceil(horas_cotizadas) - Math.ceil(horas_sugeridas) : Math.ceil(horas_sugeridas) - Math.ceil(horas_cotizadas);
      const importe_horas_diferencia =
        diferencia > 0 ? importe_horas_cotizadas - importe_horas_sugeridas : importe_horas_sugeridas - importe_horas_cotizadas;

      return (
        <tr key={key}>
          <td>
            {tipo_levantamiento ? tipo_levantamiento.nombre : null} {numero_levantamiento}
          </td>
          <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
          <td>
            <Link
              to={`/${tipo_cliente === 'CLIENTE' ? 'clientes' : 'prospectos'}/detalle/${customer ? customer._id : null}`}
              target='_blank'
              rel='noreferrer'
            >
              {customer ? customer.razon_social : null}
            </Link>
            {customerId && customerId.nombre_comercial && (
              <span style={{ fontSize: '10px', fontWeight: 700 }}>
                {' '}
                <br /> {customerId.nombre_comercial}
              </span>
            )}
          </td>
          <td>
            <strong> {format(horas_cotizadas, 0)} </strong>
          </td>
          <td>
            <strong>$ {format(importe_horas_cotizadas, 2)} </strong>
          </td>
          <td>
            <strong> {format(horas_sugeridas, 0)} </strong>
          </td>
          <td>
            <strong>$ {format(importe_horas_sugeridas, 2)} </strong>
          </td>
          <td className={diferencia >= 0 ? 'success' : 'danger'}>
            <strong> {format(horas_diferencia, 0)} </strong>
          </td>
          <td className={diferencia >= 0 ? 'success' : 'danger'}>
            <strong>$ {format(importe_horas_diferencia, 2)} </strong>
          </td>
          <td>
            <strong>$ {format(importe, 2)} </strong>
          </td>
        </tr>
      );
    });
  };

  const obtenerClase = (orden, tipo, campo) => {
    if (orden !== campo) return 'sorting';
    if (tipo === -1) return 'sorting_desc';
    return 'sorting_asc';
  };

  const establecerOrden = (orden, tipo, campo) => {
    props.loadData({ page: 0, orden: campo, tipo: orden === campo ? tipo * -1 : -1 });
  };

  const { list, orden, tipo } = props;
  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={11} />;

  return (
    <div className='panel panel-default panel-table'>
      <div className='table-responsive'>
        <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
          <thead>
            <tr>
              <th
                rowSpan={2}
                className={obtenerClase(orden, tipo, 'numero_levantamiento')}
                width='5%'
                onClick={() => {
                  establecerOrden(orden, tipo, 'numero_levantamiento');
                }}
              >
                #
              </th>
              <th
                rowSpan={2}
                className={obtenerClase(orden, tipo, 'fecha')}
                width='10%'
                onClick={() => {
                  establecerOrden(orden, tipo, 'fecha');
                }}
              >
                Fecha
              </th>
              <th
                rowSpan={2}
                className={obtenerClase(orden, tipo, 'cliente')}
                onClick={() => {
                  establecerOrden(orden, tipo, 'cliente');
                }}
              >
                Cliente / Prospecto
              </th>
              <th width='10%' colSpan={2} style={{ textAlign: 'center' }}>
                Cotizadas
              </th>
              <th width='10%' colSpan={2} style={{ textAlign: 'center' }}>
                Sugeridas
              </th>
              <th width='10%' colSpan={2} style={{ textAlign: 'center' }}>
                Diferencias
              </th>
              <th
                rowSpan={2}
                className={obtenerClase(orden, tipo, 'importe')}
                width='10%'
                onClick={() => {
                  establecerOrden(orden, tipo, 'importe');
                }}
              >
                Total
              </th>
            </tr>
            <tr>
              <th>Horas</th>
              <th>Importe</th>
              <th>Horas</th>
              <th>Importe</th>
              <th>Horas</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

List.propTypes = {
  tipos_pedidos: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired,
  listAlmacenes: PropTypes.array.isRequired,
  listSucursales: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  agenteId: PropTypes.string.isRequired,
  sucursalId: PropTypes.string.isRequired,
  almacenId: PropTypes.string.isRequired,
  orden: PropTypes.string.isRequired,
  tipo: PropTypes.number.isRequired,
};

export default List;
