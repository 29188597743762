import { DivFormGroup } from '@controls';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, DropdownButton, DropdownItem, Modal, Row } from 'react-bootstrap';
import { Icon, Loading, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { isEmpty, isMongoId } from 'validator';
import { CbPagination } from '@controls';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import FormSeguimiento from './components/FormSeguimiento';
import RowSeguimientoCliente from './components/RowSeguimientoCliente';
import { useStoreNotification } from '@stores/catalogs.store';

const Nuevo = ({ load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    loading: false,
    activePage: 1,
    clientes: [],
    prospectos: [],
    search: '',
    count: 0,
    pagination: 20,
    showModal: false,
    maximo_descuento: 0,
    isLoading: false,
    resultados: [],
    motivos: [],
    agentes: [],
    agentes_asignados: [],
    fecha: moment().format('YYYY-MM-DD'),
    hora: moment().format('HH:mm'),
    motivo: null,
    resultadoVisita: null,
    observaciones: '',
    titulo: '',
    fechaProximaVisita: null,
    horaProximaVisita: null,
    fechaFinalizacion: null,
    tieneProximaVisita: false,
    motivoProximaVisita: null,
    agenteProximavisitaId: null,
    agenteProximavisita: null,
    agenteProximavisitaName: '',
    agenteId: null,
    agente: null,
    agenteName: '',
    customerId: null,
    prospectoId: null,
    contactoId: null,
    contacto: '',
    tipo: 'CLIENTE',
    cliente: null,
    telefonos: [],
    correos: [],
    estatus: 'INICIADO',
    opciones_estatus_seguimientos: [
      { value: 'INICIADO', label: 'INICIADO' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'PENDIENTE', label: 'PENDIENTE' },
      { value: 'FINALIZADO', label: 'FINALIZADO' },
    ],
  });

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  const open = async () => {
    const {
      data: { agente, agentes, motivos, resultados_visitas },
    } = await getRequest({ url: `seguimiento-catalogos` });
    setState((prevState) => ({
      ...prevState,
      agentes,
      agente,
      agenteProximavisita: null,
      motivos,
      resultados: resultados_visitas,
      showModal: true,
      isLoading: false,
      fecha: moment().format('YYYY-MM-DD HH:mm'),
      motivo: null,
      resultadoVisita: null,
      fechaProximaVisita: null,
      fechaFinalizacion: null,
      tieneProximaVisita: false,
      motivoProximaVisita: null,
      customerId: null,
      contactoId: null,
      contacto: '',
      telefonos: [],
      correos: [],
      activePage: 1,
      clientes: [],
      search: '',
      titulo: '',
      observaciones: '',
      count: 0,
      nuevoContacto: false,
      cliente: null,
      domicilioId: null,
      domicilios: [],
      agentes_asignados_id: null,
      agentes_asignados: [],
    }));
  };

  const handleSearch = async () => {
    const { pagination, activePage, search, tipo } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      search,
    };
    if (tipo === 'CLIENTE') {
      const { data, count } = await postRequest({ url: `contactos-clientes/search`, params: { page, limit: pagination }, body });
      setState((prevState) => ({
        ...prevState,
        clientes: Object.values(
          data.reduce((acc, { customerId, ...currentValue }) => {
            if (!acc[customerId._id]) {
              acc[customerId._id] = {
                customerId: customerId,
                contactos: [],
              };
            }
            acc[customerId._id].contactos.push({
              ...currentValue,
            });
            return acc;
          }, {})
        ),
        count: count,
        loading: false,
      }));
    } else {
      const { data, count } = await postRequest({ url: `prospectos/search`, params: { page, limit: pagination }, body });
      setState((prevState) => ({
        ...prevState,
        prospectos: data,
        count,
        loading: false,
      }));
    }
  };

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }), handleSearch);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      cliente,
      contactoId,
      telefonos,
      correos,
      agente,
      agentes_asignados,
      motivo,
      observaciones,
      estatus,
      nombre,
      paterno,
      materno,
      titulo,
      tipo,
    } = state;

    const data = {
      tipo,
      customerId: tipo == 'CLIENTE' && cliente?._id,
      prospectoId: tipo != 'CLIENTE' && cliente?._id,
      contactoId,
      nombre,
      paterno,
      materno,
      telefonos: telefonos.map((x) => x.value).join(','),
      correos: correos.map((x) => x.value).join(','),
      agenteId: agente?._id,
      agentes_asignados: agentes_asignados ? agentes_asignados.map((x) => x._id) : [],
      fecha: moment().utc().format(),
      motivoId: motivo ? motivo.value : null,
      observaciones,
      titulo,
      estatus: estatus?.value,
    };

    if (validations(data)) {
      const { data: responseData } = await postRequest({ url: 'seguimientos/cliente/nuevo', body: data });
      if (responseData) {
        setState((prevState) => ({
          ...prevState,
          showModal: false,
          nombre: '',
        }));
        load();
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (state.search.length >= 3) {
        handleSearch();
      }
    }, 800);
    return () => {
      clearTimeout(timeout);
    };
  }, [state.search]);

  const onChangeSearch = (event) => {
    const { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      search: value,
    }));
  };

  const onSelectContacto = (r, c, telefonos = [], correos = []) => {
    const contacto = c ? `${c.name || ''} ${c.paterno || ''} ${c.materno || ''}` : '';
    setState((prevState) => ({
      ...prevState,
      cliente: r.customerId,
      contacto,
      nombre: c && c.name,
      paterno: c && c.paterno,
      materno: c && c.materno,
      contactoId: c && c._id,
      telefonos: telefonos.map((x) => ({ value: x, label: x })),
      correos: correos.map((x) => ({ value: x, label: x })),
    }));
  };

  const renderList = () => {
    const { activePage, count, pagination, clientes } = state;
    return (
      <>
        <CbPanelTableResponsive>
          <thead>
            <tr>
              <th width='5%'>&nbsp;</th>
              <th width='5%'>#</th>
              <th>Cliente</th>
            </tr>
          </thead>
          <CbTableBody colSpan={3}>
            {clientes.map((r, i) => (
              <RowSeguimientoCliente key={i} cliente={r} onSelectContacto={onSelectContacto} />
            ))}
          </CbTableBody>
        </CbPanelTableResponsive>
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </>
    );
  };

  const onSelectProspecto = (r, telefonos, correos) => {
    setState((prevState) => ({
      ...prevState,
      prospectoId: r._id,
      nombre: r && r.name,
      paterno: r && r.paterno,
      materno: r && r.materno,
      telefonos: telefonos.map((x) => ({ value: x, label: x })),
      correos: correos.map((x) => ({ value: x, label: x })),
    }));
  };

  const renderRowsProspectos = () => {
    const { prospectos } = state;
    return prospectos.map((r) => {
      const telefonos = [];
      const correos = [];
      if (r.phone) telefonos.push(r.phone);
      if (r.email) correos.push(r.email);
      return (
        <>
          <tr style={{ cursor: 'pointer' }} onDoubleClick={() => onSelectProspecto(r, telefonos, correos)}>
            <th>&nbsp;</th>
            <td>{renderNombreCliente(r)}</td>
            <td>
              {telefonos.map((x, j) => (
                <CbBadge key={j} type='primary'>
                  {x}
                </CbBadge>
              ))}
            </td>
            <td>
              {correos.map((x, j) => (
                <CbBadge key={j} type='primary'>
                  {x}
                </CbBadge>
              ))}
            </td>
          </tr>
        </>
      );
    });
  };

  const onNuevoProspecto = () => {
    setState((prevState) => ({
      ...prevState,
      nuevoContacto: true,
      prospectoId: null,
      nombre: '',
      paterno: '',
      materno: '',
      telefonos: [],
      correos: [],
    }));
  };

  const renderListProspectos = () => {
    const { activePage, count, pagination } = state;
    return (
      <>
        <CbPanelTableResponsive>
          <thead>
            <tr>
              <th width='5%'>&nbsp;</th>
              <th>Prospecto</th>
              <th>Teléfono</th>
              <th>Correo</th>
            </tr>
          </thead>
          <CbTableBody colSpan={4}>
            {renderRowsProspectos()}
            <tr style={{ cursor: 'pointer' }} onClick={onNuevoProspecto} className='active'>
              <td colSpan={5} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Nuevo prospecto
              </td>
            </tr>
          </CbTableBody>
        </CbPanelTableResponsive>
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </>
    );
  };

  const onChangeSeguimiento = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.agenteId + '')) {
      validate.success = false;
      validate.message = 'Agente es requerido.';
    } else if (!moment(params.fecha).isValid()) {
      validate.success = false;
      validate.message = 'Fecha es requerida.';
    } else if (validate.success && moment.utc().isSameOrBefore(params.fecha)) {
      validate.success = false;
      validate.message = 'Fecha no puede ser mayor al día de hoy.';
    } else if (isEmpty(params.titulo + '')) {
      validate.success = false;
      validate.message = 'Titulo es requerido.';
    } else if (isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  const {
    showModal,
    isLoading,
    loading,
    motivos,
    motivo,
    observaciones,
    agente,
    search,
    telefonos,
    correos,
    nuevoContacto,
    cliente,
    estatus,
    opciones_estatus_seguimientos,
    agentes_asignados,
    agentes,
    tipo,
    nombre,
    paterno,
    materno,
    titulo,
    prospectoId,
  } = state;

  const moduleList = !loading ? tipo === 'CLIENTE' ? renderList() : renderListProspectos() : <Loading />;

  return (
    <>
      <CbButtonOver icon='plus' onClick={open} hiddenXs type='success' />
      <Modal size='xl' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Seguimiento</Modal.Title>
        </Modal.Header>
        <If condition={!cliente && !prospectoId && !nuevoContacto}>
          <If.Then>
            <Modal.Body>
              <Row>
                <Col sm='12'>
                  <DivFormGroup>
                    <div className='input-group'>
                      <div className='input-group-btn'>
                        <DropdownButton title={tipo} id='dropdown-basic'>
                          <DropdownItem eventKey={1} active={false} onClick={() => setState((prevState) => ({ ...prevState, tipo: 'CLIENTE' }))}>
                            CLIENTE
                          </DropdownItem>
                          <DropdownItem eventKey={1} active={false} onClick={() => setState((prevState) => ({ ...prevState, tipo: 'PROSPECTO' }))}>
                            PROSPECTO
                          </DropdownItem>
                        </DropdownButton>
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        name='search'
                        value={search}
                        onChange={onChangeSearch}
                        placeholder={'Buscar por rázon social, nombre comercial, télefonos, correos eléctroncios ...'}
                      />
                    </div>
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>{moduleList}</Col>
              </Row>
            </Modal.Body>
          </If.Then>
          <If.Else>
            <Modal.Body>
              <FormSeguimiento
                tipo={tipo}
                cliente={cliente}
                nombre={nombre}
                paterno={paterno}
                materno={materno}
                telefonos={telefonos}
                correos={correos}
                agente={agente}
                agentes_asignados={agentes_asignados}
                agentes={agentes}
                motivo={motivo}
                motivos={motivos}
                estatus={estatus}
                opciones_estatus_seguimientos={opciones_estatus_seguimientos}
                titulo={titulo}
                observaciones={observaciones}
                onChange={onChangeSeguimiento}
              />
            </Modal.Body>
          </If.Else>
        </If>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={close} disabled={isLoading} variant='default pull-right mr-5' type='button'>
            Cerrar
          </Button>
          <Button onClick={handleRegister} variant='primary pull-right mr-5' disabled={isLoading}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Nuevo.propTypes = {
  load: PropTypes.func.isRequired,
};

export default Nuevo;
