import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clave, setClave] = useState('');
  const [nombre, setNombre] = useState('');
  const [multiples, setMultiples] = useState(false);

  const close = () => {
    setShowModal(false);
    setClave('');
    setNombre('');
    setMultiples(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const data = {
      clave: clave.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      multiples: multiples,
    };
    if (validations(data)) {
      await postRequest({ url: `documentos-empleados`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.clave)) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre)) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' size='lg' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput title='Clave' name='clave' required onChange={(e) => setClave(e.target.value)} value={clave} />
          </Col>
          <Col>
            <FormInput title='Nombre' name='nombre' required onChange={(e) => setNombre(e.target.value)} value={nombre} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup>
              <div className='checkbox'>
                <label className='control-label'>
                  <input name='multiples' type='checkbox' checked={multiples} onChange={(e) => setMultiples(e.target.checked)} />{' '}
                  <strong>Múltiples archivos</strong>
                </label>
              </div>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  unidades: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default New;
