import { TextCurrency } from '@controls';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { getDescuento, getImporte, getIVA, getTotal } from '@utils/cotizaciones';

const Totales = ({ detalles, anticipo, moneda, colSpan }) => {
  const colSpanSpace = colSpan - 3;
  return detalles.length > 0 ? (
    <Fragment>
      <tr>
        <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan={colSpanSpace} className='text-right'>
          Subtotal
        </th>
        <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='2' className='text-right'>
          <TextCurrency moneda={moneda}>{getImporte(detalles)}</TextCurrency>
        </td>
        <th style={{ padding: '4px 8px', verticalAlign: 'middle' }}></th>
      </tr>
      {getDescuento(detalles) > 0 ? (
        <tr>
          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan={colSpanSpace} className='text-right'>
            Descuento
          </th>
          <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='2' className='text-right'>
            <TextCurrency moneda={moneda}>{getDescuento(detalles)}</TextCurrency>
          </td>
          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }}></th>
        </tr>
      ) : null}
      {getIVA(detalles) > 0 ? (
        <tr>
          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan={colSpanSpace} className='text-right'>
            IVA
          </th>
          <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='2' className='text-right'>
            <TextCurrency moneda={moneda}>{getIVA(detalles)}</TextCurrency>
          </td>
          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }}></th>
        </tr>
      ) : null}
      <tr>
        <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan={colSpanSpace} className='text-right'>
          Total
        </th>
        <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='2' className='text-right'>
          <TextCurrency moneda={moneda}>{getTotal(detalles)}</TextCurrency>
        </th>
        <th style={{ padding: '4px 8px', verticalAlign: 'middle' }}></th>
      </tr>
      {anticipo ? (
        <tr>
          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan={colSpanSpace} className='text-right'>
            Anticipo
          </th>
          <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='2' className='text-right'>
            <TextCurrency moneda={moneda}>{(getTotal(detalles) / 100) * anticipo}</TextCurrency>
          </td>
          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }}></th>
        </tr>
      ) : null}
    </Fragment>
  ) : null;
};

Totales.propTypes = {
  detalles: PropTypes.array,
  moneda: PropTypes.string,
  anticipo: PropTypes.number,
  colSpan: PropTypes.number,
};

export default Totales;
