import { DivFormGroup, FormInput } from '@controls';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { clean } from '@utils/formatter';
import InputCurrency from '@components/forms/InputCurrency';
import InputPercentage from '@components/forms/InputPercentage';

const getCosto = ({ tipo_costeo, cost_average, last_cost, cost }) => {
  switch (tipo_costeo?.codigo) {
    case 'PROMEDIO':
      return cost_average;
    case 'ULTIMO_COSTO':
      return last_cost;
    case 'COSTO_MAYOR':
      return last_cost > cost_average ? last_cost : cost_average;
    default:
      return cost;
  }
};

const getPrecio = ({ tipo_costeo, cost, margen_ganancia, precio, tipo_cambio }) => {
  switch (tipo_costeo?.codigo) {
    case 'ESTATICO':
      return precio;
    default:
      precio = cost * tipo_cambio;
      return precio + precio * (margen_ganancia / 100);
  }
};

const FormEquipmentCostos = (props) => {
  const onChangeMask = (e) => {
    const { value, name } = e.target;
    let { cost_average, last_cost, cost, tipo_costeo, precio, tipo_cambio, margen_ganancia } = props;
    cost = getCosto({ tipo_costeo, cost_average, last_cost, cost, [name]: clean(value) });
    props.onChange({
      [name]: clean(value),
      cost,
      precio: getPrecio({ tipo_costeo, precio, tipo_cambio, margen_ganancia, cost, [name]: clean(value) }),
    });
  };

  const onSelectMoneda = (name, value) => {
    props.onChange({
      [name]: value,
    });
  };

  const onSelectCosteo = (tipo_costeo) => {
    let { cost_average, last_cost, cost } = props;
    props.onChange({
      tipo_costeo,
      cost: getCosto({ tipo_costeo, cost_average, last_cost, cost }),
    });
  };

  const {
    last_cost,
    margen_ganancia,
    precio,
    date_last_cost,
    iva,
    moneda,
    moneda_compra,
    cost_average,
    tipos_costeo,
    tipo_costeo,
    cost,
    tipo_cambio,
    monedas,
  } = props;

  return (
    <div className='panel panel-default'>
      <div className='panel-body'>
        <Row>
          <Col sm='2'>
            <DivFormGroup name='moneda_compra' title='Moneda compra' required>
              <Select
                value={moneda_compra}
                placeholder='Seleccionar'
                options={monedas}
                onChange={(value) => onSelectMoneda('moneda_compra', value)}
                className='basic-multi-select'
                classNamePrefix='select'
              />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='tipo_costeo' title='Tipo costeo' required>
              <Select
                value={tipo_costeo}
                placeholder='Seleccionar'
                options={tipos_costeo}
                onChange={onSelectCosteo}
                getOptionLabel={(option) => option.descripcion}
                getOptionValue={(option) => option.codigo}
                className='basic-multi-select'
                classNamePrefix='select'
              />
            </DivFormGroup>
          </Col>
          <IfPermission action='costos-margen'>
            <Col sm='2'>
              <DivFormGroup name='cost_average' title='Costo promedio'>
                <InputCurrency name='cost_average' value={cost_average} onChange={onChangeMask} />
              </DivFormGroup>
            </Col>
            <Col sm='2'>
              <DivFormGroup name='last_cost' title='Último costo' required>
                <InputCurrency name='last_cost' value={last_cost} onChange={onChangeMask} />
              </DivFormGroup>
            </Col>
            <Col sm='2'>
              <FormInput title='Fecha último costo' type='date' name='date_last_cost' value={date_last_cost} disabled />
            </Col>
            <Col sm='2'>
              <DivFormGroup name='cost' title='Costo'>
                <InputCurrency name='cost' value={cost} onChange={onChangeMask} />
              </DivFormGroup>
            </Col>
          </IfPermission>
        </Row>
        <Row>
          <Col sm='2'>
            <DivFormGroup name='moneda' title='Moneda' required>
              <Select
                value={moneda}
                placeholder='Seleccionar'
                options={monedas}
                onChange={(value) => onSelectMoneda('moneda', value)}
                className='basic-multi-select'
                classNamePrefix='select'
              />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='tipo_cambio' title='Tipo cambio' required>
              <InputCurrency name='tipo_cambio' value={tipo_cambio} onChange={onChangeMask} disabled={moneda?.value === moneda_compra?.value} />
            </DivFormGroup>
          </Col>
          <IfPermission action='costos-margen'>
            <Col sm='2'>
              <DivFormGroup name='margen_ganancia' title='Margen (%)' required>
                <InputPercentage name='margen_ganancia' value={margen_ganancia} onChange={onChangeMask} />
              </DivFormGroup>
            </Col>
          </IfPermission>
          <Col sm='2'>
            <DivFormGroup name='precio' title='Precio' required>
              <InputCurrency name='precio' value={precio} onChange={onChangeMask} />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='iva' title='IVA (%)' required>
              <InputPercentage name='iva' value={iva} onChange={onChangeMask} />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='iva' title='Precio con IVA'>
              <InputCurrency name='precio_iva' disabled value={clean(precio) + clean(precio) * (iva / 100)} />
            </DivFormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

FormEquipmentCostos.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  moneda: PropTypes.string,
  date_last_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  last_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margen_ganancia: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  precio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cost_average: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fechaUltimoCosto: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iva: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tipo_costeo: PropTypes.string,
  tipos_costeo: PropTypes.array.isRequired,
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tipo_cambio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  moneda_compra: PropTypes.string,
  monedas: PropTypes.array.isRequired,
};

export default FormEquipmentCostos;
