import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableThSort from '@cbcomponents/CbTableThSort';
import Row from './Row';

const Table = ({ list, loadData, cobrarFactura, orden, tipo }) => {
  const onClickCobrar = (i) => {
    cobrarFactura(i);
  };

  const renderRows = () => {
    return list.map((r, i) => <Row key={i} {...r} cobrarFactura={() => onClickCobrar(i)} />);
  };

  const establecerOrden = (orden, tipo, campo) => {
    loadData({ page: 0, orden: campo, tipo: orden === campo ? tipo * -1 : -1 });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='1%'>&nbsp;</th>
          <CbTableThSort width='5%' orden={orden} tipo={tipo} name='serie_folio' onSort={establecerOrden}>
            Folio
          </CbTableThSort>
          <CbTableThSort width='13%' orden={orden} tipo={tipo} name='fecha_original' onSort={establecerOrden}>
            Fecha
          </CbTableThSort>
          <CbTableThSort width='25%' orden={orden} tipo={tipo} name='cliente' onSort={establecerOrden}>
            Cliente
          </CbTableThSort>
          <CbTableThSort width='6%' orden={orden} tipo={tipo} name='titulo' onSort={establecerOrden}>
            Titulo
          </CbTableThSort>
          <CbTableThSort width='6%' orden={orden} tipo={tipo} name='importe' onSort={establecerOrden}>
            Total
          </CbTableThSort>
          <CbTableThSort width='6%' orden={orden} tipo={tipo} name='saldo' onSort={establecerOrden}>
            Saldo
          </CbTableThSort>
          <CbTableThSort width='10%' orden={orden} tipo={tipo} name='estado' onSort={establecerOrden}>
            Status
          </CbTableThSort>
          <th width='5%'>&nbsp;</th>
        </tr>
      </thead>
      <CbTableBody colSpan={8}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func,
  cobrarFactura: PropTypes.func,
  orden: PropTypes.string.isRequired,
  tipo: PropTypes.number.isRequired,
};

export default Table;
