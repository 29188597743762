import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Input } from '@controls';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreTiposContactos } from '@stores/catalogs.store';

const RowContacto = ({ contacto, onChange, onRemove }) => {
  const getTiposContactos = useStoreTiposContactos();

  useEffect(() => {
    getTiposContactos.execute();
  }, []);

  const onChangeContacto = (e) => {
    const { name, value } = e.target;
    onChange({ ...contacto, [name]: value });
  };

  const onChangeMultiTipoContacto = (value) => {
    onChange({ ...contacto, tipos: value });
  };
  return (
    <tr>
      <td>
        <Select
          value={contacto.tipos}
          isMulti
          name='tipos'
          placeholder='Selecciona opciones'
          options={getTiposContactos.data}
          onChange={(e) => onChangeMultiTipoContacto(e)}
          getOptionLabel={(option) => option.tipo}
          getOptionValue={(option) => option._id}
          className='basic-multi-select'
          classNamePrefix='select'
        />
      </td>
      <td>
        <Input
          name='nombre'
          className='form-control input-sm'
          value={contacto.nombre}
          placeholder='Nombre'
          onChange={(e) => onChangeContacto(e, 'nombre')}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <Input
          name='paterno'
          className='form-control input-sm'
          value={contacto.paterno}
          placeholder='Paterno'
          onChange={(e) => onChangeContacto(e, 'paterno')}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <Input
          name='materno'
          className='form-control input-sm'
          value={contacto.materno}
          placeholder='Materno'
          onChange={(e) => onChangeContacto(e, 'materno')}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <Input
          name='telefono'
          className='form-control input-sm'
          placeholder='Teléfono/Celular'
          value={contacto.telefono}
          onChange={(e) => onChangeContacto(e, 'telefono')}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <Input
          name='correo'
          className='form-control input-sm'
          value={contacto.correo}
          placeholder='Correo electrónico'
          type='email'
          onChange={(e) => onChangeContacto(e, 'correo')}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <CbButtonOver onClick={onRemove} icon='remove' title='Agregar' type='danger' />
      </td>
    </tr>
  );
};

RowContacto.propTypes = {
  contacto: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    telefono: PropTypes.string.isRequired,
    correo: PropTypes.string.isRequired,
    materno: PropTypes.string.isRequired,
    paterno: PropTypes.string.isRequired,
    tipos: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default RowContacto;
