import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';

const RowSeguimientoCliente = ({ cliente, onSelectContacto }) => {
  return (
    <>
      <tr className='warning'>
        <th>&nbsp;</th>
        <td>{cliente.customerId.numero_cliente}</td>
        <td>{cliente.customerId.razon_social}</td>
      </tr>
      <tr>
        <td style={{ margin: 0, padding: 0, verticalAlign: 'middle' }} colSpan={3}>
          <table className='table table-striped table-condensed table-hover'>
            <thead>
              <tr>
                <th width='5%'>&nbsp;</th>
                <th width='10%'>Tipo</th>
                <th width='30%'>Contacto</th>
                <th width='30%'>Telefonos</th>
                <th>Correos</th>
              </tr>
            </thead>
            <tbody>
              {cliente.contactos.map((c, i) => {
                const contacto = `${c.name} ${c.paterno} ${c.materno}`;
                const telefonos = [];
                const correos = [];
                if (c.phone) telefonos.push(c.phone);
                if (c.email) correos.push(c.email);
                return (
                  <tr key={i} style={{ cursor: 'pointer' }} onDoubleClick={() => onSelectContacto(cliente, c, telefonos, correos)}>
                    <td>&nbsp;</td>
                    <td>
                      {c.tipos
                        .filter((x) => x.tipoContactoId)
                        .map((x, j) => (
                          <CbBadge key={j} type='primary'>
                            {x.tipoContactoId.tipo}
                          </CbBadge>
                        ))}
                    </td>
                    <th>{contacto}</th>
                    <td>
                      {telefonos.map((x, j) => (
                        <CbBadge key={j} type='primary'>
                          {x}
                        </CbBadge>
                      ))}
                    </td>
                    <td>
                      {correos.map((x, j) => (
                        <CbBadge key={j} type='primary'>
                          {x}
                        </CbBadge>
                      ))}
                    </td>
                  </tr>
                );
              })}
              <tr key='new-contact' style={{ cursor: 'pointer' }} onClick={() => onSelectContacto(cliente)} className='active'>
                <td colSpan={5} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Nuevo contacto
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

RowSeguimientoCliente.propTypes = {
  cliente: PropTypes.object,
  onSelectContacto: PropTypes.func,
};

export default RowSeguimientoCliente;
