import { DivFormGroup, Input, Label } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';
import MultiSelectAgentes from '@components/forms/MultiSelectAgentes';

const Edit = ({ visitaId }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [fechaHora, setFechaHora] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [horasServicio, setHorasServicio] = useState(1);
  const [agenteId, setAgenteId] = useState([]);
  const [observaciones, setObservaciones] = useState('');

  const close = () => {
    setShowModal(false);
    setFechaHora(moment().format('YYYY-MM-DD HH:mm'));
    setHorasServicio(1);
    setAgenteId([]);
    setObservaciones('');
  };

  const open = () => {
    setShowModal(true);
    loadData();
  };

  const loadData = async () => {
    const { data } = await getRequest({ url: `ordenes-servicios-visitas/${visitaId}` });
    setFechaHora(moment(data.fecha_hora).local().format('YYYY-MM-DD HH:mm'));
    setHorasServicio(data.horas_servicio);
    setAgenteId(data.agentes_asignados);
    setObservaciones(data.observaciones);
  };

  const handleUptVisita = async () => {
    const data = {
      fecha_hora: Date.parse(fechaHora),
      horas_servicio: horasServicio,
      agente_id: agenteId.map((x) => x._id),
      observaciones: observaciones,
    };
    if (validations(data)) {
      await postRequest({ url: `ordenes-servicio/visitas/actualizar/${visitaId}`, body: data });
      close();
      loadData();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones son requeridas.';
    } else if (params.agente_id.length <= 0) {
      validate.success = false;
      validate.message = 'Agentes son requeridos.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  return (
    <Fragment>
      <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Editar visita</Tooltip>}>
        <button onClick={open} className='btn btn-outline-primary' type='button'>
          <Icon icon='edit' />
        </button>
      </OverlayTrigger>
      <Modal size='large' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Editar visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='row'>
              <div className='col-sm-6'>
                <DivFormGroup>
                  <Label name='fecha_hora' title='Fecha y hora' />
                  <Input type='datetime-local' name='fecha_hora' onChange={(e) => setFechaHora(e.target.value)} value={fechaHora} />
                </DivFormGroup>
              </div>
              <div className='col-sm-6'>
                <DivFormGroup>
                  <Label name='horas_servicio' title='Horas de servicio' />
                  <Input type='number' name='horas_servicio' onChange={(e) => setHorasServicio(e.target.value)} value={horasServicio} />
                </DivFormGroup>
              </div>
              <div className='col-sm-12'>
                <DivFormGroup>
                  <Label name='observaciones' title='Observaciones' required />
                  <textarea className='form-control' name='observaciones' onChange={(e) => setObservaciones(e.target.value)} value={observaciones} />
                </DivFormGroup>
              </div>
              <div className='col-sm-12'>
                <DivFormGroup>
                  <Label name='agente_id' required title='Agentes' />
                  <MultiSelectAgentes value={agenteId} onChange={(value) => setAgenteId(value)} />
                </DivFormGroup>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleUptVisita} type='button' className='btn btn-success pull-right mr-5'>
            Actualizar
          </button>
          <button onClick={close} className='btn btn-default pull-right mr-5' type='button'>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

Edit.propTypes = {
  ordenServicioId: PropTypes.string.isRequired,
  visitaId: PropTypes.string.isRequired,
  agentes: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Edit;
