import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ list, load }) => {
  const opciones = {
    AUTORIZADO: 'Autorizado',
    ADVERTENCIA: 'Advertencia',
    OCUPA_AUTORIZACION: 'Ocupa autorizacion',
    SIN_AUTORIZACION: 'Sin autorizacion',
  };

  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, opcion, description } = row;
      return (
        <tr key={item}>
          <td>{description}</td>
          <td>{opciones[opcion]}</td>
          <td>
            <IfPermission action='eliminar'>
              <Delete id={_id} description={description} opcion={opcion} {...{ list, load }} btnClass='btn btn-sm btn-danger pull-right' />
            </IfPermission>
            <IfPermission action='editar'>
              <Edit id={_id} description={description} opcion={opcion} {...{ list, load }} btnClass='btn btn-outline-info' />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Descripcion</th>
          <th width='60%'>Opcion</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default List;
