import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ id, name, ref, className = 'form-control', style, rows, cols, placeholder, value, defaultValue, onChange, onBlur, disabled }) => {
  const handleOnChange = (e) => {
    if (onChange) {
      e.persist();
      onChange(e);
    }
  };
  return (
    <textarea
      id={id}
      name={name}
      ref={ref}
      className={className}
      rows={rows}
      cols={cols}
      style={style}
      onChange={handleOnChange}
      disabled={disabled}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  rows: PropTypes.number,
  cols: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  ref: PropTypes.func,
};

export default TextArea;
