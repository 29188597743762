import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import InputNumber from '@components/forms/InputNumber';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';

const RowCredito = ({ credito, onChangeCredito, onRemoveCredito }) => {
  const handleChangeCredito = (index, field, event) => {
    onChangeCredito({
      ...credito,
      [field]: event.target.value,
    });
  };

  return (
    <div className='list-group-item'>
      <Row>
        <Col sm='6'>
          <InputNumber
            name='limite_credito'
            placeholder='Límite de Crédito'
            value={credito.limite_credito}
            onChange={(e) => handleChangeCredito('limite_credito', e)}
          />
        </Col>
        <Col sm='5'>
          <InputNumber name='plazo' placeholder='Días de Plazo' value={credito.plazo} onChange={(e) => handleChangeCredito('plazo', e)} />
        </Col>
        <Col sm='1'>
          <Button onClick={onRemoveCredito} className='btn btn-danger pull-right' type='button'>
            <Icon icon='trash' />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

RowCredito.propTypes = {
  credito: PropTypes.object,
  onChangeCredito: PropTypes.func,
  onRemoveCredito: PropTypes.func,
};

export default RowCredito;
