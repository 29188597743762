import { DivFormGroup, FormInput, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { getRequest, postRequest } from '@utils/api';
import { getImporte } from '@utils/cotizaciones';
import { CbPagination } from '@controls';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import { useStoreClasificacionesCotizaciones, useStoreMedidas } from '@stores/catalogs.store';
import { mapParte } from '@utils/cotizaciones';

const BuscadorKits = ({ onAgregarDetalle, onClose, sucursalId, monedaId, tipoCambio, showModalKitsComerciales, onLoadingTable }) => {
  const [state, setState] = useState({
    kits_comerciales: [],
    loading: true,
    activePage: 1,
    count: 0,
    pagination: 50,
    page: 1,
    search: '',
  });
  const getStoreClasificacionesCotizaciones = useStoreClasificacionesCotizaciones();
  const getStoreMedidas = useStoreMedidas();

  let timeout = 0;

  useEffect(() => {
    getStoreClasificacionesCotizaciones.execute();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [state.tipoCotizacionId, state.activePage, state.search]);

  const agregarKitComercial = async (producto, cantidad) => {
    onClose();
    onLoadingTable(true);
    const { data } = await getRequest({ url: `kits-comerciales/${producto._id}` });
    try {
      const measure = getStoreMedidas.data.find((x) => x.measure.toUpperCase() === 'KIT');
      const detalleKit = {
        relacionadoId: data._id,
        descripcion: data.nombre,
        tipo_partida: { value: 'KIT_COMERCIAL', label: 'KIT_COMERCIAL' },
        noIdentificador: data.codigo,
        measureId: measure,
        precio_original: 0,
        precio: 0,
        moneda_original: monedaId,
        moneda: monedaId,
        cantidad: cantidad || 0,
        descuento: 0,
        iva: producto.iva || 16,
        colapsado: true,
        status: 'ACTIVO',
        partes: [],
      };
      const partes = data.partes.filter((x) => x && x._id).map((parte) => mapParte(parte, sucursalId, monedaId, tipoCambio));
      onAgregarDetalle({
        ...detalleKit,
        precio: getImporte(partes, 4),
        partes,
      });
      onLoadingTable(false);
    } catch (e) {
      console.log(e);
    }
  };

  const closeModalKitComerciales = () => {
    onClose();
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({ ...prevState, [campo]: value }));
  };

  const onChange = (event) => {
    setState((prevState) => ({ ...prevState, search: event.target.value }));
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (!state.loading && (state.search.toString().length === 0 || state.search.toString().length >= 3)) {
        handleSearch();
      }
    }, 800);
  };

  const handleSearch = useCallback(async () => {
    const { pagination, tipoCotizacionId, search, page } = state;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      search,
      tipoCotizacionId,
    };
    const { data, count } = await postRequest({ url: `kits-comerciales/search`, params: { page, limit: pagination }, body });
    setState((prevState) => ({
      ...prevState,
      kits_comerciales: data,
      count,
      loading: false,
    }));
  }, [state.tipoCotizacionId, state.search, state.page]);

  const handlePagination = (eventKey) => {
    const { pagination } = state;
    setState((prevState) => ({ ...prevState, activePage: eventKey, page: eventKey * pagination - pagination }));
  };

  const onClickKit = (producto) => {
    return agregarKitComercial(producto, 1);
  };

  const { tipoCotizacionId, search, kits_comerciales, pagination, loading, count, activePage } = state;
  return (
    <>
      <Modal show={showModalKitsComerciales} onHide={closeModalKitComerciales} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Kits comerciales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3}>
              <DivFormGroup>
                <Label name='tipoCotizacionId' title='Linea' required />
                <Select
                  value={tipoCotizacionId}
                  name='tipoCotizacionId'
                  placeholder='Seleccione'
                  options={getStoreClasificacionesCotizaciones.data}
                  onChange={(value) => onSelect('tipoCotizacionId', value)}
                  getOptionLabel={(x) => x.nombre}
                  getOptionValue={(x) => x._id}
                  className='basic-multi-select'
                  classNamePrefix='select'
                />
              </DivFormGroup>
            </Col>
            <Col xs={9}>
              <FormInput title='Código o Descripción' placeholder='Buscar...' name='buscar' onChange={onChange} value={search} />
            </Col>
          </Row>
          <Row>
            <Col>
              <CbPanelTableResponsive>
                <thead>
                  <tr>
                    <th width='2%'>&nbsp;</th>
                    <th width='10%'>Linea</th>
                    <th width='5%'>Código</th>
                    <th width='45%'>Descripción</th>
                  </tr>
                </thead>
                <CbTableBody colSpan={4} loading={loading}>
                  {kits_comerciales.map((x) => (
                    <tr key={x._id} onDoubleClick={() => onClickKit(x)} style={{ cursor: 'pointer' }}>
                      <td>&nbsp;</td>
                      <td>{x.tipoCotizacionId ? x.tipoCotizacionId.nombre : null}</td>
                      <td>{x.codigo}</td>
                      <td>{x.nombre}</td>
                    </tr>
                  ))}
                </CbTableBody>
              </CbPanelTableResponsive>
              <div style={{ textAlign: 'center' }}>
                <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModalKitComerciales} className='btn btn-default pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

BuscadorKits.propTypes = {
  isLoadingTable: PropTypes.bool,
  monedaId: PropTypes.string,
  sucursalId: PropTypes.string,
  tipoCambio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showModalKitsComerciales: PropTypes.bool,
  onAgregarDetalle: PropTypes.func,
  onClose: PropTypes.func,
  onLoadingTable: PropTypes.func,
};

export default BuscadorKits;
