import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteAnticipos = ({ anticiposId, anticipo, tipo, loadAnticipos }) => {
  const message = `${anticipo} ${tipo === 'PORCENTAJE' ? '%' : null}`;
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`anticipos/${anticiposId}`}
      description={`¿Confirma que desea eliminar el anticipo <strong><u> ${message}</u> </strong>?`}
      loadData={loadAnticipos}
    />
  );
};

DeleteAnticipos.propTypes = {
  id: PropTypes.number.isRequired,
  anticipo: PropTypes.number.isRequired,
  tipo: PropTypes.string.isRequired,
  anticiposId: PropTypes.string.isRequired,
  loadAnticipos: PropTypes.func.isRequired,
};

export default DeleteAnticipos;
