import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ data, ...props }) => {
  const renderRows = () => {
    return data.map((row, item) => {
      const { _id, serie, nombre, controlMantenimientoId, tipoPiezaId, stock } = row;
      return (
        <tr key={item}>
          <td>{serie}</td>
          <td>{nombre}</td>
          <td>{controlMantenimientoId.nombre}</td>
          <td>{tipoPiezaId.nombre}</td>
          <td>
            <span className='label label-danger'>mínimo {stock.minimo}</span>
            <span className='label label-success'>máximo {stock.maximo}</span>
          </td>
          <td>
            <IfPermission action='eliminar'>
              <Delete id={item} itemId={_id} nombre={nombre} {...props} btnClass='btn btn-outline-danger' />
            </IfPermission>
            <IfPermission action='editar'>
              <Edit id={item} itemId={_id} nombre={nombre} {...props} btnClass='btn btn-outline-info' />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='10%'>Nº</th>
          <th width='30%'>Nombre</th>
          <th width='15%'>Control de Mantenimiento</th>
          <th width='15%'>Tipo</th>
          <th width='10%'>Stock</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
};

export default List;
