import { DivFormGroup, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputPercentage from '@components/forms/InputPercentage';

const EditAnticipos = ({ anticipo: initialAnticipo, anticiposId, loadAnticipos }) => {
  const { addNotification: notification } = useStoreNotification();
  const [anticipo, setAnticipo] = useState(initialAnticipo);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
    setAnticipo(initialAnticipo);
  };

  const onChange = (event) => {
    let { value } = event.target;
    setAnticipo(Number(value.replace(/%|,/gi, '')));
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = { anticipo };

    if (validations(data)) {
      setIsLoading(true);
      await putRequest({ url: `anticipos/${anticiposId}`, body: data });
      loadAnticipos();
      close();
      setIsLoading(false);
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.anticipo + '')) {
      validate.success = false;
      validate.message = 'Anticipo es requerido.';
    } else if (params.anticipo === 0) {
      validate.success = false;
      validate.message = 'Anticipo debe ser mayor a cero.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='pencil' onClick={open} title='Editar' />
      <CbModal show={showModal} title='Editar anticipo' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup>
              <Label name='anticipo' title='Anticipo' required />
              <InputPercentage name='anticipo' onChange={onChange} value={anticipo} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditAnticipos.propTypes = {
  id: PropTypes.number.isRequired,
  anticipo: PropTypes.number.isRequired,
  anticiposId: PropTypes.string.isRequired,
  loadAnticipos: PropTypes.func.isRequired,
};

export default EditAnticipos;
