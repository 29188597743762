import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { CustomerRegistroOption } from '@stateless';
import PropTypes from 'prop-types';
import {
  useStoreTiposOrdenesServicios,
  useStoreSolucionesSolicitadas,
  useStoreTiposServiciosSolicitados,
  useStoreTiposManosObras,
  useStoreTiposCobrosEquipos,
} from '@stores/catalogs.store';
import { getRequest } from '@utils/api';
import { DivFormGroup, Input, FormInput } from '@controls';
import NewCRM from '../NewCRM';

const FormOrdenServicio = ({
  tipoOrdenServicioId,
  numero_orden_servicio,
  tipos_mano_obra_id,
  tipos_cobro_equipo_id,
  soluciones_solicitadas_id,
  tipos_servicios_solicitados_id,
  horas,
  clienteRegistro,
  descripcion,
  cotizacion,
  onSelect,
  onChange,
  onChangeCliente,
  onSelectTipoOrdenServicio,
  onRegisterRegistroCliente,
  referencia,
}) => {
  const getTiposOrdenesServicios = useStoreTiposOrdenesServicios();
  const getSolucionesSolicitadas = useStoreSolucionesSolicitadas();
  const getTiposServiciosSolicitados = useStoreTiposServiciosSolicitados();
  const getTiposManosObras = useStoreTiposManosObras();
  const getTiposCobrosEquipos = useStoreTiposCobrosEquipos();
  useEffect(() => {
    getTiposCobrosEquipos.execute();
    getTiposManosObras.execute();
    getTiposOrdenesServicios.execute();
    getSolucionesSolicitadas.execute();
    getTiposServiciosSolicitados.execute();
  }, []);

  const loadOptions = async (inputValue, callback) => {
    const { customer } = cotizacion;
    const { data } = await getRequest({
      url: `customers-registros/autocomplete?query=${inputValue}&customerId=${customer?._id}&customerId=${customer?.domicilio?._id}`,
    });
    callback(data);
  };

  return (
    <>
      <Row>
        <Col sm={3}>
          <DivFormGroup name='tipoOrdenServicioId' required title='Tipo orden de servicio'>
            <Select
              value={tipoOrdenServicioId}
              name='tipoOrdenServicioId'
              placeholder='Seleccione..'
              options={getTiposOrdenesServicios.data}
              getOptionLabel={(x) => x.nombre}
              getOptionValue={(x) => x._id}
              onChange={onSelectTipoOrdenServicio}
            />
          </DivFormGroup>
        </Col>
        <Col sm={3}>
          <FormInput
            title='Número de orden'
            placeholder='Num. de orden'
            name='numero_orden_servicio'
            onChange={onChange}
            value={numero_orden_servicio}
          />
        </Col>
        <Col sm={3}>
          <DivFormGroup name='tipos_mano_obra_id' required title='Cobro mano de obra'>
            <Select
              value={tipos_mano_obra_id}
              name='tipos_mano_obra_id'
              placeholder='Seleccione..'
              getOptionLabel={(x) => x.definicion}
              getOptionValue={(x) => x._id}
              options={getTiposManosObras.data}
              onChange={(selectedOption) => onSelect('tipos_mano_obra_id', selectedOption)}
            />
          </DivFormGroup>
        </Col>
        <Col sm={3}>
          <DivFormGroup name='tipos_cobro_equipo_id' required title='Cobro equipo'>
            <Select
              value={tipos_cobro_equipo_id}
              name='tipos_cobro_equipo_id'
              options={getTiposCobrosEquipos.data}
              getOptionLabel={(x) => x.definicion}
              getOptionValue={(x) => x._id}
              placeholder='Seleccione..'
              onChange={(selectedOption) => onSelect('tipos_cobro_equipo_id', selectedOption)}
            />
          </DivFormGroup>
        </Col>
        <Col sm={4}>
          <DivFormGroup name='soluciones_solicitadas_id' required title='Soluciones solicitadas'>
            <Select
              isMulti
              value={soluciones_solicitadas_id}
              name='soluciones_solicitadas_id'
              options={getSolucionesSolicitadas.data}
              onChange={(selectedOption) => onSelect('soluciones_solicitadas_id', selectedOption)}
              className='basic-multi-select'
              getOptionLabel={(x) => x.definicion}
              getOptionValue={(x) => x._id}
              placeholder='Seleccione..'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
        <Col sm={4}>
          <DivFormGroup name='tipos_servicios_solicitados_id' required title='Tipos servicios solicitados'>
            <Select
              defaultValue={tipos_servicios_solicitados_id}
              name='tipos_servicios_solicitados_id'
              options={getTiposServiciosSolicitados.data}
              className='basic-multi-select'
              classNamePrefix='select'
              getOptionLabel={(x) => x.definicion}
              getOptionValue={(x) => x._id}
              placeholder='Seleccione..'
              isMulti
              onChange={(selectedOption) => onSelect('tipos_servicios_solicitados_id', selectedOption)}
            />
          </DivFormGroup>
        </Col>
        <Col sm={4}>
          <DivFormGroup name='horas' title='Horas cotizadas'>
            <Input name='horas' value={horas} placeholder='horas' disabled />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={clienteRegistro ? 12 : 10}>
          <DivFormGroup name='registroclienteName' required title='Registro de cliente'>
            <AsyncSelect
              value={clienteRegistro}
              loadOptions={loadOptions}
              onChange={onChangeCliente}
              getOptionLabel={(x) => `${x.folio} ${x.descripcion}`}
              placeholder='Seleccione ..'
              getOptionValue={(x) => x._id}
              isClearable
              components={{ Option: CustomerRegistroOption }}
            />
          </DivFormGroup>
        </Col>
        {!clienteRegistro && (
          <Col sm={2}>
            <br />
            <NewCRM refresh={onRegisterRegistroCliente} cliente={cotizacion?.customer} domicilio={cotizacion?.customer?.domicilio} />
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={12}>
          <DivFormGroup name='descripcion' required title='Descripción corta'>
            <Input name='descripcion' value={descripcion} placeholder='Descripción corta' onChange={onChange} />
          </DivFormGroup>
        </Col>
        <Col sm={12}>
          <DivFormGroup name='referencia' title='Referencia'>
            <Input name='referencia' value={referencia} disabled placeholder='Referencia' />
          </DivFormGroup>
        </Col>
      </Row>
    </>
  );
};

FormOrdenServicio.propTypes = {
  tipoOrdenServicioId: PropTypes.object,
  numero_orden_servicio: PropTypes.string,
  tipos_mano_obra_id: PropTypes.object,
  tipos_cobro_equipo_id: PropTypes.object,
  soluciones_solicitadas_id: PropTypes.object,
  tipos_servicios_solicitados_id: PropTypes.object,
  horas: PropTypes.string,
  clienteRegistro: PropTypes.object,
  descripcion: PropTypes.string,
  cotizacion: PropTypes.object,
  setState: PropTypes.func,
  onSelectTipoOrdenServicio: PropTypes.func,
  onRegisterRegistroCliente: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onChangeCliente: PropTypes.func,
  referencia: PropTypes.string,
};

export default FormOrdenServicio;
