import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import CbBadge from '@cbcomponents/CbBadge';

const ESTATUS_RECEPCIONES = {
  INICIADO: 'INICIADO',
  FINALIZADO: 'FINALIZADO',
  EN_PROCESO: 'EN PROCESO',
};

const RowsRecepcionCompras = ({
  tipoCompraId,
  fecha,
  almacen,
  sucursal,
  agente,
  numero_recepcion_compra,
  numero_compra,
  _id,
  estatus,
  compraId,
  loadData,
}) => {
  const estatus_label = (estatus) => {
    const badgeClasses = {
      INICIADO: 'primary',
      FINALIZADO: 'secondary',
      EN_PROCESO: 'success',
    };

    const badgeClass = badgeClasses[estatus] || 'primary';
    return <CbBadge type={badgeClass}>{ESTATUS_RECEPCIONES[estatus]}</CbBadge>;
  };

  return (
    <tr>
      <td>{tipoCompraId.nombre}</td>
      <td>{numero_recepcion_compra}</td>
      <td>{numero_compra}</td>
      <td>{moment(fecha).local().format('YYYY-MM-DD')}</td>
      <td>{sucursal.name}</td>
      <td>{almacen.name}</td>
      <td>{agente.nombre}</td>
      <td>{estatus_label(estatus)}</td>
      <td>
        <div className='btn-group'>
          <RedirectTooltip
            id={2}
            icon='edit'
            url={`/recepciones-compras/edicion/${_id}`}
            labelTooltip='Editar'
            className='btn btn-sm btn-outline-primary'
          />
          <RedirectTooltip id={1} icon='search' url={`/recepciones-compras/${_id}`} labelTooltip='Detalle' className='btn btn-sm btn-outline-dark' />
          <If condition={compraId}>
            {/*<If.Then>
              <RedirectTooltip
                id={2}
                icon="edit"
                url={`compras/edicion/${compraId}`}
                labelTooltip="Compra"
                className="btn btn-sm btn-outline-primary"
              />
            </If.Then>*/}
          </If>
          <If condition={estatus !== 'FINALIZADO'}>
            <If.Then>
              <Confirmation
                id={_id}
                btnIcon='eject'
                typeRequest='postRequest'
                btnType='default btn-sm'
                action='Afectar existencias'
                url={`recepcionescompras/afectarExistencias/${_id}`}
                description={`¿Confirma que desea afectar existencias de la <strong>recepción de compra: <u>${tipoCompraId.nombre} #${numero_recepcion_compra}</u></strong>?`}
                loadData={loadData}
              />
            </If.Then>
          </If>
        </div>
      </td>
    </tr>
  );
};

RowsRecepcionCompras.propTypes = {
  _id: PropTypes.string.isRequired,
  numero_compra: PropTypes.number.isRequired,
  numero_recepcion_compra: PropTypes.number.isRequired,
  tipoCompraId: PropTypes.object.isRequired,
  fecha: PropTypes.string.isRequired,
  almacen: PropTypes.object.isRequired,
  sucursal: PropTypes.object.isRequired,
  agente: PropTypes.object.isRequired,
  created: PropTypes.string.isRequired,
  estatus: PropTypes.string.isRequired,
  compraId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default RowsRecepcionCompras;
