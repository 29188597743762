import { DivFormGroup } from '@controls';
import React, { useState, useRef } from 'react';
import { Button, Col, Modal, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';

const Importar = () => {
  const [state, setState] = useState({
    anticipo: 0,
    domicilios: [],
    contactos: [],
    cliente: null,
    folio: '',
    descripcion: '',
    showModal: false,
    isLoading: false,
    cargando: false,
    progress: 0,
  });

  const frm = useRef(null);

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  const open = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: true,
      isLoading: false,
    }));
  };

  const handleReadCSV = async (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      cargando: true,
      progress: 0,
      csv: null,
      isLoading: true,
    }));
    const form = new FormData(frm.current);
    await postUploadRequest({ url: `customers-registros/importar`, form });
    setState((prevState) => ({
      ...prevState,
      cargando: false,
      progress: 0,
      isLoading: false,
    }));
  };

  const { showModal, isLoading, cargando, progress } = state;

  return (
    <>
      <Button onClick={open} className='btn btn-primary pull-right mr-5' type='button'>
        <Icon icon='import' /> <span className='hidden-xs'>Importar</span>
      </Button>
      <Modal size='large' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Importar registros de clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={frm} onSubmit={handleReadCSV} encType='multipart/form-data'>
            <Row>
              <Col sm='3'>
                <DivFormGroup>
                  <div className='btn btn-default btn-file'>
                    <Icon icon='paperclip' /> Archivo
                    <input type='file' name='csv' id='csv' />
                  </div>
                </DivFormGroup>
              </Col>
              <Col sm='9'>
                <Button type='submit' className='btn btn-success' disabled={cargando}>
                  <Icon icon='import' /> Importar
                </Button>
                {isLoading ? <Saving action='Importantando' /> : ''}
              </Col>
              <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button onClick={close} disabled={isLoading} className='btn btn-default pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Importar.propTypes = {};

export default Importar;
