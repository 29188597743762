import React from 'react';
import PropTypes from 'prop-types';
import Then from './If/Then';
import ElseIf from './If/ElseIf';
import Else from './If/Else';

const KEYWORDS = {
  THEN: Then,
  ELSEIF: ElseIf,
  ELSE: Else,
};

const If = ({ condition, children }) => {
  const childArray = React.Children.toArray(children);
  for (let i = 0; i < childArray.length; i++) {
    const child = childArray[i];
    if ((child.type === KEYWORDS.THEN || childArray.length === 1) && condition) return child;
    if (child.type === KEYWORDS.ELSEIF) {
      if (child.props && child.props.condition) return child;
      continue;
    }
    if (child.type === KEYWORDS.ELSE) {
      return child;
    }
  }
  return null;
};

If.propTypes = {
  children: PropTypes.node.isRequired,
  condition: PropTypes.bool.isRequired,
};

If.Then = Then;
If.ElseIf = ElseIf;
If.Else = Else;

export default If;
