import { DivFormGroup, Input, InputPassword } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmail, isEmpty, isLength } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const NewSellers = ({ loadSellers }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [fatherLastName, setFatherLastName] = useState('');
  const [motherLastName, setMotherLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setName('');
    setFatherLastName('');
    setMotherLastName('');
    setUsername('');
    setPassword('');
    setRepassword('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'fatherLastName':
        setFatherLastName(value);
        break;
      case 'motherLastName':
        setMotherLastName(value);
        break;
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'repassword':
        setRepassword(value);
        break;
      default:
        break;
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      name: name.trim(),
      fatherLastName: fatherLastName.trim(),
      motherLastName: motherLastName.trim(),
      username: username.trim(),
      password: password.trim(),
      repassword: repassword.trim(),
    };

    if (validations(data)) {
      await postRequest({ url: `sellers`, body: data });
      loadSellers();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.name + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (validate.success && isEmpty(params.fatherLastName + '')) {
      validate.success = false;
      validate.message = 'Apellido Paterno es requerido.';
    } else if (validate.success && isEmpty(params.username + '')) {
      validate.success = false;
      validate.message = 'Nombre de Usuario es requerido.';
    } else if (validate.success && !isEmail(params.username + '')) {
      validate.success = false;
      validate.message = 'Nombre de Usuario, debe tener formato de email.';
    } else if (validate.success && !isLength(params.username + '', { max: 100 })) {
      validate.message = 'Contraseña debe contener min 8 y max 10 caracteres.';
      validate.success = false;
    } else if (validate.success && isEmpty(params.password + '')) {
      validate.success = false;
      validate.message = 'Contraseña es requerido.';
    } else if (validate.success && !isLength(params.password + '', { min: 8, max: 10 })) {
      validate.message = 'Contraseña debe contener min 8 y max 10 caracteres.';
      validate.success = false;
    } else if (validate.success && isEmpty(params.repassword + '')) {
      validate.success = false;
      validate.message = 'Favor de confirmar la contraseña.';
    } else if (validate.success && !isLength(params.repassword + '', { min: 8, max: 10 })) {
      validate.message = 'Contraseña debe contener min 8 y max 10 caracteres.';
      validate.success = false;
    } else if (validate.success && params.password !== params.repassword) {
      validate.message = 'Contraseñas no coinciden. favor de verficar.';
      validate.success = false;
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar Vendedor' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='name' title='Nombre'>
              <Input name='name' onChange={onChange} value={name} />
            </DivFormGroup>
            <DivFormGroup name='fatherLastName' title='Apellido Paterno'>
              <Input name='fatherLastName' onChange={onChange} value={fatherLastName} />
            </DivFormGroup>
            <DivFormGroup name='motherLastName' title='Apellido Materno'>
              <Input name='motherLastName' onChange={onChange} value={motherLastName} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <legend>Acceso Carro de Juan</legend>
            <DivFormGroup name='username' title='Nombre de Usuario (Email)'>
              <Input name='username' onChange={onChange} value={username} maxLength={100} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='password' title='Contraseña'>
              <InputPassword name='password' onChange={onChange} value={password} maxLength={10} />
              <small className='text-center'>Min 8 y Máx 10 caracteres</small>
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='repassword' title='Confirmación'>
              <InputPassword name='repassword' onChange={onChange} value={repassword} maxLength={10} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewSellers.propTypes = {
  loadSellers: PropTypes.func.isRequired,
};

export default NewSellers;
