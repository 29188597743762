import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from '@components/forms/InputNumber';

const ColClasificacionZona = ({ sb, onChangeClasificacionZona }) => {
  const handleChangeAreaZonaSubclasificacion = (event) => {
    onChangeClasificacionZona({
      ...sb,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <td width='5%' style={{ padding: '0px' }}>
      <InputNumber
        style={{ padding: '.2em' }}
        value={sb.cantidad}
        name='cantidad'
        onChange={(event) => handleChangeAreaZonaSubclasificacion(event)}
      />
    </td>
  );
};

ColClasificacionZona.propTypes = {
  sb: PropTypes.object.isRequired,
  onChangeClasificacionZona: PropTypes.func.isRequired,
};

export default ColClasificacionZona;
