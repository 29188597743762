import { ABONADA, CANCELADA, PAGADA, PAGADA_MANUAL, PENDIENTE } from '@config/constants';
import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { format } from '@utils/parseCost';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DetailCustomer from '@components/customers/DetailCustomer';
import Cancel from './Cancel';

const Table = ({ list }) => {
  const switchEstado = (estado, id) => {
    let text = '';
    let color = 'secondary';

    if (estado === PENDIENTE) {
      text = 'Pendiente';
      color = 'secondary';
    } else if (estado === PAGADA) {
      text = 'Pagada';
      color = 'success';
    } else if (estado === PAGADA_MANUAL) {
      text = 'Pagada manualmente';
      color = 'success';
    } else if (estado === ABONADA) {
      text = 'Abonada';
      color = 'warning';
    } else if (estado === CANCELADA) {
      text = 'Cancelada';
      color = 'danger';
    }
    return (
      <span id={id} className={`label label-${color}`}>
        {text}
      </span>
    );
  };

  const renderRows = () => {
    return list.map((r, i) => {
      const { _id, fecha_original, serie, numero, uuid, importe, saldo, customerId, estado, fecha_pago, moneda } = r;
      let currency = 'MXN';
      if (moneda === 'DOLAR' || moneda === 'DOLLAR' || moneda === 'DÓLAR') currency = 'USD';
      return (
        <tr key={i}>
          <td className='text-center'>
            <input
              type='checkbox'
              name='notascreditos[]'
              id={`notacredito-${i}`}
              onChange={this.onChange}
              value={_id}
              disabled={saldo === 0 || estado !== PENDIENTE || moneda !== 'PESO'}
            />
          </td>
          <td>
            {serie ? `${serie}-` : ''} {numero}
          </td>
          <td>{moment(fecha_original).local().format('DD/MM/YYYY HH:mm')}</td>
          <td>{uuid}</td>
          <td>
            <DetailCustomer customer={customerId} />
          </td>
          <td>${format(importe, 2)} </td>
          <td className='with-actions'>
            <span className='label label-default mr-5'>{currency}</span>
            {switchEstado(estado, `row-${i}`)}
            {estado === PAGADA || estado === PAGADA_MANUAL ? (
              <span>
                <br />
                <small>
                  <em>{moment(fecha_pago).isValid() ? moment(fecha_pago).local().format('DD/MM/YYYY HH:mm') : ''} </em>
                </small>
              </span>
            ) : null}
            <span className='actions'>
              <RedirectTooltip
                id={1}
                icon='search'
                url={`/notas-creditos/detalle/${_id}`}
                labelTooltip='Completa'
                className='btn btn-sm btn-primary'
              />
              {estado === PENDIENTE ? (
                <Fragment>
                  <Cancel id={i} itemId={_id} notaCredito={{ serie, numero }} {...this.props} />
                </Fragment>
              ) : null}
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='1%'>&nbsp;</th>
          <th width='3%'>Folio</th>
          <th width='13%'>Fecha</th>
          <th width='25%'>Folio</th>
          <th width='25%'>Cliente</th>
          <th width='6%'>Importe</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={7}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
};

export default Table;
