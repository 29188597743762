import Joi from 'joi';
import { MESSAGES } from '@utils/validations';
const R_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const levantamientoSchemaUpdate = Joi.object({
  tipoLevantamientoId: Joi.string().label('Tipo de cotización').required().regex(R_MONGO_ID),
  numero_levantamiento: Joi.string().label('Número de levantamiento').required(),
  fecha: Joi.date().label('Fecha').required(),
  estatus_levantamiento: Joi.string().label('Estatus').required(),
  agenteId: Joi.string().label('Agente').required().regex(R_MONGO_ID),
  sucursalId: Joi.string().label('Sucursal').required().regex(R_MONGO_ID),
  clienteId: Joi.string().label('Cliente').required().regex(R_MONGO_ID),
  tipo_cliente: Joi.string().label('Tipo de cliente').required(),
  domicilioId: Joi.string().label('Domicilio').regex(R_MONGO_ID),
  observaciones: Joi.string().allow(null, '').label('Observaciones'),
  clasificacionesId: Joi.array().label('Clasificaciones').items(Joi.string().regex(R_MONGO_ID)),
}).messages(MESSAGES);

export function validateUpdate(levantamiento) {
  return levantamientoSchemaUpdate.validate(levantamiento);
}
