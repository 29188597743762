import React from 'react';
import PropTypes from 'prop-types';
import RowDetalleParte from './RowDetalleParte';

const RowDetalleTable = ({ partes, onChangeParte, onRemoveParte }) => {
  return (
    <>
      <tr>
        <td style={{ padding: '0px', verticalAlign: 'middle' }} colSpan='8'>
          <table className='table table-condenced table-hover' style={{ margin: '0px' }}>
            <thead>
              <tr>
                <th width='2%'>&nbsp;</th>
                <th width='12%'>#</th>
                <th>Descripción</th>
                <th>Unidad</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th width='8%' className='text-right'>
                  Importe
                </th>
                <th width='8%'>&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {partes.map((parte, j) => (
                <RowDetalleParte key={j} parte={parte} onChangeParte={(parte) => onChangeParte(j, parte)} onRemoveParte={() => onRemoveParte(j)} />
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

RowDetalleTable.propTypes = {
  i: PropTypes.number,
  partes: PropTypes.array,
  onChangeParte: PropTypes.func,
  onRemoveParte: PropTypes.func,
};

export default RowDetalleTable;
