import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteTiposContactos from './DeleteTiposContactos';
import EditTiposContactos from './EditTiposContactos';

const ListTiposContactos = ({ catalogTiposContactos, ...props }) => {
  const renderRows = () => {
    return catalogTiposContactos.map((row, item) => {
      const { _id, tipo } = row;
      return (
        <tr key={item}>
          <td>{tipo}</td>
          <td>
            <IfPermission action='eliminar'>
              <DeleteTiposContactos id={item} tiposContactoId={_id} tipo={tipo} {...props} btnClass='btn btn-outline-danger' />
            </IfPermission>
            <IfPermission action='editar'>
              <EditTiposContactos id={item} tiposContactoId={_id} tipo={tipo} {...props} btnClass='btn btn-outline-info' />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='80%'>Nombre</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListTiposContactos.propTypes = {
  catalogTiposContactos: PropTypes.array.isRequired,
};

export default ListTiposContactos;
