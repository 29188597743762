import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React,{ useCallback, useEffect, useState } from "react"
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import New from './New';
import { Col, Row } from 'react-bootstrap';

const Tarjetas = () => {
    const [data, setData] = useState([]);
    const [tarjeta] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState(0);
    const [pagination] = useState(20);
    const [search, setSearch] = useState('');
    
    const handleSearch = useCallback(async () => {
        const page = activePage * pagination - pagination;
        setLoading(true);
        const body = { search };
        const { data, count } = await postRequest({ url: `tarjetas/search`, params: { page, limit: pagination }, body });
        setData(data);
        setCount(count);
        setLoading(false);
      }, [activePage, search, pagination]);
    
    useEffect(() =>{
        handleSearch();
    },[handleSearch]);
 
    const onSearch = (params = {search:''}) =>{
        setSearch(params.search);
        setActivePage(1);
    }

    const handlePagination = (eventKey) => {
        setActivePage(eventKey);
    }
    const renderList = () => {
        return <List data={data} loadData={handleSearch}/>;
    };

    const moduleList = !loading ? renderList() : <Loading/>;

    return(
        <Module title='Tarjetas'>
         <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
            <IfPermission action='crear'>
                 <New loadData={handleSearch} tarjeta={tarjeta} />
            </IfPermission>
         </Search>
            <Row>
                <Col>
                    <br />
                    {moduleList}
                    <div className='text-center'>
                        <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination}/>
                    </div>
                </Col>
            </Row>
        </Module>
    );
};
Tarjetas.propTypes = {};

export default Tarjetas;