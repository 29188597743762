import { DivFormGroup, FormInput, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import Required from '@controls/Required';
import RowDomicilio from './components/RowDomicilio';
import CbTableResponsive from '@cbcomponents/CbTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import RowContacto from './components/RowContacto';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const INIT_DOMICILIO = {
  editando: true,
  estado: '',
  municipio: '',
  colonia: '',
  localidad: '',
  paisObject: null,
  estadoObject: null,
  municipioObject: null,
  coloniaObject: null,
  codigo_postal: '',
};

const INIT_CONTACTO = {
  nombre: '',
  paterno: '',
  materno: '',
  correo: '',
  telefono: '',
  tipos: [],
};

// const TIPOS_CLIENTES = [
//   { value: 'F', label: 'Física' },
//   { value: 'M', label: 'Moral' },
//   { value: 'I', label: 'Informal' },
//   { value: 'E', label: 'Extranjera' },
// ];

const Nuevo = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [numeroCliente, setNumeroCliente] = useState('');
  const [regimen, setRegimen] = useState('F');
  const [rfc, setRfc] = useState('');
  const [nombre, setNombre] = useState('');
  const [paterno, setPaterno] = useState('');
  const [materno, setMaterno] = useState('');
  const [razon_social, setRazonSocial] = useState('');
  const [nombre_comercial, setNombreComercial] = useState('');

  const [listContactos, setListContactos] = useState([]);
  const [listDomicilios, setListDomicilios] = useState([]);

  const close = () => setShowModal(false);

  const open = async () => {
    const { data } = await getRequest({ url: `customers/siguienteNumero` });
    setNumeroCliente(data.numero_cliente);
    setRegimen('I');
    setRfc('XAXX010101000');
    setShowModal(true);
    setListContactos([{ ...INIT_CONTACTO }]);
    setListDomicilios([{ ...INIT_DOMICILIO }]);
  };

  const validations = (params) => {
    let validate = {
      success: false,
      message: '',
    };

    if (isEmpty(params.rfc)) {
      validate.message = 'RFC es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.nombre)) {
      validate.message = 'Nombre es requerido.';
    } else if (params.regimen === 'F' && isEmpty(params.paterno)) {
      validate.message = 'Apellido paterno es requerido.';
    } else if (
      params.listDomicilios.filter(
        (domicilio) =>
          isEmpty(domicilio.calle + '') ||
          !domicilio.paisId ||
          !domicilio.estadoId ||
          !domicilio.municipioId ||
          !domicilio.coloniaId ||
          isEmpty(domicilio.codigo_postal + '')
      ).length > 0
    ) {
      validate.message = 'Los domicilios requieren calle, pais, estado, municipio, colonia y código postal.';
    } else if (
      params.listContactos.filter(
        (contacto) => contacto.tipos.length === 0 || isEmpty(contacto.nombre) || (isEmpty(contacto.correo) && isEmpty(contacto.telefono))
      ).length > 0
    ) {
      validate.message = 'Los contactos requieren tipo contacto, nombre y correo electronico.';
    } else {
      validate.success = true;
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const handleRegistrar = async (event) => {
    event.preventDefault();

    const body = {
      numero_cliente: numeroCliente,
      regimen,
      rfc,
      nombre,
      paterno,
      materno,
      razon_social,
      nombre_comercial,
      nacionalidad: '',
      extranjero: false,
      estado_civil: '',
      sexo: '',
      fecha_nacimiento: '',
      listContactos,
      listDomicilios: listDomicilios.map((domicilio) => ({
        calle: domicilio.calle || '',
        exterior: domicilio.exterior || '',
        interior: domicilio.interior || '',
        calle1: domicilio.calle1 || '',
        calle2: domicilio.calle2 || '',
        coloniaId: domicilio.coloniaObject?._id,
        municipioId: domicilio.municipioObject?._id,
        estadoId: domicilio.estadoObject?._id,
        paisId: domicilio.paisObject?._id,
        codigo_postal: domicilio.codigo_postal || '',
        localidad: domicilio.localidad || '',
        referencias: domicilio.referencias || '',
        nombre: domicilio.nombre || '',
        monitoreo: domicilio.monitoreo || '',
        es_fiscal: domicilio.es_fiscal,
      })),
    };

    if (validations(body)) {
      await postRequest({ url: `customers/nuevo`, body });
      close();
      if (loadData) loadData();
    }
  };

  const onChangeRegimen = (e) => {
    const { value } = e.target;
    if (value === 'I') {
      setRfc('XAXX010101000');
    } else if (value === 'E') {
      setRfc('XEXX010101000');
    }
    setRegimen(value);
  };

  const addContact = () => {
    setListContactos([
      ...listContactos,
      {
        nombre: '',
        paterno: '',
        materno: '',
        correo: '',
        telefono: '',
        tipos: [],
      },
    ]);
  };

  const removedContacto = (index, e) => {
    e.preventDefault();
    setListContactos(listContactos.filter((_, i) => i !== index));
  };

  const onChangeContacto = (index, contacto) => {
    setListContactos(listContactos.map((cnt, i) => (i === index ? { ...contacto } : cnt)));
  };

  const addDomicilio = () => {
    setListDomicilios([
      ...listDomicilios,
      {
        editando: true,
        estado: '',
        municipio: '',
        colonia: '',
        localidad: '',
        paisObject: null,
        estadoObject: null,
        municipioObject: null,
        coloniaObject: null,
        codigo_postal: '',
      },
    ]);
  };

  const removeDomicilio = (index, e) => {
    e.preventDefault();
    setListDomicilios(listDomicilios.filter((_, i) => i !== index));
  };

  const editDomicilio = (index, e) => {
    e.preventDefault();
    setListDomicilios(listDomicilios.map((domicilio, i) => ({ ...domicilio, editando: i === index && !domicilio.editando })));
  };

  const renderRowsContactos = () => {
    return listContactos.map((contacto, i) => (
      <RowContacto key={i} contacto={contacto} onChange={onChangeContacto.bind(null, i)} onRemove={removedContacto.bind(null, i)} />
    ));
  };

  const onChangeDomicilio = (index, dom) => {
    setListDomicilios(listDomicilios.map((domicilio, i) => (i === index ? { ...dom } : domicilio)));
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='plus' title='Nuevo' type='success'></CbButtonOver>
      <CbModal show={showModal} onClose={close} onSave={handleRegistrar} title='Nuevo cliente' size='xl'>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col sm='4'>
                <div className='form-group inline'>
                  <label>Régimen</label>
                  <div>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChangeRegimen} value={'F'} checked={regimen === 'F'} /> Física
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChangeRegimen} value={'M'} checked={regimen === 'M'} /> Moral
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChangeRegimen} value={'I'} checked={regimen === 'I'} /> Informal
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChangeRegimen} value={'E'} checked={regimen === 'E'} /> Extranjera
                    </label>
                  </div>
                </div>
              </Col>
              <Col sm='3'>
                <FormInput name='numero_cliente' title='Número de cliente' onChange={(e) => setNumeroCliente(e.target.value)} value={numeroCliente} />
              </Col>
              <Col sm='5'>
                <DivFormGroup name='clienteName' required title={'RFC'}>
                  <Input
                    name='rfc'
                    title='RFC'
                    onChange={(e) => setRfc(e.target.value)}
                    value={rfc}
                    maxLength={13}
                    required
                    disabled={regimen === 'I' || regimen === 'E'}
                  />
                </DivFormGroup>
              </Col>
            </Row>

            <Row>
              {regimen === 'F' ? (
                <Fragment>
                  <Col sm='4'>
                    <FormInput name='nombre' title='Nombre(s)' onChange={(e) => setNombre(e.target.value)} value={nombre} required />
                  </Col>
                  <Col sm='4'>
                    <FormInput name='paterno' title='Apellido Paterno' onChange={(e) => setPaterno(e.target.value)} value={paterno} required />
                  </Col>
                  <Col sm='4'>
                    <FormInput name='materno' title='Apellido Materno' onChange={(e) => setMaterno(e.target.value)} value={materno} />
                  </Col>
                </Fragment>
              ) : (
                <Fragment>
                  <Col sm='12'>
                    <FormInput
                      name='razon_social'
                      title='Razón Social'
                      onChange={(e) => setRazonSocial(e.target.value)}
                      value={razon_social}
                      required
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
            <Row>
              <Col sm='12'>
                <FormInput
                  name='nombre_comercial'
                  title='Nombre comercial'
                  onChange={(e) => setNombreComercial(e.target.value)}
                  value={nombre_comercial}
                  required
                />
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <div className='panel panel-default'>
            <div className='panel-heading'>
              <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Nuevo domicilio</Tooltip>}>
                <button onClick={addDomicilio} className='btn btn-outline-success btn-sm pull-right' type='button'>
                  <Icon icon='plus' />
                </button>
              </OverlayTrigger>
              <h2 className='panel-title'>
                <Icon icon='user' style={{ fontSize: '0.8em' }} /> Domicilios
              </h2>
            </div>
            <CbTableResponsive>
              <thead>
                <tr>
                  <th width='15%'>Nombre</th>
                  <th width='5%'>Monitoreo</th>
                  <th>Domicilio</th>
                  <th width='10%'></th>
                </tr>
              </thead>
              <CbTableBody colSpan={5}>
                {listDomicilios.map((domicilio, i) => (
                  <RowDomicilio
                    key={i}
                    domicilio={domicilio}
                    editDomicilio={editDomicilio.bind(null, i)}
                    removeDomicilio={removeDomicilio.bind(null, i)}
                    onChangeDomicilio={onChangeDomicilio.bind(null, i)}
                  />
                ))}
              </CbTableBody>
            </CbTableResponsive>
            <div className='panel-footer'></div>
          </div>
        </div>
        <div>
          <div className='panel panel-default'>
            <div className='panel-heading'>
              <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Nuevo Contacto</Tooltip>}>
                <button onClick={addContact} className='btn btn-success pull-right' type='button'>
                  <Icon icon='plus' />
                </button>
              </OverlayTrigger>
              <h2 className='panel-title'>
                <Icon icon='user' style={{ fontSize: '0.8em' }} /> Contactos
              </h2>
            </div>
            <CbTableResponsive>
              <thead>
                <tr>
                  <th width='15%'>
                    Tipo <Required />
                  </th>
                  <th width='15%'>
                    Nombre <Required />
                  </th>
                  <th width='15%'>Apellido P. </th>
                  <th width='15%'>Apellido M.</th>
                  <th width='10%'>Celular/Teléfono</th>
                  <th width='15%'>
                    Correo
                    <Required />
                  </th>
                  <th width='10%'></th>
                </tr>
              </thead>
              <CbTableBody colSpan={6}>{renderRowsContactos()}</CbTableBody>
            </CbTableResponsive>
            <div className='panel-footer'></div>
          </div>
        </div>
      </CbModal>
    </>
  );
};

Nuevo.propTypes = {
  loadData: PropTypes.func,
};

export default Nuevo;
