import moment from 'moment';
import { postRequest } from '@utils/api';

export const fetchAll = async ({ pagina, limite, orden, ...params }, signal) => {
  const respuesta = await postRequest({
    url: 'ordenes-servicio/search',
    signal,
    params: {
      page: pagina,
      limit: limite,
      skip: params.skip,
      sort: orden?.campo,
      order: orden?.direccion,
      search: params.search,
      startDate: moment(params.fechaInicio, 'YYYY-MM-DD').utc(),
      endDate: moment(params.fechaFin, 'YYYY-MM-DD').utc(),
      sucursalId: params.sucursalId,
      agenteId: params.agenteId,
      estatus: params.estatus,
      agenteAsignadoId: params.agenteAsignadoId,
      tipoOrdenServicioId: params.tipoOrdenServicioId,
    },
  });
  if (respuesta.error) {
    return {
      error: respuesta.error,
      rows: [],
      count: 0,
    };
  }
  return {
    rows: respuesta.data,
    count: respuesta.count,
  };
};
