import React from 'react';
import { Row, Col } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { DivFormGroup, TextArea } from '@controls';
import PropTypes from 'prop-types';
import { getRequest } from '@utils/api';
import { Icon } from '@stateless';
import Select from '@components/forms/Select';

const RowDetalles = ({ x, agentes, resultados, opciones_estatus_seguimientos, onClickElimiarSeguimiento, onChangeSeguimiento }) => {
  const loadOptionsAgentes = async (inputValue, callback) => {
    const { data } = await getRequest({ url: `agentes-autocomplete`, params: { query: inputValue } });
    callback(data);
  };

  const onChangeAgentesAsignadosSeguimientos = (selectedOptions) => {
    onChangeSeguimiento({
      ...x,
      agentes_asignados: selectedOptions,
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    onChangeSeguimiento({
      ...x,
      [name]: value,
    });
  };

  const onSelectEstatus = (e) => {
    onChangeSeguimiento({
      ...x,
      estatus: e,
    });
  };

  const onChangeResultadoSeguimiento = (selectedOption) => {
    onChangeSeguimiento({
      ...x,
      resultadoVisita: selectedOption,
    });
  };

  return (
    <div className={'list-group-item list-group-item-' + (x.estatus === 'FINALIZADO' ? 'success' : 'warning')}>
      <Row>
        <Col sm='12'>
          <a href='' className='pull-right' type='button' onClick={onClickElimiarSeguimiento}>
            <Icon style={{ color: 'red' }} icon='remove' />
          </a>
          <DivFormGroup name='observaciones' title='Observaciones' required>
            <TextArea name='observaciones' rows={2} onChange={(e) => onChange(e)} value={x.observaciones} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='12'>
          <DivFormGroup name='agente' title='Responsables'>
            <AsyncSelect
              isClearable
              isMulti
              value={x.agentes_asignados}
              onChange={(selectedOptions) => onChangeAgentesAsignadosSeguimientos(selectedOptions)}
              options={agentes}
              loadOptions={loadOptionsAgentes}
              getOptionLabel={(option) => `${option.nombre}`}
              getOptionValue={(option) => option._id}
              placeholder='Seleccione asesores'
              noResultsText='No se encontraron resultados'
            />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='resultadoVisita' title='Resultado'>
            <CreatableSelect
              isClearable
              value={x.resultadoVisita}
              onChange={(selectedOption) => onChangeResultadoSeguimiento(selectedOption)}
              options={resultados}
              searchPromptText='Resultados...'
              getOptionLabel={(option) => `${option.nombre}`}
              getOptionValue={(option) => option._id}
              placeholder='Seleccione o agregue resultado...'
              noResultsText='No se encontraron resultados'
            />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='estatus' title='Estatus'>
            <Select value={x.estatus} options={opciones_estatus_seguimientos} onChange={(value) => onSelectEstatus(value)} />
          </DivFormGroup>
        </Col>
      </Row>
    </div>
  );
};

RowDetalles.propTypes = {
  x: PropTypes.object,
  i: PropTypes.number,
  agentes: PropTypes.array,
  resultados: PropTypes.array,
  opciones_estatus_seguimientos: PropTypes.array,
  onClickElimiarSeguimiento: PropTypes.func,
  onChangeSeguimiento: PropTypes.func,
};

export default RowDetalles;
