import React, { useEffect } from 'react';
import Select from 'react-select';
import { useStoreTiposPedidos } from '@stores/catalogs.store';
import PropTypes from 'prop-types';

const SelectTipoPedido = ({ onChange, name, value }) => {
  const { data, execute } = useStoreTiposPedidos();
  useEffect(() => {
    execute();
  }, []);
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      value={value}
      name={name}
      placeholder='Seleccione'
      options={data}
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='basic-multi-select'
      getOptionLabel={(x) => x.nombre}
      getOptionValue={(x) => x._id}
      classNamePrefix='select'
    />
  );
};

SelectTipoPedido.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
};

export default SelectTipoPedido;
