import React from 'react';
import PropTypes from 'prop-types';
import Required from './Required';

const DivFormGroup = ({ className = 'form-group', style, children, title, required, name, inline }) => {
  let nameClass = className.match('form-group') ? className : `form-group ${className}`;
  if (inline) nameClass = nameClass.replace('form-group', 'form-group inline');
  return (
    <div className={nameClass} style={style}>
      {title && (
        <label name={name} title={title}>
          {title} {required && <Required />}
        </label>
      )}
      {children}
    </div>
  );
};

DivFormGroup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  inline: PropTypes.bool,
};

export default DivFormGroup;
