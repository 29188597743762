import React, { useState, useEffect } from 'react';

import { Module, Icon } from '@stateless';
import Panel from '@controls/Panel';

import { getRequest } from '@utils/api';

import ItemField from './ItemField';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetailCharacteristics = () => {
  const { characteristicsId } = useParams();
  const [characteristics, setCharacteristics] = useState(null);

  useEffect(() => {
    const fetchCharacteristics = async () => {
      const { data } = await getRequest({ url: `characteristics/${characteristicsId}` });
      setCharacteristics(data);
    };

    fetchCharacteristics();
  }, [characteristicsId]);

  const renderItems = (list, proccess) => {
    return list.map((fieldValue, item) => {
      if (typeof fieldValue.stepId !== 'undefined' && fieldValue.stepId !== null) {
        const {
          stepId: { addProcess },
        } = fieldValue;

        if (proccess) {
          if (addProcess) {
            return <ItemField key={item} {...fieldValue} />;
          }
        } else {
          if (!addProcess) {
            return <ItemField key={item} {...fieldValue} />;
          }
        }
      }
      return null;
    });
  };

  if (!characteristics) {
    return <Module loading={true} />;
  }

  const { characteristicsConfigurationId, characteristicsRecipeId, listFieldsValues } = characteristics;
  const detailProcess = renderItems(listFieldsValues, true);

  return (
    <Module
      onClickBack={window.history.back}
      title={`${characteristicsConfigurationId.name}-${characteristicsRecipeId.recipe}`}
      loading={!characteristics}
    >
      <Row>
        <Col>
          <Panel
            type='primary'
            header={
              <div>
                <Icon icon='cog' /> Procedimiento
              </div>
            }
          >
            {detailProcess}
          </Panel>
        </Col>
      </Row>
    </Module>
  );
};

DetailCharacteristics.propTypes = {};

export default DetailCharacteristics;
