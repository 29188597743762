import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import List from './List';
import New from './New';

import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import { Col, Row } from 'react-bootstrap';

const TiposCompras = () => {
  const [list, setList] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [tiposPartidas, setTiposPartidas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  const load = useCallback(async () => {
    setLoading(true);
    const { almacenes, sucursales, tiposPartidas } = await getRequest({ url: `tipos-compras-catalogos` });
    setAlmacenes(almacenes);
    setSucursales(sucursales);
    setTiposPartidas(tiposPartidas);
    handleSearch();
  }, []);

  const handleSearch = useCallback(async () => {
    setLoading(true);
    const page = activePage * pagination - pagination;
    const body = { search };
    const { data, count } = await postRequest({ url: 'tipos-compras/search', params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search]);

  const onSearch = useCallback(
    (params = { search: '' }) => {
      setSearch(params.search);
      setActivePage(1);
      handleSearch();
    },
    [handleSearch]
  );

  const handlePagination = useCallback(
    (eventKey) => {
      setActivePage(eventKey);
      handleSearch();
    },
    [handleSearch]
  );

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Module title='Tipos de Compras'>
      <Row>
        <Col>
          <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
            <New load={onSearch} almacenes={almacenes} sucursales={sucursales} tiposPartidas={tiposPartidas} />
          </Search>
        </Col>
      </Row>
      <Loading loading={loading}>
        <List list={list} almacenes={almacenes} sucursales={sucursales} tiposPartidas={tiposPartidas} load={onSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

TiposCompras.propTypes = {};

export default TiposCompras;
