import { DivFormGroup, FilterSelectNuevo, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import CbTableResponsive from '@cbcomponents/CbTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import { useStoreNotification } from '@stores/catalogs.store';

const Subclasificadores = ({ itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [subclasificaciones, setSubclasificaciones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setSubclasificaciones([]);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `clasificadores-levantamientos/${itemId}` });
    setSubclasificaciones(
      data.subclasificaciones
        ? data.subclasificaciones.map((sc) => ({
            ...sc,
            equipos: sc.equipos.map((e) => ({
              equipoId: e,
              codigo: e.code,
              descripcion: e.name,
              unidadeMedida: e.equipmentsMeasureId.measure,
            })),
          }))
        : []
    );
    setIsLoading(false);
    setShowModal(true);
  };

  const onChangeSubclasificacion = (index, event) => {
    const { value, name } = event.target;
    setSubclasificaciones((prev) =>
      prev.map((sub, i) => {
        if (index === i) sub[name] = value;
        return { ...sub };
      })
    );
  };

  const handleAgregarSubclasificador = () => {
    setSubclasificaciones((prev) => [
      ...prev,
      {
        orden: Math.max(prev.map((x) => x.orden)) || 1,
        nombre: '',
        equipos: [],
      },
    ]);
  };

  const handleRemoveSubclasificador = (index, e) => {
    e.preventDefault();
    setSubclasificaciones((prev) => prev.filter((x, i) => i !== index));
  };

  const onChangeProductoInput = (index, value) => {
    setSubclasificaciones((prev) =>
      prev.map((sc, i) => {
        if (i === index) {
          return {
            ...sc,
            productoName: value,
          };
        }
        return sc;
      })
    );
  };

  const onChangeProducto = (index, itemSelect) => {
    if (itemSelect) {
      setSubclasificaciones((prev) =>
        prev.map((sc, i) => {
          if (i === index) {
            return {
              ...sc,
              productoName: '',
              equipos: [
                ...sc.equipos,
                {
                  equipoId: itemSelect,
                  codigo: itemSelect.code,
                  descripcion: itemSelect.name,
                  unidadeMedida: itemSelect.equipmentsMeasureId.measure,
                },
              ],
            };
          }
          return sc;
        })
      );
    }
  };

  const handleRemoveEquipoSubclasificador = (index, jindex, e) => {
    e.preventDefault();
    setSubclasificaciones((prev) =>
      prev.map((sc, i) => {
        if (i === index) {
          return {
            ...sc,
            equipos: sc.equipos.filter((e, j) => j !== jindex),
          };
        }
        return sc;
      })
    );
  };

  const handleSubclasificadores = async (event) => {
    event.preventDefault();
    const data = {
      subclasificaciones,
    };

    if (validations(data)) {
      await putRequest({ url: `clasificadores-levantamientos/subclasificadores/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='th-list' title='Editar' onClick={open}  type='success' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleSubclasificadores} isLoading={isLoading} size='xl'>
        <Row>
          <Col>
            {subclasificaciones.map((subclasificacion, i) => (
              <div className='list-group' key={i}>
                <div className={'list-group-item'}>
                  <a href='' className='pull-right' type='button' onClick={(e) => handleRemoveSubclasificador(i, e)}>
                    <Icon style={{ color: 'red' }} icon='remove' />
                  </a>
                  <h4 className='list-group-item-heading'>{subclasificacion.nombre}</h4>
                  <Row>
                    <Col>
                      <DivFormGroup name='orden' title='Orden' required>
                        <Input type='number' name='orden' onChange={(e) => onChangeSubclasificacion(i, e)} value={subclasificacion.orden} />
                      </DivFormGroup>
                    </Col>
                    <Col>
                      <DivFormGroup name='nombre' title='Nombre' required>
                        <Input name='nombre' onChange={(e) => onChangeSubclasificacion(i, e)} value={subclasificacion.nombre} />
                      </DivFormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DivFormGroup name='producto' title='Buscar y agregar equipo'>
                        <FilterSelectNuevo
                          placeholder='Buscar por código o descripción'
                          async={true}
                          value={subclasificacion.productoObj}
                          inputValue={subclasificacion.productoName}
                          onInputChange={(value) => onChangeProductoInput(i, value)}
                          onChangeOption={(itemSelect) => onChangeProducto(i, itemSelect)}
                          url='equipments-autocomplete'
                          fields={['_id', 'code,name']}
                          options={[]}
                        />
                      </DivFormGroup>
                    </Col>
                  </Row>
                  <CbTableResponsive>
                    <thead>
                      <tr>
                        <th width='5%'>Código</th>
                        <th width='45%'>Descripción</th>
                        <th width='5%'>Unidad</th>
                        <th width='2%'>&nbsp;</th>
                      </tr>
                    </thead>
                    <CbTableBody colSpan={4} isLoading={isLoading}>
                      {subclasificacion.equipos.map((equipo, j) => (
                        <tr key={j}>
                          <td>{equipo.codigo}</td>
                          <td>{equipo.descripcion}</td>
                          <td>{equipo.unidadeMedida}</td>
                          <td>
                            <a href='' className='pull-right' type='button' onClick={(e) => handleRemoveEquipoSubclasificador(i, j, e)}>
                              <Icon style={{ color: 'red' }} icon='remove' />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </CbTableBody>
                  </CbTableResponsive>
                </div>
              </div>
            ))}
            <div className='list-group'>
              <Button
                className='list-group-item'
                onClick={handleAgregarSubclasificador}
                style={{ textAlign: 'center', fontSize: '1.1em', fontWeight: '600' }}
                type='button'
              >
                <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Agregar subclasificaciones
              </Button>
            </div>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Subclasificadores.propTypes = {
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Subclasificadores;
