import React from 'react';

const DataEmpty = () => {
  const styleDataEmpty = {
    backgroundColor: 'gray',
    color: 'white',
    padding: '10px 0px 3px 0px',
    marginBottom: '20px',
  };

  return (
    <div className='text-center' style={styleDataEmpty}>
      <p>Sin información seleccionada</p>
    </div>
  );
};

export default DataEmpty;
