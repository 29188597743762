import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import CbBadgeMoneda from '@cbcomponents/CbBadgeMoneda';
import CbModal from '@cbcomponents/CbModal';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';

const ModalAlternativos = ({ equipmentId, showModal, isLoading, onClose, onSelectAlternativo }) => {
  const [alternativosEquipo, setAlternativosEquipo] = useState([]);

  useEffect(() => {
    const loadData = async (equipmentId) => {
      const { alternativos } = await getRequest({ url: `equipments/alternativos/${equipmentId}` });
      setAlternativosEquipo(alternativos);
    };

    loadData(equipmentId);
  }, [equipmentId]);

  const handleDoubleClickSeleccionadoAlternativo = (x) => {
    onSelectAlternativo(x);
  };

  const handleClose = () => {
    onClose();
  };

  const rowsAlternativo = alternativosEquipo
    .filter((x) => x.equipoId)
    .map((x, i) => (
      <tr key={i} style={{ cursor: 'pointer' }} onDoubleClick={() => handleDoubleClickSeleccionadoAlternativo(x)}>
        <td>{x.equipoId.code}</td>
        <td>{x.equipoId.name}</td>
        <td>{x.equipoId.equipmentsMeasureId.measure}</td>
        <td>
          $ {format(x.equipoId.precio, 2)} <CbBadgeMoneda moneda={x.equipoId.moneda}></CbBadgeMoneda>
        </td>
      </tr>
    ));

  return (
    <CbModal size='xl' show={showModal} onHide={handleClose} title='Equipos alternativos y otros proveedores'>
      <Row>
        <Col>
          <CbPanelTableResponsive>
            <thead>
              <tr>
                <th width='5%'>Codigo</th>
                <th width='45%'>Descripción</th>
                <th width='5%'>Unidad</th>
                <th width='5%'>Precio</th>
              </tr>
            </thead>
            <CbTableBody isLoading={isLoading} colSpan={4}>
              {rowsAlternativo}
            </CbTableBody>
          </CbPanelTableResponsive>
        </Col>
      </Row>
    </CbModal>
  );
};

ModalAlternativos.propTypes = {
  equipmentId: PropTypes.string,
  showModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectAlternativo: PropTypes.func,
  onSelectProveedor: PropTypes.func,
};

export default ModalAlternativos;
