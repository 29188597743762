import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Button, Col, DropdownItem, Modal, Row } from 'react-bootstrap';
import { Icon, Loading, RowsNotFound, Saving } from '@stateless';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { format } from '@utils/parseCost';
import { isMongoId } from 'validator';
import SelectAgente from '@components/forms/SelectAgente';
import SelectProveedor from '@components/forms/SelectProveedor';
import InputCurrency from '@components/forms/InputCurrency';
import SelectMoneda from '@components/forms/SelectMoneda';
import SelectTipoCompra from '@components/forms/SelectTipoCompra';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectUsoCFDI from '@components/forms/SelectUsoCFDI';
import RowEquipo from './components/RowEquipo';

const CompletarOrdenDeCompra = ({ solicitudId, notification, loadData }) => {
  const [state, setState] = useState({
    showModal: false,
    nombre: '',
    isLoading: false,
    detalles: [],
    sucursales: [],
    sucursalId: null,
    remisionar: false,
    tipos_compras: [],
    tipoCompraId: null,
    usoCfdiId: null,
    usos_cfdi: [],
    proveedores: [],
    proveedorName: '',
    proveedorObj: null,
    proveedor: null,
    fecha: moment().format('YYYY-MM-DD'),
    monedaId: MONEDA.DOLAR,
  });

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      condicion: '',
      nombre: '',
      remisionar: false,
    }));
  };

  const open = async () => {
    const fecha = moment().format('YYYY-MM-DD');
    const { data } = await getRequest({ url: `tipo-cambio/fecha`, params: { fecha } });
    if (!data?._id) {
      return notification({
        title: 'Advertencia',
        message: 'Se debe capturar el tipo de cambio del dia. Catálogos -> Tipos de cambio',
        type: 'warning',
      });
    }
    const {
      data: { sucursales, monedas, usos_cfdi, ubicaciones, tipos_compras, measures, agente },
    } = await getRequest({ url: `compras-catalogos` });
    setState((prevState) => ({
      ...prevState,
      sucursales: sucursales.map((sucursal) => ({
        value: sucursal._id,
        label: `${sucursal.name}`,
      })),
      measures: measures.map((measure) => ({
        value: measure._id,
        label: `${measure.measure}`,
      })),
      tipos_compras,
      ubicaciones,
      usos_cfdi: usos_cfdi.map((usoCfdi) => ({
        value: usoCfdi._id,
        label: `${usoCfdi.descripcion}`,
      })),
      monedas: monedas.map((moneda) => ({
        value: moneda.enumerador,
        label: `${moneda.descripcion}`,
      })),
      agenteObj: { value: agente._id, label: agente.nombre },
      agenteId: agente._id,
      maximo_descuento: agente.maximo_descuento,
      sucursalId: agente.sucursalId,
      agenteName: agente.nombre,
      isLoadingForm: false,
      showModal: true,
      tipoCambio: data.valor,
    }));
    obtenerSolicitud(solicitudId);
  };

  const obtenerSolicitud = async (solicitudCompraId) => {
    const {
      data: { encabezado, detalle },
    } = await getRequest({ url: `solicitud-compra/${solicitudCompraId}` });
    setState((prevState) => ({
      ...prevState,
      isLoadingForm: false,
      sucursalId: encabezado.sucursal._id,
      detalles: detalle.map((x) => {
        let measure = null,
          measureId = null,
          measures = [];
        measure = x.unidadMedida;
        measureId = x.unidadMedidaId;
        if (!x.tipo_partida === 'EQUIPO') {
          if (x.relacionadoId && x.relacionadoId.listCost && x.relacionadoId.listCost.length > 0) {
            measures = prevState.measures.filter((y) => {
              const existe = x.relacionadoId.listCost.map((z) => z.measureId).includes(y.value);
              return existe;
            });
          } else {
            measures = prevState.measures.filter((y) => y.tipo === 'SERVICIO');
          }
        }

        return {
          ...x,
          measure,
          measureId,
          measures,
          tipo_partida: x.tipo_partida,
          noIdentificador: x.codigo,
          moneda_original: x.relacionadoId.moneda_compra,
          precio_original: x.relacionadoId.cost,
          precio: x.relacionadoId.cost || 0,
          descuento: x.descuento || 0,
          impuesto: x.relacionadoId.iva || 16,
        };
      }),
    }));
  };

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));
    if (campo === 'tipoCompraId') {
      const tipoCompra = state.tipos_compras.find((x) => x.value === value) || {};
      getRequest({ url: `compras/siguienteNumero`, params: { tipoCompraId: tipoCompra._id } }).then(({ data }) => {
        setState((prevState) => ({
          ...prevState,
          usa_gastos: tipoCompra.tipoPartidaId ? tipoCompra.tipoPartidaId.usa_gastos : false,
          usa_servicios: tipoCompra.tipoPartidaId ? tipoCompra.tipoPartidaId.usa_servicios : false,
          sucursalId: tipoCompra.tiene_sucursal ? tipoCompra.sucursalId : null,
          numeroOrden: data.numero_compra,
        }));
      });
    }
  };

  const onChangeProveedor = (proveedor) => {
    setState((prevState) => ({
      ...prevState,
      proveedor,
    }));
  };

  const onChangeAgente = (itemSelect) => {
    setState((prevState) => ({
      ...prevState,
      agente: itemSelect,
    }));
  };

  const renderDomicilio = (proveedor) => {
    if (!proveedor) return '';
    const { street, numberExt, numberInt, colony, city, postalCode, state } = proveedor.address;
    return `${street} #${numberExt}-${numberInt},${postalCode} ${colony} ${city}, ${state}`;
  };

  const handleCompletarOrdenDeCompraServicio = async (event) => {
    event.preventDefault();
    const {
      tipoCompraId,
      numeroOrden,
      fecha,
      fecha_entrega,
      estatus,
      sucursalId,
      monedaId,
      tipoCambio,
      total,
      subtotal,
      descuento,
      impuestos,
      proveedor,
      usoCfdiId,
      observaciones,
      detalles,
      agenteId,
    } = state;
    const data = {
      solicitudCompraId: solicitudId,
      tipoCompraId: tipoCompraId,
      numero_compra: numeroOrden,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      estatus_compra: estatus,
      sucursalId,
      monedaId,
      agenteId,
      tipoCambio: cleanNumber(tipoCambio),
      fecha_entrega,
      total: total || 0,
      subtotal: subtotal || 0,
      descuento: descuento || 0,
      iva: impuestos || 0,
      proveedorId: proveedor ? proveedor._id : '',
      usoCfdiId,
      observaciones,
      detalles: detalles.map((detalle) => {
        return {
          ...detalle,
          relacionadoId: detalle.relacionadoId._id,
        };
      }),
    };

    if (validations(data)) {
      await postRequest({ url: `solicitudes-compras/completar/${solicitudId}`, body: data });
      setState((prevState) => ({ ...prevState, showModal: false }));
      loadData();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (!isMongoId(params.tipoCompraId + '')) {
      validate.success = false;
      validate.message = 'Tipo compra es requerido.';
    } else if (!isMongoId(params.sucursalId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerida.';
    } else if (!isMongoId(params.agenteId + '')) {
      validate.success = false;
      validate.message = 'Agente es requerido.';
    } else if (!isMongoId(params.proveedorId + '')) {
      validate.success = false;
      validate.message = 'Proveedor es requerido.';
    } else if (!isMongoId(params.usoCfdiId + '')) {
      validate.success = false;
      validate.message = 'Uso de CFDI es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  const onChangeDetalle = (index, detalle) => {
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => (i === index ? detalle : x)),
    }));
  };

  const renderEquipos = () => {
    if (state.detalles.length === 0) return <RowsNotFound message='No se han agregado equipos.' colSpan={12} />;
    return state.detalles.map((detalle, i) => (
      <RowEquipo key={i} detalle={detalle} i={i} onChangeDetalle={(detalle) => onChangeDetalle(i, detalle)} />
    ));
  };

  const {
    showModal,
    sucursalId,
    isLoading,
    tipoCompraId,
    usoCfdiId,
    proveedorObj,
    proveedor,
    numeroOrden,
    monedaId,
    tipoCambio,
    agenteObj,
    total,
    subtotal,
    descuento,
    impuestos,
  } = state;

  return (
    <Fragment>
      <DropdownItem onClick={open}>
        <Icon icon='pencil' /> Generar compra
      </DropdownItem>
      <Modal size='xl' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Generar compra</Modal.Title>
        </Modal.Header>
        {!isLoading ? (
          <Fragment>
            <Modal.Body>
              <Row>
                <Col sm='3'>
                  <DivFormGroup name='tipoCompraId' required title='Tipo compra'>
                    <SelectTipoCompra value={tipoCompraId} onChange={(value) => onSelect('tipoCompraId', value)} />
                  </DivFormGroup>
                </Col>
                <Col sm='3 col-xs-3'>
                  <FormInput title='Num. de orden' placeholder='Num. de orden' disabled name='numeroOrden' onChange={onChange} value={numeroOrden} />
                </Col>
                <Col sm='3 col-xs-3'>
                  <DivFormGroup name='monedaId' required title='Moneda'>
                    <SelectMoneda value={monedaId} onChange={(value) => onSelect('monedaId', value)} />
                  </DivFormGroup>
                </Col>
                <Col sm='3 col-xs-3'>
                  <DivFormGroup name='tipoCambio' title='Tipo de cambio'>
                    <InputCurrency
                      className='form-control'
                      name='tipoCambio'
                      value={tipoCambio}
                      onChange={onChange}
                      mask={createNumberMask({ suffix: '', prefix: '$ ', allowDecimal: true })}
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='6'>
                  <DivFormGroup name='sucursalId' required title='Sucursal'>
                    <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
                  </DivFormGroup>
                </Col>
                <Col sm='5 col-xs-6'>
                  <DivFormGroup name='agenteName' required title='Agente'>
                    <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='9'>
                  <DivFormGroup name='proveedorName' required title='Proveedor'>
                    <SelectProveedor value={proveedorObj} onChange={onChangeProveedor} name='proveedorObj' />
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <FormInput title='RFC' disabled name='proveedorRfc' onChange={onChange} value={proveedor ? proveedor.rfc : ''} />
                </Col>
              </Row>
              <Row>
                <Col sm='9'>
                  <FormInput title='Domicilio' disabled name='proveedorDomicilio' onChange={onChange} value={renderDomicilio(proveedor)} />
                </Col>
                <Col sm='3'>
                  <DivFormGroup name='usoCfdiId' required title='Uso de CFDI'>
                    <SelectUsoCFDI value={usoCfdiId} onChange={(value) => onSelect('usoCfdiId', value)} />
                  </DivFormGroup>
                </Col>
              </Row>
            </Modal.Body>
            <div style={{ overflow: 'scroll' }}>
              <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
                <thead>
                  <tr>
                    <th width='10%'>#</th>
                    <th>Descripción</th>
                    <th>Unidad</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                    <th>% Descuento</th>
                    <th>% IVA</th>
                    <th>Importe</th>
                    <th width='5%'>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {renderEquipos()}
                  {state.detalles.length > 0 && (
                    <>
                      <tr>
                        <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='7' className='text-right'>
                          Subtotal
                        </th>
                        <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='2'>
                          $ {format(subtotal, 2)}
                        </td>
                      </tr>
                      {impuestos && (
                        <tr>
                          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='7' className='text-right'>
                            IVA
                          </th>
                          <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='2'>
                            $ {format(impuestos, 2)}
                          </td>
                        </tr>
                      )}
                      {descuento && (
                        <tr>
                          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='7' className='text-right'>
                            Descuento
                          </th>
                          <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='2'>
                            $ {format(descuento, 2)}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='7' className='text-right'>
                          Total
                        </th>
                        <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='2'>
                          $ {format(total, 2)}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : (
          <Loading />
        )}
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button className='btn btn-primary pull-right' onClick={handleCompletarOrdenDeCompraServicio} disabled={isLoading}>
            Generar
          </Button>
          <Button onClick={close} disabled={isLoading} className='btn btn-default pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

CompletarOrdenDeCompra.propTypes = {
  id: PropTypes.string.isRequired,
  solicitudId: PropTypes.string.isRequired,
  notification: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default CompletarOrdenDeCompra;
