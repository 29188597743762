import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row } from 'react-bootstrap';
import { Icon, RowsNotFound } from '@stateless';
import { ContactoPrincipal } from '@controls';
import { getRequest } from '@utils/api';
import moment from 'moment';

const ModalContactos = ({ clienteId, callback }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [principal, setPrincipal] = useState(null);

  const close = () => {
    setShowModal(false);
    setData([]);

    if (typeof callback === 'function') {
      callback();
    }
  };

  const loadData = async () => {
    const { data, principal } = await getRequest({ url: `seguimiento-contactos/${clienteId}` });
    setData(data);
    setPrincipal(principal);
  };

  const open = async () => {
    setShowModal(true);
    await loadData();
  };

  const renderRowContactos = () => {
    return data.map((contacto, i) => {
      let extension = '';

      if (contacto.extension !== '') {
        extension = <div>Extensión {contacto.extension}</div>;
      }

      let titulo = '';

      if (typeof contacto.tituloContactoId !== 'undefined') {
        if (contacto.tituloContactoId !== null) {
          titulo = contacto.tituloContactoId.abreviatura;
        }
      }

      return (
        <tr key={i}>
          <td>
            <ul>
              {contacto.tipos.map((t, i) => (
                <li key={i}>
                  <small>{t.tipoContactoId ? t.tipoContactoId.tipo : ''}</small>
                </li>
              ))}
            </ul>
          </td>
          <td>
            {titulo} {contacto.name} {contacto.paterno} {contacto.materno}
            <br />
            {contacto.sexo === 'F' ? 'Femenino' : contacto.sexo === 'M' ? 'Masculino' : ''}
            <br />
            {contacto.fecha_nacimiento !== '' && contacto.fecha_nacimiento !== null
              ? `Fecha de nacimiento ${moment(contacto.fecha_nacimiento).local().format('DD/MM/YYYY')}`
              : ''}
          </td>
          <td>
            <ContactoPrincipal type='mobile' value={contacto.mobile} principal={principal} clienteId={clienteId} callback={loadData} />
            <br />
            {contacto.mobile2.length > 0 ? (
              <ContactoPrincipal type='mobile' value={contacto.mobile2} principal={principal} clienteId={clienteId} callback={loadData} />
            ) : null}
          </td>
          <td>
            <ContactoPrincipal type='phone' value={contacto.phone} principal={principal} clienteId={clienteId} callback={loadData} />
            {extension}
          </td>
          <td>
            <ContactoPrincipal type='email' value={contacto.email} principal={principal} clienteId={clienteId} callback={loadData} />
            <br />
            {contacto.email2.length > 0 ? (
              <ContactoPrincipal type='email' value={contacto.email2} principal={principal} clienteId={clienteId} callback={loadData} />
            ) : null}
          </td>
        </tr>
      );
    });
  };

  const rows = renderRowContactos();

  return (
    <span>
      <button onClick={open} className='btn btn-sm btn-info' type='button'>
        <Icon icon='user' /> Contactos
      </button>

      <Modal size='large' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Contactos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row>
              <Col sm='12'>
                <table className='table table-striped table-hover'>
                  <thead>
                    <tr>
                      <th width='20%'>Tipo</th>
                      <th width='40%'>Nombre</th>
                      <th width='15%'>Celulares</th>
                      <th width='15%'>Teléfono</th>
                      <th width='15%'>Correos electrónicos</th>
                    </tr>
                  </thead>
                  <tbody>{data.length > 0 ? rows : <RowsNotFound colSpan={5} message='No se han agregado contactos' />}</tbody>
                </table>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </span>
  );
};

ModalContactos.propTypes = {
  clienteId: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

export default ModalContactos;
