import { RowItem } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Domicilio from '@components/customers/Domicilio';

const PersonaFisica = ({ customer }) => {
  const switchEstadoCivil = (estado) => {
    let label = '';
    if (estado === 'S') {
      label = 'Soltero/a';
    } else if (estado === 'C') {
      label = 'Casado/a';
    } else if (estado === 'U') {
      label = 'Unión Libre';
    } else if (estado === 'D') {
      label = 'Divorciado/a';
    }
    return label;
  };

  const {
    tituloClienteId,
    name,
    paterno,
    materno,
    email,
    curp,
    mobile,
    phone,
    fecha_nacimiento,
    estado_civil,
    sexo,
    nacionalidad,
    rfc,
    nombre_comercial,
    tipoEstablecimientoId,
    rubroId,
  } = customer;

  let labelSexo = '';

  if (sexo === 'M') {
    labelSexo = 'Masculino';
  } else if (sexo === 'F') {
    labelSexo = 'Femenino';
  }

  let titulo = '';

  if (typeof tituloClienteId !== 'undefined') {
    if (tituloClienteId !== null) {
      titulo = tituloClienteId.abreviatura;
    }
  }

  return (
    <Row>
      <Col sm='4'>
        <RowItem label='Cliente'>
          {titulo} {name} {paterno} {materno}
        </RowItem>
        <RowItem label='Celular'>{mobile}</RowItem>
        <RowItem label='Teléfono'>{phone}</RowItem>
        <RowItem label='Correo electrónico'>{email}</RowItem>
      </Col>
      <Col sm='5'>
        <RowItem label='Nombre Comercial'>{nombre_comercial}</RowItem>
        <RowItem label='Establecimiento'>{tipoEstablecimientoId?.nombre || ''}</RowItem>
        <RowItem label='Rubro'>{rubroId?.rubro || ''}</RowItem>
        <Domicilio {...customer} />
      </Col>
      <Col sm='3'>
        <RowItem label='Fecha de Nacimiento'>{fecha_nacimiento !== null ? moment(fecha_nacimiento).format('DD/MM/YYYY') : ''}</RowItem>
        <RowItem label='Estado civil'>{switchEstadoCivil(estado_civil)}</RowItem>
        <RowItem label='Género'>{labelSexo}</RowItem>
        <RowItem label='Nacionalidad'>{nacionalidad}</RowItem>
        <RowItem label='RFC'>{rfc}</RowItem>
        <RowItem label='CURP'>{curp}</RowItem>
      </Col>
    </Row>
  );
};

PersonaFisica.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default PersonaFisica;
