import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { components } from 'react-select';
import { useStoreSavingLoading } from '@stores/catalogs.store';

export const CustomerOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ borderRadius: '0', fontSize: '12px' }}>
        <b>
          {rest.data.tipo_cliente && (
            <span title='Tipo cliente' className='label label-warning'>
              {rest.data.tipo_cliente}
            </span>
          )}{' '}
          {rest.data.numero_cliente && (
            <span title='Numero de cliente' className='label label-success'>
              {rest.data.numero_cliente}
            </span>
          )}{' '}
          {rest.data.razon_social}
        </b>{' '}
        <br />
        <span title='RFC' className='label label-primary'>
          {rest.data.rfc}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.nombre_comercial}</span>
      </div>
    </components.Control>
  );
};

CustomerOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const OrdenServicioOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ padding: '5px', borderRadius: '0' }}>
        OT:{' '}
        <b>
          {rest.data.numero_orden_servicio && (
            <span title='Descripción' className='label label-success'>
              {rest.data.numero_orden_servicio}
            </span>
          )}{' '}
          {rest.data.referencia}
        </b>{' '}
        <br />
        CLIENTE:
        <b>
          {rest.data.customerId?.numero_cliente && (
            <span title='Numero de cliente' className='label label-success'>
              {rest.data.cliente?.numero_cliente}
            </span>
          )}{' '}
          {rest.data.cliente?.razon_social}
        </b>{' '}
        <br />
        <span title='RFC' className='label label-primary'>
          {rest.data.cliente?.rfc}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.cliente?.nombre_comercial}</span> <br />
        DOMICILIO:{' '}
        <span title='MONITOREO' className='label label-primary'>
          {rest.data.domicilioId?.monitoreo}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.cliente?.domicilio_entrega?.nombre}</span>
      </div>
    </components.Control>
  );
};

OrdenServicioOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const CustomerRegistroOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ padding: '5px', borderRadius: '0' }}>
        CRM:{' '}
        <b>
          {rest.data.folio && (
            <span title='Descripción' className='label label-success'>
              {rest.data.folio}
            </span>
          )}{' '}
          {rest.data.descripcion}
        </b>{' '}
        <br />
        CLIENTE:
        <b>
          {rest.data.customerId?.numero_cliente && (
            <span title='Numero de cliente' className='label label-success'>
              {rest.data.customerId?.numero_cliente}
            </span>
          )}{' '}
          {rest.data.customerId?.razon_social}
        </b>{' '}
        <br />
        <span title='RFC' className='label label-primary'>
          {rest.data.customerId?.rfc}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.customerId?.nombre_comercial}</span> <br />
        DOMICILIO:{' '}
        <span title='MONITOREO' className='label label-primary'>
          {rest.data.domicilioId?.monitoreo}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.domicilioId?.nombre}</span>
      </div>
    </components.Control>
  );
};

CustomerRegistroOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const DomicilioOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ padding: '5px', borderRadius: '0' }}>
        <span title='MONITOREO' className='label label-primary'>
          {rest.data.monitoreo}
        </span>{' '}
        <span title='Nombre comercial'>{rest.data.nombre}</span> <br />
        <span title='Calle'>{rest.data.calle}</span>
        {rest.data.exterior && <span title='Numero exterior'> {rest.data.exterior}</span>}
        {rest.data.interior && <span title='Numero interior'>-{rest.data.interior}</span>}, &nbsp;
        <span title='Colonia'>{rest.data.colonia}</span>, <span title='Código postal'>{rest.data.codigo_postal}</span>
        <br />
        <span title='Localidad'>{rest.data.municipio || rest.data.localidad}</span>, <span title='Estado'>{rest.data.estado}</span>,{' '}
        <span title='País'>{rest.data.pais}.</span>
      </div>
    </components.Control>
  );
};

DomicilioOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const ProductOption = ({ ...rest }) => {
  return (
    <components.Control {...rest}>
      <div style={{ borderRadius: '0', fontSize: '12px' }}>
        <b>
          {rest.data.tipo_partida && (
            <span title='Tipo partida' className='label label-warning'>
              {rest.data.tipo_partida}
            </span>
          )}{' '}
          {rest.data.code}
        </b>{' '}
        {rest.data.name}
      </div>
    </components.Control>
  );
};

ProductOption.propTypes = {
  innerRef: PropTypes.func,
  getValue: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

export const Loading = ({ loading = true, children }) => {
  if (!loading) return children;
  return (
    <div className='text-center'>
      <br />
      <br />
      <br />
      <br />
      <div className='loader'></div> <br />
      <h3 className=''>Cargando...</h3>
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export const Module = ({ loading = false, children }) => {
  return (
    <>
      <div className='content-header'></div>
      <div className='content'>
        <div className='container-fluid'>
          <Loading loading={loading}>{children}</Loading>
        </div>
      </div>
    </>
  );
};

Module.propTypes = {
  icon: PropTypes.string,
  parent: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClickBack: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export const SubModule = (props) => {
  const icon = typeof props.icon !== 'undefined' ? props.icon : '';
  const space = icon !== '' ? '' : '';
  const onClickBack = typeof props.onClickBack !== 'undefined' ? props.onClickBack : '';
  return (
    <div>
      <h1 className='page-header'>
        {onClickBack ? (
          <button className='btn btn-link' title='Atrás' onClick={onClickBack}>
            <Icon icon='glyphicon glyphicon-chevron-left' style={{ fontSize: '20px', fontWeight: '100', color: '#3333335c' }} />
          </button>
        ) : null}
        <Icon icon={icon} />
        {space}
        <small>{props.title}</small>
      </h1>
      {props.children}
    </div>
  );
};

SubModule.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClickBack: PropTypes.func,
};

export const Icon = (props) => {
  return <span className={`glyphicon glyphicon-${props.icon}`} style={{ ...props.style }} {...props} aria-hidden='true'></span>;
};

Icon.propTypes = {
  style: PropTypes.object,
  icon: PropTypes.string.isRequired,
};

export const DataNotFount = ({ style, message = 'No se encontraron resultados.'}) => {
  return (
    <div className='alert alert-info text-center' style={style} role='alert'>
      {message}
    </div>
  );
};

DataNotFount.propTypes = {
  style: PropTypes.object,
  message: PropTypes.string,
};


export const HR = () => {
  return <hr style={{ marginTop: '4px', marginBottom: '5px' }} />;
};

export const Space = (props) => {
  return <span className={props.className}>&nbsp;</span>;
};

Space.propTypes = {
  className: PropTypes.string,
};

export const Saving = ({ action, saving }) => {
  const getStoreSavingLoading = useStoreSavingLoading();
  if (saving || getStoreSavingLoading.saving) {
    return (
      <span className='lead' style={{ marginRight: '10px' }}>
        {action || 'Guardando'} favor de esperar...
      </span>
    );
  }
  return null;
};

Saving.propTypes = {
  action: PropTypes.string,
  saving: PropTypes.bool,
};

export const TitlePanelDiets = (props) => {
  const styles = {
    marginBottom: '5px',
    marginTop: '5px',
  };

  return (
    <h3 style={styles} className='panel-title'>
      {props.title}
    </h3>
  );
};

TitlePanelDiets.propTypes = {
  title: PropTypes.string,
};

export const RowsNotFound = ({ colSpan, children, message = 'Aún no hay registros.'}) => {
  if (children && children.length > 0) return children;
  return (
    <tr>
      <td colSpan={colSpan} className='text-center'>
        {message}
      </td>
    </tr>
  );
};

RowsNotFound.propTypes = {
  colSpan: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
};
