import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import Nuevo from '../Nuevo';
import List from './List';

const Prospectos = () => {
  const [search, setSearch] = useState('');
  const [customers, setCustomers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pagination] = useState(50);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleSearch();
  }, [activePage, search]);

  const onSearch = (params = {}) => {
    setSearch(params.search);
  };

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search: search,
    };
    const { data, count } = await postRequest({ url: `prospectos/search`, params: { page, limit: pagination }, body });
    setCustomers(data);
    setCount(count);
    setLoading(false);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  useEffect(() => {
    handlePagination(1);
  }, []);

  return (
    <Module title='Prospectos'>
      <Search onSearch={onSearch} searchOnStopType blockOne='col-sm-10' blockSecond='col-sm-2'>
        <Nuevo loadData={handleSearch} />
      </Search>
      <Loading loading={loading}>
        <List list={customers} loadList={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

Prospectos.propTypes = {};

export default Prospectos;
