import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import ListRubros from './ListRubros';
import NewRubros from './NewRubros';
import { useParams } from 'react-router-dom';

const Rubros = () => {
  const params = useParams();
  const [establecimiento] = useState('');
  const [catalogRubros, setCatalogRubros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  useEffect(() => {
    handlePagination(1);
  }, []);

  const handleSearch = async (params = {}) => {
    const { establecimientoId } = params;
    const page = activePage * pagination - pagination;
    const body = {
      search: params.search,
    };
    const { data, count } = await postRequest({ url: `rubros/${establecimientoId}/search`, params: { page, limit: pagination }, body });
    setCatalogRubros(data);
    setCount(count);
    setSearch(params.search);
    setLoading(false);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    const page = eventKey * pagination - pagination;
    handleSearch({ page, pagination, search });
  };

  const { establecimientoId } = params;

  return (
    <Module onClickBack={window.history.back} title={`ESTABLECIMIENTO: ${establecimiento} -  Rubros`}>
      <Search onSearch={handleSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <IfPermission action='crear-rubros'>
          <NewRubros establecimientoId={establecimientoId} loadRubros={handleSearch} />
        </IfPermission>
      </Search>
      <Loading loading={loading}>
        <ListRubros establecimientoId={establecimientoId} catalogRubros={catalogRubros} loadRubros={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} />
        </div>
      </Loading>
    </Module>
  );
};

Rubros.propTypes = {};

export default Rubros;
