import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import DivFormGroup from '@controls/DivFormGroup';
import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import NewContacto from './NewContacto';
import { Col, Row } from 'react-bootstrap';

const Domicilio = () => {
  const [list, setList] = useState([]);
  const [paises, setPaises] = useState([]);
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(50);
  const [search, setSearch] = useState('');
  const [paisId, setPaisId] = useState(null);
  const [estadoId, setEstadoId] = useState(null);
  const [municipio, setMunicipio] = useState(null);

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      paisId: paisId ? paisId._id : null,
      estadoId: estadoId ? estadoId.value : null,
      municipio: municipio ? municipio.label : null,
    };
    const { data, count } = await postRequest({ url: 'domicilios/search', params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search, paisId, estadoId, municipio]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  const loadCollections = useCallback(async () => {
    const { paises, estados, municipios } = await getRequest({ url: `domicilios/catalogos` });

    setPaises([
      {
        value: '0',
        label: 'Todos los paises',
      },
      ...paises.map((pais) => ({
        ...pais,
        value: pais._id,
        label: pais.pais,
      })),
    ]);
    setEstados([
      {
        value: '0',
        label: 'Todos los estados',
      },
      ...estados.map((estado) => ({
        ...estado,
        value: estado.estadoId,
        label: estado.estado,
      })),
    ]);
    setMunicipios([
      {
        value: '0',
        label: 'Todos los municipios',
      },
      ...municipios.map((municipio) => ({
        ...municipio,
        value: municipio.ombre,
        label: municipio.nombre,
      })),
    ]);
  }, []);

  useEffect(() => {
    loadCollections();
  }, [loadCollections]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const renderList = () => (
    <div>
      <List list={list} refresh={handleSearch} />
    </div>
  );

  const moduleList = !loading ? renderList() : <Loading />;

  return (
    <Module title='Domicilio'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <Row>
          <Col sm='4'>
            <DivFormGroup>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='home' title='paises' />
                </span>
                <Select
                  value={paisId}
                  name='paisId'
                  placeholder='Selecciona...'
                  options={paises}
                  onChange={(e) => setPaisId(e)}
                  className='basic-multi-select'
                  classNamePrefix='select'
                />
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='home' title='estados' />
                </span>
                <Select
                  value={estadoId}
                  name='estadoId'
                  placeholder='Selecciona...'
                  options={estados.filter((x) => paisId && x.paisId === paisId._id)}
                  onChange={(e) => setEstadoId(e)}
                  className='basic-multi-select'
                  classNamePrefix='select'
                />
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='home' title='municipios' />
                </span>
                <Select
                  value={municipio}
                  onChange={(e) => setMunicipio(e)}
                  name='municipio'
                  placeholder='Selecciona...'
                  options={municipios.filter((x) => estadoId && x.estadoId && x.estadoId._id === estadoId._id)}
                  className='basic-multi-select'
                  classNamePrefix='select'
                />
              </div>
            </DivFormGroup>
          </Col>
        </Row>
        <IfPermission action='crear'>
          <NewContacto refresh={handleSearch} />
        </IfPermission>
      </Search>
      <Row>
        <Col sm='12'>
          {moduleList}
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} handlePagination={handlePagination} />
          </div>
        </Col>
      </Row>
    </Module>
  );
};

Domicilio.propTypes = {};

export default Domicilio;
