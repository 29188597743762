import React from 'react';
import PropTypes from 'prop-types';

const RowItem = ({ label, children, lead = true }) => {
  const classNameLead = lead ? 'lead' : '';

  return (
    <h5>
      <strong className={classNameLead}>{label}: </strong>&nbsp;<span className={classNameLead}>{children} </span>
    </h5>
  );
};

RowItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  lead: PropTypes.bool,
};


export default RowItem;
