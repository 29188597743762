import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import EnviarReferencias from './EnviarReferencias';
import Table from './Table';
import { Col, Row } from 'react-bootstrap';
import Select from '@components/forms/Select';

const FacturasFaltantes = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(30);
  const [search, setSearch] = useState('');
  const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));
  const [orden, setOrden] = useState('fecha_original');
  const [tipo, setTipo] = useState(-1);
  const [estatus_facturas] = useState({
    PAGADA: 'PAGADA',
    PENDIENTE: 'PENDIENTE',
    CANCELADA: 'CANCELADA',
  });
  const [estatus] = useState([
    {
      value: '0',
      label: 'Todos los estatus',
    },
    ...Object.entries(estatus_facturas).map(([key, value]) => ({
      value: key,
      label: value,
    })),
  ]);
  const [estatus_factura, setEstatusFactura] = useState('0');
  const frmFacturas = useRef(null);
  const modalEnviar = useRef(null);

  useEffect(() => {
    handleSearch();
  }, [activePage]);

  const handleSearch = useCallback(() => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
      orden,
      tipo,
      estatus: estatus_factura,
    };
    postRequest({ url: `facturas/search`, params: { page, limit: pagination }, body }).then(({ data, count }) => {
      setList(data);
      setCount(count);
      setLoading(false);
    });
  }, [activePage, pagination, search, fechaFin, fechaInicio, orden, tipo, estatus_factura]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search || search);
    setActivePage(1);
    setTipo(params.tipo || tipo);
    setOrden(params.orden || orden);
    setFechaInicio(params.fechaInicio || fechaInicio);
    setFechaFin(params.fechaFin || fechaFin);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  const onSelect = (name, value) => {
    if (name === 'estatus_factura') {
      setEstatusFactura(value);
    }
    handlePagination(1);
  };

  const obtenerFacturasSeleccionadas = () => {
    const form = new FormData(frmFacturas.current);
    const facturas = [];
    form.forEach((value, key) => {
      if (key === 'facturas[]') {
        facturas.push(value);
      }
    });
    return list.filter((x) => facturas.includes(x._id));
  };

  return (
    <Module title='Facturas'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col sm='6'>
            <div className='input-group'>
              <span className='input-group-text'>
                <Icon icon='tags' title='Estatus' />
              </span>
              <Select value={estatus_factura} options={estatus} onChange={(value) => onSelect('estatus_factura', value)} />
            </div>
          </Col>
          <Col sm='6'>
            <EnviarReferencias obtenerFacturasSeleccionadas={obtenerFacturasSeleccionadas} list={list} loadData={handleSearch} ref={modalEnviar} />
            <Link to='/facturas/importar' className='btn btn-success pull-right mr-5'>
              <Icon icon='import' /> <span className='hidden-xs'>Importar</span>
            </Link>
            <Link to='/facturas/importar-archivo' className='btn btn-primary pull-right mr-5'>
              <Icon icon='import' /> <span className='hidden-xs'>Importar Archivo</span>
            </Link>
            <Link style={{ display: 'none' }} to='/facturas/facturacion' className='btn btn-success pull-right mr-5'>
              <Icon icon='plus' /> <span className='hidden-xs'>Facturar</span>
            </Link>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <form ref={frmFacturas}>
          <Table
            {...{ list, loading, estatus_factura, estatus, pagination, count, activePage }}
            loadData={onSearch}
            cobrarFactura={() => modalEnviar.current.open()}
          />
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </form>
      </Loading>
    </Module>
  );
};

FacturasFaltantes.propTypes = {};

export default FacturasFaltantes;
