import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ list, load }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, estado, estadoId, paisId } = row;
      return (
        <tr key={item}>
          <td>
            {paisId?.codigo} - {paisId?.pais}
          </td>
          <td>{estadoId}</td>
          <td>{estado}</td>
          <td>
            <IfPermission action='eliminar'>
              <Delete id={_id} estado={estado} estadoId={estadoId} {...{ list, load }} btnClass='btn btn-sm btn-danger pull-right' />
            </IfPermission>
            <IfPermission action='editar'>
              <Edit id={_id} estado={estado} estadoId={estadoId} {...{ list, load }} btnClass='btn btn-outline-info' />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='40%'>País</th>
          <th width='40%'>Código</th>
          <th width='40%'>Nombre</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default List;
