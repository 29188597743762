import React from 'react';
import List from './List';

const Notificaciones = (props) => {
  return <List {...props} />;
};

Notificaciones.propTypes = {};

export default Notificaciones;
