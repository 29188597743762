import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest } from '@utils/api';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const ModalWorkforces = ({ equipmentId, equipmentName, equipmentCode, listWorkForcesSetted, showModal, onClose, onSelectServicio }) => {
  const { addNotification: notification } = useStoreNotification();
  const [listWorkForces, setListWorkForces] = useState([]);
  const [service, setService] = useState(null);
  const [desglosar, setDesglosar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData(equipmentId, listWorkForcesSetted);
  }, [equipmentId, listWorkForcesSetted]);

  const loadData = async (equipmentId, listWorkForcesProps = []) => {
    setIsLoading(true);
    const { listWorkforce, service } = await getRequest({ url: `equipments/workforces/${equipmentId}` });
    setListWorkForces(listWorkForcesProps.length > 0 ? listWorkForcesProps : listWorkforce.map((x) => ({ ...x, cantidad: 0 })));
    setService(service);
    setIsLoading(false);
  };

  const onAceptar = () => {
    const cantidad = listWorkForces.map((x) => x.cantidad).reduce((a, b) => a + b, 0);
    if (cantidad <= 0) return notification({ type: 'error', message: 'Es necesario agregar por lo menos un equipo', title: 'Advertencia' });
    onSelectServicio({
      servicio: service,
      label: 'HORA',
      listWorkForces,
      relacionadoId: equipmentId,
    });
  };

  const close = () => {
    onClose();
  };

  const onClickDecrementar = (index, e) => {
    e.preventDefault();
    setListWorkForces(
      listWorkForces.map((x, i) => {
        const cantidad = x.cantidad - 1;
        if (i === index) {
          return {
            ...x,
            cantidad: cantidad < 0 ? 0 : cantidad,
          };
        }
        return x;
      })
    );
  };

  const onClickIncrementar = (index, e) => {
    e.preventDefault();
    setListWorkForces(
      listWorkForces.map((x, i) => {
        const cantidad = x.cantidad + 1;
        if (i === index) {
          return {
            ...x,
            cantidad: cantidad < 0 ? 0 : cantidad,
          };
        }
        return x;
      })
    );
  };

  const onChangeInput = (index, e) => {
    e.preventDefault();
    setListWorkForces(
      listWorkForces.map((x, i) => {
        const cantidad = Number(e.target.value);
        if (i === index) {
          return {
            ...x,
            cantidad: cantidad < 0 ? 0 : cantidad,
          };
        }
        return x;
      })
    );
  };

  const rowsWorkforces = listWorkForces
    .filter((x, i) => (desglosar ? true : i < 1))
    .map((x, i) => (
      <tr key={i} style={{ cursor: 'pointer' }}>
        <td>
          <InputGroup>
            <Button onClick={(e) => onClickDecrementar(i, e)}>
              <Icon icon='minus' />
            </Button>
            <Form.Control
              className='text-center'
              type='text'
              placeholder='Cantidad'
              aria-label='Cantidad'
              value={x.cantidad}
              onChange={(e) => onChangeInput(i, e)}
            />
            <Button onClick={(e) => onClickIncrementar(i, e)}>
              <Icon icon='plus' />
            </Button>
          </InputGroup>
        </td>
        {desglosar && <td>Nivel {x.level}</td>}
        <td>{x.value}hr</td>
        <td className='text-right'>{Math.ceil(x.value * x.cantidad)}hr</td>
      </tr>
    ));

  return (
    <CbModal size='lg' show={showModal} onClose={close} onSave={onAceptar} title='Horas de servicios'>
      <Row>
        <Col>
          <p>
            Servicios para el equipo: {equipmentCode} <strong>{equipmentName}</strong>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <CbPanelTableResponsive>
            <thead>
              <tr>
                <th width='20%'>Equipos</th>
                {desglosar && <th width='40%'>Nivel de servicio</th>}
                <th width='20%'>Horas</th>
                <th className='text-right' width='20%'>
                  Horas totales
                </th>
              </tr>
            </thead>
            <CbTableBody colSpan={4} loading={isLoading}>
              {rowsWorkforces}
              {desglosar && (
                <tr>
                  <th className='text-right'>{listWorkForces.map((x) => x.cantidad).reduce((a, b) => a + b, 0)} equipos</th>
                  <td></td>
                  <th className='text-right'>TOTALES</th>
                  <th className='text-right'>{listWorkForces.map((x) => Math.ceil(x.value * x.cantidad)).reduce((a, b) => a + b, 0)}hr</th>
                </tr>
              )}
              <tr>
                <th style={{ cursor: 'pointer' }} colSpan={desglosar ? 4 : 4} className='text-center' onClick={() => setDesglosar(!desglosar)}>
                  Mostrar {desglosar ? 'menos' : 'más'}
                </th>
              </tr>
            </CbTableBody>
          </CbPanelTableResponsive>
        </Col>
      </Row>
    </CbModal>
  );
};

ModalWorkforces.propTypes = {
  equipmentId: PropTypes.string,
  equipmentCode: PropTypes.string,
  equipmentName: PropTypes.string,
  listWorkForces: PropTypes.array,
  showModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectServicio: PropTypes.func,
  listWorkForcesSetted: PropTypes.array,
};

export default ModalWorkforces;
