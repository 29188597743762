import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectCuentaBancaria from '../../../components/forms/SelectCuentaBancaria';
import moment from 'moment';


const New = ({ loadData }) => {
     const { addNotification: notification } = useStoreNotification();
     const [cuenta_bancaria_id, setCuentaBancaria] = useState('');
     const [referencia, setReferencia] = useState('');
     const [fecha, setFecha] = useState('');
     const [concepto, setConcepto] = useState('');
     const [cargo, setCargo] = useState('');
     const [abono, setAbono] = useState('');
     const [showModal, setShowModal] = useState(false);
     const [isLoading, setIsLoading] = useState(false);

    const close = () => {
        setShowModal(false);
        setCuentaBancaria('');
    };

    const open = () =>{
        setShowModal(true);
        setIsLoading(false);
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        if( name === 'cuenta_bancaria_id'){
            setCuentaBancaria(value);
        }
        if( name === 'referencia'){
            setReferencia(value);
        }
        if( name === 'fecha'){
            setFecha(value);
        }
        if( name === 'concepto'){
            setConcepto(value);
        }
        if( name === 'cargo'){
            setCargo(value);
        }
        if( name === 'abono'){
            setAbono(value);
        }
    };
    const onChangeCuentaBancaria = (itemSelect) =>{
      if (itemSelect) {
        setCuentaBancaria(itemSelect);   
      } else {
        setCuentaBancaria(null);
      }
    }
    const handleRegister = async (event) =>{
        event.preventDefault();
        const data = {cuenta_bancaria_id, referencia, fecha: moment(fecha,'YYYY-MM-DD').utc(), concepto,cargo, abono};

        if(validations(data)){
            await postRequest({ url: '/customers/estados-cuentas', body: data});
            loadData();
            close();
        }
    };

    const validations = (params) => {
        let validate = {
            success: true,
            message:'',
        };
        if(isEmpty(params.cuenta_bancaria_id + '')){
            validate.success = false;
            validate.message ='Cuenta Bancaria es requerido.';
        }else  if(isEmpty(params.fecha + '')){
            validate.success = false;
            validate.message ='Fecha es requerido.';
        }else  if(isEmpty(params.concepto + '')){
            validate.success = false;
            validate.message ='Concepto es requerido.'
        }
        if (!validate.success) {
            notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
        }
        return validate.success;
    };
    return (
        <>
          <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
          <CbModal show={showModal} title='Agregar' onClose={close} onSave={handleRegister} isLoading={isLoading}>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='cuenta_bancaria_id' title='Cuenta Bancaria' required>
                <SelectCuentaBancaria name='cuenta_bancaria_id' title='Cuenta Bancaria' required onChange={onChangeCuentaBancaria} value={cuenta_bancaria_id}/>
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='referencia' title='Referencia' required>
                  <input type='text' id='referencia' name='referencia' className='form-control' onChange={onChange} value={referencia} placeholder='Referencia' />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='fecha' title='Fecha' required>
                  <input type='date' id='fecha' name='fecha' className='form-control' onChange={onChange} value={fecha} placeholder='Fecha' />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='concepto' title='Concepto' required>
                  <input type='text'id='concepto' name='concepto' className='form-control' onChange={onChange} value={concepto} placeholder='Concepto' />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='cargo' title='Cargo' required>
                  <input type='text'id='cargo' name='cargo' className='form-control' onChange={onChange} value={cargo} placeholder='Cargo' />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='abono' title='Abono' required>
                  <input type='text'id='abono' name='abono' className='form-control' onChange={onChange} value={abono} placeholder='Abono' />
                </DivFormGroup>
              </Col>
            </Row>
          </CbModal>
        </>
      );
} 
New.propTypes = {
    cuenta_bancaria_id: PropTypes.string.isRequired,
    referencia: PropTypes.string.isRequired,
    fecha: PropTypes.string.isRequired,
    concepto:PropTypes.string.isRequired,
    cargo: PropTypes.number.isRequired,
    abono: PropTypes.number.isRequired,
    loadData: PropTypes.func.isRequired

};
export default New;