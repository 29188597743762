import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AdjuntImage } from '@controls';

const ImagenEquipo = ({ urlImage, onRemove, onUpdate }) => {
  const [showIconRemove, setShowIconRemove] = useState(false);

  const removeImage = (event) => {
    event.preventDefault();
    setShowIconRemove(false);
    onRemove();
  };

  const handleMouseOver = () => {
    setShowIconRemove(true);
  };

  const handleMouseLeave = () => {
    setShowIconRemove(false);
  };

  const loadFile = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      onUpdate(file, reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className='panel panel-default'>
      <div className='panel-body'>
        <Col sm='3'>
          <Row>
            <Col sm='12'>
              <AdjuntImage loadFile={loadFile} />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <a
                href='#'
                id='remove_img'
                style={{ display: showIconRemove ? 'block' : 'none', color: 'red', position: 'fixed' }}
                className='pull-right'
                onClick={removeImage}
                onMouseOver={handleMouseOver}
              >
                <span className='glyphicon glyphicon-remove'></span>
              </a>
              {urlImage && (
                <img id='input_img' src={urlImage} width='200px' height='200px' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} />
              )}
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  );
};

ImagenEquipo.propTypes = {
  urlImage: PropTypes.string,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default ImagenEquipo;
