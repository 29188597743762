import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import Table from './Table';
import SelectTipoPedido from '@components/forms/SelectTipoPedido';
import SelectAgente from '@components/forms/SelectAgente';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import Select from 'react-select';

const List = () => {
  const [state, setState] = useState({
    list: [],
    loading: true,
    checkEnviarCopia: true,
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
    fechaInicio: moment().format('YYYY-MM-01'),
    fechaFin: moment().format('YYYY-MM-DD'),
    storehouses: [],
    clientesAEnviar: [],
    showModal: false,
    enviados: false,
    orden: 'numero_levantamiento',
    tipos_pedidos: [],
    sucursalId: '0',
    agenteId: '0',
    agentes: [],
    estatus_pedidos: {
      INICIADO: 'INICIADO',
      EN_PROCESO: 'EN PROCESO',
      ENTREGADO: 'ENTREGADO',
      FINALIZADO: 'FINALIZADO',
      CANCELADO: 'CANCELADO',
    },
    estatus: [],
    estatus_pedido: '0',
    tipoPedidoId: '0',
    sucursales: [],
    listSucursales: [],
    listAlmacenes: [],
  });

  const handleSearch = useCallback(async () => {
    const { pagination, activePage, search, fechaFin, fechaInicio, sucursalId, almacenId, agenteId, estatus_pedido, tipoPedidoId } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
      sucursalId,
      almacenId,
      tipoPedidoId,
      estatus: estatus_pedido,
      agenteId,
    };
    const { data, count } = await postRequest({ url: `compras/search`, params: { page, limit: pagination }, body });
    setState((prevState) => ({
      ...prevState,
      list: data,
      count: count,
      loading: false,
    }));
  }, [state]);

  const loadCatalogos = useCallback(async (callback) => {
    const {
      data: { sucursales, agente, agentes, almacenes, tipos_pedidos },
    } = await getRequest({ url: `levantamientos-catalogos` });
    setState((prevState) => ({
      ...prevState,
      tipos_pedidos: [
        {
          value: '0',
          label: 'Todos los tipos de pedidos',
        },
        ...tipos_pedidos.map((tipo_pedido) => ({
          value: tipo_pedido._id,
          label: `${tipo_pedido.nombre}`,
        })),
      ],
      listSucursales: sucursales,
      listAlmacenes: almacenes,
      sucursales: [
        {
          value: '0',
          label: 'Todas las sucursales',
        },
        ...sucursales.map((sucursal) => ({
          value: sucursal._id,
          label: `${sucursal.name}`,
        })),
      ],
      almacenes: [
        {
          value: '0',
          label: 'Todos los almacenes',
        },
        ...almacenes.map((almacen) => ({
          value: almacen._id,
          label: `${almacen.name}`,
        })),
      ],
      agentes: [
        {
          value: '0',
          label: 'Todos los agentes',
        },
        ...agentes.map((agente) => ({
          value: agente._id,
          label: `${agente.nombre}`,
        })),
      ],
      estatus: [
        {
          value: '0',
          label: 'Todos los estatus',
        },
        ...Object.entries(prevState.estatus_pedidos).map(([key, value]) => ({
          value: key,
          label: value,
        })),
      ],
      agente: agente,
      agenteId: agente ? agente._id : '0',
      sucursalId: agente && agente.sucursalId ? agente.sucursalId._id : '0',
      almacenId: agente && agente.almacenId ? agente.almacenId._id : '0',
    }));
    callback();
  }, []);

  useEffect(() => {
    loadCatalogos(handleSearch);
  }, [loadCatalogos, handleSearch]);

  const onSearch = (params = { search: '' }) => {
    setState((prevState) => ({
      ...prevState,
      search: params.search,
      activePage: 1,
      fechaInicio: params.fechaInicio,
      fechaFin: params.fechaFin,
    }));
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }));
    handleSearch();
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
    handlePagination(1);
  };

  const { pagination, count, activePage, loading, sucursalId, agenteId, estatus, estatus_pedido, almacenId, tipoPedidoId } = state;

  return (
    <Module title='Pedidos'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <div className='row'>
          <div className='col-sm-2'>
            <div className='input-group'>
              <span className='input-group-text'>
                <Icon icon='tags' title='Tipos de pedidos' />
              </span>
              <SelectTipoPedido name='tipoPedidoId' value={tipoPedidoId} onChange={(value) => onSelect('tipoPedidoId', value)} />
            </div>
          </div>
          <div className='col-sm-2'>
            <IfPermission action='TodasSucursales'>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='home' title='sucursales' />
                </span>
                <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
              </div>
            </IfPermission>
          </div>
          <div className='col-sm-2'>
            <IfPermission action='TodosAlmacenes'>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='equalizer' title='almacenes' />
                </span>
                <SelectAlmacen value={almacenId} onChange={(value) => onSelect('almacenId', value)} />
              </div>
            </IfPermission>
          </div>
          <div className='col-sm-2'>
            <IfPermission action='TodosAgentes'>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='user' title='Agentes' />
                </span>
                <SelectAgente value={agenteId} onChange={(value) => onSelect('agenteId', value)} />
              </div>
            </IfPermission>
          </div>
          <div className='col-sm-2'>
            <div className='input-group'>
              <span className='input-group-text'>
                <Icon icon='tags' title='Estatus' />
              </span>
              <Select value={estatus_pedido} options={estatus} onChange={(value) => onSelect('estatus_pedido', value)} />
            </div>
          </div>
          <div className='col-sm-2'>
            <IfPermission action='crear'>
              <Link to='/pedidos/nuevo' className='btn btn-success pull-right'>
                <Icon icon='plus' /> Nuevo
              </Link>
            </IfPermission>
          </div>
        </div>
      </Search>
      <Loading loading={loading}>
        <Table {...state} loadData={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

List.propTypes = {};

export default List;
