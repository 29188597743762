import { MONEDA } from '@config/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Icon, Module, Saving } from '@stateless';
import { FormInput } from '@controls';
import { getRequest, putRequest } from '@utils/api';
import { castServicio, getTipoCotizacion, measuresServicios, HORA, recalcularPrecioDetalle, recalcularPrecioServicio } from '@utils/cotizaciones';
import { CbTableResponsive } from '@controls';
import CbTableBody from '@cbcomponents/CbTableBody';
import BuscadorDetalles from './New/BuscadorDetalles';
import FormEncabezado from './New/FormEncabezado';
import ModalImprimir from './New/ModalImprimir';
import RowDetalle from './New/RowDetalle';
import Totales from './New/Totales';
import getState from '@utils/state';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import TableHeaders from './New/components/TableHeaders';
import {
  useStoreSucursales,
  useStoreTiposRecibos,
  useStoreMonedas,
  useStoreMedidas,
  useStoreUsosCfdis,
  useStoreFormasPagos,
  useStoreMetodosPagos,
} from '@stores/catalogs.store';
import { TIPOS_PARTIDAS, ESTATUS_COTIZACIONES, validationsRecibo, mapDataDetallesRecibo } from '@utils/cotizaciones';
import useAuth from '@hooks/useAuth';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = (props) => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const { reciboId } = useParams();
  const { agente } = useAuth();
  const [idCotizacion, setIdCotizacion] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);

  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [estatusRecibos] = useState([...ESTATUS_COTIZACIONES]);

  const [state, setState] = useState({
    fecha: moment().format('YYYY-MM-DD'),
    moneda: {
      enumerador: MONEDA.PESO,
      descripcion: 'PESO',
    },
    agente: null,
    detalles: [],
    observaciones: '',
    estatus: { value: 'INICIADA', label: 'INICIADA' },
    sucursal: null,
    cliente: null,
    domicilios: [],
    domicilioId: null,
    anticipo: 0,
    tipoReciboId: null,
    tipoCambio: props.tipoCambio || 0,
  });

  const getStoreSucursales = useStoreSucursales();
  const getStoreTiposRecibos = useStoreTiposRecibos();
  const getStoreMonedas = useStoreMonedas();
  const getStoreMedidas = useStoreMedidas();
  const getStoreUsosCfdis = useStoreUsosCfdis();
  const getStoreFormasPagos = useStoreFormasPagos();
  const getStoreMetodosPagos = useStoreMetodosPagos();

  useEffect(() => {
    getStoreSucursales.execute();
    getStoreTiposRecibos.execute();
    getStoreMonedas.execute();
    getStoreMedidas.execute();
    getStoreFormasPagos.execute();
    getStoreUsosCfdis.execute();
    getStoreMetodosPagos.execute();

    const fetchData = async () => {
      setIsLoadingForm(true);
      if (!reciboId) {
        notification({
          title: 'Información incompleta',
          message: 'Es necesario tener el id del recibo',
          type: 'error',
        });
        setTimeout(() => {
          navigate('/recibos');
        }, 1000);
      }
      if (!agente) {
        notification({
          title: 'Información incompleta',
          message: 'Es necesario tener configurado el usuario como agente',
          type: 'error',
        });
        setTimeout(() => {
          navigate('/');
        }, 1000);
        return;
      }
      const { data: recibo } = await getRequest({ url: `recibos/${reciboId}` });
      const customer = recibo.customer._id;
      const domicilios = customer?.listDomicilios?.length > 0 ? customer.listDomicilios : [{ ...recibo.customer.domicilio }];
      setState((prevState) => ({
        ...prevState,
        numeroOrden: recibo.numero_recibo,
        fecha: moment(recibo.fecha).local().format('YYYY-MM-DD'),
        estatus: estatusRecibos.find((x) => x.value === recibo.estado),
        sucursal: recibo.sucursal,
        almacen: recibo.almacen,
        moneda: getStoreMonedas.data.find((x) => x.enumerador === recibo.moneda),
        tipoRecibo: recibo.tipoReciboId,
        tipoCambio: recibo.tipo_cambio,
        cliente: {
          ...customer,
        },
        agente: {
          ...recibo.agente,
        },
        formaPago: recibo.forma_pago,
        metodoPago: recibo.metodo_pago,
        usoCfdi: recibo.uso_cfdi,
        domicilioId: recibo.customer.domicilio,
        domicilios,
        observaciones: recibo.observaciones,
        detalles: recibo.detalles.map((x) => {
          return {
            ...x,
            tipo_partida: TIPOS_PARTIDAS.find((y) => y.value === x.tipo_partida),
            relacionado: x.relacionadoId,
            relacionadoId: x.relacionadoId._id,
            measureId: {
              _id: x.unidadMedida._id,
              measure: x.unidadMedida.nombre,
            },
            moneda: recibo.moneda,
            moneda_original: recibo.moneda,
            noIdentificador: x.codigo,
            precio: x.precio,
            precio_original: x.precio,
            iva: x.iva,
            imagen: x.relacionadoId?.imagen,
            status: x.relacionadoId.status,
            tiene_sustitutos: x.relacionadoId?.listAlternos?.length > 0,
            tiene_otro_proveedor: x.relacionadoId?.listCostosProveedores?.length > 1,
          };
        }),
      }));
      setIsLoadingForm(false);
    };
    fetchData();
  }, []);

  const precios = state.detalles.map((detalle) => detalle?.precio).join('-');
  const cantidades = state.detalles.map((detalle) => detalle?.cantidad).join('-');

  useEffect(() => {
    recalcularTipoCambio();
  }, [state.moneda, state.tipoCambio]);

  useEffect(() => {
    recalcularTipoCambio();
  }, [precios, cantidades]);

  useEffect(() => {
    recalcularServicios();
  }, [state.sucursal]);

  useEffect(() => {
    if (!isLoadingForm) actualizarNumeroLevantamiento();
  }, [state.tipoReciboId]);

  const actualizarNumeroLevantamiento = async () => {
    setIsLoadingForm(true);
    const { data } = await getRequest({
      url: `recibos/siguienteNumero`,
      params: { tipoReciboId: getTipoCotizacion(state.tipoReciboId) },
    });
    setState((prevState) => ({
      ...prevState,
      numeroOrden: data.numero_recibo,
    }));
    setIsLoadingForm(false);
  };

  const onChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      ...getState(event.target),
    }));
  };

  const subirExcel = () => {
    setState((prevState) => ({
      ...prevState,
      showModalSubirExcel: true,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      numeroOrden,
      fecha,
      estatus,
      sucursal,
      moneda,
      tipoCambio,
      domicilioId,
      cliente,
      observaciones,
      detalles,
      agente,
      tipoRecibo,
      metodoPago,
      formaPago,
      usoCfdi,
    } = state;
    console.log('state', state);
    const data = {
      numero_recibo: numeroOrden,
      tipoReciboId: tipoRecibo?._id,
      fecha,
      estatus_recibo: estatus?.value,
      sucursalId: sucursal?._id,
      monedaId: moneda?.enumerador,
      metodoPagoId: metodoPago?._id,
      formaPagoId: formaPago?._id,
      usoCfdiId: usoCfdi?._id,
      tipoCambio,
      observaciones,
      clienteId: cliente?._id,
      agenteId: agente?._id,
      domicilioId: domicilioId?._id,
      detalles: mapDataDetallesRecibo(detalles),
    };
    const { success, message } = validationsRecibo(data);
    if (!success) {
      return notification({
        title: 'Validaciones',
        message,
        type: 'error',
      });
    }
    setIsLoadingForm(true);
    setIsLoading(true);
    putRequest({ url: `recibos/${reciboId}`, body: data }).then(({ data: cotizacion }) => {
      setIdCotizacion(cotizacion._id);
      setShowModalDownload(true);
      setIsLoadingForm(false);
      setIsLoading(false);
    });
  };

  const onChangeDetalle = (index, detalle) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((x, i) => (i === index ? detalle : x))],
    }));
  };

  const recalcularTipoCambio = () => {
    const { monedaId, tipoCambio } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((detalle) => recalcularPrecioDetalle(detalle, monedaId, tipoCambio))],
    }));
  };

  const recalcularServicios = () => {
    const { sucursalId } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((detalle) => recalcularPrecioServicio(detalle, sucursalId))],
    }));
  };

  const onRemoveDetalle = (index) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.filter((x, i) => i !== index)],
    }));
  };

  const mismoServicio = (detalle, producto) => {
    return detalle.relacionadoId === producto.relacionadoId && detalle.measureId._id === producto.measureId._id;
  };

  const onSelectServicio = ({ servicio, listWorkForces, relacionadoId }) => {
    const { monedaId, tipoCambio, detalles, sucursalId } = state;
    const cantidad = listWorkForces.map((x) => x.cantidad).reduce((a, b) => a + b, 0);
    let listWorkForcesSettedNueva = detalles
      .map((d) => (d.relacionadoId == relacionadoId ? listWorkForces : d.listWorkForcesSetted))
      .filter((d) => d)
      .reduce((a, b) => a.concat(b), []);
    const nuevoValor = listWorkForcesSettedNueva.map((x) => Math.ceil(x.value * x.cantidad)).reduce((a, b) => a + b, 0);
    const measures = measuresServicios(getStoreMedidas.data);
    const producto = castServicio(servicio, monedaId, tipoCambio, measures, sucursalId);
    const hora = measures.find((y) => y.measure.toUpperCase() === HORA);
    if (hora) {
      producto.measureId = hora;
      producto.precio = (producto.listCost.find((y) => y.measureId === hora._id) || { cost: 0 }).cost;
      producto.precio_original = producto.precio;
    }
    let detallesNuevos = [
      ...detalles.map((d) => {
        if (d.relacionadoId === relacionadoId) {
          return {
            ...d,
            cantidad: cantidad,
            listWorkForcesSetted: listWorkForces,
            showModalWorkforce: false,
            tiene_servicio: true,
          };
        } else if (mismoServicio(d, producto)) {
          return {
            ...d,
            showModalWorkforce: false,
            cantidad: Number(nuevoValor),
          };
        }
        return d;
      }),
    ];
    const estaRegistrado = !!detallesNuevos.find((d) => mismoServicio(d, producto));
    if (!estaRegistrado) {
      detallesNuevos.push({
        ...producto,
        tipo_partida: { value: 'SERVICIO', label: 'SERVICIO' },
        cantidad: nuevoValor,
        descuento: 0,
        iva: producto.iva || 16,
        tiene_sustitutos: producto.listAlternos?.length > 0,
        tiene_otro_proveedor: producto.listCostosProveedores?.length > 1,
      });
    }
    setState((prevState) => ({
      ...prevState,
      detalles: [...detallesNuevos],
    }));
  };

  const closeDownload = () => {
    setShowModalDownload(false);
    setTimeout(() => navigate('/recibos'), 1000);
  };

  const { detalles, monedaId, tipoCambio, sucursalId, observaciones } = state;

  return (
    <Module onClickBack={window.history.back} parent='Cotizaciones' title='Editar recibo' loading={isLoadingForm}>
      <ModalImprimir showModal={showModalDownload} onClose={closeDownload} idCotizacion={idCotizacion} />
      <FormEncabezado
        numeroOrden={state.numeroOrden}
        fecha={state.fecha}
        fecha_vencimiento={state.fecha_vencimiento}
        estatus={state.estatus}
        sucursal={state.sucursal}
        moneda={state.moneda}
        cliente={state.cliente}
        tipoCambio={state.tipoCambio}
        estatus_levantamientos={estatusRecibos}
        domicilioId={state.domicilioId}
        domicilios={state.domicilios}
        agente={state.agente}
        anticipo={state.anticipo}
        tipoRecibo={state.tipoRecibo}
        formaPago={state.formaPago}
        metodoPago={state.metodoPago}
        usoCfdi={state.usoCfdi}
        setState={(obj) => setState((prevState) => ({ ...prevState, ...obj }))}
      />
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <BuscadorDetalles
              {...state}
              onAgregarDetalle={(detalle) => {
                setState((prevState) => ({ ...prevState, detalles: [...detalles, detalle] }));
              }}
              onLoadingTable={(isLoadingTable) => setIsLoadingTable(isLoadingTable)}
            />
          </Container>
        </Card.Body>
        {state.detalles && (
          <CbTableResponsive>
            <TableHeaders />
            <CbTableBody loading={isLoadingTable} colSpan={8}>
              {detalles.map((detalle, i) => (
                <RowDetalle
                  key={i}
                  monedaId={monedaId}
                  tipoCambio={tipoCambio}
                  sucursalId={sucursalId}
                  detalle={detalle}
                  onChangeDetalle={(detalle) => onChangeDetalle(i, detalle)}
                  onRemoveDetalle={() => onRemoveDetalle(i)}
                  onSelectServicio={onSelectServicio}
                />
              ))}
              <Totales colSpan={8} moneda={state.monedaId} detalles={state.detalles} anticipo={state.anticipo} />
            </CbTableBody>
          </CbTableResponsive>
        )}
        <Card.Footer />
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col>
                <FormInput type='textarea' title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
                <Button onClick={subirExcel} variant='warning pull-right mr-5' disabled={isLoading}>
                  <Icon icon='open-file' /> Importar Excel
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <br />
    </Module>
  );
};

Edit.propTypes = {
  tipoCambio: PropTypes.number,
};

export default Edit;
