import { Confirmation, DivFormGroup, Input } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { getRequest, postRequest } from '@utils/api';
import { textDomicilio } from '@utils/cotizaciones';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const EditVisita = ({ ordenServicioId, visitaId, agentes, close, showModal }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    visitaId: '',
    ordenServicioId: '',
    ordenServicio: null,
    fecha_hora: moment().format('DD/MM/YYYY HH:mm'),
    horas_servicio: 1,
    agentes_asignados: [],
    observaciones: '',
    agentes: [],
    agente_id: [],
    showModal: false,
  });

  useEffect(() => {
    open();
  }, []);

  const closeModal = () => {
    setState({
      ...state,
      showModal: false,
      visitaId: '',
      ordenServicioId: '',
      fecha_hora: moment().format('YYYY-MM-DD HH:mm'),
      horas_servicio: 1,
      observaciones: '',
      agentes: [],
      agente_id: [],
      visita: {},
    });
    close();
  };

  const open = () => {
    setState({
      ...state,
      ordenServicioId: ordenServicioId,
      visitaId: visitaId,
      visita: {},
    });
    loadData(visitaId);
  };

  const loadData = async (visitaId) => {
    const { data: visita } = await getRequest({ url: `ordenes-servicio/visita/${visitaId}` });
    setState({
      ...state,
      visita: visita,
      ordenServicio: visita.ordenServicioId,
      fecha_hora: moment(visita.fecha_hora).local().format('YYYY-MM-DD HH:mm'),
      horas_servicio: visita.horas_servicio,
      agente_id: visita.agentes_asignados,
      observaciones: visita.observaciones,
      showModal: true,
    });
  };

  const handleUptVisita = async () => {
    const { visitaId, fecha_hora, horas_servicio, agente_id, observaciones } = state;
    const data = { fecha_hora: Date.parse(fecha_hora), horas_servicio, agente_id: agente_id.map((x) => x.value), observaciones };
    if (validations(data)) {
      try {
        await postRequest({ url: `ordenes-servicio/visitas/actualizar/${visitaId}`, body: data });
        closeModal();
        loadData();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones son requeridas.';
    } else if (params.agente_id.length <= 0) {
      validate.success = false;
      validate.message = 'Agentes son requeridos.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onDeleted = () => {
    loadData();
    close();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSelectChange = (campo, value) => {
    setState({ ...state, [campo]: value });
  };

  const { agente_id, fecha_hora, horas_servicio, observaciones, ordenServicio } = state;

  return (
    <Modal size='large' show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Editar visita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-sm-12'>
            <DivFormGroup name='cliente' title='Cliente'>
              <Input disabled name='cliente' value={ordenServicio?.cliente?.razon_social} />
            </DivFormGroup>
          </div>
          <div className='col-sm-12'>
            <DivFormGroup name='domicilio' title='Domicilio servicio'>
              <Input disabled name='domicilio' value={textDomicilio(ordenServicio?.cliente?.domicilio_entrega)} />
            </DivFormGroup>
          </div>
        </div>
        <form>
          <div className='row'>
            <div className='col-sm-6'>
              <DivFormGroup name='fecha_hora' title='Fecha y hora'>
                <Input type='datetime-local' name='fecha_hora' onChange={handleChange} value={fecha_hora} />
              </DivFormGroup>
            </div>
            <div className='col-sm-6'>
              <DivFormGroup name='horas_servicio' title='Horas de servicio'>
                <Input type='number' name='horas_servicio' onChange={handleChange} value={horas_servicio} />
              </DivFormGroup>
            </div>
            <div className='col-sm-12'>
              <DivFormGroup name='observaciones' title='Observaciones' required>
                <textarea className='form-control' name='observaciones' onChange={handleChange} value={observaciones} />
              </DivFormGroup>
            </div>
            <div className='col-sm-12'>
              <DivFormGroup name='agente_id' required title='Agentes'>
                <Select
                  isMulti
                  value={agente_id}
                  name='agente_id'
                  options={agentes}
                  onChange={(value) => handleSelectChange('agente_id', value)}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  getOptionLabel={(e) => e.nombre}
                  getOptionValue={(e) => e._id}
                />
              </DivFormGroup>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleUptVisita} type='button' className='btn btn-success pull-right mr-5'>
          Actualizar
        </button>
        <button onClick={closeModal} className='btn btn-default pull-right mr-5' type='button'>
          Cerrar
        </button>
        <Confirmation
          id={visitaId}
          btnIcon=''
          btnTitle='Eliminar'
          typeConfirm='danger'
          btnType='danger'
          action='Eliminar visita'
          url={`ordenes-servicio/visitas/eliminar/${ordenServicioId}/${visitaId}`}
          description={`¿Confirma que desea eliminar la <strong>visita: <u> ${observaciones}</u></strong>?`}
          loadData={onDeleted}
        />
      </Modal.Footer>
    </Modal>
  );
};

EditVisita.propTypes = {
  ordenServicioId: PropTypes.string.isRequired,
  visitaId: PropTypes.string.isRequired,
  agentes: PropTypes.array.isRequired,

  loadData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default EditVisita;
