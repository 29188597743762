import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';
import moment from 'moment';

const List = ({data, loadData}) => {
    if (!data) {
        return <p>Cargando datos...</p>;
    }
    const renderRows = () => {
        return data.map((row, i ) =>{
            const {_id,cuenta_bancaria_id, referencia, fecha, concepto, cargo, abono }= row;
            return (
                <tr key={i}>
                    <td>{cuenta_bancaria_id?.nombre}</td>
                    <td>{referencia}</td>
                    <td>  {moment(fecha).local().format('YYYY/MM/DD')} </td>
                    <td>{concepto}</td>
                    <td>{cargo}</td>
                    <td>{abono}</td>
                    <td>
                    <IfPermission action='eliminar'> 
                    <Delete id={i} itemId={_id} referencia={referencia} loadData={loadData} />
                    </IfPermission> 
                    <IfPermission action='editar'> 
                    <Edit id={i} itemId={_id} 
                    cuenta_bancaria_id={cuenta_bancaria_id} 
                    referencia={referencia} 
                    fecha={fecha} 
                    concepto={concepto} 
                    cargo={cargo} 
                    abono={abono}
                    loadData={loadData}/>
                    </IfPermission>

                    </td>
                </tr>
            );
        });
    };
    return(
        <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>CUenta Bancaria</th>
          <th width='20%'>Referencia</th>
          <th width='20%'>Fecha</th>
          <th width='10%'>Concepto</th>
          <th width='10%'>Cargo</th>
          <th width='10%'>Abono</th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
    );
};
List.propTypes = {
    data: PropTypes.array.isRequired,
    loadData: PropTypes.func.isRequired
};
export default List;