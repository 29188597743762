import { DivFormGroup, Input, Label, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const NewUnitMeasures = ({ loadUnitMeasures }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [measure, setMeasure] = useState('');
  const [codigo, setCodigo] = useState('');
  const [codigoSat, setCodigoSat] = useState('');
  const [note, setNote] = useState('');
  const [tipo, setTipo] = useState('EQUIPO');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setMeasure('');
    setCodigo('');
    setCodigoSat('');
    setNote('');
    setTipo('EQUIPO');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setMeasure('');
    setCodigo('');
    setCodigoSat('');
    setNote('');
    setTipo('EQUIPO');
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'measure':
        setMeasure(value);
        break;
      case 'codigo':
        setCodigo(value);
        break;
      case 'codigo_sat':
        setCodigoSat(value);
        break;
      case 'note':
        setNote(value);
        break;
      case 'tipo':
        setTipo(value);
        break;
      default:
        break;
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      measure: measure.trim(),
      note: note.trim(),
      codigo: codigo.trim(),
      codigo_sat: codigoSat.trim(),
      tipo,
    };

    if (validations(data)) {
      await postRequest({ url: `measures`, body: data });
      setShowModal(false);
      loadUnitMeasures();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.codigo_sat + '')) {
      validate.success = false;
      validate.message = 'Código SAT es requerido.';
    } else if (isEmpty(params.measure + '')) {
      validate.success = false;
      validate.message = 'Unidad es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar' onSave={handleRegister} onClose={close} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='codigo' title='Código' required>
              <Input name='codigo' onChange={onChange} value={codigo} placeholder='Código' />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='codigo_sat' title='Código SAT' required>
              <Input name='codigo_sat' onChange={onChange} value={codigoSat} placeholder='Código SAT' />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='measure' title='Unidad' required>
              <Input name='measure' onChange={onChange} value={measure} placeholder='Unidad' />
            </DivFormGroup>
          </Col>
          <Col>
            <div className='form-group inline'>
              <label>Tipo de Medida</label>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='tipo' id='rdo-equipo' onChange={onChange} value={'EQUIPO'} defaultChecked={true} /> Equipo
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='tipo' id='rdo-servicio' onChange={onChange} value={'SERVICIO'} /> Servicio
                </label>
              </div>
            </div>
          </Col>
          <Col>
            <DivFormGroup>
              <Label name='note' title='Nota' />
              <TextArea name='note' onChange={onChange} value={note} rows={3} placeholder='Nota' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewUnitMeasures.propTypes = {
  loadUnitMeasures: PropTypes.func.isRequired,
};

export default NewUnitMeasures;
