import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ itemId, description: propDescription, percentage: propPercentage, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setDescription('');
    setPercentage(0);
  };

  const open = () => {
    setDescription(propDescription);
    setPercentage(propPercentage);
    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      description: description.trim().toUpperCase(),
      percentage,
    };

    if (validations(data)) {
      await putRequest({ url: `descuentos-pagos/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.description + '')) {
      validate.success = false;
      validate.message = 'Descripción es requerida.';
    } else if (isEmpty(params.percentage + '')) {
      validate.success = false;
      validate.message = 'Porcentaje es requerido.';
    } else if (params.percentage < 0) {
      validate.success = false;
      validate.message = 'Porcentaje debe ser igual o mayor a cero.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='12'>
            <FormInput required name='description' title='Descripción' onChange={(e) => setDescription(e.target.value)} value={description} />
          </Col>
          <Col sm='12'>
            <FormInput
              required
              type='number'
              name='percentage'
              title='Porcentaje'
              onChange={(e) => setPercentage(e.target.value)}
              value={percentage}
            />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Edit;
