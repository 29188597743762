import React from 'react';
import RowAlternativo from './RowAlternativo';
import PropTypes from 'prop-types';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableFooterBotton from '@cbcomponents/CbTableFooterBotton';

const TableAlternativos = ({ alternativos, onChangeAlternativo }) => {
  const onChange = (idx, alternativo) => {
    alternativos[idx] = alternativo;
    onChangeAlternativo([...alternativos]);
  };

  const onRemove = (idx) => {
    alternativos.splice(idx, 1);
    onChangeAlternativo([...alternativos]);
  };

  const onAdd = () => {
    alternativos.push({ equipoId: null });
    onChangeAlternativo([...alternativos]);
  };
  return (
    <CbPanelTableResponsive title='Equipos alternativos'>
      <thead>
        <tr>
          <th>Equipo</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>
        {alternativos.map((alternativo, i) => (
          <RowAlternativo key={i} equipo={alternativo} onChangeAlternativo={(eqp) => onChange(i, eqp)} onRemoveItemAlternativo={() => onRemove(i)} />
        ))}
        <CbTableFooterBotton colSpan={6} title='Agregar equipo alternativo' onClick={onAdd} />
      </CbTableBody>
    </CbPanelTableResponsive>
  );
};

TableAlternativos.propTypes = {
  alternativos: PropTypes.array,
  onChangeAlternativo: PropTypes.func,
};
export default TableAlternativos;
