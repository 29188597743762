import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RowVisitasPorAtender from './RowVisitasPorAtender';
import PropTypes from 'prop-types';

const TableVisitasPorAtender = ({ visitasPorAtender }) => {
  if (visitasPorAtender.length == 0) return null;
  return (
    <Row>
      <Col>
        <div className='panel panel-default'>
          <div className='panel-heading'>
            <h3 className='panel-title'>Ordenes de servicio por atender</h3>
          </div>
          <div className='table-responsive'>
            <table className='table'>
              <tbody>
                {visitasPorAtender.map((a, i) => (
                  <RowVisitasPorAtender key={i} a={a} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Col>
    </Row>
  );
};

TableVisitasPorAtender.propTypes = {
  visitasPorAtender: PropTypes.array,
};

export default TableVisitasPorAtender;
