import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({itemId,nombre, loadData}) => {
    return(
        <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`estados-duentas/${itemId}`}
      description={`¿Confirma que desea eliminar el estado de cuenta <strong><u> ${nombre}</u> </strong>?`}
      loadData={loadData}
    />
    );
};

Delete.propTypes = {
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    loadData: PropTypes.func.isRequired
};

export default Delete;