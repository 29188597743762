import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Icon } from './Stateless';
import { Row, Col, Card, Button, OverlayTrigger, Popover, InputGroup } from 'react-bootstrap';
import DateRangePickerEs from '../../DateRangePickerEs';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const Search = (props) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [fechaDesde, setFechaDesde] = useState(moment().startOf('month').toDate());
  const [fechaHasta, setFechaHasta] = useState(moment().endOf('month').toDate());

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearch(searchText);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    onSearch();
  }, [search]);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    if (props.loading) return;
    onSearch();
  };

  const onSearch = () => {
    props.onSearch({
      search: search ? search.trim() : '',
      fechaInicio: fechaDesde,
      fechaFin: fechaHasta,
    });
  };

  const handleSelect = ({ startDate, endDate }) => {
    setFechaDesde(startDate);
    setFechaHasta(endDate);
    setShow(false);
    props.onSearch({
      search: search ? search.trim() : '',
      fechaInicio: startDate,
      fechaFin: endDate,
    });
  };

  const { placeholder, loading, blockOne, blockSecond } = props;

  if (props.withDatetime) {
    return (
      <Row>
        <Col xs={12}>
          <Card style={{ marginBottom: '10px' }}>
            <Card.Body style={{ paddingTop: '10px', paddingBottom: '7px' }}>
              <Row>
                <div className={blockOne || 'col-xs-12 col-sm-6'}>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <div className='form-group'>
                          <InputGroup>
                            <Button variant='outline-primary' type='submit' disabled={loading}>
                              <Icon icon='search' />
                            </Button>
                            <input
                              type='text'
                              className='form-control'
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                              placeholder={placeholder || 'Buscar ...'}
                            />
                            <OverlayTrigger
                              show={show}
                              target={target}
                              container={ref.current}
                              placement='bottom'
                              overlay={
                                <Popover id='popover-basic' style={{ maxWidth: '100%' }}>
                                  <Popover.Header as='h3'>Rango de Fechas</Popover.Header>
                                  <Popover.Body style={{ padding: 0 }}>
                                    <DateRangePickerEs startDateInit={fechaDesde} endDateInit={fechaHasta} onChange={handleSelect} />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <Button variant='outline-primary' onClick={handleClick}>
                                <Icon icon='calendar' /> {fechaDesde.toLocaleDateString()} - {fechaHasta.toLocaleDateString()}
                              </Button>
                            </OverlayTrigger>
                          </InputGroup>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
                <div className={blockSecond || 'col-xs-12 col-sm-6'}>{props.children}</div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <Row>
      <Col>
        <Card style={{ marginBottom: '10px' }}>
          <Card.Body style={{ paddingTop: '10px', paddingBottom: '7px' }}>
            <Row>
              <div className={blockOne || 'col-xs-8 col-sm-4'}>
                <form onSubmit={handleSubmit} className=''>
                  <div className='form-group'>
                    <InputGroup>
                      <input
                        type='text'
                        className='form-control'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={placeholder || 'Buscar ...'}
                      />
                      <Button variant='outline-primary' type='submit' disabled={loading}>
                        <Icon icon='search' />
                      </Button>
                    </InputGroup>
                  </div>
                </form>
              </div>
              <div className={blockSecond || 'col-xs-4 col-sm-8'}>{props.children}</div>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

Search.propTypes = {
  children: PropTypes.node,
  blockOne: PropTypes.string,
  withDatetime: PropTypes.bool,
  searchOnStopType: PropTypes.bool,
  loading: PropTypes.bool,
  blockSecond: PropTypes.string,
  search: PropTypes.string,
  fechaFin: PropTypes.string,
  fechaInicio: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

export default Search;
