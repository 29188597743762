import { useState, useEffect, useRef } from 'react';

const usePagination = (fetchData, itemsPorPagina = 10, campoSort = '', initialParams) => {
  const [paginaActual, setPaginaActual] = useState(1);
  const [datos, setDatos] = useState([]);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [count, setCount] = useState(0);
  const [cargando, setCargando] = useState(true);
  const [params, setParams] = useState(initialParams || {});
  const [orden, setOrden] = useState({ campo: campoSort, direccion: 'desc' });
  const abortControllerRef = useRef(null);

  const cargarDatos = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCargando(true);
    try {
      const { rows: items, count: total } = await fetchData(
        {
          pagina: paginaActual,
          limite: itemsPorPagina,
          skip: (paginaActual - 1) * itemsPorPagina,
          orden,
          ...params,
        },
        abortController.signal
      );
      setDatos(items || []);
      setTotalPaginas(total > 0 ? Math.ceil(total / itemsPorPagina) : 1);
      setCount(total);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error al cargar los datos:', error);
      }
    } finally {
      if (!abortController.signal.aborted) {
        setCargando(false);
      }
    }
  };

  useEffect(() => {
    cargarDatos();
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [paginaActual, itemsPorPagina, orden, params]);

  const cambiarPagina = (pagina) => {
    if (pagina > 0 && pagina <= totalPaginas) {
      setPaginaActual(pagina);
    }
  };

  const cambiarOrden = (campo) => {
    setOrden((prevOrden) => ({
      campo,
      direccion: prevOrden.campo === campo && prevOrden.direccion === 'asc' ? 'desc' : 'asc',
    }));
  };

  const refresh = () => {
    cargarDatos();
  };

  return {
    datos,
    paginaActual,
    totalPaginas,
    cargando,
    cambiarPagina,
    cambiarOrden,
    orden,
    setParams,
    refresh,
    count,
  };
};

export default usePagination;
