import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';
import RowContract from './RowContract';

import { URL_FILES, postRequest } from '@utils/api';

const TableContracts = ({ customer }) => {
  const generatePDF = async (contractId) => {
    await postRequest({ url: `contract/pdf`, body: { contractId } });
    window.open(`${URL_FILES}pdf/${contractId}.pdf`, '_blank');
  };

  const renderTableContracts = () => {
    const { _id, listContracts } = customer;

    const rows = listContracts.map((c, i) => {
      return <RowContract key={i} contract={c} customerId={_id} generatePDF={generatePDF} />;
    });

    return (
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th>Folio</th>
            <th>Fecha</th>
            <th>Monto</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{listContracts.length > 0 ? rows : <RowsNotFound colSpan={4} />}</tbody>
      </table>
    );
  };

  if (!customer || (customer.listContracts && customer.listContracts.length === 0)) return null;

  return (
    <div className='col-sm-12'>
      <div className='panel panel-primary'>
        <div className='panel-heading'>
          <h4 className='panel-title'>
            <Icon icon='book' /> Contratos
          </h4>
        </div>
        {renderTableContracts()}
      </div>
    </div>
  );
};

TableContracts.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default TableContracts;
