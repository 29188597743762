import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';
import InputCurrency from '@components/forms/InputCurrency';

const ListServicios = ({ catalogServicios, onChange }) => {
  const handleChange = (item, event) => {
    const { name, value, checked, type } = event.target;
    onChange(item, name, type === 'checkbox' ? checked : value);
  };

  const handleFocus = (check, item) => {
    onChange(item, 'check', !check ? true : check);
  };

  if (catalogServicios.length == 0) return <RowsNotFound />;
  return catalogServicios.map((row, item) => {
    const { nombre, precio, check } = row;
    return (
      <tr key={item}>
        <td style={{ padding: '5px', textAlign: 'center', verticalAlign: 'middle' }}>
          <input type='checkbox' name='check' onChange={(e) => handleChange(item, e)} checked={check} />
        </td>
        <td style={{ padding: '0px' }}>
          <div className='form-control disabled' style={{ borderRadius: '0px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {nombre}
          </div>
        </td>
        <td style={{ padding: '0px' }}>
          <InputCurrency
            name='precio'
            value={check && precio ? precio : '0'}
            style={{ width: '100%', minWidth: '80px' }}
            onChange={(e) => handleChange(item, e)}
            onFocus={() => handleFocus(check, item)}
          />
        </td>
      </tr>
    );
  });
};

ListServicios.propTypes = {
  catalogServicios: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ListServicios;
