import { DOLAR, EURO, PESO } from '@config/constants';
import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ bancos, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [nombre, setNombre] = useState('');
  const [noCuenta, setNoCuenta] = useState('');
  const [sucursal, setSucursal] = useState('');
  const [noSucursal, setNoSucursal] = useState('');
  const [titular, setTitular] = useState('');
  const [convenio, setConvenio] = useState('');
  const [clabe, setClabe] = useState('');
  const [swift, setSwift] = useState('');
  const [bancoId, setBancoId] = useState('');
  const [moneda, setMoneda] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNoCuenta('');
    setClabe('');
    setBancoId('');
    setMoneda('');
    setNombre('');
    setNoCuenta('');
    setSucursal('');
    setNoSucursal('');
    setTitular('');
    setConvenio('');
    setSwift('');
    setComentarios('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      nombre,
      bancoId,
      noCuenta,
      sucursal,
      noSucursal,
      titular,
      convenio,
      clabe,
      swift,
      moneda,
      comentarios,
    };

    if (validations(data)) {
      await postRequest({ url: `cuentas-bancarias`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.noCuenta + '')) {
      validate.success = false;
      validate.message = 'Número de cuenta es requerido.';
    } else if(validate.success && isEmpty(params.nombre + '')){
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }else if(validate.success && isEmpty(params.sucursal + '')){
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    }else if(validate.success && isEmpty(params.noSucursal + '')){
      validate.success = false;
      validate.message = 'Número de Sucursal es requerido.';
    }else if (validate.success && isEmpty(params.clabe + '')) {
      validate.success = false;
      validate.message = 'Número clabe es requerido.';
    }else if(validate.success && isEmpty(params.titular + '')){
      validate.success = false;
      validate.message = 'Titular es requerido.';
    } else if(validate.success && isEmpty(params.convenio + '')){
      validate.success = false;
      validate.message = 'Convenio es requerido.';
    }else if (validate.success && !isMongoId(params.bancoId + '')) {
      validate.success = false;
      validate.message = 'Banco es requerido.';
    } else if(validate.success && isEmpty(params.swift + '')){
      validate.success = false;
      validate.message = 'SWIFT es requerido.';
    }else if (validate.success && isEmpty(params.moneda + '')) {
      validate.success = false;
      validate.message = 'Tipo de moneda es requerido.';
    }else if(validate.success && isEmpty(params.comentarios  + '')){
      validate.success = false;
      validate.message = 'Comentarios es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <FormInput name='nombre' title='Nombre' required onChange={(e) => setNombre(e.target.value)} value={nombre} />
          </Col>
          <Col sm='6'>
            <DivFormGroup name='bancoId' title='Banco' required>
              <select name='bancoId' id='bancoId' className='form-control' onChange={(e) => setBancoId(e.target.value)} value={bancoId}>
                <option value=''>Seleccionar...</option>
                {bancos.map((b, i) => (
                  <option key={i} value={b._id}>
                    {b.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormInput name='noCuenta' title='Número de cuenta' required onChange={(e) => setNoCuenta(e.target.value)} value={noCuenta} />
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <FormInput name='sucursal' title='Sucursal' required onChange={(e) => setSucursal(e.target.value)} value={sucursal} />
          </Col>
          <Col sm='6'>
            <FormInput name='noSucursal' title='No. Sucursal' required onChange={(e) => setNoSucursal(e.target.value)} value={noSucursal} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput name='titular' title='Titular' required onChange={(e) => setTitular(e.target.value)} value={titular} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput name='convenio' title='Convenio' required onChange={(e) => setConvenio(e.target.value)} value={convenio} />
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <FormInput name='clabe' title='Número clabe' required onChange={(e) => setClabe(e.target.value)} value={clabe} />
          </Col>
          <Col sm='6'>
            <FormInput name='swift' title='SWIFT' required onChange={(e) => setSwift(e.target.value)} value={swift} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='moneda' title='Tipo de moneda' required>
              <select name='moneda' id='moneda' className='form-control' onChange={(e) => setMoneda(e.target.value)} value={moneda}>
                <option value=''>Seleccionar...</option>
                <option value={PESO}>Pesos</option>
                <option value={DOLAR}>Dolares</option>
                <option value={EURO}>Euros</option>
              </select>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput name='comentarios' title='Comentarios' required onChange={(e) => setComentarios(e.target.value)} value={comentarios} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  bancos: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default New;
