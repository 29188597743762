import { RedirectTooltip } from '@controls';
import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import TableServices from './TableServices';

const Services = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count } = await postRequest({ url: `services/search`, params: { page, limit: pagination }, body });
    setData(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  return (
    <Module title='Servicios'>
      <Search searchOnStopType onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <IfPermission action='crear'>
          <Link to='/servicios/nuevo' className='btn btn-success pull-right'>
            <Icon icon='plus' /> Nuevo
          </Link>
        </IfPermission>
        <IfPermission action='importar'>
          <RedirectTooltip
            id={1}
            labelTooltip='Importar'
            url='/servicios/importar'
            icon='copy'
            text='Importar'
            className='btn btn-primary pull-right mr-5'
          />
        </IfPermission>
      </Search>
      <Loading loading={loading}>
        <TableServices listServices={data} loadServices={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} />
        </div>
      </Loading>
    </Module>
  );
};

Services.propTypes = {};

export default Services;
