import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest } from '@utils/api';
import { textDomicilio } from '@utils/cotizaciones';
import { format } from '@utils/parseCost';
import Concepto from './Concepto';
import { useParams } from 'react-router-dom';

const ESTATUS_RECIBOS = {
  INICIADA: 'INICIADA',
  ACEPTADA: 'ACEPTADA',
  DECLINADA: 'DECLINADA',
  AUTORIZADA: 'AUTORIZADA',
  EN_PROCESO: 'EN PROCESO',
  CANCELADA: 'CANCELADA',
};

const QuotationByCustomer = () => {
  const { quotationId } = useParams();
  const [quotation, setQuotation] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuotation = async () => {
      const { data } = await getRequest({ url: `recibo/${quotationId}` });
      setQuotation(data);
      setLoading(false);
    };

    fetchQuotation();
  }, [quotationId]);

  const renderDetalles = (listDetalles) => {
    return listDetalles.map((detalle, i) => (
      <>
        <tr key={i}>
          <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.codigo}</th>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.descripcion}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.unidadMedida.nombre}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{format(detalle.cantidad, 2)}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(detalle.precio, 2)}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(detalle.descuento, 2)}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(detalle.iva, 2)}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }} className='text-right'>
            $ {format(detalle.importe, 2)}
          </td>
        </tr>
        {detalle.tipo_partida === 'KIT' && (
          <>
            <tr>
              <td style={{ padding: '0px', verticalAlign: 'middle' }} colSpan='11'>
                <table className='table table-striped table-condenced table-hover' style={{ margin: '0px' }}>
                  <tbody>
                    <tr>
                      <th width='5%'>&nbsp;</th>
                      <th width='12%'>#</th>
                      <th>Descripción</th>
                      <th>Unidad</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th width='8%'>Importe</th>
                    </tr>
                    {detalle.partes.map((parte, j) => (
                      <tr key={j}>
                        <td>&nbsp;</td>
                        <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{parte.codigo}</th>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{parte.descripcion}</td>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{parte.unidadMedida.nombre}</td>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{format(parte.cantidad, 2)}</td>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(parte.precio, 2)}</td>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }} className='text-right'>
                          $ {format(parte.importe, 2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </>
        )}
      </>
    ));
  };

  const renderQuotation = () => {
    const {
      sub_total,
      iva,
      importe,
      numero_recibo,
      detalles,
      fecha,
      moneda,
      customer,
      agente,
      sucursal,
      tipo_cambio,
      estado,
      descuento,
      tipo_cliente,
      anticipo,
    } = quotation;

    return (
      <Module onClickBack={window.history.back} title={`Recibo #${numero_recibo}`}>
        <div className='row'>
          <div className='col-md-12'>
            <div className='panel panel-primary'>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-xs-6'>
                    <Concepto label='Fecha:' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                    <Concepto label='Sucursal:' valor={sucursal.name} />
                    <Concepto label={tipo_cliente.charAt(0) + tipo_cliente.substring(1).toLowerCase() + ':'} valor={customer.razon_social} />
                    <Concepto label='RFC:' valor={customer.rfc} />
                    <Concepto label='Domicilio:' valor={textDomicilio(customer.domicilio)} />
                  </div>
                  <div className='col-xs-6'>
                    <div className='pull-right'>
                      <Concepto label='Agente:' valor={agente ? agente.nombre : ''} />
                      <Concepto label='Estatus:' valor={ESTATUS_RECIBOS[estado]} />
                      <Concepto label='Moneda:' valor={moneda} />
                      <Concepto label='Tipo de cambio:' valor={`$ ${format(tipo_cambio, 2)}`} />
                      <div>
                        <span className='text-success'>
                          <strong>Total:</strong> <u>$ {format(importe, 2)}</u>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='panel panel-default panel-table'>
              <div className='table-responsive'>
                <table className='table table-striped table-condenced table-hover'>
                  <thead>
                    <tr>
                      <th width='10%'>#</th>
                      <th>Descripción</th>
                      <th>Unidad</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Descuento</th>
                      <th>IVA</th>
                      <th width='10%' className='text-right'>
                        Importe
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderDetalles(detalles)}
                    {detalles.length > 0 && (
                      <>
                        <tr>
                          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='7' className='text-right'>
                            Subtotal:
                          </th>
                          <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} className='text-right'>
                            $ {format(sub_total, 2)}
                          </td>
                        </tr>
                        {iva && (
                          <tr>
                            <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='7' className='text-right'>
                              IVA:
                            </th>
                            <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} className='text-right'>
                              $ {format(iva, 2)}
                            </td>
                          </tr>
                        )}
                        {descuento && (
                          <tr>
                            <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='7' className='text-right'>
                              Descuento
                            </th>
                            <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} className='text-right'>
                              $ {format(descuento, 2)}
                            </td>
                          </tr>
                        )}
                        {anticipo && (
                          <tr>
                            <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='7' className='text-right'>
                              Anticipo
                            </th>
                            <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} className='text-right'>
                              $ {format((importe / 100) * anticipo, 2)}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th style={{ padding: '4px 8px', verticalAlign: 'middle' }} colSpan='7' className='text-right'>
                            Total:
                          </th>
                          <td style={{ padding: '4px 8px', verticalAlign: 'middle' }} className='text-right'>
                            $ {format(importe, 2)}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='panel panel-primary'>
              <div className='panel panel-body'>
                <div className='row'>
                  <div className='col-xs-12'>
                    <Concepto label='Observaciones:' />
                    {quotation.observaciones}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Module>
    );
  };

  return <div>{loading ? <Loading /> : renderQuotation()}</div>;
};

QuotationByCustomer.propTypes = {};

export default QuotationByCustomer;
