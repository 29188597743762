import React from 'react';
import Required from '@controls/Required';

const TableHeaders = () => {
  return (
    <thead>
      <tr>
        <th width='10%'>#</th>
        <th>
          Descripción <Required />
        </th>
        <th width='10%'>
          Unidad <Required />
        </th>
        <th width='10%'>
          Cantidad <Required />
        </th>
        <th width='7%'>
          Precio <Required />
        </th>
        <th width='5%' abbr='Descuento'>
          Desc.
        </th>
        <th width='11%' className='text-right'>
          Importe
        </th>
        <th width='7%'>&nbsp;</th>
      </tr>
    </thead>
  );
};

export default TableHeaders;
