import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { printAmount, switchMoneda } from '@utils/parseCost';

const RowSaldo = ({ saldo }) => {
  const getColor = (count) => {
    if (count <= 30) {
      return '#FFD600';
    } else if (count > 30 && count <= 60) {
      return '#E65100';
    } else {
      return '#B71C1C';
    }
  };

  const switchSaldo = (dias, saldo_vencido) => {
    const color = getColor(dias);
    return (
      <span className={`label label-default`} style={{ backgroundColor: color }}>
        {printAmount(saldo_vencido)}
      </span>
    );
  };

  const { documento, agenteId, fecha, vencimiento, saldo_vencer, saldo_vencido } = saldo;
  const moneda = switchMoneda(saldo);

  const mVencimiento = moment(vencimiento);
  const current = new Date();
  const now = moment(current);
  const diasTranscurridos = Math.abs(mVencimiento.diff(now, 'days') + 1);
  const labelSaldo = switchSaldo(diasTranscurridos, saldo_vencido);
  const color = getColor(diasTranscurridos);

  return (
    <tr>
      <td>
        <strong>{documento}</strong>
      </td>
      <td>
        <span className={`label label-${moneda.color}`}>{moneda.text}</span>
      </td>
      <td>{agenteId.nombre}</td>
      <td>{fecha !== null ? moment(fecha).format('DD/MM/YYYY') : ''}</td>
      <td>
        <u>{vencimiento !== null ? moment(vencimiento).format('DD/MM/YYYY') : ''}</u>
      </td>
      <td>
        <strong style={{ color }}>{diasTranscurridos} dias</strong>
      </td>
      <td>{printAmount(saldo_vencer)}</td>
      <td>{labelSaldo}</td>
      <td></td>
    </tr>
  );
};

RowSaldo.propTypes = {
  saldo: PropTypes.object.isRequired,
};

export default RowSaldo;
