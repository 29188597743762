import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';
import RowFactura from './RowFactura';
import { Col, Row } from 'react-bootstrap';

const TableFacturas = ({ facturas, loadData, url, customer }) => {
  const renderTable = () => {
    const rows = facturas.map((s, i) => <RowFactura key={i} factura={s} {...customer} loadData={loadData} />);
    const totales = facturas.reduce(
      (t, f) => {
        t.importe += f.importe;
        t.saldo += f.saldo;
        return t;
      },
      { importe: 0, saldo: 0 }
    );

    return (
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th width='5%'>Serie</th>
            <th width='15%'>Número</th>
            <th width='30%'>Folio</th>
            <th width='10%'>Importe</th>
            <th width='10%'>Saldo</th>
            <th width='10%'></th>
            <th width='10%'></th>
          </tr>
        </thead>
        <tbody>{facturas.length > 0 ? rows : <RowsNotFound colSpan={7} />}</tbody>
        <tfoot>
          <tr>
            <th colSpan='3'>Totales</th>
            <th>${format(totales.importe, 2)}</th>
            <th>${format(totales.saldo, 2)}</th>
            <th colSpan='2'></th>
          </tr>
        </tfoot>
      </table>
    );
  };

  const renderLinkPaymentOnline = () => (
    <a href={url} target='_blank' rel='noopener noreferrer' className='btn btn-success pull-right'>
      <Icon icon='globe' /> Pago en línea
    </a>
  );

  if (!facturas || facturas.length === 0) return null;

  return (
    <Col sm='12'>
      <div className='panel panel-primary'>
        <div className='panel-heading'>
          <h4 className='panel-title'>
            <Icon icon='warning-sign' /> Facturas
          </h4>
        </div>
        <div className='panel-body'>
          <Row>
            <Col sm='6'>
              <h4>
                Saldo a favor <CbBadge type='success'>$ {format(customer.saldo_favor, 2)}</CbBadge>
              </h4>
            </Col>
            <Col sm='6'>{facturas.length > 0 ? renderLinkPaymentOnline() : null}</Col>
          </Row>
        </div>
        {renderTable()}
      </div>
    </Col>
  );
};

TableFacturas.propTypes = {
  url: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  facturas: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
};

export default TableFacturas;
