import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import PropTypes from 'prop-types';

const InputPercentage = ({ value, onChange, name, placeholder, disabled, style, className }) => {
  const currencyMask = createNumberMask({
    suffix: ' %',
    prefix: '',
    allowDecimal: true,
  });

  const handleOnChange = (e) => {
    const { value } = e.target;
    let number = value.replace(/[^0-9.]/g, '');
    number = number === '' ? '0' : number;
    onChange({
      target: {
        name,
        value: number,
      },
    });
  };

  return (
    <MaskedInput
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      guide={false}
      value={value}
      style={style}
      className={'form-control ' + className}
      onChange={handleOnChange}
      mask={currencyMask}
    />
  );
};

InputPercentage.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default InputPercentage;
