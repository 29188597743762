import PropTypes from 'prop-types';

const Then = ({ children }) => {
  return children;
};

Then.propTypes = {
  children: PropTypes.node,
};

export default Then;
