import { DivFormGroup, Input, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useStoreNotification } from '@stores/catalogs.store';

const NewProviders = ({ clasificaciones, usosCfdis, loadProviders }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    showModal: false,
    code: '',
    nameProvider: '',
    nombre_comercial: '',
    rfc: '',
    phone: '',
    email: '',
    street: '',
    numberInt: '',
    numberExt: '',
    colony: '',
    city: '',
    state: '',
    postalCode: '',
    contactName: '',
    contactPhone: '',
    contactCellular: '',
    contactEmail: '',
    clasificacionProveedorId: '',
    usoCfdiId: '',
    isLoading: false,
  });

  const close = () => {
    setState({
      ...state,
      showModal: false,
      nameProvider: '',
      code: '',
      rfc: '',
      phone: '',
      email: '',
      street: '',
      numberInt: '',
      numberExt: '',
      colony: '',
      city: '',
      state: '',
      postalCode: '',
      contactName: '',
      contactPhone: '',
      contactCellular: '',
      contactEmail: '',
      clasificacionProveedorId: null,
    });
  };

  const open = () => {
    setState({ ...state, showModal: true, isLoading: false });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      clasificacionProveedorId,
      nameProvider,
      rfc,
      phone,
      email,
      street,
      numberInt,
      numberExt,
      code,
      colony,
      city,
      state: estado,
      postalCode,
      contactName,
      contactPhone,
      contactCellular,
      contactEmail,
      usoCfdiId,
      nombre_comercial,
    } = state;

    const data = {
      code: code.trim(),
      nameProvider: nameProvider.trim(),
      nombre_comercial: nombre_comercial.trim(),
      rfc: rfc.trim(),
      phone: phone.trim(),
      email: email.trim(),
      street: street.trim(),
      numberExt: numberExt.trim(),
      numberInt: numberInt.trim(),
      colony: colony.trim(),
      city: city.trim(),
      state: estado.trim(),
      postalCode: postalCode.trim(),
      contactName: contactName.trim(),
      contactPhone: contactPhone.trim(),
      contactCellular: contactCellular.trim(),
      contactEmail: contactEmail.trim(),
      clasificacionProveedorId: clasificacionProveedorId?._id,
      usoCfdiId: usoCfdiId?._id,
    };

    if (validations(data)) {
      await postRequest({ url: `provider`, body: data });
      loadProviders();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.code + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.nameProvider + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.clasificacionProveedorId + '')) {
      validate.success = false;
      validate.message = 'Clasificación es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onSelect = (campo, value) => {
    setState({ ...state, [campo]: value });
  };

  const {
    showModal,
    nameProvider,
    rfc,
    phone,
    email,
    clasificacionProveedorId,
    code,
    street,
    numberInt,
    numberExt,
    colony,
    city,
    state: estado,
    postalCode,
    contactName,
    contactPhone,
    contactCellular,
    contactEmail,
    usoCfdiId,
    isLoading,
    nombre_comercial,
  } = state;

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar rubro' isLoading={isLoading} onSave={handleRegister}>
        <Row>
          <Col>
            <FormInput name='code' title='Código' required onChange={onChange} value={code} />
          </Col>
          <Col>
            <FormInput name='nameProvider' title='Nombre' required onChange={onChange} value={nameProvider} />
          </Col>
          <Col>
            <FormInput name='rfc' title='RFC' onChange={onChange} value={rfc} maxLength={13} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput name='nombre_comercial' title='Nombre comercial' onChange={onChange} value={nombre_comercial} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='clasificacionProveedorId' title='Clasificación' required>
              <Select
                value={clasificacionProveedorId}
                placeholder='Selecciona una opcion'
                options={clasificaciones}
                getOptionLabel={(option) => option.nombre}
                getOptionValue={(option) => option._id}
                onChange={(value) => onSelect('clasificacionProveedorId', value)}
              />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='usoCfdiId' title='Uso de CFDI'>
              <Select
                value={usoCfdiId}
                placeholder='Selecciona una opcion'
                options={usosCfdis}
                getOptionLabel={(option) => option.descripcion}
                getOptionValue={(option) => option._id}
                onChange={(value) => onSelect('usoCfdiId', value)}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput name='phone' title='Teléfono' onChange={onChange} value={phone} />
          </Col>
          <Col>
            <FormInput name='email' title='Correo electrónico' onChange={onChange} value={email} />
          </Col>
        </Row>
        <Row>
          <Col>
            <legend>
              <Icon icon='home' /> Domicilio
            </legend>
          </Col>
          <Col>
            <FormInput name='street' title='Calle' onChange={onChange} value={street} />
          </Col>
          <Col>
            <DivFormGroup name='numberExt' title='Número'>
              <br />
              <Row>
                <Col>
                  <Input name='numberExt' onChange={onChange} value={numberExt} placeholder='Exterior' />
                </Col>
                <Col>
                  <Input name='numberInt' onChange={onChange} value={numberInt} placeholder='Interior' />
                </Col>
              </Row>
            </DivFormGroup>
          </Col>
          <Col>
            <FormInput name='colony' title='Colonia' onChange={onChange} value={colony} />
          </Col>
          <Col>
            <FormInput name='postalCode' title='Código postal' onChange={onChange} value={postalCode} />
          </Col>
          <Col>
            <FormInput name='city' title='Municipio' onChange={onChange} value={city} />
          </Col>
          <Col>
            <FormInput name='state' title='Estado' onChange={onChange} value={estado} />
          </Col>
        </Row>
        <Row>
          <Col>
            <legend>
              <Icon icon='user' /> Contacto
            </legend>
          </Col>
          <Col>
            <FormInput name='contactName' title='Nombre' onChange={onChange} value={contactName} />
          </Col>
          <Col>
            <FormInput name='contactPhone' title='Teléfono' onChange={onChange} value={contactPhone} />
          </Col>
          <Col>
            <FormInput name='contactCellular' title='Celular' onChange={onChange} value={contactCellular} />
          </Col>
          <Col>
            <FormInput name='contactEmail' title='Correo electrónico' onChange={onChange} value={contactEmail} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewProviders.propTypes = {
  usosCfdis: PropTypes.array.isRequired,
  loadProviders: PropTypes.func.isRequired,

  clasificaciones: PropTypes.array.isRequired,
};

export default NewProviders;
