import React from 'react';
import { Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';
import CbPagination from './CbPagination';
import PropTypes from 'prop-types';

const CbCustomPagination = ({ pagination, activePage, count, onPagination, onChangeItemsPerPagina }) => {
  return (
    <Row>
      <Col>
        <div className='btn-toolbar justify-content-between mb-3'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={onPagination} />
          <div className='input-group'>
            <div className='input-group-prepend'>
              <DropdownButton title={pagination}>
                <DropdownItem onClick={() => onChangeItemsPerPagina(10)}>10</DropdownItem>
                <DropdownItem onClick={() => onChangeItemsPerPagina(20)}>20</DropdownItem>
                <DropdownItem onClick={() => onChangeItemsPerPagina(50)}>50</DropdownItem>
                <DropdownItem onClick={() => onChangeItemsPerPagina(100)}>100</DropdownItem>
                <DropdownItem onClick={() => onChangeItemsPerPagina(200)}>200</DropdownItem>
                <DropdownItem onClick={() => onChangeItemsPerPagina(500)}>500</DropdownItem>
                <DropdownItem onClick={() => onChangeItemsPerPagina(1000)}>1000</DropdownItem>
              </DropdownButton>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

CbCustomPagination.propTypes = {
  pagination: PropTypes.number,
  activePage: PropTypes.number,
  count: PropTypes.number,
  onPagination: PropTypes.func,
  onChangeItemsPerPagina: PropTypes.func,
};

export default CbCustomPagination;
