import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { RowsNotFound } from '@stateless';
import { Icon } from '@stateless';
import ListServicios from './ListServicios';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Input } from '@controls';
import InputCurrency from '@components/forms/InputCurrency';

const ContratoMonitoreo = (props) => {
  const onChange = (event) => {
    props.onChange(event);
  };

  const onChangeServicios = (item, nombre, event) => {
    props.onChangeServicios(item, nombre, event);
  };

  const onChangeList = (item, nombre, name, value) => {
    props.onChangeList(item, nombre, name, value);
  };

  const renderFormasPagoServicios = () => {
    const { catalogoFormasPagoServicios } = props;
    if (catalogoFormasPagoServicios.length > 0) {
      return catalogoFormasPagoServicios.map((row, item) => {
        const { forma } = row;
        if (catalogoFormasPagoServicios[item].tipo === 'SERVICIO') {
          return (
            <tr key={item}>
              <td style={{ padding: '0px', textAlign: 'center', verticalAlign: 'middle' }}>
                <input
                  type='radio'
                  name='check'
                  checked={row.check}
                  onChange={(event) => onChangeServicios(item, 'catalogoFormasPagoServicios', event)}
                />
              </td>
              <td style={{ padding: '0px' }}>
                <OverlayTrigger placement='top' overlay={<Tooltip id={1}>{forma}</Tooltip>}>
                  <input style={{ borderRadius: '0px' }} className='form-control disabled' value={forma} />
                </OverlayTrigger>
              </td>
            </tr>
          );
        }
      });
    } else {
      return <RowsNotFound />;
    }
  };

  const renderCondicionesPagoServicios = () => {
    const { catalogoCondicionesPagoServicios } = props;
    if (catalogoCondicionesPagoServicios.length > 0) {
      return catalogoCondicionesPagoServicios.map((row, item) => {
        const { plazo } = row;
        return (
          <tr key={item}>
            <td style={{ padding: '0px', textAlign: 'center', verticalAlign: 'middle' }}>
              <input
                type='radio'
                name='checkCPS'
                checked={row.check}
                onChange={(event) => onChangeServicios(item, 'catalogoCondicionesPagoServicios', event)}
              />
            </td>
            <td style={{ padding: '0px' }}>
              <OverlayTrigger placement='top' overlay={<Tooltip id={1}>{plazo}</Tooltip>}>
                <input style={{ borderRadius: '0px' }} className='form-control disabled' value={plazo} />
              </OverlayTrigger>
            </td>
          </tr>
        );
      });
    } else {
      return <RowsNotFound />;
    }
  };

  const renderList = (list, nombre) => {
    return (
      <ListServicios
        catalogServicios={list}
        loadServicios={props.loadServicios}
        onChange={(item, name, value) => onChangeList(item, nombre, name, value)}
      />
    );
  };

  const { monitoreo, monitoreoAdicionales, interactivos, interactivosAdicionales, card, clabe, total, nombreBanco } = props;
  const listMonitoreo = renderList(monitoreo, 'monitoreo');
  const listMonitoreoAdicionales = renderList(monitoreoAdicionales, 'monitoreoAdicionales');
  const listInteractivos = renderList(interactivos, 'interactivos');
  const listInteractivosAdicionales = renderList(interactivosAdicionales, 'interactivosAdicionales');
  const formas_pago_servicios = renderFormasPagoServicios();
  const condiciones_pago_servicios = renderCondicionesPagoServicios();
  return (
    <>
      <Row>
        <Col sm='6'>
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Header>
                  <Icon icon='th-list' /> Servicio de monitoreo
                </Card.Header>
                <table className='table table-condensed table-hover table-striped table-bordered ta'>
                  <tbody>{listMonitoreo}</tbody>
                </table>
              </Card>
            </Col>
            <Col sm='12'>
              <br />
              <Card>
                <Card.Header>
                  <Icon icon='th-list' /> Servicios adicionales para monitoreo
                </Card.Header>
                <table className='table table-condensed table-hover table-striped table-bordered'>
                  <tbody>{listMonitoreoAdicionales}</tbody>
                </table>
              </Card>
            </Col>
            <Col sm='12'>
              <br />
              <Card>
                <Card.Header>
                  <Icon icon='th-list' /> Total de servicios contratados (con IVA)
                </Card.Header>
                <table className='table table-condensed table-hover table-striped table-bordered ta'>
                  <thead>
                    <tr>
                      <th colSpan={2} style={{ padding: '5px', textAlign: 'right', verticalAlign: 'middle' }}>
                        Totales
                      </th>
                      <th style={{ padding: '0px' }}>
                        <InputCurrency name='total' className='form-control' value={total} style={{ width: '100%', minWidth: '80px' }} disabled />
                      </th>
                    </tr>
                  </thead>
                </table>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm='6'>
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Header>
                  <Icon icon='th-list' /> Servicios interactivos
                </Card.Header>
                <table className='table table-condensed table-hover table-striped table-bordered ta'>
                  <tbody>{listInteractivos}</tbody>
                </table>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <br />
              <Card>
                <Card.Header>
                  <Icon icon='th-list' /> Servicios interactivos adicionales
                </Card.Header>
                <Row>
                  <Col sm='12'>
                    <table className='table table-condensed table-hover table-striped table-bordered ta'>
                      <tbody>{listInteractivosAdicionales}</tbody>
                    </table>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <br />
          <Card>
            <Card.Header>
              <Icon icon='th-list' /> Forma de pago
            </Card.Header>
            <Row>
              <Col sm='12'>
                <table className='table table-condensed table-hover table-striped table-bordered ta'>
                  <tbody>
                    {formas_pago_servicios}
                    {card ? (
                      <>
                        <tr>
                          <td style={{ padding: '0px', verticalAlign: 'middle' }}>Cuenta / CLABE:</td>
                          <td style={{ padding: '0px' }}>
                            <Input style={{ borderRadius: '0px' }} type='text' name='clabe' value={clabe} onChange={onChange} />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '0px', verticalAlign: 'middle' }}>Nombre del banco:</td>
                          <td style={{ padding: '0px' }}>
                            <Input style={{ borderRadius: '0px' }} type='text' name='nombreBanco' value={nombreBanco} onChange={onChange} />
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col sm='6'>
          <br />
          <Card>
            <Card.Header>
              <Icon icon='th-list' /> Condiciones de pago servicios (Meses de plazo)
            </Card.Header>{' '}
            <table className='table table-condensed table-hover table-striped table-bordered'>
              <tbody>{condiciones_pago_servicios}</tbody>
            </table>
          </Card>
        </Col>
      </Row>
    </>
  );
};

ContratoMonitoreo.propTypes = {
  monitoreo: PropTypes.array.isRequired,
  monitoreoAdicionales: PropTypes.array.isRequired,
  interactivos: PropTypes.array.isRequired,
  interactivosAdicionales: PropTypes.array.isRequired,
  catalogoFormasPagoServicios: PropTypes.array.isRequired,
  catalogoCondicionesPagoServicios: PropTypes.array.isRequired,
  total: PropTypes.string.isRequired,

  card: PropTypes.bool.isRequired,
  clabe: PropTypes.string.isRequired,
  nombreBanco: PropTypes.string.isRequired,
  onChangeList: PropTypes.func.isRequired,
  onChangeServicios: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadServicios: PropTypes.func.isRequired,
};

export default ContratoMonitoreo;
