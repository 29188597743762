import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';

const CbDomicilio = ({ address }) => {
  if (!address) return null;
  const {
    calle = '',
    exterior = '',
    interior = '',
    colonia = '',
    coloniaId = 0,
    codigo_postal = '',
    municipio = '',
    estado = '',
    estadoId = 0,
    pais = '',
    longitud,
    latitud,
    tiene_posicion = false,
  } = address;

  const domicilio = `${calle} ${exterior}${interior ? `, ${interior}` : ''}`;
  const coloniaCP = `${colonia && coloniaId ? colonia : ''}${codigo_postal && codigo_postal !== '0' ? `, C.P. ${codigo_postal}` : ''}`;
  const ciudad = `${municipio}${estado && estadoId ? `, ${estado}` : ''}`;

  return (
    <small>
      <p>
        {tiene_posicion && latitud && longitud && (
          <>
            <a href={`https://maps.google.com/?q=${latitud},${longitud}`} target='_blank' rel='noreferrer'>
              <Icon icon='map-marker' style={{ color: 'green' }} />{' '}
            </a>
            &nbsp;
          </>
        )}
        {domicilio}
        {colonia && <br />}
        {coloniaCP}
        {municipio && <br />}
        {ciudad} {pais}
      </p>
    </small>
  );
};

CbDomicilio.propTypes = {
  address: PropTypes.shape({
    calle: PropTypes.string,
    exterior: PropTypes.string,
    interior: PropTypes.string,
    colonia: PropTypes.string,
    coloniaId: PropTypes.number,
    codigo_postal: PropTypes.string,
    municipio: PropTypes.string,
    estado: PropTypes.string,
    estadoId: PropTypes.number,
    pais: PropTypes.string,
    longitud: PropTypes.number,
    latitud: PropTypes.number,
    tiene_posicion: PropTypes.bool,
  }),
};

export default CbDomicilio;
