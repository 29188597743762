import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const NewTitulos = ({ loadTitulos }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [abreviatura, setAbreviatura] = useState('');
  const [titulo, setTitulo] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setAbreviatura('');
    setTitulo('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'abreviatura') setAbreviatura(value);
    if (name === 'titulo') setTitulo(value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const data = {
      abreviatura: abreviatura.trim(),
      titulo: titulo.trim(),
    };

    if (validations(data)) {
      await postRequest({ url: 'titulos', body: data });
      loadTitulos();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.abreviatura + '')) {
      validate.success = false;
      validate.message = 'Abreviatura es requerida.';
    } else if (validate.success && isEmpty(params.titulo + '')) {
      validate.success = false;
      validate.message = 'Título es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar título' onClose={close} isLoading={isLoading} onSave={handleRegister}>
        <Row>
          <Col>
            <DivFormGroup name='abreviatura' title='Abreviatura' required>
              <Input name='abreviatura' onChange={onChange} value={abreviatura} placeholder='Lic.' />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='titulo' title='Título' required>
              <Input name='titulo' onChange={onChange} value={titulo} placeholder='Licenciado' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewTitulos.propTypes = {
  loadTitulos: PropTypes.func.isRequired,
};

export default NewTitulos;
