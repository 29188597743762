import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, ButtonGroup, Card, Col, FormGroup, InputGroup, ListGroup, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest, postRequest } from '@utils/api';
import { validateUpdate } from '@validations/levantamientos';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store.js';

const Areas = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    clasificaciones: [],
    activeClasificacion: null,
    idLevantamiento: null,
    showModal: false,
    isLoadingForm: true,
    isLoadingTable: false,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    agente: null,
    maximo_descuento: 0,
    detalles: [],
    observaciones: '',
    estatus: 'INICIADA',
    cliente: null,
    productos: [],
    tipoCambio: 0,
    usa_almacen: false,
    usa_servicios: false,
    usa_gastos: false,
    tipo_cliente: 'CLIENTE',
    tipos_clientes: [
      { value: 'PROSPECTO', label: 'PROSPECTO' },
      { value: 'CLIENTE', label: 'CLIENTE' },
    ],
    estatus_levantamientos: [{ value: 'INICIADA', label: 'INICIADA' }],
    domicilios: [],
    anticipo: 0,
    areas: [],
  });

  const inicializar = useCallback(async () => {
    await getLevantamiento();
  }, []);

  const getLevantamiento = useCallback(async () => {
    const { levantamientoId } = params;
    const { data } = await getRequest({ url: `levantamientos/${levantamientoId}` });
    const encabezado = data;
    try {
      const cliente = encabezado.customerId;
      const domicilios =
        cliente && cliente.listDomicilios && cliente.listDomicilios.length > 0
          ? encabezado.customerId.listDomicilios
          : [encabezado.customer.domicilio];
      setState((prevState) => ({
        ...prevState,
        isLoadingForm: false,
        numeroOrden: encabezado.numero_levantamiento,
        fecha: moment(encabezado.fecha).local().format('YYYY-MM-DD'),
        fecha_vencimiento: moment(encabezado.fecha_vencimiento).local().format('YYYY-MM-DD'),
        estatus: encabezado.estado,
        sucursalId: encabezado.sucursal._id,
        monedaId: encabezado.moneda,
        tipoLevantamientoId: encabezado.tipo_levantamiento,
        tipo_cliente: encabezado.tipo_cliente,
        clienteId: encabezado.customer._id,
        clienteName: encabezado.customer.razon_social,
        cliente: encabezado.customer,
        agente: encabezado.agente,
        domicilioId: encabezado.customer.domicilio._id || 1,
        domicilios,
        observaciones: encabezado.observaciones,
        isLoading: false,
        areas: encabezado.areas,
      }));
    } catch (e) {
      console.log(e);
    }
  }, [params]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `clasificadores-levantamientos/all` });
      const clasificaciones = data.filter((c) => c.subclasificaciones && c.subclasificaciones.length > 0);
      setState((prevState) => ({
        ...prevState,
        clasificaciones,
      }));
      inicializar();
    };
    fetchData();
  }, [inicializar]);

  const handleAtras = () => {
    const { levantamientoId } = params;
    navigate(`/levantamientos/${levantamientoId}/areas`);
  }

  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();
      const { levantamientoId } = params;
      const { numeroOrden, fecha, estatus, sucursalId, almacenId, tipo_cliente, domicilioId, cliente, observaciones, areas, tipoLevantamientoId } =
        state;
      const data = {
        numero_levantamiento: numeroOrden,
        tipoLevantamientoId,
        tipo_cliente,
        fecha: moment(fecha, 'YYYY-MM-DD').utc(),
        estatus_levantamiento: estatus,
        sucursalId,
        almacenId,
        observaciones,
        clienteId: cliente ? cliente._id : '',
        domicilioId,
        areas: areas.map((area) => ({
          nombre: area.nombre,
          clasificaciones: area.clasificaciones.map((clasificacion) => ({
            clasificacionId: clasificacion.clasificacionId,
            subclasificaciones: clasificacion.subclasificaciones.map((subclasificacion) => ({
              subclasificacionId: subclasificacion.subclasificacionId,
              cantidad: subclasificacion.cantidad,
            })),
          })),
          zonas: area.zonas.map((zona) => ({
            nombre: zona.nombre,
            clasificaciones: zona.clasificaciones.map((clasificacion) => ({
              clasificacionId: clasificacion.clasificacionId,
              subclasificaciones: clasificacion.subclasificaciones.map((subclasificacion) => ({
                subclasificacionId: subclasificacion.subclasificacionId,
                cantidad: subclasificacion.cantidad,
              })),
            })),
          })),
        })),
      };
      if (validations(data)) {
        await putRequest({ url: `levantamientos/${levantamientoId}`, body: data });
        setTimeout(() => navigate('/levantamientos'), 1000);
      }
    },
    [params, state, navigate]
  );

  const validations = useCallback(
    (params) => {
      const { error } = validateUpdate(params);
      if (error) {
        notification({
          title: 'Información incompleta',
          message: error.details[0].message,
          type: 'error',
        });
      }
      return !error;
    },
    [notification]
  );

  const [area, setArea] = useState('');

  const handleAgregarArea = async () => {
    const { levantamientoId } = params;
    if (!area) {
      notification({
        title: 'Información incompleta',
        message: 'El nombre del area es requerido',
        type: 'error',
      });
      return;
    } 
    const { data } = await postRequest({ url: `levantamientos/${levantamientoId}/areas`, body: { nombre: area?.toUpperCase() } });
    if (data) {
      setState((prevState) => ({
        ...prevState,
        areas: [...prevState.areas, data],
      }));
      setArea('');
      navigate(`/levantamientos/${levantamientoId}/areas/${data?._id}`);
    }
  };

  const handleEditarArea = (areaId) => {
    const { levantamientoId } = params;
    navigate(`/levantamientos/${levantamientoId}/areas/${areaId}`);
  };

  const handleEliminarArea = (index) => { 
    setState((prevState) => ({
      ...prevState,
      areas: prevState.areas.filter((a,idx) => idx !== index),
    }));
  };

  const renderView = () => {
    const {
      isLoading,
      areas,
    } = state;
    return (
      <>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='12'>
              <FormGroup>
                <label>Agregar area</label>
                <InputGroup>
                <input
                  className='form-control'
                  value={area}
                  placeholder='Nombre del area'
                  onChange={(e) => setArea(e.target.value)}
                />
                <Button variant='success' onClick={handleAgregarArea}>
                  <Icon icon='plus' />
                </Button>
                </InputGroup>
              </FormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br/>
        <ListGroup>
          {areas.map((area, i) => (
            <ListGroup.Item key={i} className='d-flex justify-content-between align-items-center'>
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{area?.nombre}</h5>
              </div>
              <ButtonGroup>
                <Button variant="warning" onClick={()=> handleEditarArea(area?._id)}><Icon icon="copy" /></Button>
                <Button variant="danger" onClick={() => handleEliminarArea(i)}><Icon icon="trash" /></Button>
              </ButtonGroup>
            </ListGroup.Item>
          ))}
        </ListGroup>
      <br />
      <Card>
        <Card.Footer>
          <Row>
            <Col sm='12'>
              <Saving saving={isLoading} />
              <Button onClick={handleAtras} variant='warning' disabled={isLoading}>
                <Icon icon='arrow-left' /> Ir a datos generales
              </Button>
              <Button onClick={handleRegister} variant='success' className='pull-right' disabled={isLoading}>
                <Icon icon='floppy-disk' /> Finalizar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      </>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Levantamientos' title='Editar levantamiento'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Areas.propTypes = {
  params: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  onChangeTipoCliente: PropTypes.func.isRequired,
};

export default Areas;
