import { DivFormGroup, Input, Label, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';

const NewStep = ({ recipeId, refresh }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState('');
  const [addProcess, setAddProcess] = useState(false);
  const [note, setNote] = useState('');
  const [required, setRequired] = useState(false);
  const [capturable, setCapturable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setStep('');
    setNote('');
    setRequired(false);
    setCapturable(false);
    setAddProcess(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const data = {
      step: step.trim(),
      note: note.trim(),
      addProcess,
      required,
      capturable,
      recipeId,
    };

    if (validations(data)) {
      await postRequest({ url: `steps`, body: data });
      refresh();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.step + '')) {
      validate.success = false;
      validate.message = 'Título de Campo es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='wrench' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Nuevo' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <div className='checkbox'>
              <label className='control-label'>
                <input id='addProcess' name='addProcess' type='checkbox' checked={addProcess} onChange={(e) => setAddProcess(e.target.checked)} />{' '}
                <strong>Incluir en el proceso</strong>
              </label>
            </div>
          </Col>
          {addProcess && (
            <Col>
              <div className='checkbox'>
                <label className='control-label'>
                  <input id='capturable' name='capturable' type='checkbox' checked={capturable} onChange={(e) => setCapturable(e.target.checked)} />{' '}
                  <strong>¿Es Para Captura en el Carro de Juan?</strong>
                </label>
              </div>
              <p></p>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <DivFormGroup>
              <Label name='step' title='Título' />
              <Input name='step' onChange={(e) => setStep(e.target.value)} value={step} placeholder='Nombre del Título de Campo' />
            </DivFormGroup>
          </Col>
          <Col>
            <div className='checkbox'>
              <label className='control-label'>
                <input id='required' name='required' type='checkbox' checked={required} onChange={(e) => setRequired(e.target.checked)} />{' '}
                <strong>* ¿Es campo obligatorio?</strong>
              </label>
            </div>
          </Col>
          <Col>
            <DivFormGroup>
              <Label name='note' title='Nota' />
              <TextArea name='note' onChange={(e) => setNote(e.target.value)} value={note} rows={3} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewStep.propTypes = {
  recipeId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default NewStep;
