import { MONEDA } from '@config/constants';
import { FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Icon, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import BuscadorDetalles from './BuscadorDetalles';
import FormEncabezado from './FormEncabezado';
import RowDetalle from './RowDetalle';
import Totales from './Totales';
import React, { useState, useEffect } from 'react';
import {
  castServicio,
  getTipoRecibo,
  measuresServicios,
  validationsRecibo,
  HORA,
  recalcularPrecioDetalle,
  recalcularPrecioServicio,
} from '@utils/cotizaciones';
import { CbTableResponsive } from '@controls';
import CbTableBody from '@cbcomponents/CbTableBody';
import ModalImprimir from './ModalImprimir';
import getState from '@utils/state';
import { Card, Col, Container, Row } from 'react-bootstrap';
import TableHeaders from './components/TableHeaders';
import {
  useStoreSucursales,
  useStoreMonedas,
  useStoreMedidas,
  useStoreTiposRecibos,
  useStoreUsosCfdis,
  useStoreFormasPagos,
  useStoreMetodosPagos,
} from '@stores/catalogs.store';
import { mapDataDetallesRecibo } from '@utils/cotizaciones';
import useAuth from '@hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Index = ({ tipoCambio: tipoCambioInit }) => {
  const { addNotification: notification } = useStoreNotification();

  const navigate = useNavigate();
  const { agente } = useAuth();
  const [idCotizacion, setIdCotizacion] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [estatusRecibos] = useState([{ value: 'INICIADA', label: 'INICIADA' }]);

  const [state, setState] = useState({
    fecha: moment().format('YYYY-MM-DD'),
    fecha_vencimiento: moment().add(30, 'days').format('YYYY-MM-DD'),
    moneda: {
      enumerador: MONEDA.PESO,
      descripcion: 'PESO',
    },
    agenteId: null,
    maximo_descuento: 0,
    detalles: [],
    observaciones: '',
    estatus: { value: 'INICIADA', label: 'INICIADA' },
    sucursal: null,
    cliente: null,
    domicilios: [],
    domicilioId: null,
    anticipo: 0,
    tipoRecibo: null,
    tipoCambio: tipoCambioInit || 0,
  });

  const getStoreSucursales = useStoreSucursales();
  const getStoreTiposRecibos = useStoreTiposRecibos();
  const getStoreMonedas = useStoreMonedas();
  const getStoreMedidas = useStoreMedidas();
  const getStoreUsosCfdis = useStoreUsosCfdis();
  const getStoreFormasPagos = useStoreFormasPagos();
  const getStoreMetodosPagos = useStoreMetodosPagos();

  useEffect(() => {
    getStoreSucursales.execute();
    getStoreTiposRecibos.execute();
    getStoreMonedas.execute();
    getStoreMedidas.execute();
    getStoreUsosCfdis.execute();
    getStoreUsosCfdis.execute();
    getStoreFormasPagos.execute();
    getStoreMetodosPagos.execute();

    inicializar();
  }, []);
  const precios = state.detalles.map((detalle) => detalle?.precio).join('-');
  const cantidades = state.detalles.map((detalle) => detalle?.cantidad).join('-');

  useEffect(() => {
    recalcularTipoCambio();
  }, [state.moneda, state.tipoCambio]);

  useEffect(() => {
    recalcularTipoCambio();
  }, [precios, cantidades]);

  useEffect(() => {
    recalcularServicios();
  }, [state.sucursal]);

  useEffect(() => {
    actualizarNumeroLevantamiento();
  }, [state.tipoRecibo]);

  const inicializar = async () => {
    if (!agente) {
      notification({
        title: 'Información incompleta',
        message: 'Es necesario tener configurado el usuario como agente',
        type: 'error',
      });
      setTimeout(() => {
        navigate('/');
      }, 1000);
      return;
    }
    const tipoRecibo = getStoreTiposRecibos.data.find((x) => x.default);
    setState((prevState) => ({
      ...prevState,
      tipoRecibo,
      agente: agente,
      maximo_descuento: agente.maximo_descuento,
      sucursal: agente.sucursalId,
    }));
  };

  const actualizarNumeroLevantamiento = async () => {
    const { data } = await getRequest({
      url: `recibos/siguienteNumero`,
      params: { tipoReciboId: getTipoRecibo(state.tipoRecibo) },
    });
    setState((prevState) => ({
      ...prevState,
      numeroOrden: data.numero_recibo,
    }));
    setIsLoadingForm(false);
  };

  const onChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      ...getState(event.target),
    }));
  };

  const subirExcel = () => {
    setState((prevState) => ({
      ...prevState,
      showModalSubirExcel: true,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      numeroOrden,
      fecha,
      estatus,
      sucursal,
      moneda,
      tipoCambio,
      domicilioId,
      cliente,
      observaciones,
      detalles,
      agente,
      tipoRecibo,
      metodoPago,
      formaPago,
      usoCfdi,
    } = state;
    const data = {
      numero_recibo: numeroOrden,
      tipoReciboId: tipoRecibo?._id,
      fecha,
      estatus_recibo: estatus?.value,
      sucursalId: sucursal?._id,
      monedaId: moneda?.enumerador,
      metodoPagoId: metodoPago?._id,
      formaPagoId: formaPago?._id,
      usoCfdiId: usoCfdi?._id,
      tipoCambio,
      observaciones,
      clienteId: cliente?._id,
      agenteId: agente?._id,
      domicilioId: domicilioId?._id,
      detalles: mapDataDetallesRecibo(detalles),
    };

    const { success, message } = validationsRecibo(data);
    if (!success) {
      return notification({
        title: 'Validaciones',
        message,
        type: 'error',
      });
    }
    setIsLoadingForm(true);
    setIsLoading(true);
    postRequest({ url: `recibos`, body: data }).then(({ data: recibo }) => {
      setIdCotizacion(recibo._id);
      setShowModalDownload(true);
      setIsLoadingForm(false);
      setIsLoading(false);
    });
  };

  const onChangeDetalle = (index, detalle) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((x, i) => (i === index ? detalle : x))],
    }));
  };

  const recalcularTipoCambio = () => {
    const { moneda, tipoCambio } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((detalle) => recalcularPrecioDetalle(detalle, moneda, tipoCambio))],
    }));
  };

  const recalcularServicios = () => {
    const { sucursal } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.map((detalle) => recalcularPrecioServicio(detalle, sucursal))],
    }));
  };

  const onRemoveDetalle = (index) => {
    setState((prevState) => ({
      ...prevState,
      detalles: [...prevState.detalles.filter((x, i) => i !== index)],
    }));
  };

  const mismoServicio = (detalle, producto) => {
    return detalle.relacionadoId === producto.relacionadoId && detalle.measureId._id === producto.measureId._id;
  };

  const onSelectServicio = ({ servicio, listWorkForces, relacionadoId }) => {
    const { moneda, tipoCambio, detalles, sucursal } = state;
    const cantidad = listWorkForces.map((x) => x.cantidad).reduce((a, b) => a + b, 0);
    let listWorkForcesSettedNueva = detalles
      .map((d) => (d.relacionadoId == relacionadoId ? listWorkForces : d.listWorkForcesSetted))
      .filter((d) => d)
      .reduce((a, b) => a.concat(b), []);
    const nuevoValor = listWorkForcesSettedNueva.map((x) => Math.ceil(x.value * x.cantidad)).reduce((a, b) => a + b, 0);
    const measures = measuresServicios(getStoreMedidas.data);
    const producto = castServicio(servicio, moneda, tipoCambio, measures, sucursal);
    const hora = measures.find((y) => y.measure.toUpperCase() === HORA);
    if (hora) {
      producto.measureId = hora;
      producto.precio = (producto.listCost.find((y) => y.measureId === hora._id) || { cost: 0 }).cost;
      producto.precio_original = producto.precio;
    }
    let detallesNuevos = [
      ...detalles.map((d) => {
        if (d.relacionadoId === relacionadoId) {
          return {
            ...d,
            cantidad: cantidad,
            listWorkForcesSetted: listWorkForces,
            showModalWorkforce: false,
            tiene_servicio: true,
          };
        } else if (mismoServicio(d, producto)) {
          return {
            ...d,
            showModalWorkforce: false,
            cantidad: Number(nuevoValor),
          };
        }
        return d;
      }),
    ];
    const estaRegistrado = !!detallesNuevos.find((d) => mismoServicio(d, producto));
    if (!estaRegistrado) {
      detallesNuevos.push({
        ...producto,
        tipo_partida: { value: 'SERVICIO', label: 'SERVICIO' },
        cantidad: nuevoValor,
        descuento: 0,
        iva: producto.iva || 16,
        tiene_sustitutos: producto.listAlternos?.length > 0,
        tiene_otro_proveedor: producto.listCostosProveedores?.length > 1,
      });
    }
    setState((prevState) => ({
      ...prevState,
      detalles: [...detallesNuevos],
    }));
  };

  const closeDownload = () => {
    setShowModalDownload(false);
    setTimeout(() => navigate('/recibos'), 1000);
  };

  const { detalles, moneda, tipoCambio, observaciones } = state;

  return (
    <Module onClickBack={window.history.back} parent='Recibos' title='Nuevo recibo' loading={isLoadingForm}>
      <ModalImprimir showModal={showModalDownload} onClose={closeDownload} idCotizacion={idCotizacion} />
      <FormEncabezado
        numeroOrden={state.numeroOrden}
        fecha={state.fecha}
        fecha_vencimiento={state.fecha_vencimiento}
        estatus={state.estatus}
        sucursal={state.sucursal}
        moneda={state.moneda}
        cliente={state.cliente}
        tipoCambio={state.tipoCambio}
        estatus_recibos={estatusRecibos}
        domicilioId={state.domicilioId}
        domicilios={state.domicilios}
        agente={state.agente}
        anticipo={state.anticipo}
        tipoRecibo={state.tipoRecibo}
        formaPago={state.formaPago}
        usoCfdi={state.usoCfdi}
        metodoPago={state.metodoPago}
        setState={(obj) => setState((prevState) => ({ ...prevState, ...obj }))}
      />
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <BuscadorDetalles
              {...state}
              onAgregarDetalle={(detalle) => {
                setState((prevState) => ({ ...prevState, detalles: [...detalles, detalle] }));
              }}
              onLoadingTable={(isLoadingTable) => setIsLoadingTable(isLoadingTable)}
            />
          </Container>
        </Card.Body>
        {state.detalles && (
          <CbTableResponsive>
            <TableHeaders />
            <CbTableBody loading={isLoadingTable} colSpan={8}>
              {detalles.map((detalle, i) => (
                <RowDetalle
                  key={i}
                  moneda={moneda}
                  tipoCambio={tipoCambio}
                  sucursal={state.sucursal}
                  detalle={detalle}
                  onChangeDetalle={(detalle) => onChangeDetalle(i, detalle)}
                  onRemoveDetalle={() => onRemoveDetalle(i)}
                  onSelectServicio={onSelectServicio}
                />
              ))}
              <Totales colSpan={8} moneda={state.moneda} detalles={state.detalles} anticipo={state.anticipo} />
            </CbTableBody>
          </CbTableResponsive>
        )}
        <Card.Footer />
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col>
                <FormInput type='textarea' title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
                <Button onClick={subirExcel} className='btn btn-warning pull-right mr-5' disabled={isLoading}>
                  <Icon icon='open-file' /> Importar Excel
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <br />
    </Module>
  );
};

Index.propTypes = {
  tipoCambio: PropTypes.number,
};

export default Index;
