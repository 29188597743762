import If from '@controls/If';
import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import CompletarOrdenDeCompraConDetalles from './CompletarOrdenDeCompraConDetalles';
import Table from './Table';
import { Col, Row } from 'react-bootstrap';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import Select from '@components/forms/Select';

const List = () => {
  const [state, setState] = useState({
    list: [],
    loading: true,
    checkEnviarCopia: true,
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
    fechaInicio: moment().format('YYYY-MM-01'),
    fechaFin: moment().format('YYYY-MM-DD'),
    storehouses: [],
    clientesAEnviar: [],
    showModal: false,
    enviados: false,
    sucursalId: '0',
    sucursales: [],
    sucursal: null,
    sucursales_mostrar: [],
    estatus: [],
    estatus_solicitudes: {
      INICIADA: 'INICIADA',
      RECHAZADA: 'RECHAZADA',
      ACEPTADA: 'ACEPTADA',
      TERMINADA: 'TERMINADA',
    },
    estatus_solicitud: '0',
    agenteId: '0',
    agentes: [],
  });

  useEffect(() => {
    loadCatalogos(handleSearch);
  }, []);

  const loadCatalogos = async (callback) => {
    const {
      data: { sucursales, agentes },
    } = await getRequest({ url: `solicitudes-compras-catalogos` });
    setState((prevState) => ({
      ...prevState,
      sucursales: sucursales.map((sucursal) => ({
        value: sucursal._id,
        label: `${sucursal.name}`,
      })),
      sucursales_mostrar: [
        {
          value: '0',
          label: 'Todas los sucursales',
        },
        ...sucursales.map((sucursal) => ({
          value: sucursal._id,
          label: `${sucursal.name}`,
        })),
      ],
      agentes: [
        {
          value: '0',
          label: 'Todos los agentes',
        },
        ...agentes.map((agente) => ({
          value: agente._id,
          label: `${agente.nombre}`,
        })),
      ],
      estatus: [
        {
          value: '0',
          label: 'Todos los estatus',
        },
        ...Object.entries(prevState.estatus_solicitudes).map(([key, value]) => ({
          value: key,
          label: value,
        })),
      ],
    }));
    callback();
  };

  const onChangeList = (list) => {
    setState((prevState) => ({
      ...prevState,
      list,
    }));
  };

  const handleSearch = () => {
    const { pagination, activePage, search, fechaFin, fechaInicio, sucursalId, agenteId, estatus_solicitud } = state;
    const page = activePage * pagination - pagination;
    setState(
      (prevState) => ({ ...prevState, loading: true }),
      async () => {
        const body = {
          search,
          fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
          fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
          sucursalId,
          agenteId,
          estatus: estatus_solicitud,
        };
        const { data, count } = await postRequest({ url: `solicitudes-compras/search`, params: { page, limit: pagination }, body });
        setState((prevState) => ({
          ...prevState,
          list: data.map((x) => ({
            ...x,
            detalles: x.detalles.map((y) => ({
              ...y,
              check: false,
            })),
          })),
          count: count,
          loading: false,
        }));
      }
    );
  };

  const onSearch = (params = { search: '' }) => {
    setState(
      (prevState) => ({
        ...prevState,
        search: params.search,
        fechaInicio: params.fechaInicio,
        fechaFin: params.fechaFin,
        activePage: 1,
      }),
      handleSearch
    );
  };

  const handlePagination = (eventKey) => {
    setState(
      (prevState) => ({
        ...prevState,
        activePage: eventKey,
      }),
      handleSearch
    );
  };

  const renderList = () => {
    return <Table {...state} loadData={handleSearch} onChangeList={onChangeList} />;
  };

  const onSelect = (name, value) => {
    setState(
      (prevState) => ({
        ...prevState,
        [name]: value,
      }),
      () => {
        handlePagination(1);
      }
    );
  };

  const { pagination, count, activePage, loading, sucursalId, agenteId, estatus, estatus_solicitud } = state;

  return (
    <Module title='Solicitudes de compras'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col sm='3'>
            <IfPermission action='TodasSucursales'>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='home' title='sucursales' />
                </span>
                <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
              </div>
            </IfPermission>
          </Col>
          <Col sm='3'>
            <IfPermission action='TodosAgentes'>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='user' title='Agentes' />
                </span>
                <SelectAgente value={agenteId} onChange={(value) => onSelect('agenteId', value)} />
              </div>
            </IfPermission>
          </Col>
          <Col sm='3'>
            <div className='input-group'>
              <span className='input-group-text'>
                <Icon icon='tags' title='Estatus' />
              </span>
              <Select value={estatus_solicitud} options={estatus} onChange={(value) => onSelect('estatus_solicitud', value)} />
            </div>
          </Col>
          <Col sm='3'>
            <Link to='/solicitudes-compras/nueva' className='btn btn-success pull-right'>
              <Icon icon='plus' /> Nueva
            </Link>
            <CompletarOrdenDeCompraConDetalles
              loadData={handleSearch}
              detalles={state.list.reduce((a, b) => [...a, ...b.detalles.filter((x) => x.check)], [])}
              btnClass='btn btn-sm btn-default mr-5'
            />
          </Col>
        </Row>
      </Search>
      <If condition={!loading}>
        <If.Then>
          <form>
            {renderList()}
            <div className='text-center'>
              <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
            </div>
          </form>
        </If.Then>
        <If.Else>
          <br />
          <Loading />
        </If.Else>
      </If>
    </Module>
  );
};

List.propTypes = {};

export default List;
