import React from 'react';
import List from './list/List';

const RecepcionCompras = (props) => {
  return <List {...props} />;
};

RecepcionCompras.propTypes = {};

export default RecepcionCompras;
