import PropTypes from 'prop-types';
import React from 'react';

const CbTableResponsive = ({ children }) => {
  return (
    <div className='table-responsive table-min-heigth'>
      <table className='table table-condensed table-hover dataTable table-with-row-buttons'>{children}</table>
    </div>
  );
};

CbTableResponsive.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CbTableResponsive;
