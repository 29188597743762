import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput, Input } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, Fragment } from 'react';
import { Icon, Loading, Module, RowsNotFound, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import AgregarDetalle from '../pedidos/AgregarDetalle';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';
import InputNumber from '@components/forms/InputNumber';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import Select from '@components/forms/Select';

const New = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();

  const [state, setState] = useState({
    reset: false,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fechaEntrega: moment().format('YYYY-MM-DD'),
    ubicaciones: [],
    monedaId: MONEDA.DOLAR,
    sucursales: [],
    almacenes: [],
    monedas: [],
    measures: [],
    providers: [],
    storehouses: [],
    proveedores: [],
    proveedorObj: null,
    proveedorName: '',
    detalles: [],
    usos_cfdi: [],
    usoCfdiId: null,
    subtotal: 0,
    total: 0,
    impuestos: 0,
    descuento: 0,
    observaciones: '',
    estatus: 'FINALIZADA',
    sucursalId: null,
    almacenId: null,
    proveedor: null,
    productoName: '',
    productoObj: null,
    productos: [],
    tipoCambio: 0,
    existencias: [],
    buscar: '',
    estatus_mermas: [
      { value: 'INICIADA', label: 'INICIADA' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'FINALIZADA', label: 'FINALIZADA' },
    ],
    showModalAgregar: false,
    bloquesMostrar: 5,
  });

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { numero_merma },
      } = await getRequest({ url: `mermas/siguienteNumero` });
      const {
        data: { sucursales, almacenes, agente },
      } = await getRequest({ url: `mermas-catalogos` });
      setState((prevState) => ({
        ...prevState,
        sucursales: sucursales.map((sucursal) => ({
          value: sucursal._id,
          label: `${sucursal.name}`,
        })),
        agenteObj: { value: agente._id, label: agente.nombre },
        agenteId: agente._id,
        agenteName: agente.nombre,
        sucursalId: agente.sucursalId,
        almacenId: agente.almacenId,
        almacenes,
        isLoadingForm: false,
        numeroOrden: numero_merma,
      }));
    };
    fetchData();
  }, []);

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.estatus_merma + '')) {
      validate.success = false;
      validate.message = 'Estatus es requerido.';
    } else if (!isMongoId(params.sucursalId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    } else if (!isMongoId(params.almacenId + '')) {
      validate.success = false;
      validate.message = 'Almácen es requerido.';
    } else if (!isMongoId(params.agenteId + '')) {
      validate.success = false;
      validate.message = 'Agente es requerido.';
    } else if (params.detalles.length === 0) {
      validate.success = false;
      validate.message = 'No hay partidas asociados al merma';
    }

    params.detalles.forEach((detalle) => {
      if (isEmpty(detalle.descripcion + '')) {
        validate.success = false;
        validate.message = 'Descripción es requerido.';
      } else if (isEmpty(detalle.cantidad + '') || detalle.cantidad <= 0) {
        validate.success = false;
        validate.message = 'Cantidad es requerido.';
      } else if (detalle.cantidad > detalle.existencia) {
        validate.success = false;
        validate.message = 'Cantidad, debe ser menor o igual a la existencia.';
      } else if (isEmpty(detalle.precio + '') || detalle.precio <= 0) {
        validate.success = false;
        validate.message = 'Precio es requerido.';
      } else if (!isMongoId(detalle.ubicacionId + '')) {
        validate.success = false;
        validate.message = 'Ubicación es requerido.';
      }
    });

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));
    if (campo === 'sucursalId') {
      setState((prevState) => ({
        ...prevState,
        almacenId: null,
      }));
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { numeroOrden, fecha, estatus, sucursalId, almacenId, agenteId, observaciones, detalles } = state;

    const data = {
      numero_merma: numeroOrden,
      fecha,
      estatus_merma: estatus,
      sucursalId,
      almacenId,
      agenteId,
      observaciones,
      detalles: detalles.map((detalle) => ({
        ...detalle,
      })),
    };
    if (validations(data)) {
      await postRequest({ url: `mermas`, body: data });
      setTimeout(() => navigate(0), 1000);
    }
  };

  const onChangeDetalle = (name, index, event) => {
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => {
        if (i === index) {
          x[name] = name === 'cantidad' ? cleanNumber(event.target.value) : event.target.value;
        }
        return { ...x };
      }),
    }));
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        agente: itemSelect,
        agenteId: itemSelect._id,
        agenteObj: itemSelect,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        agenteName: '',
        agenteId: null,
        agente: null,
        agenteObj: null,
      }));
    }
  };

  const onClickRemoveDetalle = (index, event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.filter((_, i) => i !== index),
    }));
  };

  const renderDetalles = () => {
    if (state.detalles.length === 0) return <RowsNotFound message='No se han agregado detalles.' colSpan={8} />;
    return state.detalles.map((detalle, i) => (
      <tr key={i}>
        <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.noIdentificador}</th>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
          <Input className=' input-sm' value={detalle.descripcion} style={{ width: '250px' }} />
        </td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.measure}</td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.existencia}</td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
          <InputNumber
            style={{ width: '80px' }}
            className=' input-sm '
            value={detalle.cantidad}
            onChange={(e) => onChangeDetalle('cantidad', i, e)}
            name='cantidad'
          />
        </td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.ubicacion}</td>
        <td>
          <a href='#' onClick={(e) => onClickRemoveDetalle(i, e)} style={{ color: 'red' }}>
            <Icon icon='remove' />
          </a>
        </td>
      </tr>
    ));
  };

  const renderView = () => {
    const { numeroOrden, fecha, estatus, sucursalId, almacenId, agenteObj, isLoading, observaciones, estatus_mermas } = state;
    return (
      <Fragment>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-4'>
                <FormInput
                  title='Número de orden'
                  placeholder='Número de orden'
                  disabled
                  name='numeroOrden'
                  onChange={onChange}
                  value={numeroOrden}
                />
              </div>
              <div className='col-sm-4'>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </div>
              <div className='col-sm-4'>
                <DivFormGroup name='estatus' required title='Estatus'>
                  <Select name='estatus' value={estatus} disabled options={estatus_mermas} onChange={(value) => onSelect('estatus', value)} />
                </DivFormGroup>
              </div>
              <div className='col-sm-4'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} name='sucursalId' />
                </DivFormGroup>
              </div>
              <div className='col-sm-4'>
                <DivFormGroup name='almacenId' required title='Almacen'>
                  <SelectAlmacen
                    value={almacenId}
                    onChange={(value) => onSelect('almacenId', value)}
                    name='almacenId'
                    filter={(x) => x.sucursalId === sucursalId?._id}
                  />
                </DivFormGroup>
              </div>
              <div className='col-sm-4'>
                <DivFormGroup name='agenteName' required title='Agente'>
                  <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                </DivFormGroup>
              </div>
            </div>
          </div>
          <div className='panel-footer'>
            <div className='row'>
              <div className='col-sm-12'>
                <AgregarDetalle onAgregarDetalle={onAgregarDetalle} almacenId={almacenId} />
              </div>
            </div>
          </div>
        </div>
        <div className='panel panel-default panel-table'>
          <div className='table-responsive'>
            <table className='table table-condensed table-hover table-striped '>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Descripción</th>
                  <th>Unidad</th>
                  <th>Existencia</th>
                  <th>Cantidad</th>
                  <th>Ubicación</th>
                  <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                </tr>
              </thead>
              <tbody>{renderDetalles()}</tbody>
            </table>
          </div>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </div>
            </div>
          </div>
          <div className='panel-footer'>
            <div className='row'>
              <div className='col-sm-12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const onAgregarDetalle = (existencias) => {
    const { detalles } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [
        ...detalles,
        ...existencias.map((itemSelect) => {
          const producto = itemSelect.equipmentId;
          let measure = null,
            measureId = null;
          measure =
            producto.equipmentsMeasureId && producto.equipmentsMeasureId.measure
              ? producto.equipmentsMeasureId.measure
              : producto.equipmentsMeasureId;
          measureId =
            producto.equipmentsMeasureId && producto.equipmentsMeasureId._id ? producto.equipmentsMeasureId._id : producto.equipmentsMeasureId;
          return {
            ...producto,
            equipmentId: producto._id,
            descripcion: producto.name.toUpperCase(),
            noIdentificador: producto.code,
            measure,
            measureId,
            ubicacionOrigen: itemSelect.ubicacionId.nombre,
            ubicacionOrigenId: itemSelect.ubicacionId._id,
            existenciaId: itemSelect._id,
            existencia: itemSelect.stock,
            cantidad: 1,
            listaExistencias: itemSelect.listaExistencias,
          };
        }),
      ],
    }));
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ordenes de mermas' title='Nueva orden de merma'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

New.propTypes = {};

export default New;
