import React, { useState, useRef } from 'react';
import { ProgressBar, Row, Col } from 'react-bootstrap';
import { Icon, Module, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';

const Import = () => {
  const [state, setState] = useState({
    tipo: 'N',
    isLoading: false,
    cargando: false,
    csv: null,
    valid: false,
    msg: '',
    progress: 0,
  });

  const frm = useRef(null);

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'tipo') {
      setState({ ...state, [name]: value, csv: null, valid: false, msg: '', progress: 0 });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleReadCSV = async (event) => {
    event.preventDefault();

    setState({
      ...state,
      cargando: true,
      progress: 0,
      csv: null,
      isLoading: true,
    });

    const form = new FormData(frm.current);

    let tipo = '';
    switch (state.tipo) {
      case 'N':
        tipo = 'importar/csv';
        break;
      case 'E':
        tipo = 'importar/exportado-csv';
        break;
      case 'C':
        tipo = 'importar/exportado-costos-csv';
        break;
      case 'CP':
        tipo = 'importar/exportado-costos-promedio-csv';
        break;
      case 'RP':
        tipo = 'importar/exportado-productos-nuevos-csv';
        break;
      case 'PE':
        tipo = 'importar/exportado-productos-existencias';
        break;
      case 'PI':
        tipo = 'importar/exportado-productos-existencias-iniciales';
        break;
      case 'WF':
        tipo = 'importar/exportado-productos-workforces';
        break;
      case 'PC':
        tipo = 'importar/exportado-productos-clasificaciones';
        break;
      case 'ES':
        tipo = 'importar/exportado-precios-estaticos-csv';
        break;
      case 'TC':
        tipo = 'importar/exportado-tipos-cotizaciones-csv';
        break;
    }

    const { csv, valid, msg } = await postUploadRequest({ url: `equipments/${tipo}`, form });
    setState({ ...state, csv, valid, msg, cargando: false, progress: 0, isLoading: false });
    frm.current.reset();
  };

  const renderDataCSV = () => {
    const {
      csv: { detalle },
      valid,
      msg,
    } = state;
    let headers = [];
    if (detalle.length > 0) headers = Object.keys(detalle[0]);
    return (
      <Row>
        {!valid && (
          <div className='alert alert-danger'>
            <p>{msg}</p>
          </div>
        )}
        <Col sm='12'>
          <CbPanelTableResponsive>
            <thead>
              <tr>
                <th>
                  <strong>Número</strong>
                </th>
                <th>
                  <strong>Acción</strong>
                </th>
                {headers.map((e, i) => {
                  return (
                    <th key={i}>
                      <strong>{e}</strong>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <CbTableBody colSpan={headers.length + 2}>
              {detalle.map((d, i) => {
                let datoCreacion = 0;
                return (
                  <>
                    <tr key={i} className={d[datoCreacion] === true ? 'success' : d[datoCreacion] === false ? 'info' : ''}>
                      <td>{i + 1}</td>
                      <td>{d[datoCreacion] === true ? 'Creación' : d[datoCreacion] === false ? 'Actualización' : 'Ninguna'}</td>
                      {headers.map((e, j) => {
                        return <td key={j}>{d[e]}</td>;
                      })}
                    </tr>
                  </>
                );
              })}
            </CbTableBody>
          </CbPanelTableResponsive>
        </Col>
      </Row>
    );
  };

  const { cargando, isLoading, csv, progress, tipo } = state;

  return (
    <Module onClickBack={window.history.back} parent='Equipos' title='Importar equipos'>
      <br />
      <form ref={frm} onSubmit={handleReadCSV} encType='multipart/form-data'>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col sm='12'>
                <div className='form-group inline'>
                  <label>Tipo archivo</label>
                  <div>
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} defaultChecked={true} value={'N'} checked={tipo === 'N'} /> Normal
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'E'} checked={tipo === 'E'} /> Exportación
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'C'} checked={tipo === 'C'} /> Costos
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'CP'} checked={tipo === 'CP'} /> Costos promedios
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'WF'} checked={tipo === 'WF'} /> Horas de trabajo
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'RP'} checked={tipo === 'RP'} /> Productos nuevos
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'PE'} checked={tipo === 'PE'} /> Existencia de productos
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'PI'} checked={tipo === 'PI'} /> Existencia iniciales de productos
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'PC'} checked={tipo === 'PC'} /> Clasificaciones de productos
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'ES'} checked={tipo === 'ES'} /> Precios estaticos
                    </label>
                    <br />
                    <label className='radio-inline'>
                      <input type='radio' name='tipo' onChange={onChange} value={'TC'} checked={tipo === 'TC'} /> Tipos cotizaciones
                    </label>
                    <br />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm='12' className='text-center'>
                <div className='btn btn-default btn-file mr-5'>
                  <Icon icon='paperclip' /> Archivo
                  <input type='file' name='csv' id='csv' accept='.xls,.csv,.xlsx' onChange={handleReadCSV} />
                </div>
                {isLoading ? <Saving action='Importantando' /> : ''}
              </Col>
              <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
            </Row>
          </div>
        </div>
      </form>
      {csv !== null ? renderDataCSV() : null}
    </Module>
  );
};

Import.propTypes = {};

export default Import;
