import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import Select from '@components/forms/Select';

const Edit = ({ formapagoId, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [forma, setForma] = useState('');
  const [card, setCard] = useState(false);
  const [tipo, setTipo] = useState('');
  const [tiposFormasPago] = useState([
    { value: 'EQUIPO', label: 'EQUIPO' },
    { value: 'SERVICIO', label: 'SERVICIO' },
  ]);
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setForma('');
    setCard(false);
    setTipo(null);
  };

  const open = async () => {
    const {
      data: { forma, card, tipo },
    } = await getRequest({ url: `formapago/${formapagoId}` });
    setShowModal(true);
    setForma(forma);
    setCard(card);
    setTipo(tipo);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      forma: forma.trim(),
      card: card === 'true' ? true : false,
      tipo,
    };

    if (validations(data)) {
      await putRequest({ url: `formapago/${formapagoId}`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.forma + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.tipo + '')) {
      validate.success = false;
      validate.message = 'Tipo es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar ficha técnica' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col sm='8'>
            <DivFormGroup name='forma' title='Nombre' required>
              <Input name='forma' placeholder='Nombre' onChange={(e) => setForma(e.target.value)} defaultValue={forma} />
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='card' title='Cargo a tarjeta' required>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='card' onChange={() => setCard(true)} value={true} defaultChecked={card === 'true' || card === true} /> Si
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='card' onChange={() => setCard(false)} value={false} defaultChecked={card === 'false' || card === false} />{' '}
                  No
                </label>
              </div>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='tipo' required title='Tipo'>
              <Select name='tipo' value={tipo} options={tiposFormasPago} onChange={setTipo} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  formapagoId: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Edit;
