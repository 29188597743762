import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteEquipment = ({ equipmentId, name, loadEquipments }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`equipments/${equipmentId}`}
      description={`¿Confirma que desea eliminar el equipo <strong><u> ${name}</u> </strong>?`}
      loadData={() => {
        loadEquipments({ page: 0, pagination: 10 });
      }}
    />
  );
};

DeleteEquipment.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  equipmentId: PropTypes.string.isRequired,
  loadEquipments: PropTypes.func.isRequired,
  btnClass: PropTypes.string.isRequired,
};

export default DeleteEquipment;
