import { TIPOS_DOMICILIOS } from '@config/constants';
import { DivFormGroup, FilterSelectNuevo, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import Select from 'react-select';
import { getRequest, postRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ domicilioId, refresh, listPaises, listEstados, listMunicipios }) => {
  const { addNotification: notification } = useStoreNotification();

  const [state, setState] = useState({
    maximo_descuento: 0,
    showModal: false,
    isLoading: false,
    name: '',
    tipos: [],
    paterno: '',
    materno: '',
    phone: '',
    mobile: '',
    email: '',
    sexo: '',
    fecha_nacimiento: null,
    customerId: '',
    optionsMinicipios: [],
    paisId: '',
    estadoId: '',
    municipio: '',
    nombre: '',
    monitoreo: '',
    calle: '',
    exterior: '',
    interior: '',
    localidad: '',
    colonia: '',
    coloniaId: '',
    coloniaObject: null,
    codigo_postal: '',
    pais: '',
    tipo: '',
    calle1: '',
    calle2: '',
    referencias: '',
    coloniaIdOriginal: '',
    es_entrega: false,
    es_fiscal: false,
    position: { lat: '', lng: '' },
    ubicacion: '',
  });

  const refColonia = useRef(null);
  let typingCodigoPostal = null;

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      condicion: '',
      nombre: '',
    }));
  };

  const open = async () => {
    const {
      data: {
        customerId,
        nombre,
        monitoreo,
        calle,
        exterior,
        interior,
        localidad,
        colonia,
        coloniaId,
        codigo_postal,
        paisId,
        estadoId,
        estado,
        latitud,
        longitud,
        ubicacion,
        municipio,
        municipioId,
        pais,
        tipo,
        es_entrega,
        es_fiscal,
        entre_calles: { calle1, calle2 },
        referencias,
      },
    } = await getRequest({ url: `domicilios/${domicilioId}` });

    setState({
      showModal: true,
      customerId,
      optionsMinicipios: [],
      paisId,
      estadoId: {
        estadoId,
        estado,
      },
      municipio: {
        estadoId,
        clave: municipioId,
        nombre: municipio,
      },
      nombre,
      monitoreo,
      calle,
      exterior,
      interior,
      localidad,
      colonia: colonia,
      coloniaId: coloniaId ? coloniaId._id : coloniaId,
      coloniaObject: {
        label: coloniaId ? coloniaId.d_asenta : colonia,
        value: coloniaId ? coloniaId._id : coloniaId,
      },
      codigo_postal,
      pais,
      tipo,
      calle1,
      calle2,
      referencias,
      coloniaIdOriginal: coloniaId,
      es_entrega,
      es_fiscal,
      position: {
        lat: latitud,
        lng: longitud,
      },
      ubicacion,
      isLoading: false,
    });
  };

  const onChangeColoniaInput = (value) => {
    setState((prevState) => ({ ...prevState, colonia: value }));
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeColonia = (itemSelect) => {
    if (itemSelect !== null) {
      const { row } = itemSelect;
      setState((prevState) => ({
        ...prevState,
        colonia: row.d_asenta,
        coloniaId: row._id,
        coloniaObject: {
          label: row.d_asenta,
          value: row._id,
        },
        codigo_postal: row.d_codigo,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        colonia: '',
        coloniaId: '',
        coloniaObject: null,
      }));
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const {
      customerId,
      calle,
      calle1,
      calle2,
      codigo_postal,
      coloniaId,
      es_entrega,
      es_fiscal,
      estadoId,
      exterior,
      interior,
      localidad,
      tipo,
      referencias,
      nombre,
      monitoreo,
    } = state;
    const data = {
      customerId,
      calle,
      calle1,
      calle2,
      codigo_postal,
      coloniaId,
      es_entrega,
      es_fiscal,
      estadoId,
      exterior,
      interior,
      localidad,
      tipo,
      referencias,
      nombre,
      monitoreo,
    };

    if (validations(data)) {
      await putRequest({ url: `domicilios/${domicilioId}`, body: data });
      refresh();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.calle + '')) {
      validate.success = false;
      validate.message = 'Calle es requerido.';
    } else if (isEmpty(params.colonia + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.phone + '')) {
      validate.success = false;
      validate.message = 'Teléfono es requerido.';
    } else if (isEmpty(params.email + '')) {
      validate.success = false;
      validate.message = 'Correo electrónico es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onChangeCodigoPostal = (event) => {
    const { name, value } = event.target;
    if (typingCodigoPostal) clearTimeout(typingCodigoPostal);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    typingCodigoPostal = setTimeout(() => {
      onSelectCodigoPostal(value);
    }, 300);
  };

  const onSelectCodigoPostal = async (cp) => {
    if (cp.length === 5) {
      const { data } = await postRequest({ url: `colonia/codigo-postal`, body: { search: cp } });
      if (data.length > 0) {
        let colonia = data[0];
        const municipio = colonia && colonia.municipio_id;
        const estado = municipio && municipio.estadoId;
        const pais = estado && estado.paisId;
        if (data.length > 0) {
          let colonia = data[0];
          const municipio = colonia.municipio_id;
          const estado = municipio ? municipio.estadoId : null;
          setState((prevState) => ({
            ...prevState,
            paisId: colonia.paisId,
            estadoId: estado,
            municipio: municipio,
            localidad: colonia.d_ciudad,
            pais,
          }));
          if (refColonia.current) setTimeout(() => refColonia.current.inputRef.focus(), 300);
        }
      }
    }
  };

  const {
    showModal,
    isLoading,
    nombre,
    monitoreo,
    calle,
    exterior,
    interior,
    localidad,
    colonia,
    calle1,
    calle2,
    referencias,
    paisId,
    codigo_postal,
    municipio,
    estadoId,
    tipo,
    coloniaObject,
    es_entrega,
    es_fiscal,
  } = state;

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <DivFormGroup>
              <div className='form-check'>
                <label>Tipos de contacto</label>
                <div>
                  <label className='radio-inline'>
                    <input
                      type='radio'
                      name='tipo'
                      id='rdo-principal'
                      onChange={(e) => onSelect('tipo', e.target.value)}
                      value={TIPOS_DOMICILIOS.PRINCIPAL}
                      defaultChecked={tipo === TIPOS_DOMICILIOS.PRINCIPAL ? true : false}
                    />{' '}
                    Principal
                  </label>
                  <label className='radio-inline'>
                    <input
                      type='radio'
                      name='tipo'
                      id='rdo-sucursal'
                      onChange={(e) => onSelect('tipo', e.target.value)}
                      value={TIPOS_DOMICILIOS.SUCURSAL}
                      defaultChecked={tipo === TIPOS_DOMICILIOS.SUCURSAL ? true : false}
                    />{' '}
                    Sucursal
                  </label>
                </div>
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <div className='form-check'>
                <label>&nbsp;</label>
                <div>
                  <label className='checkbox-inline'>
                    <input type='checkbox' name='es_fiscal' checked={es_fiscal} onChange={(e) => onSelect('es_fiscal', e.target.checked)} />
                    Fiscal
                  </label>
                </div>
                <br />
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <div className='form-check'>
                <label>&nbsp;</label>
                <div>
                  <label className='checkbox-inline'>
                    <input type='checkbox' name='es_entrega' checked={es_entrega} onChange={(e) => onSelect('es_entrega', e.target.checked)} />
                    Entrega
                  </label>
                </div>
                <br />
              </div>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='8'>
            <DivFormGroup name='nombre' title='Nombre domicilio'>
              <Input name='nombre' onChange={(e) => onSelect('nombre', e.target.value)} value={nombre} />
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='monitoreo' title='Monitoreo'>
              <Input name='monitoreo' onChange={(e) => onSelect('monitoreo', e.target.value)} value={monitoreo} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='4'>
            <DivFormGroup name='calle' title='Calle' required>
              <Input name='calle' onChange={(e) => onSelect('calle', e.target.value)} value={calle} />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='exterior' title='Número Ext' required>
              <Input name='exterior' onChange={(e) => onSelect('exterior', e.target.value)} value={exterior} placeholder='Exterior' />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='interior' title='Número Int'>
              <Input name='interior' onChange={(e) => onSelect('interior', e.target.value)} value={interior} placeholder='Interior' />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='calle1' title='Entre calle'>
              <Input name='calle1' onChange={(e) => onSelect('calle1', e.target.value)} value={calle1} placeholder='Entre calle' />
            </DivFormGroup>
          </Col>
          <Col sm='2'>
            <DivFormGroup name='calle2' title='Y calle'>
              <Input name='calle2' onChange={(e) => onSelect('calle2', e.target.value)} value={calle2} placeholder='Y Calle' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <DivFormGroup name='pais' title='País' required>
              <Select
                value={paisId}
                name='paisId'
                placeholder='Selecciona...'
                options={listPaises}
                getOptionLabel={(p) => p.pais}
                getOptionValue={(p) => p._id}
                onChange={(value) => onSelect('paisId', value)}
                className='basic-multi-select'
                classNamePrefix='select'
              />
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup name='estado' title='Estado' required>
              <Select
                value={estadoId}
                name='estadoId'
                placeholder='Selecciona...'
                options={listEstados.filter((x) => paisId && x.paisId === paisId._id)}
                getOptionLabel={(p) => p.estado}
                getOptionValue={(p) => p.estadoId}
                onChange={(value) => onSelect('estadoId', value)}
                className='basic-multi-select'
                classNamePrefix='select'
              />
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup name='municipio' title='Municipio' required>
              <Select
                value={municipio}
                name='municipio'
                placeholder='Selecciona...'
                getOptionLabel={(p) => p.nombre}
                getOptionValue={(p) => p.clave}
                options={listMunicipios.filter((x) => estadoId && x.estadoId && x.estadoId.estadoId === estadoId.estadoId)}
                onChange={(value) => onSelect('municipio', value)}
                className='basic-multi-select'
                classNamePrefix='select'
              />
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup name='colonia' title='Colonia' required>
              <FilterSelectNuevo
                async={true}
                inputValue={colonia}
                onInputChange={onChangeColoniaInput}
                disabled={municipio !== '' && estadoId !== '' ? false : true}
                value={coloniaObject}
                onChangeOption={onChangeColonia}
                url={`colonias/autocomplete/${municipio && municipio.clave}/${estadoId && estadoId.estadoId}`}
                innerRef={refColonia}
                fields={['_id', 'd_asenta']}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='codigo_postal' title='Código Postal' required>
              <Input name='codigo_postal' onChange={onChangeCodigoPostal} value={codigo_postal} maxLength={5} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='localidad' title='Localidad'>
              <Input name='localidad' onChange={(e) => onSelect('localidad', e.target.value)} value={localidad} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='referencias' title='Referencias de ubicación'>
              <Input name='referencias' onChange={(e) => onSelect('referencias', e.target.value)} value={referencias} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  domicilioId: PropTypes.number.isRequired,

  refresh: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  listPaises: PropTypes.array.isRequired,
  listEstados: PropTypes.array.isRequired,
  listMunicipios: PropTypes.array.isRequired,
};

export default Edit;
