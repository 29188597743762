import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { clean } from '@utils/formatter';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const Edit = ({ itemId, loadData, controles, piezas, fabricantes }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [serie, setSerie] = useState('');
  const [nombre, setNombre] = useState('');
  const [controlMantenimientoId, setControlMantenimientoId] = useState('');
  const [tipoPiezaId, setTipoPiezaId] = useState('');
  const [fabricanteId, setFabricanteId] = useState('');
  const [minimo, setMinimo] = useState(0);
  const [maximo, setMaximo] = useState(0);
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setSerie('');
    setNombre('');
    setControlMantenimientoId('');
    setTipoPiezaId('');
    setFabricanteId('');
    setMinimo(0);
    setMaximo(0);
  };

  const open = async () => {
    const {
      data: {
        serie,
        nombre,
        controlMantenimientoId,
        tipoPiezaId,
        fabricanteId,
        stock: { minimo, maximo },
      },
    } = await getRequest({ url: `piezas/${itemId}` });
    setShowModal(true);
    setSerie(serie);
    setNombre(nombre);
    setControlMantenimientoId(controlMantenimientoId);
    setTipoPiezaId(tipoPiezaId);
    setFabricanteId(fabricanteId);
    setMinimo(minimo);
    setMaximo(maximo);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      serie: serie.trim(),
      nombre: nombre.trim(),
      minimo: clean(minimo),
      maximo: clean(maximo),
      controlMantenimientoId,
      tipoPiezaId,
      fabricanteId,
    };

    if (validations(data)) {
      await putRequest({ url: `piezas/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.serie + '')) {
      validate.success = false;
      validate.message = 'Nº Parte es requerido.';
    } else if (validate.success && isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre de la Pieza es requerido.';
    } else if (validate.success && isEmpty(params.controlMantenimientoId + '')) {
      validate.success = false;
      validate.message = 'Control de Mantenimiento es requerido.';
    } else if (validate.success && isEmpty(params.tipoPiezaId + '')) {
      validate.success = false;
      validate.message = 'Tipo es requerido.';
    } else if (validate.success && isEmpty(params.fabricanteId + '')) {
      validate.success = false;
      validate.message = 'Fabricante es requerido.';
    } else if (validate.success && !isNaN(params.minimo) && params.minimo <= 0) {
      validate.success = false;
      validate.message = 'Stock Mínimo es requerido.';
    } else if (validate.success && !isNaN(params.maximo) && params.maximo <= 0) {
      validate.success = false;
      validate.message = 'Stock Máximo es requerido.';
    } else if (validate.success && (params.minimo === params.maximo || params.minimo >= params.maximo)) {
      validate.success = false;
      validate.message = 'Favor de verificar los stock.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='4'>
            <DivFormGroup name='serie' title='Nº Parte'>
              <Input name='serie' onChange={(e) => setSerie(e.target.value)} defaultValue={serie} />
            </DivFormGroup>
          </Col>
          <Col sm='12'>
            <DivFormGroup name='nombre' title='Nombre'>
              <Input name='nombre' onChange={(e) => setNombre(e.target.value)} defaultValue={nombre} />
            </DivFormGroup>
            <DivFormGroup name='controlMantenimientoId' title='Control de Mantenimiento'>
              <select
                id='controlMantenimientoId'
                name='controlMantenimientoId'
                className='form-control'
                onChange={(e) => setControlMantenimientoId(e.target.value)}
                defaultValue={controlMantenimientoId}
              >
                <option value=''>Seleccione</option>
                {controles.map((c, i) => (
                  <option key={i} value={c._id}>
                    {c.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
            <DivFormGroup name='tipoPiezaId' title='Tipo'>
              <select
                id='tipoPiezaId'
                name='tipoPiezaId'
                className='form-control'
                onChange={(e) => setTipoPiezaId(e.target.value)}
                defaultValue={tipoPiezaId}
              >
                <option value=''>Seleccione</option>
                {piezas.map((c, i) => (
                  <option key={i} value={c._id}>
                    {c.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
            <DivFormGroup name='fabricanteId' title='Fabricante'>
              <select
                id='fabricanteId'
                name='fabricanteId'
                className='form-control'
                onChange={(e) => setFabricanteId(e.target.value)}
                defaultValue={fabricanteId}
              >
                <option value=''>Seleccione</option>
                {fabricantes.map((c, i) => (
                  <option key={i} value={c._id}>
                    {c.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='12'>
            <legend>Stock</legend>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='minimo' title='Mínimo'>
              <InputNumber name='minimo' className='form-control' value={minimo} onChange={(e) => setMinimo(e.target.value)} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='maximo' title='Máximo'>
              <InputNumber id='maximo' name='maximo' className='form-control' value={maximo} onChange={(e) => setMaximo(e.target.value)} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  controles: PropTypes.array.isRequired,
  piezas: PropTypes.array.isRequired,
  fabricantes: PropTypes.array.isRequired,
};

export default Edit;
