import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { format } from '@utils/parseCost';
import Concepto from './Concepto';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetalleNotaCredito = () => {
  const { pagoId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `pago/${pagoId}` });
      setData(data);
    };

    fetchData();
  }, [pagoId]);

  const renderDetalle = () => {
    const { detalle } = data;

    return (
      <table className='table table-condensed'>
        <thead>
          <tr>
            <th>Folio</th>
            <th>Id Documento</th>
            <th>Metodo pago</th>
            <th>Moneda</th>
            <th>Tipo cambio</th>
            <th># Parcialidad</th>
            <th>Saldo anterior</th>
            <th>Imp. pagado</th>
            <th>Saldo Insoluto</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {detalle.length > 0 ? (
            detalle.map((d, i) => {
              const {
                facturaId,
                metodo_pago_sat,
                numero_parcialidad,
                serie,
                folio,
                id_documento,
                importe_pagado,
                importe_saldo_anterior,
                importe_saldo_insoluto,
                moneda,
                tipo_cambio,
              } = d;
              return (
                <tr key={i}>
                  <td>{serie ? `${serie}-${folio}` : folio}</td>
                  <td>{id_documento}</td>
                  <td>{metodo_pago_sat ? metodo_pago_sat.descripcion : null}</td>
                  <td>{moneda}</td>
                  <td>{tipo_cambio}</td>
                  <td>{numero_parcialidad}</td>
                  <td>${format(importe_saldo_anterior, 2)}</td>
                  <td>${format(importe_pagado, 2)}</td>
                  <td>${format(importe_saldo_insoluto, 2)}</td>
                  <td>
                    {facturaId ? (
                      <Link to={`/facturas/detalle/${facturaId}`} className='btn btn-sm btn-primary'>
                        <Icon icon='search' />
                      </Link>
                    ) : null}
                  </td>
                </tr>
              );
            })
          ) : (
            <RowsNotFound message='Sin detalle' colSpan={11} />
          )}
        </tbody>
      </table>
    );
  };

  if (!data) {
    return <Module title='Loading...' onClickBack={window.history.back} loading />;
  }

  const {
    encabezado: {
      serie,
      folio,
      uuid,
      fecha,
      fecha_pago,
      monto,
      moneda,
      forma_pago_sat,
      customerId,
      tipo_cambio,
      numero_operacion,
      rfc_emisor_cta_ben,
      rfc_emisor_cta_ord,
      cta_benificiario,
      cta_ordenante,
    },
  } = data;
  const cliente = renderNombreCliente(customerId);

  return (
    <Module title={cliente} onClickBack={window.history.back} loading={!data}>
      <Row>
        <Col>
          <div className='panel panel-primary'>
            <div className='panel-heading'>
              <h3 className='panel-title'>Pago</h3>
            </div>
            <div className='panel-body'>
              <Row>
                <Col sm='9'>
                  <Concepto label='Serie' valor={serie} />
                  <Concepto label='Número' valor={folio} />
                  <Concepto label='Folio Fiscal' valor={uuid} />
                  <Concepto label='Fecha' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                  <Concepto label='Fecha Pago' valor={moment(fecha_pago).local().format('DD/MM/YYYY')} />
                </Col>
                <Col sm='3'>
                  <Concepto label='Moneda' valor={moneda} />
                  <Concepto label='Tipo Cambio' valor={tipo_cambio} />
                  <Concepto label='Forma de Pago' valor={forma_pago_sat.descripcion} />
                  <div>
                    <span className='text-success'>
                      <strong>Monto</strong> <u>$ {format(monto, 2)}</u>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <legend></legend>
                </Col>
              </Row>
              <Row>
                <Col sm='4'>
                  <Concepto label='Cliente' valor={cliente} />
                  <Concepto label='RFC' valor={customerId.rfc} />
                </Col>
                <Col sm='4'>
                  <Concepto label='Numero Operación' valor={numero_operacion} />
                  <Concepto label='Cta Ordenante' valor={cta_ordenante} />
                  <Concepto label='Rfc Banco Cuenta Ordenante' valor={rfc_emisor_cta_ord} />
                </Col>
                <Col sm='4'>
                  <Concepto label='Cta Benificiario' valor={cta_benificiario} />
                  <Concepto label='Rfc Banco Cuenta Ordenante' valor={rfc_emisor_cta_ben} />
                </Col>
              </Row>
            </div>
            {renderDetalle()}
          </div>
        </Col>
      </Row>
    </Module>
  );
};

DetalleNotaCredito.propTypes = {};

export default DetalleNotaCredito;
