import PropTypes from 'prop-types';

const ElseIf = ({ children }) => {
  return children;
};

ElseIf.propTypes = {
  children: PropTypes.node,
};

export default ElseIf;
