import { DivFormGroup, Input, Label } from '@controls';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, Saving } from '@stateless';
import { postRequest } from '@utils/api';

const Import = () => {
  const [state, setState] = useState({
    regimen: 'F',
    isLoading: false,
    cargando: false,
    csv: null,
    valid: false,
    msg: '',
    progress: 0,
    rfcReceptor: '',
    fechaInicial: moment().add(-1, 'month').format('YYYY-MM-DD'),
    horaInicial: moment().add(-1, 'month').format('HH:mm'),
    fechaFinal: moment().format('YYYY-MM-DD'),
    horaFinal: moment().format('HH:mm'),
    isCompleted: false,
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleObtenerXmlsHora = (e) => {
    e.preventDefault();
    setState(
      (prevState) => ({
        ...prevState,
        fechaInicial: moment().add(-1, 'hour').format('YYYY-MM-DD'),
        horaInicial: moment().add(-1, 'hour').format('HH:mm'),
        fechaFinal: moment().format('YYYY-MM-DD'),
        horaFinal: moment().format('HH:mm'),
      }),
      handleObtenerXmls
    );
  };

  const handleObtenerXmlsDia = (e) => {
    e.preventDefault();
    setState(
      (prevState) => ({
        ...prevState,
        fechaInicial: moment().add(-1, 'day').format('YYYY-MM-DD'),
        horaInicial: moment().add(-1, 'day').format('HH:mm'),
        fechaFinal: moment().format('YYYY-MM-DD'),
        horaFinal: moment().format('HH:mm'),
      }),
      handleObtenerXmls
    );
  };

  const handleObtenerXmls = async (event) => {
    if (event) event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      cargando: true,
      progress: 0,
      csv: null,
      isLoading: true,
    }));

    for (let i = 1; i <= 100; i++) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          progress: i,
        }));
      }, 100);
    }

    setTimeout(async () => {
      const body = {
        fechaInicialBusqueda: state.fechaInicial + ' ' + state.horaInicial,
        fechaFinalBusqueda: state.fechaFinal + ' ' + state.horaFinal,
        rfcReceptorBusqueda: state.rfcReceptor,
      };
      await postRequest({ url: 'migrar/facturasSat', body });
      setState((prevState) => ({ ...prevState, cargando: false, progress: 0 }));
    }, 100);
  };

  const { cargando, isLoading, progress, fechaInicial, horaInicial, fechaFinal, horaFinal, rfcReceptor } = state;

  return (
    <Module onClickBack={window.history.back} parent='Facturas' title='Obtener facturas'>
      <br />
      <form onSubmit={handleObtenerXmls} encType='multipart/form-data'>
        <Row>
          <Col sm='4'>
            <Row>
              <DivFormGroup className='col-sm-6'>
                <Label name='fechaInicial' title='Fecha inicial' />
                <input className='form-control' name='fechaInicial' id='fechaInicial' type='date' value={fechaInicial} onChange={onChange} />
              </DivFormGroup>
              <DivFormGroup className='col-sm-6'>
                <Label name='horaInicial' title='Hora' />
                <input className='form-control' name='horaInicial' id='horaInicial' type='time' value={horaInicial} onChange={onChange} />
              </DivFormGroup>
            </Row>
          </Col>
          <Col sm='4'>
            <Row>
              <DivFormGroup className='col-sm-6'>
                <Label name='fechaFinal' title='Fecha final' />
                <input className='form-control' name='fechaFinal' id='fechaFinal' type='date' value={fechaFinal} onChange={onChange} />
              </DivFormGroup>
              <DivFormGroup className='col-sm-6'>
                <Label name='horaFinal' title='Hora' />
                <input className='form-control' name='horaFinal' id='horaFinal' type='time' value={horaFinal} onChange={onChange} />
              </DivFormGroup>
            </Row>
          </Col>
          <Col sm='4'>
            <Row>
              <DivFormGroup className='col-sm-12'>
                <Label name='rfc' title='RFC Cliente(Opcional)' />
                <Input name='rfcReceptor' onChange={onChange} value={rfcReceptor} />
              </DivFormGroup>
            </Row>
          </Col>
          <Col sm='9'>
            <button className='btn btn-primary mr-5' disabled={cargando} onClick={handleObtenerXmlsDia}>
              <Icon icon='save' /> Obtener ultimo día
            </button>
            <button className='btn btn-primary mr-5' disabled={cargando} onClick={handleObtenerXmlsHora}>
              <Icon icon='save' /> Obtener ultima hora
            </button>
            <button type='submit' className='btn btn-success' disabled={cargando}>
              <Icon icon='import' /> Obtener del filtro
            </button>
            {isLoading ? <Saving action='Importantando' /> : ''}
          </Col>
          <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
        </Row>
      </form>
    </Module>
  );
};

Import.propTypes = {};

export default Import;
