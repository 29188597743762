import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, URL_FILES } from '@utils/api';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { ButtonGroup, Col, Row, DropdownItem, Dropdown } from 'react-bootstrap';
import CbTableResponsive from '@cbcomponents/CbTableResponsive';
import StatusServicio from '@components/servicios/StatusServicio';
import TextDate from '@controls/TextDate';
import { Icon } from '@stateless';

const OrdenesServicios = ({ id }) => {
  const [ordenes_servicios, setOrdenesServicios] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const close = () => {
    setShowModal(false);
    setOrdenesServicios([]);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `customers/ordenes-servicios/${id}` });
    setShowModal(true);
    setOrdenesServicios(data);
  };

  const handleClickCotizacion = async (cotizacionId) => {
    const { data } = await getRequest({
      url: `cotizaciones/pdf/${cotizacionId}`,
      params: { codigos: true, desgloseKits: true, horasServicio: true },
    });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  }

  const handleClickOrdenServicio = async (ordenServicioId) => {
    const { data } = await getRequest({ url: `ordenes-servicio/pdf/${ordenServicioId}` });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  }

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='duplicate' /> Ordenes de servicios
      </DropdownItem>
      <CbModal title='Ordenes' show={showModal} onClose={close} size="xl" fullscreen>
        <Row>
          <Col sm='12'>
            <CbTableResponsive>
              <thead>
                <tr>
                  <th width="10%">Fecha</th>
                  <th width="5%">Orden</th>
                  <th width="15%">CRM</th>
                  <th width="50%">Descripción</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {ordenes_servicios.map((item) => (
                  <tr key={item._id}>
                    <td><TextDate>{item.fecha_solicitud}</TextDate></td>
                    <td>{item.numero_orden_servicio}</td>
                    <td>{item.registro_cliente.folio} {item.registro_cliente.descripcion}</td>
                    <td>{item.referencia}</td>
                    <td><StatusServicio status={item.estatus_servicios}/></td>
                    <td>
                      <ButtonGroup className='pull-right'>
                        <CbButtonOver icon='file' title='Orden de trabajo' onClick={() => handleClickOrdenServicio(item._id)} type='success' />
                        <Dropdown as={ButtonGroup}>
                          <Dropdown.Toggle variant='outline-primary'>
                            <Icon icon='option-vertical' />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item.cotizacionId && (<Dropdown.Item onClick={() => handleClickCotizacion(item.cotizacionId)}><Icon icon="file" /> Cotizacion principal</Dropdown.Item>)}
                            {item.cotizacionAdicionalId && (<Dropdown.Item onClick={() => handleClickCotizacion(item.cotizacionAdicionalId)}><Icon icon="file" /> Cotizacion adicional</Dropdown.Item>)}
                          </Dropdown.Menu>
                        </Dropdown>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </CbTableResponsive>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

OrdenesServicios.propTypes = {
  refresh: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default OrdenesServicios;
