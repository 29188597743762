import { FORZOSO } from '@config/constants';
import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteCondicionesPagoServicios = ({ condicionespagoserviciosId, tipo, plazo, loadCondicionesPagoServicios }) => {
  let message = `Indeterminado`;
  if (tipo === FORZOSO) message = `Forzoso a ${plazo} meses`;

  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`condicionespagoservicios/${condicionespagoserviciosId}`}
      description={`¿Confirma que desea eliminar la condición de venta <strong><u> ${message}</u> </strong>?`}
      loadData={loadCondicionesPagoServicios}
    />
  );
};

DeleteCondicionesPagoServicios.propTypes = {
  id: PropTypes.number.isRequired,
  tipo: PropTypes.string.isRequired,
  plazo: PropTypes.number.isRequired,
  condicionespagoserviciosId: PropTypes.string.isRequired,
  loadCondicionesPagoServicios: PropTypes.func.isRequired,
};

export default DeleteCondicionesPagoServicios;
