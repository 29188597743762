import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Button, InputGroup, Card, Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { useParams, useNavigate } from 'react-router-dom';

const EquiposZonas = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    clasificaciones: [],
    activeClasificacion: null,
    idLevantamiento: null,
    showModal: false,
    isLoadingForm: true,
    isLoadingTable: false,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    agente: null,
    maximo_descuento: 0,
    detalles: [],
    observaciones: '',
    estatus: 'INICIADA',
    cliente: null,
    productos: [],
    tipoCambio: 0,
    usa_almacen: false,
    usa_servicios: false,
    usa_gastos: false,
    tipo_cliente: 'CLIENTE',
    tipos_clientes: [
      { value: 'PROSPECTO', label: 'PROSPECTO' },
      { value: 'CLIENTE', label: 'CLIENTE' },
    ],
    estatus_levantamientos: [{ value: 'INICIADA', label: 'INICIADA' }],
    domicilios: [],
    anticipo: 0,
    zonas: [],
    area: null,
    zona: null
  });

  useEffect(() => {
    getLevantamiento();
  }, []);
      

  const getLevantamiento = useCallback(async () => {
    const { levantamientoId } = params;
    const { data } = await getRequest({ url: `levantamientos/${levantamientoId}` });
    const encabezado = data;
    try {
      const cliente = encabezado.customerId;
      const domicilios =
        cliente && cliente.listDomicilios && cliente.listDomicilios.length > 0
          ? encabezado.customerId.listDomicilios
          : [encabezado.customer.domicilio];
      const area = encabezado.areas.find((a) => a._id === params.areaId);  
      const zona = area.zonas.find((z) => z._id === params.zonaId);
      setState((prevState) => ({
        ...prevState,
        isLoadingForm: false,
        numeroOrden: encabezado.numero_levantamiento,
        fecha: moment(encabezado.fecha).local().format('YYYY-MM-DD'),
        fecha_vencimiento: moment(encabezado.fecha_vencimiento).local().format('YYYY-MM-DD'),
        estatus: encabezado.estado,
        sucursalId: encabezado.sucursal._id,
        monedaId: encabezado.moneda,
        tipoLevantamientoId: encabezado.tipo_levantamiento,
        tipo_cliente: encabezado.tipo_cliente,
        clienteId: encabezado.customer._id,
        clienteName: encabezado.customer.razon_social,
        cliente: encabezado.customer,
        agente: encabezado.agente,
        domicilioId: encabezado.customer.domicilio._id || 1,
        domicilios,
        observaciones: encabezado.observaciones,
        isLoading: false,
        area,
        zona,
        clasificaciones: encabezado.clasificacionesId,
        detalles: zona.clasificaciones.map((c) => c.subclasificaciones.map((s) => ({ subclasificacionId: s.subclasificacionId, cantidad: s.cantidad }))).flat(),
      }));
    } catch (e) {
      console.log(e);
    }
  }, [params]);


  const handleAtras = () => {
    const { levantamientoId } = params;
    navigate(`/levantamientos/${levantamientoId}/areas`);
  }

  const handleRegister = async (event) => {
    event.preventDefault();
    const { levantamientoId, areaId, zonaId } = params;
    const body = {
      clasificaciones: state.clasificaciones.map((c) => ({
        clasificacionId: c._id,
        subclasificaciones: state.detalles
          .filter((d) => c.subclasificaciones.some((s) => s._id === d.subclasificacionId))
          .map((d) => ({
            subclasificacionId: d.subclasificacionId,
            cantidad: d.cantidad,
          })),
      })).filter((c) => c.subclasificaciones.length > 0),
    };
    const { data } = await putRequest({ url: `levantamientos/${levantamientoId}/areas/${areaId}/zonas/${zonaId}`, body });
    if (data) {
      setState((prevState) => ({
        ...prevState,
        zonas: [...prevState.zonas, data],
      }));
      navigate(`/levantamientos/${levantamientoId}/areas/${areaId}`);
    }
  };

  const renderView = () => {
    const {
      isLoading,
      area,
      zona,
      clasificaciones,
    } = state;
    return (
      <>
        <h3>Zona {area?.nombre} {'>'} { zona?.nombre }</h3>
        <br />
          {clasificaciones.map((clasificacion) => (
            <Fragment key={clasificacion._id}>
              <Card className='mb-3'>
                <Card.Header>
                  <Card.Title>
                    {clasificacion.nombre}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {clasificacion.subclasificaciones.map((sub) => (
                    <Row key={sub._id} className="align-items-center mb-2">
                      <Col xs="9">
                          <input
                            type="checkbox"
                            className='form-check-input me-1'
                            id={sub._id}
                            checked={state.detalles.some((d) => d.subclasificacionId === sub._id)}
                            onChange={(e) => {
                              const detalles = e.target.checked
                                ? [...state.detalles, { subclasificacionId: sub._id, cantidad: 1 }]
                                : state.detalles.filter((d) => d.subclasificacionId !== sub._id);
                              setState((prevState) => ({ ...prevState, detalles }));
                            }}
                        />
                        <label className="form-check-label" htmlFor={sub._id}>&nbsp;{sub.nombre}</label>
                      </Col>
                      <Col xs="3" className="d-flex align-items-center">
                        <InputGroup>
                          <Button
                            variant={ state.detalles.some((d) => d.subclasificacionId === sub._id && d.cantidad == 1)? 'outline-danger': 'outline-primary'}
                            size="sm"
                            onClick={() => {
                              if (!state.detalles.some((d) => d.subclasificacionId === sub._id)) {
                                return;
                              }
                              if (state.detalles.find((d) => d.subclasificacionId === sub._id).cantidad === 1) {
                                const detalles = state.detalles.filter((d) => d.subclasificacionId !== sub._id);
                                setState((prevState) => ({ ...prevState, detalles }));
                                return;
                              }
                              const detalles = state.detalles.map((d) =>
                                d.subclasificacionId === sub._id && d.cantidad > 1
                                  ? { ...d, cantidad: d.cantidad - 1 }
                                  : d
                              );
                              setState((prevState) => ({ ...prevState, detalles }));
                            }}
                            disabled={!state.detalles.some((d) => d.subclasificacionId === sub._id)}
                          >
                            <Icon icon={state.detalles.some((d) => d.subclasificacionId === sub._id && d.cantidad == 1)? 'trash': 'minus'} />
                          </Button>
                          <input
                            type="number"
                            className='form-control'
                            value={
                              state.detalles.find((d) => d.subclasificacionId === sub._id)?.cantidad || 0
                            }
                            onChange={(e) => { 
                              const detalles = state.detalles.map((d) =>
                                d.subclasificacionId === sub._id
                                  ? { ...d, cantidad: parseInt(e.target.value) }
                                  : d
                              );
                              setState((prevState) => ({ ...prevState, detalles }));
                            }}
                            style={{ width: '50px', textAlign: 'center' }}
                          />
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                              const detalles = state.detalles.map((d) =>
                                d.subclasificacionId === sub._id
                                  ? { ...d, cantidad: d.cantidad + 1 }
                                  : d
                              );
                              if (!state.detalles.some((d) => d.subclasificacionId === sub._id)) {
                                detalles.push({ subclasificacionId: sub._id, cantidad: 1 });
                              }
                              setState((prevState) => ({ ...prevState, detalles }));
                            }}
                          >
                            +
                          </Button>
                        </InputGroup>
                      </Col>
                    </Row>
                  ))}
                </Card.Body>
              </Card>
            </Fragment>
          ))}
        <br />
        <Card>
          <Card.Footer>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleAtras} variant='warning' disabled={isLoading}>
                  <Icon icon='arrow-left' /> Ir a Areas
                </Button>
                <Button onClick={handleRegister} variant='success' className='pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar Zona
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Levantamientos' title='Editar levantamiento'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

EquiposZonas.propTypes = {
  params: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  onChangeTipoCliente: PropTypes.func.isRequired,
};

export default EquiposZonas;
