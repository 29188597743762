import Joi from 'joi';
import { MESSAGES } from '@utils/validations';
const R_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const ordenServicioSchemaUpdate = Joi.object({
  tipoOrdenServicioId: Joi.string().label('Tipo de orden de servicio').required().regex(R_MONGO_ID),
  fecha_solicitud: Joi.date().label('Fecha de solicitud').required(),
  fecha_entrega: Joi.date().label('Fecha de entrega').required(),
  agenteId: Joi.string().label('Agente solicitante').required().regex(R_MONGO_ID),
  sucursalId: Joi.string().label('Sucursal').required().regex(R_MONGO_ID),
  estatus_servicios: Joi.string().label('Estatus').required(),
  clienteId: Joi.string().label('Cliente').required().regex(R_MONGO_ID),
  domicilio_entrega_Id: Joi.string().label('Domicilio de entrega').required().regex(R_MONGO_ID),
  domicilio_cliente_id: Joi.string().label('Domicilio de cliente').required().regex(R_MONGO_ID),
  contacto_solicitante: Joi.string().label('Contacto solicitante').required().regex(R_MONGO_ID),
  soluciones_solicitadas_id: Joi.array().items(Joi.string().label('Soluciones solicitadas').required().regex(R_MONGO_ID)),
  tipos_servicios_solicitados_id: Joi.array().items(Joi.string().label('Tipos de servicios solicitados').required().regex(R_MONGO_ID)),
  tipos_mano_obras_id: Joi.string().label('Tipo de mano de obra').required().regex(R_MONGO_ID),
  tipos_cobro_equipo_id: Joi.string().label('Tipo de cobro de equipo').required().regex(R_MONGO_ID),
  numero_orden_servicio: Joi.number().label('Número de orden de servicio').required(),
  descripcion: Joi.string().label('Descripción').required(),
  referencia: Joi.string().label('Referencia').required(),
}).messages(MESSAGES);

export function validateUpdate(pedido) {
  return ordenServicioSchemaUpdate.validate(pedido);
}
