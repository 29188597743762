import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditTitulos = ({ tituloId, abreviatura: propAbreviatura, titulo: propTitulo, loadTitulos }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [abreviatura, setAbreviatura] = useState('');
  const [titulo, setTitulo] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setAbreviatura('');
    setTitulo('');
  };

  const open = () => {
    setShowModal(true);
    setAbreviatura(propAbreviatura);
    setTitulo(propTitulo);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      abreviatura: abreviatura.trim(),
      titulo: titulo.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `titulos/${tituloId}`, body: data });
      loadTitulos();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.abreviatura + '')) {
      validate.success = false;
      validate.message = 'Abreviatura es requerida.';
    } else if (validate.success && isEmpty(params.titulo + '')) {
      validate.success = false;
      validate.message = 'Título es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col sm='3'>
            <DivFormGroup name='abreviatura' title='Abreviatura' required>
              <Input name='abreviatura' onChange={(e) => setAbreviatura(e.target.value)} value={abreviatura} placeholder='Lic.' />
            </DivFormGroup>
          </Col>
          <Col sm='9'>
            <DivFormGroup name='titulo' title='Título' required>
              <Input name='titulo' onChange={(e) => setTitulo(e.target.value)} value={titulo} placeholder='Licenciado' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditTitulos.propTypes = {
  tituloId: PropTypes.string.isRequired,
  abreviatura: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
  loadTitulos: PropTypes.func.isRequired,
};

export default EditTitulos;
