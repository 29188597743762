import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [orden, setOrden] = useState(1);
  const [nombre, setNombre] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setOrden(1);
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = { nombre, orden };

    if (validations(data)) {
      await postRequest({ url: `condiciones-garantias`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.orden + '')) {
      validate.success = false;
      validate.message = 'Orden es requerido.';
    } else if (params.orden <= 0) {
      validate.success = false;
      validate.message = 'Orden debe ser mayor a cero.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Condición de garantía es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Nuevo' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='orden' title='Orden' required>
              <input
                type='number'
                className='form-control'
                name='orden'
                id='orden'
                value={orden}
                onChange={(e) => setOrden(e.target.value)}
                placeholder='Orden'
              />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='nombre' title='Condición de garantía' required>
              <textarea
                id='nombre'
                name='nombre'
                className='form-control'
                placeholder='Condición de garantía'
                onChange={(e) => setNombre(e.target.value)}
                value={nombre}
                rows={4}
              ></textarea>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  loadData: PropTypes.func.isRequired,
};

export default New;
