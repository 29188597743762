import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { textDomicilio } from '@utils/cotizaciones';
import { DomicilioOption } from '@stateless';

const SelectDomicilioCliente = ({ onChange, name, value, options, isDisabled }) => {
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };
  return (
    <Select
      value={value}
      name={name}
      placeholder='Seleccione'
      options={options}
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='basic-multi-select'
      getOptionLabel={textDomicilio}
      getOptionValue={(x) => x._id}
      classNamePrefix='select'
      components={{ Option: DomicilioOption }}
      isDisabled={isDisabled}
      isClearable
      styles={{ container: (base) => ({ ...base, flex: 'auto' }) }}
    />
  );
};


SelectDomicilioCliente.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
};

export default SelectDomicilioCliente;
