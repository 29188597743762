import { FormGruopIcon, RedirectTooltip } from '@controls';
import CbPagination from '@controls/CbPagination';
import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { ButtonGroup, DropdownButton, DropdownItem, Row, Col, Card } from 'react-bootstrap';
import Select from 'react-select';
import { Icon, Loading, Module } from '@stateless';
import { postRequest, URL_FILES } from '@utils/api';
import List from './list/List';
import IconDropdown from '@controls/IconDropdown';
import SelectSucursal from '@components/forms/SelectSucursal';
import { fetchAll } from '@services/agendasMonitoreos.service';
import usePagination from '@hooks/usePagination';

const PERIODOS_MONITOREO = {
  MENSUAL: 'MENSUAL',
  TRIMESTRAL: 'TRIMESTRAL',
  SEMESTRAL: 'SEMESTRAL',
  ANUAL: 'ANUAL',
};

const ESTATUS_CUENTA_MONITOREO = [
  { value: 'EN_SERVICIO', label: 'EN SERVICIO', type: 'success' },
  { value: 'BAJA', label: 'BAJA', type: 'danger' },
  { value: 'ACTIVACION_EN_PROCESO', label: 'ACTIVACION EN PROCESO', type: 'secondary' },
  { value: 'BAJA_EN_PROCESO', label: 'BAJA EN PROCESO', type: 'warning' },
  { value: 'SUSPENDIDO', label: 'SUSPENDIDO', type: 'danger' },
  { value: 'CUENTA_DUPLICADA', label: 'CUENTA DUPLICADA', type: 'danger' },
  { value: 'BAJA_SIN_DESCONEXION', label: 'BAJA SIN DESCONEXION', type: 'warning' },
  { value: 'CAMBIO_DE_CUENTA', label: 'CAMBIO DE CUENTA', type: 'warning' },
  { value: 'DATOS_PENDIENTES', label: 'DATOS PENDIENTES', type: 'secondary' },
  { value: 'CANCELADO', label: 'CANCELADO', type: 'secondary' },
  { value: 'PERSONALIZADO', label: 'PERSONALIZADO', type: 'secondary' },
  { value: 'PENDIENTE', label: 'PENDIENTE', type: 'secondary' },
];

const ESTATUS_EMITIDA = [
  { value: 'FALTA_EMITIR_ESTE_MES', label: 'FALTA DE EMITIR ESTE MES' },
  { value: 'SE_PASO_EMITIR', label: 'SE PASO EMITIR' },
  { value: 'EMITIDA_ESTE_MES', label: 'EMITIDA ESTE MES' },
  { value: 'NO_TOCA_EMITIR', label: 'NO TOCA EMITIR' },
  { value: 'DETALLES_VACIOS', label: 'CON DETALLES VACIOS' },
];

const OPTIONS_PERIODOS_MONITOREO = Object.values(PERIODOS_MONITOREO).map((key) => ({ label: PERIODOS_MONITOREO[key], value: key }));

const AgendaMonitoreo = () => {
  const [search, setSearch] = useState('');
  const [pagination] = useState(100);
  const [periodicidades] = useState([...OPTIONS_PERIODOS_MONITOREO]);
  const [estados] = useState([...ESTATUS_CUENTA_MONITOREO]);
  const [estatusEmitidas] = useState([...ESTATUS_EMITIDA]);
  const [estatusEmitida, setEstatusEmitida] = useState(null);
  const [periodicidad, setPeriodicidad] = useState(null);
  const [estado, setEstado] = useState(null);
  const [sucursal, setSucursal] = useState(null);

  const { datos, paginaActual, count, cargando, cambiarPagina, setParams, refresh } = usePagination(fetchAll, pagination, 'numero_cliente');
  useEffect(() => {
    setParams({
      search,
      periodicidad: periodicidad?.value,
      estado: estado?.value,
      sucursal: sucursal?._id,
      estatus_emitida: estatusEmitida?.value,
    });
  }, [search, periodicidad, estado, sucursal, estatusEmitida]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
  };

  const onSelect = (name, value) => {
    if (name === 'periodicidad') setPeriodicidad(value);
    if (name === 'estado') setEstado(value);
    if (name === 'sucursal') setSucursal(value);
    if (name === 'estatus_emitida') setEstatusEmitida(value);
  };

  const onClickGenerarAgenda = async () => {
    const body = {
      periodicidad: periodicidad?.value,
      sucursal: sucursal?._id,
      estatus_emitida: estatusEmitida?.value,
      search,
    };
    const { filesnames } = await postRequest({ url: `agendas-monitoreos/generar-agenda`, body });
    for (let i = 0; i < filesnames.length; i++) {
      const filename = filesnames[i];
      window.open(URL_FILES + 'excel/' + filename, '_blank');
    }
  };

  const onClickSincronizarAgenda = async () => {
    const body = {
      periodicidad: periodicidad?.value,
      sucursal: sucursal?._id,
      estatus_emitida: estatusEmitida?.value,
      search,
    };
    await postRequest({ url: `agendas-monitoreos/sincronizar-agenda`, body });
  };

  const onClickAplicarAgenda = async () => {
    const body = {
      periodicidad: periodicidad?.value,
      sucursal: sucursal?._id,
      estatus_emitida: estatusEmitida?.value,
      search,
    };
    await postRequest({ url: `agendas-monitoreos/aplicar-agenda`, body });
  };

  const onClickDesaplicarAgenda = async () => {
    const page = paginaActual * pagination - pagination;
    const body = {
      search,
      periodicidad: periodicidad?.value,
      sucursal: sucursal?._id,
      estado: estado?.value,
      estatus_emitida: estatusEmitida?.value,
    };
    await postRequest({ url: `agendas-monitoreos/desaplicar-agenda`, params: { page, limit: pagination }, body });
  };

  const onClickExportarAgenda = async () => {
    const body = {
      periodicidad: periodicidad?.value,
      sucursal: sucursal?._id,
      estado: estado?.value,
      estatus_emitida: estatusEmitida?.value,
      search,
    };
    const { filename } = await postRequest({ url: `agendas-monitoreos/exportar-agenda`, body });
    window.open(URL_FILES + 'excel/' + filename, '_blank');
  };

  return (
    <Module title='Agenda monitoreos'>
      <Search onSearch={onSearch} searchOnStopType blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col>
            <Row>
              <Col md={2}>
                <FormGruopIcon title='Periocidad' icon='tag'>
                  <Select
                    value={periodicidad}
                    isClearable
                    placeholder='Seleccione'
                    options={periodicidades}
                    onChange={(value) => onSelect('periodicidad', value)}
                  />
                </FormGruopIcon>
              </Col>
              <Col md={2}>
                <FormGruopIcon title='Estado' icon='tag'>
                  <Select value={estado} isClearable placeholder='Seleccione' options={estados} onChange={(value) => onSelect('estado', value)} />
                </FormGruopIcon>
              </Col>
              <Col md={2}>
                <FormGruopIcon title='Sucursal' icon='tag'>
                  <SelectSucursal value={sucursal} onChange={(value) => onSelect('sucursal', value)} />
                </FormGruopIcon>
              </Col>
              <Col md={2}>
                <FormGruopIcon title='Emitida' icon='tag'>
                  <Select
                    value={estatusEmitida}
                    isClearable
                    placeholder='Seleccione'
                    options={estatusEmitidas}
                    onChange={(value) => onSelect('estatus_emitida', value)}
                  />
                </FormGruopIcon>
              </Col>
              <Col md={4}>
                <ButtonGroup className='pull-right'>
                  <IfPermission action='importar'>
                    <RedirectTooltip
                      id={1}
                      hiddenXs
                      labelTooltip='Importar'
                      url='/agendas-monitoreos/importar'
                      icon='print'
                      text='Importar '
                      className='btn btn-outline-primary'
                    />
                  </IfPermission>
                  <IfPermission action='crear'>
                    <RedirectTooltip
                      id={3}
                      labelTooltip='Nuevo'
                      url='/agendas-monitoreos/crear'
                      icon='plus'
                      text='Nuevo'
                      className='btn btn-outline-success'
                    />
                  </IfPermission>
                  <DropdownButton title={<IconDropdown />} as={ButtonGroup} variant='outline-primary'>
                    <IfPermission action='correr'>
                      <DropdownItem onClick={onClickSincronizarAgenda}>
                        <Icon icon='export' /> Sincronizar agenda
                      </DropdownItem>
                    </IfPermission>
                    <IfPermission action='correr'>
                      <DropdownItem onClick={onClickGenerarAgenda}>
                        <Icon icon='export' /> Obtener agenda
                      </DropdownItem>
                    </IfPermission>
                    <IfPermission action='aplicar'>
                      <DropdownItem onClick={onClickAplicarAgenda}>
                        <Icon icon='export' /> Aplicar agenda
                      </DropdownItem>
                    </IfPermission>
                    <IfPermission action='aplicar'>
                      <DropdownItem onClick={onClickDesaplicarAgenda}>
                        <Icon icon='export' /> Desaplicar agenda
                      </DropdownItem>
                    </IfPermission>
                    <IfPermission action='exportar'>
                      <DropdownItem onClick={onClickExportarAgenda}>
                        <Icon icon='export' /> Exportar agenda
                      </DropdownItem>
                    </IfPermission>
                  </DropdownButton>
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Search>
      <Loading loading={cargando}>
        <Card>
          <List list={datos} loadData={refresh} refresh={refresh} />
          <Card.Footer>
            <CbPagination activePage={paginaActual} count={count} pagination={pagination} onSelect={cambiarPagina} />
          </Card.Footer>
        </Card>
      </Loading>
      <br />
    </Module>
  );
};

AgendaMonitoreo.propTypes = {};

export default AgendaMonitoreo;
