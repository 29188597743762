import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { DropdownItem } from 'react-bootstrap';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { CbTableResponsive } from '@controls';
import CbTableBody from '@cbcomponents/CbTableBody';
import RowFactura from './RowFactura';
import CbModal from '@cbcomponents/CbModal';

const Documentos = ({ agenda }) => {
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [activePage] = useState(1);
  const [pagination] = useState(30);
  const [search] = useState('');
  const [fechaInicio] = useState(moment().format('YYYY-01-01'));
  const [fechaFin] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);
  const [orden] = useState(null);
  const [tipo] = useState(null);
  const [estatus_factura] = useState(null);

  const close = () => setShowModal(false);

  const open = () => {
    setShowModal(true);
    handleSearch();
  };

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
      orden,
      tipo,
      estatus: estatus_factura,
    };
    const { data } = await postRequest({ url: `facturas/customer/${agenda.cliente_id._id}`, params: { page, limit: pagination }, body });
    setList(data);
    setLoading(false);
  }, [activePage, pagination, search, fechaFin, fechaInicio, orden, tipo, estatus_factura, agenda]);

  useEffect(() => {
    if (showModal) {
      handleSearch();
    }
  }, [showModal, handleSearch]);

  const rows = list.map((s, i) => <RowFactura key={i} factura={s} />);

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='eye-open' /> Documentos
      </DropdownItem>
      <CbModal show={showModal} onClose={close} title='Documentos' isLoading={loading}>
        <CbTableResponsive>
          <thead>
            <tr>
              <th width='5%'>Serie</th>
              <th width='15%'>Número</th>
              <th width='30%'>Fecha</th>
              <th width='30%'>Titulo</th>
              <th width='30%'>Folio</th>
              <th width='10%'>Importe</th>
              <th width='10%'>Saldo</th>
              <th width='10%'></th>
              <th width='10%'></th>
            </tr>
          </thead>
          <CbTableBody colSpan={9}>{rows}</CbTableBody>
        </CbTableResponsive>
      </CbModal>
    </>
  );
};

Documentos.propTypes = {
  agenda: PropTypes.object.isRequired,
};

export default Documentos;
