import { DOLAR, EURO, PESO } from '@config/constants';
import { DivFormGroup } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest, URL_FILES } from '@utils/api';
import { format } from '@utils/parseCost';
import CbPagination from '@cbcomponents/CbPagination';
import Table from './Table';
import { Button, Card, Col, Row } from 'react-bootstrap';
import SelectCliente from '@components/forms/SelectCliente';

const List = ({ agenteId, desde: initialDesde, hasta: initialHasta }) => {
  const [total, setTotal] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [clientes, setClientes] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pagination] = useState(20);
  const [count, setCount] = useState(0);
  const [dias] = useState('');
  const [desde, setDesde] = useState(initialDesde);
  const [hasta, setHasta] = useState(initialHasta);
  const [moneda, setMoneda] = useState(PESO);
  const [secciones, setSecciones] = useState([]);
  const [cliente, setCliente] = useState(null);

  const loadData = useCallback(async () => {
    setLoading(true);
    const page = activePage * pagination - pagination;
    const body = {
      dias: isNaN(parseInt(dias)) ? 0 : parseInt(dias),
      desde,
      hasta,
      moneda,
      customerId: cliente?._id,
    };
    const { data, count, total, saldo, clientes, filtros } = await postRequest({
      url: `asignaciones/${agenteId}`,
      params: { page, limit: pagination },
      body,
    });
    setList(data);
    setCount(count);
    setTotal(total);
    setSaldo(saldo);
    setClientes(clientes);
    setSecciones(filtros);
    setLoading(false);
  }, [agenteId, activePage, pagination, dias, desde, hasta, moneda, cliente]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleFilter = () => {
    setActivePage(1);
    loadData();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    loadData();
  };

  const renderList = () => (
    <>
      <Row>
        <Col sm='12'>
          <br />
          <button onClick={handleExcel} className='btn btn-success pull-right' type='button'>
            <Icon icon='save-file' /> Excel
          </button>
          <Link to='/cartera-vencida/importar' className='btn btn-primary pull-right mr-5'>
            <Icon icon='plus' /> Importar
          </Link>
          <legend>General</legend>
          <Col sm='4'>
            <span className='lead'>Importe Total $ {format(total, 2)}</span>
          </Col>
          <Col sm='4'>
            <span className='lead'>Saldo Total $ {format(saldo, 2)}</span>
          </Col>
          <Col sm='2'>
            <span className='lead'>{count} Facturas</span>
          </Col>
          <Col sm='2'>
            <span className='lead'>{clientes} Clientes</span>
          </Col>
          <Col sm='12'>
            <Row>
              <Table {...{ total, saldo, count, clientes, activePage, pagination, list, secciones }} loadData={loadData} />
              <div className='text-center'>
                <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
              </div>
            </Row>
          </Col>
        </Col>
      </Row>
    </>
  );

  const handleExcel = async () => {
    const body = {
      dias: isNaN(parseInt(dias)) ? 0 : parseInt(dias),
      desde,
      hasta,
      moneda,
    };
    const { file } = await postRequest({ url: `cartera-vencida-excel`, body });
    window.open(URL_FILES + 'excel/' + file, '_blank');
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      setCliente(itemSelect.row);
      loadData();
    } else {
      setCliente(null);
      loadData();
    }
  };

  return (
    <Module title='Asignaciones detallado'>
      <form>
        <Row>
          <Col sm='12'>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm='3'>
                    <DivFormGroup name='clienteName' title='Cliente'>
                      <SelectCliente value={cliente} onChange={onChangeCliente} name='cliente' />
                    </DivFormGroup>
                  </Col>
                  <Col sm='2'>
                    <DivFormGroup name='desde' title='Desde'>
                      <input
                        className='form-control'
                        name='desde'
                        id='desde'
                        type='date'
                        defaultValue={desde !== '' && desde !== null ? moment(desde).format('YYYY-MM-DD') : null}
                        onChange={(e) => setDesde(e.target.value)}
                      />
                    </DivFormGroup>
                  </Col>
                  <Col sm='2'>
                    <DivFormGroup name='hasta' title='Hasta'>
                      <input
                        className='form-control'
                        name='hasta'
                        id='hasta'
                        type='date'
                        defaultValue={hasta !== '' && hasta !== null ? moment(hasta).format('YYYY-MM-DD') : null}
                        onChange={(e) => setHasta(e.target.value)}
                      />
                    </DivFormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='4'>
                    <label>Moneda</label>
                    <div className='form-group inline'>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='moneda'
                          id='rdo-peso'
                          onChange={(e) => setMoneda(e.target.value)}
                          value={PESO}
                          checked={moneda === PESO}
                        />{' '}
                        <strong>Pesos</strong>
                      </label>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='moneda'
                          id='rdo-dolar'
                          onChange={(e) => setMoneda(e.target.value)}
                          value={DOLAR}
                          checked={moneda === DOLAR}
                        />{' '}
                        <strong>Dólares</strong>
                      </label>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='moneda'
                          id='rdo-euro'
                          onChange={(e) => setMoneda(e.target.value)}
                          value={EURO}
                          checked={moneda === EURO}
                        />{' '}
                        <strong>Euros</strong>
                      </label>
                    </div>
                  </Col>
                  <Col sm='1'>
                    <Button onClick={handleFilter} className='btn btn-primary pull-right' type='button'>
                      <Icon icon='search' /> Buscar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
      <Loading loading={loading}>{renderList()}</Loading>
    </Module>
  );
};

List.propTypes = {
  agenteId: PropTypes.string,
  desde: PropTypes.string,
  hasta: PropTypes.string,
};

export default List;
