import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';
import Baja from './Baja';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ list, load }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, numero_empleado, nombre_completo, rfc, curp, grupoEmpleadoId, departamentoId, puestoId, tipoNominaId } = row;
      return (
        <tr key={item}>
          <td>{numero_empleado}</td>
          <td>{nombre_completo}</td>
          <td>{rfc}</td>
          <td>{curp}</td>
          <td>{grupoEmpleadoId && grupoEmpleadoId.nombre}</td>
          <td>{departamentoId && departamentoId.nombre}</td>
          <td>{puestoId.nombre}</td>
          <td>{tipoNominaId.nombre}</td>
          <td>
            <Delete
              id={item}
              empleadoId={_id}
              numeroEmpleado={numero_empleado}
              nombre_completo={nombre_completo}
              load={load}
              btnClass='btn btn-sm btn-danger pull-right '
            />
            <Baja id={item} empleadoId={_id} numeroEmpleado={numero_empleado} load={load} btnClass='btn btn-outline-warning' />
            <Edit id={item} empleadoId={_id} load={load} btnClass='btn btn-outline-info' />
          </td>
        </tr>
      );
    });
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={8} />;

  return (
    <div className='panel panel-default panel-table'>
      <div className='table-responsive'>
        <table className='table table-condensed table-hover table-striped'>
          <thead>
            <tr>
              <th width='5%'># Empleado</th>
              <th width='20%'>Nombre completo</th>
              <th width='10%'>RFC</th>
              <th width='10%'>CURP</th>
              <th width='10%'>Grupo de empleados</th>
              <th width='10%'>Departamento</th>
              <th width='10%'>Puesto</th>
              <th width='10%'>Tipo de nomina</th>
              <th width='10%'></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default List;
