import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { CbTableResponsive } from '@controls';
import CbTableBody from '@cbcomponents/CbTableBody';
import RowAgendaMonitoreo from './RowAgendaMonitoreo';

const List = (props) => {
  const frmAgendaMonitoreo = useRef(null);

  const onClickColapsar = (key) => {
    props.onClickColapsar(key);
  };

  const renderRows = () => {
    const { list } = props;
    return list.map((row, key) => <RowAgendaMonitoreo {...props} key={key} onClickColapsar={() => onClickColapsar(key)} {...row} />);
  };

  return (
    <form ref={frmAgendaMonitoreo}>
      <CbTableResponsive>
        <thead>
          <tr>
            <th width='1%'></th>
            <th width='10%'>Cliente</th>
            <th width='9%'>Referencia</th>
            <th width='9%'>Periodo</th>
            <th width='20%'>Fechas</th>
            <th width='20%'>Total</th>
            <th width='20%'>Estatus</th>
            <th width='1%'>Acciones</th>
          </tr>
        </thead>
        <CbTableBody colSpan={8}>{renderRows()}</CbTableBody>
      </CbTableResponsive>
    </form>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  tipos_monitoreos: PropTypes.array.isRequired,
  sat_usos_cfdis: PropTypes.array.isRequired,
  dealers: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  onClickColapsar: PropTypes.func.isRequired,
};

export default List;
