import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ModalDetalle from './ModalDetalle';

const RowsSurtidoCarros = ({ _id, sellerId, detalle, almacenId, created }) => {
  return (
    <tr>
      <td>
        <strong>
          {sellerId.name} {sellerId.fatherLastName} {sellerId.motherLastName}
        </strong>
        <br />
        <span className='text-muted'>{almacenId.name}</span>
      </td>
      <td>
        <h4 style={{ marginTop: '0px' }}>
          <span className='label label-success'>{detalle.length}</span> <small>artículos</small>
        </h4>
      </td>
      <td>
        <small className='text-muted'>{moment(created).local().format('DD/MM/YYYY HH:mm')}</small>
      </td>
      <td>
        <ModalDetalle surtidoCarrosId={_id} />
      </td>
    </tr>
  );
};

RowsSurtidoCarros.propTypes = {
  _id: PropTypes.string.isRequired,
  sellerId: PropTypes.object.isRequired,
  almacenId: PropTypes.object.isRequired,
  detalle: PropTypes.array.isRequired,
  created: PropTypes.string.isRequired,
};

export default RowsSurtidoCarros;
