import { TYPE_CONFIGURATIONS } from '@config/constants';
import { DivFormGroup, Input, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';

const NewConfiguration = ({ esquemas, loadConfigurations }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [note, setNote] = useState('');
  const [esquemaId, setEsquemaId] = useState('');
  const [type, setType] = useState(TYPE_CONFIGURATIONS.PRODUCT);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setName('');
    setNote('');
    setEsquemaId('');
    setType(TYPE_CONFIGURATIONS.PRODUCT);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'name') setName(value);
    if (name === 'note') setNote(value);
    if (name === 'esquemaId') setEsquemaId(value);
    if (name === 'type') setType(value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      name: name.trim(),
      note: note.trim(),
      type,
      esquemaId,
    };

    if (validations(data)) {
      await postRequest({ url: `configuration`, body: data });
      loadConfigurations();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.name + '')) {
      validate.success = false;
      validate.message = 'Nombre de la Configuración es requerido.';
    } else if (validate.success && (isEmpty(params.esquemaId + '') || !isMongoId(params.esquemaId + ''))) {
      validate.success = false;
      validate.message = 'Esquema de pago es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Nuevo' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='name' title='Nombre de la configuración' required>
              <Input name='name' onChange={onChange} value={name} />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='name' title='Nombre de la configuración' required>
              <div>
                <label className='radio-inline'>
                  <input type='radio' name='type' id='rdo-product' onChange={onChange} value={TYPE_CONFIGURATIONS.PRODUCT} defaultChecked={true} />{' '}
                  Productos
                </label>
                <label className='radio-inline'>
                  <input type='radio' name='type' id='rdo-services' onChange={onChange} value={TYPE_CONFIGURATIONS.SERVICE} /> Servicios
                </label>
              </div>
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='note' title='Nota'>
              <TextArea name='note' onChange={onChange} value={note} rows={3} />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='esquemaId' title='Esquema de pago' required>
              <select name='esquemaId' id='esquemaId' className='form-control' value={esquemaId} onChange={onChange}>
                <option value=''>Seleccione</option>
                {esquemas.map((e, i) => (
                  <option value={e._id} key={i}>
                    {e.esquema}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewConfiguration.propTypes = {
  esquemas: PropTypes.array.isRequired,
  loadConfigurations: PropTypes.func.isRequired,
};

export default NewConfiguration;
