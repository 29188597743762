import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import ListAnticipos from './ListAnticipos';
import NewAnticipos from './NewAnticipos';

const Anticipos = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
    };
    const { data, count } = await postRequest({ url: `anticipos/search`, params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  return (
    <Module title='Anticipos'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <IfPermission action='crear'>
          <NewAnticipos loadAnticipos={handleSearch} />
        </IfPermission>
      </Search>
      <Loading loading={loading}>
        <ListAnticipos list={list} loadAnticipos={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

Anticipos.propTypes = {};

export default Anticipos;
