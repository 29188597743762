import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';

import TableProducts from './TableProducts';

import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';

export const FILTERS = [
  { name: 'ALL', text: 'Todos', className: 'info' },
  { name: 'SERVICE', text: 'Servicios', className: 'primary' },
  { name: 'PRODUCT', text: 'Productos', className: 'warning' },
];

const ListProducts = () => {
  const [listProducts, setListProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');
  const [filterSelected, setFilterSelected] = useState({ name: 'ALL', text: 'Todos', className: 'default', active: true });
  const [catalogFilters, setCatalogFilters] = useState([]);

  useEffect(() => {
    handlePagination(1);
  }, []);

  const handleSearch = async (params = {}) => {
    const page = activePage * pagination - pagination;
    const filter = filterSelected;
    const body = {
      search: params.search,
      filter: filter.name,
    };
    const { data, count } = await postRequest({ url: `products/search`, params: { page, limit: pagination }, body });
    setListProducts(data);
    setCount(count);
    setSearch(params.search);
    setFilterSelected(filter);
    setLoading(false);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    const page = eventKey * pagination - pagination;
    handleSearch({ page, pagination, search, filter: filterSelected });
  };

  const handleFilter = (itemMenu) => {
    const itemSelected = itemMenu - 1;
    setActivePage(1);
    setFilterSelected(catalogFilters[itemSelected]);
    handleSearch({
      filter: catalogFilters[itemSelected],
      page: 0,
      search,
      pagination,
    });
  };

  const handleItem = (item) => {
    const updatedCatalogFilters = catalogFilters.map((typeFilter) => {
      if (typeFilter.name === item) {
        typeFilter.active = true;
      } else {
        typeFilter.active = false;
      }
      return typeFilter;
    });
    setCatalogFilters(updatedCatalogFilters);
  };

  const renderItemsFilter = () => {
    const items = catalogFilters.map((typeFilter, rowFilter) => {
      const key = rowFilter + 1;
      return (
        <DropdownItem key={key} eventKey={key} onClick={() => handleItem(typeFilter.name)} active={typeFilter.active}>
          {typeFilter.text}
        </DropdownItem>
      );
    });

    return (
      <DropdownButton title='Filtro' key={1} onSelect={handleFilter}>
        {items}
      </DropdownButton>
    );
  };

  const filter = renderItemsFilter();
  return (
    <Module title='Productos'>
      <Search onSearch={handleSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <Row>
          <Col sm='2'>{filter}</Col>
          <Col sm='2'>
            <h4>
              <span className={`label label-${filterSelected.className}`}>{filterSelected.text}</span>
            </h4>
          </Col>
          <Col sm='8'>
            <Link to='/productos/nuevo' className='btn btn-success pull-right'>
              <Icon icon='plus' /> Nuevo Producto
            </Link>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <TableProducts listProducts={listProducts} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

ListProducts.propTypes = {};

export default ListProducts;
