import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectCliente from '@components/forms/SelectCliente';
import SelectCreateOrUpdateDomicilio from '@components/forms/SelectCreateOrUpdateDomicilio';
import SelectCreateOrUpdateContacto from '@components/forms/SelectCreateOrUpdateContacto';

const NewCRM = ({ refresh, cliente: initCliente, domicilio: initDomicilio }) => {
  const { addNotification: notification } = useStoreNotification();

  const [cliente, setCliente] = useState(initCliente);
  const [domicilio, setDomicilio] = useState(initDomicilio);
  const [contacto, setContacto] = useState(null);
  const [folio, setFolio] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [domicilios, setDomicilios] = useState([]);
  const [contactos, setContactos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const close = () => {
    setShowModal(false);
    setCliente(null);
    setFolio('');
    setDescripcion('');
    setDomicilio(null);
    setContacto(null);
    setDomicilios([]);
    setContactos([]);
  };

  const open = async () => {
    setLoading(true);
    if (cliente?._id) {
      const { data } = await getRequest({ url: `customer/${cliente._id}/detail` });
      setCliente(data);
      setDomicilios(data.listDomicilios);
      setContactos(data.listContactos);
      setDomicilio(data.listDomicilios.length === 1 ? data.listDomicilios[0] : null);
      setContacto(data.listContactos.length === 1 ? data.listContactos[0] : null);
    } else {
      setCliente(null);
      setFolio('');
      setDescripcion('');
      setDomicilio(null);
      setContacto(null);
      setDomicilios([]);
      setContactos([]);
    }
    setShowModal(true);
    await ObtenerNumeroSolicitudCompra();
  };

  const ObtenerNumeroSolicitudCompra = async () => {
    const { data } = await getRequest({ url: `customers-registros/siguienteNumero` });
    setFolio(data.numero);
    setLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      folio,
      descripcion,
      customerId: cliente?._id,
      domicilioId: domicilio?._id,
      contactoId: contacto?._id,
    };
    if (!validations(data)) return;
    try {
      const { data: registro } = await postRequest({ url: `customers-registros`, body: data });
      if (registro) {
        refresh(registro);
        close();
      }
    } catch (error) {
      notification({ title: 'Error', message: error.message, type: 'error' });
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.folio + '')) {
      validate.success = false;
      validate.message = 'Folio es requerido.';
    } else if (isEmpty(params.descripcion + '')) {
      validate.success = false;
      validate.message = 'Descripción es requerido.';
    } else if (!isMongoId(params.customerId + '')) {
      validate.success = false;
      validate.message = 'Cliente es requerido.';
    }
    // else if (!isMongoId(params.domicilioId + '')) {
    //   validate.success = false;
    //   validate.message = 'Domicilio es requerido.';
    // } else if (!isMongoId(params.contactoId + '')) {
    //   validate.success = false;
    //   validate.message = 'Contacto es requerido.';
    // }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      setCliente(itemSelect);
      setDomicilios(itemSelect.listDomicilios);
      setContactos(itemSelect.listContactos);
      setDomicilio(itemSelect.listDomicilios.length === 1 ? itemSelect.listDomicilios[0] : null);
      setContacto(itemSelect.listContactos.length === 1 ? itemSelect.listContactos[0] : null);
    } else {
      setCliente(null);
      setDomicilio(null);
      setContacto(null);
      setDomicilios([]);
      setContactos([]);
    }
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Nuevo registro de cliente' onClose={close} onSave={handleRegister} isLoading={isLoading} size='lg' fullscreen>
        <Row>
          <Col md={4}>
            <DivFormGroup name='folio' title='Folio'>
              <Input name='folio' onChange={(e) => setFolio(e.target.value)} value={folio} placeholder='Folio' />
            </DivFormGroup>
          </Col>
          <Col md={8}>
            <DivFormGroup name='descripcion' title='Descripción'>
              <Input name='descripcion' onChange={(e) => setDescripcion(e.target.value)} value={descripcion} placeholder='Descripción' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='clienteName' required title='Cliente'>
              <SelectCliente value={cliente} onChange={onChangeCliente} name='clienteState' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SelectCreateOrUpdateDomicilio
              value={domicilio}
              name='contacto'
              onChange={(value) => setDomicilio(value)}
              setDomicilios={(value) => setDomicilios(value)}
              customerId={cliente?._id}
              domicilios={domicilios}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectCreateOrUpdateContacto
              value={contacto}
              name='contacto'
              contactos={contactos}
              customerId={cliente?._id}
              setContactos={(value) => setContactos(value)}
              onChange={(value) => setContacto(value)}
            />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewCRM.propTypes = {
  refresh: PropTypes.func.isRequired,

  cliente: PropTypes.object.isRequired,
  domicilio: PropTypes.object.isRequired,
};

export default NewCRM;
