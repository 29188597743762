import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ id, estado, load }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`estados/${id}`}
      description={`¿Confirma que desea eliminar el estado <strong><u> ${estado}</u> </strong>?`}
      loadData={load}
    />
  );
};

Delete.propTypes = {
  estadoId: PropTypes.number.isRequired,
  estado: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Delete;
