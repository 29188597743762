import Confirmation from '@controls/Confirmation';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import EditUnitMeasures from './EditUnitMeasures';
import { ButtonGroup } from 'react-bootstrap';

const ListUnitMeasures = ({ catalogUnitMeasures, loadUnitMeasures }) => {
  const renderRows = () => {
    return catalogUnitMeasures.map((row, item) => {
      const { _id, measure, tipo, codigo, codigo_sat } = row;
      let labelTipo = '';
      if (tipo === 'EQUIPO') {
        labelTipo = <span className='label label-success'>Equipo</span>;
      } else if (tipo === 'SERVICIO') {
        labelTipo = <span className='label label-primary'>Servicio</span>;
      }

      return (
        <tr key={item}>
          <td>
            <strong>{codigo}</strong>
          </td>
          <td>{codigo_sat}</td>
          <td>{measure}</td>
          <td>{labelTipo}</td>
          <td>
            <ButtonGroup>
              <IfPermission action='editar'>
                <EditUnitMeasures id={item} idUnitMeasures={_id} loadUnitMeasures={loadUnitMeasures} btnClass='btn btn-sm btn-outline-success' />
              </IfPermission>
              <IfPermission action='eliminar'>
                <Confirmation
                  btnIcon='trash'
                  typeRequest='deleteRequest'
                  btnType='outline-danger btn-sm'
                  action='Eliminar'
                  url={`measures/${_id}`}
                  description={`¿Confirma que desea eliminar la unidad de medida <strong><u> ${measure}</u> </strong>?`}
                  loadData={loadUnitMeasures}
                />
              </IfPermission>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Código</th>
          <th width='25%'>Código SAT</th>
          <th width='25%'>Unidad</th>
          <th width='20%'>Tipo</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListUnitMeasures.propTypes = {
  catalogUnitMeasures: PropTypes.array.isRequired,
  loadUnitMeasures: PropTypes.func.isRequired,
};

export default ListUnitMeasures;
