import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getRequest } from '@utils/api';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const FilterSelectNuevo = ({
  multi = false,
  async = false,
  disabled = false,
  placeholder = '',
  options = [],
  url = '',
  lengthSearch = 3,
  value = '',
  inputValue = '',
  id,
  innerRef = () => {},
  fields = ['_id', 'name'],
}) => {
  const loadData = useCallback(
    async (input, callback) => {
      if (!input || input.trim().length < lengthSearch) {
        callback([]);
        return;
      }

      const { data } = await getRequest({ url: `${url}?query=${input}` });
      if (data.length > 0) {
        const options = renderOptions(data);
        callback(options);
      } else {
        if (value) {
          callback([{ value: value, label: inputValue }]);
        } else {
          callback([]);
        }
      }
    },
    [lengthSearch, url, value, inputValue]
  );

  const renderOptions = (data) => {
    const value = fields[0];
    const label = fields[1].split(',');
    const options = [];
    data.map((option, i) => {
      let tag = '';
      label.map((t) => {
        t = t.trim();
        if (option[t]) {
          tag += ' ' + option[t];
        } else {
          tag += ' ' + option._id[t];
        }
      });
      if (option[value]) {
        options.push({ value: option[value], label: tag.toString().trim().toUpperCase(), row: option });
      } else {
        options.push({ value: option._id[value] || option._id[value] + '' + i, label: tag.toString().trim().toUpperCase(), row: option });
      }
    });
    return options;
  };

  const onInputChange = (value, { action }) => {
    if (action === 'input-change') {
      onInputChange(value);
    }
  };

  const onChangeOption = (value) => {
    onChangeOption(value);
  };

  const syncSelect = () => (
    <Select
      placeholder={placeholder ? placeholder : 'Buscar...'}
      id={id}
      searchPromptText='Resultados...'
      noOptionsMessage={() => 'Sin resultados...'}
      name={name || 'options'}
      inputValue={inputValue}
      value={value}
      onInputChange={onInputChange}
      options={renderOptions(options)}
      onChange={onChangeOption}
      isDisabled={disabled}
      isClearable
      multi={multi}
      isSearchable
      defaultOptions
      ref={(input) => {
        if (innerRef) innerRef(input);
      }}
    />
  );

  const asyncSelect = () => (
    <AsyncSelect
      placeholder={placeholder ? placeholder : 'Buscar...'}
      searchPromptText='Resultados...'
      noOptionsMessage={() => 'Sin resultados...'}
      id={id}
      classNamePrefix='form-control'
      name={name || 'options'}
      multi={multi}
      defaultInputValue={inputValue}
      onInputChange={onInputChange}
      value={value}
      loadOptions={loadData}
      onChange={onChangeOption}
      isDisabled={disabled}
      defaultOptions
      isClearable
      isSearchable
      ref={(input) => {
        if (innerRef) innerRef(input);
      }}
    />
  );

  return async ? asyncSelect() : syncSelect();
};

FilterSelectNuevo.propTypes = {
  disabled: PropTypes.bool.isRequired,
  inputValue: PropTypes.string,
  value: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
  options: PropTypes.array,
  onChangeOption: PropTypes.func,
  multi: PropTypes.bool,
  async: PropTypes.bool,
  innerRef: PropTypes.func,
  url: PropTypes.string,
  lengthSearch: PropTypes.number,
  fields: PropTypes.array,
};


export default FilterSelectNuevo;
