import { TYPES_USERS_FRONT } from '@config/constants';
import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row, ListGroup } from 'react-bootstrap';
import { FormInput } from '@controls';
import { useStoreNotification } from '@stores/catalogs.store';

const NewSubModule = ({ module, loadCatalog }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [sort, setSort] = useState(1);
  const [nameSubModule, setNameSubModule] = useState('');
  const [path, setPath] = useState('');
  const [type] = useState(TYPES_USERS_FRONT.ADMIN);
  const [processes, setProcesses] = useState([]);
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setSort(1);
    setNameSubModule('');
    setPath('');
  };

  const open = () => {
    setShowModal(true);
    setSort(1);
    setNameSubModule('');
    setPath('');
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'sort') setSort(value);
    if (name === 'nameSubModule') setNameSubModule(value);
    if (name === 'path') setPath(value);
  };

  const handleRegisterSubModule = async (event) => {
    event.preventDefault();

    const data = {
      sort: sort,
      moduleID: module._id,
      name: nameSubModule.trim(),
      path: path.trim(),
      processes,
      type,
    };

    if (validations(data)) {
      await postRequest({ url: 'submodules', body: data });
      loadCatalog();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.sort.toString())) {
      validate.success = false;
      validate.message = 'Orden es requerido.';
    } else if (params.sort <= 0) {
      validate.success = false;
      validate.message = 'Orden debe ser mayor a cero.';
    } else if (isEmpty(params.name)) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.path)) {
      validate.success = false;
      validate.message = 'Ruta es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const addItemProcess = () => {
    setProcesses([...processes, { code: '', name: '', sort: processes.length + 1 }]);
  };

  const onChangeProcess = (index, campo, event) => {
    const { value } = event.target;
    setProcesses(
      processes.map((x, i) => {
        if (i === index) return { ...x, [campo]: value };
        return x;
      })
    );
  };

  const removeItemProcess = (item) => {
    setProcesses(processes.filter((_, i) => i !== item));
  };

  const renderProcesses = () => {
    return (
      <div>
        <div className='list-group'>
          {processes.map((process, i) => (
            <ListGroup.Item key={i}>
              <Row>
                <Col sm={6}>
                  <DivFormGroup>
                    <Input name={`process-${i}`} value={process.code} placeholder='Código' onChange={(e) => onChangeProcess(i, 'code', e)} />
                  </DivFormGroup>
                </Col>
                <Col sm={5}>
                  <DivFormGroup>
                    <Input name={`process-${i}`} placeholder='Nombre' value={process.name} onChange={(e) => onChangeProcess(i, 'name', e)} />
                  </DivFormGroup>
                </Col>
                <Col sm={1}>
                  <button onClick={() => removeItemProcess(i)} className='btn btn-danger pull-right' type='button'>
                    <Icon icon='trash' />
                  </button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
          <button
            onClick={addItemProcess}
            className='list-group-item'
            style={{ textAlign: 'center', fontSize: '1.1em', fontWeight: '600' }}
            type='button'
          >
            <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Agregar acción
          </button>
        </div>
      </div>
    );
  };

  const handleAgregarCrud = () => {
    setProcesses([
      ...processes,
      { code: 'crear', name: 'Crear', sort: processes.length + 1 },
      { code: 'detalle', name: 'Detalle', sort: processes.length + 2 },
      { code: 'editar', name: 'Editar', sort: processes.length + 3 },
      { code: 'eliminar', name: 'Eliminar', sort: processes.length + 4 },
    ]);
  };

  const buttons = [{ text: 'CRUD', type: 'warning', onClick: handleAgregarCrud }];

  return (
    <>
      <CbButtonOver title='Agregar Sub Módulo' icon='plus' onClick={open} type='success' size='sm' />
      <CbModal
        show={showModal}
        onClose={close}
        onSave={handleRegisterSubModule}
        title='Agregar Sub Módulo'
        isLoading={isLoading}
        extraButtons={buttons}
      >
        <Row>
          <Col>
            <FormInput type='number' name='sort' title='Orden' required onChange={onChange} value={sort} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput type='text' name='nameSubModule' title='Nombre' required onChange={onChange} value={nameSubModule} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput type='text' name='path' title='Ruta' required onChange={onChange} value={path} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Acciones</h4>
            {renderProcesses()}
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewSubModule.propTypes = {
  module: PropTypes.object.isRequired,
  loadCatalog: PropTypes.func.isRequired,
};

export default NewSubModule;
