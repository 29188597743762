import moment from 'moment';
import React, { useState, useEffect, Fragment } from 'react';
import { Icon, Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';
import Concepto from './Concepto';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetallePreconciliacion = () => {
  const { conciliacionId } = useParams();
  const [data, setData] = useState(null);
  const [detalle, setDetalle] = useState([]);
  const [lista, setLista] = useState([]);
  const [query, setQuery] = useState('');
  const [totales, setTotales] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data, detalle, totales } = await getRequest({ url: `concilianes/${conciliacionId}` });
      setData(data);
      setDetalle(detalle);
      setLista(detalle);
      setTotales(totales);
    };
    fetchData();
  }, [conciliacionId]);

  const onChange = (event) => {
    const { value } = event.target;

    const filteredDetalle = lista.filter((l) => {
      const {
        deposito: { concepto, cliente },
      } = l;

      if (cliente !== null) {
        if (
          cliente.name.toLowerCase().includes(value.toLowerCase()) ||
          cliente.paterno.toLowerCase().includes(value.toLowerCase()) ||
          cliente.materno.toLowerCase().includes(value.toLowerCase()) ||
          cliente.razon_social.toLowerCase().includes(value.toLowerCase()) ||
          concepto.toLowerCase().includes(value.toLowerCase())
        ) {
          return l;
        }
      } else {
        if (concepto.toLowerCase().includes(value.toLowerCase())) {
          return l;
        }
      }
    });

    setQuery(value);
    setDetalle(filteredDetalle);
  };

  if (!data) {
    return <Module loading />;
  }

  const { numero_cliente, nombre_cliente, nombre_cuenta, cuentaId, cuenta } = data;

  return (
    <Module onClickBack={window.history.back} parent='Concilaciones' title={`${cuenta} - ${cuentaId.bancoId.nombre}`} loading={!data}>
      <Row>
        <Col sm='12'>
          <div className='panel panel-primary'>
            <div className='panel-heading'>
              <h3 className='panel-title'>Cuenta</h3>
            </div>
            <div className='panel-body'>
              <Row>
                <Col sm='6'>
                  <Concepto label='No. de Cliente' valor={numero_cliente} />
                  <Concepto label='Nombre del Cliente' valor={nombre_cliente} />
                  <Concepto label='Nombre de la Cuenta' valor={nombre_cuenta} />
                  <Concepto label='Cuenta' valor={cuenta} />
                </Col>
                <Col sm='6'>
                  <Concepto label='Aplicado' valor={`$ ${format(totales.aplicado, 2)} (${totales.aplicadoMovimientos} movimientos)`} />
                  <Concepto label='Por aplicar' valor={`$ ${format(totales.pendiente, 2)}  (${totales.pendienteMovimientos} movimientos)`} />
                  <Concepto label='Total' valor={`$ ${format(totales.total, 2)}  (${totales.totalMovimientos} movimientos)`} />
                </Col>
              </Row>
            </div>
          </div>

          <form className='form-horizontal'>
            <Col sm='9'></Col>
            <Col sm='3'>
              <div className='input-group'>
                <input type='text' id='query' name='query' className='form-control' value={query} onChange={onChange} />
                <span className='input-group-text'>
                  <button className='btn btn-primary' type='button'>
                    <Icon icon='search' />
                  </button>
                </span>
              </div>
            </Col>
            <br />
            <br />
            <br />
          </form>

          {detalle.map((d, i) => {
            const {
              facturas,
              deposito: { numero, fecha, concepto, sucursal, referencia, codigo, depositos, cliente, nota },
            } = d;

            const facturasTotales = facturas.reduce(
              (t, r) => {
                t.importe += r.importe;
                t.saldoAnterior += r.saldo_anterior;
                t.saldoNuevo += r.saldo;
                return t;
              },
              {
                importe: 0,
                saldoAnterior: 0,
                saldoNuevo: 0,
              }
            );

            return (
              <div key={i} className='panel panel-primary'>
                <div className='panel-heading'>
                  <h3 className='panel-title'>Movimiento No {numero}</h3>
                </div>
                <table className='table table-condensed'>
                  <thead>
                    <tr>
                      <th width='5%'>No</th>
                      <th width='10%'>Fecha</th>
                      <th width='45%'>Concepto</th>
                      <th width='10%'>Sucursal</th>
                      <th width='10%'>Referencia</th>
                      <th width='10%'>Código</th>
                      <th width='10%'>Depósitos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {facturas.length > 0 ? (
                      <Fragment key={i}>
                        <tr key={i} className={'success'}>
                          <td>{numero}</td>
                          <td>
                            {fecha.substring(0, 2)}/{fecha.substring(2, 4)}/{fecha.substring(4, 6)}
                          </td>
                          <td>{concepto}</td>
                          <td>{sucursal}</td>
                          <td>{referencia}</td>
                          <td>{codigo}</td>
                          <td>$ {format(depositos, 2)}</td>
                        </tr>
                        <tr>
                          <td colSpan={7}>
                            <Col sm='7'>
                              <h4>
                                <small>
                                  <strong>
                                    <em>{renderNombreCliente(cliente)}</em>
                                  </strong>
                                </small>
                              </h4>
                            </Col>
                            <Col sm='3'>
                              <h4>
                                <span className='text-success'>Referencia bancaria {cliente.listReferencias[0].referencia}</span>
                              </h4>
                            </Col>
                            <Col sm='2'>
                              <h4>
                                <CbBadge type={cliente.saldo_favor > 0 ? 'success' : 'secondary'}>$ {format(cliente.saldo_favor, 2)}</CbBadge>{' '}
                                <small>
                                  <em>Saldo a favor</em>
                                </small>
                              </h4>
                            </Col>

                            <table className='table table-condensed'>
                              <thead>
                                <tr>
                                  <th width='20%'>Factura</th>
                                  <th width='25%'>UUID</th>
                                  <th width='10%'>Fecha</th>
                                  <th width='15%'>Importe</th>
                                  <th width='15%'>Saldo anterior</th>
                                  <th width='15%'>Saldo nuevo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {facturas.length > 0 ? (
                                  facturas.map((r, ri) => {
                                    const { serie, numero, uuid, fecha, importe, saldo_anterior, saldo } = r;

                                    const isPay = saldo === 0 ? true : false;

                                    let colorRowFactura = '';
                                    let colorSaldo = '';

                                    if (isPay) {
                                      colorRowFactura = 'success';
                                      colorSaldo = 'text-success';
                                    } else if (saldo < saldo_anterior) {
                                      colorRowFactura = 'warning';
                                      colorSaldo = 'text-warning';
                                    } else {
                                      colorSaldo = 'text-danger';
                                    }

                                    return (
                                      <tr key={`${i}-${ri}`} className={colorRowFactura}>
                                        <td>
                                          {serie}
                                          {numero}
                                        </td>
                                        <td>{uuid}</td>
                                        <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
                                        <td>
                                          <strong>$ {format(importe, 2)}</strong>
                                        </td>
                                        <td>$ {format(saldo_anterior, 2)}</td>
                                        <td>
                                          <span className={colorSaldo}>$ {format(saldo, 2)}</span>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <RowsNotFound colSpan={6} />
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th colSpan={3}>
                                    <span className='pull-right'>Totales</span>
                                  </th>
                                  <th>$ {format(facturasTotales.importe, 2)}</th>
                                  <th>$ {format(facturasTotales.saldoAnterior, 2)}</th>
                                  <th>$ {format(facturasTotales.saldoNuevo, 2)}</th>
                                </tr>
                              </tfoot>
                            </table>

                            {nota.length > 0 ? (
                              <p>
                                <strong>Nota: </strong> {nota}
                              </p>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      </Fragment>
                    ) : (
                      <tr key={i} className='danger'>
                        <td>{numero}</td>
                        <td>
                          {fecha.substring(0, 2)}/{fecha.substring(2, 4)}/{fecha.substring(4, 6)}
                        </td>
                        <td>{concepto}</td>
                        <td>{sucursal}</td>
                        <td>{referencia}</td>
                        <td>{codigo}</td>
                        <td>${format(depositos, 2)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            );
          })}
        </Col>
      </Row>
    </Module>
  );
};

DetallePreconciliacion.propTypes = {};

export default DetallePreconciliacion;
