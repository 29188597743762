import { DivFormGroup, Input, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditStep = ({ _id, refresh }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState('');
  const [note, setNote] = useState('');
  const [addProcess, setAddProcess] = useState(false);
  const [required, setRequired] = useState(false);
  const [capturable, setCapturable] = useState(false);
  const [isLoading] = useState(false);

  const close = () => setShowModal(false);

  const open = async () => {
    const {
      data: { step, note, required, capturable, addProcess },
    } = await getRequest({ url: `steps/${_id}` });
    setShowModal(true);
    setStep(step);
    setNote(note);
    setRequired(required);
    setAddProcess(addProcess);
    setCapturable(capturable);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      step: step.trim(),
      note: note.trim(),
      required,
      capturable,
      addProcess,
    };

    if (validations(data)) {
      await putRequest({ url: `steps/${_id}`, body: data });
      refresh();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.step + '')) {
      validate.success = false;
      validate.message = 'Título de Campo es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <span>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='12'>
            {addProcess ? (
              <div>
                <div className='checkbox'>
                  <label className='control-label'>
                    <input id='capturable' name='capturable' type='checkbox' checked={capturable} onChange={(e) => setCapturable(e.target.checked)} />{' '}
                    <strong>¿Es Para Captura en el Carro de Juan?</strong>
                  </label>
                </div>
                <br />
              </div>
            ) : null}
            <DivFormGroup name='step' title='Título'>
              <Input name='step' onChange={(e) => setStep(e.target.value)} value={step} placeholder='Nombre del Título de Campo' />
            </DivFormGroup>
            <div className='checkbox'>
              <label className='control-label'>
                <input id='required' name='required' type='checkbox' checked={required} onChange={(e) => setRequired(e.target.checked)} />{' '}
                <strong>* ¿Es campo obligatorio?</strong>
              </label>
            </div>
            <br />
            <DivFormGroup name='note' title='Nota'>
              <TextArea name='note' onChange={(e) => setNote(e.target.value)} value={note} rows={3} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </span>
  );
};

EditStep.propTypes = {
  id: PropTypes.number.isRequired,
  _id: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};


export default EditStep;
