import PropTypes from 'prop-types';
import React from 'react';

import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';

const TableProducts = ({ listProducts }) => {
  const renderRows = () => {
    return listProducts.map((rowProduct, item) => {
      const { code, name, moneda, tipo_costeo, last_cost, cost_average, precios, last_tipo_cambio, cost_base, iva, clasificacionCotizacionId } =
        rowProduct;
      const precio_publico = 200;
      return (
        <tr key={item}>
          <td>{clasificacionCotizacionId && clasificacionCotizacionId.nombre}</td>
          <td>{code}</td>
          <td style={{ padding: '0' }}>
            <input type='text' className='form-control' style={{ margin: '0' }} value={name} readOnly />
          </td>
          <td>{moneda}</td>
          <td style={{ padding: '0' }}>
            <select className='form-control' style={{ margin: '0' }} value={tipo_costeo} disabled>
              <option value='PROMEDIO'>Promedio</option>
              <option value='ULTIMO_COSTO'>Ultimo costo</option>
              <option value='COSTO_BASE'>Costo base</option>
              <option value='COSTO_BASE'>Precio base</option>
            </select>
          </td>
          <td style={{ padding: '0' }}>
            <select className='form-control' style={{ margin: '0' }} value={'VENTA'} disabled>
              <option value='VENTA'>Venta</option>
              <option value='COMPRA'>Compra</option>
            </select>
          </td>
          <td style={{ padding: '0' }}>
            <input type='text' className='form-control' style={{ margin: '0' }} value={last_tipo_cambio} readOnly />
          </td>
          <td style={{ padding: '0' }}>
            <input type='text' className='form-control' style={{ margin: '0' }} value={last_cost} readOnly />
          </td>
          <td style={{ padding: '0' }}>
            <input type='text' className='form-control' style={{ margin: '0' }} value={cost_average} readOnly />
          </td>
          <td style={{ padding: '0' }}>
            <input type='text' className='form-control' style={{ margin: '0' }} value={cost_base} readOnly />
          </td>
          <td style={{ padding: '0' }}>
            <input type='text' className='form-control' style={{ margin: '0' }} value={precios.margen} readOnly />
          </td>
          <td style={{ padding: '0' }}>
            <input type='text' className='form-control' style={{ margin: '0' }} value={precios.dolar} readOnly />
          </td>
          <td style={{ padding: '0' }}>
            <input type='text' className='form-control' style={{ margin: '0' }} value={iva} readOnly />
          </td>
          <td style={{ padding: '0' }}>
            <input type='text' className='form-control' style={{ margin: '0' }} value={precio_publico} readOnly />
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='5%'>Linea</th>
          <th width='5%'>Código</th>
          <th width='20%'>Nombre</th>
          <th width='5%'>Moneda</th>
          <th width='5%'>Tipo costeo</th>
          <th width='5%'>Tipo cambiario</th>
          <th width='5%'>Tipo cambio</th>
          <th>Ultimo costo</th>
          <th>Costo promedio</th>
          <th>Costo base</th>
          <th>Margen</th>
          <th>Precio</th>
          <th>IVA</th>
          <th>Precio publico</th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

TableProducts.propTypes = {
  listProducts: PropTypes.array.isRequired,
};

export default TableProducts;
