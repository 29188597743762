import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';

const RowDomicilio = ({ address }) => {
  if (!address || Object.getOwnPropertyNames(address).length === 0) return null;

  const { calle, exterior, interior, colonia, coloniaId, codigo_postal, municipio, estado, estadoId, pais, longitud, latitud, tiene_posicion } =
    address;
  let domicilio = `${calle} ${exterior}`;
  let coloniaCP = '';
  let ciudad = '';

  domicilio += interior !== '' ? `, ${interior}` : '';
  coloniaCP = colonia !== '' && coloniaId !== 0 ? `${colonia}` : '';
  coloniaCP += codigo_postal !== '' && codigo_postal !== '0' ? `, C.P. ${codigo_postal}` : '';
  ciudad = municipio !== '' ? municipio : '';
  ciudad += estado !== '' && estadoId !== 0 ? `, ${estado}` : '';

  return (
    <small>
      <p>
        {tiene_posicion && (
          <>
            <a href={`https://maps.google.com/?q=${latitud},${longitud}`} target='_blank' rel='noreferrer'>
              <Icon icon='map-marker' style={{ color: 'green' }} />{' '}
            </a>
            &nbsp;
          </>
        )}
        {domicilio}
        {colonia !== '' ? <br /> : null}
        {coloniaCP}
        {municipio !== '' ? <br /> : null}
        {ciudad} {pais}
      </p>
    </small>
  );
};

RowDomicilio.propTypes = {
  detalles: PropTypes.array,
  subtotal: PropTypes.number,
  impuestos: PropTypes.number,
  descuento: PropTypes.number,
  total: PropTypes.number,
  moneda: PropTypes.string,
  anticipo: PropTypes.number,
  saldo: PropTypes.number,
  pagos: PropTypes.array,
  pagosTotal: PropTypes.number,
  pagosSaldo: PropTypes.number,
  pagosAnticipo: PropTypes.number,
  pagosRestante: PropTypes.number,
  pagosRestanteTotal: PropTypes.number,
  pagosRestanteSaldo: PropTypes.number,
  address: PropTypes.object,
};

export default RowDomicilio;
