import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import New from './New';

const Estados = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [paises, setPaises] = useState([]);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  useEffect(() => {
    handleSearch();
  }, [activePage, search]);

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count, paises } = await postRequest({ url: 'estados/search', params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setPaises(paises);
    setLoading(false);
  };

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  return (
    <Module title='Estados'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <IfPermission action='crear'>
          <New load={handleSearch} paises={paises} />
        </IfPermission>
      </Search>
      <Loading loading={loading}>
        <List list={list} load={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

Estados.propTypes = {};

export default Estados;
