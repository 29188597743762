import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getRequest } from '@utils/api';
import moment from 'moment';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbTableBody from '@cbcomponents/CbTableBody';

const ModalDetalle = ({ surtidoCarrosId }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);

  const close = () => {
    setShowModal(false);
    setData(null);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `surtidocarros/${surtidoCarrosId}` });
    setShowModal(true);
    setData(data);
  };

  const renderRows = (detalle) => {
    return detalle.map(({ configuracionId, recetaId, equipoId, cantidad }, i) => (
      <tr key={i}>
        <td>{i + 1}</td>
        <td>
          <h5 style={{ marginTop: '-2px' }}>
            {equipoId.code} {equipoId.name}
          </h5>
          <h5 style={{ marginTop: '-10px', marginBottom: '0px' }}>
            <small>
              {configuracionId.name}, {recetaId.recipe}
            </small>
          </h5>
        </td>
        <td className='text-center'>{cantidad}</td>
      </tr>
    ));
  };

  return (
    <>
      <CbButtonOver icon='list-alt' title='Detallado' onClick={open} hiddenXs type='primary' />
      <CbModal show={showModal} title={`Detalle del Surtido ${moment(data?.fecha).format('DD/MM/YYYY HH:mm')}`} onClose={close} isLoading={false}>
        <p style={{ margin: '10px' }}>
          <strong>Nota: </strong>
          <small>{data !== null ? data.nota : ''}</small>
        </p>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th width='5%'></th>
              <th width='55%'>Equipo</th>
              <th width='10%' className='text-center'>
                <span>Cantidad</span>
              </th>
            </tr>
          </thead>
          <CbTableBody colSpa='3'>{data?.detalle?.length > 0 && renderRows(data?.detalle)}</CbTableBody>
        </table>
      </CbModal>
    </>
  );
};

ModalDetalle.propTypes = {
  surtidoCarrosId: PropTypes.string.isRequired,
};

export default ModalDetalle;
