import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteTitulos = ({ tituloId, abreviatura, titulo, loadData }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`titulos/${tituloId}`}
      description={`¿Confirma que desea eliminar el título <strong><u> ${abreviatura} - ${titulo}</u> </strong>?`}
      loadData={loadData}
    />
  );
};

DeleteTitulos.propTypes = {
  id: PropTypes.number.isRequired,
  abreviatura: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
  tituloId: PropTypes.string.isRequired,
  loadTitulos: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default DeleteTitulos;
