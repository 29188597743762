import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import { urlPhoto } from '@utils/api';
import { isURL } from 'validator';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ data, ...props }) => {
  const renderRows = () => {
    return data.map((row, item) => {
      const { _id, description, path } = row;
      return (
        <tr key={item}>
          <td>
            <img
              className='img-responsive'
              style={{ maxHeight: '242px', margin: 'auto', maxWidth: '100%' }}
              src={path ? (isURL(path) ? path : urlPhoto() + path) : 'https://ftp3.syscom.mx/usuarios/fotos/imagen_no_disponible.jpg'}
              alt='...'
            />
          </td>
          <td>{description}</td>
          <td>
            <IfPermission action='eliminar'>
              <Delete id={item} itemId={_id} {...props} description={description} btnClass='btn btn-sm btn-danger pull-right' />
            </IfPermission>
            <IfPermission action='editar'>
              <Edit
                id={item}
                itemId={_id}
                imagePreviewUrl={path ? (isURL(path) ? path : urlPhoto() + path) : 'https://ftp3.syscom.mx/usuarios/fotos/imagen_no_disponible.jpg'}
                description={description}
                {...row}
                {...props}
                loadData={props.loadData}
                btnClass='btn btn-sm btn-info pull-right'
              />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='30%'>Clave</th>
          <th width='30%'>Nombre</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default List;
