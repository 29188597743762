import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, DropdownItem, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';

const AsignarAgente = ({ factura, itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [agente, setAgente] = useState(null);

  const close = () => {
    setShowModal(false);
    setAgente(null);
  };

  const open = () => {
    const { agenteId } = factura;
    setShowModal(true);
    setAgente(agenteId);
  };

  const handleAsignar = async (event) => {
    event.preventDefault();
    const body = {
      agenteId: agente?.value,
    };
    if (validations(body)) {
      await putRequest({ url: `factura-asignar/${itemId}`, body });
      loadData();
      close();
    }
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setAgente(itemSelect);
    } else {
      setAgente(null);
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (validate.success && (!params.agenteId || isEmpty(params.agenteId + ''))) {
      validate.success = false;
      validate.message = 'Agente es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='user' /> Agente asignado
      </DropdownItem>
      <CbModal title='Asignación de agente' show={showModal} onClose={close} onSave={handleAsignar}>
        <Row>
          <Col>
            <DivFormGroup name='agenteName' title='Agente'>
              <SelectAgente value={agente} onChange={onChangeAgente} name='agente' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

AsignarAgente.propTypes = {
  id: PropTypes.number.isRequired,
  factura: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default AsignarAgente;
