import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';

import Row from './Row';

const TableRecepcionCompras = ({ recepcionescompras, loadData }) => {
  let rows = null;
  if (recepcionescompras.length > 0) {
    rows = recepcionescompras.map((c, i) => <Row key={i} {...c} loadData={loadData} />);
  } else {
    rows = <RowsNotFound message='Aún no hay recepciones de compra.' colSpan={9} />;
  }

  return (
    <div className='panel panel-default panel-table'>
      <div className='table-responsive'>
        <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
          <thead>
            <tr>
              <th width='10%'>Tipo compra</th>
              <th width='10%'># Recepción</th>
              <th width='10%'># Compra</th>
              <th width='10%'>Fecha</th>
              <th width='15%'>Sucursal</th>
              <th width='15%'>Almacen</th>
              <th width='10%'>Agente</th>
              <th width='10%'>Estatus</th>
              <th width='10%'></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

TableRecepcionCompras.propTypes = {
  recepcionescompras: PropTypes.array.isRequired,
  loadData: PropTypes.func,
};

export default TableRecepcionCompras;
