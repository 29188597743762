import React, { useState, useEffect } from 'react';
import { Module, Loading } from '@stateless';
import ListModules from './ListModules';
import NewModule from './NewModule';
import { getRequest } from '@utils/api';
import { Card, Col, Row } from 'react-bootstrap';

const Modules = () => {
  const [catalogModules, setCatalogModules] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadCatalog = async () => {
    setLoading(true);
    const { data } = await getRequest({ url: `modules` });
    setCatalogModules(data);
    setLoading(false);
  };

  useEffect(() => {
    loadCatalog();
  }, []);

  return (
    <Module title='Módulos'>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <NewModule loadCatalog={loadCatalog} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Loading loading={loading}>
        <ListModules catalogModules={catalogModules} loadCatalog={loadCatalog} />
      </Loading>
    </Module>
  );
};

Modules.propTypes = {};

export default Modules;
