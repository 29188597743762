import { DivFormGroup, FormInput, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, DropdownItem, Modal, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { renderNombreCliente } from '@utils/general';
import InputCurrency from '@components/forms/InputCurrency';

const EnviarReferencias = ({ cliente }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [telefonos, setTelefonos] = useState([]);
  const [correos, setCorreos] = useState([]);
  const [monto, setMonto] = useState(0);
  const [titulo, setTitulo] = useState('');

  const closeModal = () => setShowModal(false);

  const openModal = () => {
    let correos = [];
    let telefonos = [];
    if (cliente.email) {
      correos.push(cliente.email);
    } else {
      correos = cliente.listContactos.filter((x) => x.email).map((x) => x.email);
    }
    if (cliente.mobile) {
      telefonos.push(cliente.mobile);
    } else if (cliente.phone) {
      telefonos.push(cliente.phone);
    } else {
      telefonos = [
        ...cliente.listContactos.filter((x) => x.phone).map((x) => x.phone),
        ...cliente.listContactos.filter((x) => x.mobile).map((x) => x.mobile),
      ];
    }
    setCorreos(correos);
    setTelefonos(telefonos);
    setShowModal(true);
    setIsLoading(false);
  };

  const handleSendFacturas = async () => {
    await postRequest({ url: `references/oxxo/${cliente._id}`, body: { monto: cleanNumber(monto), titulo } });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'monto') setMonto(value);
    if (name === 'titulo') setTitulo(value);
  };

  return (
    <>
      <DropdownItem onClick={openModal}>
        <Icon icon='usd' /> Generar recibo
      </DropdownItem>
      <Modal size='lg' show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Envio de referencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md='12'>
              <DivFormGroup>
                <label className='control-label'>Cliente</label>
                <input type='text' name='rfc' className='form-control disabled' value={renderNombreCliente(cliente)} />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <DivFormGroup>
                <label className='control-label'>Telefonos</label>
                <input type='text' name='rfc' className='form-control disabled' value={telefonos.join(', ')} />
              </DivFormGroup>
            </Col>
            <Col md='6'>
              <DivFormGroup>
                <label className='control-label'>Contacto</label>
                <input type='text' name='rfc' className='form-control disabled' value={correos.join(', ')} />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <DivFormGroup>
                <Label name='monto' title='Monto' />
                <InputCurrency name='monto' value={monto} onChange={onChange} />
              </DivFormGroup>
            </Col>
            <Col md='6'>
              <DivFormGroup>
                <FormInput title='Titulo' name='titulo' required onChange={onChange} value={titulo} />
              </DivFormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-primary pull-right' disabled={isLoading} onClick={handleSendFacturas}>
            Enviar
          </Button>
          <Button onClick={closeModal} disabled={isLoading} className='btn btn-default mr-5 pull-right' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EnviarReferencias.propTypes = {
  cliente: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  obtenerFacturasSeleccionadas: PropTypes.func.isRequired,
};

export default EnviarReferencias;
