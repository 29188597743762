import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, DropdownItem, Modal } from 'react-bootstrap';
import { Fragment } from 'react';
import { Icon } from '@stateless';
import { getRequest, BASE_URL, urlPhoto } from '@utils/api';
import AddDocumento from './AddDocumento';
import jwt from 'jwt-simple';
import * as SECRET from '@config/apiKey';
import { Link, useNavigate } from 'react-router-dom';
import { Confirmation } from '@controls';
import { useStoreNotification } from '@stores/catalogs.store';

const Contratos = ({ id, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [adjuntos, setAdjuntos] = useState([]);

  const close = () => setShowModal(false);

  const open = async () => {
    const { data, documentos, adjuntos } = await getRequest({ url: `customer/${id}/detail` });
    setCliente(data);
    setDocumentos(documentos);
    setAdjuntos(adjuntos);
    setShowModal(true);
  };

  const handleCopiar = (adjunto) => {
    const adjuntoId = jwt.encode(adjunto._id, SECRET.API_KEY);
    copyToClipboard(BASE_URL + `firmarContrato/${adjuntoId}`);
    notification({
      message: '¡Url copiada con éxito!',
      type: 'success',
    });
  };

  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) navigator.clipboard.writeText(str);
  };

  const generarDocumento = (customerId, documentoId) => {
    navigate(`detalle/${customerId}/contrato/${documentoId}`);
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='file' /> Contratos
      </DropdownItem>
      <Modal show={showModal} onHide={close} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Contratos</Modal.Title>
        </Modal.Header>
        {documentos.map((row, item) => {
          const { _id, nombre } = row;
          const filtro = adjuntos.filter((a) => a.documentoId.toString() === _id.toString());
          console.log(filtro);
          return (
            <Fragment eventKey={_id} key={item}>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th width='50%'>Documento</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>{nombre}</th>
                    <th>
                      <AddDocumento
                        customerId={id}
                        documentoId={_id}
                        listDomicilios={cliente?.listDomicilios}
                        listContactos={cliente?.listContactos}
                        clasifications={cliente?.clasifications}
                        nombreTipoDocumento={nombre}
                        regimen={cliente?.regimen}
                        datosGenerarDocumento={
                          cliente?.regimen === 'F'
                            ? {
                                nombre: cliente?.name,
                                paterno: cliente?.paterno,
                                materno: cliente?.materno,
                                celular: cliente?.mobile,
                                fijo: cliente?.phone,
                                correo: cliente?.email,
                              }
                            : {
                                razon_social: cliente?.razon_social,
                                celular: cliente?.mobile,
                                fijo: cliente?.phone,
                                correo: cliente?.email,
                              }
                        }
                        callback={loadData}
                      />
                      <Button onClick={() => generarDocumento(id, _id)} className='btn btn-sm btn-primary' type='button'>
                        <Icon icon='file' /> Generar
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filtro.map((adjunto) => (
                    <tr key={adjunto._id}>
                      <td>{adjunto.nombre}</td>
                      <td>
                        <ButtonGroup className='pull-right'>
                          <a href={`${urlPhoto()}${adjunto.archivo}`} target='_blank' rel='noopener noreferrer' className='btn btn-sm btn-primary'>
                            <Icon icon='download' />
                          </a>
                          <Button className='btn btn-sm btn-warning' onClick={() => handleCopiar(adjunto)}>
                            <Icon icon='paperclip' />
                          </Button>
                          <Link to={`/clientes/${cliente?._id}/editar-contrato/${adjunto._id}`} className='btn btn-sm btn-primary'>
                            <Icon icon='edit' />
                          </Link>
                          <Confirmation
                            btnIcon='trash'
                            typeRequest='deleteRequest'
                            btnType='outline-danger btn-sm'
                            action='Eliminar'
                            url={`adjunto/${adjunto._id}`}
                            description={`¿Confirma que desea eliminar el adjunto <strong><u> ${adjunto.nombre}</u> </strong>?`}
                            loadData={loadData}
                          />
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Fragment>
          );
        })}

        <Modal.Footer>
          <Button onClick={close} variant='default pull-right' type='button'>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Contratos.propTypes = {
  id: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Contratos;
