import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const DetailCustomer = ({ customer, isCustomer }) => {
  if (!customer || Object.getOwnPropertyNames(customer).length === 0) return null;

  let url = `/clientes/detalle/${customer._id}`;
  if (!isCustomer) {
    url = `/prospectos/detalle/${customer._id}`;
  }

  return (
    <>
      <Link to={url} target='_blank' rel='noreferrer'>
        {customer?.razon_social || ''}
      </Link>
      {customer?.nombre_comercial && (
        <span style={{ fontSize: '10px', fontWeight: 700 }}>
          {' '}
          <br /> {customer.nombre_comercial}
        </span>
      )}
    </>
  );
};

DetailCustomer.propTypes = {
  domicilio: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  isCustomer: PropTypes.bool.isRequired,
};


export default DetailCustomer;
