import React from 'react';
import { Card, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';
import { useStoreNotification } from '@stores/catalogs.store';
import { postRequest, deleteRequest } from '@utils/api';
import { DivFormGroup, If } from '@controls';
import SelectContactoCliente from '@components/forms/SelectContactoCliente';
import ContactoEdit from '@components/customers/ContactoEdit';
import Swal from 'sweetalert2';
import { mapContactoFront } from '@utils/contratos';
const INIT_CONTACTO = {
  name: '',
  paterno: '',
  materno: '',
  email: '',
  email2: '',
  phone: '',
  extension: '',
  mobile: '',
  mobile2: '',
  tipos: [],
};

const SelectCreateOrUpdateContacto = ({
  customerId,
  contactos,
  value: contacto,
  onChange: setContacto,
  setContactos,
  name = 'contacto',
  title = 'Contacto',
}) => {
  const { addNotification: notification } = useStoreNotification();
  const handleRegisterContacto = async () => {
    if (!customerId || !contacto.name || (!contacto.email && !contacto.phone)) {
      notification({
        title: 'Información incompleta',
        message: 'Los contactos requieren nombre, correo electronico o telefono de contacto.',
        type: 'error',
      });
      return;
    }
    const data = {
      contactoId: contacto?._id,
      name: contacto?.name,
      paterno: contacto?.paterno,
      materno: contacto?.materno,
      email: contacto?.email,
      phone: contacto?.phone,
      mobile: contacto?.mobile,
      tipos: contacto?.tipos,
    };
    const { data: newContacto, error } = await postRequest({ url: `customers/${customerId}/contacto`, body: data });
    if (!error) {
      setContacto(mapContactoFront(newContacto));
      if (contactos.find((c) => c._id === newContacto._id)) {
        setContactos(contactos.map((c) => (c._id === newContacto._id ? mapContactoFront(newContacto) : c)));
      } else {
        setContactos([...contactos, mapContactoFront(newContacto)]);
      }
    }
  };

  const handleEliminarContacto = async () => {
    if (!contacto?._id) {
      return;
    }
    const confirm = await confirmarEliminar();
    if (confirm) {
      const { error } = await deleteRequest({ url: `customers/${customerId}/contactos/${contacto._id}` });
      if (!error) {
        setContacto(null);
        setContactos(contactos.filter((c) => c._id !== contacto._id));
      }
    }
  };

  const confirmarEliminar = async () => {
    const resultEliminar = await Swal.fire({
      html: `<div style="font-size: 1.5em!important;">¿Confirma que desea eliminar el contacto?</div>`,
      icon: 'primary',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    });
    return resultEliminar.isConfirmed;
  };

  if (!contacto?.editando) {
    return (
      <DivFormGroup name={name} required title={title}>
        <InputGroup>
          <SelectContactoCliente
            value={contacto}
            name={name}
            options={contactos}
            customerId={customerId}
            onChange={(value) => {
              if (value) {
                setContacto({
                  ...value,
                  tipos: value.tipos.map((tipo) => tipo.tipoContactoId),
                });
              } else {
                setContacto(null);
              }
            }}
            isDisabled={!customerId}
          />
          <If condition={customerId}>
            <If.Then>
              <If condition={contacto?.editando}>
                <If.Then>
                  <Button variant='danger' onClick={() => setContacto({ ...contacto, editando: false })}>
                    <Icon icon='remove' />
                  </Button>
                </If.Then>
                <If.Else>
                  <If condition={contacto?._id}>
                    <If.Then>
                      <Button variant='success' onClick={() => setContacto({ ...contacto, editando: true })}>
                        <Icon icon='edit' />
                      </Button>
                    </If.Then>
                    <If.Else>
                      <Button variant='success' onClick={() => setContacto({ ...INIT_CONTACTO, customerId: customerId, editando: true })}>
                        <Icon icon='plus' />
                      </Button>
                    </If.Else>
                  </If>
                </If.Else>
              </If>
            </If.Then>
          </If>
        </InputGroup>
      </DivFormGroup>
    );
  }
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={12}>
            <DivFormGroup name={name} required title={title}>
              <InputGroup>
                <SelectContactoCliente
                  value={contacto}
                  name={name}
                  options={contactos}
                  customerId={customerId}
                  onChange={(value) => {
                    if (value) {
                      setContacto({
                        ...value,
                        tipos: value.tipos.map((tipo) => tipo.tipoContactoId),
                      });
                    } else {
                      setContacto(null);
                    }
                  }}
                  isDisabled={!customerId}
                />
              </InputGroup>
            </DivFormGroup>
          </Col>
        </Row>
        {contacto?.editando && <ContactoEdit contacto={contacto} onChangeContacto={setContacto} />}
      </Card.Body>
      {contacto?.editando && (
        <Card.Footer>
          <Row>
            <Col xs={12}>
              {contacto?._id && (
                <Button onClick={handleEliminarContacto} variant='outline-danger'>
                  Eliminar contacto
                </Button>
              )}
              <div className='pull-right'>
                <Button
                  onClick={() => {
                    if (!contacto?._id) {
                      setContacto(null);
                    } else {
                      setContacto({ ...contacto, editando: false });
                    }
                  }}
                  variant='default'
                >
                  Cancelar
                </Button>
                <Button onClick={() => handleRegisterContacto()} variant='success'>
                  Guardar contacto
                </Button>
              </div>
              <br />
            </Col>
          </Row>
        </Card.Footer>
      )}
    </Card>
  );
};

SelectCreateOrUpdateContacto.propTypes = {
  customerId: PropTypes.string,
  contactos: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  setContactos: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string,
};

export default SelectCreateOrUpdateContacto;
