import PropTypes from 'prop-types';
import React from 'react';

const BsButton = ({ children, onClick, type = 'primary', disabled = false, isSubmit, size }) => {
  let typeButton = isSubmit ? 'submit' : 'button';
  let sizeButton = size && `btn-${size}`;
  return (
    <button type={typeButton} className={`btn btn-outline-${type} ${sizeButton}`} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

BsButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isSubmit: PropTypes.bool,
  size: PropTypes.string,
};

export default BsButton;
