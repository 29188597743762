import React from 'react';
import InputCurrency from '@components/forms/InputCurrency';
import InputNumber from '@components/forms/InputNumber';
import InputPercentage from '@components/forms/InputPercentage';
import { format } from '@utils/parseCost';
import PropTypes from 'prop-types';
import { cleanNumber } from '@utils/formatter';

const RowEquipo = ({ detalle, onChangeDetalle }) => {
  const handleChange = (field, value) => {
    onChangeDetalle({
      ...detalle,
      [field]: cleanNumber(value),
    });
  };

  return (
    <tr>
      <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.codigo}</th>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.descripcion}</td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.unidadMedida}</td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputNumber
          className='form-control input-sm'
          style={{ width: '80px' }}
          value={detalle.cantidad}
          onChange={(value) => handleChange('cantidad', value)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputCurrency
          className='form-control input-sm'
          style={{ width: '80px' }}
          value={detalle.precio}
          onChange={(value) => handleChange('precio', value)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputPercentage
          className='form-control input-sm'
          style={{ width: '80px' }}
          value={detalle.descuento}
          onChange={(value) => handleChange('descuento', value)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputPercentage
          className='form-control input-sm'
          value={detalle.impuesto}
          style={{ width: '80px' }}
          onChange={(value) => handleChange('impuesto', value)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(detalle.importe, 2)}</td>
      <td>&nbsp;</td>
    </tr>
  );
};

RowEquipo.propTypes = {
  detalle: PropTypes.object,
  i: PropTypes.number,
  onChangeDetalle: PropTypes.func,
};

export default RowEquipo;
