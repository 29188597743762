import { RedirectTooltip } from '@controls';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteTipoEstablecimientos from './DeleteTipoEstablecimientos';
import EditTipoEstablecimientos from './EditTipoEstablecimientos';

const ListTipoEstablecimientos = ({ catalogTipoEstablecimientos, ...props }) => {
  const renderRows = (catalogTipoEstablecimientos) => {
    return catalogTipoEstablecimientos.map((row, item) => {
      const { _id, nombre, listRubros } = row;

      const color = listRubros.length > 0 ? 'success' : 'danger';

      return (
        <tr key={item}>
          <td>
            <h4>{nombre}</h4>
          </td>
          <td>
            <h4>
              <span className={`label label-${color}`}>{listRubros.length}</span>{' '}
            </h4>
          </td>
          <td>
            <IfPermission action='eliminar'>
              <DeleteTipoEstablecimientos id={item} tipoestablecimientoId={_id} name={nombre} {...props} btnClass='btn btn-outline-danger' />
            </IfPermission>
            <IfPermission action='editar'>
              <EditTipoEstablecimientos id={item} tipoestablecimientoId={_id} {...props} btnClass='btn btn-outline-info' />
            </IfPermission>
            <IfPermission action='rubros'>
              <RedirectTooltip
                id={1}
                icon='th-list'
                url={`/tipo-establecimientos/${_id}/rubros`}
                labelTooltip='Rubros'
                className='btn btn-outline-primary'
              />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='60%'>Establecimientos</th>
          <th width='20%'>Rubros</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows(catalogTipoEstablecimientos)}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListTipoEstablecimientos.propTypes = {
  catalogTipoEstablecimientos: PropTypes.array.isRequired,
};

export default ListTipoEstablecimientos;
