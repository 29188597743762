import Search from '@controls/Search';
import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { Icon, Loading, RowsNotFound } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';

const AgregarDetalle = ({ onAgregarDetalle, almacenId }) => {
  const [isLoadingExistencia, setIsLoadingExistencia] = useState(false);
  const [existencias, setExistencias] = useState([]);
  const [seleccionados, setSeleccionados] = useState([]);
  const [search, setSearch] = useState('');
  const [showModalAgregar, setShowModalAgregar] = useState(false);
  const [pagination] = useState(20);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // componentDidMount equivalent
  }, []);

  const onChangeSearch = ({ search }) => {
    setSearch(search);
    onOpenModalAgregarDetalles();
  };

  const onChangePagina = (pagina) => {
    setActivePage(pagina);
    onOpenModalAgregarDetalles();
  };

  const closeModal = () => {
    setShowModalAgregar(false);
  };

  const onOpenModalAgregarDetalles = async (e) => {
    e && e.preventDefault();
    const page = activePage * pagination - pagination;
    setShowModalAgregar(true);
    setIsLoadingExistencia(true);
    const { data, count } = await postRequest({
      url: `existencias-almacen/${almacenId}/search`,
      params: { page, limit: pagination },
      body: { search },
    });
    setExistencias(data.map((x) => ({ ...x, selected: false })));
    setCount(count);
    setIsLoadingExistencia(false);
  };

  const onAddDetalle = () => {
    setShowModalAgregar(false);
    onAgregarDetalle(seleccionados);
  };

  const handleClickCheckboxAgregarDetalle = (indexi, indexj, checked, equipment, existencia, e) => {
    e.stopPropagation();
    const updatedExistencias = existencias.map((x, i) => {
      if (i === indexi) {
        return {
          ...x,
          list: x.list.map((y, j) => {
            if (j === indexj) {
              return {
                ...y,
                selected: checked,
              };
            }
            return { ...y };
          }),
        };
      }
      return { ...x };
    });

    const updatedSeleccionados = [
      ...seleccionados,
      {
        ...existencia,
        equipmentId: equipment._id.equipmentId,
        storehouseId: equipment._id.storehouseId,
        listaExistencias: equipment.list,
      },
    ];

    setSeleccionados(updatedSeleccionados);
    setExistencias(updatedExistencias);
  };

  const rowsSeleccionados =
    seleccionados.length > 0 ? (
      seleccionados.map((x, i) => (
        <tr key={i}>
          <td>{x.equipmentId.code}</td>
          <td>{x.equipmentId.name.toUpperCase()}</td>
          <td>{x.equipmentId.equipmentsMeasureId.measure.toUpperCase()}</td>
          <td>
            {x.ubicacionId.nombre} <b>{x.numero_serial}</b>
          </td>
          <td>{Number(x.stock)}</td>
          <td>{Number(x.apartados)}</td>
          <td>{Number(0)}</td>
        </tr>
      ))
    ) : (
      <RowsNotFound message='Sin existencias' colSpan={11} />
    );

  const rows =
    existencias.length > 0 ? (
      existencias.map((x, i) => (
        <Fragment key={i}>
          {x.list.map((y, j) => (
            <tr key={y + j} onClick={(e) => handleClickCheckboxAgregarDetalle(i, j, !y.selected, x, y, e)} style={{ cursor: 'pointer' }}>
              <td className='text-center'>
                <input
                  type='checkbox'
                  name='existencias'
                  checked={y.selected}
                  onChange={(e) => handleClickCheckboxAgregarDetalle(i, j, !y.selected, x, y, e)}
                />
              </td>
              <td>{x._id.equipmentId.code}</td>
              <td>{x._id.equipmentId.name.toUpperCase()}</td>
              <td>{x._id.equipmentId.equipmentsMeasureId.measure.toUpperCase()}</td>
              <td>
                {y.ubicacionId.nombre} <b>{y.numero_serial}</b>
              </td>
              <td>{Number(x.stock)}</td>
              <td>{Number(x.apartados)}</td>
              <td>{Number(0)}</td>
            </tr>
          ))}
        </Fragment>
      ))
    ) : (
      <RowsNotFound message='Sin existencias' colSpan={11} />
    );

  return (
    <Fragment>
      <strong style={{ color: 'red' }} className='pull-right'>
        *
      </strong>
      <button type='button' onClick={onOpenModalAgregarDetalles} className='btn btn-primary pull-right mr-5'>
        <Icon icon='plus' /> Detalle
      </button>
      <Modal size='large' show={showModalAgregar} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Existencias almacén</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='panel panel-default panel-table'>
            <div className='table-responsive' style={{ minHeight: '100px' }}>
              <table className='table table-condensed table-hover table-striped'>
                <thead>
                  <tr>
                    <th width='5%'>Código</th>
                    <th width='45%'>Descripción</th>
                    <th width='5%'>Unidad</th>
                    <th width='5%'>Ubicación / Seriel</th>
                    <th width='5%'>Existencia</th>
                    <th width='5%'>Por entregar</th>
                    <th width='5%'>Por surtir</th>
                  </tr>
                </thead>
                <tbody>{rowsSeleccionados}</tbody>
              </table>
            </div>
          </div>
          <Search searchOnStopType onSearch={onChangeSearch} blockOne='col-sm-12' blockSecond='col-sm-0'></Search>
          {isLoadingExistencia ? (
            <Loading />
          ) : (
            <div className='panel panel-default panel-table'>
              <div className='table-responsive'>
                <table className='table table-condensed table-hover table-striped'>
                  <thead>
                    <tr>
                      <th width='2%'>&nbsp;</th>
                      <th width='5%'>Código</th>
                      <th width='45%'>Descripción</th>
                      <th width='5%'>Unidad</th>
                      <th width='5%'>Ubicación / Seriel</th>
                      <th width='5%'>Existencia</th>
                      <th width='5%'>Por entregar</th>
                      <th width='5%'>Por surtir</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          )}
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={20} onSelect={onChangePagina} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary pull-right' onClick={onAddDetalle}>
            Agregar
          </button>
          <button onClick={closeModal} className='btn btn-default mr-5 pull-right' type='button'>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

AgregarDetalle.propTypes = {
  onAgregarDetalle: PropTypes.func.isRequired,
  almacenId: PropTypes.string.isRequired,
};

export default AgregarDetalle;
