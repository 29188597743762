import PropTypes from 'prop-types';
import React from 'react';
import { ESTATUS_SERVICIOS, ESTATUS_SERVICIOS_TYPE } from '@config/constants';
import CbBadge from '@cbcomponents/CbBadge';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const StatusServicio = ({ status, motivo_pausado }) => {
  const badgeClass = ESTATUS_SERVICIOS_TYPE[status];
  const statusLabel = ESTATUS_SERVICIOS[status];
    if (status === 'PAUSADA') {
      return (
        <OverlayTrigger placement='top' overlay={<Tooltip>{motivo_pausado}</Tooltip>}>
          <CbBadge type={badgeClass}>{statusLabel}</CbBadge>
        </OverlayTrigger>
      );
    }
    return <CbBadge type={badgeClass}>{statusLabel}</CbBadge>;
};

StatusServicio.propTypes = {
    status: PropTypes.string.isRequired,
    motivo_pausado: PropTypes.string,
};

export default StatusServicio;