import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeletePlazos = ({ plazosId, nombre, plazo, taza, loadPlazos }) => {
  const message = (
    <strong>
      {nombre} a {plazo} meses {taza !== null ? ` con una taza de ${taza}%` : ''}
    </strong>
  );

  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`plazos/${plazosId}`}
      description={`¿Confirma que desea eliminar el plazo <u> ${message}</u> ?`}
      loadData={loadPlazos}
    />
  );
};

DeletePlazos.propTypes = {
  id: PropTypes.number.isRequired,
  plazo: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  taza: PropTypes.number,
  plazosId: PropTypes.string.isRequired,
  loadPlazos: PropTypes.func.isRequired,
};

export default DeletePlazos;
