import { DOLAR, EURO, PESO } from '@config/constants';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ data, ...props }) => {
  const renderMoneda = (moneda) => {
    let text = '';
    let color = '';

    if (moneda === PESO) {
      text = 'Pesos';
      color = 'primary';
    } else if (moneda === DOLAR) {
      text = 'Dolares';
      color = 'success';
    } else if (moneda === EURO) {
      text = 'Euros';
      color = 'danger';
    }

    return <span className={`label label-${color}`}>{text}</span>;
  };

  const renderRows = () => {
    return data.map((row, i) => {
      const { _id,nombre, noCuenta, sucursal, noSucursal, titular, convenio, swift, clabe, bancoId, moneda, comentarios } = row;

      return (
        <tr key={i}>
          <td>{bancoId.nombre}</td>
          <td>{renderMoneda(moneda)}</td>
          <td>{noCuenta}</td>
          <td>{clabe}</td>
          <td>
            <IfPermission action='eliminar'>
              <Delete id={i} itemId={_id} nombre={`${noCuenta} - ${bancoId.nombre}`} {...props} btnClass='btn btn-outline-danger' />
            </IfPermission>
            <IfPermission action='editar'>
              <Edit id={i} itemId={_id} bancoId={bancoId} nombre={nombre} noCuenta={noCuenta} sucursal={sucursal} noSucursal={noSucursal} titular={titular} convenio={convenio} swift={swift} clabe={clabe} moneda={moneda} comentarios={comentarios} {...props} btnClass='btn btn-outline-info' />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Banco</th>
          <th width='10%'>Moneda</th>
          <th width='25%'>Cuenta</th>
          <th width='25%'>Clabe</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
};

export default List;
