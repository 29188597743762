import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Confirmation, If } from '@controls/index';
import { IfPermission, RedirectTooltip } from '@controls';
import { urlPhoto, postUploadRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import { isURL } from 'validator';
import CbBadge from '@cbcomponents/CbBadge';
import DeleteEquipment from '../DeleteEquipment';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';

const ItemEquipo = ({ equipment, handleSearch }) => {
  const ref = useRef(null);
  const handleImageChange = async (id, event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const form = new FormData();
    form.append('image', file);
    await postUploadRequest({ url: `equipments/image/${id}`, form });
    handleSearch();
  };

  return (
    <div className='thumbnail' style={{ minHeight: '465px' }}>
      <div className='caption'>
        <div style={{ textAlign: 'center', position: 'relative' }}>
          <CbBadge type={`${equipment.status === 'ACTIVO' ? 'primary' : 'secondary'}`} style={{ position: 'absolute', top: 0, left: 0 }}>
            {equipment.status}
          </CbBadge>
          <input
            type='file'
            name='image'
            style={{ display: 'none' }}
            ref={ref}
            accept='image/jpg, image/jpeg, image/png'
            onChange={(e) => handleImageChange(equipment._id, e)}
          />
          <a
            href='#'
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={(e) => {
              e.preventDefault();
              this['image' + equipment._id].click();
            }}
          >
            <Icon icon='picture' />
          </a>
          <img
            className='img-responsive'
            style={{ maxHeight: '242px', margin: 'auto', maxWidth: '100%' }}
            src={
              equipment.imagen.thumbnail
                ? isURL(equipment.imagen.thumbnail)
                  ? equipment.imagen.thumbnail
                  : urlPhoto() + equipment.imagen.thumbnail
                : 'https://ftp3.syscom.mx/usuarios/fotos/imagen_no_disponible.jpg'
            }
            alt='...'
          />
        </div>
        <h3>
          {equipment.code}
          <b style={{ fontSize: '12px' }} className='pull-right'>
            {equipment.equipmentsMeasureId ? equipment.equipmentsMeasureId.measure : ''}
          </b>
        </h3>
        <p className='ellipsis-multiline' style={{ height: '62px' }}>
          <Link to={`/equipos/detalle/${equipment._id}`}>{equipment.name}</Link>
        </p>
        <p style={{ minHeight: '20px' }}>
          <b>{equipment.marcaId ? equipment.marcaId.nombre : ''}</b>{' '}
          <b className='pull-right'>{equipment.clasificacionCotizacionId ? equipment.clasificacionCotizacionId.nombre : ''}</b>
        </p>
        <h2>
          $ {format(equipment.precio, 2)} <span style={{ fontSize: '12px' }}>{equipment.moneda === 'DOLAR' ? 'USD' : 'MXN'}</span>
        </h2>
        <p>
          <IfPermission action='eliminar'>
            <DeleteEquipment id={equipment._id} equipmentId={equipment._id} name={`${equipment.code} ${equipment.name}`} />
          </IfPermission>
          <If condition={equipment.status === 'ACTIVO'}>
            <If.Then>
              <IfPermission action='baja'>
                <Confirmation
                  id={equipment._id}
                  btnIcon='arrow-down'
                  typeConfirm='warning'
                  btnType='warning mr-5'
                  action='Baja'
                  typeRequest='putRequest'
                  url={`equipments/baja/${equipment._id}`}
                  description={`¿Confirma que desea dar de alta el equipo <strong>${equipment.code} ${equipment.name}</strong> ?`}
                  loadData={handleSearch}
                />
              </IfPermission>
            </If.Then>
          </If>
          <If condition={equipment.status === 'BAJA'}>
            <If.Then>
              <IfPermission action='alta'>
                <Confirmation
                  id={equipment._id}
                  btnIcon='arrow-up'
                  typeConfirm='success'
                  btnType='success mr-5'
                  action='Activar'
                  typeRequest='putRequest'
                  url={`equipments/alta/${equipment._id}`}
                  description={`¿Confirma que desea dar de alta el equipo <strong>${equipment.code} ${equipment.name}</strong> ?`}
                  loadData={handleSearch}
                />
              </IfPermission>
            </If.Then>
          </If>
          <IfPermission action='editar'>
            <RedirectTooltip id={1} icon='pencil' url={`/equipos/edicion/${equipment._id}`} labelTooltip='Editar' className='btn btn-info mr-5' />
          </IfPermission>
          <IfPermission action='detalle'>
            <RedirectTooltip
              id={1}
              icon='eye-open'
              url={`/equipos/detalle/${equipment._id}`}
              labelTooltip='Detalle'
              className='btn btn-primary mr-5'
            />
          </IfPermission>
        </p>
      </div>
    </div>
  );
};

ItemEquipo.propTypes = {
  equipment: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default ItemEquipo;
