import React from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { Icon } from '@stateless';
import { Input } from '@controls';
import PropTypes from 'prop-types';
import InputNumber from '@components/forms/InputNumber';

const RowDetalle = ({ detalle, i, ubicaciones, almacenId, onChangeDetalle, onChangeUbicacion, onClickCloneDetalle, onClickRemoveDetalle }) => {
  return (
    <tr key={i}>
      <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.codigo}</th>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <Input
          name='descripcion'
          value={detalle.descripcion}
          placeholder='Descripcion'
          onChange={(event) => onChangeDetalle('descripcion', i, event)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.unidadMedidaId.measure}</td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputNumber className='input-sm' value={detalle.cantidad} onChange={(e) => onChangeDetalle('cantidad', i, e)} style={{ width: '80px' }} />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.cantidad_comprada}</td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.compraDetalleId && detalle.compraDetalleId.cantidad_recibida}</td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <Select
          value={detalle.ubicacion}
          name='ubicacionId'
          placeholder='Selecciona opciones'
          options={ubicaciones.filter((x) => x.almacenId === almacenId)}
          onChange={(selectedOption) => onChangeUbicacion(i, selectedOption)}
          className='basic-multi-select'
          getOptionLabel={(x) => x.nombre}
          getOptionValue={(x) => x._id}
          classNamePrefix='select'
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <Input
          name='numero_serial'
          value={detalle.numero_serial}
          placeholder='Numero serial'
          onChange={(event) => onChangeDetalle('numero_serial', i, event)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <Input name='pedimento' value={detalle.pedimento} placeholder='Pedimento' onChange={(event) => onChangeDetalle('pedimento', i, event)} />
      </td>
      <td>
        <div className='btn-group pull-right'>
          <Button onClick={() => onClickCloneDetalle(i, detalle)} className='btn btn-sm btn-outline-primary'>
            <Icon icon='duplicate' />
          </Button>
          <Button onClick={() => onClickRemoveDetalle(i)} className='btn btn-sm btn-outline-danger'>
            <Icon icon='remove' />
          </Button>
        </div>
      </td>
    </tr>
  );
};

RowDetalle.propTypes = {
  detalle: PropTypes.object,
  i: PropTypes.number,
  ubicaciones: PropTypes.array,
  almacenId: PropTypes.string,
  onChangeDetalle: PropTypes.func,
  onChangeUbicacion: PropTypes.func,
  onClickCloneDetalle: PropTypes.func,
  onClickRemoveDetalle: PropTypes.func,
};

export default RowDetalle;
