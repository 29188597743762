import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditTipoCableados = ({ tipocableadoId, loadTipoCableados }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
  };

  const open = async () => {
    const {
      data: { nombre },
    } = await getRequest({ url: `tipocableados/${tipocableadoId}` });
    setShowModal(true);
    setNombre(nombre);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      nombre: nombre.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `tipocableados/${tipocableadoId}`, body: data });
      loadTipoCableados();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' placeholder='Nombre' onChange={(e) => setNombre(e.target.value)} defaultValue={nombre} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditTipoCableados.propTypes = {
  tipocableadoId: PropTypes.string.isRequired,
  loadTipoCableados: PropTypes.func.isRequired,
};

export default EditTipoCableados;
