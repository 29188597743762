import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteUser = ({ idUser, nombre, loadUsers }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`users/${idUser}`}
      isMenu
      description={`¿Confirma que desea eliminar al usuario <strong><u> ${nombre}</u> </strong>?`}
      loadData={loadUsers}
    />
  );
};

DeleteUser.propTypes = {
  idUser: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  loadUsers: PropTypes.func.isRequired,
};

export default DeleteUser;
