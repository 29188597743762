import React, { useState, useEffect } from 'react';
import Search from '@controls/Search';
import { Loading, Module } from '@stateless';
import { getRequest } from '@utils/api';
import List from './List';

const Files = () => {
  const [carpetas, setCarpetas] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [search, setSearch] = useState('');
  const [path, setPath] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    const { data } = await getRequest({ url: `files?search=${search}&path=${path}` });
    setCarpetas(data.directorios);
    setArchivos(data.archivos);
    setLoading(false);
  };

  useEffect(() => {
    handleSearch();
  }, [search, path]);

  const onSearch = (search) => {
    setSearch(search);
  };

  const onPath = (path) => {
    setPath(path);
  };

  return (
    <Module icon='wrench' title='Explorador de Archivos'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'></Search>
      <Loading loading={loading}>
        <List carpetas={carpetas} archivos={archivos} onPath={onPath} path={path} />
      </Loading>
    </Module>
  );
};

export default Files;
