import { MONEDA } from '@config/constants';
import { FormInput } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Loading, Module, RowsNotFound, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { textDomicilio } from '@utils/cotizaciones';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import GenerarOrdenCompra from './GenerarOrdenCompra';
import { Button, Col, Row } from 'react-bootstrap';
import RowDetalle from '../components/RowDetalle';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const params = useParams();
  const [state, setState] = useState({
    reset: false,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fechaEntrega: moment().format('YYYY-MM-DD'),
    ubicaciones: [],
    monedaId: MONEDA.DOLAR,
    sucursales: [],
    almacenes: [],
    monedas: [],
    measures: [],
    providers: [],
    storehouses: [],
    proveedores: [],
    proveedorObj: null,
    proveedorName: '',
    detalles: [],
    usos_cfdi: [],
    usoCfdiId: null,
    subtotal: 0,
    total: 0,
    impuestos: 0,
    descuento: 0,
    observaciones: '',
    estatus: 'INICIADO',
    sucursalId: null,
    almacenId: null,
    sucursalDestinoId: null,
    almacenDestinoId: null,
    proveedor: null,
    productoName: '',
    productoObj: null,
    productos: [],
    tipoCambio: 0,
    estatus_pedidos: [
      { value: 'INICIADO', label: 'INICIADO' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'FINALIZADA', label: 'FINALIZADA' },
    ],
    agenteObj: null,
    agenteName: '',
    clienteObj: null,
    cliente: null,
    clienteName: '',
    referencia: '',
    domicilios: [],
    domicilioId: null,
  });

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const ObtenerPedido = useCallback(async (pedidoId, callback = () => {}) => {
    const {
      data: { encabezado, detalle },
    } = await getRequest({ url: `pedido/${pedidoId}` });
    try {
      setState((prevState) => ({
        ...prevState,
        ...encabezado,
        pedidoId,
        agenteSolicitanteObj: { value: encabezado.agente_solicitante._id, label: encabezado.agente_solicitante.nombre },
        agenteSolicitanteId: encabezado.agente_solicitante._id,
        sucursalId: encabezado.sucursal._id,
        almacenId: encabezado.almacen._id,
        agenteName: encabezado.agente_solicitante.nombre,
        numeroOrden: encabezado.numero_pedido,
        numeroCotizacion: encabezado.numero_cotizacion,
        fecha: moment(encabezado.fecha).format('YYYY-MM-DD'),
        clienteName: encabezado.customer.razon_social,
        cliente: encabezado.customer,
        clienteObj: {
          label: encabezado.customer.razon_social,
          value: encabezado.customer._id,
          ...encabezado.customer,
        },
        domicilioId: encabezado.customer.domicilio._id,
        estatus: encabezado.estatus_pedido,
        domicilios: [
          {
            value: encabezado.customer.domicilio._id || 1,
            label: textDomicilio(encabezado.customer.domicilio),
          },
        ],
        detalles: detalle.map((det) => {
          let measure = null,
            measureId = null;
          measure = det.unidadMedidaId.measure;
          measureId = det.unidadMedidaId._id;
          return {
            ...det,
            entregado_anterior: det.entregado,
            equipment: det.equipmentId,
            equipmentId: det.equipmentId._id,
            measure,
            measureId,
            existencia: det.existencia || 0,
            moneda: encabezado.moneda,
            noIdentificador: det.codigo,
            precio_original: det.precio,
            impuesto: det.iva,
            importe: det.importe,
            observaciones: det.observaciones,
          };
        }),
      }));
      callback();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const ObtenerTipoCambio = useCallback(async (fecha, callback = () => {}) => {
    const { data } = await getRequest({ url: `tipo-cambio/fecha`, params: { fecha } });
    if (!data?._id) {
      return notification({
        title: 'Advertencia',
        message: 'Se debe capturar el tipo de cambio del dia. Catálogos -> Tipos de cambio',
        type: 'warning',
      });
    }
    setState((prevState) => ({ ...prevState, tipoCambio: data.valor || 0 }));
    callback();
  }, []);

  const Inicializar = useCallback(async () => {
    const {
      data: { sucursales, almacenes, ubicaciones, monedas },
    } = await getRequest({ url: `pedidos-catalogos` });
    setState((prevState) => ({
      ...prevState,
      sucursales: sucursales.map((sucursal) => ({
        value: sucursal._id,
        label: `${sucursal.name}`,
      })),
      almacenes: almacenes.map((almacen) => ({
        value: almacen._id,
        label: `${almacen.name}`,
      })),
      monedas: monedas.map((moneda) => ({
        value: moneda.enumerador,
        label: `${moneda.descripcion}`,
      })),
      ubicaciones,
      isLoadingForm: false,
    }));
  }, []);

  useEffect(() => {
    const { pedidoId } = params;
    const fecha = moment().format('YYYY-MM-DD');
    ObtenerPedido(pedidoId, () => {
      ObtenerTipoCambio(fecha, Inicializar);
    });
  }, [params, ObtenerPedido, ObtenerTipoCambio, Inicializar]);

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.estatus_pedido + '')) {
      validate.success = false;
      validate.message = 'Estatus es requerido.';
    } else if (isEmpty(params.fecha + '')) {
      validate.success = false;
      validate.message = 'Fecha es requerido.';
    } else if (!isMongoId(params.sucursalId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    } else if (!isMongoId(params.almacenId + '')) {
      validate.success = false;
      validate.message = 'Almácen es requerido.';
    } else if (isEmpty(params.monedaId + '')) {
      validate.success = false;
      validate.message = 'Moneda es requerido.';
    } else if (isEmpty(params.tipo_cambio + '')) {
      validate.success = false;
      validate.message = 'Tipo cambio es requerido.';
    } else if (!isMongoId(params.clienteId + '')) {
      validate.success = false;
      validate.message = 'Cliente es requerido.';
    } else if (!isMongoId(params.domicilioId + '')) {
      validate.success = false;
      validate.message = 'Domicilio es requerido.';
    } else if (params.detalles.length === 0) {
      validate.success = false;
      validate.message = 'No hay partidas asociados al pedido';
    }

    params.detalles.forEach((detalle) => {
      if (isEmpty(detalle.descripcion + '')) {
        validate.success = false;
        validate.message = 'Descripción es requerido.';
      } else if (isEmpty(detalle.cantidad + '') || detalle.cantidad <= 0) {
        validate.success = false;
        validate.message = 'Cantidad es requerido.';
      } else if (detalle.entregado > detalle.cantidad) {
        validate.success = false;
        validate.message = 'Entregado no debe ser mayor que la cantidad.';
      } else if (detalle.entregado - detalle.entregado_anterior < 0) {
        validate.success = false;
        validate.message = 'Entregado no debe ser menor que el entregado anterior.';
      } else if (detalle.entregado - detalle.entregado_anterior > detalle.existencia) {
        validate.success = false;
        validate.message = 'No hay suficiente existencia para entregar el equipo.';
      }
    });

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const handleRegister = async (event) => {
    event && event.preventDefault();
    const {
      pedidoId,
      numeroCotizacion,
      agenteSolicitanteId,
      monedaId,
      tipoCambio,
      almacenId,
      cliente,
      domicilioId,
      referencia,
      numeroOrden,
      fecha,
      estatus,
      sucursalId,
      observaciones,
      detalles,
    } = state;

    const data = {
      numero_pedido: numeroOrden,
      numero_cotizacion: numeroCotizacion,
      agente_solicitante_id: agenteSolicitanteId,
      fecha,
      monedaId,
      almacenId,
      clienteId: cliente ? cliente._id : '',
      domicilioId,
      referencia,
      tipo_cambio: tipoCambio,
      estatus_pedido: estatus,
      sucursalId,
      observaciones,
      detalles: detalles.map((detalle) => ({
        ...detalle,
      })),
    };
    if (validations(data)) {
      await putRequest({ url: `pedido/${pedidoId}`, body: data });
      setTimeout(() => navigate(0), 1000);
    }
  };

  const handleEntregarYRegister = (event) => {
    event.preventDefault();
    const { detalles } = state;
    setState(
      (prevState) => ({
        ...prevState,
        detalles: detalles.map((x) => ({
          ...x,
          entregado: x.cantidad,
        })),
      }),
      handleRegister
    );
  };

  const onChangeDetalle = (index, name, event) => {
    if (['cantidad', 'entregado'].includes(name)) {
      setState((prevState) => ({
        ...prevState,
        detalles: prevState.detalles.map((x, i) => {
          if (i === index) x[name] = Number(cleanNumber(event.target.value));
          return { ...x };
        }),
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => {
        if (i === index) x[name] = event.target.value;
        return { ...x };
      }),
    }));
  };

  const renderDetalles = () => {
    if (state.detalles.length === 0) return <RowsNotFound message='No se han agregado detalles.' colSpan={12} />;
    return state.detalles.map((detalle, i) => <RowDetalle key={i} detalle={detalle} onChangeDetalle={onChangeDetalle.bind(null, i)} />);
  };

  const renderView = () => {
    const { isLoading, observaciones } = state;
    return (
      <div>
        <div className='panel panel-default'>
          <div className='panel-body'></div>
        </div>
        <div className='panel panel-default panel-table' style={{ overflow: 'scroll' }}>
          <div style={{ overflow: 'scroll' }}>
            <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
              <thead>
                <tr>
                  <th width='10%'>#</th>
                  <th>Descripción</th>
                  <th width='10%'>Unidad</th>
                  <th>Cantidad</th>
                  <th>Entregado</th>
                  <th>Existencia</th>
                  <th width='15%'>Estatus</th>
                  <th>Observaciónes</th>
                  <th>Entregado por</th>
                  <th width='5%'>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                </tr>
              </thead>
              <tbody>{renderDetalles()}</tbody>
            </table>
          </div>
          <div className='panel-body'>
            <Row>
              <Col sm='12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </div>
          <div className='panel-footer'>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='btn btn-primary pull-right  mr-5' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
                <Button onClick={handleEntregarYRegister} className='btn btn-success pull-right mr-5' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Entregar todo y guardar
                </Button>
                <GenerarOrdenCompra pedidoId={params.pedidoId} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Pedidos' title='Editar pedido'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Edit.propTypes = {};

export default Edit;
