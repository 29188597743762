import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditCondicionesPagoEquipos = ({ condicionespagoequiposId, loadCondicionesPagoEquipos }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [condicion, setCondicion] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setCondicion('');
  };

  const open = async () => {
    const {
      data: { condicion },
    } = await getRequest({ url: `condicionespagoequipos/${condicionespagoequiposId}` });
    setShowModal(true);
    setCondicion(condicion);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      condicion: condicion.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `condicionespagoequipos/${condicionespagoequiposId}`, body: data });
      loadCondicionesPagoEquipos();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.condicion + '')) {
      validate.success = false;
      validate.message = 'Condición es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar condición de pago de equipos' isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='condicion' title='Condición' required>
              <Input name='condicion' onChange={(e) => setCondicion(e.target.value)} value={condicion} placeholder='Condición de pago de equipos' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditCondicionesPagoEquipos.propTypes = {
  condicionespagoequiposId: PropTypes.string.isRequired,
  loadCondicionesPagoEquipos: PropTypes.func.isRequired,
};

export default EditCondicionesPagoEquipos;
