export const AreasDefault = [
  {
    nombre: 'Planta Baja',
    showZonas: true,
    clasificaciones: [],
    zonas: [
      {
        nombre: 'Fachada calle',
        clasificaciones: [],
      },
      {
        nombre: 'Cochera',
        clasificaciones: [],
      },
      {
        nombre: 'Pasillo lateral izquierdo',
        clasificaciones: [],
      },
      {
        nombre: 'Vestibulo',
        clasificaciones: [],
      },
      {
        nombre: 'Estudio',
        clasificaciones: [],
      },
      {
        nombre: 'Baño Estudio',
        clasificaciones: [],
      },
      {
        nombre: 'Pasillo de Servicio',
        clasificaciones: [],
      },
      {
        nombre: 'BAR',
        clasificaciones: [],
      },
      {
        nombre: 'Sala',
        clasificaciones: [],
      },
      {
        nombre: 'baño p/baja',
        clasificaciones: [],
      },
      {
        nombre: 'Comedor',
        clasificaciones: [],
      },
      {
        nombre: 'Alacena',
        clasificaciones: [],
      },
      {
        nombre: 'Cocina',
        clasificaciones: [],
      },
      {
        nombre: 'Bodega',
        clasificaciones: [],
      },
      {
        nombre: 'Estancia Terraza',
        clasificaciones: [],
      },
      {
        nombre: 'Patio Trasero',
        clasificaciones: [],
      },
      {
        nombre: 'baño patio trasero',
        clasificaciones: [],
      },
    ],
  },
  {
    nombre: 'Planta 1er nivel',
    showZonas: true,
    clasificaciones: [],
    zonas: [
      {
        nombre: 'Recámara Principal',
        clasificaciones: [],
      },
      {
        nombre: 'Baño Recamara principal',
        clasificaciones: [],
      },
      {
        nombre: 'Vestidor Rec. Principal',
        clasificaciones: [],
      },
      {
        nombre: 'Pasillo primer piso',
        clasificaciones: [],
      },
      {
        nombre: 'Cuarto Lavado',
        clasificaciones: [],
      },
      {
        nombre: 'Bodega',
        clasificaciones: [],
      },
      {
        nombre: 'Escalera Servicio',
        clasificaciones: [],
      },
      {
        nombre: 'Recamara dos',
        clasificaciones: [],
      },
      {
        nombre: 'Baños Recamara dos',
        clasificaciones: [],
      },
      {
        nombre: 'Terraza recamara dos',
        clasificaciones: [],
      },
      {
        nombre: 'Recamara tres',
        clasificaciones: [],
      },
      {
        nombre: 'Baño recamara tres',
        clasificaciones: [],
      },
    ],
  },
  // {
  //   nombre: 'Planta 2do nivel',
  //   showZonas: false,
  //   zonas: [
  //     {
  //       nombre: 'pasillo 2do piso',
  //       clasificaciones: []
  //     },
  //     {
  //       nombre: 'Mezanine',
  //       clasificaciones: []
  //     },
  //     {
  //       nombre: 'Cuarto de Servicio',
  //       clasificaciones: []
  //     },
  //     {
  //       nombre: 'Cinema',
  //       clasificaciones: []
  //     },
  //     {
  //       nombre: 'baño',
  //       clasificaciones: []
  //     },
  //     {
  //       nombre: 'SITE',
  //       clasificaciones: []
  //     },
  //   ]
  // },
];

export const InicializarArea = (area, clasificaciones) => {
  return {
    ...area,
    clasificaciones: [
      ...clasificaciones.map((clasificacion) => ({
        ...clasificacion,
        clasificacionId: clasificacion._id,
        subclasificaciones: [
          ...clasificacion.subclasificaciones.map((subclasificacion) => ({
            ...subclasificacion,
            subclasificacionId: subclasificacion._id,
            cantidad: 0,
            cantidad_autorizada: 0,
          })),
        ],
      })),
    ],
    zonas: [
      ...area.zonas.map((zona) => ({
        ...zona,
        clasificaciones: [
          ...clasificaciones.map((clasificacion) => ({
            ...clasificacion,
            clasificacionId: clasificacion._id,
            subclasificaciones: [
              ...clasificacion.subclasificaciones.map((subclasificacion) => ({
                ...subclasificacion,
                subclasificacionId: subclasificacion._id,
                cantidad: 0,
                cantidad_autorizada: 0,
              })),
            ],
          })),
        ],
      })),
    ],
  };
};
