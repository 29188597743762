import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { Icon } from '@stateless';
import { getRequest } from '@utils/api';
import InputNumber from '@components/forms/InputNumber';

const RowEquipo = ({ equipo, onChangeEquipo, onRemoveItemEquipo }) => {
  const onChangeEquipoHandler = (itemSelect) => {
    onChangeEquipo({
      equipoId: itemSelect,
    });
  };

  const loadOptions = useCallback(async (inputValue, callback) => {
    const { data } = await getRequest({ url: `equipments-autocomplete`, params: { query: inputValue } });
    callback(data);
  }, []);

  const onChangeEquipoInput = (key, e) => {
    const { value } = e.target;
    onChangeEquipo({
      [key]: value,
    });
  };

  const onRemoveItemEquipoHandler = () => {
    onRemoveItemEquipo();
  };

  return (
    <tr>
      <td>
        <AsyncSelect
          value={equipo.equipoId}
          loadOptions={loadOptions}
          onChange={onChangeEquipoHandler}
          getOptionLabel={(x) => `${x.code} ${x.name}`}
          getOptionValue={(x) => x._id}
          isClearable
          placeholder='Buscar...'
        />
      </td>
      <td>
        <InputNumber placeholder='Cantidad' className='form-control' onChange={(e) => onChangeEquipoInput('cantidad', e)} value={equipo.cantidad} />
      </td>
      <td>
        <button onClick={onRemoveItemEquipoHandler} className='btn btn-danger pull-right' type='button'>
          <Icon icon='trash' />
        </button>
      </td>
    </tr>
  );
};

RowEquipo.propTypes = {
  equipo: PropTypes.object,
  onChangeEquipo: PropTypes.func,
  onRemoveItemEquipo: PropTypes.func,
};

export default RowEquipo;
