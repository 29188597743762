import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteConfiguration = ({ configurationId, name, loadConfigurations }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`configuration/${configurationId}`}
      description={`¿Confirma que desea eliminar la configuración <strong><u> ${name}</u> </strong>?`}
      loadData={loadConfigurations}
    />
  );
};

DeleteConfiguration.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  configurationId: PropTypes.string.isRequired,
  loadConfigurations: PropTypes.func.isRequired,
};

export default DeleteConfiguration;
