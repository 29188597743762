import { DivFormGroup, Label, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { clean } from '@utils/formatter';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import AddPlazos from './AddPlazos';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputPercentage from '@components/forms/InputPercentage';

const NewEsquemas = ({ plazos, loadEsquemas }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [esquema, setEsquema] = useState('');
  const [minimo, setMinimo] = useState(0);
  const [maximo, setMaximo] = useState(0);
  const [descuentoMinimo, setDescuentoMinimo] = useState(0);
  const [descuentoMaximo, setDescuentoMaximo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setEsquema('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const collectionPlazos = plazos
      .filter((p) => document.getElementById(p._id).checked)
      .map((p) => ({
        plazoId: p._id,
      }));

    const data = {
      esquema: esquema.trim(),
      minimo: clean(minimo),
      maximo: clean(maximo),
      descuento_minimo: clean(descuentoMinimo),
      descuento_maximo: clean(descuentoMaximo),
      plazos: collectionPlazos,
    };

    if (validations(data)) {
      await postRequest({ url: `esquemas`, body: data });
      loadEsquemas();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.esquema + '')) {
      validate.success = false;
      validate.message = 'Esquema de pago es requerido.';
    }

    if (validate.success && params.minimo > params.maximo) {
      validate.success = false;
      validate.message = 'Anticipo mínimo no puede ser mayor que anticipo máximo.';
    }

    if (validate.success && params.descuento_minimo > params.descuento_maximo) {
      validate.success = false;
      validate.message = 'Descuento mínimo no puede ser mayor que descuento máximo.';
    }

    const { plazos } = params;

    if (validate.success && plazos.length > 0) {
      const unique = [...new Set(plazos.map((a) => a.plazoId))];

      if (unique.length !== plazos.length) {
        validate.success = false;
        validate.message = 'Hay plazos duplicados, favor de corregir.';
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} onSave={handleRegister} isLoading={isLoading} title='Nuevo Esquema'>
        <Row>
          <Col sm='12'>
            <DivFormGroup>
              <Label name='esquema' title='Esquema de Pago' required />
              <TextArea name='esquema' onChange={(e) => setEsquema(e.target.value)} defaultValue={esquema} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup>
              <Label name='minimo' title='Anticipo mínimo' />
              <InputPercentage name='minimo' onChange={(e) => setMinimo(e.target.value)} value={minimo} />
            </DivFormGroup>
            <DivFormGroup>
              <Label name='maximo' title='Anticipo máximo' />
              <InputPercentage name='maximo' onChange={(e) => setMaximo(e.target.value)} value={maximo} />
            </DivFormGroup>
            <DivFormGroup>
              <Label name='descuento_minimo' title='Descuento mínimo' />
              <InputPercentage name='descuento_minimo' onChange={(e) => setDescuentoMinimo(e.target.value)} value={descuentoMinimo} />
            </DivFormGroup>
            <DivFormGroup>
              <Label name='descuento_maximo' title='Descuento máximo' />
              <InputPercentage name='descuento_maximo' onChange={(e) => setDescuentoMaximo(e.target.value)} value={descuentoMaximo} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <AddPlazos {...{ plazos }} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewEsquemas.propTypes = {
  plazos: PropTypes.array.isRequired,
  loadEsquemas: PropTypes.func.isRequired,
};

export default NewEsquemas;
