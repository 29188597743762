import { DivFormGroup, Input, FormInput } from '@controls';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Card, Container } from 'react-bootstrap';
import { Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest, URL_FILES } from '@utils/api';
import { isMongoId } from 'validator';
import ContratoEquipoVenta from '../edit/ContratoEquipoVenta';
import ContratoMonitoreo from '../edit/ContratoMonitoreo';
import ImprimirContrato from './ImprimirContrato';
import DetalleDomicilio from './DetalleDomicilio';
import DetalleContacto from './DetalleContacto';
import { useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import {
  mapDomicilioFront,
  mapContactoFront,
  sumarImportes,
} from '@utils/contratos';

const Edit = () => {
  const { addNotification: notification } = useStoreNotification();
  const params = useParams();
  const [domicilios, setDomicilios] = useState([]);
  const [contactos, setContactos] = useState([]);

  const [state, setState] = useState({
    showModalImprimir: false,
    domicilios: [],
    contactos: [],
    titular: null,
    encargado: null,
    datosGenerarDocumento: [],
    catalogServicios: [],
    catalogoFormasPagoServicios: [],
    catalogoCondicionesPagoServicios: [],
    catalogoFormaPagoEquipos: [],
    catalogoTipoEstablecimientos: [],
    catalogoTipoCableados: [],
    monitoreo: [],
    monitoreoAdicionales: [],
    interactivos: [],
    interactivosAdicionales: [],
    correo_facturacion: '',
    total: '0',
    card: false,
    clabe: '',
    nombreBanco: '',
    fecha_venta_equipo: moment().format('YYYY-MM-DD'),
    loading: true,
    isLoading: false,
    domicilio_fiscal: null,
    domicilio_monitoreo: null,
    urlContrato: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const { customerId, adjuntoId } = params;
      const { data, estados, documentos } = await getRequest({ url: `customer/${customerId}/detail` });
      const response = await getRequest({ url: `customer/getDocumentoContratoVentaEquipo/${adjuntoId}` });

      const { contrato } = response.data;
      const documento = documentos.find((x) => x._id === contrato.documentoId);
      let domicilios = data.listDomicilios || [];
      domicilios = domicilios.map(mapDomicilioFront);
      let contactos = data.listContactos || [];
      contactos = contactos.map(mapContactoFront);
      const regimen = data.regimen;
      const datosGenerarDocumento = {
        razon_social: data.razon_social,
        nombre_comercial: data.nombre_comercial,
        celular: data.mobile,
        fijo: data.phone,
        correo: data.email,
      };
      setDomicilios(domicilios);
      setContactos(contactos);
      setState((prevState) => ({
        ...prevState,
        showModal: true,
        numero_contrato: contrato.numero_contrato,
        fecha: moment(contrato.fecha).local().format('YYYY-MM-DD'),
        correo_facturacion: contrato.correo_facturacion,
        titular: contrato.contacto_titular_id,
        encargado: contrato.contacto_encargado_pago_id,
        domicilio_fiscal: contrato.domicilio_fiscal_id,
        domicilio_monitoreo: contrato.domicilio_monitoreo_id,
        regimen: regimen,
        datosGenerarDocumento: datosGenerarDocumento,
        estados,
        nombreTipoDocumento: documento.nombre,
        total: contrato.total,
        catalogoFormasPagoServicios: response.data.catalogoFormasPagoServicios.map((x) => ({ ...x, checkFPS: x.check })),
        catalogoCondicionesPagoServicios: response.data.catalogoCondicionesPagoServicios.map((x) => ({ ...x, checkCPS: x.check })),
        catalogoFormaPagoEquipos: response.data.catalogoFormaPagoEquipos.map((x) => ({ ...x, checkFPE: x.check })),
        catalogoCondicionesPagoEquipos: response.data.catalogoCondicionesPagoEquipos.map((x) => ({ ...x, checkCPE: x.check })),
        catalogoTipoEstablecimientos: response.data.catalogoTipoEstablecimientos.map((x) => ({ ...x, checkTE: x.check })),
        catalogoTipoCableados: response.data.catalogoTipoCableados.map((x) => ({ ...x, checkTC: x.check })),
        equipo_seguridad: response.data.equipo_seguridad.map((x) => ({ ...x, check: x.check })),
        monitoreo: response.data.monitoreo,
        monitoreoAdicionales: response.data.monitoreoAdicionales,
        interactivos: response.data.interactivos,
        interactivosAdicionales: response.data.interactivosAdicionales,
        loading: false,
      }));
    };

    fetchData();
  }, [params]);

  const onChange = (event) => {
    const { name, value, checked, type } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: type === 'radio' || type === 'check' ? checked : value,
    }));
  };

  const onChangeServicios = (item, nombre, event) => {
    const { name, value, checked, type } = event.target;
    setState((prevState) => ({
      ...prevState,
      [nombre]: prevState[nombre].map((element, i) => {
        if (item === i) {
          return { ...element, [type === 'radio' ? 'check' : name]: type === 'radio' ? checked : value };
        }
        return { ...element, [type === 'radio' ? 'check' : name]: type === 'radio' ? false : element[type === 'radio' ? 'check' : name] };
      }),
    }));
  };

  const onChangeList = (item, nombre, name, value) => {
    setState((prevState) => ({
      ...prevState,
      [nombre]: prevState[nombre].map((element, i) => (item === i ? { ...element, [name]: value } : element)),
    }));
  };


  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (!isMongoId(params.domicilio_fiscal + '')) {
      validate.success = false;
      validate.message = 'Domicilio fiscal es requerido.';
    } else if (!isMongoId(params.domicilio_monitoreo + '')) {
      validate.success = false;
      validate.message = 'Domicilio de servicio es requerido.';
    } else if (!isMongoId(params.titular + '')) {
      validate.success = false;
      validate.message = 'Titular es requerido.';
    } else if (!isMongoId(params.encargado + '')) {
      validate.success = false;
      validate.message = 'Encargado es requerido.';
    }
    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const generarDocumento = async (event) => {
    event.preventDefault();
    const { customerId, adjuntoId } = params;
    const {
      correo_facturacion,
      monitoreo,
      monitoreoAdicionales,
      interactivos,
      interactivosAdicionales,
      catalogoFormasPagoServicios,
      catalogoCondicionesPagoServicios,
      total,
      clabe,
      nombreBanco,
      equipo_seguridad,
      catalogoFormaPagoEquipos,
      catalogoCondicionesPagoEquipos,
      catalogoTipoEstablecimientos,
      catalogoTipoCableados,
      fecha_venta_equipo,
      domicilio_fiscal,
      nombreTipoDocumento,
      titular,
      encargado,
      domicilio_monitoreo,
    } = state;
    let body = {
      titular: titular?._id,
      domicilio_fiscal: domicilio_fiscal?._id,
      encargado: encargado?._id,
      domicilio_monitoreo: domicilio_monitoreo?._id,
      adjuntoId,
      correo_facturacion,
      total: getTotal(nombreTipoDocumento),
      clabe,
    };
    let tipoDocumento = '';
    switch (nombreTipoDocumento.trim().toUpperCase()) {
      case 'CONTRATO DE MONITOREO':
        tipoDocumento = 'actualizarDocumentoContratoMonitoreo';
        body = {
          ...body,
          monitoreo,
          monitoreoAdicionales,
          interactivos,
          interactivosAdicionales,
          catalogoFormasPagoServicios: catalogoFormasPagoServicios,
          catalogoCondicionesPagoServicios: catalogoCondicionesPagoServicios,
          total,
          clabe,
          nombreBanco,
        };
        break;
      case 'CONTRATO DE VENTA DE EQUIPO':
        tipoDocumento = 'actualizarDocumentoContratoVentaEquipo';
        body = {
          ...body,
          equipo_seguridad,
          catalogoFormaPagoEquipos: catalogoFormaPagoEquipos,
          catalogoCondicionesPagoEquipos: catalogoCondicionesPagoEquipos,
          catalogoTipoEstablecimientos: catalogoTipoEstablecimientos,
          catalogoTipoCableados: catalogoTipoCableados,
          total,
          clabe,
          fecha_venta_equipo,
        };
        break;
    }
    if (validations(body)) {
      const { data } = await putRequest({ url: `customer/${tipoDocumento}/${customerId}/${adjuntoId}`, body });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        showModalImprimir: true,
        urlContrato: `${URL_FILES}/${data.archivo}`,
        adjuntoId: data._id,
      }));
    }
  };

  const getTotal = (nombre) => {
    const { equipo_seguridad, monitoreo, monitoreoAdicionales, interactivos, interactivosAdicionales } = state;
    if (nombre === 'Contrato de venta de equipo') return sumarImportes(equipo_seguridad).toString();
    return (
      sumarImportes(monitoreo) +
      sumarImportes(monitoreoAdicionales) +
      sumarImportes(interactivos) +
      sumarImportes(interactivosAdicionales)
    ).toString();
  };

  const renderTipoMonitoreo = (nombre) => {
    const {
      monitoreo,
      monitoreoAdicionales,
      interactivos,
      interactivosAdicionales,
      catalogoFormasPagoServicios,
      catalogoCondicionesPagoServicios,
      equipo_seguridad,
      catalogoFormaPagoEquipos,
      catalogoCondicionesPagoEquipos,
      catalogoTipoEstablecimientos,
      catalogoTipoCableados,
      card,
      clabe,
      nombreBanco,
      fecha_venta_equipo,
      loading,
    } = state;
    if (nombre === 'Contrato de venta de equipo') {
      return (
        <ContratoEquipoVenta
          monitoreo={monitoreo}
          monitoreoAdicionales={monitoreoAdicionales}
          interactivos={interactivos}
          catalogoCondicionesPagoEquipos={catalogoCondicionesPagoEquipos}
          equipo_seguridad={equipo_seguridad}
          catalogoFormaPagoEquipos={catalogoFormaPagoEquipos}
          catalogoTipoEstablecimientos={catalogoTipoEstablecimientos}
          catalogoTipoCableados={catalogoTipoCableados}
          fecha_venta_equipo={fecha_venta_equipo}
          total={getTotal(nombre)}
          loading={loading}
          onChangeList={onChangeList}
          onChangeServicios={onChangeServicios}
          onChange={onChange}
          card={card}
          clabe={clabe}
        />
      );
    }
    return (
      <ContratoMonitoreo
        monitoreo={monitoreo}
        monitoreoAdicionales={monitoreoAdicionales}
        interactivos={interactivos}
        interactivosAdicionales={interactivosAdicionales}
        catalogoFormasPagoServicios={catalogoFormasPagoServicios}
        catalogoCondicionesPagoServicios={catalogoCondicionesPagoServicios}
        total={getTotal(nombre)}
        loading={loading}
        onChangeList={onChangeList}
        onChangeServicios={onChangeServicios}
        onChange={onChange}
        card={card}
        clabe={clabe}
        nombreBanco={nombreBanco}
      />
    );
  };

  const onChangeDomicilio = (tipo, domicilio) => {
    setState((prevState) => ({
      ...prevState,
      [tipo]: domicilio,
    }));
  };

  const closeImprimir = () => {
    setState((prevState) => ({ ...prevState, showModalImprimir: false }));
  };

  const onChangeContacto = (tipo, contacto) => {
    setState((prevState) => ({
      ...prevState,
      [tipo]: contacto,
    }));
  };

  const renderContract = () => {
    const {
      isLoading,
      correo_facturacion,
      loading,
      showModalImprimir,
      nombreTipoDocumento,
      domicilio_fiscal,
      domicilio_monitoreo,
      titular,
      encargado,
    } = state;
    const tipoMonitoreo = !loading ? renderTipoMonitoreo(nombreTipoDocumento) : <Loading />;
    return (
      <Module onClickBack={window.history.back} parent='Clientes' title='Generar contrato'>
        <Card>
          <Card.Body>
            <Container fluid>
              <Row>
                <Col sm='6'>
                  <FormInput name='numero_contrato' title='Numero contrato' value={state.numero_contrato} disabled />
                </Col>
                <Col sm='6'>
                  <DivFormGroup name='fecha' title='Fecha'>
                    <Input type='date' name='fecha' value={state.fecha} onChange={onChange} />
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <FormInput name='nombre' title='Razón social' value={state.datosGenerarDocumento.razon_social} disabled />
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <FormInput name='nombre' title='Nombre comercial' value={state.datosGenerarDocumento.nombre_comercial} disabled />
                </Col>
              </Row>
              <br />
              <DetalleDomicilio
                domicilio={domicilio_fiscal}
                tipo='domicilio_fiscal'
                label='Domicilio fiscal'
                setDomicilio={(data) => onChangeDomicilio('domicilio_fiscal', data)}
                domicilios={domicilios}
                customerId={params.customerId}
                setDomicilios={setDomicilios}
              />
              <br />
              <DetalleDomicilio
                domicilio={domicilio_monitoreo}
                tipo='domicilio_monitoreo'
                label='Domicilio monitoreo'
                setDomicilio={(data) => onChangeDomicilio('domicilio_monitoreo', data)}
                domicilios={domicilios}
                customerId={params.customerId}
                setDomicilios={setDomicilios}
              />

              <br />
              <DetalleContacto
                tipo='titular'
                label='Titular'
                contactos={contactos}
                setContactos={setContactos}
                contacto={titular}
                setContacto={(data) => onChangeContacto('titular', data)}
                customerId={params.customerId}
              />
              <br />
              <DetalleContacto
                tipo='encargado'
                label='Encargado'
                contactos={contactos}
                contacto={encargado}
                setContactos={setContactos}
                setContacto={(data) => onChangeContacto('encargado', data)}
                customerId={params.customerId}
              />
              <br />
              <Row>
                <Col sm='12'>
                  <DivFormGroup name='correo_facturacion' title='Correo electronico de facturación:'>
                    <Input
                      type='email'
                      name='correo_facturacion'
                      placeholder='Correo electronico de facturación:'
                      onChange={onChange}
                      value={correo_facturacion}
                    />
                  </DivFormGroup>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <br />
        {tipoMonitoreo}
        <Card>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button type='button' className='btn btn-success pull-right mr-5' onClick={generarDocumento}>
                  Guardar
                </Button>
                <Button onClick={window.history.back} className='btn btn-default pull-right mr-5' type='button'>
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ImprimirContrato
          showModal={showModalImprimir}
          onClose={closeImprimir}
          urlContrato={state.urlContrato}
          navigator={navigator}
          adjuntoId={state.adjuntoId}
        />
      </Module>
    );
  };

  const { loading } = state;
  const view = !loading ? renderContract() : <Loading />;
  return <Row className='fluid'>{view}</Row>;
};

Edit.propTypes = {};

export default Edit;
