import { Card } from 'react-bootstrap';
import { cleanNumber } from '@utils/formatter';
import { CustomerOption, DomicilioOption } from '@stateless';
import { DivFormGroup, FormInput } from '@controls';
import { getRequest } from '@utils/api';
import { hasPermission } from '@utils/permissions';
import { textDomicilio } from '@utils/cotizaciones';
import {
  useStoreSucursales,
  useStoreMonedas,
  useStoreTiposRecibos,
  useStoreUsosCfdis,
  useStoreFormasPagos,
  useStoreMetodosPagos,
} from '@stores/catalogs.store';
import AsyncSelect from 'react-select/async';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import InputCurrency from '@components/forms/InputCurrency';

const FormEncabezado = ({
  agente,
  cliente,
  domicilioId,
  domicilios,
  estatus_recibos,
  estatus,
  fecha,
  formaPago,
  metodoPago,
  moneda,
  numeroOrden,
  setState,
  sucursal,
  tipoCambio,
  tipoRecibo,
  usoCfdi,
}) => {
  let refDomicilio = useRef(null);
  const getStoreSucursales = useStoreSucursales();
  const getStoreMonedas = useStoreMonedas();
  const getTiposRecibos = useStoreTiposRecibos();
  const getStoreUsosCfdis = useStoreUsosCfdis();
  const getStoreFormasPagos = useStoreFormasPagos();
  const getStoreMetodosPagos = useStoreMetodosPagos();

  const onChange = (event) => {
    const { name, value } = event.target;
    setState({ [name]: value });
  };
  const onSelect = (campo, value) => {
    setState({
      [campo]: value,
    });
  };

  const onChangeTipoCambio = (event) => {
    const { name, value } = event.target;
    setState({ [name]: cleanNumber(value) });
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState({
        agenteId: itemSelect,
        maximo_descuento: itemSelect.maximo_descuento,
      });
    } else {
      setState({
        agenteId: null,
      });
    }
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      const domicilios = itemSelect.listDomicilios || [];
      const domicilioId = domicilios.length === 1 ? domicilios[0] : null;
      if (domicilios.length > 1 && refDomicilio.current) setTimeout(() => refDomicilio.current.inputRef.focus(), 300);
      setState({
        cliente: itemSelect,
        domicilioId,
        domicilios: domicilios,
      });
    } else {
      setState({
        cliente: null,
        domicilioId: null,
        domicilios: [],
      });
    }
  };

  const loadOptions = async (inputValue, callback) => {
    const clientes = await getRequest({ url: `customers-autocomplete`, params: { query: inputValue } });
    callback([...clientes.data.map((x) => ({ ...x }))]);
  };

  const loadOptionsAgentes = async (inputValue, callback) => {
    const { data } = await getRequest({ url: `agentes-autocomplete`, params: { query: inputValue } });
    callback(data);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col sm='3'>
              <DivFormGroup name='tipoRecibo' required title='Tipo de recibo'>
                <Select
                  value={tipoRecibo}
                  placeholder='Seleccione'
                  options={getTiposRecibos.data}
                  getOptionLabel={(x) => x.nombre}
                  getOptionValue={(x) => x._id}
                  onChange={onSelect.bind(this, 'tipoRecibo')}
                />
              </DivFormGroup>
            </Col>
            <Col sm='3'>
              <FormInput required title='Núm. Recibo' placeholder='Núm. Recibo' disabled name='numeroOrden' onChange={onChange} value={numeroOrden} />
            </Col>
            <Col xs='3'>
              <FormInput
                title='Fecha'
                type='date'
                required
                name='fecha'
                onChange={onChange}
                disabled={!hasPermission('cambiar-fecha')}
                value={fecha}
              />
            </Col>
            <Col xs='3'>
              <DivFormGroup name='estatus' required title='Estatus'>
                <Select value={estatus} placeholder='Seleccione' options={estatus_recibos} onChange={onSelect.bind(this, 'estatus')} />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs='3'>
              <DivFormGroup name='moneda' required title='Moneda'>
                <Select
                  value={moneda}
                  placeholder='Seleccione'
                  options={getStoreMonedas.data}
                  getOptionLabel={(x) => x.descripcion}
                  getOptionValue={(x) => x.enumerador}
                  onChange={onSelect.bind(this, 'moneda')}
                />
              </DivFormGroup>
            </Col>
            <Col xs='3'>
              <DivFormGroup name='tipoCambio' title='Tipo de cambio'>
                <InputCurrency
                  id='tipoCambio'
                  name='tipoCambio'
                  disabled={!hasPermission('cambiar-tipo-cambio')}
                  value={tipoCambio}
                  onChange={onChangeTipoCambio}
                  prefix='$ '
                />
              </DivFormGroup>
            </Col>
            <Col xs='3'>
              <DivFormGroup name='agente' required title='Agente'>
                <AsyncSelect
                  value={agente}
                  loadOptions={loadOptionsAgentes}
                  onChange={onChangeAgente}
                  getOptionLabel={(x) => `${x.nombre}`}
                  getOptionValue={(x) => x._id}
                  isClearable
                />
              </DivFormGroup>
            </Col>
            <Col xs='3'>
              <DivFormGroup name='sucursal' required title='Sucursal'>
                <Select
                  value={sucursal}
                  placeholder='Seleccione'
                  options={getStoreSucursales.data}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x._id}
                  onChange={onSelect.bind(this, 'sucursal')}
                />
              </DivFormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Row>
            <Col sm='8'>
              <DivFormGroup name='clienteName' required title={'Cliente'}>
                <AsyncSelect
                  value={cliente}
                  loadOptions={loadOptions}
                  onChange={onChangeCliente}
                  getOptionLabel={(x) => `${x.razon_social}`}
                  getOptionValue={(x) => x._id}
                  isClearable
                  placeholder={'Buscar por rázon social, nombre comercial, RFC '}
                  components={{ Option: CustomerOption }}
                />
              </DivFormGroup>
            </Col>
            <Col sm='4'>
              <FormInput title='RFC' disabled name='clienteRfc' onChange={onChange} value={cliente ? cliente.rfc : ''} />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <DivFormGroup name='domicilioId' required title='Domicilio'>
                <Select
                  value={domicilioId}
                  ref={(ref) => {
                    refDomicilio = ref;
                  }}
                  placeholder='Seleccione un domicilio'
                  options={domicilios}
                  isDisabled={!cliente}
                  getOptionLabel={(x) => textDomicilio(x)}
                  getOptionValue={(x) => x._id}
                  components={{ Option: DomicilioOption }}
                  onChange={onSelect.bind(this, 'domicilioId')}
                />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='4'>
              <DivFormGroup required title='Forma de Pago' name='formas_pagos'>
                <Select
                  value={formaPago}
                  placeholder='Seleccione'
                  options={getStoreFormasPagos.data}
                  getOptionLabel={(x) => `${x.codigo} ${x.descripcion}`}
                  getOptionValue={(x) => x._id}
                  onChange={onSelect.bind(this, 'formaPago')}
                />
              </DivFormGroup>
            </Col>
            <Col sm='4'>
              <DivFormGroup required title='Método de Pago' name='metodos_pagos'>
                <Select
                  value={metodoPago}
                  placeholder='Seleccione'
                  options={getStoreMetodosPagos.data}
                  getOptionLabel={(x) => `${x.codigo} ${x.descripcion}`}
                  getOptionValue={(x) => x._id}
                  onChange={onSelect.bind(this, 'metodoPago')}
                />
              </DivFormGroup>
            </Col>
            <Col sm='4'>
              <DivFormGroup required title='Uso CFDI' name='usos_cfdis'>
                <Select
                  value={usoCfdi}
                  placeholder='Seleccione'
                  options={getStoreUsosCfdis.data}
                  getOptionLabel={(x) => `${x.codigo} ${x.descripcion}`}
                  getOptionValue={(x) => x._id}
                  onChange={onSelect.bind(this, 'usoCfdi')}
                />
              </DivFormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

FormEncabezado.propTypes = {
  numeroOrden: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fecha: PropTypes.string,
  fecha_vencimiento: PropTypes.string,
  estatus: PropTypes.object,
  sucursal: PropTypes.object,
  cliente: PropTypes.object,
  agente: PropTypes.object,
  maximo_descuento: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tipoCambio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  domicilioId: PropTypes.object,
  domicilios: PropTypes.array,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  agenteId: PropTypes.object,
  tipoRecibo: PropTypes.string,
  estatus_recibos: PropTypes.array,
  formaPago: PropTypes.object,
  metodoPago: PropTypes.object,
  usoCfdi: PropTypes.object,
  moneda: PropTypes.object,
};

export default FormEncabezado;
