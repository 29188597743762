import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';

const FormGroupIcon = ({ title, icon, children }) => {
  return (
    <div className='form-group'>
      <div className='input-group'>
        <span className='input-group-text'>
          <Icon icon={icon} title={title} />
        </span>
        {children}
      </div>
    </div>
  );
};

FormGroupIcon.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormGroupIcon;
