import { DOLAR, EURO, PESO } from '@config/constants';
import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ noCuenta: initialNoCuenta, clabe: initialClabe, bancoId: initialBancoId, moneda: initialMoneda,nombre: initialNombre,sucursal: initialSucursal, noSucursal: initialNoSucursal, titular:initialTitular, convenio: initialConvenio, comentarios:initialComentarios ,swift: initialSwift , itemId, bancos, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [noCuenta, setNoCuenta] = useState('');
  const [sucursal, setSucursal] = useState('');
  const [noSucursal, setNoSucursal] = useState('');
  const [titular, setTitular] = useState('');
  const [convenio, setConvenio] = useState('');
  const [clabe, setClabe] = useState('');
  const [swift, setSwift] = useState('');
  const [bancoId, setBancoId] = useState('');
  const [moneda, setMoneda] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [isLoading] = useState(false);

  useEffect(() => {
    if (showModal) {
      setNombre(initialNombre);
      setNoCuenta(initialNoCuenta);
      setSucursal(initialSucursal);
      setNoSucursal(initialNoSucursal);
      setTitular(initialTitular);
      setConvenio(initialConvenio);
      setSwift(initialSwift);
      setClabe(initialClabe);
      setBancoId(initialBancoId._id);
      setMoneda(initialMoneda);
      setComentarios(initialComentarios);
    }
  }, [showModal, initialNoCuenta, initialNombre,initialSucursal,initialNoSucursal,initialTitular,initialConvenio,initialSwift, initialComentarios,initialClabe, initialBancoId, initialMoneda]);

  const close = () => {
    setShowModal(false);
    setNoCuenta('');
    setClabe('');
    setBancoId('');
    setMoneda('');
  };

  const open = () => {
    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      nombre,
      noCuenta,
      sucursal,
      noSucursal,
      titular,
      convenio,
      swift,
      clabe,
      bancoId,
      moneda,
      comentarios
    };

    if (validations(data)) {
      await putRequest({ url: `cuentas-bancarias/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.noCuenta + '')) {
      validate.success = false;
      validate.message = 'Número de cuenta es requerido.';
    } else if(validate.success && isEmpty(params.nombre + '')){
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }else if(validate.success && isEmpty(params.sucursal + '')){
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    }else if(validate.success && isEmpty(params.noSucursal + '')){
      validate.success = false;
      validate.message = 'Número de Sucursal es requerido.';
    }else if (validate.success && isEmpty(params.clabe + '')) {
      validate.success = false;
      validate.message = 'Número clabe es requerido.';
    }else if(validate.success && isEmpty(params.titular + '')){
      validate.success = false;
      validate.message = 'Titular es requerido.';
    } else if(validate.success && isEmpty(params.convenio + '')){
      validate.success = false;
      validate.message = 'Convenio es requerido.';
    }else if (validate.success && !isMongoId(params.bancoId + '')) {
      validate.success = false;
      validate.message = 'Banco es requerido.';
    } else if(validate.success && isEmpty(params.swift + '')){
      validate.success = false;
      validate.message = 'SWIFT es requerido.';
    }else if (validate.success && isEmpty(params.moneda + '')) {
      validate.success = false;
      validate.message = 'Tipo de moneda es requerido.';
    }else if(validate.success && isEmpty(params.comentarios  + '')){
      validate.success = false;
      validate.message = 'Comentarios es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
        <Col sm='6'>
            <DivFormGroup name='nombre' title='Nombre' required>
              <input
                type='text'
                id='nombre'
                name='nombre'
                className='form-control'
                onChange={(e) => setNombre(e.target.value)}
                value={nombre}
                placeholder='Nombre'
              />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='bancoId' title='Banco' required>
              <select name='bancoId' id='bancoId' className='form-control' onChange={(e) => setBancoId(e.target.value)} value={bancoId}>
                <option value=''>Seleccionar...</option>
                {bancos.map((b, i) => (
                  <option key={i} value={b._id}>
                    {b.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='12'>
            <DivFormGroup name='noCuenta' title='Número de cuenta' required>
              <input
                type='text'
                id='noCuenta'
                name='noCuenta'
                className='form-control'
                onChange={(e) => setNoCuenta(e.target.value)}
                value={noCuenta}
                placeholder='Número de cuenta'
              />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='sucursal' title='Sucursal' required>
              <input
                type='text'
                id='sucursal'
                name='sucursal'
                className='form-control'
                onChange={(e) => setSucursal(e.target.value)}
                value={sucursal}
                placeholder='Sucursal'
              />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='noSucursal' title='Número de Sucursal' required>
              <input
                type='text'
                id='noSucursal'
                name='noSucursal'
                className='form-control'
                onChange={(e) => setNoSucursal(e.target.value)}
                value={noSucursal}
                placeholder='Número de Sucursal'
              />
            </DivFormGroup>
          </Col>
          <Col sm='12'>
            <DivFormGroup name='titular' title='Titular' required>
              <input
                type='text'
                id='titular'
                name='titular'
                className='form-control'
                onChange={(e) => setTitular(e.target.value)}
                value={titular}
                placeholder='Titular'
              />
            </DivFormGroup>
          </Col>
          <Col sm='12'>
            <DivFormGroup name='convenio' title='Convenio' required>
              <input
                type='text'
                id='convenio'
                name='convenio'
                className='form-control'
                onChange={(e) => setConvenio(e.target.value)}
                value={convenio}
                placeholder='Convenio'
              />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='clabe' title='Número clabe' required>
              <input
                type='text'
                id='clabe'
                name='clabe'
                className='form-control'
                onChange={(e) => setClabe(e.target.value)}
                value={clabe}
                placeholder='Número clabe'
              />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='swift' title='SWIFT' required>
              <input
                type='text'
                id='swift'
                name='swift'
                className='form-control'
                onChange={(e) => setSwift(e.target.value)}
                value={swift}
                placeholder='SWIFT'
              />
            </DivFormGroup>
          </Col>
          <Col sm='12'>
            <DivFormGroup name='moneda' title='Tipo de moneda' required>
              <select name='moneda' id='moneda' className='form-control' onChange={(e) => setMoneda(e.target.value)} value={moneda}>
                <option value=''>Seleccionar...</option>
                <option value={PESO}>Pesos</option>
                <option value={DOLAR}>Dolares</option>
                <option value={EURO}>Euros</option>
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='12'>
            <DivFormGroup name='comentario' title='Comentarios' required>
              <input
                type='text'
                id='comentarios'
                name='comentarios'
                className='form-control'
                onChange={(e) => setSucursal(e.target.value)}
                value={comentarios}
                placeholder='Comentarios'
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  nombre:PropTypes.string.isRequired,
  noCuenta: PropTypes.string.isRequired,
  sucursal:PropTypes.string.isRequired,
  noSucursal:PropTypes.string.isRequired,
  titular:PropTypes.string.isRequired,
  convenio:PropTypes.string.isRequired,
  clabe: PropTypes.string.isRequired,
  bancoId: PropTypes.object.isRequired,
  swift:PropTypes.string.isRequired,
  moneda: PropTypes.string,
  comentarios:PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  bancos: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Edit;
