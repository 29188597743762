import { Input } from '@controls';
import { Icon } from '@controls/Stateless';
import TextCurrency from '@controls/TextCurrency';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import {
  calcularPrecioYMoneda,
  calculatePrecioServicio,
  esEquipo,
  esKit,
  getDescuentoDetalle,
  getImporteDetalle,
  getIVADetalle,
  getSubtotalDetalle,
  getTotalDetalle,
} from '@utils/cotizaciones';
import { cleanNumber } from '@utils/formatter';
import { format } from '@utils/parseCost';
import { hasPermission } from '@utils/permissions';
import CbTableTd from '@cbcomponents/CbTableTd';
import CbModalImage from '@cbcomponents/CbModalImage';
import ModalWorkforces from './ModalWorkforces';
import ModalAlternativos from './ModalAlternativos';
import { getRequest } from '@utils/api';
import { If } from '@controls';
import { useStoreMedidas } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';
import InputCurrency from '@components/forms/InputCurrency';
import InputPercentage from '@components/forms/InputPercentage';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    minHeight: '30px',
    height: '30px',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '30px',
  }),
};

const filtrarSevicios = (measure, parte) => {
  if (measure.tipo !== 'SERVICIO') return false;
  if (!parte.listCost) return true;
  return parte.listCost.map((y) => y.measureId).includes(measure._id);
};

const RowDetalle = ({ detalle, onChangeDetalle, moneda, tipoCambio, onRemoveDetalle, sucursal, onSelectServicio }) => {
  const [showModalImagen, setShowModalImagen] = useState(false);
  const [imagen, setImagen] = useState('');
  const getStoreMedidas = useStoreMedidas();

  const closeImagen = () => setShowModalImagen(false);
  const closeWorkforce = () => {
    onChangeDetalle({ ...detalle, showModalWorkforce: false });
  };

  const onDoubleClickSeleccionadoAlternativo = async (itemSelect) => {
    try {
      const { data } = await getRequest({ url: `equipments/${itemSelect.equipoId._id}` });
      const producto = data;
      const costo = producto.cost;
      let precio = costo;
      let precio_original = precio;
      let measure = null,
        measureId = null;

      measure = producto?.equipmentsMeasureId?.measure;
      measureId = producto?.equipmentsMeasureId?._id;

      precio = producto.precio;
      precio_original = precio;
      producto.moneda_original = producto.moneda;
      const newDetalle = {
        ...detalle,
        measureId: measureId,
        relacionadoId: producto._id,
        descripcion: producto.name.toUpperCase(),
        noIdentificador: producto.code.toUpperCase(),
        precio_original: precio_original,
        measure: measure ? measure.toUpperCase() : null,
        precio,
        cantidad: 1,
        descuento: 0,
        iva: producto.iva || 16,
        importe: precio,
        tiene_sustitutos: producto.listAlternos && producto.listAlternos.length > 0,
        tiene_otro_proveedor: producto.listCostosProveedores && producto.listCostosProveedores.length > 1,
      };
      onChangeDetalle({
        ...newDetalle,
        ...calcularPrecioYMoneda(newDetalle, moneda, tipoCambio),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (name, event) => {
    onChangeDetalle({
      ...detalle,
      [name]: ['cantidad', 'precio'].includes(name) ? cleanNumber(event.target.value) : event.target.value,
    });
  };

  const onSelectUnidad = (name, measure) => {
    if (measure) {
      if (detalle.listCost && detalle.listCost.length > 0) {
        const calculate = calculatePrecioServicio(detalle, measure, sucursal);
        detalle.precio = calculate.precio;
        detalle.precio_original = calculate.precio_original;
        detalle.moneda = calculate.moneda;
      }
    }
    onChangeDetalle({
      ...detalle,
      measureId: measure,
      ...calcularPrecioYMoneda(detalle, moneda, tipoCambio),
    });
  };

  const onClickColapsarDetalle = () => {
    detalle.colapsado = !detalle.colapsado;
    onChangeDetalle(detalle);
  };

  const onClickCambiarServicio = () => {
    onChangeDetalle({ ...detalle, showModalWorkforce: !detalle.showModalWorkforce });
  };

  const onClickMostrarImagen = (imagen) => {
    setShowModalImagen(true);
    setImagen(imagen);
  };

  const onCloseAlternativos = () => {
    onChangeDetalle({ ...detalle, showModalAlternativos: false });
  };

  const openAlternativos = () => {
    onChangeDetalle({ ...detalle, showModalAlternativos: true });
  };
  return (
    <>
      <tr className={detalle.status !== 'ACTIVO' ? 'danger' : ''} title={detalle.status !== 'ACTIVO' ? 'Dado de baja o eliminado' : ''}>
        <CbTableTd>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                <TextCurrency>{detalle.precio_original}</TextCurrency>
                &nbsp; {detalle.moneda_original === 'PESO' ? 'MXN' : 'USD'}
              </Tooltip>
            }
          >
            <b>{detalle.noIdentificador}</b>
          </OverlayTrigger>
        </CbTableTd>
        <CbTableTd>
          <OverlayTrigger placement='top' overlay={<Tooltip>{detalle.descripcion}</Tooltip>}>
            <Input
              name='descripcion'
              className='input-sm td-input'
              value={detalle.descripcion}
              onChange={(e) => onChange('descripcion', e)}
              style={{ minWidth: '250px' }}
            />
          </OverlayTrigger>
        </CbTableTd>
        <CbTableTd>
          {esEquipo(detalle) || esKit(detalle) ? (
            detalle.measureId?.measure
          ) : (
            <Select
              value={detalle.measureId}
              name='measureId'
              placeholder='Seleccione'
              options={getStoreMedidas.data.filter((x) => filtrarSevicios(x, detalle))}
              onChange={(measure) => onSelectUnidad('measureId', measure)}
              getOptionLabel={(x) => x.measure}
              getOptionValue={(x) => x._id}
              styles={customStyles}
            />
          )}
        </CbTableTd>
        <CbTableTd>
          <InputNumber
            name='cantidad'
            disabled={detalle.tiene_servicio && detalle.listWorkForcesSetted?.length > 0}
            className='input-sm td-input'
            value={detalle.cantidad || 0}
            decimalLimit={4}
            onChange={(e) => onChange('cantidad', e)}
          />
        </CbTableTd>
        <CbTableTd>
          <InputCurrency
            name='precio'
            className='input-sm td-input'
            disabled={!(!esKit(detalle) && (hasPermission('cambiar-precios') || detalle.precio_ajustable))}
            value={detalle.precio || 0}
            onChange={(e) => onChange('precio', e)}
          />
        </CbTableTd>
        <CbTableTd isNumber>
          <OverlayTrigger placement='top' overlay={<Tooltip>$ {format(getDescuentoDetalle(detalle), 2)}</Tooltip>}>
            <InputPercentage
              name='descuento'
              className='input-sm td-input'
              disabled={!hasPermission('aplicar-descuento')}
              value={detalle.descuento || 0}
              onChange={(e) => onChange('descuento', e)}
            />
          </OverlayTrigger>
        </CbTableTd>
        <CbTableTd isNumber>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                <b>Importe: </b>
                <TextCurrency>{getImporteDetalle(detalle)}</TextCurrency> <br />
                <b>Descuento: </b>
                <TextCurrency>{getDescuentoDetalle(detalle)}</TextCurrency> <br />
                <b>Subtotal: </b>
                <TextCurrency>{getSubtotalDetalle(detalle)}</TextCurrency> <br />
                <b>IVA: </b>
                <TextCurrency>{getIVADetalle(detalle)}</TextCurrency> <br />
                <b>Total: </b>
                <TextCurrency>{getTotalDetalle(detalle)}</TextCurrency>
              </Tooltip>
            }
          >
            <span>
              <TextCurrency>{getImporteDetalle(detalle)}</TextCurrency>
            </span>
          </OverlayTrigger>
        </CbTableTd>
        <CbTableTd>
          <ButtonGroup className='pull-right'>
            <If condition={esKit(detalle)}>
              <Button variant='outline-success' size='sm' onClick={onClickColapsarDetalle}>
                <Icon icon={detalle.colapsado ? 'triangle-top' : 'triangle-bottom'} />
              </Button>
            </If>
            <If condition={detalle.listWorkForcesSetted?.length > 0}>
              <Button variant='outline-dark' size='sm' onClick={onClickCambiarServicio} title='Cambiar servicios'>
                <Icon icon='time' />
              </Button>
            </If>
            <If condition={detalle.tiene_sustitutos}>
              <Button variant='outline-success' size='sm' onClick={openAlternativos} title='Cambiar alternativo'>
                <Icon icon='retweet' />
              </Button>
            </If>
            <If condition={detalle.imagen?.thumbnail}>
              <Button variant='outline-dark' size='sm' onClick={() => onClickMostrarImagen(detalle?.imagen?.thumbnail)}>
                <Icon icon='eye-open' />
              </Button>
            </If>
            <Button variant='outline-danger' size='sm' onClick={onRemoveDetalle}>
              <Icon icon='remove' />
            </Button>
          </ButtonGroup>
        </CbTableTd>
      </tr>
      {showModalImagen && <CbModalImage show={showModalImagen} onClose={closeImagen} imagen={imagen} />}
      {detalle.showModalWorkforce && (
        <ModalWorkforces
          equipmentId={detalle.relacionadoId}
          equipmentName={detalle.name}
          equipmentCode={detalle.code}
          listWorkForcesSetted={detalle.listWorkForcesSetted}
          showModal={detalle.showModalWorkforce}
          onClose={closeWorkforce}
          onSelectServicio={onSelectServicio}
        />
      )}
      {detalle.showModalAlternativos && (
        <ModalAlternativos
          equipmentId={detalle.relacionadoId}
          showModal={detalle.showModalAlternativos}
          tipoCambio={tipoCambio}
          equipmentPrincipal={detalle}
          onClose={onCloseAlternativos}
          onSelectAlternativo={onDoubleClickSeleccionadoAlternativo}
        />
      )}
    </>
  );
};

RowDetalle.propTypes = {
  i: PropTypes.number,
  detalle: PropTypes.object,
  actualizarTotalesDetalles: PropTypes.func,
  setState: PropTypes.func,
  onChangeDetalle: PropTypes.func,
  onRemoveDetalle: PropTypes.func,
  moneda: PropTypes.string,
  tipoCambio: PropTypes.number,
  sucursal: PropTypes.string,
  onSelectServicio: PropTypes.func,
  closeWorkforce: PropTypes.func,
  equipmentPrincipal: PropTypes.object,
};

export default RowDetalle;
