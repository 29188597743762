import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectCliente from '@components/forms/SelectCliente';
import SelectCreateOrUpdateDomicilio from '@components/forms/SelectCreateOrUpdateDomicilio';
import SelectCreateOrUpdateContacto from '@components/forms/SelectCreateOrUpdateContacto';
import SelectCuentaMonitoreo from '@components/forms/SelectCuentaMonitoreo';

const Edit = ({ refresh, id }) => {
  const { addNotification: notification } = useStoreNotification();
  const [cliente, setCliente] = useState(null);
  const [domicilio, setDomicilio] = useState(null);
  const [contacto, setContacto] = useState(null);
  const [folio, setFolio] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [domicilios, setDomicilios] = useState([]);
  const [contactos, setContactos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [cuentaMonitoreo, setCuentaMonitoreo] = useState(null);
  const close = () => {
    setShowModal(false);
    setCliente(null);
    setFolio('');
    setDescripcion('');
    setDomicilio(null);
    setContacto(null);
    setDomicilios([]);
    setContactos([]);
    setCuentaMonitoreo(null);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `customers-registros/${id}` });
    setCliente(data.customerId);
    setFolio(data.folio);
    setDescripcion(data.descripcion);
    setDomicilio(data.domicilioId);
    setContacto(data.contactoId);
    setDomicilios((data.customerId && data.customerId.listDomicilios) || []);
    setContactos((data.customerId && data.customerId.listContactos) || []);
    setCuentaMonitoreo(data.cuenta_monitoreo);
    setShowModal(true);
    setLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      folio,
      descripcion,
      customerId: cliente?._id,
      domicilioId: domicilio?._id,
      contactoId: contacto?._id,
      cuenta_monitoreo: cuentaMonitoreo?._id,
    };
    setLoading(true);
    if (validations(data)) {
      await putRequest({ url: `customers-registros/${id}`, body: data });
      refresh();
      close();
    }
    setLoading(false);
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.folio + '')) {
      validate.success = false;
      validate.message = 'Folio es requerido.';
    } else if (isEmpty(params.descripcion + '')) {
      validate.success = false;
      validate.message = 'Descripción es requerido.';
    } else if (!isMongoId(params.customerId + '')) {
      validate.success = false;
      validate.message = 'Cliente es requerido.';
    } else if (!isMongoId(params.domicilioId + '')) {
      validate.success = false;
      validate.message = 'Domicilio es requerido.';
    } else if (!isMongoId(params.contactoId + '')) {
      validate.success = false;
      validate.message = 'Contacto es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      const listContactos = itemSelect.listContactos.map(x => ({ ...x, tipos: x.tipos.map(x => x.tipoContactoId) }));
      setCliente(itemSelect);
      setDomicilios(itemSelect.listDomicilios);
      setContactos(listContactos);
      setDomicilio(itemSelect.listDomicilios.length === 1 ? itemSelect.listDomicilios[0] : null);
      setContacto(listContactos.length === 1 ? listContactos[0] : null);
    } else {
      setCliente(null);
      setDomicilio(null);
      setContacto(null);
      setDomicilios([]);
      setContactos([]);
    }
  };

  return (
    <>
      <CbButtonOver icon='edit' title='Editar' onClick={open} type='success' />
      <CbModal title='Editar' show={showModal} onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='folio' title='Folio'>
              <Input name='folio' onChange={(e) => setFolio(e.target.value)} value={folio} placeholder='Folio' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='descripcion' title='Descripción'>
              <Input name='descripcion' onChange={(e) => setDescripcion(e.target.value)} value={descripcion} placeholder='Descripción' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='clienteName' required title='Cliente'>
              <SelectCliente value={cliente} onChange={onChangeCliente} name='clienteState' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SelectCreateOrUpdateDomicilio
              value={domicilio}
              name='contacto'
              onChange={(value) => setDomicilio(value)}
              setDomicilios={(value) => setDomicilios(value)}
              customerId={cliente?._id}
              domicilios={domicilios}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectCreateOrUpdateContacto
              value={contacto}
              name='contacto'
              contactos={contactos}
              customerId={cliente?._id}
              setContactos={(value) => setContactos(value)}
              onChange={(value) => setContacto(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='cuenta_monitoreo' title='Cuenta monitoreo'>
              <SelectCuentaMonitoreo
                value={cuentaMonitoreo}
                name='cuentaMonitoreo'
                onChange={(value) => setCuentaMonitoreo(value)}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  refresh: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default Edit;
