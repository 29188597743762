import PropTypes from 'prop-types';
import React from 'react';
import { Pagination } from 'react-bootstrap';

const CbPagination = ({ activePage, count = 0, pagination, onSelect }) => {
  const buttons = [];
  const totalPages = Math.ceil(count / pagination);
  const maxButtons = 10;
  const halfMaxButtons = Math.floor(maxButtons / 2);

  let startPage = Math.max(activePage - halfMaxButtons, 1);
  let endPage = Math.min(activePage + halfMaxButtons, totalPages);

  if (endPage - startPage < maxButtons - 1) {
    if (startPage === 1) {
      endPage = Math.min(startPage + maxButtons - 1, totalPages);
    } else if (endPage === totalPages) {
      startPage = Math.max(endPage - maxButtons + 1, 1);
    }
  }

  if (startPage > 1) {
    buttons.push(<Pagination.First key='first' onClick={() => onSelect(1)} />);
    buttons.push(<Pagination.Prev key='prev' onClick={() => onSelect(activePage - 1)} />);
    buttons.push(
      <Pagination.Item key={1} onClick={() => onSelect(1)}>
        {1}
      </Pagination.Item>
    );
    if (startPage > 2) {
      buttons.push(<Pagination.Ellipsis key='start-ellipsis' />);
    }
  }

  for (let page = startPage; page <= endPage; page++) {
    buttons.push(
      <Pagination.Item key={page} active={page === activePage} onClick={() => onSelect(page)}>
        {page}
      </Pagination.Item>
    );
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      buttons.push(<Pagination.Ellipsis key='end-ellipsis' />);
    }
    buttons.push(
      <Pagination.Item key={totalPages} onClick={() => onSelect(totalPages)}>
        {totalPages}
      </Pagination.Item>
    );
    buttons.push(<Pagination.Next key='next' onClick={() => onSelect(activePage + 1)} />);
    buttons.push(<Pagination.Last key='last' onClick={() => onSelect(totalPages)} />);
  }

  return <Pagination style={{ margin: 0 }}>{buttons}</Pagination>;
};

CbPagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  count: PropTypes.number,
  pagination: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CbPagination;
