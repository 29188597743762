import Search from '@controls/Search';
import DivFormGroup from '@controls/DivFormGroup';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import { Col, Row } from 'react-bootstrap';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import Select from 'react-select';
import useAuth from '@hooks/useAuth';

const ReporteWorkForces = (props) => {
  const { agente: agenteAuth } = useAuth();
  const [state, setState] = useState({
    data: [],
    quotations: [],
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
    fechaInicio: moment().format('YYYY-MM-01'),
    fechaFin: moment().format('YYYY-MM-DD'),
    storehouses: [],
    sucursales: [],
    clientesAEnviar: [],
    showModal: false,
    enviados: false,
    descuentos: [],
    tipos_pedidos: [],
    orden: 'numero_levantamiento',
    sucursalId: '0',
    agenteId: '0',
    agentes: [],
    loading: true,
    tipo: -1,
    estatus_levantamientos: {
      PENDIENTE: 'PENDIENTE',
      INICIADA: 'INICIADA',
      ENVIADA: 'ENVIADA',
      ACEPTADA: 'ACEPTADA',
      DECLINADA: 'DECLINADA',
      AUTORIZADA: 'AUTORIZADA',
      FINALIZADA: 'FINALIZADA',
      CANCELADA: 'CANCELADA',
      PAUSADA: 'PAUSADA',
      REMISIONADA: 'REMISIONADA',
    },
    estatus: [],
    estatus_levantamiento: null,
  });

  const loadCatalogos = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        estatus: [
          {
            value: '0',
            label: 'Todos los estatus',
          },
          ...Object.entries(prevState.estatus_levantamientos).map(([key, value]) => ({
            value: key,
            label: value,
          })),
        ],
        agente: agenteAuth,
        agenteId: agenteAuth,
        sucursalId: agenteAuth && agenteAuth.sucursalId,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadCatalogos();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [state.activePage, state.sucursalId, state.agenteId, state.estatus_levantamiento]);

  const onSearch = (params = { search: '' }) => {
    setState((prevState) => ({
      ...prevState,
      search: params.search,
      activePage: 1,
      fechaInicio: params.fechaInicio,
      fechaFin: params.fechaFin,
      tipo: params.tipo,
      orden: params.orden,
    }));
  };

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }));
  };

  const handleSearch = async () => {
    const { pagination, activePage, search, fechaFin, fechaInicio, tipo, sucursalId, agenteId, orden, estatus_levantamiento } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
      orden,
      sucursalId,
      agenteId,
      tipo,
      estatus: estatus_levantamiento,
    };
    const { data, count } = await postRequest({ url: `levantamientos-workforces/search`, params: { page, limit: pagination }, body });
    setState((prevState) => ({
      ...prevState,
      quotations: data,
      count,
      loading: false,
    }));
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const { activePage, loading, sucursalId, agenteId, estatus, quotations, count, pagination, estatus_levantamiento } = state;

  return (
    <Module title='Reporte de horas de servicio cotizadas'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col sm='3'>
            <DivFormGroup>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='home' title='sucursales' />
                </span>
                <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} name='sucursalId' />
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='user' title='Agentes' />
                </span>
                <SelectAgente value={agenteId} onChange={(value) => onSelect('agenteId', value)} name='agenteId' />
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='3'>
            <DivFormGroup>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='tags' title='Estatus' />
                </span>
                <Select
                  value={estatus_levantamiento}
                  onChange={(value) => onSelect('estatus_levantamiento', value)}
                  name='estatus_levantamiento'
                  options={estatus}
                />
              </div>
            </DivFormGroup>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <List {...state} {...props} list={quotations} loadData={onSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

export default ReporteWorkForces;
