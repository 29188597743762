import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableFooterBotton from '@cbcomponents/CbTableFooterBotton';
import Required from '@controls/Required';
import RowDetalle from './RowDetalle';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const INIT_DETALLE = {
  servicioId: null,
  measureId: null,
  cantidad: 1,
  name: '',
  listasPrecios: [],
  obj: null,
  descripcion: '',
};

const New = ({ unidades, listas_precios, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clave, setClave] = useState('');
  const [nombre, setNombre] = useState('');
  const [detalles, setDetalles] = useState([]);

  const close = () => {
    setShowModal(false);
    setDetalles([]);
    setNombre('');
    setClave('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setDetalles([
      {
        ...INIT_DETALLE,
        listasPrecios: listas_precios.map((x) => ({ listaPrecioId: x, precio: 0, descuento: 0 })),
      },
    ]);
    setNombre('');
    setClave('');
  };

  const onChange = (event) => {
    const { name, checked, type, value } = event.target;
    if (name === 'clave') {
      setClave(type === 'checkbox' ? checked : value);
    } else if (name === 'nombre') {
      setNombre(type === 'checkbox' ? checked : value);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const data = {
      clave: clave.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      detalles: detalles.map((x) => ({
        servicioId: x.servicio?._id,
        measureId: x.unidad?._id,
        cantidad: cleanNumber(x.cantidad),
      })),
    };

    if (validations(data)) {
      await postRequest({ url: 'tipos-monitoreos', body: data });
      loadData();
      close();
    }
  };

  const addItemServicio = () => {
    setDetalles([
      ...detalles,
      {
        ...INIT_DETALLE,
        listasPrecios: listas_precios.map((x) => ({ listaPrecioId: x, precio: 0, descuento: 0 })),
      },
    ]);
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.clave)) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre)) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else {
      for (let i = 0; i < params.detalles.length; i++) {
        const servicio = params.detalles[i];
        if (!isMongoId(servicio.servicioId + '')) {
          validate.success = false;
          validate.message = 'Servicio en servicios es requerido.';
          break;
        } else if (servicio.cantidad <= 0) {
          validate.success = false;
          validate.message = 'Cantidad en servicios, debe ser mayor a cero.';
          break;
        } else if (!isMongoId(servicio.measureId + '')) {
          validate.success = false;
          validate.message = 'Unidad en servicios es requerida.';
          break;
        }
      }
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChangeServicioDetalle = (index, servicio) => {
    setDetalles(detalles.map((x, i) => (i === index ? servicio : x)));
  };

  const onRemoveItemServicio = (index) => {
    setDetalles(detalles.filter((_, i) => i !== index));
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar' onSave={handleRegister} onClose={close} isLoading={isLoading}>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col>
                <FormInput title='Clave' name='clave' required onChange={onChange} value={clave} />
              </Col>
              <Col>
                <FormInput title='Nombre' name='nombre' required onChange={onChange} value={nombre} />
              </Col>
            </Row>
          </div>
        </div>
        <Row>
          <Col>
            <CbPanelTableResponsive
              title={
                <>
                  Servicios <Required />
                </>
              }
            >
              <thead>
                <tr>
                  <th width='20%'>Servicio</th>
                  <th width='30%'>Descripción</th>
                  <th width='20%'>Unidad</th>
                  <th width='20%'>Cantidad</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <CbTableBody colSpan={5}>
                {detalles.map((servicio, i) => (
                  <RowDetalle
                    key={i}
                    servicio={servicio}
                    unidades={unidades}
                    onChangeServicioDetalle={(servicio) => onChangeServicioDetalle(i, servicio)}
                    onRemoveItemServicio={() => onRemoveItemServicio(i)}
                    listas_precios={listas_precios}
                  />
                ))}
                <CbTableFooterBotton colSpan={5} onClick={addItemServicio} title='Agregar servicio' />
              </CbTableBody>
            </CbPanelTableResponsive>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  unidades: PropTypes.array.isRequired,
  listas_precios: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default New;
