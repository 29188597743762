import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteProviders = ({ providerId, name, loadProviders }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`provider/${providerId}`}
      description={`¿Confirma que desea eliminar el proveedor <strong><u> ${name}</u> </strong>?`}
      loadData={loadProviders}
    />
  );
};

DeleteProviders.propTypes = {
  providerId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  loadProviders: PropTypes.func.isRequired,
};

export default DeleteProviders;
