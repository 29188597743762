export default (target) => {
  if (!target) return {};
  const { value, name, type, checked } = target;
  switch (type) {
    case 'checkbox':
      return { [name]: checked };
    case 'radio':
      return { [name]: value === 'true' };
    case 'number':
      return { [name]: parseInt(value, 10) };
    default:
      return { [name]: value };
  }
};
