import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { getRequest, URL_FILES } from '@utils/api';
import PropTypes from 'prop-types';
import CbModal from '@cbcomponents/CbModal';
import { useNavigate } from 'react-router-dom';

const ModalOpcionesDocumentos = ({ show, ordenServicioId, pedidoId, onClose }) => {
  const navigate = useNavigate();
  const onDescargarOrdenServicio = async () => {
    const { data } = await getRequest({ url: `ordenes-servicio/pdf/${ordenServicioId}` });
    if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
  };

  const onDescargarPedido = async () => {
    const { data } = await getRequest({ url: `pedido/pdf/${pedidoId}` });
    if (data) window.open(`${URL_FILES}/${data.url}`, '_blank');
  };

  const onOpenOrdenesServicios = () => {
    onClose();
    setTimeout(() => navigate('/ordenes-servicio'), 1000);
  };

  const onOpenOrdenServicio = () => {
    onClose();
    setTimeout(() => navigate(`/ordenes-servicio/editar/${ordenServicioId}`), 1000);
  };

  const onOpenPedidos = () => {
    onClose();
    setTimeout(() => navigate('/pedidos'), 1000);
  };

  const onOpenPedido = () => {
    onClose();
    setTimeout(() => navigate(`/pedidos/edicion/${pedidoId}`), 1000);
  };

  return (
    <CbModal show={show} onClose={onClose} title='Descargar documentos'>
      <Row>
        {ordenServicioId && (
          <Col sm={pedidoId ? 6 : 12}>
            <div className='d-grid gap-2'>
              <Button onClick={onDescargarOrdenServicio} variant='primary block mb-5'>
                Descargar Orden Servicio
              </Button>
              <Button onClick={onOpenOrdenesServicios} variant='primary block mb-5'>
                Ir a ordenes de servicios
              </Button>
              <Button onClick={onOpenOrdenServicio} variant='primary block mb-5'>
                Editar orden de servicio
              </Button>
            </div>
          </Col>
        )}
        {pedidoId && (
          <Col sm={ordenServicioId ? 6 : 12}>
            <div className='d-grid gap-2'>
              <Button onClick={onDescargarPedido} variant='primary block mb-5'>
                Descargar Pedido
              </Button>
              <Button onClick={onOpenPedidos} variant='primary block mb-5'>
                Ir a pedidos
              </Button>
              <Button onClick={onOpenPedido} variant='primary block mb-5'>
                Editar pedido
              </Button>
            </div>
          </Col>
        )}
      </Row>
    </CbModal>
  );
};

ModalOpcionesDocumentos.propTypes = {
  show: PropTypes.bool,
  ordenServicioId: PropTypes.string,
  pedidoId: PropTypes.string,
  onClose: PropTypes.func,
};

export default ModalOpcionesDocumentos;
