import { DivFormGroup, Input, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectSucursal from '@components/forms/SelectSucursal';
import MultiSelectAgentes from '@components/forms/MultiSelectAgentes';

const EditStoreHouse = ({ storehouseId, loadStoreHouse }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nameStoreHouse, setNameStoreHouse] = useState('');
  const [sucursalId, setSucursalId] = useState(null);
  const [address, setAddress] = useState('');
  const [selectedAgentes, setSelectedAgentes] = useState([]);
  const [isLoading] = useState(false);

  const close = () => setShowModal(false);

  const open = async () => {
    const {
      data: { name, address, sucursalId, agentes },
    } = await getRequest({ url: `storehouse/${storehouseId}` });
    setShowModal(true);
    setNameStoreHouse(name);
    setSucursalId(sucursalId ? sucursalId._id : null);
    setSelectedAgentes(agentes.map((x) => ({ value: x._id, label: x.nombre })));
    setAddress(address);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      nameStoreHouse: nameStoreHouse.toUpperCase().trim(),
      address: address.toUpperCase().trim(),
      sucursalId,
      agentes: selectedAgentes.map((x) => x.value),
    };

    if (validations(data)) {
      await putRequest({ url: `storehouse/${storehouseId}`, body: data });
      loadStoreHouse();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nameStoreHouse + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.sucursalId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerida.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='nameStoreHouse' title='Nombre' required>
              <Input name='nameStoreHouse' placeholder='Nombre' onChange={(e) => setNameStoreHouse(e.target.value)} value={nameStoreHouse} />
            </DivFormGroup>
            <DivFormGroup name='address' title='Domicilio'>
              <TextArea name='address' placeholder='Domicilio' onChange={(e) => setAddress(e.target.value)} value={address} rows={3} />
            </DivFormGroup>
            <DivFormGroup name='sucursalId' title='Sucursal' required>
              <SelectSucursal value={sucursalId} onChange={(value) => setSucursalId(value)} />
            </DivFormGroup>
            <DivFormGroup name='agentes' title='Agentes encargados'>
              <MultiSelectAgentes value={selectedAgentes} onChange={(value) => setSelectedAgentes(value)} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditStoreHouse.propTypes = {
  storehouseId: PropTypes.string.isRequired,
  sucursales: PropTypes.array.isRequired,
  agentes: PropTypes.array,
  loadStoreHouse: PropTypes.func.isRequired,
};

export default EditStoreHouse;
