import React, { Fragment, useState } from 'react';
import { Icon, Loading } from '@stateless';
import { DivFormGroup } from '@controls';
import '@styles/btn-login.css';
import { useStoreNotification } from '@stores/catalogs.store';
import { Col, Container, Row, InputGroup, Form, Button } from 'react-bootstrap';
import useAuth from '@hooks/useAuth';
export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  // const getStoreTipoCambio = useStoreTipoCambio();
  const addNotification = useStoreNotification((state) => state.addNotification);
  const { login } = useAuth();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await login({ email, password: pass });
    } catch (error) {
      addNotification({ type: 'error', message: error.message });
    }
    setLoading(false);
  };
  return (
    <>
      <Container fluid style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col className='col-md-4 col-sm-1 col-xs-0'></Col>
          <Col className='col-md-4 col-sm-10 col-xs-12' style={{ height: '100%' }}>
            <Row className='login-center'>
              <Col>
                <Row>
                  <Col>
                    <img src='./img/logo_login.png' className='img-responsive' style={{ maxWidth: '20em', margin: 'auto' }} />
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col>
                    <form onSubmit={handleSubmit} className='form-horizontal'>
                      <Loading loading={loading}>
                        <DivFormGroup>
                          <InputGroup className='mb-3'>
                            <InputGroup.Text>
                              <Icon icon='user' />
                            </InputGroup.Text>
                            <Form.Control type='email' placeholder='Username' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                          </InputGroup>
                        </DivFormGroup>
                        <DivFormGroup>
                          <InputGroup className='mb-3'>
                            <InputGroup.Text>
                              <Icon icon='lock' />
                            </InputGroup.Text>
                            <Form.Control
                              type='password'
                              placeholder='Password'
                              name='pass'
                              value={pass}
                              maxLength='10'
                              onChange={(e) => setPass(e.target.value)}
                            />
                          </InputGroup>
                        </DivFormGroup>
                        <DivFormGroup>
                          <Button variant='primary' className='btn-block' type='submit' disabled={loading}>
                            {loading ? 'Cargando...' : 'INICIAR SESIÓN'}
                          </Button>
                        </DivFormGroup>
                        <p className='mb-1'>{/* <a href="#" onClick={onOpenModal.bind(this)}>¿Olvidaste tu contraseña?</a> */}</p>
                      </Loading>
                    </form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className='col-sm-3 col-xs-0'></Col>
        </Row>
      </Container>
    </>
  );
}

Login.propTypes = {};
