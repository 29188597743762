import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SelectCreateOrUpdateDomicilio from '@components/forms/SelectCreateOrUpdateDomicilio';

const DetalleDomicilio = ({ domicilio, tipo, label, setDomicilio, domicilios, setDomicilios, customerId }) => {
  const onChangeDomicilio = (updatedDomicilio) => {
    setDomicilio(updatedDomicilio);
  };

  return (
    <Row>
      <Col>
        <SelectCreateOrUpdateDomicilio
          domicilios={domicilios}
          value={domicilio}
          customerId={customerId}
          setDomicilios={setDomicilios}
          onChange={onChangeDomicilio}
          name={tipo}
          title={label}
        />
      </Col>
    </Row>
  );
};

DetalleDomicilio.propTypes = {
  domicilio: PropTypes.object,
  tipo: PropTypes.string,
  label: PropTypes.string,
  setDomicilio: PropTypes.func,
  domicilios: PropTypes.array,
  setDomicilios: PropTypes.func,
  customerId: PropTypes.string,
};

export default DetalleDomicilio;
