import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import List from './List';
import { Col, Row } from 'react-bootstrap';

const KitServicios = () => {
  const [catalogCanastas, setCatalogCanastas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (params = {}) => {
    const body = {
      search: params.search,
    };
    const { data, count } = await postRequest({ url: `kit-servicios/search`, body });
    setCatalogCanastas(data);
    setCount(count);
    setLoading(false);
    setSearch(params.search);
  };

  const renderList = () => {
    return <List {...{ catalogCanastas, count, search }} loadData={loadData} />;
  };

  const moduleList = !loading ? renderList() : <Loading />;

  return (
    <Module title='Kit de Servicios'>
      <Search onSearch={loadData} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <Link to='/kit-servicios/captura' className='btn btn-success pull-right'>
          <Icon icon='plus' /> Kit
        </Link>
      </Search>
      <Row>
        <Col sm='12'>{moduleList}</Col>
      </Row>
    </Module>
  );
};

KitServicios.propTypes = {};

export default KitServicios;
