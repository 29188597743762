import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import ListStoreHouse from './ListStoreHouse';
import NewStoreHouse from './NewStoreHouse';

const StoreHouse = () => {
  const [catalogStoreHouse, setCatalogStoreHouse] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination] = useState(20);
  const [activePage, setActivePage] = useState(1);
  const [agentes, setAgentes] = useState([]);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);

  const loadSucursales = useCallback(async (callback) => {
    const {
      data: { sucursales, agentes },
    } = await getRequest({ url: `storehouse/catalogos` });
    setSucursales(sucursales);
    setAgentes(agentes);
    if (callback) callback();
  }, []);

  useEffect(() => {
    loadSucursales(handleSearch);
  }, [loadSucursales]);

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count } = await postRequest({ url: `storehouse/search`, params: { page, limit: pagination }, body });
    setCatalogStoreHouse(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  const renderList = () => (
    <ListStoreHouse catalogStoreHouse={catalogStoreHouse} loadStoreHouse={handleSearch} sucursales={sucursales} agentes={agentes} />
  );

  const moduleList = !loading ? renderList() : <Loading />;

  return (
    <Module title='Almacenes'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <IfPermission action='crear'>
          <NewStoreHouse loadStoreHouse={handleSearch} sucursales={sucursales} agentes={agentes} />
        </IfPermission>
      </Search>
      {moduleList}
      <div className='text-center'>
        <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
      </div>
    </Module>
  );
};

StoreHouse.propTypes = {};

export default StoreHouse;
