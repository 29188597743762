import PropTypes from 'prop-types';
import React from 'react';
import CbBadge from './CbBadge';

const CbBadgeMoneda = ({ moneda }) => {
  return (
    <CbBadge type='primary' title={moneda} style={{ fontSize: '9px' }}>
      {moneda === 'DOLAR' ? 'USD' : 'MXN'}
    </CbBadge>
  );
};

CbBadgeMoneda.propTypes = {
  moneda: PropTypes.string.isRequired,
};
export default CbBadgeMoneda;
