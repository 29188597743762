import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { cleanNumber } from '@utils/formatter';
import { Input } from '@controls';
import InputNumber from '@components/forms/InputNumber';
const ESTATUS_DETALLES_PEDIDOS = [
  { value: 'PENDIENTE', label: 'PENDIENTE' },
  { value: 'PENDIENTE_ENTREGA', label: 'PENDIENTE ENTREGA' },
  { value: 'SIN_EXISTENCIA', label: 'SIN EXISTENCIA' },
  { value: 'NO_ENTREGADO', label: 'NO ENTREGADO' },
  { value: 'ENTREGADO', label: 'ENTREGADO' },
];
const RowDetalle = ({ detalle, onChangeDetalle }) => {
  const handleChangeDetalle = (field, event) => {
    let { name, value } = event.target;
    if (['cantidad', 'entregado'].includes(name)) value = Number(cleanNumber(event.target.value));
    onChangeDetalle({
      ...detalle,
      [field]: value,
    });
  };

  const handleSelectDetalle = (field, selectedOption) => {
    onChangeDetalle({
      ...detalle,
      [field]: selectedOption,
    });
  };

  return (
    <tr>
      <th style={{ padding: '2px 8px' }}>{detalle.noIdentificador}</th>
      <td style={{ padding: '2px 8px' }}>{detalle.descripcion}</td>
      <td style={{ padding: '2px 8px' }}>{detalle.measure}</td>
      <td style={{ padding: '2px 8px' }}>
        <InputNumber className='input-sm disabled' value={detalle.cantidad} onChange={(e) => handleChangeDetalle('cantidad', e)} name='cantidad' />
      </td>
      <td style={{ padding: '2px 8px' }}>
        <InputNumber className={`input-sm ${detalle.entregado > detalle.cantidad && 'is-invalid'}`} value={detalle.entregado} name='entregado' />
      </td>
      <td style={{ padding: '2px 8px' }}>
        <InputNumber className='input-sm disabled' style={{ width: '80px' }} value={detalle.existencia || 0} name='existencia' />
      </td>
      <td>
        <Select
          value={detalle.estatus_entrega}
          name='estatus_entrega'
          placeholder='Seleccione'
          options={ESTATUS_DETALLES_PEDIDOS}
          onChange={(selectedOption) => handleSelectDetalle('estatus_entrega', selectedOption)}
        />
      </td>
      <td>
        <Input
          name='observaciones'
          className='input-sm'
          value={detalle.observaciones}
          onChange={(e) => handleChangeDetalle('observaciones', e)}
          style={{ width: '100%', minWidth: '250px' }}
        />
      </td>
      <td style={{ padding: '2px 8px' }}>{detalle.entregado_por && detalle.entregado_por.name}</td>
      <td>{/* <a href="#" onClick={this.onClickRemoveDetalle.bind(this,i)} style={{ color: 'red' }} ><Icon icon="remove" /></a> */}</td>
    </tr>
  );
};

RowDetalle.propTypes = {
  detalle: PropTypes.shape({
    noIdentificador: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    measure: PropTypes.string.isRequired,
    cantidad: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    existenciaId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    listaExistencias: PropTypes.arrayOf(PropTypes.object).isRequired,
    entregado: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    existencia: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    apartado: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    por_recibir: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    estatus_entrega: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    observaciones: PropTypes.string.isRequired,
    entregado_por: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  i: PropTypes.number.isRequired,
  onChangeDetalle: PropTypes.func.isRequired,
  onSelectDetalle: PropTypes.func.isRequired,
};

export default RowDetalle;
