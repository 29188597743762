import React, { useEffect, useRef, useState } from 'react';
import { DivFormGroup, FormInput, Input, Label } from '@controls';
import Panel from '@controls/Panel';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import { Icon, Loading, Module, Saving } from '@stateless';
import Swal from 'sweetalert2';
import { getRequest, postRequest, postUploadRequest, urlPhoto } from '@utils/api';
import EnviarDocumento from './EnviarDocumentos';
import { Button, Col, Row } from 'react-bootstrap';
import useAuth from '@hooks/useAuth';
import { useParams, useNavigate } from 'react-router-dom';

const FirmaAgente = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { geolocation } = useAuth();
  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    isLoadingForm: true,
    fecha_hora_inicio: moment().format('YYYY-MM-DD HH:mm'),
    fecha_hora_fin: moment().format('YYYY-MM-DD HH:mm'),
    observaciones: '',
    no_concluyo_trabajo: '',
    comentarios_adicionales_cliente: '',
    ordenServicioId: null,
    observacionId: null,
    numero_orden_servicio: '',
    orden_servicio: null,
    actualizar: false,
  });
  const canvasRef = useRef(null);

  useEffect(() => {
    loadData(params.visitaId);
  }, [params.visitaId]);

  const loadData = async (visitaId) => {
    const { data } = await getRequest({ url: `ordenes-servicios-visitas/${visitaId}` });
    setState({
      ...state,
      isLoadingForm: false,
      actualizar: data.observacion ? true : false,
      observacionId: data.observacion ? data.observacion._id : '',
      ordenServicioId: data.ordenServicioId._id,
      clienteId: data.ordenServicioId.cliente?._id,
      fecha_hora_inicio: data.observacion
        ? moment(data.observacion.fecha_hora_inicio).format('YYYY-MM-DD HH:mm')
        : moment().format('YYYY-MM-DD HH:mm'),
      fecha_hora_fin: data.observacion ? moment(data.observacion.fecha_hora_fin).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'),
      observaciones: data.observacion ? data.observacion.observaciones : '',
      no_concluyo_trabajo: data.observacion ? data.observacion.no_concluyo_trabajo : '',
      comentarios_adicionales_cliente: data.observacion ? data.observacion.comentarios_adicionales_cliente : '',
      orden_servicio: data.ordenServicioId,
      numero_orden_servicio: data.ordenServicioId.numero_orden_servicio,
      correo: data.clienteId ? data.clienteId.email : '',
      estatus: data.estatus,
    });
    setTimeout(() => {
      if (canvasRef.current) {
        const canvas = canvasRef.current.getCanvas();
        const context = canvas.getContext('2d');
        const base_image = new Image();
        base_image.crossOrigin = 'Anonymous';
        base_image.src = urlPhoto() + data.firma_agente.photo;
        base_image.onload = () => {
          context.drawImage(base_image, 0, 0);
        };
      }
    }, 1000);
  };

  const onChange = (event) => {
    const { name, checked, type, value } = event.target;
    setState({ ...state, [name]: type === 'checkbox' || type === 'checkbox' ? checked : value });
  };

  const handleGuardarFirma = async (callback = () => {}) => {
    const blob = DataURIToBlob(canvasRef.current.toDataURL('image/png'));
    const form = new FormData();
    form.append('image', blob, 'firma.png');
    await postUploadRequest({ url: `/ordenes-servicios-visitas/firma_agente/${params.visitaId}`, form });
    callback();
  };

  const handleInicio = () => {
    navigate(`/`);
  };

  const handleAdd = async () => {
    const blob = DataURIToBlob(canvasRef.current.toDataURL('image/png'));
    const form = new FormData();
    form.append('image', blob, 'firma.png');
    await postUploadRequest({ url: `/ordenes-servicios-visitas/firma_agente/${params.visitaId}`, form });
    await postRequest({
      url: `ordenes-servicios-visitas/finalizar/${state.ordenServicioId}/${params.visitaId}`,
      params: {
        latitude: geolocation?.latitude,
        longitude: geolocation?.longitude,
      },
    });
    await handleCotizacionAdicionales();
  };

  const handleCotizacionAdicionales = async () => {
    const result = await Swal.fire({
      html: `¿Desea el cliente que se le cotize otra solución que nosotros ofrecemos?`,
      icon: 'primary',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, generar',
      cancelButtonText: 'No',
    });
    if (result.isConfirmed) {
      navigate(`/cotizaciones/nuevo/cliente/${state.clienteId}`);
    } else {
      navigate(`/`);
    }
  };

  const handleLimpiar = () => {
    canvasRef.current.clear();
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const handleAtras = (event) => {
    event.preventDefault();
    navigate(`/ordenes-servicios-visitas/firma-cliente/${params.visitaId}`);
  };

  const renderView = () => {
    const { isLoading, fecha_hora_inicio, numero_orden_servicio, estatus } = state;
    return (
      <>
        <Panel
          type='primary'
          header={
            <div>
              <Icon icon='wrench' /> Firma de agente
            </div>
          }
        >
          <Row>
            <Col sm='6'>
              <FormInput
                title='Numero de orden de servicio'
                placeholder='Numero de orden de servicio'
                disabled
                name='numero_orden_servicio'
                value={numero_orden_servicio}
              />
            </Col>
            <Col sm='6'>
              <DivFormGroup>
                <Label name='fecha_hora_inicio' title='Fecha y hora de inicio' required />
                <Input type='datetime-local' name='fecha_hora_inicio' onChange={onChange} value={fecha_hora_inicio} />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='12' style={{ textAlign: 'center' }}>
              <h3>Firme del agente aquí</h3>
            </Col>
          </Row>
          <Row>
            <Col sm='12' style={{ textAlign: 'center' }}>
              <div style={{ border: '1px solid black', display: 'inline-block', textAlign: 'center' }}>
                <SignatureCanvas
                  ref={canvasRef}
                  penColor='black'
                  canvasProps={{ width: 300, height: 150, className: 'sigCanvas', margin: '0 auto' }}
                />
              </div>
            </Col>
          </Row>
        </Panel>
        <div className='panel panel-default'>
          <div className='panel-footer'>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <EnviarDocumento {...{ params, navigate }} email={state.correo} visitaId={params.visitaId} onGuardarFirma={handleGuardarFirma} />
                <Button onClick={handleAdd} className='btn btn-success pull-right  mr-5' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar y Finalizar
                </Button>
                <Button onClick={handleInicio} className='btn btn-default pull-right  mr-5' disabled={isLoading}>
                  Ir a inicio
                </Button>
                {estatus === 'FINALIZADO' && (
                  <>
                    <Button onClick={handleAtras} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                      <Icon icon='arrow-left' /> Atrás (sin guardar)
                    </Button>
                  </>
                )}
                <Button className='btn btn-warning  pull-right mr-5' onClick={handleLimpiar}>
                  Limpiar
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  return (
    <Module onClickBack={window.history.back} parent='Ordenes de servicios' title='Detalles del trabajo'>
      {!state.isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

FirmaAgente.propTypes = {};

export default FirmaAgente;
