import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ empleadoId, numeroEmpleado, nombre_completo, load }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`empleados/${empleadoId}`}
      description={`¿Confirma que desea eliminar el empleado <strong><u> ${numeroEmpleado} - ${nombre_completo}</u> </strong>?`}
      loadData={load}
    />
  );
};

Delete.propTypes = {
  empleadoId: PropTypes.string.isRequired,
  numeroEmpleado: PropTypes.string.isRequired,
  nombre_completo: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Delete;
