import PropTypes from 'prop-types';
import React from 'react';
import { getDescuento, getImporte, getIVA, getSubtotal, getTotal } from '@utils/cotizaciones';
import CbTableTotales from './CbTableTotales';

const CbTableTodosTotales = ({ detalles, colSpan }) => {
  return (
    <>
      <CbTableTotales colSpan={colSpan} title='Importe' value={getImporte(detalles)} />
      <CbTableTotales colSpan={colSpan} title='Descuento' value={getDescuento(detalles)} />
      <CbTableTotales colSpan={colSpan} title='Subtotal' value={getSubtotal(detalles)} />
      <CbTableTotales colSpan={colSpan} title='IVA' value={getIVA(detalles)} />
      <CbTableTotales colSpan={colSpan} title='Total' value={getTotal(detalles)} />
    </>
  );
};

CbTableTodosTotales.propTypes = {
  detalles: PropTypes.array.isRequired,
  colSpan: PropTypes.number.isRequired,
};

export default CbTableTodosTotales;
