import React from 'react';
import PropTypes from 'prop-types';

const CbMoneda = ({ children }) => {
  const texto = children === 'DOLAR' ? 'USD' : 'MXN';
  return <span style={{ marginLeft: '4px', fontSize: '14px', color: '#666' }}>{texto}</span>;
};

CbMoneda.propTypes = {
  moneda: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CbMoneda;
