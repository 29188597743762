import PropTypes from 'prop-types';
import React from 'react';
import { Loading } from './Stateless';

const CbTableLoading = ({ colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan} style={{ padding: '2px 8px', verticalAlign: 'middle', textAlign: 'center' }}>
        <Loading />
      </td>
    </tr>
  );
};

CbTableLoading.propTypes = {
  colSpan: PropTypes.number.isRequired,
};

export default CbTableLoading;
