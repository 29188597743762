import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const Input = ({
  name,
  className = 'form-control',
  style,
  maxLength,
  placeholder,
  disabled,
  readOnly,
  value,
  defaultValue,
  onChange,
  onBlur,
  autocomplete = 'off',
  id,
  type,
  onFocus,
}) => {
  const inputRef = useRef(null);
  const nameClass = className.match('form-control') ? className : `form-control ${className}`;

  return (
    <input
      type={type || 'text'}
      id={id}
      name={name}
      ref={inputRef}
      className={nameClass}
      style={style}
      maxLength={maxLength}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      readOnly={readOnly}
      autoComplete={autocomplete}
      onFocus={(event) => {
        event.target.select();
        onFocus && onFocus(event);
      }}
    />
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autocomplete: PropTypes.string,
};


export default Input;
