import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { DropdownButton, DropdownItem } from 'react-bootstrap';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';

const ListCustomer = () => {
  const [search, setSearch] = useState('');
  const [list, setList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState(30);
  const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
    };
    const { data, count } = await postRequest({ url: `facturas-faltantes/search`, params: { page, limit: pagination }, body });
    setList(data.map((x) => ({ ...x, checked: false })));
    setCount(count);
    setLoading(false);
  }, [search, fechaFin, fechaInicio, activePage, pagination]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const onChange = () => {};

  const renderRows = () => {
    return list.map((row, i) => {
      const { _id, uuid, created } = row;
      return (
        <Fragment key={created}>
          <tr key={i}>
            <td>
              <input type='checkbox' name='xmls[]' onChange={onChange} value={_id} />
            </td>
            <td>{uuid}</td>
            <td>{moment(created).local().format('YYYY/MM/DD HH:mm')}</td>
          </tr>
        </Fragment>
      );
    });
  };

  const renderList = () => {
    return (
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th width='5%'>&nbsp;</th>
            <th width='20%'>UUID</th>
            <th width='20%'>Fecha obtención</th>
          </tr>
        </thead>
        <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
      </CbPanelTableResponsive>
    );
  };

  const handleImportar = async (e) => {
    e.preventDefault();
    setLoading(true);
    await postRequest({ url: `migrar/facturasFaltantes` });
    handleSearch();
  };

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
    setFechaInicio(params.fechaInicio);
    setFechaFin(params.fechaFin);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  return (
    <Module title='Comprobantes sin importar'>
      <Search onSearch={onSearch} withDatetime>
        <button onClick={handleImportar} className='btn btn-primary pull-right' type='button' disabled={loading}>
          Importar seleccionados
        </button>
      </Search>
      <Loading loading={loading}>
        {renderList()}
        <div className='panel panel-default'>
          <div className='panel-body'>
            <div className='text-right'>
              <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
              <DropdownButton dropup pullRight bsStyle='default top' title={pagination} style={{ margin: '0px' }}>
                {[5, 10, 20, 50, 100, 200, 500, 1000].map((items, i) => (
                  <DropdownItem key={i} onClick={() => setPagination(items)}>
                    {items}
                  </DropdownItem>
                ))}
              </DropdownButton>
            </div>
          </div>
        </div>
      </Loading>
    </Module>
  );
};

ListCustomer.propTypes = {};

export default ListCustomer;
