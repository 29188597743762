import { TIPO_SERVICIOS } from '@config/constants';

export const esMonitoreo = (s) => s.tipo === TIPO_SERVICIOS.MONITOREO && !s.adicional;
export const esMonitoreoAdicional = (s) => s.tipo === TIPO_SERVICIOS.MONITOREO && s.adicional;
export const esInteractivo = (s) => s.tipo === TIPO_SERVICIOS.INTERACTIVO && !s.adicional;
export const esInteractivoAdicional = (s) => s.tipo === TIPO_SERVICIOS.INTERACTIVO && s.adicional;

export const mapDomicilioFront = (domicilio) => ({
  ...domicilio,
  calle1: domicilio?.entre_calles?.calle1,
  calle2: domicilio?.entre_calles?.calle2,
  coloniaObject: domicilio?.coloniaId,
  municipioObject: domicilio?.coloniaId?.municipio_id,
  estadoObject: domicilio?.coloniaId?.municipio_id?.estadoId,
  paisObject: domicilio?.coloniaId?.municipio_id?.estadoId?.paisId,
});

export const mapContactoBack = (contacto) => ({
  _id: contacto?._id,
  nombre: contacto?.name,
  paterno: contacto?.paterno,
  materno: contacto?.materno,
  celular: contacto?.mobile,
  fijo: contacto?.phone,
  correo: contacto?.email,
});

export const mapContactoFront = (contacto) => ({
  _id: contacto?._id,
  name: contacto?.name,
  paterno: contacto?.paterno,
  materno: contacto?.materno,
  mobile: contacto?.mobile,
  phone: contacto?.phone,
  email: contacto?.email,
  tipos: contacto?.tipos.map((x) => x.tipoContactoId),
});

export const mapDomicilioBack = (domicilio) => ({
  ...domicilio,
  entre_calles: {
    calle1: domicilio?.calle1,
    calle2: domicilio?.calle2,
  },
});

export const mapCheck = (servicio) => ({
  ...servicio,
  check: false,
});

export const sumarImportes = (servicios) =>
  servicios
    .filter((x) => x.check)
    .map((x) => Number(x.precio))
    .reduce((a, b) => a + b, 0);

export const mapPrecio = (servicio) => ({
  ...servicio,
  precio: servicio.precio.toString(),
});
