import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ itemId, loadData, nombre: initialNombre, clave: initialClave, multiples: initialMultiples }) => {
  const { addNotification: notification } = useStoreNotification();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clave, setClave] = useState(initialClave);
  const [nombre, setNombre] = useState(initialNombre);
  const [multiples, setMultiples] = useState(initialMultiples);

  const close = () => {
    setShowModal(false);
    setNombre('');
    setClave('');
    setMultiples(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setNombre(initialNombre);
    setClave(initialClave);
    setMultiples(initialMultiples);
  };

  const handleRegisterProduct = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = {
      clave: clave.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      multiples: multiples,
    };
    if (validations(data)) {
      await putRequest({ url: `documentos-empleados/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.clave)) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre)) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleRegisterProduct} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <FormInput title='Clave' name='clave' required onChange={(e) => setClave(e.target.value)} value={clave} />
          </Col>
          <Col sm='6'>
            <FormInput title='Nombre' name='nombre' required onChange={(e) => setNombre(e.target.value)} value={nombre} />
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <DivFormGroup>
              <div className='checkbox'>
                <label className='control-label'>
                  <input name='multiples' type='checkbox' checked={multiples} onChange={(e) => setMultiples(e.target.checked)} />{' '}
                  <strong>Múltiples archivos</strong>
                </label>
              </div>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  unidades: PropTypes.array.isRequired,
  nombre: PropTypes.string.isRequired,
  multiples: PropTypes.bool.isRequired,
  clave: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,

  serviciosList: PropTypes.array,
};

export default Edit;
