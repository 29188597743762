import { Confirmation, RedirectTooltip } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import EditModuleOfSubModule from './EditModuleOfSubModule';
import EditSubModule from './EditSubModule';
import NewSubModule from './NewSubModule';
import { Button, ButtonGroup } from 'react-bootstrap';

const SubModules = (props) => {
  const [mostrar, setMostrar] = useState(false);

  const renderEmpty = () => (
    <tr>
      <td colSpan='3' className='text-center'>
        No hay sub módulos.
      </td>
    </tr>
  );

  const renderSubModules = () => {
    return props.module.submodules.map((subModule, itemSubModule) => (
      <Draggable key={subModule._id} draggableId={subModule._id} index={itemSubModule}>
        {(provided) => (
          <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={itemSubModule}>
            <td>{subModule.name}</td>
            <td>
              <span style={{ fontWeight: 'bold' }}>{subModule.path}</span>
            </td>
            <td>
              <ButtonGroup>
                <RedirectTooltip id={1} labelTooltip='Ir' url={`/${subModule.path}`} icon='link' className='btn btn-outline-warning btn-sm' />
                <EditModuleOfSubModule
                  id={itemSubModule}
                  idCatSubModule={subModule._id}
                  modules={props.modules}
                  module={props.module}
                  subModule={subModule}
                  loadCatalog={props.loadCatalog}
                />
                <EditSubModule id={itemSubModule} idCatSubModule={subModule._id} subModule={subModule} loadCatalog={props.loadCatalog} />
                <Confirmation
                  id={itemSubModule}
                  btnIcon='remove'
                  typeConfirm='danger'
                  btnType='outline-danger btn-sm'
                  action='Eliminar Sub Módulo'
                  typeRequest='deleteRequest'
                  url={`submodule/${subModule._id}`}
                  description={`¿Confirma que desea eliminar el <strong>sub módulo: <u>${subModule.name}</u></strong>?`}
                  loadData={props.loadCatalog}
                />
              </ButtonGroup>
            </td>
          </tr>
        )}
      </Draggable>
    ));
  };

  return (
    <>
      <div className='panel-body' style={{ padding: '10px' }}>
        <ButtonGroup className='pull-right'>
          <Button onClick={() => setMostrar(!mostrar)} size='sm'>
            {mostrar ? 'Ocultar sub módulos...' : 'Mostrar sub módulos...'}
          </Button>
          <NewSubModule {...props} />
        </ButtonGroup>
      </div>
      {mostrar && (
        <table className='table table-hover'>
          <thead>
            <tr>
              <th>Sub módulo</th>
              <th>Ruta</th>
              <th width='20%'>Acciones</th>
            </tr>
          </thead>
          <Droppable droppableId={props.module._id}>
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {props.module.submodules.length > 0 ? renderSubModules() : renderEmpty()}
              </tbody>
            )}
          </Droppable>
        </table>
      )}
    </>
  );
};

SubModules.propTypes = {
  modules: PropTypes.object.isRequired,
  module: PropTypes.object.isRequired,

  loadCatalog: PropTypes.func.isRequired,
};

export default SubModules;
