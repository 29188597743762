import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import List from './List';

const PlanesMantenimientos = () => {
  const [state, setState] = useState({
    catalogCanastas: [],
    loading: true,
    count: 0,
    search: '',
    date: '',
    date2: '',
    piezas: [],
    controles: [],
    fabricantes: [],
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (params = {}) => {
    const body = {
      search: params.search,
    };
    const { data, count } = await postRequest({ url: `planes/search`, body });
    setState((prevState) => ({
      ...prevState,
      catalogCanastas: data,
      count,
      loading: false,
      search: params.search,
    }));
  };

  const renderList = () => {
    return <List {...state} loadData={loadData} />;
  };

  const { loading } = state;

  const moduleList = !loading ? renderList() : <Loading />;

  return (
    <Module title='Planes Mantenimientos'>
      <Search onSearch={loadData} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <Link to='/planes-mantenimientos/captura' className='btn btn-success pull-right'>
          <Icon icon='plus' /> Plan
        </Link>
      </Search>

      <div className='row'>
        <div className='col-sm-12'>{moduleList}</div>
      </div>
    </Module>
  );
};

PlanesMantenimientos.propTypes = {};

export default PlanesMantenimientos;
