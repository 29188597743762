import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import FormAgente from './FormAgente';
import { useStoreNotification } from '@stores/catalogs.store.js';

const EditAgentes = (props) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    maximo_descuento: 0,
    showModal: false,
    nombre: '',
    codigo: '',
    sucursalId: null,
    almacenId: null,
    recibe_pedidos: false,
    recibe_servicios: false,
    es_tecnico: false,
    ocupa_autorizacion: false,
    isLoading: false,
  });

  const close = () => {
    setState({
      ...state,
      showModal: false,
      condicion: '',
      nombre: '',
      codigo: '',
      recibe_pedidos: false,
      es_tecnico: false,
      recibe_servicios: false,
    });
  };

  const open = () => {
    const { nombre, codigo, almacenId, sucursalId, maximo_descuento, recibe_pedidos, recibe_servicios, es_tecnico, ocupa_autorizacion } = props;
    setState({
      ...state,
      showModal: true,
      codigo,
      nombre,
      almacenId,
      sucursalId,
      maximo_descuento,
      recibe_pedidos,
      recibe_servicios,
      es_tecnico,
      ocupa_autorizacion,
    });
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const { agenteId, loadAgentes } = props;
    const { codigo, nombre, almacenId, sucursalId, maximo_descuento, recibe_pedidos, recibe_servicios, es_tecnico, ocupa_autorizacion } = state;

    const data = {
      codigo: codigo.trim(),
      nombre: nombre.trim().toUpperCase(),
      sucursalId: sucursalId?._id,
      almacenId: almacenId?._id,
      maximo_descuento,
      recibe_pedidos,
      recibe_servicios,
      es_tecnico,
      ocupa_autorizacion,
    };

    if (validations(data)) {
      const { error } = await putRequest({ url: `agentes/${agenteId}`, body: data });
      if (error) return;
      loadAgentes();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const { showModal, isLoading } = state;
  const { sucursales, almacenes } = props;

  return (
    <>
      <CbButtonOver title='Editar' onClick={open} icon='pencil' />
      <CbModal show={showModal} title='Editar agente' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <FormAgente {...state} almacenes={almacenes} sucursales={sucursales} setState={(newState) => setState({ ...state, ...newState })} />
      </CbModal>
    </>
  );
};

EditAgentes.propTypes = {
  id: PropTypes.number.isRequired,
  agenteId: PropTypes.string.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  almacenId: PropTypes.string.isRequired,
  sucursalId: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  maximo_descuento: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  loadAgentes: PropTypes.func.isRequired,
  sucursales: PropTypes.array.isRequired,
  almacenes: PropTypes.array.isRequired,
  recibe_pedidos: PropTypes.bool.isRequired,
  recibe_servicios: PropTypes.bool.isRequired,
  es_tecnico: PropTypes.bool.isRequired,
  ocupa_autorizacion: PropTypes.bool.isRequired,
};

export default EditAgentes;
