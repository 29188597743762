import { KITS } from '@config/constants';
import { Confirmation, IfPermission, RedirectTooltip } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Detail from './Detail';
import DetailComplementarios from './DetailComplementarios';
import DetailServicios from './DetailServicios';
import { ButtonGroup } from 'react-bootstrap';

const TableKits = ({ list, loadData }) => {
  const renderRows = () => {
    return list.map((k, i) => {
      const { _id, codigo, nombre, tipoCotizacionId, tipo, clase, listaServicios, listaComplementarios, lista } = k;

      let labelTipo = '';
      if (tipo === KITS.HIBRIDO) {
        labelTipo = 'Hibrido';
      } else if (tipo === KITS.INALAMBRICO) {
        labelTipo = 'Inalámbrico';
      }

      let labelClase = '';
      if (clase === KITS.INTERACTIVO) {
        labelClase = 'Interactivo';
      } else if (clase === KITS.NO_INTERACTIVO) {
        labelClase = 'No interactivo';
      }

      return (
        <tr key={i}>
          <td>{codigo}</td>
          <td>{nombre}</td>
          <td>{tipoCotizacionId.nombre}</td>
          <td>
            <strong>{labelTipo}</strong>
            <br />
            {labelClase}
          </td>
          <td>
            <ButtonGroup className='pull-right'>
              {lista && lista.length > 0 && <Detail id={i} {...k} loadData={loadData} />}
              {listaComplementarios && listaComplementarios.length > 0 && <DetailComplementarios id={i} {...k} loadData={loadData} />}
              {listaServicios && listaServicios.length > 0 && <DetailServicios id={i} {...k} loadData={loadData} />}
              <IfPermission action='editar'>
                <RedirectTooltip
                  id={1}
                  icon='pencil'
                  url={`/kits-comerciales/edicion/${_id}`}
                  labelTooltip='Editar'
                  className='btn btn-sm btn-outline-success'
                />
              </IfPermission>
              <IfPermission action='eliminar'>
                <Confirmation
                  btnIcon='trash'
                  typeRequest='deleteRequest'
                  btnType='outline-danger btn-sm'
                  action='Eliminar'
                  url={`kits-comerciales/${_id}`}
                  description={`¿Confirma que desea eliminar el kit <strong><u> ${codigo} - ${nombre}</u> </strong>?`}
                  loadData={loadData}
                />
              </IfPermission>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='10%'>Código</th>
          <th>Descripción</th>
          <th width='15%'>Cotización</th>
          <th width='10%'>Tipo</th>
        </tr>
      </thead>
      <CbTableBody colSpan={7}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

TableKits.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default TableKits;
