import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';

const ItemField = ({ value, stepId: { step, note, capturable } }) => {
  const renderNormal = () => (
    <Col>
      <h4>{value !== null && value !== '' ? value : '---'}</h4>
      <h5>
        <em>
          {step} <small>{note}</small>
        </em>
      </h5>
    </Col>
  );

  const renderCapturable = () => (
    <Col>
      <h4>
        {step} <small>{note}</small> <em>*capturable en carro de juan</em>
      </h4>
    </Col>
  );

  return !capturable ? renderNormal() : renderCapturable();
};

ItemField.propTypes = {
  value: PropTypes.string,
  stepId: PropTypes.shape({
    step: PropTypes.string,
    note: PropTypes.string,
    capturable: PropTypes.bool,
  }).isRequired,
};

export default ItemField;
