import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { CULIACAN } from '@config/constants';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import Geocode from 'react-geocode';
import { Icon } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import RowDomicilio from '../components/RowDomicilio';
import CbTableResponsive from '@cbcomponents/CbTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import useAuth from '@hooks/useAuth';

const containerStyle = {
  width: '100%',
  height: '300px',
};

Geocode.setApiKey('AIzaSyD_Eg_re4nHLgp05E8bFCFnrDvI6EdteF0');
Geocode.setLanguage('es');
Geocode.setRegion('es');
Geocode.setLocationType('ROOFTOP');
Geocode.enableDebug();

const searchPosition = async (address) => {
  const response = await Geocode.fromAddress(address.trim());
  const { geometry, formatted_address } = response.results[0];
  return { data: geometry, ubicacion: formatted_address };
};

const Domicilios = (props) => {
  const { geolocation } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [listDomicilios, setListDomicilios] = useState([]);
  const [domicilios, setDomicilios] = useState([]);

  const close = () => {
    setShowModal(false);
  };

  const onLoad = async () => {
    // Add your onLoad logic here
  };

  const open = async () => {
    const { id } = props;
    const { data } = await getRequest({ url: `customers/domicilios/${id}` });
    const listDomicilios = data;
    let listaDomicilios = listDomicilios.map((x) => {
      const colonia = x.coloniaId;
      const municipio = colonia && colonia.municipio_id;
      const estado = municipio && municipio.estadoId;
      const pais = estado && estado.paisId;
      return {
        ...x,
        calle1: x.entre_calles.calle1,
        calle2: x.entre_calles.calle2,
        editando: false,
        estadoObject: estado,
        municipioObject: municipio,
        coloniaObject: colonia,
        paisObject: pais,
        estadoId: x.estadoId ? x.estadoId.toString() : null,
      };
    });
    const domicilios = [];
    for (let i = 0; i < listaDomicilios.length; i++) {
      const x = listaDomicilios[i];
      let ubicacionActual = x.tiene_posicion ? x.ubicacion : '';
      let posicionActual = x.tiene_posicion ? { lat: x.latitud, lng: x.longitud } : '';
      if (!x.tiene_posicion) {
        const address = `${x.calle} ${x.exterior} ${x.interior} ${x.colonia} ${x.municipio} ${x.estado}`;
        const { data, ubicacion } = await searchPosition(address);
        ubicacionActual = ubicacion;
        posicionActual = { lat: data.location.lat, lng: data.location.lng };
        await postRequest({ url: `customers/${id}/domicilios/posicion/${x._id}`, body: { location: data.location, ubicacion } });
      }
      domicilios.push({
        position: posicionActual,
        address: (
          <div>
            {x.calle} {x.exterior} {x.interior},
            <br />
            Colonia {x.colonia} C.P. {x.codigo_postal}
            <br />
            {x.municipio} {x.estado}, {x.pais}
          </div>
        ),
        name: x.nombre,
        ubicacion: ubicacionActual,
      });
    }
    setListDomicilios(listDomicilios);
    setShowModal(true);
    setDomicilios(domicilios);
  };

  const addDomicilio = () => {
    setListDomicilios([
      ...listDomicilios,
      {
        editando: true,
        estado: '',
        municipio: '',
        colonia: '',
        localidad: '',
        paisObject: null,
        estadoObject: null,
        municipioObject: null,
        coloniaObject: null,
        codigo_postal: '',
      },
    ]);
  };

  const editDomicilio = (index) => {
    setListDomicilios([
      ...listDomicilios.map((dom, i) =>
        i === index
          ? {
              ...dom,
              calle1: dom.entre_calles?.calle1,
              calle2: dom.entre_calles?.calle2,
              coloniaObject: dom.coloniaId,
              municipioObject: dom.coloniaId?.municipio_id,
              estadoObject: dom.coloniaId?.municipio_id?.estadoId,
              paisObject: dom.coloniaId?.municipio_id?.estadoId?.paisId,
              editando: !dom.editando,
            }
          : {
              ...dom,
              editando: false,
            }
      ),
    ]);
  };

  const onChangeDomicilio = (index, domicilio) => {
    setListDomicilios(listDomicilios.map((dom, i) => (i === index ? { ...dom, ...domicilio } : { ...dom })));
  };

  const onMarkerClick = () => {};

  const onMarkerClose = () => {};

  return (
    <>
      <Button variant='outline-warning' size='sm' onClick={open}>
        <Icon icon='home' /> {listDomicilios.length > 0 ? listDomicilios.length : props.listDomicilios.length}
      </Button>
      <Modal show={showModal} onHide={close} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Domicilios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Domicilios</Tooltip>}>
            <Button onClick={addDomicilio} className='btn btn-outline-success btn-sm pull-right' type='button'>
              <Icon icon='plus' /> Nuevo
            </Button>
          </OverlayTrigger>
          <br /> 
          <div className='panel'>
            <CbTableResponsive>
              <thead>
                <tr>
                  <th width='15%'>Nombre</th>
                  <th width='5%'>Monitoreo</th>
                  <th>Domicilio</th>
                  <th width='10%'></th>
                </tr>
              </thead>
              <CbTableBody colSpan={5}>
                {listDomicilios.map((domicilio, i) => (
                  <RowDomicilio
                    key={i}
                    customerId={props.id}
                    domicilio={domicilio}
                    editDomicilio={() => editDomicilio(i)}
                    onChangeDomicilio={(domicilio) => onChangeDomicilio(i, domicilio)}
                    loadData={open}
                  />
                ))}
              </CbTableBody>
            </CbTableResponsive>
          </div>

          <Card>

            <LoadScript googleMapsApiKey='AIzaSyD_Eg_re4nHLgp05E8bFCFnrDvI6EdteF0' onLoad={onLoad} libraries={['places']}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                  lat: geolocation?.latitude || CULIACAN.LATITUDE,
                  lng: geolocation?.longitude || CULIACAN.LONGITUDE,
                }}
                zoom={10}
              >
                {domicilios.map((x, i) => (
                  <Marker key={i} title={x.title} position={x.position} draggable={false} onClick={() => onMarkerClick(x)}>
                    {x.showInfo && (
                      <InfoWindow onCloseClick={() => onMarkerClose(x)}>
                        <div>
                          <strong>{x.name}</strong>
                          <div>{x.address}</div>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
              </GoogleMap>
            </LoadScript>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close} className='btn btn-default pull-right' type='button'>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Domicilios.propTypes = {
  id: PropTypes.number.isRequired,
  listDomicilios: PropTypes.array,
  loadData: PropTypes.func,
  estados: PropTypes.array,
  position: PropTypes.object,

  paises: PropTypes.array,
};

export default Domicilios;
