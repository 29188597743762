import { getRequest } from '@utils/api';
import { create } from 'zustand';
import moment from 'moment';
import { socketManager } from '../../socketManager.js';

const initState = {
  loading: false,
  initialized: false,
  error: false,
  data: [],
  errorData: null,
};

export const useStoreSocket = create((set) => ({
  notifications: [],
  addNotification: (notification) => set((state) => ({ notifications: [...state.notifications, notification] })),
}));

export const initializeNotifications = () => {
  const addNotification = useStoreSocket.getState().addNotification;
  socketManager.subscribe('notification', (message) => {
    addNotification(message);
  });
};

export const useStoreTipoCambio = create((set, get) => ({
  tipoCambio: 0,
  loading: false,
  setTipoCambio: (tipoCambio) => set(() => ({ tipoCambio: tipoCambio })),
  execute: async () => {
    if (get().loading || get().tipoCambio > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: `tipo-cambio/fecha?fecha=${moment().local().format('YYYY-MM-DD')}` });
      set({ loading: false, initialized: true, tipoCambio: data?.valor || 1 });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreSidebar = create((set) => ({
  sidebar: false,
  collapsed: false,
  toggleSidebar: () => set((state) => ({ sidebar: !state.sidebar })),
  toggleCollapsed: () => set((state) => ({ collapsed: !state.collapsed })),
}));

export const useStoreNotification = create((set, get) => ({
  notificaciones: [],
  alertas: [],
  addAlert: (alert) => set((state) => ({ alertas: [...state.alertas, alert] })),
  addNotification: (notification) => set((state) => ({ notificaciones: [...state.notificaciones, notification] })),
  pullNotification: () => {
    let notificaciones = get().notificaciones;
    const first = notificaciones.shift();
    set(() => ({ notificaciones: [...notificaciones] }));
    return first;
  },
  pullAlert: () =>  {
    let alertas = get().alertas;
    const first = alertas.shift();
    set(() => ({ alertas: [...alertas] }));
    return first;
  },
}));

export const useStoreSavingLoading = create((set) => ({
  saving: false,
  loading: (value) => set({ saving: value }),
}));

export const useStoreSucursales = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/sucursales' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreAlmacenes = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/almacenes' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreAgentes = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/agentes' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreUsosCfdis = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/usos-cfdis' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreFormasPagos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/formas-pagos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreMetodosPagos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/metodos-pagos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreClasificacionesCotizaciones = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/clasificaciones-cotizaciones' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreClasificacionesLevantamientos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/clasificaciones-levantamientos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreMedidas = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/medidas' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreMarcas = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/marcas' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposRelaciones = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-relaciones' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreGrupos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/grupos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreFichasTecnicas = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/fichas-tecnicas' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposDocumentos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-documentos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposLevantamientos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-levantamientos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreClasificacionesProveedores = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/clasificaciones-proveedores' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreListasPrecios = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/listas-precios' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreMonedas = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/monedas' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreUsuarios = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/usuarios' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreUbicaciones = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/ubicaciones' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposCompras = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-compras' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposPartidas = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-partidad' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposContactos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-contactos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTitulos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/titulos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreEstados = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/estados' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposEstablecimientos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-establecimients' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreBancos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/bancos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreDocumentos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/documentos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreClasificacionesClientes = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/clasificaciones-cliente' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStorePaises = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/paises' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposPedidos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-pedidos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposOrdenesServicios = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-ordenes-servicios' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposServiciosSolicitados = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-servicios-solicitados' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposManosObras = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-manos-obras' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposCobrosEquipos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-cobros-equipos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreSolucionesSolicitadas = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/soluciones-solicitadas' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposRecibos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-recibos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreRubros = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/rubros' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreMotivos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/motivos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreTiposMonitoreos = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/tipos-monitoreos' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreDealers = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/dealers' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreMediosComunicacion = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/medios-comunicaciones' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));

export const useStoreCuentasBancarias = create((set, get) => ({
  ...initState,
  execute: async () => {
    if (get().loading || get().data.length > 0) return;
    set({ loading: true, initialized: false, error: false, errorData: null });
    try {
      const { data } = await getRequest({ url: '/catalogos/cuentas-bancarias' });
      set({ loading: false, initialized: true, data });
    } catch (error) {
      set({ loading: false, error: true, errorData: error });
    }
  },
}));
