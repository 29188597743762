import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from '@utils/api';
import PropTypes from 'prop-types';

function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

const SelectCuentaMonitoreo = ({ onChange, name, value }) => {
  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];
    const { data: clientes } = await getRequest({ url: `cuentas-monitoreos/autocomplete`, params: { query: inputValue } });
    return clientes;
  };

  const onSelect = (selected) => {
    onChange(selected);
  };

  const debouncedLoadOptions = useCallback(
    debounce((inputValue, callback) => {
      loadOptions(inputValue).then(callback);
    }, 500),
    []
  );

  return (
    <AsyncSelect
      inputId={name}
      styles={{ control: (base) => ({ ...base, height: '38px', width: '100%' }) }}
      value={value}
      loadOptions={debouncedLoadOptions}
      onChange={onSelect}
      getOptionLabel={(x) => `${x.numero} ${x.nombre}`}
      getOptionValue={(x) => x._id}
      isClearable
      placeholder={'Buscar por numero o nombre'}
    />
  );
};


SelectCuentaMonitoreo.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
};

export default SelectCuentaMonitoreo;
