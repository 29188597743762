import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ _id, name, refresh }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger btn-sm'
      action='Eliminar'
      url={`customer/${_id}`}
      description={`¿Confirma que desea eliminar el cliente <strong><u> ${name}</u> </strong>?`}
      loadData={refresh}
    />
  );
};

Delete.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default Delete;
