import { RedirectTooltip } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { RowsNotFound } from '@stateless';

const Table = ({ list }) => {
  const renderRows = () => {
    return list.map((r, i) => (
      <tr key={i}>
        <td>
          <Link to={`/equipos/detalle/${r._id._id}`} rel='noreferrer' target='_blank'>
            {r._id.code}
          </Link>
        </td>
        <td>{r._id.equipmentId.name.toUpperCase()}</td>
        <td>{r._id.equipmentId.equipmentsMeasureId.measure}</td>
        <td>{r._id.storehouseId.name}</td>
        <td>{Number(r.stock)}</td>
        <td className='with-actions'>
          <span className='actions'>
            <RedirectTooltip id={i} icon='search' url={`pedidos/detalle/${r._id}`} labelTooltip='Detalles' className='btn btn-sm btn-default' />
          </span>
        </td>
      </tr>
    ));
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={8} />;

  return (
    <div className='panel panel-default panel-table'>
      <div className='table-responsive'>
        <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
          <thead>
            <tr>
              <th width='5%'>Codigo</th>
              <th width='45%'>Descripción</th>
              <th width='5%'>Unidad</th>
              <th width='5%'>Almacen</th>
              <th width='5%'>Existencia</th>
              <th width='5%'>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Table;
