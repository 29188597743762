import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { FormInput } from '@controls';
import Select from 'react-select';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [forma, setForma] = useState('');
  const [card, setCard] = useState(false);
  const [tipo, setTipo] = useState('');
  const [tiposFormasPago] = useState([
    { value: 'EQUIPO', label: 'EQUIPO' },
    { value: 'SERVICIO', label: 'SERVICIO' },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setForma('');
    setCard(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const data = {
      forma: forma.trim(),
      card: card === 'true' || card === true ? true : false,
      tipo: tipo.value,
    };

    if (validations(data)) {
      await postRequest({ url: `formapago`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.forma + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.tipo + '')) {
      validate.success = false;
      validate.message = 'Tipo es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' size='lg' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col xs={8}>
            <FormInput title='Nombre' required name='forma' onChange={(e) => setForma(e.target.value)} value={forma} />
          </Col>
          <Col xs={4}>
            <DivFormGroup required title='Cargo a tarjeta'>
              <div>
                <label className='radio-inline'>
                  <input
                    type='radio'
                    name='card'
                    onChange={() => setCard(true)}
                    value={true}
                    defaultChecked={card === 'true' || card === true ? true : false}
                  />{' '}
                  Si
                </label>
                <label className='radio-inline'>
                  <input
                    type='radio'
                    name='card'
                    onChange={() => setCard(false)}
                    value={false}
                    defaultChecked={card === 'false' || card === false ? true : false}
                  />{' '}
                  No
                </label>
              </div>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='tipo' required title='Tipo'>
              <Select name='tipo' value={tipo} options={tiposFormasPago} onChange={(value) => setTipo(value)} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  load: PropTypes.func.isRequired,

  tipos_formas_pago: PropTypes.array,
  tipo: PropTypes.string,
};

export default New;
