import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';
import CbTableLoading from './CbTableLoading';

const CbTableBody = ({ children, colSpan, loading }) => {
  if (loading) {
    return (
      <tbody>
        <CbTableLoading colSpan={colSpan} />
      </tbody>
    );
  }
  return (
    <tbody>
      <RowsNotFound colSpan={colSpan}>{children}</RowsNotFound>
    </tbody>
  );
};

CbTableBody.propTypes = {
  children: PropTypes.node.isRequired,
  colSpan: PropTypes.number,
  loading: PropTypes.bool,
};

export default CbTableBody;
