import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import TableSurtidoCarros from './TableSurtidoCarros';

const ListSurtidoCarros = () => {
  const [surtidocarros, setSurtidocarros] = useState([]);
  const [loading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  useEffect(() => {
    handlePagination(1);
  }, []);

  const handleSearch = async (params = {}) => {
    const page = activePage * pagination - pagination;
    const body = params;
    const { data, count } = await postRequest({ url: `surtidocarros/search`, params: { page, limit: pagination }, body });
    setSurtidocarros(data);
    setCount(count);
    setSearch(params.search);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    const page = eventKey * pagination - pagination;
    handleSearch({ page, pagination, search });
  };

  return (
    <Module title='Surtido de Carros'>
      <Search onSearch={handleSearch} blockOne='col-sm-6' blockSecond='col-sm-6'>
        <Link to='/surtido-carros/surtir' className='btn btn-success pull-right'>
          <Icon icon='plus' /> Surtir
        </Link>
      </Search>
      <Loading loading={loading}>
        <TableSurtidoCarros
          surtidocarros={surtidocarros}
          loading={loading}
          activePage={activePage}
          count={count}
          pagination={pagination}
          search={search}
          loadRecepcionCompras={handleSearch}
        />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

ListSurtidoCarros.propTypes = {};

export default ListSurtidoCarros;
