import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { CustomerOption } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { textContacto, textDomicilio } from '@utils/cotizaciones';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ refresh, id }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    domicilios: [],
    contactos: [],
    cliente: null,
    folio: '',
    descripcion: '',
    showModal: false,
    isLoading: false,
    domicilio: null,
    contacto: null,
  });

  const close = () => {
    setState({
      ...state,
      showModal: false,
      cliente: null,
      folio: '',
      descripcion: '',
      domicilio: null,
      contacto: null,
    });
  };

  const open = async () => {
    const { data } = await getRequest({ url: `customers-registros/${id}` });
    setState({
      ...state,
      folio: data.folio,
      descripcion: data.descripcion,
      cliente: data.customerId,
      domicilio: data.domicilioId,
      contacto: data.contactoId,
      domicilios: (data.customerId && data.customerId.listDomicilios) || [],
      contactos: (data.customerId && data.customerId.listContactos) || [],
      showModal: true,
      isLoading: false,
    });
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { folio, descripcion, cliente, domicilio, contacto } = state;
    const data = {
      folio,
      descripcion,
      customerId: cliente?._id,
      domicilioId: domicilio?._id,
      contactoId: contacto?._id,
    };

    if (validations(data)) {
      const { data: registro } = await putRequest({ url: `customers-registros/${id}`, body: data });
      if (registro) {
        refresh(registro);
        close();
      }
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.folio + '')) {
      validate.success = false;
      validate.message = 'Folio es requerido.';
    } else if (isEmpty(params.descripcion + '')) {
      validate.success = false;
      validate.message = 'Descripción es requerido.';
    } else if (!isMongoId(params.customerId + '')) {
      validate.success = false;
      validate.message = 'Cliente es requerido.';
    } else if (!isMongoId(params.domicilioId + '')) {
      validate.success = false;
      validate.message = 'Domicilio es requerido.';
    } else if (!isMongoId(params.contactoId + '')) {
      validate.success = false;
      validate.message = 'Contacto es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const loadOptions = async (inputValue, callback) => {
    const { data } = await getRequest({ url: `customers-autocomplete`, params: { query: inputValue } });
    callback(data);
  };

  const onChangeClienteInput = (value) => {
    setState({ ...state, clienteName: value });
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      const domicilios = itemSelect.listDomicilios;
      const domicilio = domicilios.length === 1 ? domicilios[0] : null;
      const contactos = itemSelect.listContactos;
      const contacto = contactos.length === 1 ? contactos[0] : null;
      setState({
        ...state,
        clienteName: itemSelect.razon_social,
        cliente: itemSelect,
        domicilio,
        contacto,
        domicilios,
        contactos,
      });
    } else {
      setState({
        ...state,
        clienteName: '',
        cliente: null,
        domicilio: null,
        domicilios: [],
      });
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const onSelect = (campo, value) => {
    setState({ ...state, [campo]: value });
  };

  const { showModal, isLoading, cliente, domicilio, domicilios, folio, contacto, contactos, descripcion } = state;

  return (
    <>
      <CbButtonOver icon='edit' title='Editar' onClick={open} type='success' />
      <CbModal title='Editar CRM' show={showModal} onClose={close} onSave={handleRegister} isLoading={isLoading} size='md'>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='folio' title='Folio'>
              <Input name='folio' onChange={onChange} value={folio} placeholder='Folio' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='descripcion' title='Descripción'>
              <Input name='descripcion' onChange={onChange} value={descripcion} placeholder='Descripción' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='clienteName' required title='Cliente'>
              <AsyncSelect
                value={cliente}
                loadOptions={loadOptions}
                onChange={onChangeCliente}
                onInputChange={onChangeClienteInput}
                getOptionLabel={(x) => `${x.razon_social}`}
                getOptionValue={(x) => x._id}
                isClearable
                placeholder={'Buscar por rázon social, nombre comercial, RFC '}
                components={{ Option: CustomerOption }}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='domicilio' required title='Domicilio'>
              <Select
                value={domicilio}
                name='domicilio'
                placeholder='Selecciona opciones'
                options={domicilios}
                getOptionLabel={textDomicilio}
                getOptionValue={(x) => x._id}
                onChange={(value) => onSelect('domicilio', value)}
                isDisabled={!cliente || domicilios.length === 1}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='contacto' required title='Contacto'>
              <Select
                value={contacto}
                name='contacto'
                placeholder='Selecciona opciones'
                options={contactos}
                getOptionLabel={textContacto}
                getOptionValue={(x) => x._id}
                onChange={(value) => onSelect('contacto', value)}
                isDisabled={!cliente || contactos.length === 1}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  refresh: PropTypes.func.isRequired,

  id: PropTypes.string.isRequired,
};

export default Edit;
