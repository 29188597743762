import { DivFormGroup, Input, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest, URL_FILES } from '@utils/api';
import { isMongoId } from 'validator';
import ContratoEquipoVenta from '../edit/ContratoEquipoVenta';
import ContratoMonitoreo from '../edit/ContratoMonitoreo';
import ImprimirContrato from './ImprimirContrato';
import {
  esInteractivo,
  esInteractivoAdicional,
  esMonitoreo,
  esMonitoreoAdicional,
  mapCheck,
  mapDomicilioFront,
  mapContactoFront,
  sumarImportes,
} from '@utils/contratos';
import { mapPrecio } from '@utils/contratos';
import DetalleDomicilio from './DetalleDomicilio';
import DetalleContacto from './DetalleContacto';
import { useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const ContractByCustomer = () => {
  const { addNotification: notification } = useStoreNotification();
  const params = useParams();
  const [domicilios, setDomicilios] = useState([]);
  const [contactos, setContactos] = useState([]);

  const [state, setState] = useState({
    showModalImprimir: false,
    regimen: '',
    titular: { name: '', paterno: '', materno: '', celular: '', fijo: '', correo: '' },
    encargado: { razon_social: '', name: '', paterno: '', materno: '', celular: '', fijo: '', correo: '' },
    datosGenerarDocumento: [],
    catalogServicios: [],
    catalogoFormasPagoServicios: [],
    catalogoCondicionesPagoServicios: [],
    catalogoFormaPagoEquipos: [],
    catalogoTipoEstablecimientos: [],
    catalogoTipoCableados: [],
    monitoreo: [],
    monitoreoAdicionales: [],
    interactivos: [],
    interactivosAdicionales: [],
    correo_facturacion: '',
    card: false,
    clabe: '',
    nombreBanco: '',
    fecha_venta_equipo: moment().format('YYYY-MM-DD'),
    loading: true,
    domicilio_fiscal: null,
    domicilio_monitoreo: null,
    urlContrato: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const { customerId, documentoId } = params;
      const { data, estados, documentos } = await getRequest({ url: `customer/${customerId}/detail` });
      const {
        data: { numero_contrato },
      } = await getRequest({ url: `contracts/siguienteNumero` });
      const documento = documentos.find((x) => x._id === documentoId);
      let domicilios = data.listDomicilios || [];
      domicilios = domicilios.map(mapDomicilioFront);
      let contactos = data.listContactos || [];
      contactos = contactos.map(mapContactoFront);
      const regimen = data.regimen;
      const datosGenerarDocumento = {
        razon_social: data.razon_social,
        nombre_comercial: data.nombre_comercial,
        celular: data.mobile,
        fijo: data.phone,
        correo: data.email,
      };
      let contacto_titular = null,
        contacto_encargado = null,
        correo_facturacion = '',
        domicilio_fiscal = null,
        domicilio_monitoreo = null;
      if (contactos.length === 1) {
        correo_facturacion = contactos[0].email;
        contacto_titular = contactos[0];
        contacto_encargado = contactos[0];
      }
      if (domicilios.length === 1) {
        domicilio_fiscal = mapDomicilioFront(domicilios[0]);
        domicilio_monitoreo = mapDomicilioFront(domicilios[0]);
      }
      if (!domicilio_fiscal) {
        const domicilioFiscal = domicilios.find((x) => x.es_fiscal);
        domicilio_fiscal = domicilioFiscal && mapDomicilioFront(domicilioFiscal);
      }
      setDomicilios(domicilios.map(mapDomicilioFront));
      setContactos(contactos);
      setState((prevState) => ({
        ...prevState,
        numero_contrato,
        fecha: moment().format('YYYY-MM-DD'),
        showModal: true,
        correo_facturacion,
        titular: contacto_titular,
        encargado: contacto_encargado,
        domicilio_fiscal,
        domicilio_monitoreo,
        regimen: regimen,
        datosGenerarDocumento: datosGenerarDocumento,
        estados,
        nombreTipoDocumento: documento.nombre,
      }));

      const {
        data: serviciosData,
        formas_pago_servicios,
        condiciones_pago_servicios,
        equipo_seguridad,
        forma_pago_equipos,
        condiciones_pago_equipos,
        tipo_establecimientos,
        tipo_cableados,
      } = await getRequest({ url: `servicios/all` });
      const servicios = serviciosData;
      setState((prevState) => ({
        ...prevState,
        catalogoFormasPagoServicios: formas_pago_servicios.map(mapCheck),
        catalogoCondicionesPagoServicios: condiciones_pago_servicios.map(mapCheck),
        catalogoFormaPagoEquipos: forma_pago_equipos.map(mapCheck),
        catalogoCondicionesPagoEquipos: condiciones_pago_equipos.map(mapCheck),
        catalogoTipoEstablecimientos: tipo_establecimientos.map(mapCheck),
        catalogoTipoCableados: tipo_cableados.map(mapCheck),
        equipo_seguridad: equipo_seguridad.map(mapCheck),
        monitoreo: servicios.filter(esMonitoreo).map(mapPrecio),
        monitoreoAdicionales: servicios.filter(esMonitoreoAdicional).map(mapPrecio),
        interactivos: servicios.filter(esInteractivo).map(mapPrecio),
        interactivosAdicionales: servicios.filter(esInteractivoAdicional).map(mapPrecio),
        loading: false,
      }));
    };

    fetchData();
  }, [params]);

  const onChange = (event) => {
    const { name, value, checked, type } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: type === 'radio' || type === 'check' ? checked : value,
    }));
  };

  const onChangeServicios = (item, nombre, event) => {
    const { name, value, checked, type } = event.target;
    setState((prevState) => ({
      ...prevState,
      [nombre]: prevState[nombre].map((element, i) => {
        if (item === i) {
          return { ...element, [type === 'radio' ? 'check' : name]: type === 'radio' ? checked : value };
        }
        return { ...element, [type === 'radio' ? 'check' : name]: type === 'radio' ? false : element[type === 'radio' ? 'check' : name] };
      }),
    }));
  };

  const onChangeList = (item, nombre, name, value) => {
    setState((prevState) => ({
      ...prevState,
      [nombre]: prevState[nombre].map((element, i) => (item === i ? { ...element, [name]: value } : element)),
    }));
  };

  const getTotal = (nombre) => {
    const { equipo_seguridad, monitoreo, monitoreoAdicionales, interactivos, interactivosAdicionales } = state;
    if (nombre === 'Contrato de venta de equipo') return sumarImportes(equipo_seguridad).toString();
    return (
      sumarImportes(monitoreo) +
      sumarImportes(monitoreoAdicionales) +
      sumarImportes(interactivos) +
      sumarImportes(interactivosAdicionales)
    ).toString();
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (!isMongoId(params.domicilio_fiscal + '')) {
      validate.success = false;
      validate.message = 'Domicilio fiscal es requerido.';
    } else if (!isMongoId(params.domicilio_monitoreo + '')) {
      validate.success = false;
      validate.message = 'Domicilio de servicio es requerido.';
    } else if (!isMongoId(params.titular + '')) {
      validate.success = false;
      validate.message = 'Titular es requerido.';
    } else if (!isMongoId(params.encargado + '')) {
      validate.success = false;
      validate.message = 'Encargado es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const generarDocumento = async () => {
    const {
      correo_facturacion,
      monitoreo,
      monitoreoAdicionales,
      interactivos,
      interactivosAdicionales,
      catalogoFormasPagoServicios,
      catalogoCondicionesPagoServicios,
      clabe,
      nombreBanco,
      equipo_seguridad,
      catalogoFormaPagoEquipos,
      catalogoCondicionesPagoEquipos,
      catalogoTipoEstablecimientos,
      catalogoTipoCableados,
      fecha_venta_equipo,
      domicilio_fiscal,
      nombreTipoDocumento,
      titular,
    } = state;
    let { encargado, domicilio_monitoreo } = state;
    let data = {
      titular: titular?._id,
      encargado: encargado?._id,
      domicilio_fiscal: domicilio_fiscal?._id,
      domicilio_monitoreo: domicilio_monitoreo?._id,
      documentoId: params.documentoId,
      correo_facturacion,
      total: getTotal(nombreTipoDocumento),
      clabe,
    };
    let tipoDocumento = '';
    switch (nombreTipoDocumento.trim().toUpperCase()) {
      case 'CONTRATO DE MONITOREO':
        tipoDocumento = 'generaDocumentoContratoMonitoreo';
        data = {
          ...data,
          monitoreo,
          monitoreoAdicionales,
          interactivos,
          interactivosAdicionales,
          catalogoFormasPagoServicios: catalogoFormasPagoServicios,
          catalogoCondicionesPagoServicios: catalogoCondicionesPagoServicios,
          nombreBanco,
        };
        break;
      case 'CONTRATO DE VENTA DE EQUIPO':
        tipoDocumento = 'generaDocumentoContratoVentaEquipo';
        data = {
          ...data,
          equipo_seguridad,
          catalogoFormaPagoEquipos: catalogoFormaPagoEquipos,
          catalogoCondicionesPagoEquipos: catalogoCondicionesPagoEquipos,
          catalogoTipoEstablecimientos: catalogoTipoEstablecimientos,
          catalogoTipoCableados: catalogoTipoCableados,
          fecha_venta_equipo,
        };
        break;
    }
    if (validations(data)) {
      const { data: contrato } = await postRequest({ url: `customer/${tipoDocumento}/${params.customerId}/${params.documentoId}`, body: data });
      setState((prevState) => ({
        ...prevState,
        loading: false,
        showModalImprimir: true,
        urlContrato: `${URL_FILES}/${contrato.archivo}`,
        adjuntoId: contrato._id,
      }));
    }
  };

  const renderTipoMonitoreo = (nombre) => {
    if (nombre === 'Contrato de venta de equipo') {
      return (
        <ContratoEquipoVenta
          monitoreo={state.monitoreo}
          monitoreoAdicionales={state.monitoreoAdicionales}
          interactivos={state.interactivos}
          catalogoCondicionesPagoEquipos={state.catalogoCondicionesPagoEquipos}
          equipo_seguridad={state.equipo_seguridad}
          catalogoFormaPagoEquipos={state.catalogoFormaPagoEquipos}
          catalogoTipoEstablecimientos={state.catalogoTipoEstablecimientos}
          catalogoTipoCableados={state.catalogoTipoCableados}
          fecha_venta_equipo={state.fecha_venta_equipo}
          total={getTotal(nombre)}
          loading={state.loading}
          onChangeList={onChangeList}
          onChangeServicios={onChangeServicios}
          onChange={onChange}
          card={state.card}
          clabe={state.clabe}
        />
      );
    }
    return (
      <ContratoMonitoreo
        monitoreo={state.monitoreo}
        monitoreoAdicionales={state.monitoreoAdicionales}
        interactivos={state.interactivos}
        interactivosAdicionales={state.interactivosAdicionales}
        catalogoFormasPagoServicios={state.catalogoFormasPagoServicios}
        catalogoCondicionesPagoServicios={state.catalogoCondicionesPagoServicios}
        total={getTotal(nombre)}
        loading={state.loading}
        onChangeList={onChangeList}
        onChangeServicios={onChangeServicios}
        onChange={onChange}
        card={state.card}
        clabe={state.clabe}
        nombreBanco={state.nombreBanco}
      />
    );
  };

  const onChangeDomicilio = (tipo, domicilio) => {
    setState((prevState) => ({
      ...prevState,
      [tipo]: domicilio,
    }));
  };

  const closeImprimir = () => {
    setState((prevState) => ({ ...prevState, showModalImprimir: false }));
  };

  const onChangeContacto = (tipo, contacto) => {
    setState((prevState) => ({
      ...prevState,
      [tipo]: contacto,
    }));
  };

  const renderContract = () => {
    const { loading, correo_facturacion, domicilio_fiscal, domicilio_monitoreo, nombreTipoDocumento, titular, encargado } = state;
    const tipoMonitoreo = !loading ? renderTipoMonitoreo(nombreTipoDocumento) : <Loading />;
    return (
      <Module onClickBack={window.history.back} parent='Clientes' title='Generar contrato'>
        <Card>
          <Card.Body>
            <Container fluid>
              <Row>
                <Col sm='6'>
                  <FormInput name='numero_contrato' title='Numero contrato' value={state.numero_contrato} disabled />
                </Col>
                <Col sm='6'>
                  <DivFormGroup name='fecha' title='Fecha'>
                    <Input type='date' name='fecha' value={state.fecha} onChange={onChange} />
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <FormInput name='nombre' title='Razón social' value={state.datosGenerarDocumento.razon_social} disabled />
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <FormInput name='nombre' title='Nombre comercial' value={state.datosGenerarDocumento.nombre_comercial} disabled />
                </Col>
              </Row>
              <br />
              <DetalleDomicilio
                domicilio={domicilio_fiscal}
                tipo='domicilio_fiscal'
                label='Domicilio fiscal'
                setDomicilio={(data) => onChangeDomicilio('domicilio_fiscal', data)}
                domicilios={domicilios}
                customerId={params.customerId}
                setDomicilios={setDomicilios}
              />
              <br />
              <DetalleDomicilio
                domicilio={domicilio_monitoreo}
                tipo='domicilio_monitoreo'
                label='Domicilio monitoreo'
                setDomicilio={(data) => onChangeDomicilio('domicilio_monitoreo', data)}
                domicilios={domicilios}
                customerId={params.customerId}
                setDomicilios={setDomicilios}
              />

              <br />
              <DetalleContacto
                tipo='titular'
                label='Titular'
                contactos={contactos}
                setContactos={setContactos}
                contacto={titular}
                setContacto={(data) => onChangeContacto('titular', data)}
                customerId={params.customerId}
              />
              <br />
              <DetalleContacto
                tipo='encargado'
                label='Encargado'
                contactos={contactos}
                contacto={encargado}
                setContactos={setContactos}
                setContacto={(data) => onChangeContacto('encargado', data)}
                customerId={params.customerId}
              />
              <br />
              <Row>
                <Col sm='12'>
                  <DivFormGroup name='correo_facturacion' title='Correo electronico de facturación:'>
                    <Input
                      type='email'
                      name='correo_facturacion'
                      placeholder='Correo electronico de facturación:'
                      onChange={onChange}
                      value={correo_facturacion}
                    />
                  </DivFormGroup>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <br />
        {tipoMonitoreo}
        <br />
        <Card>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <Saving saving={loading} />
                <Button type='button' className='btn btn-success pull-right mr-5' onClick={generarDocumento}>
                  Guardar
                </Button>
                <Button onClick={close} className='btn btn-default pull-right mr-5' type='button'>
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ImprimirContrato
          showModal={state.showModalImprimir}
          onClose={closeImprimir}
          urlContrato={state.urlContrato}
          navigator={navigator}
          adjuntoId={state.adjuntoId}
        />
      </Module>
    );
  };

  return (
    <Loading loading={state.loading}>
      <Row className='fluid'>{renderContract()}</Row>
    </Loading>
  );
};

ContractByCustomer.propTypes = {
  params: PropTypes.object,
  notification: PropTypes.func.isRequired,
};

export default ContractByCustomer;
