import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ codigo: initialCodigo, nombre: initialNombre, itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();

  const [showModal, setShowModal] = useState(false);
  const [codigo, setCodigo] = useState(initialCodigo);
  const [nombre, setNombre] = useState(initialNombre);
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setCodigo('');
    setNombre('');
  };

  const open = () => {
    setCodigo(initialCodigo);
    setNombre(initialNombre);
    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = { codigo, nombre };

    if (validations(data)) {
      await putRequest({ url: `marcas/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='codigo' title='Código' required>
              <input
                type='text'
                name='codigo'
                className='form-control'
                onChange={(e) => setCodigo(e.target.value)}
                value={codigo}
                placeholder='Código'
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='nombre' title='Nombre' required>
              <input
                type='text'
                name='nombre'
                className='form-control'
                onChange={(e) => setNombre(e.target.value)}
                value={nombre}
                placeholder='Nombre'
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Edit;
