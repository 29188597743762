import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ itemId, nombre: initialNombre, clave: initialClave }) => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clave, setClave] = useState('');
  const [nombre, setNombre] = useState('');

  const close = () => {
    setShowModal(false);
    setNombre('');
    setClave('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setNombre(initialNombre);
    setClave(initialClave);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      clave: clave.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
    };
    if (validations(data)) {
      await putRequest({ url: `tipos_ordenes_servicios/${itemId}`, body: data });
      resetForm();
    }
  };

  const resetForm = () => {
    setTimeout(() => navigate(0), 1000);
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.clave)) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre)) {
      validate.success = false;
      validate.message = 'Nombre es requerida.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <FormInput title='Clave' name='clave' required onChange={(e) => setClave(e.target.value)} value={clave} />
          </Col>
          <Col sm='6'>
            <FormInput title='Nombre' name='nombre' required onChange={(e) => setNombre(e.target.value)} value={nombre} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  itemId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  clave: PropTypes.string.isRequired,
};

export default Edit;
