import moment from 'moment';
import React, { useState, useEffect, Fragment } from 'react';
import { Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import Concepto from './Concepto';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const ESTATUS_SOLICITUDES = {
  INICIADA: 'INICIADA',
  EN_PROCESO: 'EN PROCESO',
  RECHAZADA: 'RECHAZADA',
  ACEPTADA: 'ACEPTADA',
  CANCELADA: 'CANCELADA',
  TERMINADA: 'TERMINADA',
};

const DetalleSolicitudCompra = () => {
  const params = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { solicitudCompraId } = params;
      const { data } = await getRequest({ url: `solicitud-compra/${solicitudCompraId}` });
      setData(data);
    };

    fetchData();
  }, [params]);

  const renderDetalle = () => {
    const { detalle } = data;
    const detalles = detalle.map((d, i) => {
      const { codigo, descripcion, unidadMedida, cantidad } = d;
      return (
        <tr key={i}>
          <td>{cantidad}</td>
          <td>{codigo}</td>
          <td>{descripcion}</td>
          <td>{unidadMedida}</td>
        </tr>
      );
    });
    return (
      <table className='table table-condensed'>
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Código</th>
            <th>Descripción</th>
            <th>Unidad</th>
          </tr>
        </thead>
        <tbody>{detalle.length > 0 ? detalles : <RowsNotFound message='Sin detalle' colSpan={11} />}</tbody>
      </table>
    );
  };

  const renderData = () => {
    const {
      encabezado: { numero_solicitud, fecha, estatus_solicitud, sucursal, agente, observaciones },
    } = data;

    return (
      <Fragment>
        <div className='panel panel-primary'>
          <div className='panel-body'>
            <Row>
              <Col sm='6'>
                <Concepto label='Número de solicitud:' valor={numero_solicitud} />
                <Concepto label='Fecha:' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                <Concepto label='Estatus:' valor={ESTATUS_SOLICITUDES[estatus_solicitud]} />
              </Col>
              <Col sm='6'>
                <Concepto label='Sucursal:' valor={sucursal ? sucursal.name : null} />
                <Concepto label='Agente:' valor={agente ? agente.nombre : null} />
              </Col>
            </Row>
          </div>
        </div>
        <div className='panel panel-primary panel-table'>{renderDetalle()}</div>
        <div className='panel panel-primary'>
          <div className='panel panel-body'>
            <Row>
              <Col sm='12'>
                <Concepto label='Observaciones:' />
                {observaciones}
              </Col>
            </Row>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Module title={`Solicitud de compra #${data && data.encabezado.numero_solicitud}`} onClickBack={window.history.back} loading={!data}>
      {data && renderData()}
    </Module>
  );
};

DetalleSolicitudCompra.propTypes = {};

export default DetalleSolicitudCompra;
