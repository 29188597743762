import { DivFormGroup, FilterSelectNuevo, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ load, estados }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState({
    D_mnpio: '',
    c_CP: '',
    c_cve_ciudad: '',
    c_estado: '',
    c_mnpio: '',
    c_oficina: '',
    c_tipo_asenta: '',
    d_CP: '',
    d_asenta: '',
    d_ciudad: '',
    d_codigo: '',
    d_estado: '',
    d_tipo_asenta: '',
    d_zona: '',
    id_asenta_cpcons: '',
    municipioObject: null,
    isLoading: false,
    optionsMinicipios: [],
  });

  const close = () => {
    setShowModal(false);
    setFormState({
      D_mnpio: '',
      c_CP: '',
      c_cve_ciudad: '',
      c_estado: '',
      c_mnpio: '',
      c_oficina: '',
      c_tipo_asenta: '',
      d_CP: '',
      d_asenta: '',
      d_ciudad: '',
      d_codigo: '',
      d_estado: '',
      d_tipo_asenta: '',
      d_zona: '',
      id_asenta_cpcons: '',
      municipioObject: null,
      isLoading: false,
    });
  };

  const open = () => {
    setShowModal(true);
    setFormState((prevState) => ({ ...prevState, isLoading: false }));
  };

  const handleChange = (name, value) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeEstado = (value) => {
    setFormState({
      ...formState,
      estado: value,
      c_estado: value.estadoId,
      d_estado: value.estado,
      D_mnpio: '',
      c_mnpio: 0,
      c_cve_ciudad: 0,
      d_asenta: '',
      d_ciudad: '',
      d_codigo: '',
    });
  };

  const onChangeMunicipioInput = (value) => {
    setFormState((prevState) => ({ ...prevState, D_mnpio: value }));
  };

  const onChangeMunicipio = (itemSelect) => {
    if (itemSelect !== null) {
      setFormState({
        ...formState,
        D_mnpio: itemSelect.label,
        c_mnpio: itemSelect.value,
        municipioObject: itemSelect,
        c_cve_ciudad: 0,
        d_asenta: '',
        d_ciudad: '',
        d_codigo: '',
      });
    } else {
      setFormState({
        ...formState,
        D_mnpio: '',
        c_mnpio: 0,
        municipioObject: null,
        c_cve_ciudad: 0,
        d_asenta: '',
        d_ciudad: '',
        d_codigo: '',
      });
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      D_mnpio,
      c_CP,
      c_cve_ciudad,
      c_estado,
      c_mnpio,
      c_oficina,
      c_tipo_asenta,
      d_CP,
      d_asenta,
      d_ciudad,
      d_codigo,
      d_estado,
      d_tipo_asenta,
      d_zona,
      id_asenta_cpcons,
    } = formState;

    const data = {
      D_mnpio: D_mnpio.trim(),
      c_CP: c_CP,
      c_cve_ciudad: c_cve_ciudad,
      c_estado: c_estado,
      c_mnpio: c_mnpio,
      c_oficina: c_oficina,
      c_tipo_asenta: c_tipo_asenta,
      d_CP: d_CP,
      d_asenta: d_asenta.trim(),
      d_ciudad: d_ciudad.trim(),
      d_codigo: d_codigo,
      d_estado: d_estado.trim(),
      d_tipo_asenta: d_tipo_asenta.trim(),
      d_zona: d_zona.trim(),
      id_asenta_cpcons: id_asenta_cpcons,
    };

    if (validations(data)) {
      await postRequest({ url: 'colonia', body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.c_estado + '')) {
      validate.success = false;
      validate.message = 'Estado es requerido.';
    } else if (!params.c_mnpio) {
      validate.success = false;
      validate.message = 'Municipio es requerido.';
    } else if (isEmpty(params.d_ciudad + '')) {
      validate.success = false;
      validate.message = 'Localidad es requerida.';
    } else if (isEmpty(params.d_asenta + '')) {
      validate.success = false;
      validate.message = 'Colonia es requerida.';
    } else if (isEmpty(params.d_codigo + '')) {
      validate.success = false;
      validate.message = 'Código postal es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const { D_mnpio, c_estado, d_asenta, d_ciudad, d_codigo, estado, municipioObject, isLoading, optionsMinicipios } = formState;

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Nuevo' onClose={close} onSave={handleRegister} isLoading={isLoading} size='xl'>
        <Row>
          <Col>
            <DivFormGroup name='pais' title='País'>
              <Input name='pais' value='MÉXICO' disabled={true} />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='d_estado' title='Estado' required>
              <Select
                name='estado'
                value={estado}
                onChange={onChangeEstado}
                options={estados}
                getOptionValue={(option) => option.estadoId}
                getOptionLabel={(option) => option.estado}
              />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='municipio' title='Municipio' required>
              <FilterSelectNuevo
                async={true}
                disabled={c_estado !== '' ? false : true}
                value={municipioObject}
                inputValue={D_mnpio}
                onInputChange={onChangeMunicipioInput}
                onChangeOption={onChangeMunicipio}
                url={`municipios/autocomplete/${c_estado}`}
                fields={['c_mnpio', 'D_mnpio']}
                options={optionsMinicipios}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='d_ciudad' title='Localidad' required>
              <Input name='d_ciudad' placeholder='Localidad' onChange={(e) => handleChange(e.target.name, e.target.value)} value={d_ciudad} />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='d_asenta' title='Colonia' required>
              <Input name='d_asenta' placeholder='Colonia' onChange={(e) => handleChange(e.target.name, e.target.value)} value={d_asenta} />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='d_codigo' title='Código postal' required>
              <Input
                name='d_codigo'
                placeholder='Código postal'
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={d_codigo}
                maxLength={5}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  load: PropTypes.func.isRequired,

  estados: PropTypes.array.isRequired,
};

export default New;
