import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Icon, Loading, Module, RowsNotFound, Saving } from '@stateless';
import { deleteRequest, getRequest, postRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { format } from '@utils/parseCost';
import { isEmpty, isMongoId } from 'validator';
import CbBadge from '@cbcomponents/CbBadge';
import ModalAdjuntos from './ModalAdjuntos';
import ModalContactos from './ModalContactos';
import RowCotizacion from './RowCotizacion';
import RowFactura from './RowFactura';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Seguimiento = () => {
  const { addNotification: notification } = useStoreNotification();
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    isLoading: false,
    cargando: true,
    fecha: '',
    hora: '',
    motivoId: '',
    resultadoVisitaId: '',
    observaciones: '',
    fechaProximaVisita: '',
    horaProximaVisita: '',
    facturas: [],
    motivos: [],
    resultados: [],
    seguimientos: [],
    levantamientos: [],
    principal: null,
    isCompleted: false,
    seguimientoId: null,
    estatus_seguimientos: {
      INICIADO: 'INICIADO',
      EN_PROCESO: 'EN PROCESO',
      PENDIENTE: 'PENDIENTE',
      FINALIZADO: 'FINALIZADO',
    },
    estatus: 'INICIADO',
    opciones_estatus_seguimientos: [
      { value: 'INICIADO', label: 'INICIADO' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'PENDIENTE', label: 'PENDIENTE' },
      { value: 'FINALIZADO', label: 'FINALIZADO' },
    ],
    formOpen: false,
  });

  useEffect(() => {
    setTimeout(() => navigate('/seguimientos'), 1000);
  }, [navigate]);

  const loadData = async () => {
    const { clienteId } = params;
    setState((prevState) => ({ ...prevState, cargando: true }));
    const { data, facturas, motivos, resultados, seguimientos, principal, levantamientos, agentes, agente } = await getRequest({
      url: `seguimientos/cliente/${clienteId}`,
    });
    setState((prevState) => ({
      ...prevState,
      data,
      facturas,
      motivos,
      resultados,
      seguimientos,
      principal,
      levantamientos,
      agentes,
      agenteId: agente,
      agente: agente,
      cargando: false,
      formOpen: false,
    }));
  };

  const deleteSeguimiento = (select) => {
    notification({
      type: 'warning',
      title: 'Confirmación',
      message: '¿Confirma que desea eliminar el seguimiento?',
      autoDismiss: 0,
      position: 'tc',
      action: {
        label: 'Aceptar',
        callback: async () => {
          setState((prevState) => ({ ...prevState, cargando: true }));
          await deleteRequest({ url: `seguimientos/${select._id}` });
          setState((prevState) => ({ ...prevState, cargando: false }));
          loadData();
        },
      },
    });
  };

  const selectSeguimiento = (select) => {
    const {
      _id,
      fecha,
      hora,
      motivoId,
      resultadoVisitaId,
      observaciones,
      fechaProximaVisita,
      horaProximaVisita,
      agenteId,
      agentes_asignados,
      estatus,
      horaFinalizacion,
      fechaFinalizacion,
    } = select;
    let isCompleted = false;
    if (!isEmpty(fecha + '') && !isEmpty(observaciones + '') && isMongoId(motivoId + '') && isMongoId(resultadoVisitaId + '')) {
      isCompleted = true;
    }

    setState((prevState) => ({
      ...prevState,
      seguimientoId: _id,
      agenteId,
      isCompleted,
      fecha: moment(fecha).format('YYYY-MM-DD'),
      fechaFinalizacion: moment(fechaFinalizacion).format('YYYY-MM-DD'),
      agentesAsignadosId: agentes_asignados,
      hora,
      horaFinalizacion,
      motivoId,
      resultadoVisitaId,
      observaciones,
      fechaProximaVisita: fechaProximaVisita === null ? '' : moment(fechaProximaVisita).format('YYYY-MM-DD'),
      horaProximaVisita,
      estatus,
      cargando: false,
      formOpen: true,
    }));
  };

  const nuevoSeguimiento = () => {
    setState((prevState) => ({
      ...prevState,
      seguimientoId: null,
      agenteId: prevState.agente,
      isCompleted: false,
      fecha: moment().format('YYYY-MM-DD'),
      hora: moment().format('HH:mm'),
      motivoId: null,
      resultadoVisitaId: null,
      observaciones: '',
      fechaProximaVisita: null,
      horaProximaVisita: null,
      estatus: 'INICIADO',
      cargando: false,
      formOpen: true,
    }));
  };

  const handleCancelar = (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      formOpen: false,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const { clienteId } = params;
    const { seguimientoId, fecha, hora, motivoId, resultadoVisitaId, observaciones, fechaProximaVisita, horaProximaVisita, agenteId, estatus } =
      state;

    const data = {
      clienteId,
      seguimientoId,
      fecha,
      hora,
      motivoId: motivoId ? motivoId._id : null,
      agenteId: agenteId ? agenteId._id : null,
      resultadoVisitaId: resultadoVisitaId ? resultadoVisitaId._id : null,
      observaciones,
      fechaProximaVisita,
      horaProximaVisita,
      estatus,
    };
    if (validations(data)) {
      await postRequest({ url: 'seguimientos/cliente', body: data });
      loadData();
    }
  };

  const renderForm = () => {
    const { isLoading, facturas, data, seguimientos, principal, levantamientos, formOpen } = state;

    const rowsCotizaciones = levantamientos.map((s, i) => <RowCotizacion key={i} cotizacion={s} {...data} />);

    const rows = facturas.map((s, i) => <RowFactura key={i} factura={s} {...data} />);

    const totales = facturas.reduce(
      (t, f) => {
        t.importe += f.importe;
        t.saldo += f.saldo;
        return t;
      },
      { importe: 0, saldo: 0 }
    );

    return (
      <>
        <div className='panel panel-default panel-table'>
          <div className='panel-body'>
            {data !== null ? (
              <Row>
                <Col sm='4'>
                  <h5>
                    Numero cliente: <b>{data.numero_cliente}</b>
                  </h5>
                  <h5>
                    Nombre: <b>{renderNombreCliente(data)}</b>
                  </h5>
                  <h5>
                    Nombre Comercial: <b>{data.nombre_comercial}</b>
                  </h5>
                  <h5>
                    RFC: <b>{data.rfc}</b>
                  </h5>
                </Col>
                <Col sm='4'>
                  <h5>
                    Tel: <b>{data.phone}</b>
                  </h5>
                  <h5>
                    Cel: <b>{data.mobile}</b>
                  </h5>
                </Col>
                <Col sm='4'>
                  <h5>
                    Correo: <b>{data.email}</b>
                  </h5>
                  <ModalContactos clienteId={data._id} principal={principal} callback={loadData} />
                  <ModalAdjuntos clienteId={data._id} />
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
        <div className='panel panel-default panel-table'>
          <div className='panel-heading'>
            <div className='panel-title'>Últimas cotizaciones</div>
          </div>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th width='5%'>Número</th>
                <th width='10%'>Sucursal</th>
                <th width='15%'>Fecha alta</th>
                <th width='15%'>Fecha vencimiento</th>
                <th width='10%'>Importe</th>
                <th width='10%'>Estatus</th>
                <th width='10%'></th>
              </tr>
            </thead>
            <tbody>{levantamientos.length > 0 ? rowsCotizaciones : <RowsNotFound colSpan={7} />}</tbody>
          </table>
        </div>
        <div className='panel panel-default panel-table'>
          <div className='panel-heading'>
            <div className='panel-title'>Últimas facturas</div>
          </div>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th width='10%'>Número</th>
                <th width='10%'>Fecha</th>
                <th width='30%'>Folio</th>
                <th width='10%'>Importe</th>
                <th width='10%'>Saldo</th>
                <th width='10%'>Estatus</th>
                <th width='10%'></th>
              </tr>
            </thead>
            <tbody>{facturas.length > 0 ? rows : <RowsNotFound colSpan={7} />}</tbody>
            <tfoot>
              <tr>
                <th colSpan='3'>Totales</th>
                <th>${format(totales.importe, 2)}</th>
                <th>${format(totales.saldo, 2)}</th>
                <th colSpan='2'></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <Row>
          <div className={formOpen ? 'col-sm-6' : 'col-sm-12'}>
            <div className='panel panel-default panel-table'>
              <div className='panel-heading'>
                <h3 className='panel-title'>Seguimientos</h3>
              </div>
              <div className='panel-body'></div>
              <table className='table table-striped table-hover dataTable table-with-row-buttons' style={{ marginTop: '-21px' }}>
                <tbody>
                  {seguimientos.length > 0 ? (
                    seguimientos.map((s, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            Motivo: <b>{s.motivoId ? s.motivoId.nombre : null} </b> <br />
                            Agente: <b>{s.agenteId ? s.agenteId.nombre : null} </b> <br />
                            Fecha:{' '}
                            <b>
                              {s.fecha ? moment(s.fecha).format('YYYY/MM/DD') : null} {s.fecha ? s.hora : ''}
                            </b>{' '}
                            <br />
                            {s.resultadoVisitaId ? (
                              <span>
                                {' '}
                                Resultado: <b>{s.resultadoVisitaId.nombre}</b>
                              </span>
                            ) : null}{' '}
                            <br />
                            Observaciones: <b>{s.observaciones} </b>
                          </td>
                          <td>
                            <CbBadge type={s.estatus === 'INICIADO' ? 'primary' : 'secondary'}>{state.estatus_seguimientos[s.estatus]}</CbBadge>
                          </td>
                          <td className='with-actions'>
                            <span className='actions'>
                              <OverlayTrigger placement='top' overlay={<Tooltip id={i}>Eliminar</Tooltip>}>
                                <Button onClick={() => deleteSeguimiento(s)} className='btn btn-outline-danger btn-sm pull-right mr-5'>
                                  <Icon icon='remove' />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement='top' overlay={<Tooltip id={i}>Editar</Tooltip>}>
                                <Button onClick={() => selectSeguimiento(s)} className='btn btn-outline-primary btn-sm pull-right mr-5'>
                                  <Icon icon='share' />
                                </Button>
                              </OverlayTrigger>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <RowsNotFound colSpan={2} message='Sin visitas' />
                  )}
                </tbody>
              </table>
              <div className='panel-footer'>
                <Button onClick={nuevoSeguimiento} className='btn btn-primary btn-block'>
                  <Icon icon='plus' /> Agregar seguimiento
                </Button>
              </div>
            </div>
          </div>
          {formOpen && (
            <Col sm='6'>
              <div className='panel panel-default panel-table'>
                <div className='panel-heading'>
                  <div className='panel-title'>Seguimiento</div>
                </div>
                <div className='panel-body'></div>
                <div>
                  <Saving saving={isLoading} />
                  <button onClick={handleRegister} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                    Guardar
                  </button>
                  <button onClick={handleCancelar} className='btn btn-default pull-right mr-5' disabled={isLoading}>
                    Cancelar
                  </button>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    const now = new Date();

    if (!moment(params.fecha).isValid()) {
      validate.success = false;
      validate.message = 'Fecha es requerida.';
    } else if (validate.success && moment(now).isSameOrBefore(params.fecha)) {
      validate.success = false;
      validate.message = 'Fecha no puede ser mayor al día de hoy.';
    } else if (validate.success && isEmpty(params.hora + '')) {
      validate.success = false;
      validate.message = 'Hora es requerida.';
    } else if (validate.success && !isMongoId(params.motivoId + '')) {
      validate.success = false;
      validate.message = 'Motivo es requerido.';
    } else if (validate.success && !isMongoId(params.resultadoVisitaId + '')) {
      validate.success = false;
      validate.message = 'Resultado es requerido.';
    } else if (validate.success && isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones es requerido.';
    } else if (validate.success && !isEmpty(params.fechaProximaVisita + '')) {
      if (validate.success && isEmpty(params.horaProximaVisita + '')) {
        validate.success = false;
        validate.message = 'Hora de Próximo seguimiento es requerida.';
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const { cargando } = state;
  return (
    <Module onClickBack={window.history.back} parent='Seguimientos' title='Seguimiento'>
      {!cargando ? renderForm() : <Loading />}
    </Module>
  );
};

Seguimiento.propTypes = {};

export default Seguimiento;
