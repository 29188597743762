import { FormInput } from '@controls/index';
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Icon, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const Contizaciones = () => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    loading: false,
    rfc: '',
    razon_social: '',
    nombre_comercial: '',
    correo_cotizaciones: '',
    correo_cobranza: '',
    regimen_fiscal: '',
    url_video_semanal: '',
    enviar_correo_cobranza: false,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { data } = await getRequest({ url: `configurations` });
    setState({
      ...state,
      ...data,
      razon_social: data.razonSocial || '',
      regimen_fiscal: data.regimenFiscal || '',
      isLoading: false,
    });
  };

  const onChange = (e) => {
    const { type, name, value, checked } = e.target;
    setState({
      ...state,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleActualizar = async (e) => {
    e.preventDefault();
    const { rfc, razon_social, nombre_comercial, correo_cobranza, regimen_fiscal, url_video_semanal, enviar_correo_cobranza, correo_cotizaciones } =
      state;
    const data = {
      rfc,
      razon_social,
      nombre_comercial,
      correo_cobranza,
      regimen_fiscal,
      url_video_semanal,
      enviar_correo_cobranza,
      correo_cotizaciones,
    };
    if (validations(data)) {
      await putRequest({ url: `configurations`, body: data });
      loadData();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.rfc + '')) {
      validate.success = false;
      validate.message = 'RFC es requerido.';
    } else if (isEmpty(params.razon_social + '')) {
      validate.success = false;
      validate.message = 'Razón social es requerido.';
    } else if (isEmpty(params.nombre_comercial + '')) {
      validate.success = false;
      validate.message = 'Nombre comercial es requerido.';
    } else if (isEmpty(params.url_video_semanal + '')) {
      validate.success = false;
      validate.message = 'Video semanal es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const {
    isLoading,
    rfc,
    razon_social,
    nombre_comercial,
    correo_cobranza,
    regimen_fiscal,
    url_video_semanal,
    enviar_correo_cobranza,
    correo_cotizaciones,
  } = state;

  return (
    <Module title='Configuración general'>
      <form>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col xs='12'>
                <FormInput title='RFC' placeholder='RFC' required name='rfc' onChange={onChange} value={rfc} />
              </Col>
              <Col xs='12'>
                <FormInput title='Regimen Físcal' required name='regimen_fiscal' onChange={onChange} value={regimen_fiscal} />
              </Col>
              <Col xs='12'>
                <FormInput title='Razón Social' required name='razon_social' onChange={onChange} value={razon_social} />
              </Col>
              <Col xs='12'>
                <FormInput title='Nombre comercial' required name='nombre_comercial' onChange={onChange} value={nombre_comercial} />
              </Col>
            </Row>
            <Row>
              <Col xs='4'>
                <br />
                <div className='checkbox'>
                  <label htmlFor='enviar_correo_cobranza'>
                    <input type='checkbox' name='enviar_correo_cobranza' checked={enviar_correo_cobranza} onChange={onChange} />
                    Enviar correo a cobranza
                  </label>
                </div>
              </Col>
              <Col xs='8'>
                <FormInput title='Correo de cobranza' required name='correo_cobranza' onChange={onChange} value={correo_cobranza} />
              </Col>
            </Row>
            <Row>
              <Col xs='12'>
                <FormInput title='Correo de cotizaciones' required name='correo_cotizaciones' onChange={onChange} value={correo_cotizaciones} />
              </Col>
            </Row>
            <Row>
              <Col xs='12'>
                <FormInput title='Video semanal' required name='url_video_semanal' onChange={onChange} value={url_video_semanal} />
              </Col>
            </Row>
          </div>
          <div className='panel-footer'>
            <Row>
              <Col>
                <Saving saving={isLoading} />
                <Button onClick={handleActualizar} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    </Module>
  );
};

Contizaciones.propTypes = {};

export default Contizaciones;
