import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import { FormInput } from '@controls';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = useCallback(() => {
    setShowModal(false);
    setNombre('');
  }, []);

  const open = useCallback(() => {
    setShowModal(true);
    setIsLoading(false);
  }, []);

  const onChange = useCallback((event) => {
    const { name, value } = event.target;
    if (name === 'nombre') {
      setNombre(value);
    }
  }, []);

  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();
      const data = {
        nombre: nombre.trim(),
      };

      if (validations(data)) {
        await postRequest({ url: `medios_comunicaciones`, body: data });
        load();
        close();
      }
    },
    [nombre, load, close]
  );

  const validations = useCallback((params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  }, []);

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' size='lg' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput title='Nombre' required name='nombre' onChange={onChange} value={nombre} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  load: PropTypes.func.isRequired,
};

export default New;
