import React from 'react';
import PropTypes from 'prop-types';
import { format } from '@utils/parseCost';

const TextCurrency = ({ children, moneda }) => {
  if (moneda) {
    return (
      <span style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', textAlign: 'right' }}>
        <span>
          {typeof moneda === 'string' ? '$' : moneda.simbolo} {format(children, 2)}{' '}
        </span>
        <span style={{ color: '#71717a', fontSize: '0.50rem', marginBottom: '0.125rem', lineHeight: 1 }}>
          {typeof moneda === 'string' ? moneda : moneda.codigo}
        </span>
      </span>
    );
  }
  return <>$ {format(children, 2)}</>;
};

TextCurrency.propTypes = {
  children: PropTypes.string.isRequired,
  moneda: PropTypes.string,
};

export default TextCurrency;
