import { DivFormGroup, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, DropdownItem, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { putRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import { isEmpty, isMongoId } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const PagoManual = ({ itemId, loadData, factura: { serie, numero, saldo } }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nota, setNota] = useState('');

  const close = () => {
    setShowModal(false);
    setNota('');
  };

  const open = () => {
    setShowModal(true);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nota') {
      setNota(value);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const body = {
      nota,
      saldo,
      facturaId: itemId,
    };

    if (validations(body)) {
      await putRequest({ url: `pago-factura/${itemId}`, body });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.facturaId + '')) {
      validate.success = false;
      validate.message = 'Factura es requerida.';
    } else if (validate.success && isEmpty(params.nota + '')) {
      validate.success = false;
      validate.message = 'Observaciones son requeridas.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='check' /> Aplicar pago manual
      </DropdownItem>
      <CbModal title='Envio de correos' show={showModal} onClose={close} onSave={handleRegister}>
        <p className='lead'>
          <Icon icon='warning-sign' />
          &nbsp;¿Confirma que desea <strong>Pagar Manualmente</strong> la factura
          <strong>
            {serie}
            {numero}
          </strong>
          por un monto de <u>${format(saldo, 2)}</u>
        </p>
        <Row>
          <Col>
            <DivFormGroup>
              <Label name='nota' title='Observaciones' />
              <textarea id='nota' name='nota' className='form-control' rows='5' onChange={onChange} value={nota}></textarea>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

PagoManual.propTypes = {
  id: PropTypes.number.isRequired,
  factura: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default PagoManual;
