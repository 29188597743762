import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Input, DivFormGroup } from '@controls';
import { Col, Row } from 'react-bootstrap';

const FormContacto = ({
  name: initialName,
  tiposContactos,
  tipos: initialTipos,
  materno: initialMaterno,
  paterno: initialPaterno,
  email: initialEmail,
  sexo: initialSexo,
  phone: initialPhone,
  mobile: initialMobile,
  fecha_nacimiento: initialFechaNacimiento,
  setState,
}) => {
  const [name, setName] = useState(initialName);
  const [tipos] = useState(initialTipos);
  const [paterno, setPaterno] = useState(initialPaterno);
  const [materno, setMaterno] = useState(initialMaterno);
  const [email, setEmail] = useState(initialEmail);
  const [sexo] = useState(initialSexo);
  const [phone, setPhone] = useState(initialPhone);
  const [mobile, setMobile] = useState(initialMobile);
  const [fecha_nacimiento, setFechaNacimiento] = useState(initialFechaNacimiento);

  const handleSelect = (name, value) => {
    setState({ [name]: value });
  };

  return (
    <>
      <Row>
        <Col>
          <DivFormGroup name='tipos' title='Tipo Contacto' required>
            <Select
              value={tipos}
              isMulti
              name='tipos'
              placeholder='Selecciona opciones'
              options={tiposContactos}
              onChange={(value) => handleSelect('tipos', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
        <Col>
          <DivFormGroup name='name' title='Nombre' required>
            <Input name='name' onChange={(e) => setName(e.target.value)} placeholder='Nombre' value={name} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <DivFormGroup name='paterno' title='Paterno'>
            <Input name='paterno' onChange={(e) => setPaterno(e.target.value)} placeholder='Paterno' value={paterno} />
          </DivFormGroup>
        </Col>
        <Col>
          <DivFormGroup name='materno' title='Materno'>
            <Input name='materno' onChange={(e) => setMaterno(e.target.value)} placeholder='Materno' value={materno} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <DivFormGroup name='phone' title='Teléfono' required>
            <Input name='phone' onChange={(e) => setPhone(e.target.value)} placeholder='Teléfono' value={phone} />
          </DivFormGroup>
        </Col>
        <Col>
          <DivFormGroup name='mobile' title='Celular'>
            <Input name='mobile' onChange={(e) => setMobile(e.target.value)} placeholder='Celular' value={mobile} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <DivFormGroup name='email' title='Correo electrónico' required>
            <Input name='email' onChange={(e) => setEmail(e.target.value)} placeholder='Correo electrónico' value={email} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <DivFormGroup name='sexo' title='Sexo'>
            <Select
              value={sexo}
              name='sexo'
              placeholder='Selecciona opciones'
              options={[
                { value: 'M', label: 'Masculino' },
                { value: 'F', label: 'Femenino' },
              ]}
              onChange={(value) => handleSelect('sexo', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
        <Col>
          <DivFormGroup name='fecha_nacimiento' title='Fecha nacimiento'>
            <Input
              type='date'
              name='fecha_nacimiento'
              onChange={(e) => setFechaNacimiento(e.target.value)}
              placeholder='Fecha nacimiento'
              value={fecha_nacimiento}
            />
          </DivFormGroup>
        </Col>
      </Row>
    </>
  );
};

FormContacto.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  tiposContactos: PropTypes.array.isRequired,
  tipos: PropTypes.array.isRequired,
  materno: PropTypes.string.isRequired,
  paterno: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  sexo: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  fecha_nacimiento: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
};

export default FormContacto;
