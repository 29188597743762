import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, URL_FILES } from '@utils/api';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import CbTableResponsive from '@cbcomponents/CbTableResponsive';
import StatusServicio from '@components/servicios/StatusServicio';
import TextDate from '@controls/TextDate';

const OrdenesServicios = ({ id }) => {
  const [ordenes_servicios, setOrdenesServicios] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const close = () => {
    setShowModal(false);
    setOrdenesServicios([]);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `customers-registros/ordenes-servicios/${id}` });
    setShowModal(true);
    setOrdenesServicios(data);
  };

  const handleClickCotizacion = async (cotizacionId) => {
    const { data } = await getRequest({
      url: `cotizaciones/pdf/${cotizacionId}`,
      params: { codigos: true, desgloseKits: true, horasServicio: true },
    });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  }

  const handleClickOrdenServicio = async (ordenServicioId) => {
    const { data } = await getRequest({ url: `ordenes-servicio/pdf/${ordenServicioId}` });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  }

  return (
    <>
      <CbButtonOver icon='list' title='Ordenes de servicios' onClick={open} type='warning' />
      <CbModal title='Ordenes' show={showModal} onClose={close}>
        <Row>
          <Col sm='12'>
            <CbTableResponsive>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Orden</th>
                  <th>Descripción</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {ordenes_servicios.map((item) => (
                  <tr key={item._id}>
                    <td><TextDate>{item.fecha_solicitud}</TextDate></td>
                    <td>{item.numero_orden_servicio}</td>
                    <td>{item.referencia}</td>
                    <td><StatusServicio status={item.estatus_servicios}/></td>
                    <td>
                      <ButtonGroup className='pull-right'>
                        { item.cotizacionId && (<CbButtonOver icon='file' title='Cotizacion principal' onClick={() => handleClickCotizacion(item.cotizacionId)} type='primary' />)}
                        { item.cotizacionAdicionalId && (<CbButtonOver icon='file' title='Cotizacion adicional' onClick={() => handleClickCotizacion(item.cotizacionAdicionalId)} type='secondary' />)}
                        <CbButtonOver icon='file' title='Orden de trabajo' onClick={() => handleClickOrdenServicio(item._id)} type='success' />
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </CbTableResponsive>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

OrdenesServicios.propTypes = {
  refresh: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default OrdenesServicios;
