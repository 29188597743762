import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, DropdownItem } from 'react-bootstrap';
import { Fragment } from 'react';
import { Icon, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import moment from 'moment';

const Movimientos = ({ pedidoId }) => {
  const [showModal, setShowModal] = useState(false);
  const [movimientos, setMovimientos] = useState([]);

  const close = () => setShowModal(false);

  const open = async () => {
    setShowModal(true);
    const { data } = await getRequest({ url: `pedido/movimientos/${pedidoId}` });
    setMovimientos(data);
  };

  const renderRows = () => {
    return movimientos.map((movimiento, i) => (
      <tr key={i}>
        <td>{movimiento.clase_movimiento.descripcion}</td>
        <td>{movimiento.cantidad}</td>
        <td>{movimiento.equipmentId.code}</td>
        <td>{movimiento.equipmentId.description}</td>
        <td>{movimiento.ubicacionId.nombre}</td>
        <td>{moment(movimiento.fecha).local().format('YYYY/MM/DD HH:mm')}</td>
      </tr>
    ));
  };

  const rows = movimientos.length > 0 ? renderRows() : <RowsNotFound colSpan={6} message='No se han agregado movimientos' />;

  return (
    <Fragment>
      <DropdownItem onClick={open}>
        <Icon icon='list' /> Movimientos
      </DropdownItem>
      <Modal show={showModal} onHide={close} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Movimientos</Modal.Title>
        </Modal.Header>
        <table className='table table-condensed table-hover table-striped'>
          <thead>
            <tr>
              <th width='10%'>Movimiento</th>
              <th width='5%'>Cantidad</th>
              <th width='5%'>Código</th>
              <th width='20%'>Descripción</th>
              <th width='10%'>Ubicación</th>
              <th width='10%'>Fecha</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <Modal.Footer>
          <button onClick={close} className='btn btn-default pull-right' type='button'>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

Movimientos.propTypes = {
  pedidoId: PropTypes.string.isRequired,
};

export default Movimientos;
