import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableFooterBotton from '@cbcomponents/CbTableFooterBotton';
import Required from '@controls/Required';
import RowDetalle from './RowDetalle';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const INIT_DETALLE = {
  servicioId: null,
  measureId: null,
  cantidad: 1,
  name: '',
  listaPreciosId: null,
  obj: null,
};

const Edit = (props) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading] = useState(false);
  const [nombre, setNombre] = useState('');
  const [clave, setClave] = useState('');
  const [detalles, setDetalles] = useState([]);

  const close = () => {
    setShowModal(false);
    setDetalles([]);
    setNombre('');
    setClave('');
  };

  const open = async () => {
    const { itemId } = props;
    const { data } = await getRequest({ url: `tipos-monitoreos/${itemId}` });
    setShowModal(true);
    setNombre(data.nombre);
    setClave(data.clave);
    setDetalles(
      data.detalles
        ? data.detalles.map((service) => ({
            _id: service._id,
            servicio: service.servicioId,
            descripcion: service.descripcion,
            cantidad: service.cantidad,
            unidad: service.measureId,
            listasPrecios: service.listasPrecios,
          }))
        : [
            {
              ...INIT_DETALLE,
              listasPrecios: props.listas_precios.map((x) => ({
                listaPrecioId: x,
                precio: 0,
                descuento: 0,
              })),
            },
          ]
    );
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { loadData, itemId } = props;

    const data = {
      clave: clave.trim(),
      nombre: nombre.trim().toUpperCase(),
      detalles: detalles.map((x) => ({
        _id: x._id,
        servicioId: x.servicio?._id,
        measureId: x.unidad?._id,
        descripcion: x.descripcion,
        cantidad: cleanNumber(x.cantidad),
        listasPrecios: x.listasPrecios,
      })),
    };

    if (validations(data)) {
      await putRequest({ url: `tipos-monitoreos/${itemId}`, body: data });
      setShowModal(false);
      loadData();
    }
  };

  const addItemServicio = () => {
    setDetalles([
      ...detalles,
      {
        ...INIT_DETALLE,
        listasPrecios: props.listas_precios.map((x) => ({
          listaPrecioId: x,
          precio: 0,
          descuento: 0,
        })),
      },
    ]);
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.clave)) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre)) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else {
      for (let i = 0; i < params.detalles.length; i++) {
        const servicio = params.detalles[i];
        if (!isMongoId(servicio.servicioId + '')) {
          validate.success = false;
          validate.message = 'Servicio en servicios es requerido.';
          break;
        } else if (servicio.cantidad <= 0) {
          validate.success = false;
          validate.message = 'Cantidad en servicios, debe ser mayor a cero.';
          break;
        } else if (!isMongoId(servicio.measureId + '')) {
          validate.success = false;
          validate.message = 'Unidad en servicios es requerida.';
          break;
        }
      }
    }
    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChangeServicioDetalle = (index, servicio) => {
    setDetalles(
      detalles.map((x, i) => {
        if (i === index) {
          return servicio;
        }
        return x;
      })
    );
  };

  const onRemoveItemServicio = (index) => {
    setDetalles(detalles.filter((x, i) => i !== index));
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col sm='6'>
                <FormInput title='Clave' name='clave' required onChange={(e) => setClave(e.target.value)} value={clave} />
              </Col>
              <Col sm='6'>
                <FormInput title='Nombre' name='nombre' required onChange={(e) => setNombre(e.target.value)} value={nombre} />
              </Col>
            </Row>
          </div>
        </div>
        <Row>
          <Col sm='12'>
            <CbPanelTableResponsive
              title={
                <>
                  Servicios <Required />
                </>
              }
            >
              <thead>
                <tr>
                  <th width='20%'>Servicio</th>
                  <th width='30%'>Descripción</th>
                  <th width='20%'>Unidad</th>
                  <th width='20%'>Cantidad</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <CbTableBody colSpan={5}>
                {detalles.map((servicio, i) => (
                  <RowDetalle
                    key={i}
                    servicio={servicio}
                    unidades={props.unidades}
                    onChangeServicioDetalle={(servicio) => onChangeServicioDetalle(i, servicio)}
                    onRemoveItemServicio={() => onRemoveItemServicio(i)}
                    listas_precios={props.listas_precios}
                  />
                ))}
                <CbTableFooterBotton colSpan={5} onClick={addItemServicio} title='Agregar servicio' />
              </CbTableBody>
            </CbPanelTableResponsive>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  unidades: PropTypes.array.isRequired,
  nombre: PropTypes.string.isRequired,
  clave: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,

  detalles: PropTypes.array,
  listas_precios: PropTypes.array.isRequired,
};

export default Edit;
