import { DivFormGroup, Input, Label, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmail, isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store.js';
const subjectDefault = 'Envío de referencia para pagar en Oxxo';

const SendReferenciaEmail = ({ email, contactos, reference, referenceId }) => {
  const { addNotification: notification } = useStoreNotification();

  const [showModal, setShowModal] = useState(false);
  const [subject, setSubject] = useState(subjectDefault);
  const [emailState, setEmailState] = useState(
    email ||
      contactos
        .filter((x) => x.email)
        .map((x) => x.email)
        .join(', ')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [checkEnviarCopia, setCheckEnviarCopia] = useState(true);

  const close = () => {
    setShowModal(false);
    setSubject(subjectDefault);
  };

  const open = () => {
    setShowModal(true);
    setSubject(subjectDefault);
    setEmailState(email);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'subject') setSubject(value);
    if (name === 'email') setEmailState(value);
  };

  const handleSendReference = async (event) => {
    event.preventDefault();
    const body = {
      referenceId,
      reference,
      subject: subject.trim(),
      email: emailState.trim(),
      enviarCopia: checkEnviarCopia,
    };

    if (validations(body)) {
      await postRequest({ url: `email/reference/oxxo` });
      close();
    }
  };

  const onChangeCheck = (event) => {
    const { name, checked } = event.target;
    if (name === 'checkEnviarCopia') setCheckEnviarCopia(checked);
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.reference + '')) {
      validate.success = false;
      validate.message = 'Falta seleccionar una referencia valida.';
    } else if (validate.success && isEmpty(params.subject + '')) {
      validate.success = false;
      validate.message = 'Asunto es requerido.';
    } else if (validate.success && isEmpty(params.email + '')) {
      validate.success = false;
      validate.message = 'Destinatario(s) es requerido.';
    }

    const list = params.email.split(',');

    if (validate.success && list.length > 0) {
      const fail = list.filter((e) => {
        if (!isEmpty(e.trim() + '')) {
          return !isEmail(e.trim() + '');
        }
      });

      if (fail.length > 0) {
        validate.success = false;
        validate.message = 'Favor de especificar correo válidos en el campo de Destinatario(s).';
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='envelope' title='Enviar' />
      <CbModal show={showModal} title='Enviar' onClose={close} onSave={handleSendReference} isLoading={isLoading}>
        <DivFormGroup>
          <Label name='subject' title='Asunto:' required />
          <Input name='subject' onChange={onChange} defaultValue={subject} />
        </DivFormGroup>
        <DivFormGroup>
          <Label name='email' title='Destinatario(s):' required />
          <TextArea name='email' onChange={onChange} defaultValue={emailState} />
          <p>
            <br />
            <small>Separar correos por comas: correo1@codigoblanco.mx,correo2@codigoblanco.mx </small>
          </p>
        </DivFormGroup>
        <div className='checkbox'>
          <label>
            <input type='checkbox' name='checkEnviarCopia' onChange={onChangeCheck} checked={checkEnviarCopia} /> Enviar a copia a correo de cobranza
          </label>
        </div>
      </CbModal>
    </>
  );
};

SendReferenciaEmail.propTypes = {
  id: PropTypes.number.isRequired,
  referenceId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactos: PropTypes.array.isRequired,
  reference: PropTypes.string.isRequired,
};

export default SendReferenciaEmail;
