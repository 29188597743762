import { DivFormGroup, FilterSelectNuevo } from '@controls';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { isMongoId } from 'validator';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import RemoverClientesContacto from './RemoverClientesContacto';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const ClientesContacto = () => {
  const { addNotification: notification } = useStoreNotification();
  const { contactoId } = useParams();
  const [data, setData] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [clienteObj, setClienteObj] = useState(null);
  const [clienteName, setClienteName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { data } = await getRequest({ url: `contactos/${contactoId}` });
    setData(data);
    setClientes(data.clientes);
    setLoading(false);
  };

  const onChangeClienteInput = (e) => {
    setClienteName(e);
  };

  const onChangeCliente = async (itemSelect) => {
    if (itemSelect !== null) {
      setClienteObj(itemSelect.label);
      const clienteId = itemSelect.value._id;
      if (validations({ clienteId, contactoId })) {
        await postRequest({ url: `contacto-cliente/${contactoId}`, body: { clienteId } });
        loadData();
      }
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.contactoId)) {
      validate.success = false;
      validate.message = 'Contacto es requerido.';
    } else if (!isMongoId(params.clienteId)) {
      validate.success = false;
      validate.message = 'Cliente es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const returnRowsClientes = () => {
    return clientes.map((c, i) => (
      <tr key={i}>
        <td>{renderNombreCliente(c)}</td>
        <td>
          <RemoverClientesContacto
            id={i}
            contactoId={contactoId}
            clienteId={c._id}
            nombre={renderNombreCliente(c)}
            loadData={loadData}
            btnClass='btn btn-sm btn-danger pull-right'
          />
        </td>
      </tr>
    ));
  };

  const returnData = () => (
    <div>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='clienteId' title='Busqueda de Clientes'>
            <FilterSelectNuevo
              async={true}
              disabled={false}
              value={clienteObj}
              inputValue={clienteName}
              onInputChange={onChangeClienteInput}
              onChangeOption={onChangeCliente}
              url={`clientes-agentes-autocomplete`}
              fields={['_id', 'name']}
            />
          </DivFormGroup>
        </Col>
      </Row>
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th width='80%'>Clientes</th>
            <th width='20%'></th>
          </tr>
        </thead>
        <CbTableBody colSpan={2}>{returnRowsClientes()}</CbTableBody>
      </CbPanelTableResponsive>
    </div>
  );

  return (
    <Module onClickBack={window.history.back} parent='Contactos' title='Clientes asignados'>
      {loading && data === null ? <Loading /> : returnData()}
    </Module>
  );
};

ClientesContacto.propTypes = {};

export default ClientesContacto;
