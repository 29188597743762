import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from '@components/forms/InputNumber';

const RowPrecio = ({ listaPrecioMeasure, indexI }) => {
  const renderListaPrecio = (i, j, lista) => (
    <>
      <td>
        <InputNumber className='form-control' name='precio' value={lista.precio} />
      </td>
      <td>
        <InputNumber className='form-control' name='descuento' value={lista.descuento} />
      </td>
    </>
  );

  return (
    <tr>
      <td>{listaPrecioMeasure?.unidad?.measure}</td>
      <td>
        <InputNumber className='form-control' name='precio' value={listaPrecioMeasure.precio} />
      </td>
      <td>
        <InputNumber className='form-control' name='descuento' value={listaPrecioMeasure.descuento} />
      </td>
      {listaPrecioMeasure.listasPrecios.map((listaPrecio, j) => renderListaPrecio(indexI, j, listaPrecio))}
    </tr>
  );
};

RowPrecio.propTypes = {
  listaPrecioMeasure: PropTypes.object.isRequired,
  indexI: PropTypes.number.isRequired,
};

export default RowPrecio;
