import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditSellers = ({ idSellers, loadSellers }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [fatherLastName, setFatherLastName] = useState('');
  const [motherLastName, setMotherLastName] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = async () => {
    const {
      data: { name, fatherLastName, motherLastName },
    } = await getRequest({ url: `sellers/${idSellers}` });
    setShowModal(true);
    setName(name);
    setFatherLastName(fatherLastName);
    setMotherLastName(motherLastName);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      name: name.trim(),
      fatherLastName: fatherLastName.trim(),
      motherLastName: motherLastName.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `sellers/${idSellers}`, body: data });
      loadSellers();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.name + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (validate.success && isEmpty(params.fatherLastName + '')) {
      validate.success = false;
      validate.message = 'Apellido Paterno es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='name' title='Nombre'>
              <Input name='name' onChange={(e) => setName(e.target.value)} value={name} />
            </DivFormGroup>
            <DivFormGroup name='fatherLastName' title='Apellido Paterno'>
              <Input name='fatherLastName' onChange={(e) => setFatherLastName(e.target.value)} value={fatherLastName} />
            </DivFormGroup>
            <DivFormGroup name='motherLastName' title='Apellido Materno'>
              <Input name='motherLastName' onChange={(e) => setMotherLastName(e.target.value)} value={motherLastName} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditSellers.propTypes = {
  idSellers: PropTypes.string.isRequired,
  loadSellers: PropTypes.func.isRequired,
};

export default EditSellers;
