import { DivFormGroup } from '@controls';
import React, { useState } from 'react';
import { Button, Col, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';

const Import = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [csv, setCsv] = useState(null);
  const [valid] = useState(false);
  const [msg] = useState('');
  const [progress, setProgress] = useState(0);
  const [frm, setFrm] = useState(null);

  const handleReadCSV = async (event) => {
    event.preventDefault();
    setCargando(true);
    setProgress(0);
    setCsv(null);
    setIsLoading(true);
    const form = new FormData(frm);
    await postUploadRequest({ url: `importar-clientes-saldo-csv`, form });
    setCargando(false);
    setProgress(0);
    setIsLoading(false);
  };

  const renderDataCSV = () => {
    const { detalle, headers } = csv || { detalle: [], headers: [] };

    return (
      <Row>
        {!valid && (
          <div className='alert alert-danger'>
            <p>{msg}</p>
          </div>
        )}
        <Col sm='12' style={{ position: 'relative', overflowX: 'auto', width: '100%' }}>
          <table className='table table-condensed table-bordered'>
            <thead>
              <tr>
                <th className='col-sm-6'>
                  <strong>Acción</strong>
                </th>
                {headers.map((e, i) => (
                  <th key={i} className='col-sm-6'>
                    <strong>{e}</strong>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {detalle.length > 0 ? (
                detalle.map((d, i) => {
                  let datoCreacion = 0;
                  return (
                    <>
                      <tr key={i} className={d[datoCreacion] === true ? 'success' : d[datoCreacion] === false ? 'info' : ''}>
                        <td className='col-sm-6'>
                          {d[datoCreacion] === true ? 'Creación' : d[datoCreacion] === false ? 'Actualización' : 'Ninguna'}
                        </td>
                        {headers.map((e, j) => (
                          <td key={j} className='col-sm-6'>
                            {d[j]}
                          </td>
                        ))}
                      </tr>
                    </>
                  );
                })
              ) : (
                <RowsNotFound colSpan={7} />
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  return (
    <Module onClickBack={window.history.back} title='Importar saldos'>
      <br />
      <form
        ref={(form) => {
          setFrm(form);
        }}
        onSubmit={handleReadCSV}
        encType='multipart/form-data'
      >
        <Row>
          <Col sm='3'>
            <DivFormGroup>
              <div className='btn btn-default btn-file'>
                <Icon icon='paperclip' /> Archivo de saldos
                <input type='file' name='csv' id='csv' />
              </div>
            </DivFormGroup>
          </Col>
          <Col sm='9'>
            <Button type='submit' className='btn btn-success' disabled={cargando}>
              <Icon icon='import' /> Importar
            </Button>
            {isLoading ? <Saving action='Importantando' /> : ''}
          </Col>
          <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
        </Row>
      </form>
      {csv && renderDataCSV()}
    </Module>
  );
};

Import.propTypes = {};

export default Import;
