import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ nombre: initialNombre, orden: initialOrden, itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [orden, setOrden] = useState(1);
  const [nombre, setNombre] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setOrden(1);
    setNombre('');
  };

  const open = () => {
    setNombre(initialNombre);
    setOrden(initialOrden);
    setShowModal(true);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'orden') setOrden(value);
    if (name === 'nombre') setNombre(value);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = { nombre, orden };
    if (validations(data)) {
      setIsLoading(true);
      await putRequest({ url: `aviso-privacidad/${itemId}`, body: data });
      loadData();
      close();
      setIsLoading(false);
    }
  };

  const validations = (params) => {
    let validate = { success: true, message: '' };

    if (isEmpty(params.orden + '')) {
      validate.success = false;
      validate.message = 'Orden es requerido.';
    } else if (params.orden <= 0) {
      validate.success = false;
      validate.message = 'Orden debe ser mayor a cero.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Párrafo es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver title='Editar' onClick={open} icon='pencil' />
      <CbModal show={showModal} title='Editar aviso de privacidad' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='orden' title='Orden' required>
              <input type='number' className='form-control' name='orden' id='orden' placeholder='Orden' value={orden} onChange={onChange} />
            </DivFormGroup>
            <DivFormGroup name='nombre' title='Párrafo' required>
              <textarea
                id='nombre'
                name='nombre'
                className='form-control'
                placeholder='Párrafo'
                onChange={onChange}
                value={nombre}
                rows={8}
              ></textarea>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  orden: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Edit;
