import React, { useState, useEffect } from 'react';
import { SubModule, Loading } from '@stateless';
import { getRequest } from '@utils/api';
import TableContactos from './TableContactos';
import { useParams } from 'react-router-dom';

const Ubicaciones = () => {
  const params = useParams();
  const [directorio, setDirectorio] = useState([]);
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadList();
  }, [params]);
  const loadList = async () => {
    const { customerId } = params;
    const { data, customer } = await getRequest({ url: `contactos/${customerId}/ubicaciones` });
    setDirectorio(data);
    setCustomer(customer);
    setLoading(false);
  };

  const renderNombre = (cliente) => {
    let view = '';

    if (cliente.regimen === 'F') {
      let titulo = '';

      if (typeof cliente.tituloClienteId !== 'undefined') {
        if (cliente.tituloClienteId !== null) {
          titulo = cliente.tituloClienteId.abreviatura;
        }
      }

      view = `${titulo} ${cliente.name} ${cliente.paterno} ${cliente.materno}`;
    } else if (cliente.regimen === 'M') {
      view = cliente.razon_social;
    }
    return view;
  };

  const renderView = () => {
    const { customerId } = params;
    return <TableContactos {...{ directorio, customer, loading }} customerId={customerId} loadList={loadList} />;
  };

  const name = !loading ? ` de ${renderNombre(customer)}` : '';

  return (
    <SubModule onClickBack={window.history.back} title={`Ubicación de los Contactos ${name}`}>
      {!loading ? renderView() : <Loading />}
    </SubModule>
  );
};

Ubicaciones.propTypes = {};

export default Ubicaciones;
