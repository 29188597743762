import Label from '@controls/Label';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DropdownItem, Modal } from 'react-bootstrap';
import { Icon, Saving } from '@stateless';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';

const ConfiguracionCorreo = ({ idUser, configuration_email, loadUsers }) => {
  const [showModal, setShowModal] = useState(false);
  const [validation, setValidation] = useState({
    error: false,
    message: '',
    success: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(configuration_email ? configuration_email.email : '');
  const [password, setPassword] = useState(configuration_email ? configuration_email.password : '');

  const close = (event) => {
    event.preventDefault();
    setShowModal(false);
    setValidation({
      error: false,
      message: '',
      success: false,
    });
    setIsLoading(false);
  };

  const open = () => {
    setEmail(configuration_email ? configuration_email.email : '');
    setPassword(configuration_email ? configuration_email.password : '');
    setShowModal(true);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const handleEditUser = async (event) => {
    event.preventDefault();
    const data = { password, email };
    if (validations(data)) {
      setValidation({ error: false, message: '' });
      await putRequest({ url: `users/configuracion-correo/${idUser}`, body: data });
      loadUsers();
      close(event);
    }
  };

  const validations = (params) => {
    let success = true;
    if (isEmpty(params.password + '')) {
      setValidation({ error: true, message: 'Password es requerido.' });
      setIsLoading(false);
      success = false;
    } else if (isEmpty(params.email + '')) {
      setValidation({ error: true, message: 'Correo electronico.' });
      setIsLoading(false);
      success = false;
    }
    return success;
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='envelope' /> Configuración correo de envío
      </DropdownItem>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Configurar correo de envío</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEditUser}>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='form-group'>
                  <Label name='email' required>
                    Correo electronico
                  </Label>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    name='email'
                    autoComplete='off'
                    placeholder='Correo electronico'
                    value={email}
                    onChange={onChange}
                  />
                </div>
                <div className='form-group'>
                  <Label name='password' required>
                    Password
                  </Label>
                  <input
                    type='password'
                    className='form-control'
                    name='password'
                    placeholder='Password'
                    autoComplete='off'
                    value={password}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {validation.error && (
            <div className='alert alert-danger text-center' role='alert'>
              {validation.message}
            </div>
          )}
          <Saving saving={isLoading} />
          <button onClick={handleEditUser} className='btn btn-success pull-right' disabled={isLoading}>
            Guardar
          </button>
          <span className='pull-right'>&nbsp;</span>
          <button onClick={close} disabled={isLoading} className='btn btn-default pull-right' type='button'>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ConfiguracionCorreo.propTypes = {
  idUser: PropTypes.string.isRequired,
  configuration_email: PropTypes.object.isRequired,
  loadUsers: PropTypes.func.isRequired,
};

export default ConfiguracionCorreo;
