import { ABONADA, CANCELADA, PAGADA, PAGADA_MANUAL, PENDIENTE } from '@config/constants';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { format } from '@utils/parseCost';
import Concepto from './Concepto';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetalleFactura = () => {
  const { facturaId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `factura/${facturaId}` });
      setData(data);
    };

    fetchData();
  }, [facturaId]);

  const renderDetalle = () => {
    const { detalle } = data;
    return (
      <table className='table table-condensed'>
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Código</th>
            <th>Descripción</th>
            <th>Medida</th>
            <th>Precio</th>
            <th>Subtotal</th>
            <th>Descuento</th>
            <th>IVA</th>
            <th>IEPS</th>
            <th>Retención</th>
            <th>Importe</th>
          </tr>
        </thead>
        <tbody>
          {detalle.length > 0 ? (
            detalle.map((d, i) => {
              const { codigo, descripcion, unidadMedidaId, precio, cantidad, sub_total, descuento, iva, ieps, retencion, importe } = d;

              return (
                <tr key={i}>
                  <td>{format(cantidad, 2)}</td>
                  <td>{codigo}</td>
                  <td>{descripcion}</td>
                  <td>{unidadMedidaId ? unidadMedidaId.measure : ''}</td>
                  <td>${format(precio, 2)}</td>
                  <td>${format(sub_total, 2)}</td>
                  <td>${format(descuento, 2)}</td>
                  <td>${format(iva, 2)}</td>
                  <td>${format(ieps, 2)}</td>
                  <td>${format(retencion, 2)}</td>
                  <td>${format(importe, 2)}</td>
                </tr>
              );
            })
          ) : (
            <RowsNotFound message='Sin detalle' colSpan={11} />
          )}
        </tbody>
      </table>
    );
  };

  const renderDepositos = () => {
    const { movimientos } = data;

    const depositoTotal = movimientos.reduce((t, r) => (t += r.depositos), 0);

    return (
      <div className='container-fluid'>
        <h3>
          <small>Depósitos</small>
        </h3>
        <table className='table table-condensed'>
          <thead>
            <tr>
              <th>Conciliación</th>
              <th>Fecha</th>
              <th>Concepto</th>
              <th>Sucursal</th>
              <th>Referencia</th>
              <th>Código</th>
              <th>Depósitos</th>
              <th>Aplicación manual</th>
            </tr>
          </thead>
          <tbody>
            {movimientos.length > 0 ? (
              movimientos.map((d, i) => {
                const { fecha, concepto, sucursal, referencia, codigo, depositos, created, fecha_pago, conciliacion, nota } = d;

                return (
                  <tr key={i}>
                    <td>{moment(created).local().format('DD/MM/YYYY HH:mm')}</td>
                    <td>
                      {fecha.substring(0, 2)}/{fecha.substring(2, 4)}/{fecha.substring(4, 6)}
                    </td>
                    <td>{concepto}</td>
                    <td>{sucursal}</td>
                    <td>{referencia}</td>
                    <td>{codigo}</td>
                    <td>${format(depositos, 2)}</td>

                    {conciliacion === 'MANUAL' ? (
                      <td>
                        <small>{nota}</small>
                        <br />
                        {moment(fecha_pago).local().format('DD/MM/YYYY HH:mm')}
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                );
              })
            ) : (
              <RowsNotFound message='Sin depositos' colSpan={8} />
            )}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={6}>
                <span className='pull-right'>Total</span>
              </th>
              <th>$ {format(depositoTotal, 2)}</th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  const switchEstado = (estado) => {
    let text = '';
    let color = 'secondary';

    if (estado === PENDIENTE) {
      text = 'Pendiente de pago';
      color = 'secondary';
    } else if (estado === PAGADA) {
      text = 'Pagada';
      color = 'success';
    } else if (estado === PAGADA_MANUAL) {
      text = 'Pago manual';
      color = 'success';
    } else if (estado === ABONADA) {
      text = 'Abonada';
      color = 'warning';
    } else if (estado === CANCELADA) {
      text = 'Cancelada';
      color = 'danger';
    }

    return <span className={`label label-${color}`}>{text}</span>;
  };

  if (!data) return null;
  const {
    encabezado: {
      serie,
      numero,
      uuid,
      fecha,
      fecha_entrega,
      importe,
      saldo,
      moneda,
      forma_pago,
      tipoDocumentoId,
      agenteId,
      customerId,
      estado,
      cancelacion,
      fecha_pago,
      pago_manual,
    },
  } = data;
  const cliente = renderNombreCliente(customerId);

  let colorSaldo = 'text-muted';

  if (saldo >= importe) {
    colorSaldo = 'text-danger';
  } else if (saldo === 0) {
    colorSaldo = 'text-success';
  } else {
    colorSaldo = 'text-warning';
  }

  return (
    <Module title={cliente} onClickBack={window.history.back} loading={!data}>
      <Row>
        <Col>
          <div className='panel panel-primary'>
            <div className='panel-heading'>
              <h3 className='panel-title'>Factura</h3>
            </div>
            <div className='panel-body'>
              <Row>
                <Col sm='6'>
                  <Concepto label='Serie' valor={serie} />
                  <Concepto label='Número' valor={numero} />
                  <Concepto label='Folio Fiscal' valor={uuid} />
                  <Concepto label='Tipo' valor={tipoDocumentoId.nombre} />
                  <Concepto label='Fecha' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                  {fecha_entrega !== null ? <Concepto label='Fecha de Entrega' valor={moment(fecha_entrega).local().format('DD/MM/YYYY')} /> : null}
                </Col>
                <Col sm='6 pull-right'>
                  <div className='pull-right'>
                    <Concepto label='Moneda' valor={moneda} />
                    <Concepto label='Tipo de Pago' valor={forma_pago} />

                    <div>
                      <span className='text-success'>
                        <strong>Importe</strong> <u>$ {format(importe, 2)}</u>
                      </span>
                    </div>
                    <div>
                      <span className={colorSaldo}>
                        <strong>Saldo</strong> $ {format(saldo, 2)}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <legend></legend>
                </Col>
                <Col sm='6'>
                  <Concepto label='Cliente' valor={cliente} />
                  <Concepto label='RFC' valor={customerId.rfc} />
                </Col>
                <Col sm='6'>
                  <div className='pull-right'>
                    <Concepto label='Agente' valor={agenteId ? agenteId.nombre : null} />
                  </div>
                </Col>
                <Col>
                  <legend></legend>
                </Col>
                <Col sm='6'>
                  {switchEstado(estado)}
                  {estado === CANCELADA ? (
                    <div>
                      <p>{cancelacion.nota}</p>
                      <small>
                        Fecha de cancelación <em>{moment(cancelacion.fecha).local().format('DD/MM/YYYY HH:mm')}</em>
                      </small>
                    </div>
                  ) : null}

                  {estado === PAGADA || estado === PAGADA_MANUAL ? (
                    <div>
                      {estado === PAGADA_MANUAL ? <p>{pago_manual.nota}</p> : ''}
                      <small>
                        <em>{moment(fecha_pago).local().format('DD/MM/YYYY HH:mm')}</em>
                      </small>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </div>

            {renderDetalle()}

            {renderDepositos()}
          </div>
        </Col>
      </Row>
    </Module>
  );
};

DetalleFactura.propTypes = {};

export default DetalleFactura;
