import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteRubros = ({ rubroId, rubro, loadRubros }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`rubros/${rubroId}`}
      description={`¿Confirma que desea eliminar el rubro <strong><u> ${rubro}</u> </strong>?`}
      loadData={loadRubros}
    />
  );
};

DeleteRubros.propTypes = {
  id: PropTypes.number.isRequired,
  rubro: PropTypes.string.isRequired,
  rubroId: PropTypes.string.isRequired,
  loadRubros: PropTypes.func.isRequired,
};

export default DeleteRubros;
