import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import RowTable from './RowTable';

const List = ({ data }) => {
  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Código</th>
          <th width='20%'>Nombre</th>
          <th width='20%'>Sucursal</th>
          <th width='20%'>Se usa</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={4}>
        {data.map((item, i) => (
          <RowTable key={i} {...item} />
        ))}
      </CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
};

export default List;
