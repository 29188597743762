import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ description, id, load }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`customers-clasifications/${id}`}
      description={`¿Confirma que desea eliminar la clasificacion de cliente <strong><u> ${description}</u> </strong>?`}
      loadData={load}
    />
  );
};

Delete.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Delete;
