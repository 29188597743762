import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';
import { ButtonGroup } from 'react-bootstrap';

const List = ({ data, ...props }) => {
  const renderRows = () => {
    return data.map((row, item) => {
      const { _id, nombre, clave } = row;
      return (
        <tr key={item}>
          <td>{clave}</td>
          <td>{nombre}</td>
          <td>
            <ButtonGroup>
              <IfPermission action='editar'>
                <Edit id={item} itemId={_id} clave={clave} nombre={nombre} {...row} {...props} btnClass='btn btn-sm btn-outline-info' />
              </IfPermission>
              <IfPermission action='eliminar'>
                <Delete id={item} itemId={_id} {...props} clave={clave} nombre={nombre} btnClass='btn btn-sm btn-outline-danger' />
              </IfPermission>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='30%'>Clave</th>
          <th width='30%'>Nombre</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
};

export default List;
