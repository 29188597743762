import React from 'react';
import List from './List';

const Mermas = () => {
  return <List />;
};

Mermas.propTypes = {};

export default Mermas;
