import React from 'react';
import List from './List';

const Ajustes = () => {
  return <List />;
};

Ajustes.propTypes = {};

export default Ajustes;
