import React from 'react';
import ListSurtidoCarros from './list/ListSurtidoCarros';

const SurtidoCarros = () => {
  return <ListSurtidoCarros />;
};

SurtidoCarros.propTypes = {};

export default SurtidoCarros;
