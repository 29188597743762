import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ itemId, nombre: initialNombre, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
    setNombre(initialNombre);
  };

  const onChange = (event) => {
    const { value } = event.target;
    setNombre(value);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      nombre: nombre.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `tipo-piezas/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' onChange={onChange} value={nombre} placeholder='Nombre' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  itemId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Edit;
