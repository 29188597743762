import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest } from '@utils/api';
import { textDomicilio } from '@utils/cotizaciones';
import { format } from '@utils/parseCost';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableTodosTotales from '@cbcomponents/CbTableTodosTotales';
import Concepto from './Concepto';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const QuotationByCustomer = () => {
  const params = useParams();
  const [quotation, setQuotation] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuotation = async () => {
      const { quotationId } = params;
      const { data } = await getRequest({ url: `cotizaciones/${quotationId}` });
      setQuotation(data);
      setLoading(false);
    };

    fetchQuotation();
  }, [params]);

  const renderDetalles = (listDetalles) => {
    return listDetalles.map((detalle, i) => (
      <>
        <tr key={i}>
          <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.codigo}</th>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.descripcion}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.unidadMedida.nombre}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{format(detalle.cantidad, 2)}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(detalle.precio, 2)}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(detalle.descuento, 2)}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(detalle.iva, 2)}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }} className='text-right'>
            $ {format(detalle.importe, 2)}
          </td>
        </tr>
        {detalle.tipo_partida === 'KIT' || detalle.tipo_partida === 'KIT_COMERCIAL' ? (
          <>
            <tr>
              <td style={{ padding: '0px', verticalAlign: 'middle' }} colSpan='11'>
                <table className='table table-condenced table-hover' style={{ margin: '0px' }}>
                  <tbody>
                    <tr>
                      <th width='5%'>&nbsp;</th>
                      <th width='12%'>#</th>
                      <th>Descripción</th>
                      <th>Unidad</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th width='8%'>Importe</th>
                    </tr>
                    {detalle.partes.map((parte, j) => (
                      <tr key={j}>
                        <td>&nbsp;</td>
                        <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{parte.codigo}</th>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{parte.descripcion}</td>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{parte.unidadMedida.nombre}</td>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{format(parte.cantidad, 2)}</td>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(parte.precio, 2)}</td>
                        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }} className='text-right'>
                          $ {format(parte.importe, 2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </>
        ) : null}
      </>
    ));
  };

  const renderQuotation = () => {
    const {
      numero_levantamiento,
      detalles,
      fecha,
      fecha_vencimiento,
      moneda,
      customer,
      agente,
      sucursal,
      tipo_cambio,
      estado,
      tipo_cliente,
      anticipo,
      tipo_levantamiento,
      total,
    } = quotation;

    return (
      <Module onClickBack={window.history.back} parent='Cotizaciones' title={`Cotización #${numero_levantamiento}`}>
        <div className='panel panel-primary'>
          <div className='panel-body'>
            <Row>
              <Col xs='6'>
                <Concepto label='Tipo cotización:' valor={tipo_levantamiento.nombre} />
                <Concepto label='Anticipo:' valor={anticipo} />
                <Concepto label='Sucursal:' valor={sucursal.name} />
                <Concepto label={tipo_cliente.charAt(0) + tipo_cliente.substring(1).toLowerCase() + ':'} valor={customer.razon_social} />
                <Concepto label='RFC:' valor={customer.rfc} />
                <Concepto label='Domicilio:' valor={textDomicilio(customer.domicilio)} />
              </Col>
              <Col xs='6'>
                <div className='pull-right'>
                  <Concepto label='Fecha:' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                  {fecha_vencimiento !== null ? (
                    <Concepto label='Fecha vencimiento:' valor={moment(fecha_vencimiento).local().format('DD/MM/YYYY')} />
                  ) : null}
                  <Concepto label='Agente:' valor={agente ? agente.nombre : ''} />
                  <Concepto label='Estatus:' valor={estado} />
                  <Concepto label='Moneda:' valor={moneda} />
                  <Concepto label='Tipo de cambio:' valor={`$ ${format(tipo_cambio, 2)}`} />
                  <div>
                    <span className='text-success'>
                      <strong>Total:</strong> <u>$ {format(total, 2)}</u>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <CbPanelTableResponsive>
          <thead>
            <tr>
              <th width='10%'>#</th>
              <th>Descripción</th>
              <th>Unidad</th>
              <th>Cantidad</th>
              <th>Precio</th>
              <th>Descuento</th>
              <th>IVA</th>
              <th width='10%' className='text-right'>
                Importe
              </th>
            </tr>
          </thead>
          <CbTableBody>
            {renderDetalles(detalles)}
            <CbTableTodosTotales detalles={detalles} colSpan={8} />
          </CbTableBody>
        </CbPanelTableResponsive>
        <div className='panel panel-primary'>
          <div className='panel panel-body'>
            <Row>
              <Col xs='12'>
                <Concepto label='Observaciones:' />
                {quotation.observaciones}
              </Col>
            </Row>
          </div>
        </div>
      </Module>
    );
  };

  return !loading ? renderQuotation() : <Loading />;
};

QuotationByCustomer.propTypes = {};

export default QuotationByCustomer;
