import React from 'react';
import List from './List';
import { useParams, useLocation } from 'react-router-dom';

const DetalleAsignaciones = () => {
  const { desde, hasta } = useLocation().query;
  const { agenteId } = useParams();
  return <List desde={desde} hasta={hasta} agenteId={agenteId} />;
};

DetalleAsignaciones.propTypes = {};

export default DetalleAsignaciones;
