import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Baja = ({ empleadoId, numeroEmpleado, load }) => {
  return (
    <Confirmation
      btnIcon='arrow-down'
      typeRequest='putRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`empleados/baja/${empleadoId}`}
      description={`¿Confirma que desea eliminar el empleado <strong><u> ${numeroEmpleado}</u> </strong>?`}
      loadData={load}
    />
  );
};

Baja.propTypes = {
  empleadoId: PropTypes.string.isRequired,
  numeroEmpleado: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Baja;
