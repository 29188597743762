import { DOLAR, EURO, PESO } from '@config/constants';
import { DivFormGroup } from '@controls';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import Table from './Table';
import { Button, Card, Col, Row } from 'react-bootstrap';
import SelectCliente from '@components/forms/SelectCliente';

const pagination = 25;

const List = () => {
  const [state, setState] = useState({
    total: 0,
    saldo: 0,
    clientes: 0,
    list: [],
    loading: true,
    activePage: 1,
    count: 0,
    dias: '',
    desde: moment().format('YYYY-MM-01'),
    hasta: moment().format('YYYY-MM-DD'),
    moneda: PESO,
    secciones: [],
    searching: false,
    encabezados: {
      activePageD1_30: 1,
      activePageD31_60: 1,
      activePageD61_90: 1,
      activePageD91_120: 1,
      activePageD121: 1,
    },
    agenteName: '',
    agente: null,
    agenteObj: null,
    clienteName: '',
    clienteObj: null,
    cliente: null,
  });

  const loadData = async () => {
    const { dias, desde, hasta, moneda, agente, cliente } = state;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      dias: isNaN(parseInt(dias)) ? 0 : parseInt(dias),
      desde,
      hasta,
      moneda,
      agenteId: agente ? agente._id : null,
      customerId: cliente ? cliente._id : null,
    };
    const { data, count, total, saldo, clientes, filtros } = await postRequest({ url: `asignaciones`, body });
    setState((prevState) => ({
      ...prevState,
      list: data,
      count,
      total,
      saldo,
      clientes,
      secciones: filtros,
      sources: filtros,
      loading: false,
      searching: false,
    }));
  };

  useEffect(() => {
    loadData({ page: 0, pagination });
  }, []);

  const handleFilter = () => {
    setState((prevState) => ({
      ...prevState,
      activePage: 1,
      encabezados: {
        activePageD1_30: 1,
        activePageD31_60: 1,
        activePageD61_90: 1,
        activePageD91_120: 1,
        activePageD121: 1,
      },
      searching: true,
    }));
    loadData();
  };

  const onChange = (event) => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox') {
      setState((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const renderList = () => (
    <Row>
      <Col>
        <br />
        {/*
        <button onClick={handleExcel} className="btn btn-success pull-right" type="button">
          <Icon icon="save-file" /> Excel
        </button>
        */}
        <Link to='/asignaciones/importar' className='btn btn-primary pull-right mr-5'>
          <Icon icon='plus' /> Importar
        </Link>
        <Row>
          <Table {...state} />
        </Row>
      </Col>
    </Row>
  );

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      setState(
        (prevState) => ({
          ...prevState,
          cliente: itemSelect,
        }),
        loadData
      );
    } else {
      setState((prevState) => ({ ...prevState, clienteName: '', clienteObj: null, cliente: null }), loadData);
    }
  };

  const { desde, hasta, moneda, clienteObj, loading } = state;
  return (
    <Module title='Asignaciones'>
      <form>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm='3'>
                    <DivFormGroup name='clienteName' title='Cliente'>
                      <SelectCliente value={clienteObj} onChange={onChangeCliente} name='cliente' />
                    </DivFormGroup>
                  </Col>
                  <Col sm='2'>
                    <DivFormGroup name='desde' title='Desde'>
                      <input className='form-control' name='desde' id='desde' type='date' value={desde} onChange={onChange} />
                    </DivFormGroup>
                  </Col>
                  <Col sm='2'>
                    <DivFormGroup name='hasta' title='Hasta'>
                      <input className='form-control' name='hasta' id='hasta' type='date' value={hasta} onChange={onChange} />
                    </DivFormGroup>
                  </Col>
                  <Col sm='4'>
                    <label>Moneda</label>
                    <div className='form-group inline'>
                      <label className='radio-inline'>
                        <input type='radio' name='moneda' id='rdo-peso' onChange={onChange} value={PESO} checked={moneda === PESO ? true : false} />{' '}
                        <strong>Pesos</strong>
                      </label>
                      <label className='radio-inline'>
                        <input
                          type='radio'
                          name='moneda'
                          id='rdo-dolar'
                          onChange={onChange}
                          value={DOLAR}
                          checked={moneda === DOLAR ? true : false}
                        />{' '}
                        <strong>Dólares</strong>
                      </label>
                      <label className='radio-inline'>
                        <input type='radio' name='moneda' id='rdo-euro' onChange={onChange} value={EURO} checked={moneda === EURO ? true : false} />{' '}
                        <strong>Euros</strong>
                      </label>
                    </div>
                  </Col>
                  <Col sm='1'>
                    <Button onClick={handleFilter} className='btn btn-primary pull-right' type='button'>
                      <Icon icon='search' /> Buscar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
      <Loading loading={loading}>{renderList()}</Loading>
    </Module>
  );
};

export default List;
