import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale';
import moment from 'moment';
import { defaultStaticRanges, defaultInputRanges } from 'react-date-range/src/defaultRanges';
import PropTypes from 'prop-types';

const newStaticRanges = defaultStaticRanges.map((range) => {
  switch (range.label) {
    case 'Today':
      return {
        ...range,
        label: 'Hoy',
      };
    case 'Yesterday':
      return {
        ...range,
        label: 'Ayer',
      };
    case 'This Week':
      return {
        ...range,
        label: 'Esta Semana',
      };
    case 'Last Week':
      return {
        ...range,
        label: 'Semana Pasada',
      };
    case 'This Month':
      return {
        ...range,
        label: 'Este Mes',
      };
    case 'Last Month':
      return {
        ...range,
        label: 'Mes Pasado',
      };
    default:
      return range;
  }
});
newStaticRanges.push({
  label: 'Ultimos 3 meses',
  range: () => ({
    startDate: moment().add(-3, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  }),
  isSelected: ({ startDate, endDate }) => {
    return moment().add(-3, 'months').startOf('day').isSame(startDate) && moment().endOf('day').isSame(endDate);
  },
});
newStaticRanges.push({
  label: 'Este Año',
  range: () => ({
    startDate: moment().startOf('year').toDate(),
    endDate: moment().endOf('year').toDate(),
  }),
  isSelected: ({ startDate, endDate }) => {
    return moment().startOf('year').startOf('day').isSame(startDate) && moment().endOf('year').isSame(endDate);
  },
});
const newInputRanges = defaultInputRanges.map((range) => {
  switch (range.label) {
    case 'days up to today':
      return {
        ...range,
        label: 'dias hasta hoy',
      };
    case 'days starting today':
      return {
        ...range,
        label: 'dias a partir de hoy',
      };
  }
  return range;
});

const DateRangePickerEs = ({ startDateInit, endDateInit, onChange }) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: startDateInit || moment().startOf('month').toDate(),
    endDate: endDateInit || moment().endOf('month').toDate(),
    key: 'selection',
  });

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setSelectionRange(selection);
    onChange(selection);
  };

  return (
    <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} locale={es} staticRanges={newStaticRanges} inputRanges={newInputRanges} />
  );
};

DateRangePickerEs.propTypes = {
  startDateInit: PropTypes.instanceOf(Date),
  endDateInit: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};

export default DateRangePickerEs;
