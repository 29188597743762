import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DetailCustomer from '@components/customers/DetailCustomer';

const Table = ({ list }) => {
  const renderRows = () => {
    const now = moment();
    return list.map((r, i) => {
      const { _id, serie, numero, fecha, importe, saldo, customerId, agenteId } = r;
      const then = moment(new Date(fecha));
      const dias = now.diff(then, 'days');
      let tipo = '';
      if (dias > 60) {
        tipo = 'danger';
      } else if (dias > 30) {
        tipo = 'warning';
      } else {
        tipo = '';
      }
      return (
        <tr key={i} className={tipo}>
          <td>{serie ? `${serie}-${numero}` : numero}</td>
          <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
          <td>
            <DetailCustomer customer={customerId} />
          </td>
          <td>$ {format(importe, 2)}</td>
          <td>$ {format(saldo, 2)}</td>
          <td>{dias}</td>
          <td>{agenteId ? agenteId.nombre : ''}</td>
          <td>
            <RedirectTooltip
              id={1}
              icon='search'
              url={`/facturas/detalle/${_id}`}
              labelTooltip='Completa'
              className='btn btn-sm btn-primary pull-right'
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='10%'>Número</th>
          <th width='10%'>Fecha</th>
          <th width='30%'>Cliente</th>
          <th width='10%'>Importe</th>
          <th width='10%'>Saldo</th>
          <th width='5%'>Días</th>
          <th width='20%'>Agente</th>
          <th width='5%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={8}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
};

export default Table;
