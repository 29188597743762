import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteEquipoSeguridad from './DeleteEquipoSeguridad';
import EditEquipoSeguridad from './EditEquipoSeguridad';

const ListEquipoSeguridad = ({ catalogEquipoSeguridad }) => {
  const renderRows = () => {
    return catalogEquipoSeguridad.map((row, item) => {
      const { _id, nombre, precio } = row;

      return (
        <tr key={item}>
          <td>
            <strong>{nombre}</strong>
            <br />$ {precio}
          </td>
          <td>
            <IfPermission action='eliminar'>
              <DeleteEquipoSeguridad id={item} equiposeguridadId={_id} name={nombre} btnClass='btn btn-outline-danger' />
            </IfPermission>
            <IfPermission action='editar'>
              <EditEquipoSeguridad id={item} equiposeguridadId={_id} btnClass='btn btn-outline-info' />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='80%'>Equipo de Seguridad Electrónica</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListEquipoSeguridad.propTypes = {
  catalogEquipoSeguridad: PropTypes.array.isRequired,
};

export default ListEquipoSeguridad;
