import { TIPO_SERVICIOS } from '@config/constants';
import Panel from '@controls/Panel';
import React, { useState, useEffect, Fragment } from 'react';
import { Icon /*, RowsNotFound*/, Module } from '@stateless';
import { getRequest } from '@utils/api';
import { format } from '@utils/parseCost';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetailService = () => {
  const params = useParams();
  const [service, setService] = useState(null);
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { serviceId } = params;
      const { data, measures, sucursales } = await getRequest({ url: `services/${serviceId}` });
      setService({
        ...data,
        listCost: measures.map((x) => {
          const measure = data.listCost.find((j) => j.measureId === x._id) || { sucursales: [] };
          return {
            measure: x,
            cost: measure && measure.cost ? measure.cost : 0,
            sucursales: sucursales.map((y) => {
              const sucursal = measure.sucursales.find((k) => k.sucursalId === y._id);
              return {
                sucursal: y,
                cost: sucursal && sucursal.cost ? sucursal.cost : 0,
              };
            }),
          };
        }),
      });
      setSucursales(sucursales);
    };

    fetchData();
  }, [params]);

  const renderDetalle = () => {
    const { name, description, tipo, adicional, listCost } = service;

    let labelTipo = '';

    if (tipo === TIPO_SERVICIOS.MONITOREO) {
      labelTipo = <span className='label label-success'>Monitoreo</span>;
    } else if (tipo === TIPO_SERVICIOS.INTERACTIVO) {
      labelTipo = <span className='label label-primary'>Interactivo</span>;
    } else if (tipo === TIPO_SERVICIOS.MANO_OBRA) {
      labelTipo = <span className='label label-default'>Mano de obra</span>;
    }

    return (
      <Fragment>
        <Row>
          <Col sm='12'>
            <Panel
              type='primary'
              header={
                <div>
                  <Icon icon='tag' /> Generales
                </div>
              }
            >
              <Row>
                <Col sm='6'>
                  <div>
                    <p>
                      <span className='lead'>
                        <strong>Nombre: </strong>
                      </span>
                      <span className='lead'> {name}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className='lead'>
                        <strong>Características: </strong>
                      </span>
                      <span className='lead'> {description}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className='lead'>
                        <strong>Tipo: </strong>
                      </span>
                      <span className='lead'>{labelTipo}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className='lead'>
                        <strong>Adicional: </strong>
                      </span>
                      <span className='lead'>
                        {' '}
                        <u>{adicional ? 'SI' : 'NO'} </u>
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <Panel
              type='primary'
              header={
                <div>
                  <Icon icon='tag' /> Precios
                </div>
              }
            >
              <table className='table table-condensed table-hover table-striped'>
                <thead>
                  <tr>
                    <th width='60%'>Medida</th>
                    <th width='20%' className='text-center'>
                      Precio
                    </th>
                    {sucursales.map((x, i) => (
                      <th key={i} width='20%' className='text-center'>
                        {x.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listCost.map((s, i) => {
                    return (
                      <tr key={i}>
                        <td>{s.measure.measure}</td>
                        <td> {`$ ${format(s.cost)}`}</td>
                        {s.sucursales.map((x, j) => (
                          <td key={j}> {`$ ${format(x.cost)}`}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Panel>
          </Col>
        </Row>
      </Fragment>
    );
  };

  return (
    <Module onClickBack={window.history.back} title={service && `${service.name} - ${service.code} `} loading={!service}>
      {service && renderDetalle()}
    </Module>
  );
};

DetailService.propTypes = {};

export default DetailService;
