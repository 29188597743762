import React from 'react';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@stateless';
import { IfAccess } from '@controls';

const AccesosDirectos = () => {
  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <Container>
              <Row>
                <IfAccess path='prospectos'>
                  <Col>
                    <Button as={Link} to='/prospectos' className='btn-center' variant='secondary'>
                      <Icon icon='user' />
                      <span className='text'> Prospectos</span>
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='clientes'>
                  <Col>
                    <Button as={Link} to='/clientes' className='btn-center' variant='secondary'>
                      <Icon icon='user' />
                      <span className='text'> Clientes</span>
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='clientes-registros'>
                  <Col>
                    <Button as={Link} to='/clientes-registros' className='btn-center' variant='secondary'>
                      <Icon icon='file' />
                      <span className='text'> Registro de clientes</span>
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='cotizaciones'>
                  <Col>
                    <Button as={Link} to='/cotizaciones/nuevo' className='btn-center' variant='secondary'>
                      <Icon icon='file' />
                      <span className='text'>Cotizacion</span>
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='cotizaciones'>
                  <Col>
                    <Button as={Link} to='/cotizaciones' className='btn-center' variant='secondary'>
                      <Icon icon='file' />
                      <span className='text'> Cotizaciones</span>
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='ordenes-servicio'>
                  <Col>
                    <Button as={Link} to='/ordenes-servicio' className='btn-center' variant='secondary'>
                      <Icon icon='file' />
                      <span className='text'> Ordenes de servicios</span>
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='pedidos'>
                  <Col>
                    <Button as={Link} to='/pedidos' className='btn-center' variant='secondary'>
                      <Icon icon='file' />
                      <span> Pedidos</span>
                    </Button>
                  </Col>
                </IfAccess>
              </Row>
              <Row>
                <IfAccess path='equipos'>
                  <Col>
                    <Button as={Link} to='/equipos' className='btn-center' variant='secondary'>
                      <Icon icon='barcode' /> Equipos
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='servicios'>
                  <Col>
                    <Button as={Link} to='/servicios' className='btn-center' variant='secondary'>
                      <Icon icon='cog' /> Servicios
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='kits-comerciales'>
                  <Col>
                    <Button as={Link} to='/kits-comerciales' className='btn-center' variant='secondary'>
                      <Icon icon='th-list' /> Kits comerciales
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='seguimientos'>
                  <Col>
                    <Button as={Link} to='/seguimientos' className='btn-center' variant='secondary'>
                      <Icon icon='random' /> Seguimientos
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='ruleta-asesores'>
                  <Col>
                    <Button as={Link} to='/ruleta-asesores' className='btn-center' variant='secondary'>
                      <Icon icon='file' /> Ruleta asesores
                    </Button>
                  </Col>
                </IfAccess>
              </Row>
              <Row>
                <IfAccess path='ordenes-mermas'>
                  <Col>
                    <Button as={Link} to='/ordenes-mermas' className='btn-center' variant='secondary'>
                      <Icon icon='file' /> Mermas
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='ordenes-traspasos'>
                  <Col>
                    <Button as={Link} to='/ordenes-traspasos' className='btn-center' variant='secondary'>
                      <Icon icon='file' /> Traspasos
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='ajustes'>
                  <Col>
                    <Button as={Link} to='/ajustes' className='btn-center' variant='secondary'>
                      <Icon icon='file' /> Ajustes
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='existencias'>
                  <Col>
                    <Button as={Link} to='/existencias' className='btn-center' variant='secondary'>
                      <Icon icon='file' /> Existencias
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='compras'>
                  <Col>
                    <Button as={Link} to='/compras' className='btn-center' variant='secondary'>
                      <Icon icon='file' /> Compras
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='solicitudes-compras'>
                  <Col>
                    <Button as={Link} to='/solicitudes-compras' className='btn-center' variant='secondary'>
                      <Icon icon='file' /> Solicitudes de compras
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='recepciones-compras'>
                  <Col>
                    <Button as={Link} to='/recepciones-compras' className='btn-center' variant='secondary'>
                      <Icon icon='file' /> Recepciones de compras
                    </Button>
                  </Col>
                </IfAccess>
              </Row>
              <Row>
                <IfAccess path='usuarios'>
                  <Col>
                    <Button as={Link} to='/usuarios' className='btn-center' variant='secondary'>
                      <Icon icon='user' /> Usuarios
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='modulos'>
                  <Col>
                    <Button as={Link} to='/modulos' className='btn-center' variant='secondary'>
                      <Icon icon='tasks' /> Modulos
                    </Button>
                  </Col>
                </IfAccess>
                <IfAccess path='agentes'>
                  <Col>
                    <Button as={Link} to='/agentes' className='btn-center' variant='secondary'>
                      <Icon icon='user' /> Agentes
                    </Button>
                  </Col>
                </IfAccess>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <br />
      </Col>
    </Row>
  );
};

export default AccesosDirectos;
