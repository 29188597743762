import { CANCELADA, PAGADA, PAGADA_MANUAL } from '@config/constants';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import Concepto from './Concepto';
import { useParams } from 'react-router-dom';

const Detalle = () => {
  const { conversionId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `conversion/${conversionId}` });
      setData(data);
    };
    fetchData();
  }, [conversionId]);

  const renderEntradas = () => {
    const { entradas } = data;
    const detalles = entradas.map((d, i) => {
      const {
        codigo,
        descripcion,
        unidadMedidaId: { measure },
        cantidad,
      } = d;
      return (
        <tr key={i}>
          <td>{cantidad}</td>
          <td>{codigo}</td>
          <td>{descripcion}</td>
          <td>{measure}</td>
        </tr>
      );
    });
    return (
      <div>
        <h3>Entradas</h3>
        <table className='table table-condensed'>
          <thead>
            <tr>
              <th>Cantidad</th>
              <th>Código</th>
              <th>Descripción</th>
              <th>Medida</th>
            </tr>
          </thead>
          <tbody>{entradas.length > 0 ? detalles : <RowsNotFound message='Sin entradas' colSpan={11} />}</tbody>
        </table>
      </div>
    );
  };

  const renderSalidas = () => {
    const { salidas } = data;
    const detalles = salidas.map((d, i) => {
      const {
        codigo,
        descripcion,
        unidadMedidaId: { measure },
        cantidad,
      } = d;
      return (
        <tr key={i}>
          <td>{cantidad}</td>
          <td>{codigo}</td>
          <td>{descripcion}</td>
          <td>{measure}</td>
        </tr>
      );
    });
    return (
      <div>
        <h3>Salidas</h3>
        <table className='table table-condensed'>
          <thead>
            <tr>
              <th>Cantidad</th>
              <th>Código</th>
              <th>Descripción</th>
              <th>Medida</th>
            </tr>
          </thead>
          <tbody>{salidas.length > 0 ? detalles : <RowsNotFound message='Sin salidas' colSpan={11} />}</tbody>
        </table>
      </div>
    );
  };

  const renderDetalle = () => {
    const {
      encabezado: { fecha, fecha_entrega, estado, cancelacion, fecha_pago, pago_manual, numero_conversion, sucursal, almacen, agente },
    } = data;
    return (
      <Module title={`Pedido #${numero_conversion}`} onClickBack={window.history.back} loading={!data}>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-primary'>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <Concepto label='Fecha' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                    {fecha_entrega !== null && <Concepto label='Fecha de Entrega' valor={moment(fecha_entrega).local().format('DD/MM/YYYY')} />}
                    <Concepto label='Agente' valor={agente.nombre} />
                  </div>
                  <div className='col-sm-6'>
                    <div className='pull-right'>
                      <Concepto label='Sucursal' valor={sucursal.name} />
                      <Concepto label='Almacene' valor={almacen.name} />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    {estado === CANCELADA ? (
                      <div>
                        <p>{cancelacion.nota}</p>
                        <small>
                          Fecha de cancelación <em>{moment(cancelacion.fecha).local().format('DD/MM/YYYY HH:mm')}</em>
                        </small>
                      </div>
                    ) : null}
                    {estado === PAGADA || estado === PAGADA_MANUAL ? (
                      <div>
                        {estado === PAGADA_MANUAL ? <p>{pago_manual.nota}</p> : ''}
                        <small>
                          <em>{moment(fecha_pago).local().format('DD/MM/YYYY HH:mm')}</em>
                        </small>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {renderEntradas()}
              {renderSalidas()}
            </div>
          </div>
        </div>
      </Module>
    );
  };

  return data && data.encabezado ? renderDetalle() : null;
};

Detalle.propTypes = {};

export default Detalle;
