import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbPagination from '@cbcomponents/CbPagination';
import { Icon, Loading } from '@stateless';
import { DivFormGroup } from '@controls';
import RowSeguimiento from './RowSeguimiento';
import PropTypes from 'prop-types';
import { postRequest } from '@utils/api';

const TableSearchSeguimiento = ({ tipo, onAgregar, onSelect }) => {
  const [search, setSearch] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pagination] = useState(20);
  const [clientes, setClientes] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    handleSearch();
  }, [activePage, search]);

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count } = await postRequest({ url: `contactos-clientes/search`, params: { page, limit: pagination }, body });
    setClientes(
      Object.values(
        data.reduce((acc, { customerId, ...currentValue }) => {
          if (!acc[customerId._id]) {
            acc[customerId._id] = {
              customerId: customerId,
              contactos: [],
            };
          }
          acc[customerId._id].contactos.push({
            ...currentValue,
          });
          return acc;
        }, {})
      )
    );
    setCount(count);
    setLoading(false);
  };

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onClickAgregar = () => {
    let telefonos = [];
    if (search) telefonos.push({ value: search, label: this.state.search });
    onAgregar({ telefonos });
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  const onSelectContacto = (r, c, telefonos = [], correos = []) => {
    const contacto = c ? `${c.name || ''} ${c.paterno || ''} ${c.materno || ''}` : '';
    onSelect({
      customerId: r.customerId._id,
      cliente: r.customerId,
      contacto,
      nombre: c && c.name,
      paterno: c && c.paterno,
      materno: c && c.materno,
      contactoId: c && c._id,
      telefonos: telefonos.map((x) => ({ value: x, label: x })),
      correos: correos.map((x) => ({ value: x, label: x })),
    });
  };

  return (
    <>
      <Row>
        <Col sm='12'>
          <DivFormGroup name='cliente' title={tipo}>
            <div className='input-group'>
              <span className='input-group-text hidden-sm hidden-xs'>Buscar</span>
              <input
                type='text'
                className='form-control'
                name='search'
                value={search}
                onChange={onChangeSearch}
                placeholder='Buscar por rázon social, nombre comercial, télefonos, correos eléctronicos ...'
              />
              <Button className='btn btn-success' type='button' onClick={onClickAgregar} disabled={loading}>
                <Icon icon='plus' />
              </Button>
            </div>
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='12'>
          <Loading loading={loading}>
            <CbPanelTableResponsive>
              <thead>
                <tr>
                  <th width='5%'>&nbsp;</th>
                  <th width='5%'>#</th>
                  <th>Cliente</th>
                </tr>
              </thead>
              <CbTableBody colSpan={3}>
                {clientes.map((r, i) => (
                  <RowSeguimiento key={i} r={r} cliente={search} onSelectContacto={onSelectContacto} />
                ))}
              </CbTableBody>
            </CbPanelTableResponsive>
            <div className='text-center'>
              <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
            </div>
          </Loading>
        </Col>
      </Row>
    </>
  );
};

TableSearchSeguimiento.propTypes = {
  tipo: PropTypes.string,
  onAgregar: PropTypes.func,
  onSelect: PropTypes.func,
};

export default TableSearchSeguimiento;
