import React from 'react';
import { Icon } from '@stateless';
import { format } from '@utils/parseCost';
import PropTypes from 'prop-types';
import InputCurrency from '@components/forms/InputCurrency';
import InputNumber from '@components/forms/InputNumber';
import InputPercentage from '@components/forms/InputPercentage';
import SelectUnidadMedida from '@components/forms/SelectUnidadMedida';

const RowDetalle = ({ detalle, i, onChangeDetalle, onClickRemoveDetalle }) => {
  const onSelectUnidad = (field, index, value) => {
    onChangeDetalle(field, index, { target: { value: value.id } });
  };

  const handleChangeDetalle = (field, index, event) => {
    onChangeDetalle(field, index, event);
  };

  return (
    <tr key={i}>
      <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.noIdentificador}</th>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}></td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        {detalle.tipo_partida === 'EQUIPO' ? (
          detalle.measure
        ) : (
          <SelectUnidadMedida name={`measure-${i}`} value={detalle.measureId} onChange={(value) => onSelectUnidad('measure', i, value)} />
        )}
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputNumber
          className='form-control input-sm'
          style={{ width: '80px' }}
          value={detalle.cantidad}
          onChange={(event) => handleChangeDetalle('cantidad', i, event)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{format(detalle.cantidad_recibida, 2)}</td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputCurrency
          className='form-control input-sm'
          style={{ width: '80px' }}
          value={detalle.precio}
          onChange={(event) => handleChangeDetalle('precio', i, event)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputPercentage
          className='form-control input-sm'
          style={{ width: '80px' }}
          value={detalle.descuento}
          onChange={(event) => handleChangeDetalle('descuento', i, event)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputPercentage
          className='form-control input-sm'
          value={detalle.impuesto}
          style={{ width: '80px' }}
          onChange={(event) => handleChangeDetalle('impuesto', i, event)}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(detalle.importe, 2)}</td>
      <td> &nbsp; </td>
      <td>
        <a href='#' onClick={(event) => onClickRemoveDetalle(i, event)} style={{ color: 'red' }}>
          <Icon icon='remove' />
        </a>
      </td>
    </tr>
  );
};

RowDetalle.propTypes = {
  detalle: PropTypes.object,
  i: PropTypes.number,
  onChangeDetalle: PropTypes.func,
  onSelectUnidad: PropTypes.func,
  onClickRemoveDetalle: PropTypes.func,
};

export default RowDetalle;
