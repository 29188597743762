import React from 'react';
import List from './List';

const Conversiones = () => {
  return <List />;
};

Conversiones.propTypes = {};

export default Conversiones;
