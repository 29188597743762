import { DivFormGroup, FormInput, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ id, description: initialDescription, opcion: initialOpcion, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState('');
  const [opcion, setOpcion] = useState(null);
  const [isLoading] = useState(false);
  const opciones = [
    { value: 'AUTORIZADO', label: 'Autorizado' },
    { value: 'ADVERTENCIA', label: 'Advertencia' },
    { value: 'OCUPA_AUTORIZACION', label: 'Ocupa autorizacion' },
    { value: 'SIN_AUTORIZACION', label: 'Sin autorizacion' },
  ];

  const close = () => setShowModal(false);

  const open = () => {
    setDescription(initialDescription);
    setOpcion(opciones.find((x) => x.value === initialOpcion));
    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      description: description.trim(),
      opcion: opcion ? opcion.value : null,
    };
    if (validations(data)) {
      await putRequest({ url: `customers-clasifications/${id}`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.description + '')) {
      validate.success = false;
      validate.message = 'Descripcion es requerido.';
    } else if (isEmpty(params.opcion + '')) {
      validate.success = false;
      validate.message = 'Opcion, es obligatoria.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='4'>
            <FormInput
              title='Descripcion'
              placeholder='Descripcion'
              required
              name='description'
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </Col>
          <Col sm='8'>
            <DivFormGroup>
              <Label name='opcion' title='Opcion' required />
              <Select
                value={opcion}
                name='opcion'
                placeholder='Selecciona opciones'
                options={opciones}
                onChange={(selectedOption) => setOpcion(selectedOption)}
                className='basic-multi-select'
                classNamePrefix='select'
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  opcion: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Edit;
