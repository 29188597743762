import { FormInput } from '@controls/index';
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Icon, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const EstructuraCuenta = () => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    loading: false,
    estructura_cuenta: '',
    previsualizacion_estructura_cuenta: '',
    valores_permitidos: '',
    nivel_catalogo: '',
    numericos: 'NUMERICOS',
    label_numericos: 'Numéricos',
    alfanumericos: 'ALFANUMERICOS',
    label_alfanumericos: 'Alfanuméricos',
    no_nivel: 'NO_NIVEL',
    label_no_nivel: 'Cada segmento NO es un nivel',
    si_nivel: 'SI_NIVEL',
    label_si_nivel: 'Cada segmento SI es un nivel',
    mayor_si_nivel: 'MAYOR_SI_NIVEL',
    label_mayor_si_nivel: 'A partir de la cuenta de mayor los segmentos si son niveles',
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { data } = await getRequest({ url: `configurations_estructura_cuenta` });
    setState((prevState) => ({
      ...prevState,
      ...data,
      previsualizacion_estructura_cuenta: data.estructura_cuenta ? data.estructura_cuenta : '',
      isLoading: false,
    }));
  };

  const onChange = (e) => {
    const { type, name, value, checked } = e.target;
    if (name === 'estructura_cuenta') {
      let re = /^x*(-x*)*$/i;
      if (re.test(value)) {
        let valueUpercase = value.toUpperCase();
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          previsualizacion_estructura_cuenta: valueUpercase,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleActualizar = async (e) => {
    e.preventDefault();
    const { previsualizacion_estructura_cuenta, valores_permitidos, nivel_catalogo } = state;
    const data = {
      estructura_cuenta: previsualizacion_estructura_cuenta,
      valores_permitidos,
      nivel_catalogo,
    };
    if (validations(data)) {
      await putRequest({ url: `configurations_estructura_cuenta`, body: data });
      loadData();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.estructura_cuenta + '')) {
      validate.success = false;
      validate.message = 'La Estructura de Cuenta es obligatoria.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  const {
    isLoading,
    estructura_cuenta,
    previsualizacion_estructura_cuenta,
    numericos,
    alfanumericos,
    no_nivel,
    si_nivel,
    mayor_si_nivel,
    label_numericos,
    label_alfanumericos,
    label_no_nivel,
    label_si_nivel,
    label_mayor_si_nivel,
    valores_permitidos,
    nivel_catalogo,
  } = state;

  return (
    <Module title='Estructura de las cuentas'>
      <form>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col md='6'>
                <FormInput title='Estructura de la cuenta' required name='estructura_cuenta' onChange={onChange} value={estructura_cuenta} />
                <label>Valores permitidos:*</label>
                <div className='input-group'>
                  <span className='input-group-text'>
                    <input
                      type='radio'
                      name='valores_permitidos'
                      value={numericos}
                      onChange={onChange}
                      checked={valores_permitidos === 'NUMERICOS'}
                    />
                  </span>
                  <label className='form-control'>{label_numericos}</label>
                </div>
                <div className='input-group'>
                  <span className='input-group-text'>
                    <input
                      type='radio'
                      name='valores_permitidos'
                      value={alfanumericos}
                      onChange={onChange}
                      checked={valores_permitidos === 'ALFANUMERICOS'}
                    />
                  </span>
                  <label className='form-control'>{label_alfanumericos}</label>
                </div>
              </Col>
              <Col md='6'>
                <FormInput
                  title='Previsualiación de estructura'
                  name='previsualizacion_estructura_cuenta'
                  disabled
                  value={previsualizacion_estructura_cuenta}
                />
                <label>Nivel del catálogo:*</label>
                <div className='input-group'>
                  <span className='input-group-text'>
                    <input type='radio' name='nivel_catalogo' value={no_nivel} onChange={onChange} checked={nivel_catalogo === 'NO_NIVEL'} />
                  </span>
                  <label className='form-control'>{label_no_nivel}</label>
                </div>
                <div className='input-group'>
                  <span className='input-group-text'>
                    <input type='radio' name='nivel_catalogo' value={si_nivel} onChange={onChange} checked={nivel_catalogo === 'SI_NIVEL'} />
                  </span>
                  <label className='form-control'>{label_si_nivel}</label>
                </div>
                <div className='input-group'>
                  <span className='input-group-text'>
                    <input
                      type='radio'
                      name='nivel_catalogo'
                      value={mayor_si_nivel}
                      onChange={onChange}
                      checked={nivel_catalogo === 'MAYOR_SI_NIVEL'}
                    />
                  </span>
                  <label className='form-control'>{label_mayor_si_nivel}</label>
                </div>
              </Col>
            </Row>
          </div>
          <div className='panel-footer'>
            <Row>
              <div className='col-sm-12'>
                <Saving saving={isLoading} />
                <Button onClick={handleActualizar} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </div>
            </Row>
          </div>
        </div>
      </form>
    </Module>
  );
};

EstructuraCuenta.propTypes = {};

export default EstructuraCuenta;
