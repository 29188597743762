import { DivFormGroup, FormInput, Input, Label, TextArea } from '@controls';
import Panel from '@controls/Panel';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import ImageUploading from 'react-images-uploading';
import { Icon, Loading, Module, Saving } from '@stateless';
import Swal from 'sweetalert2';
import { getRequest, postRequest, postUploadRequest, urlPhoto } from '@utils/api';
import { isEmpty } from 'validator';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Observaciones = () => {
  const { addNotification: notification } = useStoreNotification();
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    isLoadingForm: true,
    fecha_hora_inicio: moment().format('YYYY-MM-DD HH:mm'),
    fecha_hora_fin: moment().format('YYYY-MM-DD HH:mm'),
    observaciones: '',
    no_concluyo_trabajo: '',
    comentarios_adicionales_cliente: '',
    ordenServicioId: null,
    observacionId: null,
    numero_orden_servicio: '',
    orden_servicio: null,
    actualizar: false,
    evidencias: [],
    evidencias_comentarios: [],
    observacionesVisita: '',
    cliente: '',
    showModalImg: false,
    imgSrcModal: '',
    cotizacionAdicionalId: null,
    estatus: '',
  });

  const loadData = useCallback(async (visitaId) => {
    const { data } = await getRequest({ url: `ordenes-servicios-visitas/${visitaId}` });
    const observaciones = data.observacion;
    setState((prevState) => ({
      ...prevState,
      isLoadingForm: false,
      actualizar: observaciones ? true : false,
      observacionesVisita: data.observaciones,
      observacionId: observaciones?._id,
      ordenServicioId: data.ordenServicioId._id,
      cliente: data.ordenServicioId.cliente.razon_social,
      fecha_hora_inicio: observaciones ? moment(observaciones?.fecha_hora_inicio).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'),
      fecha_hora_fin: observaciones ? moment(observaciones?.fecha_hora_fin).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'),
      observaciones: observaciones?.observaciones,
      no_concluyo_trabajo: observaciones?.no_concluyo_trabajo,
      comentarios_adicionales_cliente: observaciones?.comentarios_adicionales_cliente,
      orden_servicio: data.ordenServicioId,
      numero_orden_servicio: data.ordenServicioId.numero_orden_servicio,
      cotizacionId: data.ordenServicioId.cotizacionId,
      cotizacionAdicionalId: data.ordenServicioId.cotizacionAdicionalId,
      evidencias_comentarios: data.evidencias_comentarios || [],
      estatus: data.estatus,
      evidencias: data.evidencias.map((evidencia, i) => {
        const comentarios = data.evidencias_comentarios.length >= i ? data.evidencias_comentarios[i] : '';
        return {
          actual: true,
          evidencia,
          imagePreviewUrl: urlPhoto() + evidencia,
          comentarios,
        };
      }),
    }));
  }, []);

  useEffect(() => {
    const { visitaId } = params;
    loadData(visitaId);
  }, [params, loadData]);

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones son requeridas.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChange = (event) => {
    const { name, checked, type, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleSiguiente = (event) => {
    event.preventDefault();
    const { visitaId } = params;
    if (state.no_concluyo_trabajo) {
      navigate(`/ordenes-servicios-visitas/observaciones-conclusiones/${visitaId}`);
    } else {
      navigate(`/ordenes-servicios-visitas/observaciones-comentarios/${visitaId}`);
    }
  };

  const confirmarAdicionales = async (numero_orden_servicio) => {
    const resultAdicional = await Swal.fire({
      html: `¿Desea generar una cotizaciones de adicionales para la <strong>orden de servicio: <u>#${numero_orden_servicio}</u></strong> ?`,
      icon: 'primary',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, generar',
      cancelButtonText: 'No',
    });
    return resultAdicional.isConfirmed;
  };
  const confirmarEditarAdicionales = async (numero_orden_servicio) => {
    const resultEditar = await Swal.fire({
      html: `¿Desea editar la cotizacion de adicionales para la <strong>orden de servicio: <u>#${numero_orden_servicio}</u></strong> ?`,
      icon: 'primary',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, editar',
      cancelButtonText: 'No',
    });
    return resultEditar.isConfirmed;
  };

  const confirmarConcluir = async () => {
    const resultConcluir = await Swal.fire({
      html: `<div style="font-size: 1.5em!important;">¿Se concluyó la orden de servicio? </div>`,
      icon: 'primary',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    });
    return resultConcluir.isConfirmed;
  };

  const handleConcluir = async () => {
    await handleRegister();
    const { numero_orden_servicio, ordenServicioId, cotizacionAdicionalId } = state;
    const { visitaId } = params;
    const confirmoConcluir = await confirmarConcluir();
    if (confirmoConcluir) {
      if (cotizacionAdicionalId) return navigate(`/ordenes-servicios-visitas/observaciones-comentarios/${visitaId}`);
      const confirmoAdicional = await confirmarAdicionales(numero_orden_servicio);
      if (confirmoAdicional) {
        const fecha = moment().local().format('YYYY-MM-DD');
        const { data: cotizacion } = await postRequest({ url: `cotizaciones/ordenes-servicios/${ordenServicioId}?fecha=${fecha}`, body: {} });
        navigate(`/cotizaciones/edicion/${cotizacion._id}`);
      } else {
        navigate(`/ordenes-servicios-visitas/observaciones-comentarios/${visitaId}`);
      }
    } else {
      navigate(`/ordenes-servicios-visitas/observaciones-conclusiones/${visitaId}`);
    }
  };

  const handleAdd = async () => {
    const { visitaId } = params;
    const { observaciones } = state;
    const data = {
      observaciones,
    };
    if (validations(data)) {
      await postRequest({ url: `ordenes-servicios-visitas/observaciones/${visitaId}`, body: data });
    }
  };

  const handleRegister = async () => {
    const { visitaId } = params;
    const { evidencias } = state;
    const data = {
      evidencias,
      evidencias_comentarios: evidencias.map((evidencia) => evidencia.comentarios),
    };
    const form = new FormData();
    form.append('images_data', JSON.stringify(evidencias.filter((evidencia) => evidencia.actual).map((evidencia) => evidencia.evidencia)));
    form.append('comentarios', JSON.stringify(evidencias.map((evidencia) => evidencia.comentarios)));
    for (let i = 0; i < evidencias.length; i++) {
      const evidencia = evidencias[i];
      if (!evidencia.actual) {
        form.append('images', evidencia.file);
      }
    }
    if (evidencias.length > 0 && validationsEvidencia(data)) {
      await postUploadRequest({ url: `ordenes-servicios-visitas/evidencias/${visitaId}`, form });
    }
    await handleAdd();
  };

  const validationsEvidencia = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (params.evidencias.length === 0) {
      validate.success = false;
      validate.message = 'Evidencias es requerida.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChangeEvidencias = (evidencias) => {
    setState((prevState) => ({
      ...prevState,
      evidencias: [...evidencias],
    }));
  };

  const closeModalImagen = () => {
    setState((prevState) => ({
      ...prevState,
      showModalImg: false,
      imgSrcModal: '',
    }));
  };

  const onClickAdicionales = async () => {
    const { numero_orden_servicio, ordenServicioId } = state;
    await handleRegister();
    if (state.cotizacionAdicionalId) {
      const confirmoEditar = await confirmarEditarAdicionales(numero_orden_servicio);
      if (confirmoEditar) {
        navigate(`/cotizaciones/edicion/${state.cotizacionAdicionalId._id}`);
      }
    } else {
      const confirmoAdicional = await confirmarAdicionales(numero_orden_servicio);
      if (confirmoAdicional) {
        const fecha = moment().local().format('YYYY-MM-DD');
        const { data } = await postRequest({ url: `cotizaciones/ordenes-servicios/${ordenServicioId}?fecha=${fecha}` });
        if (data) navigate(`/cotizaciones/edicion/${data._id}`);
      }
    }
  };

  const renderView = () => {
    const {
      isLoading,
      fecha_hora_inicio,
      observaciones,
      numero_orden_servicio,
      evidencias,
      showModalImg,
      imgSrcModal,
      observacionesVisita,
      cliente,
      estatus,
    } = state;
    return (
      <>
        <Panel
          type='primary'
          header={
            <div>
              <Icon icon='wrench' /> Observaciones del trabajo
            </div>
          }
        >
          <Row>
            <Col sm='6'>
              <FormInput title='Numero de orden' placeholder='Numero de orden' disabled name='numero_orden_servicio' value={numero_orden_servicio} />
            </Col>
            <Col sm='6'>
              <DivFormGroup>
                <Label name='fecha_hora_inicio' title='Fecha y hora' required />
                <Input type='datetime-local' name='fecha_hora_inicio' onChange={onChange} disabled value={fecha_hora_inicio} />
              </DivFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <FormInput title='Cliente' placeholder='Cliente' disabled name='cliente' value={cliente} />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <FormInput
                title='Descripción del trabajo'
                placeholder='Descripción del trabajo'
                disabled
                name='numero_orden_servicio'
                value={observacionesVisita}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <DivFormGroup>
                <Label name='observaciones' title='Observaciones' required />
                <TextArea id='observaciones' name='observaciones' rows={10} defaultValue={observaciones} onChange={onChange} />
              </DivFormGroup>
            </Col>
          </Row>
        </Panel>
        <Row>
          <Col sm='12'>
            <div className='panel panel-default'>
              <div className='panel-body'>
                <ImageUploading multiple onChange={onChangeEvidencias} value={evidencias} dataURLKey='imagePreviewUrl'>
                  {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                    // write your building UI
                    <div className='upload__image-wrapper row'>
                      <div {...dragProps} className={`image-item col-sm-${evidencias.length > 0 ? 4 : 12}`}>
                        <div
                          style={{
                            minHeight: '150px',
                            position: 'relative',
                            cursor: 'pointer',
                            border: isDragging ? 'dashed 1px red' : 'dashed 1px black',
                          }}
                          onClick={onImageUpload}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              fontSize: '10px',
                              transform: 'translate(-50%, -50%)',
                              textAlign: 'center',
                            }}
                          >
                            <Icon icon='plus' style={{ fontSize: '4em' }} />
                            <br />
                            {isDragging ? 'Suelte la evidencia aquí porfavor' : 'Click aquí o suelte la evidencia aquí'}
                          </div>
                        </div>
                      </div>
                      {imageList.map((image, index) => (
                        <div key={index} className='image-item col-sm-4' style={{ position: 'relative' }}>
                          <div className='image-item__btn-wrapper' style={{ position: 'absolute', top: '10px', right: '30px' }}>
                            <a
                              href='#'
                              onClick={(e) => {
                                e.preventDefault();
                                onImageRemove(index);
                              }}
                            >
                              <Icon icon='trash' style={{ fontSize: '1.5em', color: 'red' }} />
                            </a>
                          </div>
                          <img
                            src={image.imagePreviewUrl}
                            alt=''
                            style={{ maxHeight: '200px', margin: 'auto' }}
                            className='img-responsive'
                            onClick={() => {
                              setState((prevState) => ({ ...prevState, showModalImg: true, imgSrcModal: image.imagePreviewUrl }));
                            }}
                          />
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Comentario'
                            value={image.comentarios}
                            onChange={(event) => {
                              setState((prevState) => ({
                                ...prevState,
                                evidencias: prevState.evidencias.map((e, i) => (index === i ? { ...e, comentarios: event.target.value } : e)),
                              }));
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </Col>
        </Row>
        <Modal show={showModalImg} onHide={closeModalImagen} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Evidencia</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img className='img-responsive' src={imgSrcModal} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModalImagen} className='btn btn-default pull-right' type='button'>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
        <div className='panel panel-default'>
          <div className='panel-footer'>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleConcluir} className='btn btn-success pull-right mr-5' disabled={isLoading}>
                  <Icon icon='console' /> Concluir visita
                </Button>
                <Button onClick={handleRegister} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
                {estatus === 'FINALIZADO' && (
                  <Button onClick={handleSiguiente} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                    <Icon icon='arrow-right' /> Siguente (sin guardar)
                  </Button>
                )}
                <Button onClick={onClickAdicionales} className='btn btn-warning pull-right mr-5' disabled={isLoading}>
                  <Icon icon='copy' /> Adicionales
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  return (
    <Module onClickBack={window.history.back} parent='Ordenes de servicios' title='Detalles del trabajo'>
      {!state.isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Observaciones.propTypes = {};

export default Observaciones;
