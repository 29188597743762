import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Loading, Module, RowsNotFound, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { hasPermission } from '@utils/permissions';
import { isEmpty, isMongoId } from 'validator';
import BuscadorRecetas from './BuscadorRecetas';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';
import InputNumber from '@components/forms/InputNumber';
import InputCurrency from '@components/forms/InputCurrency';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import SelectMoneda from '@components/forms/SelectMoneda';
import Select from '@components/forms/Select';

const New = () => {
  const { addNotification: notification } = useStoreNotification();

  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fechaEntrega: moment().format('YYYY-MM-DD'),
    ubicaciones: [],
    monedaId: MONEDA.DOLAR,
    sucursales: [],
    almacenes: [],
    monedas: [],
    measures: [],
    providers: [],
    storehouses: [],
    proveedores: [],
    proveedorObj: null,
    proveedorName: '',
    entradas: [],
    salidas: [],
    usos_cfdi: [],
    usoCfdiId: null,
    subtotal: 0,
    total: 0,
    impuestos: 0,
    descuento: 0,
    observaciones: '',
    estatus: 'INICIADO',
    sucursalId: null,
    almacenId: null,
    sucursalDestinoId: null,
    almacenDestinoId: null,
    proveedor: null,
    productoName: '',
    productoObj: null,
    productos: [],
    tipoCambio: 0,
    estatus_conversiones: [
      { value: 'INICIADO', label: 'INICIADO' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'FINALIZADA', label: 'FINALIZADA' },
    ],
    agenteObj: null,
    agenteName: '',
    referencia: '',
  });

  const onChange = useCallback((event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const onSelect = useCallback((campo, value) => {
    setState((prevState) => ({ ...prevState, [campo]: value }));
  }, []);

  const onChangeAgenteInput = useCallback((value) => {
    setState((prevState) => ({ ...prevState, agenteName: value }));
  }, []);

  const onChangeAgente = useCallback((itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        agente: itemSelect,
        maximo_descuento: itemSelect.maximo_descuento,
        agenteId: itemSelect._id,
        agenteObj: itemSelect,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        agenteName: '',
        agenteId: null,
        agente: null,
        agenteObj: null,
      }));
    }
  }, []);

  const validations = useCallback((params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.estatus_conversion + '')) {
      validate.success = false;
      validate.message = 'Estatus es requerido.';
    } else if (isEmpty(params.fecha + '')) {
      validate.success = false;
      validate.message = 'Fecha es requerido.';
    } else if (!isMongoId(params.sucursalId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    } else if (!isMongoId(params.almacenId + '')) {
      validate.success = false;
      validate.message = 'Almácen es requerido.';
    } else if (isEmpty(params.monedaId + '')) {
      validate.success = false;
      validate.message = 'Moneda es requerido.';
    } else if (isEmpty(params.tipo_cambio + '')) {
      validate.success = false;
      validate.message = 'Tipo cambio es requerido.';
    } else if (params.entradas.length === 0) {
      validate.success = false;
      validate.message = 'No hay productos de entrada';
    } else if (params.salidas.length === 0) {
      validate.success = false;
      validate.message = 'No hay productos de salida';
    }
    params.entradas.forEach((detalle) => {
      if (detalle.cantidad > detalle.existencia) {
        validate.success = false;
        validate.message = 'No hay suficiente existencia.';
      }
    });
    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  }, []);

  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();
      const { agenteId, monedaId, tipoCambio, almacenId, referencia, numeroOrden, fecha, estatus, sucursalId, observaciones, entradas, salidas } =
        state;

      const data = {
        numero_conversion: numeroOrden,
        agente_id: agenteId,
        fecha,
        monedaId,
        almacenId,
        referencia,
        tipo_cambio: tipoCambio,
        estatus_conversion: estatus,
        sucursalId,
        observaciones,
        entradas: entradas.map((detalle) => {
          return {
            ...detalle,
          };
        }),
        salidas: salidas.map((detalle) => {
          return {
            ...detalle,
          };
        }),
      };
      if (validations(data)) {
        await postRequest({ url: `conversiones`, body: data });
        setTimeout(() => navigate(0), 1000);
      }
    },
    [state, validations, navigate]
  );

  const renderEntradas = useCallback(() => {
    if (state.entradas.length === 0) return <RowsNotFound message='No se han agregado entradas.' colSpan={12} />;
    return state.entradas.map((detalle, i) => {
      return (
        <tr key={i}>
          <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.noIdentificador}</th>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.descripcion}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.measure}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.existencia}</td>
          <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
            <InputNumber
              style={{ width: '80px' }}
              className={`form-control input-sm ${detalle.cantidad > detalle.existencia && 'is-invalid'}`}
              value={detalle.cantidad}
            />
          </td>
        </tr>
      );
    });
  }, [state.entradas]);

  const renderSalidas = useCallback(() => {
    // Implement the render logic for salidas
  }, []);

  const renderView = useCallback(() => {
    const {
      numeroOrden,
      fecha,
      estatus,
      sucursalId,
      isLoading,
      observaciones,
      estatus_conversiones,
      agenteObj,
      monedaId,
      tipoCambio,
      almacenId,
      referencia,
    } = state;
    return (
      <div>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-4'>
                <FormInput
                  title='Número de orden'
                  placeholder='Número de orden'
                  disabled
                  name='numeroOrden'
                  onChange={onChange}
                  value={numeroOrden}
                />
              </div>
              <div className='col-sm-4'>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </div>
              <div className='col-sm-4'>
                <DivFormGroup name='estatus' required title='Estatus'>
                  <Select name='estatus' value={estatus} disabled options={estatus_conversiones} onChange={onSelect.bind(this, 'estatus')} />
                </DivFormGroup>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-3'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursalId} onChange={onSelect.bind(this, 'sucursalId')} />
                </DivFormGroup>
              </div>
              <div className='col-sm-3'>
                <DivFormGroup name='almacenId' required title='Almacen'>
                  <SelectAlmacen value={almacenId} onChange={onSelect.bind(this, 'almacenId')} />
                </DivFormGroup>
              </div>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup name='monedaId' required title='Moneda'>
                  <SelectMoneda value={monedaId} onChange={onSelect.bind(this, 'monedaId')} disabled />
                </DivFormGroup>
              </div>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup name='tipoCambio' title='Tipo de cambio'>
                  <InputCurrency
                    id='tipoCambio'
                    name='tipoCambio'
                    disabled={!hasPermission('cambiar-tipo-cambio')}
                    value={tipoCambio}
                    onChange={onChange}
                  />
                </DivFormGroup>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4 col-xs-12'>
                <DivFormGroup name='agenteName' required title='Agente'>
                  <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                </DivFormGroup>
              </div>
              <div className='col-sm-8 col-xs-12'>
                <FormInput title='Referencia' name='referencia' onChange={onChange} value={referencia} />
              </div>
            </div>
          </div>
          <div className='panel-footer'>
            <div className='row'>
              <div className='col-sm-12'>
                <BuscadorRecetas
                  {...state}
                  setState={(obj, callback = () => {}) => setState((prevState) => ({ ...prevState, ...obj }), callback)}
                  actualizarTotalesDetalles={actualizarTotalesDetalles}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='panel panel-default panel-table' style={{ overflow: 'scroll' }}>
          <div className='panel-heading'>
            <h3 className='panel-title'>Equipos entrantes</h3>
          </div>
          <div style={{ overflow: 'scroll' }}>
            <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
              <thead>
                <tr>
                  <th width='10%'>#</th>
                  <th>Descripción</th>
                  <th width='10%'>Unidad</th>
                  <th>Existencia</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>{renderEntradas()}</tbody>
            </table>
          </div>
          <div className='panel-heading'>
            <h3 className='panel-title'>Equipos salientes</h3>
          </div>
          <div style={{ overflow: 'scroll' }}>
            <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
              <thead>
                <tr>
                  <th width='10%'>#</th>
                  <th>Descripción</th>
                  <th width='10%'>Unidad</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>{renderSalidas()}</tbody>
            </table>
          </div>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </div>
            </div>
          </div>
          <div className='panel-footer'>
            <div className='row'>
              <div className='col-sm-12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [state, onChange, onSelect, onChangeAgenteInput, onChangeAgente, renderEntradas, renderSalidas, handleRegister]);

  const actualizarTotalesDetalles = useCallback(() => {
    // Implement the logic for actualizarTotalesDetalles
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const fecha = moment().format('YYYY-MM-DD');
      const { data } = await getRequest({ url: `tipo-cambio/fecha`, params: { fecha } });
      if (!data?._id) {
        return notification({
          title: 'Advertencia',
          message: 'Se debe capturar el tipo de cambio del dia. Catálogos -> Tipos de cambio',
          type: 'warning',
        });
      }
      setState((prevState) => ({ ...prevState, tipoCambio: data.valor || 0 }));
      inicializar();
    };

    const inicializar = async () => {
      const {
        data: { sucursales, almacenes, ubicaciones, agente, monedas },
      } = await getRequest({ url: `pedidos-catalogos` });
      const {
        data: { numero_conversion },
      } = await getRequest({ url: `conversiones/siguienteNumero` });
      setState((prevState) => ({
        ...prevState,
        sucursales: sucursales.map((sucursal) => {
          return {
            value: sucursal._id,
            label: `${sucursal.name}`,
          };
        }),
        almacenes: almacenes.map((almacen) => {
          return {
            value: almacen._id,
            label: `${almacen.name}`,
          };
        }),
        monedas: monedas.map((moneda) => ({
          value: moneda.enumerador,
          label: `${moneda.descripcion}`,
        })),
        agenteObj: { value: agente._id, label: agente.nombre },
        agenteId: agente._id,
        sucursalId: agente.sucursalId,
        almacenId: agente.almacenId,
        agenteName: agente.nombre,
        ubicaciones,
        isLoadingForm: false,
        numeroOrden: numero_conversion,
      }));
    };

    fetchData();
  }, []);

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ajustes de inventario' title='Nueva conversión'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

New.propTypes = {};

export default New;
