import { MONEDA } from '@config/constants';
import currency from 'currency.js';
import { cleanNumber } from '@utils/formatter';
import Joi from 'joi';
import { MESSAGES } from './validations';

export const UNIDAD_DE_SERVICIO = 'UNIDAD DE SERVICIO';
export const HORA = 'HORA';
export const SERVICIO = 'SERVICIO';
export const EQUIPO = 'EQUIPO';
export const KIT = 'KIT';
export const KIT_COMERCIAL = 'KIT_COMERCIAL';

const cn = (x) => parseFloat(Number(cleanNumber(x)).toFixed(2));

export const textDomicilio = (domicilio = {}) => {
  const { calle, exterior, interior, colonia, localidad, codigo_postal, estado, monitoreo } = domicilio;
  if (!codigo_postal && !estado) return '';
  if (monitoreo) {
    return `${calle} #${exterior}-${interior},${codigo_postal} ${colonia} ${localidad}, ${estado} N.M.: ${monitoreo || ''}`.toUpperCase();
  }
  return `${calle} #${exterior}${interior ? '-' + interior : ''}, ${codigo_postal || ''} ${colonia || ''} ${localidad || ''}, ${estado || ''}`.toUpperCase();
};

export const textContacto = (contacto) =>
  `${contacto.name} ${contacto.paterno} ${contacto.materno} (${contacto.phone}) (${contacto.email})`.toUpperCase();

export const cleanMultiplicar = (n1, n2) => {
  return cn(cn(n1) * cn(n2));
};

export const actualizarTotales = (detalles) => {
  let total = 0,
    descuento = 0,
    impuestos = 0,
    subtotal = 0;
  detalles = detalles.map((detalle) => {
    if (detalle.tipo_partida === 'KIT' || detalle.tipo_partida === 'KIT_COMERCIAL') {
      detalle.precio = 0;
      detalle.importe = 0;
      detalle.subtotal = 0;
      detalle.impuesto_total = 0;
      detalle.descuento_total = 0;
      for (let i = 0; i < detalle.partes.length; i++) {
        const parte = detalle.partes[i];
        parte.importe = cleanMultiplicar(parte.precio, parte.cantidad);
        detalle.precio += parte.importe;
      }
      detalle.importe = cleanMultiplicar(detalle.precio, detalle.cantidad);
    } else {
      detalle.importe = cleanMultiplicar(detalle.precio, detalle.cantidad);
    }
    if (detalle.descuento !== 0) {
      detalle.descuento_total = cleanMultiplicar(detalle.importe, detalle.descuento / 100);
    } else {
      detalle.descuento_total = 0;
    }
    if (detalle.iva !== 0) {
      detalle.iva_total = cleanMultiplicar(detalle.importe - detalle.descuento_total, detalle.iva / 100);
    } else {
      detalle.iva_total = 0;
    }

    total += cn(detalle.importe) + cn(detalle.iva_total) - cn(detalle.descuento_total);
    subtotal += cn(detalle.importe);
    descuento += cn(detalle.descuento_total);
    impuestos += cn(detalle.iva_total);
    return { ...detalle };
  });

  return { total, subtotal, descuento, impuestos, detalles };
};

export const castEquipo = (equipo) => {
  const measureId = equipo.equipmentsMeasureId;
  let precio_original = equipo.precio;
  let moneda_original = equipo.moneda;
  let precio = equipo.precio;
  let moneda = equipo.moneda;
  return {
    relacionadoId: equipo._id,
    descripcion: equipo.name.toUpperCase(),
    noIdentificador: equipo.code.toUpperCase(),
    precio_original: precio_original,
    moneda_original,
    moneda,
    measureId,
    precio,
    cantidad: 1,
    descuento: 0,
    iva: equipo.iva || 16,
    importe: precio,
    tiene_sustitutos: equipo.listAlternos?.length > 0,
    tiene_otro_proveedor: equipo.listCostosProveedores?.length > 1,
    listWorkforce: equipo.listWorkforce || [],
    status: equipo.status,
    imagen: equipo.imagen,
  };
};

export const castServicio = (servicio, monedaId, tipoCambio, measuresList, sucursalId) => {
  let precio = servicio.last_cost;
  let precio_original = precio;
  let measure = null,
    measureId = null;
  let measures = measuresList.filter((x) =>
    servicio.listCost && servicio.listCost.filter((x) => x.cost > 0).length > 0
      ? servicio.listCost
          .filter((x) => x.cost > 0)
          .map((x) => x.measureId)
          .includes(x.value)
      : measuresList.filter((x) => x.tipo === 'SERVICIO')
  );
  let moneda_original = servicio.moneda;
  let moneda = servicio.moneda;
  if (measures.length === 1) {
    measure = measures[0];
    if (servicio.listCost && servicio.listCost.length > 0) {
      const measure1 = servicio.listCost.find((j) => j.measureId === measure._id) || { sucursales: [], cost: 0 };
      precio = measure1.cost;
      precio_original = measure1.cost;
    }
    if (measure.sucursales && sucursalId) {
      const costoS = measure.sucursales.find((s) => s.sucursalId === getSucursal(sucursalId));
      if (costoS && costoS > 0) {
        precio = costoS;
        precio_original = costoS;
      }
    }
    if (measure._id) {
      measureId = measure._id;
    }
  }

  return {
    relacionadoId: servicio._id,
    descripcion: servicio.name.toUpperCase(),
    noIdentificador: servicio.code.toUpperCase(),
    precio_original: precio_original,
    measure: measure ? measure.label.toUpperCase() : null,
    moneda,
    moneda_original,
    measures,
    measureId,
    precio,
    cantidad: 1,
    descuento: 0,
    iva: servicio.iva || 16,
    importe: precio,
    tiene_sustitutos: false,
    tiene_otro_proveedor: false,
    status: servicio.status,
    listCost: servicio.listCost,
  };
};

export const getImporteDetalle = (detalle, precision = 4) => currency(detalle.precio, { precision }).multiply(detalle.cantidad).value;
export const getDescuentoDetalle = (detalle, precision = 4) =>
  currency(getImporteDetalle(detalle, precision), { precision }).multiply(currency(detalle.descuento, { precision }).divide(100)).value;
export const getSubtotalDetalle = (detalle, precision = 4) =>
  currency(getImporteDetalle(detalle, precision), { precision }).subtract(getDescuentoDetalle(detalle, precision)).value;
export const getIVADetalle = (detalle, precision = 4) =>
  currency(getSubtotalDetalle(detalle, precision), { precision }).multiply(currency(detalle.iva, { precision }).divide(100)).value;
export const getTotalDetalle = (detalle, precision = 4) =>
  currency(getSubtotalDetalle(detalle, precision), { precision }).add(getIVADetalle(detalle, precision)).value;

export const getSumatoria = (detalles, getFunc, precision = 4) =>
  detalles.map((detalle) => getFunc(detalle, precision)).reduce((a, b) => currency(a, { precision }).add(b), 0).value;

export const getImporte = (detalles, precision = 4) => getSumatoria(detalles, getImporteDetalle, precision);
export const getDescuento = (detalles, precision = 4) => getSumatoria(detalles, getDescuentoDetalle, precision);
export const getSubtotal = (detalles, precision = 4) => getSumatoria(detalles, getSubtotalDetalle, precision);
export const getIVA = (detalles, precision = 4) => getSumatoria(detalles, getIVADetalle, precision);
export const getTotal = (detalles, precision = 4) => getSumatoria(detalles, getTotalDetalle, precision);

export const measuresServicios = (measures) => measures.filter((x) => x.tipo === 'SERVICIO');

export const calculatePrecioServicio = (servicio, measure, sucursalId) => {
  let precio = 0,
    precio_original = 0,
    moneda = '';
  const measure1 = servicio?.listCost?.find((j) => j.measureId === measure._id) || { sucursales: [], cost: 0 };
  precio = measure1.cost;
  precio_original = measure1.cost;
  moneda = servicio.moneda_original;
  if (measure1.sucursales && sucursalId) {
    const costoS = measure1.sucursales.find((s) => s.sucursalId === getSucursal(sucursalId));
    if (costoS && costoS.cost && costoS.cost > 0) {
      precio = costoS.cost;
      precio_original = costoS.cost;
    }
  }
  return {
    precio,
    precio_original,
    moneda,
  };
};

export const getMoneda = (monedaId) => monedaId?.enumerador;
export const getSucursal = (sucursalId) => sucursalId?._id;
export const getTipoCotizacion = (tipoLevantamientoId) => tipoLevantamientoId?._id;
export const getTipoRecibo = (tipoRecibo) => tipoRecibo?._id;

export const calcularPrecioYMoneda = (detalle, monedaId, tipoCambio) => {
  let precio_original = detalle.precio;
  let moneda_original = detalle.moneda_original;
  let precio = detalle.precio;
  let moneda = detalle.moneda;
  if (getMoneda(monedaId) !== moneda) {
    if (getMoneda(monedaId) === MONEDA.PESO && moneda === MONEDA.DOLAR) {
      precio = currency(precio_original, { precision: 4 }).multiply(tipoCambio).value;
    } else if (getMoneda(monedaId) === MONEDA.DOLAR && moneda === MONEDA.PESO) {
      precio = currency(precio_original, { precision: 4 }).divide(tipoCambio).value;
    }
    moneda = getMoneda(monedaId);
  }
  return {
    precio,
    moneda,
    precio_original,
    moneda_original,
  };
};

export const getParteEquipo = (parte) => {
  const { equipoId } = parte;
  return {
    cantidad: parte.cantidad,
    noIdentificador: equipoId.code,
    descripcion: equipoId.name,
    measureId: equipoId.equipmentsMeasureId,
    equipoId: equipoId,
    imagen: equipoId.imagen,
    listAlternos: equipoId.listAlternos,
    listCostosProveedores: equipoId.listCostosProveedores,
    listFichas: equipoId.listFichas,
    listLimitesExistencias: equipoId.listLimitesExistencias,
    listPrecios: equipoId.listPrecios,
    listProveedores: equipoId.listProveedores,
    listWorkforce: equipoId.listWorkforce,
    marcaId: equipoId.marcaId,
    moneda: equipoId.moneda,
    moneda_original: equipoId.moneda,
    precio: equipoId.precio,
    relacionadoId: parte.relacionadoId,
    status: equipoId.status,
    tipo_partida: parte.tipo_partida,
  };
};

export const getParteServicio = (parte, sucursalId) => {
  const { servicioId } = parte;
  const calculate = calculatePrecioServicio(parte, parte.measureId, sucursalId);
  return {
    cantidad: parte.cantidad,
    noIdentificador: servicioId.code,
    descripcion: parte.name,
    listCost: servicioId.listCost,
    precio: calculate.precio,
    precio_original: calculate.precio_original,
    moneda: calculate.moneda,
    moneda_original: servicioId.moneda,
    //  listasPreciosUnidades: parte.listasPreciosUnidades,
    measureId: parte.measureId,
    precio_ajustable: servicioId.precio_ajustable,
    relacionadoId: parte.relacionadoId,
    servicioId: servicioId,
    status: servicioId.status,
    tipo_partida: parte.tipo_partida,
  };
};

export const esEquipo = (detalle) => detalle.tipo_partida?.value === 'EQUIPO';
export const esServicio = (detalle) => detalle.tipo_partida?.value === 'SERVICIO';
export const esKit = (detalle) => detalle.tipo_partida?.value === 'KIT' || detalle.tipo_partida?.value === 'KIT_COMERCIAL';
export const esCliente = (tipo_cliente) => tipo_cliente?.value === 'CLIENTE';

export const TIPOS_PARTIDAS = [
  { value: 'EQUIPO', label: 'EQUIPO' },
  { value: 'SERVICIO', label: 'SERVICIO' },
  // {value: 'KIT', label: 'KIT'},
  { value: 'KIT_COMERCIAL', label: 'KIT COMERCIAL' },
];

export const ENUM_TIPOS_PARTIDAS = {
  EQUIPO: { value: 'EQUIPO', label: 'EQUIPO' },
  SERVICIO: { value: 'SERVICIO', label: 'SERVICIO' },
  // {value: 'KIT', label: 'KIT'},
  KIT_COMERCIAL: { value: 'KIT_COMERCIAL', label: 'KIT COMERCIAL' },
};

export const TIPOS_CLIENTES = [
  { value: 'PROSPECTO', label: 'PROSPECTO' },
  { value: 'CLIENTE', label: 'CLIENTE' },
];

export const ENUM_TIPOS_CLIENTES = {
  PROSPECTO: { value: 'PROSPECTO', label: 'PROSPECTO' },
  CLIENTE: { value: 'CLIENTE', label: 'CLIENTE' },
};
export const ESTATUS_COTIZACIONES = [
  { value: 'EN_PROCESO', label: 'EN PROCESO' },
  { value: 'PENDIENTE', label: 'PENDIENTE' },
  { value: 'INICIADA', label: 'INICIADA' },
  { value: 'ENVIADA', label: 'ENVIADA' },
  { value: 'ACEPTADA', label: 'ACEPTADA' },
  { value: 'DECLINADA', label: 'DECLINADA' },
  { value: 'AUTORIZADA', label: 'AUTORIZADA' },
  { value: 'FINALIZADA', label: 'FINALIZADA' },
  { value: 'CANCELADA', label: 'CANCELADA' },
  { value: 'PAUSADA', label: 'PAUSADA' },
  { value: 'REMISIONADA', label: 'REMISIONADA' },
  { value: 'VENCIDA', label: 'VENCIDA' },
];

export const validations = (params) => {
  const schema = Joi.object({
    tipoLevantamientoId: Joi.string().required().label('Tipo de cotización'),
    numero_levantamiento: Joi.number().required().label('Número de cotización'),
    fecha: Joi.date().required().max('now').label('Fecha'),
    fecha_vencimiento: Joi.date().required().min(Joi.ref('fecha')).label('Fecha de vencimiento'),
    estatus_levantamiento: Joi.string().required().label('Estatus'),
    monedaId: Joi.string().required().label('Moneda'),
    tipoCambio: Joi.number().required().label('Tipo de cambio'),
    anticipo: Joi.number().min(0).max(100).required().label('Anticipo'),
    agenteId: Joi.string().required().label('Agente'),
    sucursalId: Joi.string().required().label('Sucursal'),
    clienteId: Joi.string().required().label('Cliente'),
    tipo_cliente: Joi.string().valid('CLIENTE', 'PROSPECTO').required().label('Tipo de cliente'),
    observaciones: Joi.string().allow('').label('Observaciones'),
    domicilioId: Joi.when('tipo_cliente', {
      is: 'CLIENTE',
      then: Joi.string().required().label('Domicilio'),
      otherwise: Joi.any().strip(),
    }),
    detalles: Joi.array()
      .items(
        Joi.object({
          _id: Joi.string().label('Detalle'),
          relacionadoId: Joi.string().required().label('Equipo'),
          tipo_partida: Joi.string().required().label('Tipo partida'),
          descripcion: Joi.string().required().label('Descripción'),
          measureId: Joi.string().required().label('Unidad'),
          precio: Joi.number().label('Precio'),
          cantidad: Joi.number().greater(0).required().label('Cantidad'),
          descuento: Joi.number().min(0).max(100).required().label('Descuento'),
          proveedorId: Joi.string().label('Proveedor'),
          iva: Joi.number().min(0).max(100).required().label('IVA'),
          listWorkForcesSetted: Joi.array().items(
            Joi.object({
              _id: Joi.string().required().label('Workforce'),
              level: Joi.number().required().label('Level'),
              cantidad: Joi.number().required().label('Cantidad'),
              value: Joi.number().required().label('Value'),
              type: Joi.string().required().label('Type'),
            })
          ),
          partes: Joi.array()
            .items(
              Joi.object({
                _id: Joi.string().label('Detalle'),
                relacionadoId: Joi.string().required().label('Equipo'),
                tipo_partida: Joi.string().required().label('Tipo partida'),
                descripcion: Joi.string().required().label('Descripción'),
                measureId: Joi.string().required().label('Unidad'),
                precio: Joi.number().label('Precio'),
                cantidad: Joi.number().greater(0).required().label('Cantidad'),
                iva: Joi.number().min(0).max(100).required().label('IVA'),
                proveedorId: Joi.string().label('Proveedor'),
                listWorkForcesSetted: Joi.array().items(
                  Joi.object({
                    _id: Joi.string().required().label('Workforce'),
                    level: Joi.number().required().label('Level'),
                    value: Joi.number().required().label('Value'),
                    cantidad: Joi.number().required().label('Cantidad'),
                    type: Joi.string().required().label('Type'),
                  })
                ),
              })
            )
            .when('tipo_partida', { is: 'KIT', then: Joi.required() })
            .label('Partes'),
        })
      )
      .min(1)
      .required()
      .label('Detalles'),
  }).messages(MESSAGES);
  const { error } = schema.validate(params, { abortEarly: false });
  if (error) {
    const message = error.details.map((detail) => detail.message)[0];
    return {
      success: false,
      message,
      error,
    };
  }
  return { success: true };
};
export const mapDataDetallesRecibo = (detalles) => {
  return detalles.map((detalle) => ({
    _id: detalle._id,
    tipo_partida: detalle.tipo_partida?.value,
    relacionadoId: detalle.relacionadoId,
    descripcion: detalle.descripcion,
    measureId: detalle.measureId?._id,
    cantidad: detalle.cantidad,
    precio: detalle.precio,
    descuento: detalle.descuento,
    iva: detalle.iva,
  }));
};
export const validationsConversion = (params) => {
  const schema = Joi.object({
    fecha_solicitud: Joi.date().required().max('now').label('Fecha de solicitud'),
    fecha_entrega: Joi.date().required().max('now').label('Fecha de solicitud'),
    sucursalId: Joi.string().required().label('Sucursal'),
    numero_pedido: Joi.number()
      .optional()
      .when('tienePedido', {
        is: true,
        then: Joi.number().required(),
      })
      .label('Numero pedido'),
    tienePedido: Joi.boolean(),
    detalles_equipos: Joi.array().items(Joi.object()).label('Detalles de equipos'),
    agenteId: Joi.string().required().label('Agente'),
    almacenId: Joi.string()
      .optional()
      .when('tienePedido', {
        is: true,
        then: Joi.string().required(),
      })
      .label('Almacen'),
    tipoPedidoId: Joi.string()
      .optional()
      .when('detalles_equipos', {
        is: Joi.array().min(1),
        then: Joi.string().required(),
      }),
    genera_orden_servicio: Joi.boolean(),
    detalles_servicios: Joi.array().items(Joi.object()).min(0).label('Detalles de servicio'),
    tipoOrdenServicioId: Joi.string().when('genera_orden_servicio', {
      is: true,
      then: Joi.string().required(),
    }),
    numero_orden_servicio: Joi.number()
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.number().required(),
      })
      .label('Numero orden servicio'),
    clienteRegistroId: Joi.string()
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.string().required(),
      })
      .label('Registro de Cliente'),
    soluciones_solicitadas_id: Joi.array()
      .items(Joi.string())
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.array().min(1).required(),
      })
      .label('Soluciones solicitadas'),
    tipos_servicios_solicitados_id: Joi.array()
      .items(Joi.string())
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.array().min(1).required(),
      })
      .label('Tipo de soluciones solicitadas'),
    tipos_mano_obras_id: Joi.string()
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.string().required(),
      })
      .label('Tipo de mano de obra'),
    tipos_cobro_equipo_id: Joi.string()
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.string().required(),
      })
      .label('Tipo de cobro de equipo'),
    descripcion: Joi.string().allow('').required().label('Descripción'),
    horas: Joi.number().required().label('Horas'),
    observaciones: Joi.string().allow('').label('Observaciones'),
    observacion: Joi.string().allow('').label('Observaciones'),
    referencia: Joi.string().allow('').label('Referencia'),
  }).messages(MESSAGES);
  const { error } = schema.validate(params, { abortEarly: false });
  if (error) {
    const message = error.details.map((detail) => detail.message)[0];
    return {
      success: false,
      message,
      error,
    };
  }
  return { success: true };
};

export const validationsRecibo = (params) => {
  const detalleSchema = Joi.object({
    _id: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .label('Detalle'),
    tipo_partida: Joi.string().label('Tipo partida'),
    relacionadoId: Joi.string().required().label('Equipo'),
    descripcion: Joi.string().required().label('Descripción'),
    measureId: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .label('Unidad'),
    cantidad: Joi.number().positive().required().label('Cantidad'),
    precio: Joi.number().required().label('Precio'),
    descuento: Joi.number().min(0).max(100).required().label('Descuento'),
    iva: Joi.number().min(0).max(100).required().label('IVA'),
    // listWorkForcesSetted: Joi.array().items(
    //   Joi.object({
    //     _id: Joi.string().required().label('Workforce'),
    //     level: Joi.number().required().label('Level'),
    //     value: Joi.number().required().label('Value'),
    //     type: Joi.string().required().label('Type'),
    //   })
    // ),
    // partes:Joi.array().label('Partes')
  });

  const schema = Joi.object({
    tipoReciboId: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .label('Tipo de recibo'),
    numero_recibo: Joi.number().required().label('Número de recibo'),
    fecha: Joi.date().max('now').required().label('Fecha de elaboración'),
    agenteId: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .label('Agente'),
    estatus_recibo: Joi.string().label('Estatus'),
    sucursalId: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .label('Sucursal'),
    monedaId: Joi.string().required().label('Moneda'),
    clienteId: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .label('Cliente'),
    metodoPagoId: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .label('Metodo Pago'),
    formaPagoId: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .label('Forma Pago'),
    usoCfdiId: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .label('Uso CFDI'),
    tipoCambio: Joi.number().required().label('Tipo de cambio'),
    domicilioId: Joi.string().required().label('Dirección'),
    observaciones: Joi.string().label('Observacion'),
    detalles: Joi.array().items(detalleSchema).min(1).required().label('Partidas'),
  });
  const { error } = schema.validate(params, { abortEarly: false });
  if (error) {
    const message = error.details.map((detail) => detail.message)[0];
    return {
      success: false,
      message,
      error,
    };
  }
  return { success: true };
};

export const validationsOrdenRapida = (params) => {
  const schema = Joi.object({
    fecha_solicitud: Joi.date().required().max('now').label('Fecha de solicitud'),
    fecha_entrega: Joi.date().required().max('now').label('Fecha de solicitud'),
    sucursalId: Joi.string().required().label('Sucursal'),
    agenteId: Joi.string().required().label('Agente'),
    tipoOrdenServicioId: Joi.string().when('genera_orden_servicio', {
      is: true,
      then: Joi.string().required(),
    }),
    numero_orden_servicio: Joi.number()
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.number().required(),
      })
      .label('Numero orden servicio'),
    clienteRegistroId: Joi.string()
      .when('tienePedido', {
        is: false,
        then: Joi.string().required(),
      })
      .label('Registro de Cliente'),
    soluciones_solicitadas_id: Joi.array()
      .items(Joi.string())
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.array().min(1).required(),
      })
      .label('Soluciones solicitadas'),
    tipos_servicios_solicitados_id: Joi.array()
      .items(Joi.string())
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.array().min(1).required(),
      })
      .label('Tipo de soluciones solicitadas'),
    tipos_mano_obras_id: Joi.string()
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.string().required(),
      })
      .label('Tipo de mano de obra'),
    tipos_cobro_equipo_id: Joi.string()
      .when('genera_orden_servicio', {
        is: true,
        then: Joi.string().required(),
      })
      .label('Tipo de cobro de equipo'),
    descripcion: Joi.string().allow('').required().label('Descripción'),
    horas: Joi.number().min(1).required().label('Horas'),
    observaciones: Joi.string().allow('').label('Observaciones'),
    observacion: Joi.string().allow('').label('Observaciones'),
    referencia: Joi.string().allow('').label('Referencia'),
  }).messages(MESSAGES);
  const { error } = schema.validate(params, { abortEarly: false });
  if (error) {
    const message = error.details.map((detail) => detail.message)[0];
    return {
      success: false,
      message,
      error,
    };
  }
  return { success: true };
};

export const getHorasCotizacion = (detalles) => {
  let horas_cotizadas = 0,
    horas_sugeridas = 0;
  for (let i = 0; i < detalles.length; i++) {
    const detalle = detalles[i];
    switch (detalle.tipo_partida?.value) {
      case 'EQUIPO':
        if (detalle.listWorkforce) {
          const workforce = detalle.listWorkforce.find((x) => x.level === 1);
          if (workforce) {
            horas_sugeridas += workforce.value;
          }
        }
        break;
      case 'SERVICIO':
        if (['INSTALACION-17', 'INSTALACIONPAQ-17', 'PINSTALACION-17', 'REPARACION-17'].includes(detalle.noIdentificador))
          horas_cotizadas += detalle.cantidad;
        break;
      case 'KIT':
      case 'KIT_COMERCIAL':
        for (let j = 0; j < detalle.partes.length; j++) {
          const parte = detalle.partes[j];
          switch (parte.tipo_partida?.value) {
            case 'EQUIPO':
              if (parte.listWorkforce) {
                const workforce = parte.listWorkforce.find((x) => x.level == 1);
                if (workforce) {
                  horas_sugeridas += workforce.value;
                }
              }
              break;
            case 'SERVICIO':
              if (['INSTALACION-17', 'INSTALACIONPAQ-17', 'PINSTALACION-17', 'REPARACION-17'].includes(parte.noIdentificador))
                horas_cotizadas += parte.cantidad;
              break;
          }
        }
        break;
    }
  }
  horas_sugeridas = Math.ceil(horas_sugeridas);
  return { horas_cotizadas, horas_sugeridas };
};

export const mapListWorkForces = (listWorkForces) => {
  if (!listWorkForces) return [];
  return listWorkForces.map((wf) => ({
    _id: wf._id,
    level: wf.level,
    value: wf.value,
    cantidad: wf.value,
    type: wf.type,
  }));
};

export const mapDataDetalles = (detalles) => {
  return detalles.map((detalle) => ({
    _id: detalle._id,
    tipo_partida: detalle.tipo_partida?.value,
    relacionadoId: detalle.relacionadoId,
    descripcion: detalle.descripcion,
    measureId: detalle.measureId?._id,
    cantidad: detalle.cantidad,
    precio: detalle.precio,
    descuento: detalle.descuento,
    iva: detalle.iva,
    listWorkForcesSetted: mapListWorkForces(detalle.listWorkForcesSetted),
    partes: detalle.partes?.map((parte) => ({
      _id: parte._id,
      tipo_partida: parte.tipo_partida?.value,
      relacionadoId: parte.relacionadoId,
      descripcion: parte.descripcion,
      measureId: parte.measureId?._id,
      cantidad: parte.cantidad,
      precio: parte.precio,
      iva: parte.iva,
      listWorkForcesSetted: mapListWorkForces(parte.listWorkForcesSetted),
    })),
  }));
};

export const recalcularPrecioDetalle = (detalle, monedaId, tipoCambio) => {
  if (esEquipo(detalle) || esServicio(detalle)) {
    if (!detalle.modifico_precio) {
      detalle.moneda = detalle.moneda_original;
      detalle.precio = detalle.precio_original;
    } else {
      if (detalle.moneda !== getMoneda(monedaId)) {
        if (getMoneda(monedaId) === MONEDA.PESO && detalle.moneda === MONEDA.DOLAR) {
          detalle.precio = currency(detalle.precio_original, { precision: 4 }).multiply(tipoCambio).value;
        } else if (getMoneda(monedaId) === MONEDA.DOLAR && detalle.moneda === MONEDA.PESO) {
          detalle.precio = currency(detalle.precio_original, { precision: 4 }).divide(tipoCambio).value;
        }
      }
    }
    return {
      ...detalle,
      ...calcularPrecioYMoneda(detalle, monedaId, tipoCambio),
    };
  } else if (esKit(detalle)) {
    detalle.partes = [
      ...detalle.partes.map((parte) => {
        if (!parte.modifico_precio) {
          parte.moneda = parte.moneda_original;
          parte.precio = parte.precio_original;
        } else {
          if (parte.moneda !== monedaId) {
            if (monedaId === MONEDA.PESO && parte.moneda === MONEDA.DOLAR) {
              parte.precio = currency(parte.precio_original, { precision: 4 }).multiply(tipoCambio).value;
            } else if (monedaId === MONEDA.DOLAR && parte.moneda === MONEDA.PESO) {
              parte.precio = currency(parte.precio_original, { precision: 4 }).divide(tipoCambio).value;
            }
          }
        }
        return {
          ...parte,
          ...calcularPrecioYMoneda(parte, monedaId, tipoCambio),
        };
      }),
    ];
    detalle.precio = getImporte(detalle.partes, 4);
  }
  return {
    ...detalle,
  };
};

export const recalcularPrecioServicio = (detalle, sucursalId) => {
  if (detalle.tipo_partida === SERVICIO) {
    if (detalle?.listCost?.length > 0) {
      const calculate = calculatePrecioServicio(detalle, detalle.measureId, sucursalId);
      detalle.precio_original = calculate.precio_original;
      detalle.precio = calculate.precio;
      detalle.moneda = detalle.moneda_original;
    }
  } else if (esKit(detalle)) {
    detalle.partes = [
      ...detalle.partes.map((parte) => {
        if (parte.tipo_partida === SERVICIO) {
          if (parte?.listCost?.length > 0) {
            const calculate = calculatePrecioServicio(parte, parte.measureId, sucursalId);
            parte.precio = calculate.precio;
            parte.precio_original = calculate.precio;
            parte.moneda = calculate.moneda_original;
          }
        }
        return { ...parte };
      }),
    ];
  }
  return { ...detalle };
};

export const mapParte = (parte, sucursalId, monedaId, tipoCambio) => {
  parte.tipo_partida = ENUM_TIPOS_PARTIDAS[parte.tipo_partida];
  const newParte = {
    ...(esEquipo(parte) ? getParteEquipo(parte, sucursalId) : getParteServicio(parte, sucursalId)),
  };
  return {
    ...newParte,
    ...calcularPrecioYMoneda(newParte, monedaId, tipoCambio),
    descuento: 0,
    iva: newParte.iva || 16,
    tiene_sustitutos: newParte.listAlternos?.length > 0,
    tiene_otro_proveedor: newParte.listCostosProveedores?.length > 1,
  };
};

export const getReferencia = ({
  tipos_mano_obra,
  tipos_cobro_equipo,
  tipo_servicio_solicitado,
  monitoreo,
  folio,
  descripcion,
  horas,
  numero_levantamiento,
  numero_pedido,
  agente,
  agente_compartido,
}) => {
  if (!agente_compartido) agente_compartido = agente;
  return `@${tipos_mano_obra || ''}.${tipos_cobro_equipo || ''}.CTA${monitoreo || ''}.CRM${folio || ''}.${tipo_servicio_solicitado || ''}.COT${
    numero_levantamiento || ''
  }.H${horas || ''}.AP${agente || ''}.AC${agente_compartido || ''}.${descripcion?.toUpperCase()}@ ${numero_pedido ? 'PED: ' + numero_pedido : ''}`.trim();
};
