import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ id, clave: initialClave, definicion: initialDefinicion, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [clave, setClave] = useState('');
  const [definicion, setDefinicion] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const open = () => {
    setClave(initialClave);
    setDefinicion(initialDefinicion);
    setShowModal(true);
  };

  const close = () => {
    setShowModal(false);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      clave: clave.trim(),
      definicion: definicion.trim(),
    };

    if (validations(data)) {
      setIsLoading(true);
      await putRequest({ url: `tipos_servicios_solicitados/${id}`, body: data });
      load();
      close();
      setIsLoading(false);
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.clave + '')) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.definicion + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col sm='4'>
            <FormInput title='Clave' placeholder='Clave' required name='clave' onChange={(e) => setClave(e.target.value)} value={clave} />
          </Col>
          <Col sm='8'>
            <FormInput
              title='Nombre'
              placeholder='Nombre'
              required
              name='definicion'
              onChange={(e) => setDefinicion(e.target.value)}
              value={definicion}
            />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  clave: PropTypes.string.isRequired,
  definicion: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Edit;
