import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest } from '@utils/api';
import ListSellers from './ListSellers';

const StoreJhonnyCars = () => {
  const [catalogSellers, setCatalogSellers] = useState([]);
  const [catalogEquipments, setCatalogEquipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search] = useState('');
  const [serviceIdDefault, setServiceIdDefault] = useState('');

  useEffect(() => {
    loadSellers();
  }, []);

  const loadSellers = async () => {
    const { data, store } = await getRequest({ url: `store/jhonnycars` });
    setCatalogSellers(data);
    setCatalogEquipments(store);
    setServiceIdDefault(data.length ? data[0]._id : '');
    setLoading(false);
  };

  return (
    <Module title='Inventario en Carros'>
      <Loading loading={loading}>
        <ListSellers
          catalogSellers={catalogSellers}
          catalogEquipments={catalogEquipments}
          loading={loading}
          search={search}
          serviceIdDefault={serviceIdDefault}
          loadSellers={loadSellers}
        />
      </Loading>
    </Module>
  );
};

StoreJhonnyCars.propTypes = {};

export default StoreJhonnyCars;
