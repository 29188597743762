import React from 'react';
import { Module } from '@stateless';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <Module title='Página no encontrada'>
      <br />
      <br />
      <br />
      <div className='error-page'>
        <h2 className='headline text-red'>404</h2>
        <div className='error-content'>
          <h3>
            <i className='fa fa-warning text-red'></i> Oops! La pagina no existe.
          </h3>
          <p>
            Click <Link href='/'>aquí</Link> para ir al inicio.
          </p>
        </div>
      </div>
    </Module>
  );
};

export default PageNotFound;
