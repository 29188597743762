import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ title, name, className = 'control-label', required, children }) => {
  const nameClass = className.match('control-label') ? className : `control-label ${className}`;
  const titleLabel = (
    <span>
      {title || children} {required ? <strong style={{ color: 'red' }}>*</strong> : ''}
    </span>
  );

  return (
    <label htmlFor={name} className={nameClass}>
      {titleLabel}
    </label>
  );
};

Label.propTypes = {
  title: PropTypes.node.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Label;
