import React from 'react';
import { Carousel } from 'react-bootstrap';
import { isURL } from 'validator';
import { urlPhoto } from '@utils/api';
import PropTypes from 'prop-types';

const PrincipalCarousel = ({ carruseles }) => {
  return (
    <Carousel>
      {carruseles.map((x, i) => (
        <Carousel.Item key={i}>
          <img
            className='img-responsive'
            alt='900x500'
            src={x.path ? (isURL(x.path) ? x.path : urlPhoto() + x.path) : 'https://ftp3.syscom.mx/usuarios/fotos/imagen_no_disponible.jpg'}
          />
          <Carousel.Caption>
            <h3>{x.description}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

PrincipalCarousel.propTypes = {
  carruseles: PropTypes.array,
};

export default PrincipalCarousel;
