import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ list, load }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, nombre, descripcion, codigo } = row;
      return (
        <tr key={item}>
          <td>{codigo}</td>
          <td>{nombre}</td>
          <td>{descripcion}</td>
          <td>
            <div className='btn-group pull-right'>
              <Edit id={_id} tipoCompraId={_id} nombre={nombre} codigo={codigo} load={load} />
              <Delete id={_id} nombre={nombre} load={load} />
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Código</th>
          <th width='20%'>Nombre</th>
          <th width='20%'>Descripción</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={4}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default List;
