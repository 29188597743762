import PropTypes from 'prop-types';

const Else = ({ children }) => {
  return children;
};

Else.propTypes = {
  children: PropTypes.node,
};

export default Else;
