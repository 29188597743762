import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DivFormGroup, Input } from '@controls';
import MultiSelectTiposContactos from '@components/forms/MultiSelectTiposContactos';

const ContactoEdit = ({ contacto, onChangeContacto }) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    onChangeContacto({
      ...contacto,
      [name]: value,
    });
  };
  return (
    <Row>
      <Col sm='6'>
        <DivFormGroup name='tipos' title='Tipo' required>
          <MultiSelectTiposContactos onChange={(value) => onChangeContacto({ ...contacto, tipos: value })} value={contacto.tipos} />
        </DivFormGroup>
      </Col>
      <Col sm='6'>
        <DivFormGroup name='nombre' title='Nombre' required>
          <div className='input-group'>
            <Input name='name' onChange={onChange} value={contacto.name} placeholder='Nombre' />
            <Input name='paterno' onChange={onChange} value={contacto.paterno} placeholder='Apellido paterno' />
            <Input name='materno' onChange={onChange} value={contacto.materno} placeholder='Apellido materno' />
          </div>
        </DivFormGroup>
      </Col>
      <Col sm='4'>
        <DivFormGroup name='telefono' title='Teléfono' required>
          <Input name='phone' onChange={onChange} value={contacto.phone} placeholder='Teléfono' />
        </DivFormGroup>
      </Col>
      <Col sm='4'>
        <DivFormGroup name='mobile' title='Celular' required>
          <Input name='mobile' onChange={onChange} value={contacto.mobile} placeholder='Celular' />
        </DivFormGroup>
      </Col>
      <Col sm='4'>
        <DivFormGroup name='email' title='Correo electronico' required>
          <Input name='email' onChange={onChange} value={contacto.email} placeholder='Correo electronico' />
        </DivFormGroup>
      </Col>
    </Row>
  );
};

ContactoEdit.propTypes = {
  contacto: PropTypes.object.isRequired,
  onChangeContacto: PropTypes.func.isRequired,
  tipo: PropTypes.string.isRequired,
};

export default ContactoEdit;
