import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { format } from '@utils/parseCost';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DetailCustomer from '@components/customers/DetailCustomer';

const Table = ({ list }) => {
  const renderRows = () => {
    return list.map((r, i) => {
      const { _id, fecha_original, serie, folio, uuid, monto, customerId, moneda, detalles } = r;
      let currency = 'MXN';
      if (moneda === 'DOLAR' || moneda === 'DOLLAR' || moneda === 'DÓLAR') currency = 'USD';
      return (
        <tr key={i}>
          <td>
            <strong>{serie ? `${serie}-${folio}` : folio}</strong>
          </td>
          <td>
            <ul className='list-unstyled'>
              {detalles.map((f, i) => (
                <li key={i}>
                  <strong>
                    {f.facturaId ? (
                      <Link to={`/facturas/detalle/${f.facturaId}`}>{f.serie ? `${f.serie}-${f.folio}` : f.folio}</Link>
                    ) : (
                      <Fragment>{f.serie ? `${f.serie}-${f.folio}` : f.folio}</Fragment>
                    )}{' '}
                    &nbsp; <span className='label label-primary'>${format(f.importe_pagado, 2)}</span>
                  </strong>
                </li>
              ))}
            </ul>
          </td>
          <td>{moment(fecha_original).local().format('DD/MM/YYYY HH:mm')}</td>
          <td>{uuid}</td>
          <td>
            <DetailCustomer customer={customerId} />
          </td>
          <td>${format(monto, 2)} </td>
          <td className='with-actions'>
            <span className='label label-default mr-5'>{currency}</span>
            <span className='actions'>
              <RedirectTooltip id={1} icon='search' url={`/pagos/detalle/${_id}`} labelTooltip='Completa' className='btn btn-sm btn-primary' />
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='10%'>Folio</th>
          <th width='20%'>Docs. Relacionados</th>
          <th width='15%'>Fecha</th>
          <th width='25%'>Folio</th>
          <th width='25%'>Cliente</th>
          <th width='6%'>Monto</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={7}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
};

export default Table;
