import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';
import InputPercentage from '@components/forms/InputPercentage';

const EditPlazos = ({ plazosId, loadPlazos }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [plazo, setPlazo] = useState('');
  const [taza, setTaza] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
    setPlazo('');
    setTaza('');
  };

  const open = async () => {
    const {
      data: { nombre, plazo, taza },
    } = await getRequest({ url: `plazos/${plazosId}` });
    setShowModal(true);
    setNombre(nombre);
    setPlazo(plazo);
    setTaza(taza);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      nombre: nombre.trim(),
      plazo: plazo,
      taza: taza,
    };

    if (validations(data)) {
      await putRequest({ url: `plazos/${plazosId}`, body: data });
      loadPlazos();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.plazo + '')) {
      validate.success = false;
      validate.message = 'Plazo es requerido.';
    } else if (params.plazo <= 0) {
      validate.success = false;
      validate.message = 'Plazo debe ser mayor a cero.';
    } else if (isEmpty(params.taza + '')) {
      validate.success = false;
      validate.message = 'Tasa es requerida.';
    } else if (params.taza >= 100) {
      validate.success = false;
      validate.message = 'Tasa debe ser menor al 100%.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' onChange={(e) => setNombre(e.target.value)} defaultValue={nombre} placeholder='Nombre' />
            </DivFormGroup>
            <DivFormGroup name='plazo' title='Plazo' required>
              <InputNumber name='plazo' value={plazo} onChange={(e) => setPlazo(Number(e.target.value))} placeholder='Plazo' />
            </DivFormGroup>
            <DivFormGroup name='taza' title='Tasa' required>
              <InputPercentage
                name='taza'
                className='form-control'
                placeholder='Tasa'
                onChange={(e) => setTaza(Number(e.target.value))}
                value={taza}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditPlazos.propTypes = {
  id: PropTypes.number.isRequired,
  plazosId: PropTypes.string.isRequired,
  loadPlazos: PropTypes.func.isRequired,
};

export default EditPlazos;
