import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ itemId, clave, nombre, loadData }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`tipos_ordenes_servicios/${itemId}`}
      description={`¿Confirma que desea eliminar el tipo de orden de servicio <strong><u>  ${clave} - ${nombre}</u> </strong>?`}
      loadData={loadData}
    />
  );
};

Delete.propTypes = {
  id: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  clave: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Delete;
