import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteEsquemas = ({ esquemasId, name, loadEsquemas }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`esquemas/${esquemasId}`}
      description={`¿Confirma que desea eliminar el esquema de pago <strong><u> ${name}</u> </strong>?`}
      loadData={loadEsquemas}
    />
  );
};

DeleteEsquemas.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  esquemasId: PropTypes.string.isRequired,
  loadEsquemas: PropTypes.func.isRequired,
};

export default DeleteEsquemas;
