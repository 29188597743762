import { useAuthStore } from './app/stores/auth.store';

const subscribeToEvent = (eventName, callback) => {
  let socket = useAuthStore.getState().socket;
  if (!socket?.connected) {
    console.log('Socket not connected');
    return;
  }
  socket?.on(eventName, callback);
};

const unsubscribeFromEvent = (eventName, callback) => {
  let socket = useAuthStore.getState().socket;
  if (!socket?.connected) {
    console.log('Socket not connected');
    return;
  }
  socket?.off(eventName, callback);
};

const emitEvent = (eventName, data) => {
  let socket = useAuthStore.getState().socket;
  if (!socket?.connected) {
    setTimeout(() => {
      emitEvent(eventName, data);
    }, 1000);
    console.log('Socket not connected');
    return;
  }
  socket?.emit(eventName, data);
};

const connectSocket = () => {
  useAuthStore.getState().connectSocket();
};

const disconnectSocket = () => {
  useAuthStore.getState().disconnectSocket();
};

export const socketManager = {
  connect: connectSocket,
  disconnect: disconnectSocket,
  subscribe: subscribeToEvent,
  unsubscribe: unsubscribeFromEvent,
  emit: emitEvent,
};
