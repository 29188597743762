import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, RowsNotFound } from '@stateless';
import Delete from './Delete';

const List = ({ catalogCanastas, ...props }) => {
  const renderRows = () => {
    return catalogCanastas.map((row, i) => {
      const { _id, fecha, storeId, maquinariaHerramientaId } = row;

      return (
        <tr key={i}>
          <td>{storeId.name}</td>
          <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
          <td>{maquinariaHerramientaId.nombre}</td>
          <td>
            <Delete
              id={i}
              itemId={_id}
              label={` de ${maquinariaHerramientaId.nombre} de la sucurdal ${storeId.name}, el día ${moment(fecha).local().format('DD/MM/YYYY')}`}
              {...props}
              btnClass='btn btn-outline-danger'
            />
            <Link to={`/mantenimientos/edicion/${_id}`} className='btn btn-outline-info'>
              <Icon icon='pencil' />
            </Link>
          </td>
        </tr>
      );
    });
  };

  const rows = catalogCanastas.length > 0 ? renderRows() : <RowsNotFound colSpan={4} />;

  return (
    <div className='panel panel-default panel-table'>
      <div className='table-responsive'>
        <table className='table table-condensed table-hover table-striped'>
          <thead>
            <tr>
              <th width='40%'>Sucursal</th>
              <th width='10%'>Fecha</th>
              <th width='40%'>Maquinaria / Herramienta</th>
              <th width='10%'></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

List.propTypes = {
  catalogCanastas: PropTypes.array.isRequired,
};

export default List;
