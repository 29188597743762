import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const NewModule = ({ loadCatalog }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [sort, setSort] = useState(1);
  const [nameModule, setNameModule] = useState('');
  const [icon, setIcon] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setSort(1);
    setNameModule('');
    setIcon('');
  };

  const open = () => {
    setShowModal(true);
    setSort(1);
    setNameModule('');
    setIcon('');
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'sort') setSort(value);
    if (name === 'nameModule') setNameModule(value);
    if (name === 'icon') setIcon(value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      sort: sort,
      name: nameModule.trim(),
      icon: icon.trim(),
    };

    if (validations(data)) {
      await postRequest({ url: 'modules', body: data });
      loadCatalog();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.sort.toString())) {
      validate.success = false;
      validate.message = 'Orden es requerido.';
    } else if (params.sort <= 0) {
      validate.success = false;
      validate.message = 'Orden debe ser mayor a cero.';
    } else if (isEmpty(params.name)) {
      validate.success = false;
      validate.message = 'Nombre del módulo es requerido.';
    } else if (isEmpty(params.icon)) {
      validate.success = false;
      validate.message = 'Icono es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver title='Nuevo' icon='plus' onClick={open} hiddenXs type='success pull-right' />
      <CbModal title='Agregar Módulo' show={showModal} onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='sort' title='Orden' required>
              <input type='number' className='form-control' name='sort' onChange={onChange} value={sort} />
            </DivFormGroup>
            <DivFormGroup name='nameModule' title='Nombre del módulo' required>
              <input type='text' className='form-control' name='nameModule' onChange={onChange} value={nameModule} />
            </DivFormGroup>
            <DivFormGroup name='icon' title='Icono' required>
              <input type='text' className='form-control' name='icon' onChange={onChange} value={icon} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewModule.propTypes = {
  loadCatalog: PropTypes.func.isRequired,
};

export default NewModule;
