import PropTypes from 'prop-types';
import React from 'react';

const NameStep = ({ step, note, required }) => {
  return (
    <div>
      <strong>{step}</strong>

      {required ? <span className='label label-default pull-right'>* Obligatorio</span> : null}

      {note.length ? (
        <p style={{ paddingRight: '15px' }}>
          <small>{note}</small>
        </p>
      ) : null}
    </div>
  );
};

NameStep.propTypes = {
  step: PropTypes.string.isRequired,
  note: PropTypes.string,
  required: PropTypes.bool,
};

export default NameStep;
