import React, { useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types'
import { useStoreCuentasBancarias } from "../../stores/catalogs.store";

const SelectCuentaBancaria = ({ onChange, name, value}) =>{
    const {data, execute} = useStoreCuentasBancarias();
    useEffect (() => {
        execute();
    }, []);
    const onSelect = ( selectedOption) => {
        onChange(selectedOption);
    };

  return (
    <Select
      value={value}
      name={name}
      placeholder='Seleccione'
      options={data}
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='basic-multi-select'
      getOptionLabel={(x) => x.nombre}
      getOptionValue={(x) => x._id}
      classNamePrefix='select'
      isClearable
    />
  );
};

SelectCuentaBancaria.propTypes = {
    onChange: PropTypes.func.isRequired,
    name:PropTypes.string.isRequired,
    value: PropTypes.object
};

export default SelectCuentaBancaria;