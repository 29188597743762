import Search from '@controls/Search';
import { useStoreNotification } from '@stores/catalogs.store';
import React, { useState, useEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Icon, Module } from '@stateless';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid,
  borderRadius: '5px',
  background: isDragging ? '#e4e4e4' : '#f4f4f4',
  marginBottom: '2px',
  borderLeft: '2px solid #e6e7e8',
  color: '#444',
  position: 'relative',
  ...draggableStyle,
});

const Tareas = () => {
  const { addNotification: notification } = useStoreNotification();

  const [state, setState] = useState({
    summary: {},
    status: 0,
    notExits: true,
    agenda: [],
    clientes: 0,
    facturas: 0,
    pagos: 0,
    servicios: 0,
    video: '',
    items: {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
    },
    names: {
      1: 'LUNES',
      2: 'MARTES',
      3: 'MIERCOLES',
      4: 'JUEVES',
      5: 'VIERNES',
    },
    kpis: [
      {
        nombre: 'SISTEMA INTEGRAL CODIGO BLANCO',
        objectivos: [
          'Compras',
          'Inventario',
          'RRHH',
          'Levantamiento',
          'Ventas',
          'Pedido',
          'Orden de Servicio',
          'Remision - Facturacion',
          'Catalogos',
          'Clientes',
        ],
      },
      {
        nombre: 'PAGINA',
        objectivos: ['Tienda en linea', 'Soluciones y Bases de datos', 'Servicios Tickets '],
      },
      {
        nombre: 'INNOVACION',
        objectivos: ['Otros aspectos'],
      },
    ],
    kpi: null,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {};

  const onRemoveItem = (name, index, e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      items: {
        ...prevState.items,
        [name]: prevState.items[name].filter((x, i) => i !== index),
      },
    }));
    notification({ title: 'Atención', message: '¡Tarea eliminada!', type: 'success' });
  };

  const onAddItem = (name, e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      items: {
        ...prevState.items,
        [name]: [
          ...prevState.items[name],
          {
            id: `item-${name + prevState.items[name].length}`,
            content: `item ${name + prevState.items[name].length}`,
            title: `item ${name + prevState.items[name].length}`,
          },
        ],
      },
    }));
    notification({ title: 'Atención', message: '¡Tarea agregada!', type: 'success' });
  };

  const onChangeInput = (name, index, e) => {
    setState((prevState) => ({
      ...prevState,
      items: {
        ...prevState.items,
        [name]: prevState.items[name].map((x, i) => {
          if (i === index) {
            return { ...x, [e.target.name]: e.target.value };
          }
          return x;
        }),
      },
    }));
  };

  const onChangeName = (name, e) => {
    setState((prevState) => ({
      ...prevState,
      names: {
        ...prevState.names,
        [name]: e.target.value,
      },
    }));
  };

  const onRemoveList = (name, e) => {
    e.preventDefault();
    const { items, names } = state;
    delete items[name];
    delete names[name];
    setState({
      items: { ...items },
      names: { ...names },
    });
    notification({ title: 'Atención', message: '¡Lista eliminada!', type: 'success' });
  };

  const onSearch = () => {};
  const onSelect = () => {};

  const selectKpi = (kpi) => {
    setState((prevState) => ({ ...prevState, kpi }));
  };

  const renderList = (item, list, name) => (
    <Col md='2' xs='4' key={name}>
      <div className='panel panel-default'>
        <div className='panel-heading' style={{ position: 'relative' }}>
          <div className='panel-title'>
            <a href='#' onClick={(e) => onRemoveList(name, e)} style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '12px' }}>
              <Icon icon='remove' style={{ color: '#888' }} />
            </a>
            <input
              value={state.names[name]}
              name='title'
              className='form-control'
              style={{
                background: 'transparent',
                border: 'none',
                padding: '0px',
                boxShadow: 'none',
                fontSize: '22px',
                height: '22px',
                fontWeight: '700',
              }}
              onChange={(e) => onChangeName(name, e)}
            />
          </div>
        </div>
        <div className='panel-body'>
          <Droppable droppableId={name}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <a
                          href='#'
                          onClick={(e) => onRemoveItem(name, index, e)}
                          style={{ position: 'absolute', right: '18px', top: '5px', fontSize: '11px' }}
                        >
                          <Icon icon='pencil' style={{ color: '#555' }} />
                        </a>
                        <a
                          href='#'
                          onClick={(e) => onRemoveItem(name, index, e)}
                          style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '12px' }}
                        >
                          <Icon icon='remove' style={{ color: '#555' }} />
                        </a>
                        <input
                          value={item.title}
                          name='title'
                          className='form-control'
                          style={{
                            background: 'transparent',
                            border: 'none',
                            padding: '0px',
                            boxShadow: 'none',
                            height: '18px',
                            fontWeight: '700',
                          }}
                          onChange={(e) => onChangeInput(name, index, e)}
                        />
                        <p>
                          <textarea
                            value={item.content}
                            name='content'
                            className='form-control'
                            style={{ background: 'transparent', border: 'none', padding: '0px', boxShadow: 'none', height: '18px' }}
                            onChange={(e) => onChangeInput(name, index, e)}
                          ></textarea>
                        </p>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <a href='#' className='btn btn-block btn-default btn-sm' onClick={(e) => onAddItem(name, e)}>
                  <Icon icon='plus' /> Nueva tarea
                </a>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </Col>
  );

  const { items, medio_comunicacion, kpi, kpis } = state;
  const listArray = [];
  for (let item in items) listArray.push(renderList(item, items[item], item));
  return (
    <Module title='KPIs'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <Row>
          <Col sm='4'>
            <Select
              value={medio_comunicacion}
              name='medio_comunicacion'
              placeholder='Selecciona opciones'
              options={[]}
              onChange={onSelect}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </Col>
          <Col sm='4'>
            <Select
              value={medio_comunicacion}
              name='medio_comunicacion'
              placeholder='Selecciona opciones'
              options={[]}
              onChange={onSelect}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </Col>
          <Col sm='4'>
            <Select
              value={medio_comunicacion}
              name='medio_comunicacion'
              placeholder='Selecciona opciones'
              options={[]}
              onChange={onSelect}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </Col>
        </Row>
      </Search>
      <Row>
        <Col md='3'>
          <a href='#' className='btn btn-primary btn-block mb-3'>
            Compose
          </a>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title'>KPIs</h3>
              <div className='card-tools'>
                <button type='button' className='btn btn-tool' data-card-widget='collapse'>
                  <i className='fas fa-minus'></i>
                </button>
              </div>
            </div>
            <div className='card-body p-0'>
              <ul className='nav nav-pills flex-column'>
                {kpis.map((x, i) => (
                  <li className={'nav-item ' + (kpi && kpi.nombre === x.nombre && 'active')} key={i}>
                    <a href='#' onClick={() => selectKpi(x)} className='nav-link'>
                      <i className='fas fa-inbox'></i> {x.nombre}
                      <span className='badge bg-primary float-right'>{x.objectivos.length}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {kpi && (
            <div className='card'>
              <div className='card-header'>
                <h3 className='card-title'>Objectivos</h3>
                <div className='card-tools'>
                  <button type='button' className='btn btn-tool' data-card-widget='collapse'>
                    <i className='fas fa-minus'></i>
                  </button>
                </div>
              </div>
              <div className='card-body p-0'>
                <ul className='nav nav-pills flex-column'>
                  {kpi.objectivos.map((x, i) => (
                    <li className={'nav-item'} key={i}>
                      <a href='#' className='nav-link'>
                        <i className='fas fa-inbox'></i> {x}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Module>
  );
};

Tareas.propTypes = {};

export default Tareas;
