import React from 'react';
import { Route } from 'react-router-dom';
import { requireAuth } from '@utils/router';
import App from '@containers/App';
import PublicRoute from '@pages/router/PublicRoute';
import PrivateRoute from '@pages/router/PrivateRoute';
import { Routes } from 'react-router-dom';

import Dashboard from '@containers/Dashboard';
import FirmarCotizacion from '@pages/clientes/cotizaciones/cliente/FirmarPublica';
import FirmarContratoEquipo from '@pages/clientes/customers/contrato-publico/FirmarPublica.js';
import Login from '@pages/application/session/Login';
import Modules from '@pages/application/modules';
import Users from '@pages/application/users';
import ApplicationData from '@pages/application/logs/ApplicationData';
import Files from '@pages/application/files';
import Logout from '@pages/application/session/Logout';
import PageNotFound from '@pages/application/security/PageNotFound';
import Forbidden from '@pages/application/security/Forbidden';
import StoreEquipments from '@pages/inventario/store-equipments';
import StoreJhonnyCars from '@pages/inventario/store-jhonnycars';

import RecepcionCompras from '@pages/inventario/recepciones-compras';
import NuevaRecepcion from '@pages/inventario/recepciones-compras/add';
import EditarRecepcion from '@pages/inventario/recepciones-compras/edit';
import DetalleRecepcion from '@pages/inventario/recepciones-compras/detail';

import SurtidoCarros from '@pages/inventario/surtido-carros/';
import SurtirCarro from '@pages/inventario/surtido-carros/add';

import Traspasos from '@pages/inventario/traspasos';
import NuevoTraspaso from '@pages/inventario/traspasos/New';
import DetalleTraspaso from '@pages/inventario/traspasos/detail';

import Mermas from '@pages/inventario/mermas';
import NuevoMerma from '@pages/inventario/mermas/New';
import DetalleMerma from '@pages/inventario/mermas/detail';

import Ajustes from '@pages/inventario/ajustes';
import NuevoAjuste from '@pages/inventario/ajustes/New';
import DetalleAjuste from '@pages/inventario/ajustes/detail';

import Pedidos from '@pages/inventario/pedidos';
import NuevoPedido from '@pages/inventario/pedidos/New';
import DetallePedido from '@pages/inventario/pedidos/detail';
import EditarPedido from '@pages/inventario/pedidos/edit';

import Conversiones from '@pages/inventario/conversiones';
import NuevoConversion from '@pages/inventario/conversiones/New';
import DetalleConversion from '@pages/inventario/conversiones/detail';
import EditarConversion from '@pages/inventario/conversiones/edit';

import Transations from '@pages/clientes/transations/transations';
import References from '@pages/clientes/transations/references';

// Clientes

import ListCustomerRegistros from '@pages/clientes/customers_registros';

import EditCustomer from '@pages/clientes/customers/edit';
import ListCustomer from '@pages/clientes/customers/list';
import DetailCustomer from '@pages/clientes/customers/detail';
import ContractByCustomer from '@pages/clientes/customers/contract';
import EditarContrato from '@pages/clientes/customers/contract/Edit';
import QuotationByCustomer from '@pages/clientes/customers/quotation';
import Ubicaciones from '@pages/clientes/customers/ubicaciones';
import ImportClientes from '@pages/clientes/customers/import';
import UnificarClientes from '@pages/clientes/customers/unificar';
import Domicilios from '@pages/catalogs/domicilios';
import Contactos from '@pages/catalogs/contactos';
import ContactosClientes from '@pages/catalogs/contactos/ClientesContacto';

import RuletaAsesores from '@pages/catalogs/ruleta-asesores';

import SeguimientoProspectos from '@pages/clientes/seguimientos/SeguimientoProspectos';
import OrdenesServicio from '@pages/clientes/ordenes-servicio';
import OrdenesServicioVisitas from '@pages/clientes/ordenes-servicios-visitas';
import NuevoOrdenServicio from '@pages/clientes/ordenes-servicio/New';
import EditarOrdenServicio from '@pages/clientes/ordenes-servicio/Edit';
import VisitasOrdenServicio from '@pages/clientes/ordenes-servicio/visitas/index';

import ObservacionesOrdenServicio from '@pages/clientes/ordenes-servicio/observaciones/observaciones';
import ObservacionesConclusionesOrdenServicio from '@pages/clientes/ordenes-servicio/observaciones/conclusiones';
import ObservacionesComentariosOrdenServicio from '@pages/clientes/ordenes-servicio/observaciones/comentarios';
import CalificacionServicioOrdenServicio from '@pages/clientes/ordenes-servicio/calificacion_servicio/index';
import FirmaClienteServicioOrdenServicio from '@pages/clientes/ordenes-servicio/firmas/cliente';
import FirmaAgenteOrdenServicio from '@pages/clientes/ordenes-servicio/firmas/agente';

import DetalleOrdenServicio from '@pages/clientes/ordenes-servicio/detail';

import Existencias from '@pages/inventario/existencias';

import AddAgendaMonitoreo from '@pages/clientes/agendas-monitoreos/add/New';
import UptAgendaMonitoreo from '@pages/clientes/agendas-monitoreos/upt/Edit';
import ListAgendaMonitoreo from '@pages/clientes/agendas-monitoreos';
import ImportarAgendaMonitoreo from '@pages/clientes/agendas-monitoreos/Importar';

import ClientesSaldos from '@pages/clientes/customers/customers-saldos';

// Catalogos

import ListasPrecios from '@pages/catalogs/listas-precios';
import UnitMeasures from '@pages/catalogs/unit-measures';
import Products from '@pages/catalogs/products';
import NewProduct from '@pages/catalogs/products/NewProduct';

import Configurations from '@pages/catalogs/configuration';
import ConfigurationDetail from '@pages/catalogs/configuration/ConfigurationDetail';

import Characteristics from '@pages/catalogs/characteristics';
import NewCharacteristics from '@pages/catalogs/characteristics/NewCharacteristics';
import DetailCharacteristics from '@pages/catalogs/characteristics/detail/DetailCharacteristics';
import EditCharacteristics from '@pages/catalogs/characteristics/EditCharacteristics';

import PreciosEquipos from '@pages/catalogs/precios-equipos';

import Equipments from '@pages/catalogs/equipments';
import NewEquipment from '@pages/catalogs/equipments/NewEquipment';
import ImportEquipment from '@pages/catalogs/equipments/import';
import DetailEquipment from '@pages/catalogs/equipments/detail/DetailEquipment';
import EditEquipments from '@pages/catalogs/equipments/EditEquipments';

import RendimientosEquipment from '@pages/catalogs/equipments/rendimientos';

import Services from '@pages/catalogs/services/';
import NewService from '@pages/catalogs/services/NewService';
import DetailService from '@pages/catalogs/services/detail/DetailService';
import EditServices from '@pages/catalogs/services/EditServices';
import ImportServices from '@pages/catalogs/services/import';

import Sellers from '@pages/catalogs/sellers';

import StoreHouse from '@pages/catalogs/storehouse';
import Sucursales from '@pages/catalogs/sucursales';
import Providers from '@pages/catalogs/providers';

import EquiposSeguridadElectronica from '@pages/catalogs/equipos-seguridad-electronica';
import TipoEstablecimientos from '@pages/catalogs/tipo-establecimientos';
import Rubros from '@pages/catalogs/rubros';
import TipoCableados from '@pages/catalogs/tipo-cableados';
import TipoServicios from '@pages/catalogs/tipo-servicios';

import FormaPago from '@pages/catalogs/formas-pago';
import CondicionesPagoEquipos from '@pages/catalogs/condiciones-equipos';
import CondicionesPagoServicios from '@pages/catalogs/condiciones-servicios';

import EsquemasPago from '@pages/catalogs/esquemas-pago';
import Plazos from '@pages/catalogs/plazos';
import Anticipos from '@pages/catalogs/anticipos';

import TiposContactos from '@pages/catalogs/tipos-contactos';
import Agentes from '@pages/catalogs/agentes';
import AgentesClientes from '@pages/catalogs/agentes/AgentesClientes';
import AgentesProspectos from '@pages/catalogs/agentes/AgentesProspectos';
import Titulos from '@pages/catalogs/titulos';

import ClasificadoresCotizaciones from '@pages/catalogs/clasificadores-cotizaciones';
import ClasificadoresCotizacionesImportar from '@pages/catalogs/clasificadores-cotizaciones/import';
import ClasificadoresLevantamientos from '@pages/catalogs/clasificadores-levantamientos';

import KitsComerciales from '@pages/catalogs/kits-comerciales';
import NewKitsComerciales from '@pages/catalogs/kits-comerciales/NewKits';
import EditKitsComerciales from '@pages/catalogs/kits-comerciales/EditKits';
import ImportKitsComerciales from '@pages/catalogs/kits-comerciales/import';

import Recetas from '@pages/catalogs/recetas';
import NewRecetas from '@pages/catalogs/recetas/New';
import EditRecetas from '@pages/catalogs/recetas/Edit';

import Exceptions from '@pages/catalogs/exceptions';
import TipoCambios from '@pages/catalogs/tipo-cambios';
import Bancos from '@pages/catalogs/bancos';
import Acreedores from '@pages/catalogs/acreedores';
import CuentasBancarias from '@pages/catalogs/cuentas-bancarias';
import Motivos from '@pages/catalogs/motivos';
import ResultadosVisitas from '@pages/catalogs/resultados-visitas';
import Documentos from '@pages/catalogs/documentos';
import UbicacionesEquipos from '@pages/catalogs/ubicaciones';
import Marcas from '@pages/catalogs/marcas';
import EquipmentsCharacteristics from '@pages/catalogs/equipments-characteristics';

import CondicionesVentas from '@pages/catalogs/condiciones-ventas';
import CondicionesInstalaciones from '@pages/catalogs/condiciones-instalaciones';
import CondicionesGarantias from '@pages/catalogs/condiciones-garantias';
import AvisoPrivacidad from '@pages/catalogs/aviso-privacidad';
import ClasificadoresProveedores from '@pages/catalogs/clasificadores-proveedores';
import CondicionesContratos from '@pages/catalogs/condiciones-contratos';
import FichasTecnicas from '@pages/catalogs/fichas-tecnicas';

import Fabricantes from '@pages/catalogs/fabricantes';
import ControlesMantenimientos from '@pages/catalogs/controles-mantenimientos';
import MaquinariaHerramientas from '@pages/catalogs/maquinaria-herramientas';
import Piezas from '@pages/catalogs/piezas';
import TiposPiezas from '@pages/catalogs/tipos-piezas';
import ServiciosTerceros from '@pages/catalogs/servicios-terceros';
import TiposMonitoreos from '@pages/catalogs/tipos-monitoreos';
import CuentasMonitoreos from '@pages/catalogs/cuentas-monitoreos';
import ImportarCuentasMonitoreos from '@pages/catalogs/cuentas-monitoreos/Importar';

import TiposLevantamientos from '@pages/catalogs/tipos-levantamientos';
import TiposOrdenesServicios from '@pages/catalogs/tipos-ordenes-servicios';
import ConfiguracionesCarrusel from '@pages/catalogs/configuraciones_carrusel';
import DocumentosEmpleados from '@pages/catalogs/documentos-empleados';
import DescuentosPagos from '@pages/catalogs/descuentos-pagos';

import Estados from '@pages/catalogs/estados';
import Paises from '@pages/catalogs/paises';
import Colonias from '@pages/catalogs/colonias';
import ImportarColonias from '@pages/catalogs/colonias/import';

import Municipios from '@pages/catalogs/municipios';
import ImportarMunicipios from '@pages/catalogs/municipios/import';

import Soluciones_Solicitadas from '@pages/catalogs/soluciones_solicitadas';
import Tipos_Servicios_Solicitados from '@pages/catalogs/tipos_servicios_solicitados';
import Tipos_Mano_Obra from '@pages/catalogs/tipos_mano_obra';
import Tipos_Cobro_Equipo from '@pages/catalogs/tipos_cobro_equipo';
import Dealers from '@pages/catalogs/dealers';

import MediosComunicaciones from '@pages/catalogs/medios-comunicaciones';

import CustomersClasifications from '@pages/catalogs/customers-clasifications';

import TiposRecibos from '@pages/catalogs/tipos-recibos';

import TiposFacturas from '@pages/catalogs/tipos-facturas';

// Levantamientos

import Levantamientos from '@pages/clientes/levantamientos';
import NuevoLevantamiento from '@pages/clientes/levantamientos/Nuevo';
import AreasLevantamiento from '@pages/clientes/levantamientos/Areas';
import ZonasLevantamiento from '@pages/clientes/levantamientos/Zonas';
import EquiposZonasLevantamiento from '@pages/clientes/levantamientos/EquiposZonas';
import EditarLevantamiento from '@pages/clientes/levantamientos/Editar';
import GenerarCotizacionLevantamiento from '@pages/clientes/levantamientos/GenerarCotizacion';

// Cotizaciones

import Cotizaciones from '@pages/clientes/cotizaciones';
import CotizacionesDetalle from '@pages/clientes/cotizaciones/detalle';
import NuevaCotizacion from '@pages/clientes/cotizaciones/New';
// const NuevaCotizacionRapida = () =>
//   LazyLoad(
//     () => import('@pages/clientes/cotizaciones/FastNew').catch(() => window.location.reload())
//   );
import EditarCotizacion from '@pages/clientes/cotizaciones/Edit';
import ConvertirCotizacion from '@pages/clientes/cotizaciones/ConvertirCotizacion';

// Recibos

import Recibos from '@pages/clientes/recibos';
import RecibosDetalle from '@pages/clientes/recibos/detalle';
import NuevoRecibo from '@pages/clientes/recibos/New';
import EditarRecibo from '@pages/clientes/recibos/Edit';

import Compras from '@pages/compras/compras';
import NuevaCompra from '@pages/compras/compras/New';
import EditarCompra from '@pages/compras/compras/Edit';
import DetalleCompra from '@pages/compras/compras/detail';
import SolicitudesCompras from '@pages/compras/solicitudes-compras';
import NuevaSolicitudCompra from '@pages/compras/solicitudes-compras/New';
import EditarSolicitudCompra from '@pages/compras/solicitudes-compras/Edit';
import DetalleSolicitudCompra from '@pages/compras/solicitudes-compras/detail';
import TiposCompras from '@pages/compras/tipos-compras';

import Preconciliaciones from '@pages/clientes/preconciliaciones';
import NewPreconciliacion from '@pages/clientes/preconciliaciones/New';
import DetallePreconciliacion from '@pages/clientes/preconciliaciones/detail';
import DetalleConciliacionClientes from '@pages/clientes/preconciliaciones/detail-clientes/';
import DetalleConciliacionPendientes from '@pages/clientes/preconciliaciones/depositos-pendientes';

import Facturas from '@pages/clientes/facturas';
import DetalleFactura from '@pages/clientes/facturas/detail';
import ImportarFactura from '@pages/clientes/facturas/import';
import ImportarFacturaArchivo from '@pages/clientes/facturas/import-archivo';
import ImportarFacturaFaltantes from '@pages/clientes/facturas/importar-faltantes';

import NotasCredito from '@pages/clientes/notas_creditos';
import DetalleNotaCredito from '@pages/clientes/notas_creditos/detail';
import ImportarNotasCreditos from '@pages/clientes/notas_creditos/import';

import CarteraVencida from '@pages/clientes/cartera-vencida';
import ImportarCarteraVencida from '@pages/clientes/cartera-vencida/Importar';

import EstadosCuentas from '@pages/clientes/estados-cuentas';
import ImportarEstadosCuentas from '@pages/clientes/estados-cuentas/Importar';

import Asignaciones from '@pages/clientes/asignaciones';
import ImportarAsignaciones from '@pages/clientes/asignaciones/Importar';
import DetalleAsignaciones from '@pages/clientes/asignaciones/detalle';

import Seguimientos from '@pages/clientes/seguimientos';
import Seguimiento from '@pages/clientes/seguimientos/Seguimiento';
import AgendaSeguimiento from '@pages/clientes/agenda-seguimientos';
import AgendaVisitas from '@pages/clientes/agenda-visitas';

import Pagos from '@pages/clientes/pagos';
import DetallePago from '@pages/clientes/pagos/detail';
import ImportarPagos from '@pages/clientes/pagos/import';

import ListProspecto from '@pages/catalogs/prospectos/list';
import DetailProspecto from '@pages/catalogs/prospectos/detail';

import RegisterKitServicios from '@pages/kit-servicios/register';
import EditKitServicios from '@pages/kit-servicios/edit';
import KitServicios from '@pages/kit-servicios/list/KitServicios';

import RegisterMantenimientos from '@pages/mantenimientos/mantenimiento/register';
import EditMantenimientos from '@pages/mantenimientos/mantenimiento/edit';
import Mantenimientos from '@pages/mantenimientos/mantenimiento/list/Mantenimientos';

import RegisterPlanesMantenimientos from '@pages/planes-mantenimientos/planes/register';
import EditPlanesMantenimientos from '@pages/planes-mantenimientos/planes/edit';
import PlanesMantenimientos from '@pages/planes-mantenimientos/planes/list/PlanesMantenimientos';

import TiposPedidos from '@pages/catalogs/tipos_pedidos';

import Tareas from '@pages/application/tareas';

import Negociaciones from '@pages/clientes/negociaciones';
import Kpis from '@pages/application/kpis';
import Mensajes from '@pages/application/mensajes';
import Notificaciones from '@pages/application/notificaciones';

import Departamentos from '@pages/nomina/departamentos';
import Puestos from '@pages/nomina/puestos';
import Empleados from '@pages/nomina/empleados';
import TiposNominas from '@pages/nomina/tiposnominas';
import MatrizVacaciones from '@pages/nomina/matrizvacaciones';
import GruposEmpleados from '@pages/nomina/grupos_empleados';
import Nomina from '@pages/nomina/nomina';

import ConfiguracionGeneral from '@pages/application/configuraciones/generales';
import EstructuraCuenta from '@pages/application/configuraciones/generales/estructura-cuenta';

import ActivityUser from '@pages/application/users/ActivityUser';
import Indicadores from '@pages/reportes/indicadores';
import HorasDeTrabajo from '@pages/reportes/workforces';
import Tarjetas from '@pages/catalogs/tarjetas/index.js';
import GeneradorReferencias from '@pages/clientes/generador-referencias';

export const RoutesApp = (
  <Routes>
    <Route element={<App />}>
      <Route path='/' element={<PublicRoute />}>
        <Route path='login' element={<Login />} />
        <Route path='firmarCotizacion/:hash' element={<FirmarCotizacion />} />
        <Route path='firmarContrato/:hash' element={<FirmarContratoEquipo />} />
      </Route>
      <Route path='/' element={<PrivateRoute />}>
        <Route index element={<Dashboard />} />
        {/* INICIO */}
        <Route path='dashboard' element={<Dashboard />} />
        <Route onEnter={requireAuth} path='tareas' element={<Tareas />} />
        <Route onEnter={requireAuth} path='negociaciones' element={<Negociaciones />} />
        <Route path='kpis' element={<Kpis />} />
        <Route onEnter={requireAuth} path='mensajes' element={<Mensajes />} />
        <Route onEnter={requireAuth} path='notificaciones' element={<Notificaciones />} />

        {/* CONFIGURACION */}
        <Route onEnter={requireAuth} path='modulos' element={<Modules />} />
        <Route onEnter={requireAuth} path='aplicacion' element={<ApplicationData />} />
        <Route onEnter={requireAuth} path='files' element={<Files />} />
        <Route onEnter={requireAuth} path='excepciones' element={<Exceptions />} />
        <Route onEnter={requireAuth} path='usuarios' element={<Users />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'listado-actividades')}
          path='usuarios/actividad/:listadoID'
          element={<ActivityUser />}
        />

        {/* CARTERA */}
        <Route path='conciliaciones'>
          <Route index onEnter={requireAuth} element={<Preconciliaciones />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='conciliar'
            element={<NewPreconciliacion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:conciliacionId'
            element={<DetallePreconciliacion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detallado-clientes')}
            path='detallado-clientes/:conciliacionId'
            element={<DetalleConciliacionClientes />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'pendientes')}
            path='pendientes/:conciliacionId'
            element={<DetalleConciliacionPendientes />}
          />
        </Route>
        <Route path='facturas'>
          <Route index onEnter={requireAuth} element={<Facturas />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'facturacion')}
            path='facturacion'
            element={<NewPreconciliacion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:facturaId'
            element={<DetalleFactura />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportarFactura />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar-archivo'
            element={<ImportarFacturaArchivo />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar-faltantes'
            element={<ImportarFacturaFaltantes />}
          />
        </Route>
        <Route path='notas-creditos'>
          <Route index onEnter={requireAuth} element={<NotasCredito />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:notaCreditoId'
            element={<DetalleNotaCredito />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportarNotasCreditos />}
          />
        </Route>
        <Route path='pagos'>
          <Route index onEnter={requireAuth} element={<Pagos />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:pagoId'
            element={<DetallePago />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportarPagos />}
          />
        </Route>
        <Route path='seguimientos'>
          <Route index onEnter={requireAuth} element={<Seguimientos />} />
          <Route path='editar/:seguimientoId' element={<Seguimientos />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'seguimiento')}
            path='seguimiento/:clienteId'
            element={<Seguimiento />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'seguimiento-prospecto')}
            path='seguimiento-prospecto/:prospectoId'
            element={<SeguimientoProspectos />}
          />
        </Route>
        <Route onEnter={requireAuth} path='agenda-seguimientos' element={<AgendaSeguimiento />} />
        <Route onEnter={requireAuth} path='agenda-visitas' element={<AgendaVisitas />} />

        <Route path='estados-cuentas'>
          <Route index onEnter={requireAuth} element={<EstadosCuentas />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportarEstadosCuentas />}
          />
        </Route>
        <Route path='cartera-vencida'>
          <Route index onEnter={requireAuth} element={<CarteraVencida />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:facturaId'
            element={<DetalleFactura />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportarCarteraVencida />}
          />
        </Route>

        <Route path='asignaciones'>
          <Route index onEnter={requireAuth} element={<Asignaciones />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle-agente')}
            path='detalle/:agenteId'
            element={<DetalleAsignaciones />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportarAsignaciones />}
          />
        </Route>

        <Route onEnter={requireAuth} path='motivos-visitas' element={<Motivos />} />
        <Route onEnter={requireAuth} path='resultados-visitas' element={<ResultadosVisitas />} />

        {/* CLIENTES */}
        <Route path='levantamientos'>
          <Route index onEnter={requireAuth} element={<Levantamientos />} />
          <Route exact path='nuevo' element={<NuevoLevantamiento />} />
          <Route exact path=':levantamientoId/areas' element={<AreasLevantamiento />} />
          <Route exact path=':levantamientoId/areas/:areaId' element={<ZonasLevantamiento />} />
          <Route exact path=':levantamientoId/areas/:areaId/zonas/:zonaId' element={<EquiposZonasLevantamiento />} />
          <Route exact path='edicion/:levantamientoId' element={<EditarLevantamiento />} />
          <Route exact path='cotizacion/:levantamientoId' element={<GenerarCotizacionLevantamiento />} />
        </Route>

        <Route path='cotizaciones'>
          <Route index onEnter={requireAuth} element={<Cotizaciones />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            exact
            path='nuevo/:tipoCliente/:customerId'
            element={<NuevaCotizacion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            exact
            path='nuevo'
            element={<NuevaCotizacion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:levantamientoId'
            element={<EditarCotizacion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:quotationId'
            element={<CotizacionesDetalle />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='convertir/:levantamientoId'
            element={<ConvertirCotizacion />}
          />
        </Route>

        <Route path='recibos'>
          <Route index onEnter={requireAuth} element={<Recibos />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            exact
            path='nuevo/:tipoCliente/:customerId'
            element={<NuevoRecibo />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            exact
            path='nuevo'
            element={<NuevoRecibo />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:reciboId'
            element={<EditarRecibo />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path=':quotationId'
            element={<RecibosDetalle />}
          />
        </Route>
        <Route path='clientes-registros'>
          <Route index onEnter={requireAuth} element={<ListCustomerRegistros />} />
        </Route>
        <Route path='clientes'>
          <Route index onEnter={requireAuth} element={<ListCustomer />} />
          {/* <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='registro'
          element={<AddCustomer />}
        /> */}
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='editar/:customerId'
            element={<EditCustomer />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'ubicaciones-contactos')}
            path='ubicaciones/:customerId/contactos'
            element={<Ubicaciones />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportClientes />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:customerId'
            element={<DetailCustomer />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle-contratos')}
            path='detalle/:customerId/contrato/:documentoId'
            element={<ContractByCustomer />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle-cotizacion')}
            path='detalle/:customerId/cotizacion/:quotationId'
            element={<QuotationByCustomer />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='unificar'
            element={<UnificarClientes />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path=':customerId/editar-contrato/:adjuntoId'
            element={<EditarContrato />}
          />
        </Route>

        <Route path='prospectos'>
          <Route index onEnter={requireAuth} element={<ListProspecto />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:prospectoId'
            element={<DetailProspecto />}
          />
        </Route>

        <Route path='agendas-monitoreos'>
          <Route index onEnter={requireAuth} element={<ListAgendaMonitoreo />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='crear'
            element={<AddAgendaMonitoreo />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='editar/:agenda_monitoreo_Id'
            element={<UptAgendaMonitoreo />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportarAgendaMonitoreo />}
          />
        </Route>

        <Route onEnter={requireAuth} path='domicilios' element={<Domicilios />} />
        <Route onEnter={requireAuth} path='contactos' element={<Contactos />} />
        <Route onEnter={requireAuth} path='contactos/clientes/:contactoId' element={<ContactosClientes />} />

        <Route path='clientes-saldos' element={<ClientesSaldos />} />

        <Route onEnter={requireAuth} path='ruleta-asesores' element={<RuletaAsesores />} />

        {/* TRANSACCIONES */}
        <Route onEnter={requireAuth} path='movimientos' element={<Transations />} />
        <Route onEnter={requireAuth} path='referencias' element={<References />} />

        {/* COMPRAS */}

        <Route onEnter={requireAuth} path='tipos-compras' element={<TiposCompras />} />

        <Route path='compras'>
          <Route index onEnter={requireAuth} element={<Compras />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            exact
            path='nueva'
            element={<NuevaCompra />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path=':compraId'
            element={<DetalleCompra />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:compraId'
            element={<EditarCompra />}
          />
        </Route>

        <Route path='solicitudes-compras'>
          <Route index onEnter={requireAuth} element={<SolicitudesCompras />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nueva'
            element={<NuevaSolicitudCompra />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:solicitudCompraId'
            element={<DetalleSolicitudCompra />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:solicitudCompraId'
            element={<EditarSolicitudCompra />}
          />
        </Route>

        <Route path='recepciones-compras'>
          <Route index onEnter={requireAuth} element={<RecepcionCompras />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NuevaRecepcion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:recepcionCompraId'
            element={<EditarRecepcion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path=':recepcionCompraId'
            element={<DetalleRecepcion />}
          />
        </Route>

        {/* ALMACEN */}

        <Route onEnter={requireAuth} path='almacen' element={<StoreEquipments />} />
        <Route onEnter={requireAuth} path='stock-carros' element={<StoreJhonnyCars />} />

        <Route path='surtido-carros'>
          <Route index onEnter={requireAuth} element={<SurtidoCarros />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'surtir')}
            path='surtir'
            element={<SurtirCarro />}
          />
        </Route>

        {/* INVENTARIO */}

        <Route onEnter={requireAuth} path='ubicaciones' element={<UbicacionesEquipos />} />
        <Route onEnter={requireAuth} path='marcas' element={<Marcas />} />
        <Route onEnter={requireAuth} path='equipments-characteristics' element={<EquipmentsCharacteristics />} />
        <Route onEnter={requireAuth} path='fichas-tecnicas' element={<FichasTecnicas />} />
        <Route onEnter={requireAuth} path='unidades-medidas' element={<UnitMeasures />} />
        <Route onEnter={requireAuth} path='listas-precios' element={<ListasPrecios />} />

        <Route onEnter={requireAuth} path='precios-equipos' element={<PreciosEquipos />} />

        <Route path='equipos'>
          <Route index onEnter={requireAuth} element={<Equipments />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NewEquipment />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportEquipment />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:equipmentId'
            element={<DetailEquipment />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:equipmentId'
            element={<EditEquipments />}
          />
          {/* <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/precios/:equipmentId'
          element={<EditPreciosEquipments />}
        /> */}
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='rendimientos'
            element={<RendimientosEquipment />}
          />
        </Route>

        <Route path='servicios'>
          <Route index onEnter={requireAuth} element={<Services />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NewService />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:serviceId'
            element={<DetailService />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:serviceId'
            element={<EditServices />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportServices />}
          />
        </Route>

        <Route path='kits-comerciales'>
          <Route index onEnter={requireAuth} element={<KitsComerciales />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NewKitsComerciales />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:kitId'
            element={<EditKitsComerciales />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportKitsComerciales />}
          />
        </Route>

        <Route path='recetas'>
          <Route index onEnter={requireAuth} element={<Recetas />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NewRecetas />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:recetaId'
            element={<EditRecetas />}
          />
        </Route>

        <Route path='tipos_pedidos'>
          <Route index onEnter={requireAuth} element={<TiposPedidos />} />
        </Route>

        <Route path='clasificadores-cotizaciones'>
          <Route index onEnter={requireAuth} element={<ClasificadoresCotizaciones />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ClasificadoresCotizacionesImportar />}
          />
        </Route>
        <Route path='clasificadores-levantamientos'>
          <Route index onEnter={requireAuth} element={<ClasificadoresLevantamientos />} />
        </Route>

        <Route path='configuraciones'>
          <Route index onEnter={requireAuth} element={<Configurations />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path=':configurationId'
            element={<ConfigurationDetail />}
          />
        </Route>

        <Route path='caracteristicas'>
          <Route index onEnter={requireAuth} element={<Characteristics />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nueva'
            element={<NewCharacteristics />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:characteristicsId'
            element={<DetailCharacteristics />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:characteristicsId'
            element={<EditCharacteristics />}
          />
        </Route>

        <Route path='ordenes-traspasos'>
          <Route index onEnter={requireAuth} element={<Traspasos />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NuevoTraspaso />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:traspasoId'
            element={<DetalleTraspaso />}
          />
        </Route>
        <Route path='ordenes-servicios-visitas'>
          <Route index onEnter={requireAuth} element={<OrdenesServicioVisitas />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'observaciones')}
            path='observaciones/:visitaId'
            element={<ObservacionesOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'observaciones-conclusiones')}
            path='observaciones-conclusiones/:visitaId'
            element={<ObservacionesConclusionesOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'observaciones-comentarios')}
            path='observaciones-comentarios/:visitaId'
            element={<ObservacionesComentariosOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'calificaciones')}
            path='calificaciones/:visitaId'
            element={<CalificacionServicioOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'firma_cliente')}
            path='firma-cliente/:visitaId'
            element={<FirmaClienteServicioOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'firma_agente')}
            path='firma-agente/:visitaId'
            element={<FirmaAgenteOrdenServicio />}
          />
          {/* <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevoOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='editar/:ordenServicioId' element={<EditarOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'observaciones')} path='observaciones/:ordenServicioId' element={<ObservacionesOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'calificacion_servicio')} path='calificacion_servicio/:ordenServicioId' element={<CalificacionServicioOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:ordenServicioId' element={<DetalleOrdenServicio/>} /> */}
        </Route>
        <Route path='ordenes-servicio'>
          <Route index onEnter={requireAuth} element={<OrdenesServicio />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NuevoOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='editar/:ordenServicioId'
            element={<EditarOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'visitas')}
            path='visitas/:ordenServicioId'
            element={<VisitasOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'observaciones')}
            path='observaciones/:ordenServicioId'
            element={<ObservacionesOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'calificacion_servicio')}
            path='calificacion_servicio/:ordenServicioId'
            element={<CalificacionServicioOrdenServicio />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:ordenServicioId'
            element={<DetalleOrdenServicio />}
          />
        </Route>

        <Route path='pedidos'>
          <Route index onEnter={requireAuth} element={<Pedidos />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NuevoPedido />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:pedidoId'
            element={<DetallePedido />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='edicion/:pedidoId'
            element={<EditarPedido />}
          />
        </Route>

        <Route path='conversiones'>
          <Route index onEnter={requireAuth} element={<Conversiones />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NuevoConversion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:conversionId'
            element={<DetalleConversion />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='edicion/:conversionId'
            element={<EditarConversion />}
          />
        </Route>

        <Route path='ordenes-mermas'>
          <Route index onEnter={requireAuth} element={<Mermas />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NuevoMerma />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:mermaId'
            element={<DetalleMerma />}
          />
        </Route>

        <Route path='ajustes'>
          <Route index onEnter={requireAuth} element={<Ajustes />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NuevoAjuste />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
            path='detalle/:ajusteId'
            element={<DetalleAjuste />}
          />
        </Route>

        <Route path='existencias'>
          <Route index onEnter={requireAuth} element={<Existencias />} />
        </Route>

        {/* MANTENIMIENTO */}

        <Route path='planes-mantenimientos'>
          <Route index onEnter={requireAuth} element={<PlanesMantenimientos />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='captura'
            element={<RegisterPlanesMantenimientos />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:planMantenimientoId'
            element={<EditPlanesMantenimientos />}
          />
        </Route>

        <Route path='mantenimientos'>
          <Route index onEnter={requireAuth} element={<Mantenimientos />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='captura'
            element={<RegisterMantenimientos />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:mantenimientoId'
            element={<EditMantenimientos />}
          />
        </Route>

        <Route path='kit-servicios'>
          <Route index onEnter={requireAuth} element={<KitServicios />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='captura'
            element={<RegisterKitServicios />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
            path='edicion/:kitServicioId'
            element={<EditKitServicios />}
          />
        </Route>

        <Route onEnter={requireAuth} path='maquinaria-herramientas' element={<MaquinariaHerramientas />} />
        <Route onEnter={requireAuth} path='servicios-terceros' element={<ServiciosTerceros />} />
        <Route onEnter={requireAuth} path='controles-mantenimientos' element={<ControlesMantenimientos />} />
        <Route onEnter={requireAuth} path='tipos-piezas' element={<TiposPiezas />} />
        <Route onEnter={requireAuth} path='piezas' element={<Piezas />} />
        <Route onEnter={requireAuth} path='fabricantes' element={<Fabricantes />} />
        <Route onEnter={requireAuth} path='esquemas-pago' element={<EsquemasPago />} />

        {/* VENDEDORES */}

        <Route onEnter={requireAuth} path='vendedores' element={<Sellers />} />

        {/* CATALOGOS */}

        <Route path='agentes'>
          <Route index onEnter={requireAuth} element={<Agentes />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'clientes')}
            path='clientes/:agenteId'
            element={<AgentesClientes />}
          />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'prospectos')}
            path='prospectos/:agenteId'
            element={<AgentesProspectos />}
          />
        </Route>

        <Route onEnter={requireAuth} path='tarjetas' element={<Tarjetas />} />
        <Route onEnter={requireAuth} path='generador-referencias' element={<GeneradorReferencias />} />
        <Route onEnter={requireAuth} path='tipos-servicios' element={<TipoServicios />} />

        <Route onEnter={requireAuth} path='plazos' element={<Plazos />} />
        <Route onEnter={requireAuth} path='anticipos' element={<Anticipos />} />

        <Route onEnter={requireAuth} path='titulos' element={<Titulos />} />

        <Route onEnter={requireAuth} path='tipo-cambio' element={<TipoCambios />} />
        <Route onEnter={requireAuth} path='bancos' element={<Bancos />} />
        <Route onEnter={requireAuth} path='cuentas-bancarias' element={<CuentasBancarias />} />

        <Route onEnter={requireAuth} path='acreedores' element={<Acreedores />} />

        <Route onEnter={requireAuth} path='documentos' element={<Documentos />} />

        <Route onEnter={requireAuth} path='condiciones-ventas' element={<CondicionesVentas />} />
        <Route onEnter={requireAuth} path='condiciones-instalacion' element={<CondicionesInstalaciones />} />
        <Route onEnter={requireAuth} path='condiciones-garantia' element={<CondicionesGarantias />} />
        <Route onEnter={requireAuth} path='aviso-privacidad' element={<AvisoPrivacidad />} />
        <Route onEnter={requireAuth} path='clasificacion-proveedores' element={<ClasificadoresProveedores />} />
        <Route onEnter={requireAuth} path='condiciones-contratos' element={<CondicionesContratos />} />

        <Route onEnter={requireAuth} path='descuentos-pagos' element={<DescuentosPagos />} />
        <Route onEnter={requireAuth} path='estados' element={<Estados />} />
        <Route onEnter={requireAuth} path='paises' element={<Paises />} />
        <Route path='colonias'>
          <Route index onEnter={requireAuth} element={<Colonias />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='importar'
            element={<ImportarColonias />}
          />
        </Route>
        <Route path='municipios'>
          <Route index onEnter={requireAuth} element={<Municipios />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='importar'
            element={<ImportarMunicipios />}
          />
        </Route>

        <Route onEnter={requireAuth} path='equipos-seguridad-electronica' element={<EquiposSeguridadElectronica />} />
        <Route onEnter={requireAuth} path='condiciones-pagos-equipos' element={<CondicionesPagoEquipos />} />
        <Route onEnter={requireAuth} path='tipos-cableados' element={<TipoCableados />} />
        <Route onEnter={requireAuth} path='tipos-recibos' element={<TiposRecibos />} />
        <Route onEnter={requireAuth} path='tipos_facturas' element={<TiposFacturas />} />
        <Route onEnter={requireAuth} path='formas-pago-equipos' element={<FormaPago />} />

        <Route onEnter={requireAuth} path='condiciones-pagos-servicios' element={<CondicionesPagoServicios />} />
        <Route onEnter={requireAuth} path='tipos-monitoreos' element={<TiposMonitoreos />} />

        <Route onEnter={requireAuth} path='cuentas-monitoreos'>
          <Route index onEnter={requireAuth} element={<CuentasMonitoreos />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
            path='importar'
            element={<ImportarCuentasMonitoreos />}
          />
        </Route>

        <Route onEnter={requireAuth} path='tipos-levantamientos' element={<TiposLevantamientos />} />
        <Route onEnter={requireAuth} path='tipos-ordenes-servicios' element={<TiposOrdenesServicios />} />
        <Route onEnter={requireAuth} path='configuraciones-carrusel' element={<ConfiguracionesCarrusel />} />
        <Route onEnter={requireAuth} path='documentos-empleados' element={<DocumentosEmpleados />} />

        <Route onEnter={requireAuth} path='tipos-contactos' element={<TiposContactos />} />
        <Route onEnter={requireAuth} path='proveedores' element={<Providers />} />

        <Route onEnter={requireAuth} path='tipo-establecimientos'>
          <Route index onEnter={requireAuth} element={<TipoEstablecimientos />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'rubros')}
            path=':establecimientoId/rubros'
            element={<Rubros />}
          />
        </Route>

        <Route path='productos'>
          <Route index onEnter={requireAuth} element={<Products />} />
          <Route
            onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
            path='nuevo'
            element={<NewProduct />}
          />
        </Route>

        <Route onEnter={requireAuth} path='almacenes' element={<StoreHouse />} />
        <Route onEnter={requireAuth} path='sucursales' element={<Sucursales />} />
        <Route onEnter={requireAuth} path='soluciones_solicitadas' element={<Soluciones_Solicitadas />} />
        <Route onEnter={requireAuth} path='tipos_servicios_solicitados' element={<Tipos_Servicios_Solicitados />} />
        <Route onEnter={requireAuth} path='tipos_mano_obra' element={<Tipos_Mano_Obra />} />
        <Route onEnter={requireAuth} path='tipos_cobro_equipo' element={<Tipos_Cobro_Equipo />} />
        <Route onEnter={requireAuth} path='dealers' element={<Dealers />} />

        <Route onEnter={requireAuth} path='medios-comunicaciones' element={<MediosComunicaciones />} />
        <Route onEnter={requireAuth} path='clientes-clasificaciones' element={<CustomersClasifications />} />

        {/* REPORTES */}
        <Route onEnter={requireAuth} path='indicadores' element={<Indicadores />} />
        <Route onEnter={requireAuth} path='reporte-horas-trabajo' element={<HorasDeTrabajo />} />

        {/* GENERALES */}

        <Route onEnter={requireAuth} path='general' element={<ConfiguracionGeneral />} />
        <Route onEnter={requireAuth} path='estructura_cuenta' element={<EstructuraCuenta />} />

        {/* NOMINA */}
        <Route onEnter={requireAuth} path='puestos' element={<Puestos />} />
        <Route onEnter={requireAuth} path='departamentos' element={<Departamentos />} />
        <Route onEnter={requireAuth} path='empleados' element={<Empleados />} />
        <Route onEnter={requireAuth} path='tiposnominas' element={<TiposNominas />} />
        <Route onEnter={requireAuth} path='nomina' element={<Nomina />} />
        <Route onEnter={requireAuth} path='grupos-empleados' element={<GruposEmpleados />} />
        <Route onEnter={requireAuth} path='matrizvacaciones' element={<MatrizVacaciones />} />

        {/* GENERALES */}
        <Route path='logout' element={<Logout />} />
        <Route path='sin-autorizacion' element={<Forbidden />} />
        <Route path='*' element={<PageNotFound />} />
      </Route>
    </Route>
  </Routes>
);
