import { PAGADA, PAGADA_MANUAL } from '@config/constants';
import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';

const RowFactura = ({ factura }) => {
  const switchEstado = (estado) => {
    const estados = {
      PENDIENTE: { text: 'Pendiente de pago', color: 'secondary' },
      PAGADA: { text: 'Pagada', color: 'success' },
      PAGADA_MANUAL: { text: 'Pago manual', color: 'success' },
      ABONADA: { text: 'Abonada', color: 'warning' },
      CANCELADA: { text: 'Cancelada', color: 'danger' },
    };

    const { text = '', color = 'secondary' } = estados[estado] || {};
    return <CbBadge type={color}>{text}</CbBadge>;
  };

  const { _id, serie, numero, uuid, importe, saldo, estado, fecha_pago } = factura;

  return (
    <tr>
      <td>{serie}</td>
      <td>{numero}</td>
      <td>{uuid}</td>
      <td>${format(importe, 2)}</td>
      <td>${format(saldo, 2)}</td>
      <td>
        {switchEstado(estado)}
        {(estado === PAGADA || estado === PAGADA_MANUAL) && (
          <span>
            <br />
            <small>
              <em>{moment(fecha_pago).local().format('DD/MM/YYYY HH:mm')}</em>
            </small>
          </span>
        )}
      </td>
      <td>
        <RedirectTooltip
          id={1}
          icon='search'
          url={`/facturas/detalle/${_id}`}
          labelTooltip='Completa'
          className='btn btn-sm btn-primary pull-right'
        />
      </td>
    </tr>
  );
};

RowFactura.propTypes = {
  factura: PropTypes.object.isRequired,
};

export default RowFactura;
