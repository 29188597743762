import React from 'react';
import PropTypes from 'prop-types';
import { isURL } from 'validator';
import { urlPhoto } from '@utils/api';
import { TextCurrency } from '@controls';
import { MONEDA } from '@config/constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CbMoneda from '@cbcomponents/CbMoneda';

const ItemBuscadorProducto = ({ x, onClickEquipo, monedaId, tipoCambio }) => {
  let precio = x.precio;
  let precio_original = precio;
  let moneda = x.moneda;
  if (monedaId !== x.moneda) {
    if (monedaId === MONEDA.PESO && x.moneda === MONEDA.DOLAR) {
      precio = precio_original * tipoCambio;
    } else if (monedaId === MONEDA.DOLAR && x.moneda === MONEDA.PESO) {
      precio = precio_original / tipoCambio;
    }
    moneda = monedaId;
  }
  return (
    <>
      <div className='col-xs-6 col-sm-3 col-md-3' key={x._id}>
        <div className='card' style={{ width: '100%', border: '1px solid #e0e0e0', borderRadius: '8px', overflow: 'hidden', height: '415px' }}>
          <div style={{ position: 'relative' }}>
            <img
              src={
                x.imagen.thumbnail
                  ? isURL(x.imagen.thumbnail)
                    ? x.imagen.thumbnail
                    : urlPhoto() + x.imagen.thumbnail
                  : 'https://ftp3.syscom.mx/usuarios/fotos/imagen_no_disponible.jpg'
              }
              alt={x.name}
              style={{ width: '100%', height: '200px', objectFit: 'cover' }}
            />
            <span
              style={{
                position: 'absolute',
                bottom: '10px',
                left: '10px',
                backgroundColor: '#e7f3ff',
                color: '#0066cc',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '12px',
              }}
            >
              C / {x.equipmentsMeasureId?.measure}
            </span>
          </div>
          <div style={{ padding: '16px' }}>
            <h3 style={{ margin: '0 0 8px 0', fontSize: '16px' }}>{x.code}</h3>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <OverlayTrigger placement='top' overlay={<Tooltip>{x.name}</Tooltip>}>
                <span style={{ marginLeft: '4px', fontSize: '14px', maxHeight: '20px', overflow: 'hidden' }}>{x.name}</span>
              </OverlayTrigger>
            </div>
            <div style={{ display: 'flex', gap: '8px', marginBottom: '8px' }}>
              <span style={{ fontSize: '12px', color: '#666', backgroundColor: '#f0f0f0', padding: '2px 6px', borderRadius: '4px' }}>
                {x.marcaId?.nombre}
              </span>
              <span style={{ fontSize: '12px', color: '#666', backgroundColor: '#f0f0f0', padding: '2px 6px', borderRadius: '4px' }}>
                {x.clasificacionCotizacionId?.nombre}
              </span>
            </div>
            <p style={{ fontSize: '24px', fontWeight: 'bold', margin: '0 0 16px 0' }}>
              <TextCurrency>{precio}</TextCurrency>
              <CbMoneda>{moneda}</CbMoneda>
            </p>
            <button
              onClick={() => onClickEquipo(x)}
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: '#0066cc',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Agregar
            </button>
          </div>
          {/* <div style={{ padding: '0 16px 16px', fontSize: '14px', color: '#666' }}>
            <p>
              <strong>Clave:</strong> IMAC27M3
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

ItemBuscadorProducto.propTypes = {
  x: PropTypes.object.isRequired,
  onClickEquipo: PropTypes.func.isRequired,
  format: PropTypes.func.isRequired,
  isURL: PropTypes.func.isRequired,
  urlPhoto: PropTypes.func.isRequired,
  monedaId: PropTypes.string.isRequired,
  tipoCambio: PropTypes.number.isRequired,
};

export default ItemBuscadorProducto;
