import React, { useState, useRef } from 'react';
import { Button, Col, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';

const Import = () => {
  const [state, setState] = useState({
    isLoading: false,
    cargando: false,
    csv: null,
    valid: false,
    msg: '',
    progress: 0,
  });

  const frm = useRef(null);

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleReadCSV = async (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      cargando: true,
      progress: 0,
      csv: null,
      isLoading: true,
    }));
    const form = new FormData(frm.current);
    const { csv, valid, msg } = await postUploadRequest({ url: `clasificadores-cotizaciones/importar-csv`, form });
    setState((prevState) => ({ ...prevState, csv, valid, msg, cargando: false, progress: 0, isLoading: false }));
  };

  const renderDataCSV = () => {
    const {
      csv: { detalle, headers },
      valid,
      msg,
    } = state;

    return (
      <Row>
        {!valid && (
          <div className='alert alert-danger'>
            <p>{msg}</p>
          </div>
        )}
        <Col style={{ position: 'relative', overflowX: 'auto', width: '100%' }}>
          <table className='table table-condensed table-bordered'>
            <thead>
              <tr>
                <th>
                  <strong>Acción</strong>
                </th>
                {headers.map((e, i) => (
                  <th key={i}>
                    <strong>{e}</strong>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {detalle.length > 0 ? (
                detalle.map((d, i) => {
                  let datoCreacion = 3;
                  return (
                    <tr key={i} className={d[datoCreacion] === true ? 'success' : d[datoCreacion] === false ? 'info' : ''}>
                      <td>{d[datoCreacion] === true ? 'Creación' : d[datoCreacion] === false ? 'Actualización' : 'Ninguna'}</td>
                      {headers.map((e, j) => (
                        <td key={j}>{d[j]}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <RowsNotFound colSpan={7} />
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const { cargando, isLoading, csv, progress } = state;

  return (
    <Module onClickBack={window.history.back} title='Importar clasificadores'>
      <div className='panel panel-default'>
        <div className='panel-body'>
          <form ref={frm} onSubmit={handleReadCSV} encType='multipart/form-data'>
            <Row>
              <Col className=' text-center'>
                <div className='btn btn-default btn-file mr-5'>
                  <Icon icon='paperclip' /> Archivo
                  <input type='file' name='csv' id='csv' accept='.csv' onChange={onChange} />
                </div>
                <Button type='submit' className='btn btn-success' disabled={cargando}>
                  <Icon icon='import' /> Importar
                </Button>
                {isLoading ? <Saving action='Importantando' /> : ''}
              </Col>
              <Col>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
            </Row>
          </form>
          <form>{csv !== null ? renderDataCSV() : null}</form>
        </div>
      </div>
    </Module>
  );
};

Import.propTypes = {};

export default Import;
