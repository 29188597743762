import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [nombre, setNombre] = useState('');
  const [rfc, setRfc] = useState('');
  const [codigo, setCodigo] = useState('');
  const [codigoBancoSat, setCodigoBancoSat] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nombre') {
      setNombre(value);
    }
    if (name === 'rfc') {
      setRfc(value);
    }
    if (name === 'codigo') {
      setCodigo(value);
    }
    if (name === 'codigoBancoSat') {
      setCodigoBancoSat(value);
    }
  };
  const handleRegister = async (event) => {
    event.preventDefault();
    const data = { nombre, rfc, codigo, codigoBancoSat };

    if (validations(data)) {
      await postRequest({ url: 'bancos', body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.rfc + '')) {
      validate.success = false;
      validate.message = 'RFC es requerido.';
    } else if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.codigoBancoSat + '')) {
      validate.success = false;
      validate.message = 'Código Banco SAT es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='codigo' title='Código' required>
              <input type='text' id='codigo' name='codigo' className='form-control' onChange={onChange} value={codigo} placeholder='Codigo' />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='rfc' title='RFC' required>
              <input type='text' id='rfc' name='rfc' className='form-control' onChange={onChange} value={rfc} placeholder='RFC' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='nombre' title='Nombre' required>
              <input type='text' id='nombre' name='nombre' className='form-control' onChange={onChange} value={nombre} placeholder='Nombre' />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='codigoBancoSat' title='Código Banco SAT' required>
              <input
                type='text'
                id='codigoBancoSat'
                name='codigoBancoSat'
                className='form-control'
                onChange={onChange}
                value={codigoBancoSat}
                placeholder='Código Banco SAT'
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  nombre: PropTypes.string.isRequired,
  rfc: PropTypes.string.isRequired,
  codigo: PropTypes.string.isRequired,
  codigoBancoSat: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default New;
