import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const RemoverClientesContacto = ({ contactoId, clienteId, nombre, loadData }) => {
  return (
    <Confirmation
      btnIcon='remove'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`contacto-cliente/${contactoId}/${clienteId}`}
      description={`¿Confirma que desea eliminar el cliente <strong><u> ${nombre}</u> </strong>?`}
      loadData={loadData}
    />
  );
};

RemoverClientesContacto.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  contactoId: PropTypes.string.isRequired,
  clienteId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default RemoverClientesContacto;
