import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, DropdownItem, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';
import Select from 'react-select';

const DescuentoPago = ({ descuentos, factura, itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();

  const [showModal, setShowModal] = useState(false);
  const [descuento, setDescuento] = useState(null);

  const close = () => {
    setShowModal(false);
    setDescuento(null);
  };

  const open = () => {
    setShowModal(true);
    setDescuento(factura.descuento_pago ? factura.descuento_pago.descuentoId : null);
  };

  const onChangeDescuento = (event) => {
    const { value } = event;
    setDescuento(value);
  };

  const handleDescontar = async (event) => {
    event.preventDefault();

    const body = {
      descuento,
      facturaId: itemId,
    };

    if (validations(body)) {
      await putRequest({ url: `descuento-pago-factura/${itemId}`, body });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.facturaId + '')) {
      validate.success = false;
      validate.message = 'Factura es requerida.';
    }
    if (validate.success && (!params.descuento || isEmpty(params.descuento + ''))) {
      validate.success = false;
      validate.message = 'Descuento es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='save-file' /> Aplicar descuento
      </DropdownItem>
      <CbModal title='Confirmación' show={showModal} onClose={close} onSave={handleDescontar}>
        <p className='lead'>
          <Icon icon='warning-sign' />
          ¿Confirma que desea hacer descuento a la factura
          <strong>
            #{factura.serie}
            {factura.numero}
          </strong>
          ?
        </p>
        <Row>
          <Col>
            <DivFormGroup name='descuento' title='Descuento a aplicar'>
              <Select
                options={descuentos}
                value={descuento}
                onChange={onChangeDescuento}
                getOptionLabel={(x) => x.description}
                getOptionValue={(x) => x._id}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

DescuentoPago.propTypes = {
  id: PropTypes.number.isRequired,
  descuentos: PropTypes.array.isRequired,
  factura: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default DescuentoPago;
