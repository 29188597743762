import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import { format } from '@utils/parseCost';

const RowContract = ({
  generatePDF,
  customerId,
  contract: {
    _id,
    referenceId: { total },
    created,
  },
}) => {
  const handleClick = (contractId) => {
    generatePDF(contractId);
  };

  return (
    <tr>
      <td>
        <strong>{_id}</strong>
      </td>
      <td>{moment(created).local().format('DD/MM/YYYY HH:mm')}</td>
      <td>
        <strong>$ {format(total)}</strong>
      </td>
      <td>
        <a href='#' onClick={() => handleClick(_id)} className='btn btn-outline-primary btn-sm pull-right mr-5'>
          <Icon icon='file' />
        </a>
        <RedirectTooltip
          id={1}
          labelTooltip='Contrato completo'
          url={`/clientes/detalle/${customerId}/contrato/${_id}`}
          icon='folder-open'
          className='btn btn-outline-success'
        />
      </td>
    </tr>
  );
};

RowContract.propTypes = {
  generatePDF: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired,
};

export default RowContract;
