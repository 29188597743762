import { Input } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import { cleanNumber } from '@utils/formatter';
import InputNumber from '@components/forms/InputNumber';
import SelectUnidadMedida from '@components/forms/SelectUnidadMedida';

const RowDetalle = ({ detalle, i, onChangeDetalle, onClickRemoveDetalle }) => {
  const handleSelectUnidad = (value) => {
    onChangeDetalle({
      ...detalle,
      measureId: value?.value,
      measure: value,
    });
  };

  const handleChangeDetalle = (field, event) => {
    let value = event.target.value;
    if (['cantidad', 'descuento', 'impuesto', 'precio'].includes(field)) {
      value = cleanNumber(value);
    }
    onChangeDetalle({
      ...detalle,
      [field]: value,
    });
  };

  const handleClickRemoveDetalle = (index, event) => {
    event.preventDefault();
    onClickRemoveDetalle();
  };

  return (
    <tr>
      <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.noIdentificador}</th>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <Input className='input-sm' value={detalle.descripcion} style={{ width: '250px' }} />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        {detalle.tipo_partida === 'EQUIPO' ? (
          detalle.measure
        ) : (
          <SelectUnidadMedida
            name={`measure-${i}`}
            value={detalle.measureId}
            options={detalle.measures}
            onChange={(value) => handleSelectUnidad(value)}
          />
        )}
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
        <InputNumber
          className='input-sm'
          style={{ width: '80px' }}
          value={detalle.cantidad}
          onChange={(event) => handleChangeDetalle('cantidad', event)}
        />
      </td>
      <td>
        <a href='#' onClick={(event) => handleClickRemoveDetalle(event)} style={{ color: 'red' }}>
          <Icon icon='remove' />
        </a>
      </td>
    </tr>
  );
};

RowDetalle.propTypes = {
  detalle: PropTypes.object,
  i: PropTypes.number,
  onSelectUnidad: PropTypes.func,
  onChangeDetalle: PropTypes.func,
  onClickRemoveDetalle: PropTypes.func,
};

export default RowDetalle;
