import * as SECRET from '@config/apiKey';
import jwt from 'jwt-simple';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { Button, Col, DropdownItem, Modal, Row } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';
import { Icon, Saving } from '@stateless';
import { BASE_URL, postUploadRequest, urlPhoto } from '@utils/api';
import { useStoreNotification } from '@stores/catalogs.store';

const FirmaCliente = (props) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading] = useState(false);
  const canvasRef = useRef(null);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    const { firma } = props;
    setShowModal(true);
    if (firma) {
      setTimeout(() => {
        const canvas = canvasRef.current.getCanvas();
        const context = canvas.getContext('2d');
        const base_image = new Image();
        base_image.crossOrigin = 'Anonymous';
        base_image.src = urlPhoto() + firma.photo;
        base_image.onload = () => {
          context.drawImage(base_image, 0, 0);
        };
      }, 100);
    }
  };

  const handleLimpiar = () => {
    canvasRef.current.clear();
  };

  const handleFirmaCliente = async (event) => {
    event.preventDefault();
    const { levantamientoId } = props;
    const blob = DataURIToBlob(canvasRef.current.toDataURL('image/png'));
    const form = new FormData();
    form.append('image', blob, 'firma.png');
    await postUploadRequest({ url: `cotizaciones/firma_cliente/${levantamientoId}`, form });
    setShowModal(false);
    props.loadData();
  };

  const handleCopiar = (e) => {
    e.preventDefault();
    const { levantamientoId } = props;
    const cotizacionId = jwt.encode(levantamientoId, SECRET.API_KEY);
    copyToClipboard(BASE_URL + `firmarCotizacion/${cotizacionId}`);
    notification({
      message: '¡Url copiada con éxito!',
      type: 'success',
    });
  };

  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) navigator.clipboard.writeText(str);
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='pencil' /> Firma cliente
      </DropdownItem>
      <Modal size='xl' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Firma del cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div style={{ border: '1px solid black', display: 'inline-block', textAlign: 'center' }}>
                <SignatureCanvas
                  ref={canvasRef}
                  penColor='black'
                  canvasProps={{ width: 300, height: 150, className: 'sigCanvas', margin: '0 auto' }}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button className='btn btn-primary pull-right mr-5' onClick={handleFirmaCliente} disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} className='btn btn-default pull-right mr-5' type='button'>
            Cerrar
          </Button>
          <Button className='btn btn-danger pull-right mr-5' onClick={handleLimpiar} disabled={isLoading}>
            Limpiar
          </Button>
          <Button className='btn btn-warning pull-right mr-5' onClick={handleCopiar} disabled={isLoading}>
            Url para firma
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FirmaCliente.propTypes = {
  id: PropTypes.string.isRequired,
  levantamientoId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  almacenId: PropTypes.string.isRequired,
  sucursalId: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  maximo_descuento: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  loadData: PropTypes.func.isRequired,
  sucursales: PropTypes.array.isRequired,
  firma: PropTypes.string,
  almacenes: PropTypes.array.isRequired,
};

export default FirmaCliente;
