import { DivFormGroup, Input, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';

const NewRecipes = ({ configurationId, refresh }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [recipe, setRecipe] = useState('');
  const [note, setNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setRecipe('');
    setNote('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      recipe: recipe.trim(),
      note: note.trim(),
      configurationId,
    };

    if (validations(data)) {
      await postRequest({ url: `recipes`, body: data });
      refresh();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.recipe + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='wrench' title='Tipo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Nuevo' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='recipe' title='Nombre'>
              <Input name='recipe' onChange={(e) => setRecipe(e.target.value)} value={recipe} />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='note' title='Nota'>
              <TextArea name='note' onChange={(e) => setNote(e.target.value)} value={note} rows={3} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewRecipes.propTypes = {
  configurationId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default NewRecipes;
