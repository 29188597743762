import React from 'react';
import { Row, Col, DropdownButton, DropdownItem, Card } from 'react-bootstrap';
import { ResponsiveContainer } from 'recharts';
import { Loading } from '@stateless';
import PropTypes from 'prop-types';
import { format } from '@utils/parseCost';
import IfPermission from '@components/application/controls/IfPermission';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Rectangle, Legend } from 'recharts';
import { ESTATUS_ORDENES } from '@config/constants';

const IndicadoresOrdenesServicio = ({ periodos, ordenesServicios, periodo, setPeriodo, loadingOrdenesServicio }) => {
  const onClickPeriodo = (periodo) => {
    setPeriodo(periodo);
  };

  return (
    <Row>
      <Col>
        <IfPermission action='indicadores-ordenes'>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <div className='pull-right mr-5' style={{ marginBottom: '10px' }}>
                    <DropdownButton title={periodo.nombre} variant='secondary'>
                      {periodos.map((periodo, i) => (
                        <DropdownItem key={i} onClick={() => onClickPeriodo(periodo)}>
                          {periodo.nombre}
                        </DropdownItem>
                      ))}
                    </DropdownButton>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Loading loading={loadingOrdenesServicio}>
                    <ResponsiveContainer width='100%' height={350}>
                      <BarChart
                        width={500}
                        height={300}
                        data={[
                          ordenesServicios.reduce((acc, x) => {
                            acc['name'] = 'Ordenes';
                            acc[x.estatus] = x.cantidad;
                            return acc;
                          }, {}),
                        ]}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {ESTATUS_ORDENES.map((x, i) => (
                          <Bar key={i} dataKey={x.estatus} fill={x.color} activeBar={<Rectangle fill='gold' stroke='blue' />} />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </Loading>
                </Col>
              </Row>
            </Card.Body>
            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th>Estatus</th>
                  <th>
                    Ordenes
                    {/* <button onClick={handleExcelEncabezado} className='btn btn-sm btn-success pull-right mr-5' type='button'>
                    <Icon icon='save-file' /> Exportar
                  </button> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordenesServicios.map((a, i) => (
                  <tr key={i}>
                    <td scope='row'>{a.estatus}</td>
                    <td width='30%'>{format(a.cantidad)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </IfPermission>
      </Col>
    </Row>
  );
};

IndicadoresOrdenesServicio.propTypes = {
  periodos: PropTypes.array,
  agentes: PropTypes.array,
  listOrdenesServicios: PropTypes.array,
  agente: PropTypes.object,
  loadData: PropTypes.func,
  periodo: PropTypes.object,
  setPeriodo: PropTypes.func,
  setAgente: PropTypes.func,
  ordenesServicios: PropTypes.array,
  loadingOrdenesServicio: PropTypes.bool,
  ordenesServiciosAgentes: PropTypes.array,
};

export default IndicadoresOrdenesServicio;
