import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DetailCustomer from '@components/customers/DetailCustomer';
import AsignarAgente from '../../facturas/AsignarAgente';

const Table = ({ list, loadData }) => {
  const renderRows = () => {
    return list.map((r, i) => {
      const { _id, serie, numero, fecha, importe, saldo, customerId, nota, agenteId } = r;
      let tipo = '';
      return (
        <tr key={i} className={tipo}>
          <td>{serie ? `${serie}-${numero}` : numero}</td>
          <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
          <td>
            <DetailCustomer customer={customerId} />
          </td>
          <td>$ {format(importe, 2)}</td>
          <td>$ {format(saldo, 2)}</td>
          <td>{nota}</td>
          <td>
            <AsignarAgente id={i} itemId={_id} factura={{ agenteId }} loadData={loadData} />
            <RedirectTooltip
              id={1}
              icon='search'
              url={`/facturas/detalle/${_id}`}
              labelTooltip='Completa'
              className='btn btn-sm btn-primary pull-right'
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='10%'>Número</th>
          <th width='10%'>Fecha</th>
          <th width='30%'>Cliente</th>
          <th width='10%'>Importe</th>
          <th width='10%'>Saldo</th>
          <th width='25%'>Referencia</th>
          <th width='5%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={7}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Table;
