import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ departamentoId, codigo, nombre, load }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`departamentos/${departamentoId}`}
      description={`¿Confirma que desea eliminar el departamento <strong><u> ${codigo} - ${nombre}</u> </strong>?`}
      loadData={load}
    />
  );
};

Delete.propTypes = {
  departamentoId: PropTypes.string.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Delete;
