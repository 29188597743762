import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { Icon } from '@stateless';
import { cleanNumber } from '@utils/formatter';
import InputNumber from '@components/forms/InputNumber';

const RowDetalle = ({ detalle, onChangeDetalle, handleDupliciate, handleDeleteRow }) => {
  const handleChangeDetalle = (field, event) => {
    let { name, value } = event.target;
    if (['cantidad', 'entregado'].includes(name)) value = Number(cleanNumber(event.target.value));
    onChangeDetalle({
      ...detalle,
      [field]: value,
    });
  };

  const handleSelectDetalle = (field, selectedOption) => {
    onChangeDetalle({
      ...detalle,
      [field]: selectedOption,
    });
  };

  return (
    <tr>
      <td style={{ padding: '2px 8px' }}>{detalle.noIdentificador}</td>
      <td style={{ padding: '2px 8px' }}>{detalle.descripcion}</td>
      <td style={{ padding: '2px 8px' }}>{detalle.measure}</td>
      <td style={{ padding: '2px 8px' }}>
        <InputNumber value={detalle.cantidad} onChange={(e) => handleChangeDetalle('cantidad', e)} name='cantidad' />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'top' }}>
        <Select
          value={detalle.existenciaId}
          name='existenciaId'
          placeholder='Seleccione'
          options={detalle.listaExistencias}
          onChange={(selectedOption) => handleSelectDetalle('existenciaId', selectedOption)}
          getOptionLabel={(x) => `${x.ubicacionId.nombre} ${x.numero_serial}`}
          getOptionValue={(x) => x._id}
        />
      </td>
      <td style={{ padding: '2px 8px', verticalAlign: 'top' }}>
        <InputNumber
          className={`input-sm ${detalle.entregado > detalle.cantidad && 'is-invalid'}`}
          value={detalle.entregado}
          onChange={(e) => handleChangeDetalle('entregado', e)}
          name='entregado'
        />
      </td>
      <td style={{ padding: '2px 8px' }}>
        <InputNumber style={{ width: '80px' }} className='input-sm disabled' value={detalle.existencia} name='existencia' />
      </td>
      <td style={{ padding: '2px 8px' }}>
        <InputNumber style={{ width: '80px' }} className='input-sm disabled' value={detalle.apartado} name='apartado' />
      </td>
      <td style={{ padding: '2px 8px' }}>
        <InputNumber style={{ width: '80px' }} className='input-sm disabled' value={detalle.por_recibir} name='por_recibir' />
      </td>
      <td>
        <div className='btn-group'>
          <Button onClick={() => handleDupliciate(detalle)} className='btn-sm btn-outline-primary'>
            <Icon icon='duplicate' />
          </Button>
          <Button onClick={handleDeleteRow} className='btn-sm btn-outline-danger'>
            <Icon icon='remove' />
          </Button>
        </div>
      </td>
    </tr>
  );
};

RowDetalle.propTypes = {
  detalle: PropTypes.shape({
    noIdentificador: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    measure: PropTypes.string.isRequired,
    cantidad: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    existenciaId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    listaExistencias: PropTypes.arrayOf(PropTypes.object).isRequired,
    entregado: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    existencia: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    apartado: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    por_recibir: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  i: PropTypes.number.isRequired,
  onChangeDetalle: PropTypes.func.isRequired,
  onSelectDetalle: PropTypes.func.isRequired,
  handleDupliciate: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

export default RowDetalle;
