import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, DropdownItem, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { putRequest } from '@utils/api';
import CbModal from '@cbcomponents/CbModal';
import Select from 'react-select';

const Viabilidad = ({ viabilidad, id, refresh }) => {
  const [showModal, setShowModal] = useState(false);
  const [viabilidadId, setViabilidadId] = useState('POR_CALIFICAR');
  const [estatusProcesoVentaId, setEstatusProcesoVentaId] = useState('POR_CALIFICAR');
  const [observaciones, setObservaciones] = useState('');
  const [loading] = useState(false);

  const estatus_proceso_ventas = [
    { type: 'secondary', value: 'POR_CALIFICAR', label: 'POR CALIFICAR' },
    { type: 'success', value: 'CONTACTADO', label: 'CONTACTADO' },
    { type: 'primary', value: 'PROPUESTA', label: 'PROPUESTA' },
    { type: 'warning', value: 'NEGOCIACION', label: 'NEGOCIACION' },
  ];

  const viabilidades = [
    { type: 'secondary', value: 'POR_CALIFICAR', label: 'POR CALIFICAR' },
    { type: 'warning', value: 'BAJA', label: 'BAJA' },
    { type: 'primary', value: 'MEDIA', label: 'MEDIA' },
    { type: 'success', value: 'ALTA', label: 'ALTA' },
  ];

  const close = () => setShowModal(false);

  const open = () => {
    setShowModal(true);
    setViabilidadId(viabilidad);
  };

  const handleConfirmar = async (event) => {
    event.preventDefault();
    const data = {
      viabilidad: viabilidadId?.value,
      estatus_proceso_venta: estatusProcesoVentaId?.value,
      observaciones,
    };
    try {
      await putRequest({ url: `prospectos/viabilidad/${id}`, body: data });
      close();
      refresh();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DropdownItem onClick={open}>
        <Icon icon='file' /> Estatus, Viabilidad y Observaciones
      </DropdownItem>
      <CbModal
        show={showModal}
        onClose={close}
        title='Viabilidad, estatus de venta y observaciones'
        size='lg'
        onSave={handleConfirmar}
        isLoading={loading}
      >
        <Row>
          <Col>
            <DivFormGroup name='viabilidadId' title='Viabilidad' required>
              <Select value={viabilidadId} options={viabilidades} onChange={setViabilidadId} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='estatusProcesoVentaId' title='Estatus proceso de venta' required>
              <Select value={estatusProcesoVentaId} options={estatus_proceso_ventas} onChange={setEstatusProcesoVentaId} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              type='textarea'
              name='observaciones'
              title='Observaciones'
              onChange={(e) => setObservaciones(e.target.value)}
              value={observaciones}
            />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Viabilidad.propTypes = {
  name: PropTypes.string.isRequired,
  viabilidad: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default Viabilidad;
