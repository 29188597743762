import React, { useState, useEffect } from 'react';
import { Module } from '@stateless';
import { getRequest } from '@utils/api';
import Concepto from './Concepto';
import { useParams } from 'react-router-dom';

const DetalleOrdenServicio = () => {
  const params = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { ordenServicioId } = params;
      const { data } = await getRequest({ url: `ordenes-servicio/${ordenServicioId}` });
      setData(data);
    };

    fetchData();
  }, [params]);

  const renderSolucionesSolicitadas = () => {
    const { soluciones_solicitadas } = data;
    let soluciones_solicitadas_text = '';
    soluciones_solicitadas.forEach((d, i) => {
      if (i > 0) {
        soluciones_solicitadas_text += ', ';
      }
      const { definicion } = d;
      soluciones_solicitadas_text += definicion;
    });
    return (
      <div className='col-sm-6'>
        {soluciones_solicitadas.length > 0 ? (
          <Concepto label='Soluciones solicitadas' valor={soluciones_solicitadas_text} />
        ) : (
          <Concepto label='Soluciuones solicitadas' valor={'Sin soluciones solicitadas'} />
        )}
      </div>
    );
  };

  const renderTiposServiciosSolicitados = () => {
    const { tipos_servicios_solicitados } = data;
    let tipos_servicios_solicitados_text = '';
    tipos_servicios_solicitados.forEach((d, i) => {
      if (i > 0) {
        tipos_servicios_solicitados_text += ', ';
      }
      const { definicion } = d;
      tipos_servicios_solicitados_text += definicion;
    });
    return (
      <div className='col-sm-6'>
        {tipos_servicios_solicitados.length > 0 ? (
          <Concepto label='Tipos servicios solicitados' valor={tipos_servicios_solicitados_text} />
        ) : (
          <Concepto label='Tipos servicios solicitados' valor={'Sin tipos servicios solicitados'} />
        )}
      </div>
    );
  };

  const renderData = () => {
    const { referencias_cliente, contacto_solicitante, tipos_mano_obras, descripcion, userId, domicilio_cliente } = data;
    return (
      <div className='row'>
        <div className='col-sm-12'>
          <div className='panel panel-primary'>
            <div className='panel-body'>
              <div className='row'>
                <div className='col-sm-4'>
                  <Concepto label='Usuario' valor={userId.name} />
                  <Concepto label='Tipo de mano de obra' valor={tipos_mano_obras.clave + ' - ' + tipos_mano_obras.definicion} />
                </div>
                <div className='col-sm-4'>
                  <Concepto label='Domicilio cliente' valor={domicilio_cliente.nombre} />
                  <Concepto label='Referencias cliente' valor={referencias_cliente} />
                </div>
                <div className='col-sm-4'>
                  <Concepto
                    label='Contacto solicitante'
                    valor={`${contacto_solicitante.name} ${contacto_solicitante.paterno} ${contacto_solicitante.materno} (${contacto_solicitante.phone}) (${contacto_solicitante.email})`}
                  />
                  <Concepto label='Descripcion de servicio' valor={descripcion} />
                </div>
                {renderSolucionesSolicitadas()}
                {renderTiposServiciosSolicitados()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Module title={`Orden de servicio #${data && data.numero_orden_servicio}`} onClickBack={window.history.back} loading={!data}>
      {data && renderData()}
    </Module>
  );
};

DetalleOrdenServicio.propTypes = {};

export default DetalleOrdenServicio;
