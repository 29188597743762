import React, { useState, useEffect } from 'react';

import Search from '@controls/Search';
import { Loading, Module } from '@stateless';
import ListServicios from './ListServicios';
import NewServicios from './NewServicios';

import { TIPO_SERVICIOS } from '@config/constants';
import IfPermission from '@controls/IfPermission';
import { postRequest } from '@utils/api';
import { Col, Row } from 'react-bootstrap';

const TipoServicios = () => {
  const [monitoreo, setMonitoreo] = useState([]);
  const [monitoreoAdicionales, setMonitoreoAdicionales] = useState([]);
  const [interactivos, setInteractivos] = useState([]);
  const [interactivosAdicionales, setInteractivosAdicionales] = useState([]);
  const [loading] = useState(true);
  const [activePage] = useState(1);
  const [pagination] = useState(100);

  useEffect(() => {
    handleSearch(1);
  }, []);

  const handleSearch = async (params = {}) => {
    const page = activePage * pagination - pagination;
    const { data } = await postRequest({ url: `servicios/search`, params: { page, limit: pagination }, body: { search: params.search } });
    const monitoreo = [],
      monitoreoAdicionales = [];
    const interactivos = [],
      interactivosAdicionales = [];

    data.map((d) => {
      if (d.tipo === TIPO_SERVICIOS.MONITOREO && !d.adicional) {
        monitoreo.push(d);
      } else if (d.tipo === TIPO_SERVICIOS.MONITOREO && d.adicional) {
        monitoreoAdicionales.push(d);
      } else if (d.tipo === TIPO_SERVICIOS.INTERACTIVO && !d.adicional) {
        interactivos.push(d);
      } else if (d.tipo === TIPO_SERVICIOS.INTERACTIVO && d.adicional) {
        interactivosAdicionales.push(d);
      }
    });

    setMonitoreo(monitoreo);
    setMonitoreoAdicionales(monitoreoAdicionales);
    setInteractivos(interactivos);
    setInteractivosAdicionales(interactivosAdicionales);
  };

  const renderList = (list, labelServicio) => (
    <div>
      <ListServicios catalogServicios={list} labelServicio={labelServicio} loadServicios={handleSearch} />
    </div>
  );

  const lblMonitoreo = 'Servicio de Monitoreo';
  const lblMonitoreoAdicional = 'Servicio de Monitoreo Adicional';
  const lblInteractivo = 'Servicio Interactivo';
  const lblInteractivoAdiconal = 'Servicio Interactivo Adicional';

  const listMonitoreo = !loading ? renderList(monitoreo, lblMonitoreo) : <Loading />;
  const listMonitoreoAdicional = !loading ? renderList(monitoreoAdicionales, lblMonitoreoAdicional) : <Loading />;
  const listInteractivo = !loading ? renderList(interactivos, lblInteractivo) : <Loading />;
  const listInteractivoAdicional = !loading ? renderList(interactivosAdicionales, lblInteractivoAdiconal) : <Loading />;

  return (
    <Module title='Tipos de servicios'>
      <Search onSearch={handleSearch} blockOne='col-sm-4' blockSecond='col-sm-8'></Search>

      <div className='panel panel-primary'>
        <div className='panel-heading'>
          <h3 className='panel-title text-center'>MONITOREO</h3>
        </div>
        <div className='panel-body'>
          <Row>
            <Col sm='6'>
              <IfPermission action='crear'>
                <NewServicios label={lblMonitoreo} tipo={TIPO_SERVICIOS.MONITOREO} adicional={false} loadServicios={handleSearch} />
              </IfPermission>
              <legend>Servicios de monitoreo</legend>
              {listMonitoreo}
            </Col>
            <Col sm='6'>
              <IfPermission action='crear'>
                <NewServicios label={lblMonitoreoAdicional} tipo={TIPO_SERVICIOS.MONITOREO} adicional={true} loadServicios={handleSearch} />
              </IfPermission>
              <legend>Adicionales</legend>
              {listMonitoreoAdicional}
            </Col>
          </Row>
        </div>
      </div>

      <div className='panel panel-primary'>
        <div className='panel-heading'>
          <h3 className='panel-title text-center'>INTERACTIVOS</h3>
        </div>
        <div className='panel-body'>
          <Row>
            <Col sm='6'>
              <IfPermission action='crear'>
                <NewServicios label={lblInteractivo} tipo={TIPO_SERVICIOS.INTERACTIVO} adicional={false} loadServicios={handleSearch} />
              </IfPermission>
              <legend>Servicios interactivos</legend>
              {listInteractivo}
            </Col>
            <Col sm='6'>
              <IfPermission action='crear'>
                <NewServicios label={lblInteractivoAdiconal} tipo={TIPO_SERVICIOS.INTERACTIVO} adicional={true} loadServicios={handleSearch} />
              </IfPermission>
              <legend>Adicionales</legend>
              {listInteractivoAdicional}
            </Col>
          </Row>
        </div>
      </div>
    </Module>
  );
};

TipoServicios.propTypes = {};

export default TipoServicios;
