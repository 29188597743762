import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton, DropdownItem, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, RowsNotFound } from '@stateless';
import { format } from '@utils/parseCost';
import EnviarRecibo from './EnviarRecibo';
import ImprimirRecibo from './ImprimirRecibo';

const List = ({ list, loadData, orden, tipo }) => {
  const renderRows = () => {
    return list.map((row, key) => {
      const { _id, numero_recibo, tipo_cliente, customer, estado, importe, fecha, agente, sucursal, moneda, fecha_vencimiento, tipoReciboId } = row;
      let tdClienteProspecto = (
        <td>
          <span className='label label-primary'>{tipo_cliente}</span>{' '}
          <Link to={`/${tipo_cliente === 'CLIENTE' ? 'clientes' : 'prospectos'}/detalle/${customer ? customer._id : null}`}>
            {customer ? customer.razon_social : null}
          </Link>
        </td>
      );

      let labelEstado = '';
      if (estado === 'CANCELADA' || estado === 'DECLINADA') {
        labelEstado = <span className='label label-danger'>{estado}</span>;
      } else if (estado === 'AUTORIZADA') {
        labelEstado = <span className='label label-primary'>AUTORIZADA</span>;
      } else if (estado === 'FINALIZADA') {
        labelEstado = <span className='label label-success'>FINALIZADA</span>;
      } else if (estado === 'EN_PROCESO') {
        labelEstado = <span className='label label-success'>EN PROCESO</span>;
      } else {
        labelEstado = <span className='label label-primary'>{estado}</span>;
      }
      const estaVencida = moment(fecha_vencimiento).local().diff(moment(), 'days') < 0;
      if (estaVencida && estado !== 'FINALIZADA') labelEstado = <span className='label label-danger'>VENCIDA</span>;

      return (
        <tr key={key}>
          <td>{numero_recibo}</td>
          <td> {tipoReciboId ? tipoReciboId.nombre : null} </td>
          <td>{moment(fecha).local().format('DD/MM/YYYY')}</td>
          {tdClienteProspecto}
          <td>{sucursal ? sucursal.name : null}</td>
          <td>{moneda}</td>
          <td>
            <strong>$ {format(importe, 2)}</strong>
          </td>
          <td>{agente ? agente.nombre : null}</td>
          <td>{labelEstado}</td>
          <td>
            <ButtonGroup className='pull-right'>
              <If condition={estado !== 'DECLINADA' && estado !== 'CANCELADA' && estado !== 'FINALIZADA' && estado !== 'ACEPTADA' && !estaVencida}>
                <If.Then>
                  <RedirectTooltip id={key} labelTooltip='Editar' url={`/recibos/edicion/${_id}`} icon='edit' className='btn btn-outline-success' />
                </If.Then>
              </If>
              <ImprimirRecibo id={key} numero_recibo={numero_recibo} reciboId={_id} />
              <DropdownButton variant='outline-primary' as={ButtonGroup} title={<span className='glyphicon glyphicon-option-vertical'></span>}>
                <DropdownItem href={`/recibos/${_id}`}>
                  <Icon icon='zoom-in' /> Detalle
                </DropdownItem>
                <If
                  condition={
                    estado !== 'CANCELADA' &&
                    estado !== 'DECLINADA' &&
                    estado !== 'FINALIZADA' &&
                    estado !== 'ENVIADA' &&
                    estado !== 'ACEPTADA' &&
                    estado !== 'AUTORIZADA' &&
                    !estaVencida
                  }
                >
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='arrow-right'
                      typeRequest='putRequest'
                      btnType='success'
                      action='Autorizar'
                      isMenu
                      url={`recibos/autorizar/${_id}`}
                      description={`¿Confirma que desea autorizar el<strong>recibo: <u>#${numero_recibo}</u></strong>?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <If condition={estado !== 'CANCELADA' && estado !== 'DECLINADA' && estado !== 'FINALIZADA' && !estaVencida}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='remove'
                      typeRequest='putRequest'
                      btnType='danger'
                      action='Cancelar'
                      isMenu
                      url={`recibos/cancelar/${_id}`}
                      description={`¿Confirma que desea cancelar el <strong>recibo: <u>#${numero_recibo}</u></strong>?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <If condition={estado !== 'CANCELADA' && estado !== 'ACEPTADA' && estado !== 'DECLINADA' && !estaVencida}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='ok'
                      typeRequest='putRequest'
                      btnType='primary '
                      action='Aceptar'
                      isMenu
                      url={`recibos/aceptar/${_id}`}
                      description={`¿Confirma que desea aceptar el <strong>recibo: <u>#${numero_recibo}</u></strong>?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <If condition={estado !== 'CANCELADA' && estado !== 'ACEPTADA' && estado !== 'DECLINADA' && !estaVencida}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='remove'
                      typeRequest='putRequest'
                      btnType='warning '
                      action='Declinar'
                      isMenu
                      url={`recibos/declinar/${_id}`}
                      description={`¿Confirma que desea declinar el <strong>recibo: <u>#${numero_recibo}</u></strong>?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <If condition={estado !== 'CANCELADA' && estado !== 'ACEPTADA' && estado !== 'DECLINADA' && !estaVencida}>
                  <If.Then>
                    <Confirmation
                      id={_id}
                      btnIcon='remove'
                      typeRequest='putRequest'
                      btnType='warning '
                      action='Eliminar'
                      isMenu
                      url={`recibos/eliminar/${_id}`}
                      description={`¿Confirma que desea eliminar el <strong>recibo: <u>#${numero_recibo}</u></strong>?`}
                      loadData={loadData}
                    />
                  </If.Then>
                </If>
                <If condition={estado !== 'CANCELADA' && estado !== 'DECLINADA' && !estaVencida}>
                  <If.Then>
                    <EnviarRecibo {...row} id={_id} btnClass='btn btn-info ' />
                  </If.Then>
                </If>
              </DropdownButton>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  const obtenerClase = (orden, tipo, campo) => {
    if (orden !== campo) return 'sorting';
    if (tipo === -1) return 'sorting_desc';
    return 'sorting_asc';
  };

  const establecerOrden = (orden, tipo, campo) => {
    loadData({ page: 0, orden: campo, tipo: orden === campo ? tipo * -1 : -1 });
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={9} />;
  return (
    <div className='table-responsive'>
      <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
        <thead>
          <tr>
            <th
              className={obtenerClase(orden, tipo, 'numero_recibo')}
              width='5%'
              onClick={() => {
                establecerOrden(orden, tipo, 'numero_recibo');
              }}
            >
              #
            </th>
            <th
              className={obtenerClase(orden, tipo, 'tipo_recibo')}
              width='10%'
              onClick={() => {
                establecerOrden(orden, tipo, 'tipo_recibo');
              }}
            >
              Tipo
            </th>
            <th
              className={obtenerClase(orden, tipo, 'fecha')}
              width='10%'
              onClick={() => {
                establecerOrden(orden, tipo, 'fecha');
              }}
            >
              Fecha
            </th>
            <th
              className={obtenerClase(orden, tipo, 'cliente')}
              width='35%'
              onClick={() => {
                establecerOrden(orden, tipo, 'cliente');
              }}
            >
              Cliente / Prospecto
            </th>
            <th
              className={obtenerClase(orden, tipo, 'sucursal')}
              width='10%'
              onClick={() => {
                establecerOrden(orden, tipo, 'sucursal');
              }}
            >
              Sucursal
            </th>
            <th
              className={obtenerClase(orden, tipo, 'moneda')}
              width='10%'
              onClick={() => {
                establecerOrden(orden, tipo, 'moneda');
              }}
            >
              Moneda
            </th>
            <th
              className={obtenerClase(orden, tipo, 'importe')}
              width='10%'
              onClick={() => {
                establecerOrden(orden, tipo, 'importe');
              }}
            >
              Importe
            </th>
            <th
              className={obtenerClase(orden, tipo, 'agente')}
              width='10%'
              onClick={() => {
                establecerOrden(orden, tipo, 'vendedor');
              }}
            >
              Agente
            </th>
            <th
              className={obtenerClase(orden, tipo, 'estado')}
              width='10%'
              onClick={() => {
                establecerOrden(orden, tipo, 'estado');
              }}
            >
              Estatus
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  listAlmacenes: PropTypes.array.isRequired,
  listSucursales: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  agenteId: PropTypes.string.isRequired,
  sucursalId: PropTypes.string.isRequired,
  tipoReciboId: PropTypes.string.isRequired,
  almacenId: PropTypes.string.isRequired,
  orden: PropTypes.string.isRequired,
  tipo: PropTypes.number.isRequired,
};

export default List;
