import React, { useEffect } from 'react';
import Select from 'react-select';
import { useStoreMediosComunicacion } from '@stores/catalogs.store';
import PropTypes from 'prop-types';

const SelectMedioComunicacion = ({ onChange, name, value, disabled }) => {
  const { data, execute } = useStoreMediosComunicacion();
  useEffect(() => {
    execute();
  }, []);
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      value={value}
      name={name}
      placeholder='Selecciona'
      options={data}
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='basic-multi-select'
      getOptionLabel={(x) => x.nombre}
      getOptionValue={(x) => x._id}
      classNamePrefix='select'
      isDisabled={disabled}
      isClearable
    />
  );
};


SelectMedioComunicacion.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
};

export default SelectMedioComunicacion;
