import React, { useState, useEffect } from 'react';
import IfPermission from '@controls/IfPermission';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import { DivFormGroup } from '@controls';

import { Module, Loading } from '@stateless';
import { getRequest } from '@utils/api';
import { Card, Col, Row } from 'react-bootstrap';
import SelectAgente from '@components/forms/SelectAgente';

const AgendaSeguimientos = () => {
  const [loading, setLoading] = useState(true);
  const [agenda, setAgenda] = useState([]);
  const [agenteObj, setAgenteObj] = useState(null);
  const [agenteId, setAgenteId] = useState(null);

  useEffect(() => {
    inicializar();
  }, []);

  const loadData = async () => {
    const { data } = await getRequest({ url: `agendaseguimientos?agenteId=${agenteId}` });
    setAgenda(data);
    setLoading(false);
  };

  const inicializar = async () => {
    const {
      data: { agente },
    } = await getRequest({ url: `agenda-seguimiento-catalogos` });
    setAgenteObj({ value: agente._id, label: agente.nombre });
    setAgenteId(agente._id);
    loadData();
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setAgenteObj(itemSelect);
      setLoading(true);
      loadData();
    } else {
      setAgenteObj(null);
    }
  };

  return (
    <Module title='Agenda de seguiminetos'>
      <IfPermission action='TodosAsesores'>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <form>
                  <Row>
                    <Col>
                      <DivFormGroup name='agentes' title='Seleccionar agente'>
                        <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                      </DivFormGroup>
                    </Col>
                  </Row>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </IfPermission>
      <Loading loading={loading}>
        <div className='panel panel-primary'>
          <div className='panel-heading'>Calendario</div>
          <div className='panel-body'>
            <FullCalendar locales={[esLocale]} locale='es' initialView='dayGridMonth' plugins={[dayGridPlugin]} events={agenda} />
          </div>
        </div>
      </Loading>
    </Module>
  );
};

export default AgendaSeguimientos;
