import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import DeleteModule from './DeleteModule';
import EditModule from './EditModule';
import SubModules from './sub-modules/';
import { ButtonGroup } from 'react-bootstrap';

const ListModules = ({ catalogModules, loadCatalog }) => {
  const chunk = (arr, chunkSize) => {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) R.push(arr.slice(i, i + chunkSize));
    return R;
  };

  const renderRows = (catalogModules) => {
    const modules = chunk(catalogModules, 3);
    return modules.map((catalogChunk, item1) => {
      const renderModules = catalogChunk.map((dataModule, item2) => {
        const { _id, sort, module, icon } = dataModule;
        return (
          <div className='col-md-4 col-sm-6' key={item1 + '-' + item2}>
            <div className='small-box bg-white'>
              <div className='inner'>
                <p>{module.toUpperCase()}</p>
                <h3>&nbsp;</h3>
                <ButtonGroup>
                  <EditModule id={item1 + '' + item2} idCatModule={_id} sort={sort} icon={icon} module={module} loadCatalog={loadCatalog} />
                  <DeleteModule id={item1 + '' + item2} idCatModule={_id} sort={sort} icon={icon} module={module} load={loadCatalog} />
                </ButtonGroup>
              </div>
              <div className='icon'>
                <i className={`glyphicon glyphicon-${icon}`}></i>
              </div>
              <SubModules module={dataModule} modules={catalogModules} loadCatalog={loadCatalog} />
            </div>
          </div>
        );
      });
      return (
        <div key={item1} className='row'>
          {renderModules}
        </div>
      );
    });
  };

  const onDragEnd = (result) => {
    console.log(result);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className=''>
        {catalogModules.length > 0 ? (
          renderRows(catalogModules)
        ) : (
          <div>
            <div colSpan='5'>No se encontraron resultados</div>
          </div>
        )}
      </div>
    </DragDropContext>
  );
};

ListModules.propTypes = {
  catalogModules: PropTypes.array.isRequired,
  loadCatalog: PropTypes.func.isRequired,
};

export default ListModules;
