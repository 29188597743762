import React, { useEffect } from 'react';
import Select from 'react-select';
import { useStoreEstados } from '@stores/catalogs.store';
import PropTypes from 'prop-types';

const SelectEstado = ({ onChange, name, value, filter }) => {
  const { data, execute } = useStoreEstados();
  useEffect(() => {
    execute();
  }, []);
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      value={value}
      name={name}
      placeholder='Seleccione'
      options={data.filter((x) => (filter ? filter(x) : true))}
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='basic-multi-select'
      getOptionLabel={(x) => x.estado}
      getOptionValue={(x) => x._id}
      classNamePrefix='select'
    />
  );
};

SelectEstado.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  filter: PropTypes.func,
};

export default SelectEstado;
