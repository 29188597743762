import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import Importar from './Importar';
import List from './List';
import New from './New';
import CbCustomPagination from '@cbcomponents/CbCustomPagination';
import { Col, Row } from 'react-bootstrap';

const ListCustomerRegistros = () => {
  const [search, setSearch] = useState('');
  const [customers, setCustomers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState(50);
  const [loading, setLoading] = useState(true);
  const [orden, setOrden] = useState('numero_cliente');
  const [tipo, setTipo] = useState(-1);
  const [estado, setEstado] = useState({ value: 0, label: 'TODOS' });
  const estados = [
    { value: 0, label: 'TODOS' },
    { value: 1, label: 'INFORMACION COMPLETA' },
    { value: 2, label: 'SIN CLIENTE' },
    { value: 3, label: 'SIN DOMICILIO' },
    { value: 4, label: 'SIN CONTACTO' },
  ];

  useEffect(() => {
    handleSearch();
  }, []);

  const onSearch = (params = { search: '', orden: 'numero_cliente', tipo: -1 }) => {
    setSearch(params.search);
    setOrden(params.orden);
    setTipo(params.tipo);
    setActivePage(1);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search: search,
      estado: estado.value,
    };
    const { data, count } = await postRequest({ url: `customers-registros/search`, params: { page, limit: pagination, orden, tipo }, body });
    setCustomers(data);
    setCount(count);
    setLoading(false);
  };

  return (
    <Module title='CRM Clientes'>
      <Search searchOnStopType blockOne='col-sm-6' blockSecond='col-sm-6' onSearch={onSearch}>
        <Row>
          <Col md='4'>
            <Select
              label='Estatus'
              options={estados}
              value={estado}
              onChange={(estado) => {
                setEstado(estado);
                handleSearch();
              }}
            />
          </Col>
          <Col md='4'>
            <New
              refresh={() => {
                onSearch({ page: 0, pagination: pagination, orden, tipo });
              }}
            />
          </Col>
          <Col md='4'>
            <Importar
              refresh={() => {
                onSearch({ page: 0, pagination: pagination, orden, tipo });
              }}
            />
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <List
          list={customers}
          refresh={() => {
            onSearch({ page: 0, pagination: pagination, orden, tipo });
          }}
          orden={orden}
          tipo={tipo}
          estados={estados}
          onChangeOrden={(orden, tipo) => {
            setOrden(orden);
            setTipo(tipo);
            onSearch({ page: 0, pagination: pagination, orden, tipo });
          }}
        />
        <CbCustomPagination
          pagination={pagination}
          activePage={activePage}
          count={count}
          onPagination={handlePagination}
          onChangeItemsPerPagina={(pagination) => {
            setPagination(pagination);
            handleSearch();
          }}
        />
      </Loading>
    </Module>
  );
};

ListCustomerRegistros.propTypes = {};

export default ListCustomerRegistros;
