import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';
import InputPercentage from '@components/forms/InputPercentage';

const NewPlazos = ({ loadPlazos }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [plazo, setPlazo] = useState('');
  const [taza, setTaza] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
    setPlazo('');
    setTaza('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const data = {
      nombre: nombre.trim(),
      plazo: Number(plazo.replace(/%|,/gi, '')),
      taza: Number(taza.replace(/%|,/gi, '')),
    };

    if (validations(data)) {
      await postRequest({ url: `plazos`, body: data });
      loadPlazos();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.plazo + '')) {
      validate.success = false;
      validate.message = 'Plazo es requerido.';
    } else if (params.plazo <= 0) {
      validate.success = false;
      validate.message = 'Plazo debe ser mayor a cero.';
    } else if (isEmpty(params.taza + '')) {
      validate.success = false;
      validate.message = 'Tasa es requerida.';
    } else if (params.taza >= 100) {
      validate.success = false;
      validate.message = 'Tasa debe ser menor al 100%.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} placeholder='Nombre' />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='plazo' title='Plazo' required>
              <InputNumber name='plazo' value={plazo} onChange={(e) => setPlazo(e.target.value)} placeholder='Plazo' />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='taza' title='Tasa' required>
              <InputPercentage placeholder='Tasa' name='taza' className='form-control' onChange={(e) => setTaza(e.target.value)} value={taza} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewPlazos.propTypes = {
  loadPlazos: PropTypes.func.isRequired,
};

export default NewPlazos;
