import { Confirmation } from '@controls/index';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ id, name, refresh }) => {
  return (
    <Confirmation
      id={id}
      btnIcon='trash'
      typeConfirm='error'
      btnType='outline-danger'
      action='Activar'
      typeRequest='deleteRequest'
      url={`prospectos/${id}`}
      description={`¿Confirma que desea eliminar el prospecto <strong>${name}</strong> ?`}
      loadData={refresh}
    />
  );
};

Delete.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default Delete;
