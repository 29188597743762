import { FORZOSO, INDETERMINADO } from '@config/constants';
import { DivFormGroup, Label, NumberPicker } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditCondicionesPagoServicios = ({ condicionespagoserviciosId, loadCondicionesPagoServicios }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [plazo, setPlazo] = useState(0);
  const [tipo, setTipo] = useState(INDETERMINADO);
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setTipo(INDETERMINADO);
    setPlazo(0);
  };

  const open = async () => {
    const {
      data: { tipo, plazo },
    } = await getRequest({ url: `condicionespagoservicios/${condicionespagoserviciosId}` });
    setShowModal(true);
    setTipo(tipo);
    setPlazo(plazo);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      tipo,
      plazo: plazo.toString().trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `condicionespagoservicios/${condicionespagoserviciosId}`, body: data });
      loadCondicionesPagoServicios();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.tipo + '')) {
      validate.success = false;
      validate.message = 'Tipo es requerido.';
    }

    if (params.tipo === FORZOSO) {
      if (isEmpty(params.plazo + '')) {
        validate.success = false;
        validate.message = 'Plazo a meses es requerido.';
      } else if (params.plazo <= 0) {
        validate.success = false;
        validate.message = 'Plazo a meses debe ser mayor a cero.';
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const renderFormPlazo = () => (
    <Col sm='6'>
      <Label title='Plazo a meses' required></Label>
      <div>
        <NumberPicker name='plazo' defaultValue={plazo} min={0} onChange={(value) => setPlazo(value)} />
      </div>
    </Col>
  );

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <DivFormGroup title='Tipo' required>
              <div>
                <label className='radio-inline'>
                  <input
                    type='radio'
                    id='indeterminado'
                    name='tipo'
                    onChange={() => setTipo(INDETERMINADO)}
                    value={INDETERMINADO}
                    checked={tipo === INDETERMINADO}
                  />{' '}
                  Indeterminado
                </label>
                <label className='radio-inline'>
                  <input type='radio' id='forzoso' name='tipo' onChange={() => setTipo(FORZOSO)} value={FORZOSO} checked={tipo === FORZOSO} /> Forzoso
                </label>
              </div>
            </DivFormGroup>
          </Col>
          {tipo === FORZOSO ? renderFormPlazo() : null}
        </Row>
      </CbModal>
    </>
  );
};

EditCondicionesPagoServicios.propTypes = {
  condicionespagoserviciosId: PropTypes.string.isRequired,
  loadCondicionesPagoServicios: PropTypes.func.isRequired,
};

export default EditCondicionesPagoServicios;
