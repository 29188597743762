import React from 'react';
import PropTypes from 'prop-types';

const STYLE_CONTAINER = {
  paddingTop: '20px',
  paddingBottom: '11px',
  paddingLeft: '20px',
  paddingRight: '20px',
};

const DivColorBG = ({ text, color = 'primary', style, className }) => {
  return (
    <div className={`bg-${color}`} style={STYLE_CONTAINER}>
      <p className={className} style={style}>
        {text}
      </p>
    </div>
  );
};

DivColorBG.propTypes = {
  color: PropTypes.oneOf(['primary', 'success', 'info', 'warning', 'danger', 'default']),
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default DivColorBG;
