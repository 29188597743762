import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const SelectContactoCliente = ({ onChange, name, value, options, isDisabled }) => {
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      name={name}
      value={value}
      isDisabled={isDisabled}
      options={options}
      placeholder='Seleccione un contacto'
      getOptionLabel={(x) => `${x.name} ${x.paterno} ${x.materno} (${x.phone}) (${x.email})`}
      getOptionValue={(x) => x._id}
      onChange={onSelect}
      styles={{ container: (base) => ({ ...base, flex: 'auto' }) }}
      isClearable
    />
  );
};

SelectContactoCliente.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
};

export default SelectContactoCliente;
