import { DivFormGroup, Label } from '@controls';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isMongoId } from 'validator';
import { useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = () => {
  const { addNotification: notification } = useStoreNotification();
  const { planMantenimientoId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [maquinaria, setMaquinaria] = useState([]);
  const [serviciosTerceros, setServiciosTerceros] = useState([]);
  const [kitServicios, setKitServicios] = useState([]);
  const [maquinariaHerramientaId, setMaquinariaHerramientaId] = useState('');
  const [itemKitServicios, setItemKitServicios] = useState(1);
  const [itemServiciosTerceros, setItemServiciosTerceros] = useState(1);
  const addForm = useRef(null);

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    const { data, maquinaria, serviciosTerceros, kitServicios } = await getRequest({ url: `planes/${planMantenimientoId}` });
    setData(data);
    setMaquinariaHerramientaId(data.maquinariaHerramientaId);
    setItemKitServicios(data.listaKit.length);
    setItemServiciosTerceros(data.listaTerceros.length);
    setMaquinaria(maquinaria);
    setServiciosTerceros(serviciosTerceros);
    setKitServicios(kitServicios);
    setLoading(false);
  };

  const handleAddForm = async (event) => {
    event.preventDefault();

    const detalleKits = [];
    const detalleTerceros = [];

    for (let i = 0; i < itemKitServicios; i++) {
      if (typeof addForm.current[`kitServicioId-${i}`] !== 'undefined') {
        if (isMongoId(addForm.current[`kitServicioId-${i}`].value + '')) {
          detalleKits.push({
            kitServicioId: addForm.current[`kitServicioId-${i}`].value,
          });
        }
      }
    }

    for (let i = 0; i < itemServiciosTerceros; i++) {
      if (typeof addForm.current[`serviciosTercerosId-${i}`] !== 'undefined') {
        if (isMongoId(addForm.current[`serviciosTercerosId-${i}`].value + '')) {
          detalleTerceros.push({
            serviciosTercerosId: addForm.current[`serviciosTercerosId-${i}`].value,
          });
        }
      }
    }

    const data = {
      maquinariaHerramientaId,
      detalleKits,
      detalleTerceros,
    };

    if (validations(data)) {
      await putRequest({ url: `planes/${planMantenimientoId}`, body: data });
      loadForm();
    }
  };

  const addItemKitServicios = () => {
    setItemKitServicios(itemKitServicios + 1);
  };

  const removeItemKitServicios = (item) => {
    document.getElementById(`row-kit-${item}`).innerHTML = null;
  };

  const renderKitServicios = () => {
    const views = [];
    const { listaKit } = data;

    for (let i = 0; i < itemKitServicios; i++) {
      const item = listaKit[i];
      const isGood = typeof item !== 'undefined';

      const view = (
        <Row key={i} id={`row-kit-${i}`}>
          <Col sm='11'>
            <DivFormGroup>
              <select
                name={`kitServicioId-${i}`}
                id={`kitServicioId-${i}`}
                className='form-control'
                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                disabled={kitServicios.length > 0 ? false : true}
                defaultValue={isGood && item.kitServicioId}
              >
                <option value=''>Seleccione</option>
                {kitServicios.map((r, i) => (
                  <option key={i} value={r._id}>
                    {r.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='1'>
            <Button onClick={() => removeItemKitServicios(i)} className='btn btn-danger pull-right' type='button'>
              <Icon icon='trash' />
            </Button>
          </Col>
        </Row>
      );
      views.push(view);
    }

    return (
      <>
        <Button onClick={addItemKitServicios} className='btn btn-sm btn-success pull-right' style={{ marginTop: '-55px' }} type='button'>
          <Icon icon='plus' />
        </Button>
        <Col sm='6'>
          <label>Kit</label>
        </Col>
        {views}
      </>
    );
  };

  const addItemServiciosTerceros = () => {
    setItemServiciosTerceros(itemServiciosTerceros + 1);
  };

  const removeItemServiciosTerceros = (item) => {
    document.getElementById(`row-tercero-${item}`).innerHTML = null;
  };

  const renderServiciosTerceros = () => {
    const views = [];
    const { listaTerceros } = data;

    for (let i = 0; i < itemServiciosTerceros; i++) {
      const item = listaTerceros[i];
      const isGood = typeof item !== 'undefined';
      const view = (
        <div key={i} id={`row-tercero-${i}`}>
          <Col sm='11'>
            <DivFormGroup>
              <select
                name={`serviciosTercerosId-${i}`}
                id={`serviciosTercerosId-${i}`}
                className='form-control'
                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                disabled={serviciosTerceros.length > 0 ? false : true}
                defaultValue={isGood && item.serviciosTercerosId}
              >
                <option value=''>Seleccione</option>
                {serviciosTerceros.map((r, i) => (
                  <option key={i} value={r._id}>
                    {r.nombre} - {r.proveedorId.name}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col sm='1'>
            <button onClick={() => removeItemServiciosTerceros(i)} className='btn btn-danger pull-right' type='button'>
              <Icon icon='trash' />
            </button>
          </Col>
        </div>
      );
      views.push(view);
    }

    return (
      <>
        <Button onClick={addItemServiciosTerceros} className='btn btn-sm btn-success pull-right' style={{ marginTop: '-55px' }} type='button'>
          <Icon icon='plus' />
        </Button>
        <Col sm='6'>
          <label>Terceros</label>
        </Col>
        {views}
      </>
    );
  };

  const renderForm = () => {
    return (
      <form ref={addForm} onSubmit={handleAddForm}>
        <Row>
          <Col sm='6'>
            <DivFormGroup>
              <Label name='maquinariaHerramientaId' title='Maquinaria / Herramienta' />
              <select
                name='maquinariaHerramientaId'
                id='maquinariaHerramientaId'
                className='form-control'
                defaultValue={maquinariaHerramientaId}
                onChange={(e) => setMaquinariaHerramientaId(e.target.value)}
                disabled={maquinaria.length > 0 ? false : true}
              >
                <option value=''>Seleccione</option>
                {maquinaria.map((s, i) => (
                  <option key={i} value={s._id}>
                    {s.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <legend>Kits de Servicios</legend>
            {renderKitServicios()}
          </Col>
        </Row>
        <Row>
          <Col>
            <legend>Servicios de Terceros</legend>
            {renderServiciosTerceros()}
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <Card>
              <Card.Body>
                <Saving saving={loading} />
                <Button type='submit' className='btn btn-lg btn-primary pull-right' disabled={loading}>
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    );
  };

  const validations = (data) => {
    let validate = { success: true, message: '' };

    if (!isMongoId(data.maquinariaHerramientaId + '')) {
      validate.success = false;
      validate.message = 'Maquinaria / Herramienta es Requerido.';
    } else if (validate.success && data.detalleKits.length <= 0) {
      validate.success = false;
      validate.message = 'Falta especificar los kit de servicios para el Plan';
    }

    const distinctKits = Array.from(new Set(data.detalleKits.map((d) => d.kitServicioId)));

    if (validate.success && distinctKits.length < data.detalleKits.length) {
      validate.success = false;
      validate.message = 'Hay kit duplicados, favor de verificar';
    } else if (validate.success && data.detalleTerceros.length <= 0) {
      validate.success = false;
      validate.message = 'Falta especificar los servicios de terceros para el Plan';
    }

    const distinctTerceros = Array.from(new Set(data.detalleTerceros.map((d) => d.serviciosTercerosId)));

    if (validate.success && distinctTerceros.length < data.detalleTerceros.length) {
      validate.success = false;
      validate.message = 'Hay servicios de terceros duplicados, favor de verificar';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const moduleForm = !loading ? renderForm() : <Loading />;

  return (
    <Module onClickBack={window.history.back} title='Edición del Plan de Mantenimiento'>
      {moduleForm}
    </Module>
  );
};

Edit.propTypes = {};

export default Edit;
