import React from 'react';
import PropTypes from 'prop-types';

const CbTableTd = ({ children, isNumber, style }) => {
  if (isNumber) {
    return (
      <td style={{ padding: '2px 3px', verticalAlign: 'middle', textAlign: 'right', ...style }} className='text-right'>
        {children}
      </td>
    );
  }
  return <td style={{ padding: '2px 3px', verticalAlign: 'middle' }}>{children}</td>;
};

CbTableTd.propTypes = {
  children: PropTypes.node.isRequired,
  isNumber: PropTypes.bool,
  style: PropTypes.object,
};

export default CbTableTd;
