import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';
import RowContacto from './RowContacto';

const TableContactos = ({ customer }) => {
  const renderTableContactos = () => {
    const { listContactos } = customer;

    const rows = listContactos.map((c, i) => <RowContacto key={i} contacto={c} />);

    return (
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th width='20%'>Tipo</th>
            <th width='40%'>Nombre</th>
            <th width='15%'>Celulares</th>
            <th width='15%'>Teléfono</th>
            <th width='15%'>Correos electrónicos</th>
          </tr>
        </thead>
        <tbody>{listContactos.length > 0 ? rows : <RowsNotFound colSpan={5} message='No se han agregado contactos' />}</tbody>
      </table>
    );
  };

  if (!customer || (customer.listContactos && customer.listContactos.length === 0)) return null;
  const tableContactos = renderTableContactos();

  return (
    <div className='col-sm-12'>
      <div className='panel panel-primary'>
        <div className='panel-heading'>
          <h4 className='panel-title'>
            <Icon icon='user' /> Contactos
          </h4>
        </div>
        {tableContactos}
      </div>
    </div>
  );
};

TableContactos.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default TableContactos;
