import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Row, Col, Nav, NavItem } from 'react-bootstrap';
import { Icon } from '@stateless';
import { DivColorBG } from '@controls';

import ListEquipments from './ListEquipments';

const ListSellers = ({ serviceIdDefault, catalogSellers, catalogEquipments }) => {
  const [serviceId, setServiceId] = useState(serviceIdDefault);

  const renderColums = () => {
    return catalogSellers.map((row, item) => {
      const { _id, name, fatherLastName } = row;

      return (
        <NavItem eventKey={_id} key={item}>
          {name} {fatherLastName}
          <div className='pull-right'>
            <Icon icon='chevron-right' />
          </div>
        </NavItem>
      );
    });
  };

  const renderColumsDetail = () => {
    return catalogSellers.map((row, item) => {
      const { _id, equipmentAssinated } = row;

      return (
        <Tab.Pane eventKey={_id} key={item}>
          {catalogEquipments.length ? (
            <ListEquipments
              sellerId={_id}
              serviceId={serviceId}
              equipmentAssinated={equipmentAssinated}
              catalogSellers={catalogSellers}
              catalogEquipments={catalogEquipments}
            />
          ) : (
            <DivColorBG text='No se encontraron resultados' />
          )}
        </Tab.Pane>
      );
    });
  };

  const onSelectColumn = (activeKey) => {
    setServiceId(activeKey);
  };

  let tabsContainer = { colums: null, detail: null };

  if (catalogSellers.length > 0) {
    tabsContainer.colums = renderColums();
    tabsContainer.detail = renderColumsDetail();
  }

  return (
    <div>
      <Tab.Container id='equipments-services' defaultActiveKey={serviceId} onSelect={onSelectColumn}>
        <Row className='clearfix'>
          <Col sm={3}>
            <div className='text-center'>
              <span className='lead'>Vendedores</span>
            </div>

            <Nav bsStyle='pills' stacked>
              {tabsContainer.colums}
            </Nav>
          </Col>
          <Col sm={9}>
            <div className='text-center'>
              <span className='lead'>Inventario de Equipos en los Carros</span>
            </div>
            <Tab.Content animation>{tabsContainer.detail}</Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

ListSellers.propTypes = {
  serviceIdDefault: PropTypes.string,
  catalogSellers: PropTypes.array.isRequired,
  catalogEquipments: PropTypes.array.isRequired,
};

export default ListSellers;
