import React from 'react';
import { Icon } from '@stateless';
import RowZona from './RowZona';
import PropTypes from 'prop-types';

const RowArea = ({ area, i, activeClasificacion, onChangeArea }) => {
  const handleChangeArea = (index, event) => {
    onChangeArea({
      ...area,
      [event.target.name]: event.target.value,
    });
  };

  const onClickToggleZonas = () => {
    onChangeArea({
      ...area,
      showZonas: !area.showZonas,
    });
  };

  const onClickQuitarArea = (index) => {
    onChangeArea({
      ...area,
      zonas: area.zonas.filter((zona, j) => j !== index),
    });
  };

  const handleAgregarZona = () => {
    onChangeArea({
      ...area,
      zonas: [
        ...area.zonas,
        {
          nombre: '',
          subclasificaciones: [],
        },
      ],
    });
  };
  const subclasificaciones = activeClasificacion?.subclasificaciones || [];
  return (
    <>
      <tr className='warning'>
        <th width='10%' style={{ padding: '0px' }}>
          <div style={{ display: 'inline-block', height: '100%', width: '13%' }}>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault();
                onClickToggleZonas(i);
              }}
            >
              <Icon icon={area.showZonas ? 'triangle-bottom' : 'triangle-right'} />
            </a>
          </div>
          <div style={{ display: 'inline-block', height: '100%', width: '87%' }}>
            <input
              className='form-control'
              style={{ padding: '.2em' }}
              placeholder='Area'
              name='nombre'
              value={area.nombre}
              onChange={(e) => handleChangeArea(i, e)}
            />
          </div>
        </th>
        <td width='20%' style={{ padding: '0px' }}></td>
        {subclasificaciones.map((sb, k) => (
          <td width='5%' style={{ padding: '0px' }} key={k}>
            <input className='form-control' style={{ padding: '.2em' }} type='number' disabled value={sb.cantidad} name='cantidad' />
          </td>
        ))}
        <td width='3%' style={{ padding: '0px' }}>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault();
              onClickQuitarArea(i);
            }}
          >
            <Icon icon='remove' style={{ color: 'red' }} />
          </a>
        </td>
      </tr>
      {area.showZonas && area.zonas.map((zona, j) => <RowZona key={j} i={i} j={j} zona={zona} activeClasificacion={activeClasificacion} />)}
      {area.showZonas && (
        <tr>
          <td colSpan={((activeClasificacion && activeClasificacion.subclasificaciones.length) || 1) + 3} style={{ padding: '0px' }}>
            <button className='btn btn-block' onClick={() => handleAgregarZona(i)}>
              <Icon icon='plus' /> Agregar sección
            </button>
          </td>
        </tr>
      )}
    </>
  );
};

RowArea.propTypes = {
  area: PropTypes.object.isRequired,
  activeClasificacion: PropTypes.object,
  i: PropTypes.number.isRequired,
  subclasificaciones: PropTypes.array.isRequired,
  onChangeArea: PropTypes.func.isRequired,
};

export default RowArea;
