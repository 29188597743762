import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { hasPermission } from '@utils/permissions';
import SelectAgente from '@components/forms/SelectAgente';
import SelectTipoLevantamiento from '@components/forms/SelectTipoLevantamiento';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectDomicilioCliente from '@components/forms/SelectDomicilioCliente';
import Select from '@components/forms/Select';
import SelectClienteProspecto from '@components/forms/SelectClienteProspecto';
import { validateUpdate } from '@validations/levantamientos';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store.js';
import MultiSelectClasificacionLevantamiento from '@components/forms/MultiSelectClasificacionLevantamiento';

const Editar = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addNotification: notification } = useStoreNotification();

  const [state, setState] = useState({
    clasificaciones: [],
    activeClasificacion: null,
    idLevantamiento: null,
    showModal: false,
    isLoadingForm: true,
    isLoadingTable: false,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    agente: null,
    maximo_descuento: 0,
    detalles: [],
    observaciones: '',
    estatus: 'INICIADA',
    cliente: null,
    productos: [],
    tipoCambio: 0,
    tipo_cliente: 'CLIENTE',
    tipos_clientes: [
      { value: 'PROSPECTO', label: 'PROSPECTO' },
      { value: 'CLIENTE', label: 'CLIENTE' },
    ],
    estatus_levantamientos: [{ value: 'INICIADA', label: 'INICIADA' }],
    domicilios: [],
    anticipo: 0,
    areas: [],
  });

  const inicializar = useCallback(async () => {
    await getLevantamiento();
  }, []);

  const getLevantamiento = useCallback(async () => {
    const { levantamientoId } = params;
    const { data } = await getRequest({ url: `levantamientos/${levantamientoId}` });
    const encabezado = data;
    try {
      const cliente = encabezado.customerId;
      const domicilios =
        cliente && cliente.listDomicilios && cliente.listDomicilios.length > 0
          ? encabezado.customerId.listDomicilios
          : [encabezado.customer.domicilio];
      setState((prevState) => ({
        ...prevState,
        isLoadingForm: false,
        numeroOrden: encabezado.numero_levantamiento,
        fecha: moment(encabezado.fecha).local().format('YYYY-MM-DD'),
        fecha_vencimiento: moment(encabezado.fecha_vencimiento).local().format('YYYY-MM-DD'),
        estatus: encabezado.estado,
        sucursalId: encabezado.sucursal,
        monedaId: encabezado.moneda,
        tipoLevantamientoId: encabezado.tipo_levantamiento,
        tipo_cliente: encabezado.tipo_cliente,
        cliente: encabezado.customer,
        agente: encabezado.agente,
        domicilioId: encabezado.customer.domicilio,
        domicilios,
        observaciones: encabezado.observaciones,
        isLoading: false,
        areas: encabezado.areas,
        clasificacionesId: encabezado.clasificacionesId,
      }));
    } catch (e) {
      console.log(e);
    }
  }, [params]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `clasificadores-levantamientos/all` });
      const clasificaciones = data.filter((c) => c.subclasificaciones && c.subclasificaciones.length > 0);
      setState((prevState) => ({
        ...prevState,
        clasificaciones,
      }));
      inicializar();
    };
    fetchData();
  }, [inicializar]);

  const actualizarNumeroLevantamiento = useCallback(async () => {
    const { data } = await getRequest({ url: `levantamientos/siguienteNumero`, params: { tipoLevantamientoId: state.tipoLevantamientoId } });
    setState((prevState) => ({
      ...prevState,
      numeroOrden: data.numero_levantamiento,
      isLoadingForm: false,
    }));
  }, [state.tipoLevantamientoId]);

  const handleRegister = async () => {
    const { levantamientoId } = params;
    const { numeroOrden, fecha, estatus, sucursalId, tipo_cliente, domicilioId, cliente, observaciones, tipoLevantamientoId, clasificacionesId } = state;
    const body = {
      numero_levantamiento: numeroOrden,
      tipoLevantamientoId: tipoLevantamientoId?._id,
      tipo_cliente,
      fecha: moment(fecha, 'YYYY-MM-DD').utc().format(),
      estatus_levantamiento: estatus,
      sucursalId: sucursalId?._id,
      agenteId: state.agente?._id,
      observaciones,
      clienteId: cliente ? cliente._id : '',
      domicilioId: domicilioId?._id,
      clasificacionesId: clasificacionesId.map((x) => x._id),
    };
    if (validations(body)) {
      const { data } = await putRequest({ url: `levantamientos/${levantamientoId}`, body });
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isLoadingForm: false,
          showModalDownload: true,
          idLevantamiento: data?._id,
        }));
        setTimeout(() => navigate(`/levantamientos/${levantamientoId}/areas`), 1000);
      } else {
        setState((prevState) => ({ ...prevState, isLoadingForm: false }));
      }
    }
  };

  const validations = useCallback(
    (params) => {
      const { error } = validateUpdate(params);
      if (error) {
        notification({
          title: 'Información incompleta',
          message: error.details[0].message,
          type: 'error',
        });
      }
      return !error;
    },
    [notification]
  );

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSelectClasificacion = (value) => {
    setState((prevState) => ({ ...prevState, clasificacionesId: value }));
  };

  const onSelect = useCallback(
    (campo, value) => {
      setState(
        (prevState) => ({ ...prevState, [campo]: value }),
        () => {
          if (campo === 'tipoLevantamientoId') {
            actualizarNumeroLevantamiento();
          }
        }
      );
    },
    [actualizarNumeroLevantamiento]
  );

  const onChangeAgente = useCallback((itemSelect) => {
    setState((prevState) => ({
      ...prevState,
      agente: itemSelect,
      maximo_descuento: itemSelect?.maximo_descuento,
    }));
  }, []);

  const onChangeCliente = useCallback(
    (itemSelect) => {
      if (itemSelect) {
        const domicilios = state.tipo_cliente === 'CLIENTE' ? itemSelect.listDomicilios : [{ ...itemSelect.domicilio, _id: 1 }];
        const domicilioId = domicilios.length === 1 ? domicilios[0]._id : null;
        if (domicilios.length > 1 && this.refDomicilio) setTimeout(() => this.refDomicilio.inputRef.focus(), 300);
        if (itemSelect && itemSelect.clasification) {
          if (itemSelect.clasification.opcion === 'ADVERTENCIA') {
            notification({ title: 'Advertencia', message: 'Este cliente cuenta con saldo vencido o limite de credito.', type: 'warning' });
          } else if (itemSelect.clasification.opcion === 'OCUPA_AUTORIZACION') {
            notification({
              title: 'Advertencia',
              message: 'Este cliente cuenta con saldo vencido o limite de credito y requiere autorizacion para cotizarle.',
              type: 'warning',
            });
          } else if (itemSelect.clasification.opcion === 'SIN_AUTORIZACION') {
            return notification({
              title: 'Advertencia',
              message: 'Este cliente cuenta con saldo vencido o limite de credito asi que no es posible cotizarle.',
              type: 'error',
            });
          }
        }
        setState((prevState) => ({
          ...prevState,
          clienteName: itemSelect.razon_social,
          cliente: itemSelect,
          domicilioId,
          domicilios: domicilios,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          clienteName: '',
          cliente: null,
          domicilios: [],
        }));
      }
    },
    [state.tipo_cliente, notification]
  );

  const renderView = () => {
    const {
      isLoading,
      tipoLevantamientoId,
      numeroOrden,
      fecha,
      estatus,
      estatus_levantamientos,
      agente,
      sucursalId,
      tipo_cliente,
      cliente,
      domicilioId,
      domicilios,
      clasificacionesId
    } = state;
    return (
      <>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col xs='12' sm='6'>
                <Row>
                  <Col xs='6'>
                    <DivFormGroup name='tipoLevantamientoId' required title='Tipo de levantamiento'>
                      <SelectTipoLevantamiento value={tipoLevantamientoId} onChange={(value) => onSelect('tipoLevantamientoId', value)} />
                    </DivFormGroup>
                  </Col>
                  <Col xs='6'>
                    <FormInput
                      required
                      title='Núm. Levantamiento'
                      placeholder='Núm. Levantamiento'
                      disabled
                      name='numeroOrden'
                      onChange={onChange}
                      value={numeroOrden}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs='12' sm='6'>
                <Row>
                  <Col xs='6'>
                    <FormInput
                      title='Fecha'
                      type='date'
                      required
                      name='fecha'
                      onChange={onChange}
                      disabled={!hasPermission('cambiar-fecha')}
                      value={fecha}
                    />
                  </Col>
                  <Col xs='12' sm='6'>
                    <DivFormGroup name='estatus' required title='Estatus'>
                      <Select name='estatus' value={estatus} options={estatus_levantamientos} onChange={(value) => onSelect('estatus', value)} />
                    </DivFormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs='12' sm='6'>
                <DivFormGroup name='agenteId' required title='Agente'>
                  <SelectAgente value={agente} onChange={onChangeAgente} name='agente' />
                </DivFormGroup>
              </Col>
              <Col xs='12' sm='6'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='8'>
                <DivFormGroup name='clienteName' required title={tipo_cliente}>
                  <SelectClienteProspecto value={cliente} onChange={onChangeCliente} name='cliente' />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <FormInput title='RFC' disabled name='clienteRfc' onChange={onChange} value={cliente ? cliente.rfc : ''} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='domicilioId' required title='Domicilio'>
                  <SelectDomicilioCliente
                    value={domicilioId}
                    onChange={(value) => onSelect('domicilioId', value)}
                    domicilios={domicilios}
                    disabled={!cliente || domicilios.length === 1}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='domicilioId' required title='Clasificaciones de levantamiento'>
                  <MultiSelectClasificacionLevantamiento value={clasificacionesId} name='clasificacionesId' onChange={onSelectClasificacion} />
                </DivFormGroup>
              </Col>
            </Row>
          </div>
        </div>
        <div className='panel panel-default'>
          <div className='panel-footer'>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <button onClick={handleRegister} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Levantamientos' title='Editar levantamiento'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Editar.propTypes = {
  params: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  onChangeTipoCliente: PropTypes.func.isRequired,
};

export default Editar;
