import React from 'react';
import { Icon } from '@stateless';
import Domicilio from '@components/customers/Domicilio';
import DomicilioEdit from '@components/customers/DomicilioEdit';
import PropTypes from 'prop-types';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { Confirmation } from '@controls';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { useStoreNotification } from '@stores/catalogs.store';

const RowDomicilio = ({ domicilio, editDomicilio, onChangeDomicilio, loadData, customerId }) => {
  const { addNotification: notification } = useStoreNotification();
  const onSaveDomicilio = async (e) => {
    e.preventDefault();
    if (
      isEmpty(domicilio.calle) ||
      !domicilio.paisObject ||
      !domicilio.estadoObject ||
      !domicilio.municipioObject ||
      !domicilio.coloniaObject ||
      isEmpty(domicilio.codigo_postal.toString())
    ) {
      notification({
        title: 'Información incompleta',
        message: 'Los domicilios requieren calle, pais, estado, municipio, colonia y código postal.',
        type: 'error',
      });
      return;
    }
    const data = {
      domicilioId: domicilio?._id,
      calle: domicilio?.calle,
      exterior: domicilio?.exterior,
      interior: domicilio?.interior,
      calle1: domicilio?.calle1,
      calle2: domicilio?.calle2,
      coloniaId: domicilio?.coloniaObject._id,
      municipioId: domicilio?.municipioObject._id,
      estadoId: domicilio?.estadoObject._id,
      paisId: domicilio?.paisObject._id,
      codigo_postal: domicilio?.codigo_postal,
      localidad: domicilio?.localidad,
      referencias: domicilio?.referencias,
      nombre: domicilio?.nombre,
      monitoreo: domicilio?.monitoreo,
      es_fiscal: domicilio?.es_fiscal,
    };
    await postRequest({ url: `customers/${customerId}/domicilio`, body: data });
    loadData();
  };

  return (
    <>
      <tr className={domicilio.esta_completo ? '' : 'danger'}>
        <td style={{ padding: '2px', verticalAlign: 'middle' }}>
          <div className='checkbox'>
            <label>{domicilio.es_fiscal && <Icon icon='check' />}</label>
          </div>
          {domicilio.nombre}
        </td>
        <td style={{ padding: '2px', verticalAlign: 'middle' }}>{domicilio.monitoreo}</td>
        <td style={{ padding: '2px', verticalAlign: 'middle' }}>
          <Domicilio domicilio={domicilio} />
        </td>
        <td style={{ padding: '2px', verticalAlign: 'middle', textAlign: 'right' }}>
          <div className='btn-group pull-right'>
            <CbButtonOver onClick={editDomicilio} icon='edit' title='Editar' type='success' />
            <Confirmation
              id={domicilio._id}
              btnIcon='trash'
              typeConfirm='danger'
              btnType='outline-danger'
              action='Eliminar'
              typeRequest='deleteRequest'
              url={`customers/${customerId}/domicilios/${domicilio._id}`}
              description={`¿Confirma que desea dar de baja el domicilio?`}
              loadData={loadData}
            />
          </div>
        </td>
      </tr>
      {domicilio.editando && (
        <tr>
          <td colSpan='5'>
            <DomicilioEdit domicilio={domicilio} onChange={onChangeDomicilio} />
            <Container>
              <Row>
                <Col xs={12}>
                  <div className='pull-right'>
                    <Button onClick={editDomicilio} variant='default'>
                      Cancelar
                    </Button>
                    <Button onClick={onSaveDomicilio} variant='success'>
                      Guardar
                    </Button>
                  </div>
                  <br />
                </Col>
              </Row>
            </Container>
          </td>
        </tr>
      )}
    </>
  );
};

RowDomicilio.propTypes = {
  domicilio: PropTypes.object.isRequired,
  editDomicilio: PropTypes.func.isRequired,
  cancelDomicilio: PropTypes.func.isRequired,
  onChangeDomicilio: PropTypes.func.isRequired,
  estados: PropTypes.array.isRequired,
  paises: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,

  customerId: PropTypes.number.isRequired,
};

export default RowDomicilio;
