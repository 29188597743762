import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [definicion, setDefinicion] = useState('');
  const [clave, setClave] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = useCallback(() => {
    setShowModal(false);
    setDefinicion('');
    setClave('');
  }, []);

  const open = useCallback(() => {
    setShowModal(true);
    setIsLoading(false);
  }, []);

  const onChange = useCallback((event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      if (name === 'definicion') setDefinicion(checked);
      if (name === 'clave') setClave(checked);
    } else {
      if (name === 'definicion') setDefinicion(value);
      if (name === 'clave') setClave(value);
    }
  }, []);

  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();
      const data = {
        definicion: definicion.trim(),
        clave: clave.trim(),
      };

      if (validations(data)) {
        await postRequest({ url: 'tipos_cobro_equipo', body: data });
        load();
        close();
      }
    },
    [definicion, clave, load, close]
  );

  const validations = useCallback((params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.clave + '')) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.definicion + '')) {
      validate.success = false;
      validate.message = 'Definición es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  }, []);

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput title='Clave' placeholder='Clave' required name='clave' onChange={onChange} value={clave} />
          </Col>
          <Col>
            <FormInput title='Definición' placeholder='Definición' required name='definicion' onChange={onChange} value={definicion} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  load: PropTypes.func.isRequired,
};

export default New;
