import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';

const Domicilio = ({ domicilio }) => {
  if (!domicilio || Object.getOwnPropertyNames(domicilio).length === 0) return null;

  let {
    nombre,
    calle,
    exterior,
    interior,
    colonia,
    coloniaId,
    codigo_postal,
    municipio,
    estado,
    estadoId,
    pais,
    longitud,
    latitud,
    tiene_posicion,
    coloniaObject,
  } = domicilio;

  let domicilioStr = `${calle || ''} ${exterior || ''}`;
  let coloniaCP = '';
  let ciudad = '';
  domicilioStr += interior ? `, ${interior}` : '';

  if ((coloniaId || coloniaObject) && (typeof coloniaId === 'object' || typeof coloniaObject === 'object')) {
    const col = coloniaId ? coloniaId : coloniaObject;
    colonia = col ? col.d_asenta : '';
    coloniaCP = col ? `${col.d_asenta}` : '';
    coloniaCP += codigo_postal && codigo_postal !== '0' ? `, C.P. ${codigo_postal}` : '';
    ciudad = col.municipio_id ? col.municipio_id.nombre : '';
    ciudad += col.municipio_id && col.municipio_id.estadoId ? `, ${col.municipio_id.estadoId.estado}` : '';
    municipio = col.municipio_id ? col.municipio_id.nombre : '';
    pais = col.municipio_id && col.municipio_id.estadoId && col.municipio_id.paisId ? col.municipio_id.estadoId.paisId.pais : '';
  } else {
    coloniaCP = colonia && coloniaId !== 0 ? `${colonia}` : '';
    coloniaCP += codigo_postal && codigo_postal !== '0' ? `, C.P. ${codigo_postal}` : '';
    ciudad = municipio ? municipio : '';
    ciudad += estado && estadoId !== 0 ? `, ${estado}` : '';
  }

  return (
    <small>
      <p>
        {tiene_posicion && (
          <>
            <a href={`https://maps.google.com/?q=${latitud},${longitud}`} target='_blank' rel='noreferrer'>
              <Icon icon='map-marker' style={{ color: 'green' }} />{' '}
            </a>
            &nbsp;
          </>
        )}
        {nombre ? (
          <>
            <strong>{nombre}</strong> <br />{' '}
          </>
        ) : null}
        {domicilioStr}
        {colonia ? <br /> : null}
        {coloniaCP}
        {municipio ? <br /> : null}
        {ciudad || ''} {pais || ''}
      </p>
    </small>
  );
};

Domicilio.propTypes = {
  domicilio: PropTypes.object.isRequired,
};

export default Domicilio;
