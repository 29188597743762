import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import New from './New';

const Empleados = () => {
  const [state, setState] = useState({
    list: [],
    loading: true,
    departamentos: [],
    grupos_empleados: [],
    tiposnominas: [],
    puestos: [],
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
  });

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const {
      data: { puestos, departamentos, tiposnominas, grupos_empleados },
    } = await getRequest({ url: `empleados/catalogos` });
    setState((prevState) => ({
      ...prevState,
      departamentos: departamentos.map((x) => ({ value: x._id, label: x.nombre, disabled: false })),
      puestos: puestos.map((x) => ({ value: x._id, label: x.nombre, disabled: false })),
      tiposnominas: tiposnominas.map((x) => ({ value: x._id, label: x.nombre, disabled: false })),
      grupos_empleados: grupos_empleados.map((x) => ({ value: x._id, label: x.nombre, disabled: false })),
    }));
    handleSearch();
  };

  const handleSearch = async () => {
    const { pagination, activePage, search } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = { search };
    const { data, count } = await postRequest({ url: 'empleados/search', params: { page, limit: pagination }, body });
    setState((prevState) => ({
      ...prevState,
      list: data,
      count: count,
      loading: false,
    }));
  };

  const onSearch = (params = { search: '' }) => {
    setState((prevState) => ({ ...prevState, search: params.search, activePage: 1 }));
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }));
    handleSearch();
  };

  const renderList = () => {
    const { list, departamentos, puestos, tiposnominas, grupos_empleados } = state;
    return (
      <List
        list={list}
        departamentos={departamentos}
        grupos_empleados={grupos_empleados}
        puestos={puestos}
        tiposnominas={tiposnominas}
        load={onSearch}
      />
    );
  };

  const { loading, departamentos, puestos, tiposnominas, grupos_empleados, count, pagination, activePage } = state;
  const moduleList = !loading ? renderList() : <Loading />;

  return (
    <Module title='Empleados'>
      <div className='row'>
        <div className='col-sm-12'>
          <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
            <IfPermission action='crear'>
              <New load={onSearch} grupos_empleados={grupos_empleados} departamentos={departamentos} tiposnominas={tiposnominas} puestos={puestos} />
            </IfPermission>
          </Search>
        </div>
      </div>
      {moduleList}
      <div className='text-center'>
        <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
      </div>
    </Module>
  );
};

Empleados.propTypes = {};

export default Empleados;
