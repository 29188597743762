import Joi from 'joi';
import { MESSAGES } from '@utils/validations';
const R_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const pedidoSchemaUpdate = Joi.object({
  tipoPedidoId: Joi.string().label('Tipo de pedido').required().regex(R_MONGO_ID),
  fecha: Joi.date().label('Fecha').required(),
  estatus_pedido: Joi.string().label('Estatus').required(),
  monedaId: Joi.string().label('Moneda').required().regex(R_MONGO_ID),
  tipo_cambio: Joi.number().label('Tipo de cambio').required(),
  sucursalId: Joi.string().label('Sucursal').required().regex(R_MONGO_ID),
  almacenId: Joi.string().label('Almácen').required().regex(R_MONGO_ID),
  agenteSolicitanteId: Joi.string().label('Agente solicitante').required().regex(R_MONGO_ID),
  clienteId: Joi.string().label('Cliente').required().regex(R_MONGO_ID),
  domicilioId: Joi.string().label('Domicilio').required().regex(R_MONGO_ID),
  detalles: Joi.array().items(
    Joi.object({
      descripcion: Joi.string().label('Descripción').required(),
      cantidad: Joi.number().label('Cantidad').required(),
      entregado: Joi.number().label('Entregado').required(),
      entregado_anterior: Joi.number().label('Entregado anterior').required(),
      existencia: Joi.number().label('Existencia').required(),
    })
  ),
}).messages(MESSAGES);

export function validateUpdate(pedido) {
  return pedidoSchemaUpdate.validate(pedido);
}
