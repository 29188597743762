import React, { useState, useEffect } from 'react';
import CbPagination from '@controls/CbPagination';
import Search from '@controls/Search';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import ListLogs from './ListLogs';

const ApplicationData = () => {
  const [dataLogs, setDataLogs] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(50);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handlePagination(1);
  }, []);

  useEffect(() => {
    handleSearch();
  }, [search, activePage]);

  const onSearch = (params = {}) => {
    setSearch(params.search);
  };
  const handleSearch = async () => {
    setLoading(true);
    const page = activePage * pagination - pagination;
    const body = {
      search,
    };
    const { data, count } = await postRequest({ url: `logs/search`, params: { page, limit: pagination }, body });
    setDataLogs(data);
    setCount(count);
    setLoading(false);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  return (
    <Module icon='wrench' title='Aplicación'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'></Search>
      <Loading loading={loading}>
        <ListLogs logs={dataLogs} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

export default ApplicationData;
