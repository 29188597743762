import React from 'react';
import List from './List';

const Existencias = (props) => {
  return <List {...props} />;
};

Existencias.propTypes = {};

export default Existencias;
