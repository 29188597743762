import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [nombre, setNombre] = useState('');
  const [clave, setClave] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setClave('');
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nombre') setNombre(value);
    if (name === 'clave') setClave(value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = { nombre, clave };

    if (validations(data)) {
      await postRequest({ url: 'tipos_pedidos', body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.clave + '')) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput title='Clave' placeholder='Clave' required name='clave' onChange={onChange} value={clave} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput title='Nombre' placeholder='Nombre' required name='nombre' onChange={onChange} value={nombre} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  loadData: PropTypes.func.isRequired,
};

export default New;
