import { DivFormGroup, FormInput, Input } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Select from 'react-select';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { validateUpdate } from '@validations/ordenesServicio';
import SelectTipoOrdenServicio from '@components/forms/SelectTipoOrdenServicio';
import SelectAgente from '@components/forms/SelectAgente';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectDomicilioCliente from '@components/forms/SelectDomicilioCliente';
import SelectContactoCliente from '@components/forms/SelectContactoCliente';
import SelectCliente from '@components/forms/SelectCliente';
import SelectTipoManoDeObra from '@components/forms/SelectTipoManoDeObra';
import SelectTipoCobroEquipo from '@components/forms/SelectTipoCobroEquipo';
import MultiSelectTiposServiciosSolicitados from '@components/forms/MultiSelectTiposServiciosSolicitados';
import MultiSelectSolucionesSolicitadas from '@components/forms/MultiSelectSolucionesSolicitadas';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const params = useParams();
  const [state, setState] = useState({
    isLoadingForm: true,
    isLoading: false,
    fecha_solicitud: moment().format('YYYY-MM-DD HH:mm'),
    estatus: { value: 'FINALIZADA', label: 'FINALIZADA' },
    estatus_servicios: [
      { value: 'INICIADA', label: 'INICIADA' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'PROGRAMADA', label: 'PROGRAMADA' },
      { value: 'FINALIZADA', label: 'FINALIZADA' },
    ],
    numeroOrdenServicio: 0,
    cliente: null,
    agente: null,
    agenteId: null,
    domicilio: null,
    domicilioId: null,
    domicilioClienteId: null,
    domicilios: null,
    tipos_servicios_solicitados_id: [],
    soluciones_solicitadas_id: [],
    contactos: [],
    showModal: false,
    ordenServicioId: null,
    tipoOrdenServicioId: null,
  });

  const { ordenServicioId } = params;

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const onSelect = useCallback(async (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));

    if (campo === 'tipoOrdenServicioId') {
      const { data } = await getRequest({ url: `ordenes-servicio/siguienteNumero`, params: { tipoOrdenServicioId: value._id } });
      setState((prevState) => ({
        ...prevState,
        numeroOrdenServicio: data.numero_orden_servicio,
      }));
    }
  }, []);

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      const domicilios = itemSelect.listDomicilios;
      const contactos = itemSelect.listContactos;
      const domicilioId = domicilios.length === 1 ? domicilios[0] : null;
      const contactoId = contactos.length === 1 ? contactos[0] : null;
      setState({
        clienteName: itemSelect.razon_social,
        cliente: itemSelect,
        domicilios,
        contactos: contactos,
        domicilioId,
        domicilioClienteId: domicilioId,
        contacto: contactoId,
      });
    } else {
      setState({
        clienteName: '',
        cliente: null,
        domicilioId: null,
        domicilioClienteId: null,
        contacto: null,
        domicilios: [],
        contactos: [],
      });
    }
  };

  const validations = (params) => {
    console.log(params);
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return !error;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      tipoOrdenServicioId,
      numeroOrdenServicio,
      fecha_solicitud,
      fecha_entrega,
      agente,
      estatus,
      sucursal,
      cliente,
      domicilioId,
      soluciones_solicitadas_id,
      tipos_servicios_solicitados_id,
      tipos_mano_obra_id,
      domicilioClienteId,
      contacto,
      descripcion,
      referencia,
      ordenServicioId,
      tipos_cobro_equipo_id,
    } = state;
    const data = {
      numero_orden_servicio: numeroOrdenServicio,
      fecha_solicitud: moment(fecha_solicitud, 'YYYY-MM-DD HH:mm').utc().toDate(),
      fecha_entrega: moment(fecha_entrega, 'YYYY-MM-DD HH:mm').utc().toDate(),
      estatus_servicios: estatus.value,
      sucursalId: sucursal._id,
      agenteId: agente._id,
      clienteId: cliente ? cliente._id : null,
      domicilio_entrega_Id: domicilioId ? domicilioId._id : null,
      domicilio_cliente_id: domicilioClienteId ? domicilioClienteId._id : null,
      contacto_solicitante: contacto ? contacto._id : null,
      soluciones_solicitadas_id: soluciones_solicitadas_id.map((x) => x._id),
      tipos_servicios_solicitados_id: tipos_servicios_solicitados_id.map((x) => x._id),
      tipos_mano_obras_id: tipos_mano_obra_id?._id,
      tipos_cobro_equipo_id: tipos_cobro_equipo_id?._id,
      tipoOrdenServicioId: tipoOrdenServicioId?._id,
      descripcion: descripcion,
      referencia: referencia,
    };

    if (validations(data)) {
      await putRequest({ url: `ordenes-servicio/${ordenServicioId}`, body: data });
      setTimeout(() => navigate('/ordenes-servicio'), 1000);
    }
  };

  const ObtenerOrdenServicio = useCallback(
    async (ordenServicioId, callback = () => {}) => {
      const { data: encabezado } = await getRequest({ url: `ordenes-servicio/${ordenServicioId}` });
      try {
        const estatusValue = state.estatus_servicios.find((x) => x.value === encabezado.estatus_servicios);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          isLoadingForm: false,
          ordenServicioId,
          cotizaciones: encabezado.cotizaciones ? encabezado.cotizaciones : [],
          numeroOrdenServicio: encabezado.numero_orden_servicio,
          fecha_solicitud: moment(encabezado.fecha_solicitud).local().format('YYYY-MM-DD HH:mm'),
          fecha_entrega: moment(encabezado.fecha_entrega).local().format('YYYY-MM-DD HH:mm'),
          estatus: estatusValue,
          sucursal: encabezado.sucursal,
          clienteName: encabezado.cliente.razon_social,
          observacion: encabezado.observacion,
          referencia: encabezado.referencia,
          cliente: {
            ...encabezado.cliente._id,
            rfc: encabezado.cliente.rfc,
          },
          agente: encabezado.agente,
          tipoOrdenServicioId: encabezado.tipoOrdenServicioId,
          domicilios: encabezado.cliente._id.listDomicilios,
          contactos: encabezado.cliente._id.listContactos,
          contacto: encabezado.contacto_solicitante,
          domicilioId: {
            ...encabezado.domicilio_entrega,
          },
          domicilioClienteId: {
            ...encabezado.domicilio_cliente,
          },
          soluciones_solicitadas_id: encabezado.soluciones_solicitadas,
          tipos_servicios_solicitados_id: encabezado.tipos_servicios_solicitados,
          descripcion: encabezado.descripcion,
          tipos_mano_obra_id: encabezado.tipos_mano_obras,
          tipos_cobro_equipo_id: encabezado.tipos_cobro_equipo,
        }));
        callback();
      } catch (e) {
        console.log(e);
      }
    },
    [state.estatus_servicios]
  );

  useEffect(() => {
    ObtenerOrdenServicio(ordenServicioId);
  }, [ObtenerOrdenServicio, ordenServicioId]);

  const renderView = () => {
    const {
      numeroOrdenServicio,
      fecha_solicitud,
      fecha_entrega,
      estatus,
      sucursal,
      domicilioId,
      domicilios,
      domicilioClienteId,
      isLoading,
      estatus_servicios,
      cliente,
      contacto,
      descripcion,
      referencia,
      soluciones_solicitadas_id,
      tipoOrdenServicioId,
      tipos_servicios_solicitados_id,
      tipos_mano_obra_id,
      tipos_cobro_equipo_id,
      contactos,
      agente,
    } = state;
    return (
      <form onSubmit={handleRegister}>
        <Card>
          <Card.Body>
            <Row>
              <Col sm='3'>
                <DivFormGroup name='tipoOrdenServicioId' title='Tipo orden de servicio' required>
                  <SelectTipoOrdenServicio
                    value={tipoOrdenServicioId}
                    name='tipoOrdenServicioId'
                    onChange={(value) => onSelect('tipoOrdenServicioId', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <FormInput
                  title='# Orden de servicio'
                  placeholder='Número de orden de servicio'
                  disabled
                  name='numeroOrdenServicio'
                  onChange={onChange}
                  value={numeroOrdenServicio}
                />
              </Col>
              <Col sm='3'>
                <FormInput
                  title='Fecha solicitud'
                  type='datetime-local'
                  required
                  name='fecha_solicitud'
                  onChange={onChange}
                  value={fecha_solicitud}
                />
              </Col>
              <Col sm='3'>
                <FormInput title='Fecha entrega' type='datetime-local' name='fecha_entrega' onChange={onChange} value={fecha_entrega} />
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <DivFormGroup name='agente' required title='Agente solicitante'>
                  <SelectAgente value={agente} name='agente' onChange={(value) => onSelect('agente', value)} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursal} name='sucursal' onChange={(value) => onSelect('sucursal', value)} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <DivFormGroup name='estatus_servicios' required title='Estatus'>
                  <Select
                    className='basic-single'
                    classNamePrefix='select'
                    value={estatus}
                    isLoading={isLoading}
                    name='estatus_servicios'
                    placeholder='Seleccione una opción'
                    options={estatus_servicios}
                    onChange={(value) => onSelect('estatus', value)}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='8'>
                <DivFormGroup name='cliente' required title='Clientes'>
                  <SelectCliente value={cliente} name='cliente' onChange={onChangeCliente} />
                </DivFormGroup>
              </Col>
              <Col sm='4'>
                <FormInput title='RFC' disabled name='clienteRfc' onChange={onChange} value={cliente ? cliente.rfc : ''} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='domicilioId' required title='Domicilio instalación'>
                  <SelectDomicilioCliente
                    value={domicilioId}
                    name='domicilioId'
                    onChange={(value) => onSelect('domicilioId', value)}
                    options={domicilios}
                    isDisabled={!cliente}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='domicilioClienteId' required title='Domicilio cliente'>
                  <SelectDomicilioCliente
                    value={domicilioClienteId}
                    name='domicilioClienteId'
                    onChange={(value) => onSelect('domicilioClienteId', value)}
                    options={domicilios}
                    isDisabled={!cliente}
                  />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <DivFormGroup name='contactoClienteId' required title='Contacto solicitante'>
                  <SelectContactoCliente
                    value={contacto}
                    name='contacto'
                    onChange={(value) => onSelect('contacto', value)}
                    options={contactos}
                    isDisabled={!cliente}
                  />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <h4>Diagnóstico preliminar</h4>
              </Col>
            </Row>
            <Row>
              <Col sm='3'>
                <DivFormGroup name='soluciones_solicitadas_id' required title='Soluciones solicitadas'>
                  <MultiSelectSolucionesSolicitadas
                    value={soluciones_solicitadas_id}
                    onChange={(value) => onSelect('soluciones_solicitadas_id', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <DivFormGroup name='tipos_servicios_solicitados_id' required title='Tipos servicios solicitados'>
                  <MultiSelectTiposServiciosSolicitados
                    value={tipos_servicios_solicitados_id}
                    onChange={(value) => onSelect('tipos_servicios_solicitados_id', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <DivFormGroup name='tipos_mano_obra_id' required title='Tipo de mano de obra'>
                  <SelectTipoManoDeObra
                    value={tipos_mano_obra_id}
                    name='tipos_mano_obra_id'
                    onChange={(value) => onSelect('tipos_mano_obra_id', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <DivFormGroup name='tipos_cobro_equipo_id' required title='Tipo de cobro de equipo'>
                  <SelectTipoCobroEquipo
                    value={tipos_cobro_equipo_id}
                    name='tipos_cobro_equipo_id'
                    onChange={(value) => onSelect('tipos_cobro_equipo_id', value)}
                  />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <Row>
              <Col sm='12'>
                <h4>Detalles</h4>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <DivFormGroup name='descripcion' title='Descripción corta'>
                  <Input name='descripcion' value={descripcion} placeholder='descripcion' onChange={onChange} />
                </DivFormGroup>
              </Col>
              <Col sm='6'>
                <DivFormGroup name='referencia' title='Referencia'>
                  <Input name='referencia' value={referencia} onChange={onChange} placeholder='Referencia' />
                </DivFormGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Saving saving={isLoading} />
                <button onClick={handleRegister} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </form>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ordenes de servicios' title='Editar orden de servicio'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Edit.propTypes = {};

export default Edit;
