import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { FormInput } from '@controls';
import { useStoreNotification } from '@stores/catalogs.store';

const INIT_STATE = {
  showModal: false,
  codigo: '',
  nombre: '',
  sucursalId: null,
};

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    ...INIT_STATE,
    isLoading: false,
    sucursales: [],
  });

  const close = () => {
    setState({
      ...INIT_STATE,
      sucursales: state.sucursales,
    });
  };

  const open = async () => {
    const {
      data: { sucursales },
    } = await getRequest({ url: `services-catalog` });
    setState({
      ...state,
      sucursales,
      showModal: true,
      isLoading: false,
    });
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { codigo, nombre, sucursalId } = state;
    const data = {
      codigo,
      nombre,
      sucursalId: sucursalId?._id,
    };

    if (validations(data)) {
      await postRequest({ url: `listas-precios`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  const { showModal, codigo, nombre, sucursalId, isLoading, sucursales } = state;

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' size='lg' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput title='Codigo' required name='codigo' onChange={(e) => setState({ ...state, codigo: e.target.value })} value={codigo} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput title='Nombre' required name='nombre' onChange={(e) => setState({ ...state, nombre: e.target.value })} value={nombre} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='sucursalId' title='Sucursal' required>
              <Select
                name='sucursalId'
                value={sucursalId}
                isClearable
                onChange={(value) => setState({ ...state, sucursalId: value })}
                options={sucursales}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                placeholder='Seleccione una sucursal'
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  loadData: PropTypes.func.isRequired,
};

export default New;
