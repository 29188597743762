import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon, RowsNotFound } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import Required from '@controls/Required';
import RowContacto from '../components/RowContacto';
import { useStoreNotification } from '@stores/catalogs.store';

const Contactos = ({ id, listContactos, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [listContactosState, setListContactosState] = useState([]);

  const close = () => {
    setShowModal(false);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `customers/contactos/${id}` });
    setListContactosState(
      data.map((contacto) => ({
        nombre: contacto.name,
        paterno: contacto.paterno,
        materno: contacto.materno,
        correo: contacto.email,
        telefono: contacto.phone,
        tipos: contacto.tipos.filter((x) => x && x.tipoContactoId).map((tipo) => tipo.tipoContactoId),
      }))
    );
    setShowModal(true);
  };

  const validations = (params) => {
    let validate = {
      success: false,
      message: '',
    };

    if (
      params.listContactos.filter(
        (contacto) =>
          contacto.tipos.length === 0 || isEmpty(contacto.nombre + '') || (isEmpty(contacto.correo + '') && isEmpty(contacto.telefono + ''))
      ).length > 0
    ) {
      validate.message = 'Los contactos requieren tipo contacto, nombre, teléfono o correo electronico.';
    } else {
      validate.success = true;
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const handleRegistrar = async (event) => {
    event.preventDefault();
    const body = {
      listContactos: listContactosState.map((x) => ({
        ...x,
        tipos: x.tipos.map((y) => y._id),
      })),
    };
    console.log(body, listContactosState);
    if (validations(body)) {
      await postRequest({ url: `customers/${id}/contactos`, body });
      close();
      if (loadData) loadData();
    }
  };

  const addContact = () => {
    setListContactosState([
      ...listContactosState,
      {
        nombre: '',
        correo: '',
        telefono: '',
        tipos: [],
      },
    ]);
  };

  const removedContacto = (index) => {
    setListContactosState(listContactosState.filter((_, i) => i !== index));
  };

  const onChangeContacto = (index, contacto) => {
    setListContactosState(listContactosState.map((c, i) => (i === index ? { ...contacto } : c)));
  };

  const renderRowsContactos = () => {
    return listContactosState.map((contacto, i) => (
      <RowContacto key={i} contacto={contacto} onChange={(contacto) => onChangeContacto(i, contacto)} onRemove={() => removedContacto(i)} />
    ));
  };

  const rowsContactos = listContactosState.length > 0 ? renderRowsContactos() : <RowsNotFound colSpan={7} message='No se han agregado contactos' />;

  return (
    <>
      <Button variant='outline-primary' size='sm' onClick={open}>
        <Icon icon='user' /> {listContactos.length}
      </Button>
      <Modal show={showModal} onHide={close} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Contactos</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleRegistrar}>
          <Modal.Body>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Nuevo Contacto</Tooltip>}>
                  <Button onClick={addContact} className='btn btn-outline-success btn-sm pull-right' type='button'>
                    <Icon icon='plus' />
                  </Button>
                </OverlayTrigger>
                <h2 className='panel-title'>
                  <Icon icon='user' style={{ fontSize: '0.8em' }} /> Contactos
                </h2>
              </div>
              <table className='table table-condensed table-hover table-striped'>
                <thead>
                  <tr>
                    <th width='15%'>
                      Tipo <Required />
                    </th>
                    <th width='15%'>
                      Nombre <Required />
                    </th>
                    <th width='15%'>Apellido P. </th>
                    <th width='15%'>Apellido M.</th>
                    <th width='10%'>
                      Teléfono <Required />
                    </th>
                    <th width='15%'>
                      Correo
                      <Required />
                    </th>
                    <th width='2%'></th>
                  </tr>
                </thead>
                <tbody>{rowsContactos}</tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' className='btn btn-primary pull-right mr-5'>
              Aceptar
            </Button>
            <Button onClick={close} className='btn btn-default pull-right mr-5' type='button'>
              Cancelar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

Contactos.propTypes = {
  id: PropTypes.number.isRequired,
  listContactos: PropTypes.array.isRequired,
  tiposContactos: PropTypes.array,
  refresh: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Contactos;
