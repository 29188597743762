import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DivFormGroup, Input, FormInput } from '@controls';
import NewCRM from '../cotizaciones/NewCRM';
import EditCRM from '../cotizaciones/EditCRM';
import SelectTipoOrdenServicio from '@components/forms/SelectTipoOrdenServicio';
import SelectTipoManoDeObra from '@components/forms/SelectTipoManoDeObra';
import SelectTipoCobroEquipo from '@components/forms/SelectTipoCobroEquipo';
import MultiSelectSolucionesSolicitadas from '@components/forms/MultiSelectSolucionesSolicitadas';
import MultiSelectTiposServiciosSolicitados from '@components/forms/MultiSelectTiposServiciosSolicitados';
import SelectCRM from '@components/forms/SelectCRM';

const FormOrdenServicio = ({
  tipoOrdenServicioId,
  numero_orden_servicio,
  tipos_mano_obra_id,
  tipos_cobro_equipo_id,
  soluciones_solicitadas_id,
  tipos_servicios_solicitados_id,
  horas,
  clienteRegistro,
  descripcion,
  cotizacion,
  onSelect,
  onChange,
  onChangeCliente,
  onSelectTipoOrdenServicio,
  referencia,
}) => {
  return (
    <>
      <Row>
        <Col sm={3}>
          <DivFormGroup name='tipoOrdenServicioId' required title='Tipo orden de servicio'>
            <SelectTipoOrdenServicio value={tipoOrdenServicioId} onChange={onSelectTipoOrdenServicio} name='tipoOrdenServicioId' />
          </DivFormGroup>
        </Col>
        <Col sm={3}>
          <FormInput
            title='Número de orden'
            placeholder='Num. de orden'
            name='numero_orden_servicio'
            onChange={onChange}
            value={numero_orden_servicio}
          />
        </Col>
        <Col sm={3}>
          <DivFormGroup name='tipos_mano_obra_id' required title='Cobro mano de obra'>
            <SelectTipoManoDeObra value={tipos_mano_obra_id} onChange={(value) => onSelect('tipos_mano_obra_id', value)} name='tipos_mano_obra_id' />
          </DivFormGroup>
        </Col>
        <Col sm={3}>
          <DivFormGroup name='tipos_cobro_equipo_id' required title='Cobro equipo'>
            <SelectTipoCobroEquipo
              value={tipos_cobro_equipo_id}
              onChange={(value) => onSelect('tipos_cobro_equipo_id', value)}
              name='tipos_cobro_equipo_id'
            />
          </DivFormGroup>
        </Col>
        <Col sm={4}>
          <DivFormGroup name='soluciones_solicitadas_id' required title='Soluciones solicitadas'>
            <MultiSelectSolucionesSolicitadas
              name='soluciones_solicitadas_id'
              value={soluciones_solicitadas_id}
              onChange={(value) => onSelect('soluciones_solicitadas_id', value)}
            />
          </DivFormGroup>
        </Col>
        <Col sm={4}>
          <DivFormGroup name='tipos_servicios_solicitados_id' required title='Tipos servicios solicitados'>
            <MultiSelectTiposServiciosSolicitados
              name='tipos_servicios_solicitados_id'
              value={tipos_servicios_solicitados_id}
              onChange={(value) => onSelect('tipos_servicios_solicitados_id', value)}
            />
          </DivFormGroup>
        </Col>
        <Col sm={4}>
          <DivFormGroup name='horas' title='Horas cotizadas'>
            <Input name='horas' value={horas} placeholder='horas' onChange={onChange} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={10}>
          <DivFormGroup name='registroclienteName' required title='Registro de cliente'>
            <SelectCRM
              onChange={onChangeCliente}
              name='clienteRegistro'
              value={clienteRegistro}
              customerId={cotizacion?.customer?._id}
              domicilioId={cotizacion?.customer?.domicilio?._id}
            />
          </DivFormGroup>
        </Col>
        <Col sm={2}>
          <br />
          {!clienteRegistro ? (
            <NewCRM refresh={onChangeCliente} cliente={cotizacion?.customer} domicilio={cotizacion?.customer?.domicilio} />
          ) : (
            <EditCRM id={clienteRegistro?._id} refresh={onChangeCliente} />
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <DivFormGroup name='descripcion' required title='Descripción corta'>
            <Input name='descripcion' value={descripcion} placeholder='Descripción corta' onChange={onChange} />
          </DivFormGroup>
        </Col>
        <Col sm={12}>
          <DivFormGroup name='referencia' title='Referencia'>
            <Input name='referencia' value={referencia} disabled placeholder='Referencia' />
          </DivFormGroup>
        </Col>
      </Row>
    </>
  );
};

FormOrdenServicio.propTypes = {
  tipoOrdenServicioId: PropTypes.object,
  numero_orden_servicio: PropTypes.string,
  tipos_mano_obra_id: PropTypes.object,
  tipos_cobro_equipo_id: PropTypes.object,
  soluciones_solicitadas_id: PropTypes.object,
  tipos_servicios_solicitados_id: PropTypes.object,
  horas: PropTypes.string,
  clienteRegistro: PropTypes.object,
  descripcion: PropTypes.string,
  cotizacion: PropTypes.object,
  setState: PropTypes.func,
  onSelectTipoOrdenServicio: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onChangeCliente: PropTypes.func,
  referencia: PropTypes.string,
};

export default FormOrdenServicio;
