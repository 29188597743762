import { FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ id, nombre: initialNombre, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState(initialNombre);
  const [isLoading] = useState(false);

  const close = () => setShowModal(false);
  const open = () => {
    setShowModal(true);
    setNombre(initialNombre);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      nombre: nombre.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `medios_comunicaciones/${id}`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='pencil' title='Editar' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' size='lg' onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput title='Nombre' placeholder='Nombre' required name='nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  clave: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Edit;
