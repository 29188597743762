import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';
import RowCredito from './RowCredito';

const TableCreditos = ({ customer }) => {
  const renderTableCreditos = () => {
    const { listCreditos } = customer;
    const rows = listCreditos.map((c, i) => <RowCredito key={i} credito={c} />);
    return (
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th width='50%'>Límite de Crédito</th>
            <th width='50%'>Días de Plazo</th>
          </tr>
        </thead>
        <tbody>{listCreditos.length > 0 ? rows : <RowsNotFound colSpan={2} message='No se han agregado créditos' />}</tbody>
      </table>
    );
  };

  if (!customer || (customer.listCreditos && customer.listCreditos.length === 0)) return null;
  const tableCreditos = renderTableCreditos();

  return (
    <div className='col-sm-12'>
      <div className='panel panel-primary'>
        <div className='panel-heading'>
          <h4 className='panel-title'>
            <Icon icon='usd' /> Créditos
          </h4>
        </div>
        {tableCreditos}
      </div>
    </div>
  );
};

TableCreditos.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default TableCreditos;
