import { DivFormGroup, FilterSelectNuevo, Label, RedirectTooltip } from '@controls';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import RemoverProspectoAgente from './RemoverProspectoAgente';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const AgentesProspectos = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [prospectoObj, setProspectoObj] = useState(null);
  const [prospectoName, setProspectoName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { agenteId } = params;
    const { data } = await getRequest({ url: `agentes/${agenteId}` });
    setData(data);
    setLoading(false);
  };

  const onChangeProspectoInput = (e) => {
    setProspectoName(e);
  };

  const onChangeProspecto = async (itemSelect) => {
    if (itemSelect !== null) {
      const { agenteId } = params;
      setProspectoObj(itemSelect.label);
      const prospectoId = itemSelect.value._id;
      const body = { prospectoId };
      await postRequest({ url: `agente-prospecto/${agenteId}`, body });
      loadData();
    }
  };

  const returnRowsProspectos = () => {
    const { agenteId } = params;
    return data.listProspectos.map((c, i) => (
      <tr key={i}>
        <td>{renderNombreCliente(c)}</td>
        <td>
          <RemoverProspectoAgente id={i} agenteId={agenteId} prospectoId={c._id} nombre={renderNombreCliente(c)} loadData={loadData} />
          <RedirectTooltip
            id={1}
            url={`/seguimientos/seguimiento-prospecto/${c._id}`}
            labelTooltip='Seguimiento prospecto'
            icon='screenshot'
            className='btn btn-outline-primary'
          />
        </td>
      </tr>
    ));
  };

  const returnData = () => (
    <>
      <Row>
        <Col>
          <DivFormGroup>
            <Label name='prospectoId' title='Busqueda de Prospectos' />
            <FilterSelectNuevo
              async={true}
              disabled={false}
              value={prospectoObj}
              inputValue={prospectoName}
              onInputChange={onChangeProspectoInput}
              onChangeOption={onChangeProspecto}
              url={`prospectos-agentes-autocomplete`}
              fields={['_id', 'name']}
            />
          </DivFormGroup>
        </Col>
      </Row>
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th width='80%'>PROSPECTOS</th>
            <th width='20%'></th>
          </tr>
        </thead>
        <CbTableBody colSpan={2}>{returnRowsProspectos()}</CbTableBody>
      </CbPanelTableResponsive>
    </>
  );

  return (
    <Module onClickBack={window.history.back} parent='Agentes' title='Prospectos asignados'>
      {loading && data === null ? <Loading /> : returnData()}
    </Module>
  );
};

AgentesProspectos.propTypes = {};

export default AgentesProspectos;
