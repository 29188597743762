import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Select from 'react-select';
import { cleanNumber } from '@utils/formatter';
import { hasPermission } from '@utils/permissions';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card } from 'react-bootstrap';
import { TIPOS_CLIENTES } from '@utils/cotizaciones';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectCreateOrUpdateDomicilio from '@components/forms/SelectCreateOrUpdateDomicilio';
import SelectTipoLevantamiento from '@components/forms/SelectTipoLevantamiento';
import SelectMoneda from '@components/forms/SelectMoneda';
import SelectAgente from '@components/forms/SelectAgente';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectClienteProspecto from '@components/forms/SelectClienteProspecto';
import InputCurrency from '@components/forms/InputCurrency';
import InputPercentage from '@components/forms/InputPercentage';

const FormEncabezado = ({
  numeroOrden,
  fecha,
  fecha_vencimiento,
  estatus,
  sucursalId,
  monedaId,
  cliente,
  tipoCambio,
  estatus_levantamientos,
  domicilioId,
  domicilios,
  agenteId,
  anticipo,
  tipoLevantamientoId,
  setState,
  onSelectTipoLevantamiento,
}) => {
  const { addNotification: notification } = useStoreNotification();
  const refDomicilio = useRef(null);

  const onChange = (event) => {
    const { name, value } = event.target;
    setState({ [name]: value });
  };

  const onSelect = (campo, value) => {
    setState({
      [campo]: value,
    });
  };

  const onChangeTipoCambio = (event) => {
    const { name, value } = event.target;
    setState({ [name]: cleanNumber(value) });
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState({
        agenteId: itemSelect,
        maximo_descuento: itemSelect.maximo_descuento,
      });
    } else {
      setState({
        agenteId: null,
      });
    }
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      const domicilios = itemSelect.tipo_cliente === 'CLIENTE' ? itemSelect.listDomicilios : [{ ...itemSelect.domicilio, _id: 1 }];
      const domicilioId = domicilios.length === 1 ? domicilios[0] : null;
      if (domicilios.length > 1 && refDomicilio.current) setTimeout(() => refDomicilio.current.inputRef.focus(), 300);
      if (itemSelect.clasification) {
        if (itemSelect.clasification.opcion === 'ADVERTENCIA') {
          notification({ title: 'Advertencia', message: 'Este cliente cuenta con saldo vencido o limite de credito.', type: 'warning' });
        } else if (itemSelect.clasification.opcion === 'OCUPA_AUTORIZACION') {
          notification({
            title: 'Advertencia',
            message: 'Este cliente cuenta con saldo vencido o limite de credito y requiere autorizacion para cotizarle.',
            type: 'warning',
          });
        } else if (itemSelect.clasification.opcion === 'SIN_AUTORIZACION') {
          return notification({
            title: 'Advertencia',
            message: 'Este cliente cuenta con saldo vencido o limite de credito asi que no es posible cotizarle.',
            type: 'error',
          });
        }
      }
      setState({
        tipo_cliente: TIPOS_CLIENTES.find((x) => x.value === itemSelect.tipo_cliente),
        cliente: itemSelect,
        domicilioId,
        domicilios: domicilios,
      });
    } else {
      setState({
        cliente: null,
        domicilioId: null,
        domicilios: [],
      });
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col xs={6} md={3}>
                <DivFormGroup name='tipoLevantamientoId' required title='Tipo de cotización'>
                  <SelectTipoLevantamiento value={tipoLevantamientoId} onChange={onSelectTipoLevantamiento} name='tipoLevantamientoId' />
                </DivFormGroup>
              </Col>
              <Col xs={6} md={3}>
                <FormInput required title='Número' placeholder='Número' disabled name='numeroOrden' onChange={onChange} value={numeroOrden} />
              </Col>
              <Col xs={6} md={3}>
                <FormInput
                  title='Fecha'
                  type='date'
                  required
                  name='fecha'
                  onChange={onChange}
                  disabled={!hasPermission('cambiar-fecha')}
                  value={fecha}
                />
              </Col>
              <Col xs={6} md={3}>
                <FormInput title='Vencimiento' type='date' required name='fecha_vencimiento' onChange={onChange} value={fecha_vencimiento} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <DivFormGroup name='estatus' required title='Estatus'>
                  <Select
                    value={estatus}
                    placeholder='Seleccione'
                    options={estatus_levantamientos}
                    onChange={(value) => onSelect('estatus', value)}
                  />
                </DivFormGroup>
              </Col>
              <Col>
                <DivFormGroup name='monedaId' required title='Moneda'>
                  <SelectMoneda value={monedaId} onChange={(value) => onSelect('monedaId', value)} name='monedaId' />
                </DivFormGroup>
              </Col>
              <Col>
                <DivFormGroup name='tipoCambio' title='Tipo de cambio'>
                  <InputCurrency
                    disabled={!hasPermission('cambiar-tipo-cambio')}
                    value={tipoCambio}
                    onChange={onChangeTipoCambio}
                    name='tipoCambio'
                  />
                </DivFormGroup>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col xs={6} md={4}>
                <DivFormGroup name='anticipo' title='Anticipo'>
                  <InputPercentage name='anticipo' onChange={onChange} value={anticipo} disabled={!hasPermission('cambiar-anticipo')} />
                </DivFormGroup>
              </Col>
              <Col xs={6} md={4}>
                <DivFormGroup name='agente' required title='Agente'>
                  <SelectAgente value={agenteId} onChange={onChangeAgente} name='agenteId' />
                </DivFormGroup>
              </Col>
              <Col>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} name='sucursalId' />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={9}>
                <DivFormGroup name='cliente' required title='Cliente ó prospecto'>
                  <SelectClienteProspecto value={cliente} onChange={onChangeCliente} />
                </DivFormGroup>
              </Col>
              <Col xs={12} md={3}>
                <FormInput title='RFC' disabled name='clienteRfc' onChange={onChange} value={cliente ? cliente.rfc : ''} />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectCreateOrUpdateDomicilio
                  customerId={cliente?._id}
                  domicilios={domicilios}
                  value={domicilioId}
                  onChange={(value) => onSelect('domicilioId', value)}
                  setDomicilios={(value) => setState({ domicilios: value })}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

FormEncabezado.propTypes = {
  numeroOrden: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fecha: PropTypes.string,
  fecha_vencimiento: PropTypes.string,
  estatus: PropTypes.object,
  tipoLevantamientoId: PropTypes.object,
  sucursalId: PropTypes.object,
  almacenId: PropTypes.object,
  monedaId: PropTypes.object,
  cliente: PropTypes.object,
  agente: PropTypes.object,
  maximo_descuento: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tipoCambio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  anticipo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  estatus_levantamientos: PropTypes.array,
  domicilioId: PropTypes.object,
  domicilios: PropTypes.array,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  agenteId: PropTypes.object,
  onSelectTipoLevantamiento: PropTypes.func,
};

export default FormEncabezado;
