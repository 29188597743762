import { TIPOS_DOMICILIOS } from '@config/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';

const RowDomicilio = ({ domicilio }) => {
  const hasValue = (field) => field !== null && field !== '';

  const { nombre, monitoreo, calle, exterior, interior, colonia, codigo_postal, municipio, estado, pais, tipo, entre_calles, referencias } =
    domicilio;

  let entreCalles = '';

  if (hasValue(entre_calles.calle1) && !hasValue(entre_calles.calle2)) {
    entreCalles = entre_calles.calle1;
  }

  if (hasValue(entre_calles.calle1) && hasValue(entre_calles.calle2)) {
    entreCalles = `${entre_calles.calle1} y ${entre_calles.calle2}`;
  }

  if (!hasValue(entre_calles.calle1) && hasValue(entre_calles.calle2)) {
    entreCalles = entre_calles.calle2;
  }

  let ubicacion = '';

  if (hasValue(referencias)) {
    if (entreCalles !== '') {
      ubicacion = (
        <div>
          Entre {entreCalles}
          <br />
          Referencia {referencias}
        </div>
      );
    } else {
      ubicacion = `Referencia ${referencias}`;
    }
  } else if (entreCalles !== '') {
    ubicacion = `Entre ${entreCalles}`;
  }

  return (
    <tr>
      <td>{monitoreo}</td>
      {tipo === TIPOS_DOMICILIOS.SUCURSAL ? (
        <td>Sucursal</td>
      ) : (
        <td>
          <Icon icon='tag' /> Principal
        </td>
      )}
      <td>{nombre}</td>
      <td>
        {calle} {exterior}, {interior},
        <br />
        Colonia {colonia} C.P. {codigo_postal},
        <br />
        {municipio} {estado}, {pais}
      </td>
      <td>{ubicacion}</td>
    </tr>
  );
};

RowDomicilio.propTypes = {
  domicilio: PropTypes.object.isRequired,
};

export default RowDomicilio;
