import { DivFormGroup, Input, TextArea } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditRecipes = ({ _id, refresh }) => {
  const { addNotification: notification } = useStoreNotification();

  const [showModal, setShowModal] = useState(false);
  const [recipe, setRecipe] = useState('');
  const [note, setNote] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = async () => {
    const {
      data: { recipe, note },
    } = await getRequest({ url: `recipes/${_id}` });
    setShowModal(true);
    setRecipe(recipe);
    setNote(note);
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      recipe: recipe.trim(),
      note: note.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `recipes/${_id}`, body: data });
      refresh();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.recipe + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='recipe' title='Nombre'>
              <Input name='recipe' onChange={(e) => setRecipe(e.target.value)} value={recipe} />
            </DivFormGroup>
            <DivFormGroup name='note' title='Nota'>
              <TextArea name='note' onChange={(e) => setNote(e.target.value)} value={note} rows={3} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditRecipes.propTypes = {
  id: PropTypes.number.isRequired,
  _id: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};


export default EditRecipes;
