import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditRubros = ({ rubroId, establecimientoId, loadRubros }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [rubro, setRubro] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setRubro('');
  };

  const open = async () => {
    const {
      data: { rubro },
    } = await getRequest({ url: `rubro/${rubroId}` });
    setShowModal(true);
    setRubro(rubro);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = { rubro: rubro.trim(), establecimientoId };
    if (validations(data)) {
      await putRequest({ url: `rubros/${rubroId}`, body: data });
      loadRubros();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.rubro + '')) {
      validate.success = false;
      validate.message = 'Rubro es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='rubro' title='Rubro' required>
              <Input name='rubro' onChange={(e) => setRubro(e.target.value)} value={rubro} placeholder='Rubro' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditRubros.propTypes = {
  id: PropTypes.number.isRequired,
  establecimientoId: PropTypes.string.isRequired,
  rubroId: PropTypes.string.isRequired,
  loadRubros: PropTypes.func.isRequired,
};

export default EditRubros;
