import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import ListEsquemas from './ListEsquemas';
import NewEsquemas from './NewEsquemas';

const Esquemas = () => {
  const [catalogEsquemas, setCatalogEsquemas] = useState([]);
  const [anticipos, setAnticipos] = useState([]);
  const [plazos, setPlazos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  useEffect(() => {
    handleSearch();
  }, [activePage, search]);

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count, anticipos, plazos } = await postRequest({ url: `esquemas/search`, params: { page, limit: pagination }, body });
    setCatalogEsquemas(data);
    setCount(count);
    setAnticipos(anticipos);
    setPlazos(plazos);
    setLoading(false);
  };

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  return (
    <Module title='Esquemas de Pago'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <IfPermission action='crear'>
          <NewEsquemas {...{ catalogEsquemas, anticipos, plazos, loading, activePage, count, pagination, search }} loadEsquemas={handleSearch} />
        </IfPermission>
      </Search>
      <Loading loading={loading}>
        <ListEsquemas
          {...{ catalogEsquemas, anticipos, plazos, loading, activePage, count, pagination, search }}
          catalogEsquemas={catalogEsquemas}
          loadEsquemas={handleSearch}
        />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

Esquemas.propTypes = {};

export default Esquemas;
