import PropTypes from 'prop-types';
import React from 'react';
const obtenerClase = (orden, tipo, campo) => {
  if (orden !== campo) return 'sorting';
  if (tipo === -1) return 'sorting_desc';
  return 'sorting_asc';
};
const CbTableThSort = ({ children, orden, tipo, name, onSort, width }) => {
  return (
    <th className={obtenerClase(orden, tipo, name)} onClick={() => onSort(orden, tipo, name)} width={width}>
      {children}
    </th>
  );
};

CbTableThSort.propTypes = {
  children: PropTypes.node.isRequired,
  colSpan: PropTypes.number,
  orden: PropTypes.string.isRequired,
  tipo: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default CbTableThSort;
