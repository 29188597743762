import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteStoreHouse = ({ storehouseId, name, loadStoreHouse }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`storehouse/${storehouseId}`}
      description={`¿Confirma que desea eliminar el almacén <strong><u> ${name}</u> </strong>?`}
      loadData={loadStoreHouse}
    />
  );
};

DeleteStoreHouse.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  storehouseId: PropTypes.string.isRequired,
  loadStoreHouse: PropTypes.func.isRequired,
};

export default DeleteStoreHouse;
