import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableThSort from '@cbcomponents/CbTableThSort';
import DetailCustomer from '@components/customers/DetailCustomer';
import Edit from './Edit';
import Evidencias from './Evidencias';
import RowDomicilio from './RowDomicilio';
import { ButtonGroup } from 'react-bootstrap';
import OrdenesServicios from './OrdenesServicios';

const List = ({ list, orden, tipo, refresh, onChangeOrden }) => {
  const [state, setState] = useState({
    show: true,
    openDropdown: true,
  });

  const renderRows = () => {
    return list.map(({ _id, folio, descripcion, customerId, domicilioId, contactoId }, key) => (
        <tr key={key}>
          <td>{folio}</td>
          <td>{descripcion}</td>
          <td>
            <DetailCustomer customer={customerId} />
          </td>
          <td>
            <RowDomicilio address={domicilioId} />
          </td>
          <td>
            {contactoId && `${contactoId.name} ${contactoId.paterno} ${contactoId.materno} (${contactoId.phone}) (${contactoId.email})`.toUpperCase()}
          </td>
          <td style={{ textAlign: 'right' }}>
            <ButtonGroup>
              <Edit id={_id} refresh={refresh} />
              <Evidencias id={_id} loadData={refresh} />
              <OrdenesServicios id={_id} />
              <Confirmation
                btnIcon='trash'
                typeRequest='deleteRequest'
                btnType='outline-danger'
                action='Eliminar'
                url={`customers-registros/${_id}`}
                description={`¿Confirma que desea eliminar el registro de cliente: <strong><u>${folio}</u></strong>?`}
                loadData={refresh}
              />
            </ButtonGroup>
          </td>
        </tr>
      ));
  };

  const establecerOrden = (orden, tipo, campo) => {
    setState({ ...state, orden: campo, tipo: orden === campo ? tipo * -1 : -1 });
    onChangeOrden(state.orden, state.tipo);
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <CbTableThSort width='5%' orden={orden} tipo={tipo} name='folio' onSort={establecerOrden}>
            #
          </CbTableThSort>
          <CbTableThSort width='5%' orden={orden} tipo={tipo} name='descripcion' onSort={establecerOrden}>
            Descripción
          </CbTableThSort>
          <CbTableThSort width='35%' orden={orden} tipo={tipo} name='razon_social' onSort={establecerOrden}>
            Cliente
          </CbTableThSort>
          <th width='35%'>Domicilio</th>
          <th width='35%'>Contacto</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  estados: PropTypes.array.isRequired,
  tiposContactos: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  onChangeOrden: PropTypes.func.isRequired,
  orden: PropTypes.string.isRequired,
  tipo: PropTypes.number.isRequired,
};

export default List;
