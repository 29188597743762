import { FORZOSO, INDETERMINADO } from '@config/constants';
import { DivFormGroup, Label, NumberPicker } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { useStoreNotification } from '@stores/catalogs.store';

const NewCondicionesPagoServicios = ({ loadCondicionesPagoServicios }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [plazo, setPlazo] = useState(0);
  const [tipo, setTipo] = useState(INDETERMINADO);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setTipo(INDETERMINADO);
    setPlazo(0);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      tipo,
      plazo: typeof plazo === 'undefined' ? plazo : plazo.trim(),
    };

    if (validations(data)) {
      await postRequest({ url: `condicionespagoservicios`, body: data });
      loadCondicionesPagoServicios();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.tipo + '')) {
      validate.success = false;
      validate.message = 'Tipo es requerido.';
    }

    if (params.tipo === FORZOSO) {
      if (isEmpty(params.plazo + '')) {
        validate.success = false;
        validate.message = 'Plazo a meses es requerido.';
      } else if (params.plazo <= 0) {
        validate.success = false;
        validate.message = 'Plazo a meses debe ser mayor a cero.';
      }
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Nuevo' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup title='Tipo' required>
              <div>
                <label className='radio-inline'>
                  <input
                    type='radio'
                    id='indeterminado'
                    name='tipo'
                    onChange={() => setTipo(INDETERMINADO)}
                    value={INDETERMINADO}
                    defaultChecked={true}
                  />{' '}
                  Indeterminado
                </label>
                <label className='radio-inline'>
                  <input type='radio' id='forzoso' name='tipo' onChange={() => setTipo(FORZOSO)} value={FORZOSO} /> Forzoso
                </label>
              </div>
            </DivFormGroup>
          </Col>
          {tipo === FORZOSO && (
            <Col>
              <Label title='Plazo a meses' required></Label>
              <div>
                <NumberPicker name='plazo' defaultValue={plazo} min={0} onChange={(value) => setPlazo(value)} />
              </div>
            </Col>
          )}
        </Row>
      </CbModal>
    </>
  );
};

NewCondicionesPagoServicios.propTypes = {
  loadCondicionesPagoServicios: PropTypes.func.isRequired,
};

export default NewCondicionesPagoServicios;
