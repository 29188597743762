import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest } from '@utils/api';
import { switchEstado } from '@utils/general';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';

const DetailKits = ({ planMantenimientoId, nombre }) => {
  const [showModal, setShowModal] = useState(false);
  const [listaKit, setListaKit] = useState([]);

  const close = () => {
    setShowModal(false);
    setListaKit([]);
  };

  const open = async () => {
    const {
      data: { listaKit },
    } = await getRequest({ url: `planes-kits/${planMantenimientoId}` });
    setListaKit(listaKit);
    setShowModal(true);
  };

  const renderKits = () => {
    return listaKit.map((k, i) => {
      const { kitServicioId } = k;

      return (
        <div className='panel panel-primary' key={i}>
          <div className='panel-heading'>
            <h3 className='panel-title'>{kitServicioId.nombre}</h3>
          </div>
          <table className='table table-condensed table-hover'>
            <thead>
              <tr>
                <th width='20%'>Frecuencia</th>
                <th width='20%'>Cantidad</th>
                <th width='60%'>Pieza</th>
              </tr>
            </thead>
            <tbody>
              {kitServicioId.detalle.map((d, x) => {
                const {
                  piezaId: { nombre },
                  cantidad,
                  temporalidad,
                } = d;
                const temporalidadLabel = switchEstado(temporalidad);

                return (
                  <tr key={x}>
                    <td>{typeof temporalidadLabel !== 'undefined' ? temporalidadLabel.text : ''}</td>
                    <td>
                      <strong>{cantidad > 0 ? cantidad : ''}</strong>
                    </td>
                    <td>{nombre}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    });
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='zoom-in' title='Ver detalles' />
      <CbModal
        show={showModal}
        title={
          <>
            Kit asignados a <strong>{nombre}</strong>
          </>
        }
        onClose={close}
      >
        {renderKits()}
      </CbModal>
    </>
  );
};

DetailKits.propTypes = {
  id: PropTypes.number.isRequired,
  planMantenimientoId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
};

export default DetailKits;
