import { PENDIENTE } from '@config/constants';
import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { RowsNotFound } from '@stateless';

const Table = ({ list, loadData }) => {
  const onChange = () => {};

  const renderRows = () => {
    return list.map((r, i) => {
      const { _id, fecha, numero_ajuste, estado, moneda, sucursal, almacen, estatus_ajuste } = r;
      return (
        <tr key={i}>
          <td className='text-center'>
            <input
              type='checkbox'
              name='ajustes[]'
              id={`ajuste-${i}`}
              onChange={onChange}
              value={_id}
              disabled={estado !== PENDIENTE || moneda !== 'PESO'}
            />
          </td>
          <td>{numero_ajuste}</td>
          <td>{moment(fecha).local().format('DD/MM/YYYY HH:mm')}</td>
          <td>{sucursal.name}</td>
          <td>{almacen.name}</td>
          <td className='with-actions'>
            <span className={`label label-primary`}>{estatus_ajuste}</span>
            <span className='actions'>
              <RedirectTooltip id={1} icon='search' url={`/ajustes/detalle/${_id}`} labelTooltip='Detalles' className='btn btn-sm btn-default' />
              <If condition={estado !== 'CANCELADA'}>
                <If.Then>
                  <Confirmation
                    id={_id}
                    btnIcon='remove'
                    typeConfirm='danger'
                    btnType='danger'
                    action='Cancelar ajuste'
                    url={`ajustes/cancelar/${_id}`}
                    description={`¿Confirma que desea CANCELAR el ajuste <strong>#${numero_ajuste}</strong> ?`}
                    loadData={loadData}
                  />
                </If.Then>
              </If>
            </span>
          </td>
        </tr>
      );
    });
  };

  const rows = list.length > 0 ? renderRows() : <RowsNotFound colSpan={8} />;

  return (
    <div className='panel panel-default panel-table'>
      <div className='table-responsive'>
        <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
          <thead>
            <tr>
              <th width='1%'>&nbsp;</th>
              <th width='10%'># ajuste</th>
              <th width='13%'>Fecha</th>
              <th width='10%'>Sucursal</th>
              <th width='10%'>Almacen</th>
              <th width='10%'>Estatus</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Table;
