import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeletePlazos from './DeletePlazos';
import EditPlazos from './EditPlazos';

const ListPlazos = ({ catalogPlazos, ...props }) => {
  const renderRows = () => {
    return catalogPlazos.map((row, item) => {
      const { _id, nombre, plazo, taza } = row;

      return (
        <tr key={item}>
          <td>{nombre}</td>
          <td>{plazo} meses</td>
          <td>
            {taza} {taza !== null ? '%' : null}
          </td>
          <td>
            <IfPermission action='eliminar'>
              <DeletePlazos id={item} plazosId={_id} nombre={nombre} plazo={plazo} taza={taza} {...props} btnClass='btn btn-outline-danger' />
            </IfPermission>
            <IfPermission action='editar'>
              <EditPlazos id={item} plazosId={_id} {...props} btnClass='btn btn-outline-info' />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='40%'>Nombre</th>
          <th width='20%'>Plazo</th>
          <th width='20%'>Tasa</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={4}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListPlazos.propTypes = {
  catalogPlazos: PropTypes.array.isRequired,
};

export default ListPlazos;
