import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import ListRow from './ListRow';

const List = ({ data, loadData }) => {
  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='30%'>Clave</th>
          <th>Nombre</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={4}>
        {data.map((row, item) => (
          <ListRow key={item} {...row} loadData={loadData} />
        ))}
      </CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default List;
