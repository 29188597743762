import Panel from '@controls/Panel';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, RowsNotFound } from '@stateless';
import RowContacto from './RowContacto';
import { Col, Row } from 'react-bootstrap';

const TableContactos = ({ customerId, directorio }) => {
  const renderDataEmpty = () => (
    <div className='well text-center'>
      <span className='lead'>
        El cliente no tiene contactos datos de alta, para agregar contactos click{' '}
        <Link to={`/clientes/editar/${customerId}`}>
          <strong>
            Aquí <Icon icon='pencil' />
          </strong>
        </Link>
      </span>
    </div>
  );

  const renderTableContactos = () => {
    return directorio.map((c, i) => {
      let titulo = '';

      if (typeof c.tituloContactoId !== 'undefined') {
        if (c.tituloContactoId !== null) {
          titulo = c.tituloContactoId.abreviatura;
        }
      }

      let extension = '';

      if (c.extension !== '') {
        extension = <div>Extensión {c.extension}</div>;
      }

      const rows = c.tipos.map((t, j) => {
        return <RowContacto key={j} {...t} contactoId={c._id} />;
      });

      return (
        <Panel
          key={i}
          type='primary'
          header={
            <div>
              {titulo} {c.name} {c.paterno} {c.materno}
            </div>
          }
        >
          <Row>
            <Col sm='3'>
              {c.mobile}
              <br />
              {c.mobile2}
            </Col>
            <Col sm='3'>
              {c.email}
              <br />
              {c.email2}
            </Col>
            <Col sm='3'>
              {c.phone}
              {extension}
            </Col>
            <Col sm='3'>
              {c.sexo === 'F' ? 'Femenino' : c.sexo === 'M' ? 'Masculino' : ''}
              <br />
              {c.fecha_nacimiento !== '' && c.fecha_nacimiento !== null ? moment(c.fecha_nacimiento).format('DD/MM/YYYY') : ''}
            </Col>
          </Row>

          <hr />

          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th width='20%'>Contacto</th>
                <th width='20%'>Nombre</th>
                <th width='5%'>Monitoreo</th>
                <th width='25%'>Domicilio</th>
                <th width='20%'>Referencias</th>
                <th width='5%'></th>
              </tr>
            </thead>
            <tbody>{c.tipos.length > 0 ? rows : <RowsNotFound colSpan={6} message='No se han agregado tipos de contactos' />}</tbody>
          </table>
        </Panel>
      );
    });
  };

  return directorio.length > 0 ? renderTableContactos() : renderDataEmpty();
};

TableContactos.propTypes = {
  customerId: PropTypes.string.isRequired,
  directorio: PropTypes.array.isRequired,
};

export default TableContactos;
