import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Col, Container, DropdownButton, DropdownItem, Row, Card } from 'react-bootstrap';
import { Module } from '@stateless';
import { hasPermission } from '@utils/permissions';
import AccesosDirectos from './components/AccesosDirectos';
import IndicadoresCotizaciones from './components/IndicadoresCotizaciones';
import IndicadoresVentas from './components/IndicadoresVentas';
import IndicadoresOrdenesServicio from './components/IndicadoresOrdenes';
import { ESTATUS_COTIZACIONES, ESTATUS_ORDENES } from '../config/constants';
import TableCotizacionesProximasAVencer from './components/TableCotizacionesProximasAVencer';
import TableSeguimientosPorAtender from './components/TableSeguimientosPorAtender';
import CardActividadesAgente from './components/CardActividadesAgente';
import TableVisitasPorAtender from './components/TableVisitasPorAtender';
import TableAgenda from './components/TableAgenda';
import TableProductosVendidos from './components/TableProductosVendidos';
import PrincipalCarousel from './components/PrincipalCarousel';
import { getRequest } from '@utils/api';
import useAuth from '../hooks/useAuth';

const Dashboard = () => {
  const { agente: authAgente } = useAuth();
  const [loading, setLoading] = useState(true);
  const [agenda, setAgenda] = useState([]);
  const [clientes, setClientes] = useState(0);
  const [prospectos, setProspectos] = useState(0);
  const [facturas, setFacturas] = useState(0);
  const [pagos, setPagos] = useState(0);
  const [servicios, setServicios] = useState(0);
  const [facturasImporte, setFacturasImporte] = useState(0);
  const [cotizacionesCount, setCotizacionesCount] = useState(0);
  const [cotizacionesImporte, setCotizacionesImporte] = useState(0);
  const [pagosImporte, setPagosImporte] = useState(0);
  const [serviciosImporte, setServiciosImporte] = useState(0);
  const [loadingCotizaciones, setLoadingCotizaciones] = useState(true);
  const [loadingOrdenesServicio, setLoadingOrdenesServicio] = useState(true);
  const [periodo, setPeriodo] = useState({ nombre: 'Ultimo semana', value: 'SEMANAL' });
  const [periodos] = useState([
    { nombre: 'Ultimo día', value: 'DIARIO' },
    { nombre: 'Ultima semana', value: 'SEMANAL' },
    { nombre: 'Ultimo mes', value: 'MENSUAL' },
    { nombre: 'Ultimo trimestre', value: 'TRIMESTRAL' },
    { nombre: 'Ultimo año', value: 'ANUAL' },
    { nombre: 'Mes anterior', value: 'MES_ANTERIOR' },
  ]);
  const [periodoLevantamiento, setPeriodoLevantamiento] = useState({ nombre: 'Ultimo semana', value: 'SEMANAL' });
  const [periodoOrdenes, setPeriodoOrdenes] = useState({ nombre: 'Ultimo semana', value: 'SEMANAL' });
  const [listCotizaciones] = useState([...ESTATUS_COTIZACIONES]);
  const [cotizaciones, setCotizaciones] = useState([]);
  const [productosVendidos, setProductosVendidos] = useState([]);
  const [cotizacionesProximasVencer, setCotizacionesProximasVencer] = useState([]);
  const [seguimientosPorAtender, setSeguimientosPorAtender] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [agente, setAgente] = useState(authAgente);
  const [carruseles, setCarruseles] = useState([]);
  const [cotizacionesAgentes, setCotizacionesAgentes] = useState([]);
  const [actividadesAgentePorDia, setActividadesAgentePorDia] = useState([]);
  const [visitasPorAtender, setVisitasPorAtender] = useState([]);
  const [ordenesServicios, setOrdenesServicios] = useState([]);
  const [ordenesServiciosAgentes, setOrdenesServiciosAgentes] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchCatalogos = async () => {
      try {
        const { data } = await getRequest({ url: `agendaseguimientosdia`, signal: controller.signal });
        const { agentes, carruseles } = await getRequest({ url: `dashboard-catalogos`, signal: controller.signal });
        setAgenda(data);
        setAgentes([{ _id: -1, nombre: 'Todos los agentes' }, ...agentes]);
        setCarruseles(carruseles);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCatalogos();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    loadDataLevantamiento(controller.signal);
    return () => {
      controller.abort();
    };
  }, [agente, periodoLevantamiento]);

  useEffect(() => {
    const controller = new AbortController();
    loadData(controller.signal);
    return () => {
      controller.abort();
    };
  }, [periodo]);

  useEffect(() => {
    const controller = new AbortController();
    loadDataOrdenes(controller.signal);
    return () => {
      controller.abort();
    };
  }, [periodoOrdenes]);

  const loadData = useCallback(
    async (signal) => {
      try {
        const {
          data: {
            cotizaciones,
            equipos_detalles,
            clientes,
            cotizaciones_importe,
            facturas,
            facturas_importe,
            pagos,
            pagos_importe,
            prospectos,
            servicios,
            servicios_importe,
          },
        } = await getRequest({ url: `dashboard`, signal, params: { periodo: periodo.value } });
        setClientes(clientes);
        setProspectos(prospectos);
        setServicios(servicios);
        setServiciosImporte(servicios_importe);
        setFacturas(facturas);
        setFacturasImporte(facturas_importe);
        setPagos(pagos);
        setPagosImporte(pagos_importe);
        setCotizacionesCount(cotizaciones);
        setCotizacionesImporte(cotizaciones_importe);
        equipos_detalles.sort((b, a) => (a.cantidad === b.cantidad ? 0 : a.cantidad < b.cantidad ? -1 : 1));
        setProductosVendidos([...equipos_detalles]);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [periodo]
  );

  const loadDataLevantamiento = useCallback(
    async (signal) => {
      if (!agente) return;
      try {
        setLoadingCotizaciones(true);
        const { data } = await getRequest({
          url: `dashboard-levantamiento`,
          signal,
          params: { periodo: periodoLevantamiento.value, agente: agente._id },
        });
        if (!data) return;
        const actividadesAgentePorDia = Object.values(
          data.actividades_seguimientos.reduce((a, f) => {
            const fecha = moment(f.created).format('YYYY-MM-DD');
            if (!a[fecha]) {
              a[fecha] = {
                fecha,
                actividades: [f],
              };
            } else {
              a[fecha].actividades.push(f);
            }
            return a;
          }, {})
        );

        const cotizaciones = [
          ...ESTATUS_COTIZACIONES.map((status) => {
            const estatus = data.cotizaciones.find((x) => x._id.estado === status.estatus);
            if (estatus) {
              return {
                ...status,
                value: estatus.total,
                cantidad: estatus.cantidad,
              };
            }
            return { ...status };
          }).filter((x) => x.value !== 0),
        ];
        const cotizacionesAgentes = data.cotizaciones_totales.reduce((agentes, agenteEstatus) => {
          const agente = agentes.find((x) => x.agenteId === agenteEstatus._id.agenteId);
          if (agente) {
            agente.estatus.push(agenteEstatus);
          } else {
            agentes.push({
              agenteId: agenteEstatus._id.agenteId,
              agenteNombre: agenteEstatus._id.agenteNombre,
              estatus: [agenteEstatus],
            });
          }
          return agentes;
        }, []);
        setLoadingCotizaciones(false);
        setCotizaciones(cotizaciones);
        setCotizacionesAgentes(cotizacionesAgentes);
        setActividadesAgentePorDia(actividadesAgentePorDia);
        setCotizacionesProximasVencer(data.cotizaciones_por_vencer);
        setSeguimientosPorAtender(data.seguimientos_por_atender);
        setVisitasPorAtender(data.visitas_por_atender);
      } catch (error) {
        console.error(error);
      }
    },
    [agente, periodoLevantamiento]
  );

  const loadDataOrdenes = useCallback(
    async (signal) => {
      setLoadingOrdenesServicio(true);
      try {
        const { data } = await getRequest({ url: `dashboard-ordenes-servicios`, signal, params: { periodo: periodoOrdenes.value } });
        const ordenesServicios = [
          ...ESTATUS_ORDENES.map((status) => {
            const estatus = data.ordenes_servicios.find((x) => x._id.estatus_servicios === status.estatus);
            if (estatus) {
              return {
                ...status,
                cantidad: estatus.cantidad,
              };
            }
            return { ...status };
          }).filter((x) => x.value !== 0),
        ];
        const ordenesServiciosAgentes = data.ordenes_servicios_totales.reduce((agentes, agenteEstatus) => {
          const agente = agentes.find((x) => x.agenteId === agenteEstatus._id.agenteId);
          if (agente) {
            agente.estatus.push(agenteEstatus);
          } else {
            agentes.push({
              agenteId: agenteEstatus._id.agenteId,
              agenteNombre: agenteEstatus._id.agenteNombre,
              estatus: [agenteEstatus],
            });
          }
          return agentes;
        }, []);
        setLoadingOrdenesServicio(false);
        setOrdenesServicios(ordenesServicios);
        setOrdenesServiciosAgentes(ordenesServiciosAgentes);
      } catch (error) {
        console.error(error);
      }
    },
    [periodoOrdenes]
  );

  const onClickPeriodo = (periodo) => {
    setPeriodo(periodo);
  };

  return (
    <Module title='Inicio'>
      <Container>
        {(hasPermission('clientes') || hasPermission('ventas') || hasPermission('servicios') || hasPermission('pagos')) && (
          <Row>
            <Col>
              <div className='pull-right'>
                <DropdownButton title={periodo.nombre} variant='secondary'>
                  {periodos.map((periodo, i) => (
                    <DropdownItem key={i} onClick={() => onClickPeriodo(periodo)}>
                      {periodo.nombre}
                    </DropdownItem>
                  ))}
                </DropdownButton>
                <br />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <IndicadoresVentas
                      loading={loading}
                      clientes={clientes}
                      prospectos={prospectos}
                      facturas={facturas}
                      pagos={pagos}
                      servicios={servicios}
                      facturasImporte={facturasImporte}
                      cotizaciones={cotizacionesCount}
                      cotizacionesImporte={cotizacionesImporte}
                      pagosImporte={pagosImporte}
                      serviciosImporte={serviciosImporte}
                    />
                  </Col>
                  <Col md={4}>
                    <PrincipalCarousel carruseles={carruseles} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <br />
          </Col>
        </Row>
        <AccesosDirectos />
        <Row>
          <Col md={7}>
            <TableVisitasPorAtender visitasPorAtender={visitasPorAtender} />
            <TableCotizacionesProximasAVencer cotizacionesProximasVencer={cotizacionesProximasVencer} />
            <TableSeguimientosPorAtender seguimientosPorAtender={seguimientosPorAtender} />
            <IndicadoresCotizaciones
              cotizacionesAgentes={cotizacionesAgentes}
              agentes={agentes}
              listCotizaciones={listCotizaciones}
              periodoLevantamiento={periodoLevantamiento}
              setPeriodoLevantamiento={setPeriodoLevantamiento}
              setAgente={setAgente}
              cotizaciones={cotizaciones}
              loadingCotizaciones={loadingCotizaciones}
              periodos={periodos}
              agente={agente}
            />
            <IndicadoresOrdenesServicio
              periodos={periodos}
              ordenesServicios={ordenesServicios}
              periodo={periodoOrdenes}
              setPeriodo={setPeriodoOrdenes}
              loadingOrdenesServicio={loadingOrdenesServicio}
              ordenesServiciosAgentes={ordenesServiciosAgentes}
              listOrdenesServicios={ESTATUS_ORDENES}
            />
            <TableAgenda agenda={agenda} />
          </Col>
          <Col md={5}>
            <TableProductosVendidos productosVendidos={productosVendidos} />
            <CardActividadesAgente actividadesAgentePorDia={actividadesAgentePorDia} />
          </Col>
        </Row>
      </Container>
    </Module>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
