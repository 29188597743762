import { Confirmation, RedirectTooltip } from '@controls';
import If from '@controls/If';
import IfPermission from '@controls/IfPermission';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, ButtonGroup, Card, DropdownButton, DropdownItem, Modal } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, URL_FILES } from '@utils/api';
import CbBadge from '@cbcomponents/CbBadge';
import DetailCustomer from '@components/customers/DetailCustomer';
import EnviarDocumentos from './EnviarDocumentos';
import ListadoVisitas from './visitas/ListadoVisitas';
import IconDropdown from '@controls/IconDropdown';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbDomicilio from '@cbcomponents/CbDomicilio';
import { hasPermission } from '@utils/permissions';
import { useNavigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import New from './visitas/New';
import StatusServicio from '@components/servicios/StatusServicio';

const Table = ({ list, loadData, onChangeList, orden, tipo }) => {
  const { geolocation } = useAuth();
  const navigate = useNavigate();
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [idOrdenServicio, setIdOrdenServicio] = useState(null);

  const onClickDownloadPdf = (id) => {
    setShowModalDownload(true);
    setIdOrdenServicio(id);
  };

  const close = () => {
    setShowModalDownload(false);
  };

  const onAceptar = async () => {
    const { data } = await getRequest({ url: `ordenes-servicio/pdf/${idOrdenServicio}` });
    if (data) {
      setShowModalDownload(false);
      window.open(`${URL_FILES}/${data.url}`, '_blank');
    }
  };

  const obtenerClase = (orden, tipo, campo) => {
    if (orden !== campo) return 'sorting';
    if (tipo === -1) return 'sorting_desc';
    return 'sorting_asc';
  };

  const establecerOrden = (orden, tipo, campo) => {
    loadData({ page: 0, orden: campo, tipo: orden === campo ? tipo * -1 : -1 });
  };

  const onChangeCheckbox = (i, e) => {
    onChangeList([...list.slice(0, i), { ...list[i], seleccionada: e.target.checked }, ...list.slice(i + 1)]);
  };

  const renderRows = () => {
    return list.map((r, i) => {
      const {
        _id,
        fecha_solicitud,
        fecha_entrega,
        fecha_finalizacion,
        numero_orden_servicio,
        domicilio_entrega,
        cliente,
        estatus_servicios,
        tipoOrdenServicioId,
        cotizacionAdicionalId,
        cotizacionId,
        referencia,
        motivo_pausado,
        seleccionada,
      } = r;
      return (
        <tr key={i}>
          <td className='text-center'>
            <input type='checkbox' name='seleccionada' onChange={(e) => onChangeCheckbox(i, e)} checked={seleccionada} />
          </td>
          <td>{tipoOrdenServicioId ? tipoOrdenServicioId.nombre : null}</td>
          <td>{numero_orden_servicio}</td>
          <td>
            <span>
              <b>
                Solicitud: <br />
              </b>
              {moment(fecha_solicitud).isValid() ? moment(fecha_solicitud).local().format('DD/MM/YYYY') : null}
              <br />
              <b>
                P. Instalación: <br />
              </b>
              {moment(fecha_entrega).isValid() ? moment(fecha_entrega).local().format('DD/MM/YYYY') : null}
              {fecha_finalizacion && (
                <>
                  <br />
                  <b>
                    Finalización: <br />
                  </b>
                  {moment(fecha_finalizacion).local().format('DD/MM/YYYY')}
                </>
              )}
            </span>
          </td>
          <td>
            <DetailCustomer customer={cliente} />
            <CbDomicilio address={domicilio_entrega} />
          </td>
          <td>
            <span>
              <b>
                Solicitante: <br /> {r.agente ? <CbBadge type='primary'>{r.agente.nombre}</CbBadge> : null}
              </b>{' '}
              <br />
              <b>
                Asignado(s): <br />{' '}
                {r.agentes_asignados
                  ? r.agentes_asignados
                      .map((x) => x.nombre)
                      .map((x) => (
                        <CbBadge key={x} type='primary'>
                          {x}
                        </CbBadge>
                      ))
                  : 'Sin agente asignado'}
              </b>
            </span>
          </td>
          <td>{referencia}</td>
          <td><StatusServicio status={estatus_servicios} motivo_pausado={motivo_pausado} /></td>
          <td>
            <ButtonGroup className='pull-right'>
              <If condition={estatus_servicios == 'INICIADA'}>
                <If.Then>
                  <New
                    position={geolocation}
                    agentes={r.agentes}
                    agentes_asignados={r.agentes_asignados}
                    ordenServicioId={_id}
                    horas={r.horas}
                    loadData={loadData}
                  />
                </If.Then>
                <If.Else>
                  <ListadoVisitas
                    id={i}
                    ordenServicioId={_id}
                    numero_orden_servicio={numero_orden_servicio}
                    agentes_asignados={r.agentes_asignados}
                    horas={r.horas}
                    loadData={loadData}
                    visitas={r.visitas}
                    estatus_servicios={estatus_servicios}
                  />
                </If.Else>
              </If>
              <IfPermission action='pdf'>
                <CbButtonOver onClick={() => onClickDownloadPdf(_id)} icon='download-alt' title='Descargar PDF' type='warning' />
              </IfPermission>
              <If condition={estatus_servicios !== 'FINALIZADA' || hasPermission('editar-finalizada')}>
                <If.Then>
                  <IfPermission action='editar'>
                    <RedirectTooltip
                      id={2}
                      icon='pencil'
                      url={`/ordenes-servicio/editar/${_id}`}
                      labelTooltip='Editar'
                      className='btn btn-outline-success'
                    />
                  </IfPermission>
                </If.Then>
              </If>
              <DropdownButton title={<IconDropdown />} as={ButtonGroup} variant='outline-primary'>
                <EnviarDocumentos ordenServicioId={_id} />
                <If condition={cotizacionId}>
                  <If.Then>
                    <DropdownItem href={`/cotizaciones/edicion/${cotizacionId}`}>
                      <Icon icon='file' /> Cotización principal
                    </DropdownItem>
                  </If.Then>
                </If>
                <IfPermission action='adicionales'>
                  <If condition={!cotizacionAdicionalId}>
                    <If.Then>
                      <Confirmation
                        id={_id}
                        btnIcon='edit'
                        typeConfirm='primary'
                        isMenu
                        btnType='primary'
                        action='Cotizar adicionales'
                        url={`cotizaciones/ordenes-servicios/${_id}?fecha=${moment().local().format('YYYY-MM-DD')}`}
                        description={`¿Confirma que desea generar cotizaciones de adicionales para la <strong>orden de servicio: <u>#${numero_orden_servicio}</u></strong> ?`}
                        loadData={(data) => {
                          setTimeout(() => {
                            navigate(`/cotizaciones/edicion/${data._id}`);
                          }, 1000);
                        }}
                      />
                    </If.Then>
                    <If.Else>
                      <DropdownItem href={`/cotizaciones/edicion/${cotizacionAdicionalId}`}>
                        <Icon icon='file' /> Cotizacion adicional
                      </DropdownItem>
                    </If.Else>
                  </If>
                </IfPermission>
                <If condition={estatus_servicios !== 'CANCELADA' && estatus_servicios !== 'FINALIZADA'}>
                  <If.Then>
                    <IfPermission action='eliminar'>
                      <Confirmation
                        id={_id}
                        btnIcon='remove'
                        typeConfirm='danger'
                        isMenu
                        btnType='danger'
                        action='Cancelar'
                        url={`ordenes-servicio/cambiar-estado/${_id}/CANCELADA`}
                        description={`¿Confirma que desea cancelar la <strong>orden de servicio: <u>#${numero_orden_servicio}</u></strong> ? Escriba el motivo.`}
                        loadData={loadData}
                        input='text'
                        inputAttributes={{ autocapitalize: 'off' }}
                      />
                    </IfPermission>
                    <IfPermission action='finalizar'>
                      <Confirmation
                        id={_id}
                        btnIcon='check'
                        typeConfirm='success'
                        btnType='success'
                        isMenu
                        action='Finalizar'
                        url={`ordenes-servicio/cambiar-estado/${_id}/FINALIZADA`}
                        description={`¿Confirma que desea finalizar la <strong>orden de servicio: <u>#${numero_orden_servicio}</u></strong> ?`}
                        loadData={loadData}
                      />
                    </IfPermission>
                    <If condition={estatus_servicios !== 'PAUSADA'}>
                      <If.Then>
                        <IfPermission action='pausar'>
                          <Confirmation
                            id={_id}
                            btnIcon='pause'
                            isMenu
                            typeConfirm='secondary'
                            btnType='secondary'
                            action='Pausar'
                            url={`ordenes-servicio/cambiar-estado/${_id}/PAUSADA`}
                            description={`¿Confirma que desea pausar la <strong>orden de servicio: <u>#${numero_orden_servicio}</u></strong> ? Escriba el motivo de la pausa.`}
                            loadData={loadData}
                            input='text'
                            inputAttributes={{ autocapitalize: 'off' }}
                          />
                        </IfPermission>
                      </If.Then>
                    </If>
                    <If condition={estatus_servicios === 'PAUSADA'}>
                      <If.Then>
                        <IfPermission action='reanudar'>
                          <Confirmation
                            id={_id}
                            btnIcon='play'
                            isMenu
                            typeConfirm='success'
                            btnType='success'
                            action='Reanudar'
                            url={`ordenes-servicio/cambiar-estado/${_id}/REANUDADA`}
                            description={`¿Confirma que desea reanudar la <strong>orden de servicio: <u>#${numero_orden_servicio}</u></strong> ?`}
                            loadData={loadData}
                          />
                        </IfPermission>
                      </If.Then>
                    </If>
                  </If.Then>
                </If>
              </DropdownButton>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };
  return (
    <>
      <Card>
        <div className='table-responsive'>
          <table className='table table-condensed table-hover dataTable table-with-row-buttons'>
            <thead>
              <tr>
                <th width='1%'>&nbsp;</th>
                <th
                  className={obtenerClase(orden, tipo, 'tipoOrdenServicioId')}
                  width='5%'
                  onClick={() => {
                    establecerOrden(orden, tipo, 'tipoOrdenServicioId');
                  }}
                >
                  Tipo
                </th>
                <th
                  className={obtenerClase(orden, tipo, 'numero_orden_servicio')}
                  width='5%'
                  onClick={() => {
                    establecerOrden(orden, tipo, 'numero_orden_servicio');
                  }}
                >
                  Numero
                </th>
                <th width='8%'>Fechas</th>
                <th width='30%'>Cliente / Domicilio</th>
                <th width='8%'>Agentes</th>
                <th
                  className={obtenerClase(orden, tipo, 'referencia')}
                  width='5%'
                  onClick={() => {
                    establecerOrden(orden, tipo, 'referencia');
                  }}
                >
                  Referencia
                </th>
                <th
                  className={obtenerClase(orden, tipo, 'estatus')}
                  width='5%'
                  onClick={() => {
                    establecerOrden(orden, tipo, 'estatus');
                  }}
                >
                  Estatus
                </th>
                <th width='10%'>&nbsp;</th>
              </tr>
            </thead>
            <CbTableBody colSpan={10}>{renderRows()}</CbTableBody>
          </table>
        </div>
      </Card>
      <Modal show={showModalDownload} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Descargar orden de servicio</Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ textAlign: 'center' }}>
          <Button onClick={close} className='btn btn-lg btn-default mr-5' type='button'>
            &nbsp;Cerrar&nbsp;
          </Button>
          <Button onClick={onAceptar} className='btn btn-lg btn-primary mr-5' type='button'>
            Imprimir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  onChangeList: PropTypes.func.isRequired,
  orden: PropTypes.string.isRequired,
  tipo: PropTypes.number.isRequired,
};

export default Table;
