import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getRequest, postRequest } from '@utils/api';
import FormOrdenServicio from './FormOrdenServicio';
import { getReferencia, validationsOrdenRapida } from '@utils/cotizaciones';
import CbButtonOver from '@components/application/controls/CbButtonOver';
import CbModal from '@components/application/controls/CbModal';
import useAuth from '@hooks/useAuth';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import Swal from 'sweetalert2';

const NewRapida = ({ onReload }) => {
  const { addNotification: notification } = useStoreNotification();
  const { agente: agenteAuth } = useAuth();
  const [cotizacion, setCotizacion] = useState({});

  const [state, setState] = useState({
    fecha: moment().format('YYYY-MM-DD'),
    fecha_solicitud: moment().format('YYYY-MM-DD'),
    fecha_entrega: moment().format('YYYY-MM-DD'),
    observacion: '',
    descripcion: '',
    sucursal: agenteAuth?.sucursal,
    almacen: null,
    cliente: null,
    agente: agenteAuth,
    clienteRegistro: null,
    genera_orden_servicio: true,
    horas: 0,
    detalles_equipos: [],
    detalles_servicios: [],
    soluciones_solicitadas_id: [],
    tipos_servicios_solicitados_id: [],
    tipos_mano_obra_id: null,
    tipoPedidoId: null,
    tipoOrdenServicioId: null,
    showModalDownload: false,
    numero_orden_servicio: '',
    detalles: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    ObtenerDatos();
  }, []);

  useEffect(() => {
    if (state.tipoOrdenServicioId) {
      ObtenerNumeroOrdenServicio();
    }
  }, [state.tipoOrdenServicioId?._id]);

  const onChange = (event) => {
    const { name, value, type, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSelectTipoOrdenServicio = (value) => {
    setState((prevState) => ({
      ...prevState,
      tipoOrdenServicioId: value,
    }));
  };

  const onChangeCliente = (value) => {
    setState((prevState) => ({
      ...prevState,
      clienteRegistro: value,
    }));
  };

  const ObtenerDatos = async () => {
    setIsLoading(true);
    setCotizacion(cotizacion);
    setIsLoading(false);
  };

  const ObtenerNumeroOrdenServicio = async () => {
    const { tipoOrdenServicioId } = state;
    const {
      data: { numero_orden_servicio },
    } = await getRequest({ url: `ordenes-servicio/siguienteNumero`, params: { tipoOrdenServicioId: tipoOrdenServicioId._id } });
    setState((prevState) => ({
      ...prevState,
      numero_orden_servicio,
    }));
  };

  const handleCompletarPedidoServicio = async (event) => {
    event.preventDefault();
    const {
      sucursal,
      agente,
      soluciones_solicitadas_id,
      tipos_servicios_solicitados_id,
      tipos_mano_obra_id,
      numero_orden_servicio,
      tipoOrdenServicioId,
      tipos_cobro_equipo_id,
      clienteRegistro,
      descripcion,
      observacion,
      horas,
      fecha_solicitud,
      fecha_entrega,
    } = state;

    const data = {
      fecha_solicitud: moment(fecha_solicitud, 'YYYY-MM-DD').utc().format(),
      fecha_entrega: moment(fecha_entrega, 'YYYY-MM-DD').utc().format(),
      tipoOrdenServicioId: tipoOrdenServicioId?._id,
      numero_orden_servicio,
      clienteRegistroId: clienteRegistro?._id,
      agenteId: agente?._id,
      sucursalId: sucursal?._id,
      soluciones_solicitadas_id: soluciones_solicitadas_id.map((x) => x._id),
      tipos_servicios_solicitados_id: tipos_servicios_solicitados_id.map((x) => x._id),
      tipos_mano_obras_id: tipos_mano_obra_id?._id,
      tipos_cobro_equipo_id: tipos_cobro_equipo_id?._id,
      descripcion,
      observacion,
      horas,
      referencia,
    };
    const { success, message } = validationsOrdenRapida(data);
    if (!success) {
      return notification({
        title: 'Validaciones',
        message,
        type: 'error',
      });
    }
    setIsLoading(true);
    const { data: dataValidacion } = await postRequest({ url: `cotizaciones/validarordenreciente/${clienteRegistro?._id}` });
    if (dataValidacion) {
      let confirmar = true;
      if (dataValidacion?.accion == 'CONFIRMAR') {
        confirmar = await verificarGenerarOrdenServicio();
      }
      if (confirmar) {
        const { data: dataR } = await postRequest({ url: 'cotizaciones/ordenes-servicio/rapida', body: data });
        if (dataR?.orden_servicio) {
          setState((prevState) => ({
            ...prevState,
            showModal: false,
            orden_servicio: dataR.orden_servicio,
          }));
          onReload();
        }
      }
    }
    setIsLoading(false);
  };

  const verificarGenerarOrdenServicio = async () => {
    const res = await Swal.fire({
      html: `<div style="font-size: 1.5em!important;">Confirmación</div><span>¡El CRM tiene una visita generada recientemente! ¿Esta de acuerdo?</span>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, confirmo',
      cancelButtonText: 'No',
    });
    return res.isConfirmed;
  };

  const {
    soluciones_solicitadas_id,
    tipos_servicios_solicitados_id,
    tipos_mano_obra_id,
    horas,
    tipoOrdenServicioId,
    tipos_cobro_equipo_id,
    descripcion,
    clienteRegistro,
    numero_orden_servicio,
    agente,
    sucursal,
    fecha_solicitud,
    fecha_entrega,
    numero_pedido,
    showModal,
  } = state;

  const referencia = getReferencia({
    tipos_mano_obra: tipos_mano_obra_id?.clave,
    tipo_servicio_solicitado: tipos_servicios_solicitados_id.length > 0 ? tipos_servicios_solicitados_id[0]?.clave : '',
    tipos_cobro_equipo: tipos_cobro_equipo_id?.clave,
    monitoreo: clienteRegistro?.monitoreo,
    folio: clienteRegistro?.folio,
    descripcion,
    horas,
    numero_levantamiento: cotizacion?.numero_levantamiento,
    numero_pedido: numero_pedido,
    agente: agente?.codigo,
  });

  const onOpen = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: true,
      fecha: moment().format('YYYY-MM-DD'),
      fecha_solicitud: moment().format('YYYY-MM-DD'),
      fecha_entrega: moment().format('YYYY-MM-DD'),
      observacion: '',
      descripcion: '',
      sucursal: null,
      almacen: null,
      cliente: null,
      clienteRegistro: null,
      genera_orden_servicio: true,
      horas: 0,
      detalles_equipos: [],
      detalles_servicios: [],
      soluciones_solicitadas_id: [],
      tipos_servicios_solicitados_id: [],
      tipos_mano_obra_id: null,
      tipoPedidoId: null,
      tipoOrdenServicioId: null,
      showModalDownload: false,
      numero_orden_servicio: '',
      detalles: [],
      tipos_cobro_equipo_id: [],
    }));
  };

  const onClose = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  return (
    <>
      <CbButtonOver onClick={onOpen} icon='plus' title='Servicio rapido' hiddenXs type='warning' />
      <CbModal show={showModal} onClose={onClose} onSave={handleCompletarPedidoServicio} title='Orden de servicio rapida' isLoading={isLoading}>
        <Row>
          <Col sm={3}>
            <DivFormGroup name='sucursal' required title='Sucursal'>
              <SelectSucursal value={sucursal} name='sucursal' onChange={(value) => onSelect('sucursal', value)} />
            </DivFormGroup>
          </Col>
          <Col sm={3}>
            <DivFormGroup name='agente' required title='Agente solicitante'>
              <SelectAgente value={agente} name='agente' onChange={(value) => onSelect('agente', value)} />
            </DivFormGroup>
          </Col>
          <Col sm={3}>
            <FormInput title='Fecha solicitud' type='date' required name='fecha_solicitud' onChange={onChange} value={fecha_solicitud} />
          </Col>
          <Col sm={3}>
            <FormInput title='Fecha entrega' type='date' required name='fecha_entrega' onChange={onChange} value={fecha_entrega} />
          </Col>
        </Row>
        <FormOrdenServicio
          soluciones_solicitadas_id={soluciones_solicitadas_id}
          tipos_servicios_solicitados_id={tipos_servicios_solicitados_id}
          tipos_mano_obra_id={tipos_mano_obra_id}
          horas={horas}
          tipoOrdenServicioId={tipoOrdenServicioId}
          tipos_cobro_equipo_id={tipos_cobro_equipo_id}
          descripcion={descripcion}
          clienteRegistro={clienteRegistro}
          cotizacion={cotizacion}
          numero_orden_servicio={numero_orden_servicio}
          onChange={onChange}
          onSelect={onSelect}
          onChangeCliente={onChangeCliente}
          onSelectTipoOrdenServicio={onSelectTipoOrdenServicio}
          referencia={referencia}
        />
      </CbModal>
    </>
  );
};

NewRapida.propTypes = {
  onReload: PropTypes.func.isRequired,
};

export default NewRapida;
