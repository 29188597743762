import { ABONADA, CANCELADA, PAGADA, PAGADA_MANUAL, PENDIENTE } from '@config/constants';
import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';
import CbBadge from '@cbcomponents/CbBadge';

const RowFactura = ({ factura }) => {
  const switchEstado = (estado) => {
    let text = '';
    let color = 'secondary';

    if (estado === PENDIENTE) {
      text = 'Pendiente de pago';
      color = 'secondary';
    } else if (estado === PAGADA) {
      text = 'Pagada';
      color = 'success';
    } else if (estado === PAGADA_MANUAL) {
      text = 'Pago manual';
      color = 'success';
    } else if (estado === ABONADA) {
      text = 'Abonada';
      color = 'warning';
    } else if (estado === CANCELADA) {
      text = 'Cancelada';
      color = 'danger';
    }
    return <CbBadge type={color}>{text}</CbBadge>;
  };

  const { _id, serie, numero, titulo, uuid, importe, saldo, estado, fecha_pago, fecha } = factura;

  return (
    <tr>
      <td>{serie}</td>
      <td>{numero}</td>
      <td>{moment(fecha).local().format('YYYY-MM-DD')}</td>
      <td>{titulo}</td>
      <td>{uuid}</td>
      <td>${format(importe, 2)}</td>
      <td>${format(saldo, 2)}</td>
      <td>
        {switchEstado(estado)}
        {estado === PAGADA || estado === PAGADA_MANUAL ? (
          <span>
            <br />
            <small>
              <em>{moment(fecha_pago).local().format('DD/MM/YYYY HH:mm')}</em>
            </small>
          </span>
        ) : null}
      </td>
      <td>
        <RedirectTooltip
          id={1}
          icon='search'
          url={`/facturas/detalle/${_id}`}
          labelTooltip='Completa'
          className='btn btn-sm btn-primary pull-right'
        />
      </td>
    </tr>
  );
};

RowFactura.propTypes = {
  factura: PropTypes.object.isRequired,
};

export default RowFactura;
