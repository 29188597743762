import { DivFormGroup } from '@controls';
import React, { useState, useRef } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { postUploadRequest } from '@utils/api';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';

const Import = () => {
  const [state, setState] = useState({
    regimen: 'C',
    isLoading: false,
    cargando: false,
    csv: null,
    valid: false,
    msg: '',
    progress: 0,
  });

  const frm = useRef(null);

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      csv: null,
      valid: false,
      msg: '',
      progress: 0,
    }));
  };

  const handleReadCSV = async (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      cargando: true,
      progress: 0,
      csv: null,
      isLoading: true,
    }));

    const form = new FormData(frm.current);
    let tipo = '';
    switch (state.regimen) {
      case 'F':
        tipo = 'fisicas';
        break;
      case 'M':
        tipo = 'morales';
        break;
      case 'C':
        tipo = 'clickbalance';
        break;
      case 'CD':
        tipo = 'clickbalance-domicilios';
        break;
      case 'R':
        tipo = 'referencias';
        break;
      case 'CL':
        tipo = 'clasificaciones';
        break;
    }
    const { csv, valid, msg } = await postUploadRequest({ url: `importar-clientes-${tipo}-csv`, form });
    setState((prevState) => ({
      ...prevState,
      csv: {
        detalle: csv.detalle,
        headers: csv.detalle.length > 0 ? Object.keys(csv.detalle[0]) : [],
      },
      valid,
      msg,
      progress: 0,
    }));
  };

  const renderDataCSV = () => {
    const {
      csv: { detalle, headers },
      valid,
      msg,
    } = state;
    return (
      <Row>
        {!valid && (
          <div className='alert alert-danger'>
            <p>{msg}</p>
          </div>
        )}
        <Col sm='12'>
          <CbPanelTableResponsive>
            <thead>
              <tr>
                {headers.map((e, i) => (
                  <th key={i}>
                    <strong>{e}</strong>
                  </th>
                ))}
              </tr>
            </thead>
            <CbTableBody colSpan={7}>
              {detalle.length > 0 ? (
                detalle.map((d, i) => (
                  <tr key={i}>
                    {headers.map((e, j) => (
                      <td key={j}>{d[e]}</td>
                    ))}
                  </tr>
                ))
              ) : (
                <RowsNotFound colSpan={7} />
              )}
            </CbTableBody>
          </CbPanelTableResponsive>
        </Col>
      </Row>
    );
  };

  const { cargando, isLoading, csv, progress, regimen } = state;

  return (
    <Module onClickBack={window.history.back} parent='Clientes' title='Importar clientes'>
      <form ref={frm} onSubmit={handleReadCSV} encType='multipart/form-data'>
        <div className='panel panel-default '>
          <div className='panel-body'>
            <Row>
              <div className='12'>
                <div className='form-group inline'>
                  <label>Tipo archivo</label>
                  <div>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} checked={regimen == 'C'} value={'C'} /> Click Balance
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} checked={regimen == 'R'} value={'R'} /> Referencias
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} checked={regimen == 'F'} value={'F'} /> Persona Física
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} checked={regimen == 'M'} value={'M'} /> Persona Moral
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} checked={regimen == 'CD'} value={'CD'} /> Domicilios
                    </label>
                    <label className='radio-inline'>
                      <input type='radio' name='regimen' onChange={onChange} checked={regimen == 'CL'} value={'CL'} /> Clasificaciones
                    </label>
                  </div>
                </div>
              </div>
              <Col sm='3'>
                <DivFormGroup>
                  <div className='btn btn-default btn-file'>
                    <Icon icon='paperclip' /> Archivo de clientes
                    <input type='file' name='csv' id='csv' onChange={handleReadCSV} />
                  </div>
                </DivFormGroup>
              </Col>
              <Col sm='9'>{isLoading ? <Saving action='Importantando' /> : ''}</Col>
              <Col sm='12'>{cargando ? <ProgressBar active now={progress} /> : ''}</Col>
            </Row>
          </div>
        </div>
      </form>
      <form>{csv !== null ? renderDataCSV() : null}</form>
    </Module>
  );
};

Import.propTypes = {};

export default Import;
