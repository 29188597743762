import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { cleanNumber } from '@utils/formatter';

const InputMask = ({
    className = 'form-control',
    type = 'text',
    disabled = false,
    autocomplete = 'off',
    prefix = '',
    suffix = '',
    allowDecimal = true,
    guide = false,
    decimalLimit = 2,
    name,
    ref,
    style,
    maxLength,
    placeholder,
    readOnly,
    defaultValue,
    onBlur,
    id,
    onFocus,
    value: propValue,
    onChange,
}) => {

  const handleChange = (e) => {
    onChange({ target: { value: cleanNumber(e.target.value, suffix), id, name, type } });
  };

  const nameClass = className.match('form-control') ? className : `form-control ${className}`;
  const value = propValue || propValue === 0 ? propValue : '';

  return (
    <MaskedInput
      guide={guide}
      type={type}
      id={id}
      name={name}
      ref={ref}
      className={nameClass}
      style={style}
      maxLength={maxLength}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      readOnly={readOnly}
      autoComplete={autocomplete}
      onFocus={(event) => {
        event.target.select();
        onFocus && onFocus(event);
      }}
      mask={createNumberMask({ suffix, prefix, allowDecimal, decimalLimit })}
    />
  );
};

InputMask.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autocomplete: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  allowDecimal: PropTypes.bool,
  guide: PropTypes.bool,
  decimalLimit: PropTypes.number,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default InputMask;
