import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';

const Table = ({ list }) => {
  const renderRows = () => {
    return list.map((r, i) => {
      const {
        _id,
        cuentaId,
        cuenta,
        fecha,
        depositos_pendientes,
        totales: { total, aplicado, pendiente },
      } = r;
      return (
        <tr key={i}>
          <td>{cuentaId.bancoId.nombre}</td>
          <td>{cuenta}</td>
          <td>
            <small>
              <em>{moment(fecha).local().format('DD/MM/YYYY HH:mm')}</em>
            </small>
          </td>
          <td>
            <span className='text-success'>$ {format(aplicado, 2)}</span>
          </td>
          <td>
            <span className='text-warning'>$ {format(pendiente, 2)}</span>
          </td>
          <td>
            <span className='text-primary'>$ {format(total, 2)}</span>
          </td>
          <td>
            {depositos_pendientes > 0 ? (
              <RedirectTooltip
                id={1}
                url={`/conciliaciones/pendientes/${_id}`}
                labelTooltip='Pagos no aplicados'
                icon='warning-sign'
                className='btn btn-sm btn-warning pull-right'
              />
            ) : null}
            <RedirectTooltip
              id={1}
              url={`/conciliaciones/detalle/${_id}`}
              labelTooltip='Conciliaciones por Movimiento'
              icon='search'
              className='btn btn-sm btn-primary pull-right'
            />
            <RedirectTooltip
              id={1}
              url={`/conciliaciones/detallado-clientes/${_id}`}
              labelTooltip='Conciliaciones por Clientes'
              icon='user'
              className='btn btn-sm btn-default pull-right'
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Banco</th>
          <th width='20%'>Cuenta</th>
          <th width='20%'>Fecha</th>
          <th width='10%'>Aplicado</th>
          <th width='10%'>Por aplicar</th>
          <th width='10%'>Total</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={7}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
};

export default Table;
