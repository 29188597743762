import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Input, DivFormGroup } from '@controls';
import { Col, Row } from 'react-bootstrap';

const FormContacto = ({ name, tiposContactos, tipos, paterno, materno, email, sexo, phone, mobile, fecha_nacimiento, setState }) => {
  const handleSelect = (name, value) => {
    setState({ [name]: value });
  };

  const handleChange = (event) => {
    setState({ [event.target.name]: event.target.value });
  };

  return (
    <>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='tipos' title='Tipo Contacto' required>
            <Select
              value={tipos}
              isMulti
              name='tipos'
              placeholder='Selecciona opciones'
              options={tiposContactos}
              onChange={(value) => handleSelect('tipos', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='name' title='Nombre' required>
            <Input name='name' onChange={handleChange} placeholder='Nombre' value={name} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='paterno' title='Paterno'>
            <Input name='paterno' onChange={handleChange} placeholder='Paterno' value={paterno} />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='materno' title='Materno'>
            <Input name='materno' onChange={handleChange} placeholder='Materno' value={materno} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='phone' title='Teléfono' required>
            <Input name='phone' onChange={handleChange} placeholder='Teléfono' value={phone} />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='mobile' title='Celular'>
            <Input name='mobile' onChange={handleChange} placeholder='Celular' value={mobile} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='12'>
          <DivFormGroup name='email' title='Correo electrónico' required>
            <Input name='email' onChange={handleChange} placeholder='Correo electrónico' value={email} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='sexo' title='Sexo'>
            <Select
              value={sexo}
              name='sexo'
              placeholder='Selecciona opciones'
              options={[
                { value: 'M', label: 'Masculino' },
                { value: 'F', label: 'Femenino' },
              ]}
              onChange={(value) => handleSelect('sexo', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='fecha_nacimiento' title='Fecha nacimiento'>
            <Input type='date' name='fecha_nacimiento' onChange={handleChange} placeholder='Fecha nacimiento' value={fecha_nacimiento} />
          </DivFormGroup>
        </Col>
      </Row>
    </>
  );
};

FormContacto.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  tiposContactos: PropTypes.array.isRequired,
  tipos: PropTypes.array.isRequired,
  materno: PropTypes.string.isRequired,
  paterno: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  sexo: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  fecha_nacimiento: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
};

export default FormContacto;
