import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isDecimal, isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditServicios = ({ servicioId, loadServicios }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [precio, setPrecio] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
    setPrecio('');
  };

  const open = async () => {
    const {
      data: { nombre, precio },
    } = await getRequest({ url: `servicios/${servicioId}` });
    setShowModal(true);
    setNombre(nombre);
    setPrecio(precio.toString());
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = { nombre: nombre.trim(), precio: precio.trim() };

    if (validations(data)) {
      await putRequest({ url: `servicios/${servicioId}`, body: data });
      loadServicios();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.precio + '')) {
      validate.success = false;
      validate.message = 'Precio es requerido.';
    } else if (!isDecimal(params.precio + '')) {
      validate.success = false;
      validate.message = 'Precio debe contener un valor númerico.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' placeholder='Nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
            </DivFormGroup>
            <DivFormGroup name='precio' title='Precio' required>
              <Input name='precio' placeholder='Precio' onChange={(e) => setPrecio(e.target.value)} value={precio} maxLength={13} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditServicios.propTypes = {
  servicioId: PropTypes.string.isRequired,
  loadServicios: PropTypes.func.isRequired,
};

export default EditServicios;
