import { DivFormGroup, FormInput, Input } from '@controls';
// import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest } from '@utils/api';
// import { isMongoId } from 'validator';
import RowReferencia from '../components/RowReferencia';
import SelectTipoEstablecimiento from '@components/forms/SelectTipoEstablecimiento';
import SelectRubro from '@components/forms/SelectRubro';
import SelectClasificacionCliente from '@components/forms/SelectClasificacionCliente';
import RowCredito from '../components/RowCredito';
import { useStoreNotification } from '@stores/catalogs.store';
import { useNavigate, useParams } from 'react-router';
import { validateUpdate } from '@validations/clientes';
import { putRequest } from '@utils/api';

const EditCustomer = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [isSaving] = useState(false);
  const [regimen, setRegimen] = useState('F');
  const [rfc, setRfc] = useState('');
  const [razon_social, setRazonSocial] = useState('');
  const [nombre_comercial, setNombreComercial] = useState('');
  // const [curp, setCurp] = useState('');
  const [numero_cliente, setNumeroCliente] = useState('');
  // const [tituloClienteId, setTituloClienteId] = useState('');
  const [name, setName] = useState('');
  const [paterno, setPaterno] = useState('');
  const [materno, setMaterno] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  // const [nacionalidad, setNacionalidad] = useState('');
  // const [extranjero, setExtranjero] = useState('NO');
  // const [tiene_represente_legal, setTieneRepresenteLegal] = useState(true);
  // const [nombre_representante_legal, setNombreRepresentanteLegal] = useState('');
  // const [paterno_representante_legal, setPaternoRepresentanteLegal] = useState('');
  // const [materno_representante_legal, setMaternoRepresentanteLegal] = useState('');
  // const [estado_civil, setEstadoCivil] = useState('');
  // const [sexo, setSexo] = useState('M');
  // const [fecha_nacimiento, setFechaNacimiento] = useState('');
  const [tipoEstablecimiento, setTipoEstablecimiento] = useState(null);
  const [rubro, setRubro] = useState(null);
  const [listCreditos, setListCreditos] = useState([]);
  const [listReferencias, setListReferencias] = useState([]);
  const [clasification, setClasification] = useState(null);
  const [comentarios, setComentarios] = useState('');
  const loadData = useCallback(async () => {
    setLoading(true);
    const { data } = await getRequest({ url: `customer/${customerId}/detail` });
    // const tituloClienteId = isMongoId(data.tituloClienteId + '') ? data.tituloClienteId : '';
    setRazonSocial(data.razon_social);
    setRegimen(data.regimen || 'F');
    setRfc(data.rfc || '');
    setName(data.name || '');
    setPaterno(data.paterno || '');
    setMaterno(data.materno || '');
    setNumeroCliente(data.numero_cliente || '');
    setNombreComercial(data.nombre_comercial || '');
    setEmail(data.email || '');
    setPhone(data.phone || '');
    setMobile(data.mobile || '');
    setTipoEstablecimiento(data.tipoEstablecimientoId || '');
    setRubro(data.rubroId || '');
    setListCreditos(data.listCreditos || []);
    setListReferencias(data.listReferencias || []);
    setClasification(data.clasification || null);
    setLoading(false);
    setListCreditos(data.listCreditos || []);
    setListReferencias(data.listReferencias || []);
    setComentarios(data.comentarios || '');    
  }, [customerId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setRazonSocial(`${name || ''} ${paterno || ''} ${materno || ''}`.replace(/\s+/g, ' ').trim());
  }, [name, paterno, materno]);

  useEffect(() => {
    if (regimen === 'I') {
      setRfc('XAXX010101000');
    } else if (regimen === 'E') {
      setRfc('XEXX010101000');
    }
  }, [regimen]);

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      numero_cliente,
      regimen,
      rfc: rfc.toString().trim().toUpperCase(),
      // curp: curp.toString().trim().toUpperCase(),
      // tituloClienteId,
      name: name.toString().trim().toUpperCase(),
      paterno: paterno.toString().trim().toUpperCase(),
      materno: materno.toString().trim().toUpperCase(),
      email,
      phone,
      mobile,
      comentarios,
      // nacionalidad: extranjero === 'NO' ? 'MEXICANA' : nacionalidad,
      // extranjero,
      razon_social: razon_social.toString().trim().toUpperCase(),
      nombre_comercial: nombre_comercial.toString().trim().toUpperCase(),
      // representante_legal: {
      //   tituloRepresentanteId,
      //   nombre: nombre_representante_legal.toString().trim().toUpperCase(),
      //   paterno: paterno_representante_legal.toString().trim().toUpperCase(),
      //   materno: materno_representante_legal.toString().trim().toUpperCase(),
      // },
      // estado_civil,
      // sexo,
      // fecha_nacimiento,
      tipoEstablecimientoId: tipoEstablecimiento?._id,
      rubroId: rubro?._id,
      listCreditos: listCreditos.map((x) => ({ limite_credito: x.limite_credito, plazo: x.plazo })),
      listReferencias: listReferencias.map((x) => ({ bancoId: x.bancoId?._id, referencia: x.referencia })),
      // tiene_represente_legal,
      clasification: clasification ? clasification._id : null,
    };
    if (validations(data)) {
      await putRequest({ url: `customer/${customerId}`, body: data });
      navigate('/clientes');
    }
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return !error;
  };

  const onChangeCredito = (idx, credito) => {
    const newCreditos = [...listCreditos];
    newCreditos[idx] = credito;
    setListCreditos(newCreditos);
  };

  const removeItemCredito = (idx) => {
    const newCreditos = listCreditos.filter((_, i) => i !== idx);
    setListCreditos(newCreditos);
  };

  const addItemCredito = () => {
    setListCreditos([...listCreditos, { limite_credito: 0, plazo: 0 }]);
  };

  const addItemReferencia = () => {
    setListReferencias([...listReferencias, { bancoId: null, referencia: '' }]);
  };

  const removeItemReferencia = (idx) => {
    const newReferencias = listReferencias.filter((_, i) => i !== idx);
    setListReferencias(newReferencias);
  };

  const onChangeReferencia = (idx, referencia) => {
    const newReferencias = [...listReferencias];
    newReferencias[idx] = referencia;
    setListReferencias(newReferencias);
  };

  return (
    <Module onClickBack={window.history.back} parent='Clientes' title='Modificar cliente'>
      <Loading loading={loading}>
        <form>
          <div className='panel panel-default'>
            <div className='panel-body'>
              <Row>
                <Col sm='5'>
                  <div className='form-group inline'>
                    <label>Régimen</label>
                    <div>
                      <label className='radio-inline'>
                        <input type='radio' name='regimen' onChange={(e) => setRegimen(e.target.value)} value={'F'} checked={regimen === 'F'} />{' '}
                        Física
                      </label>
                      <label className='radio-inline'>
                        <input type='radio' name='regimen' onChange={(e) => setRegimen(e.target.value)} value={'M'} checked={regimen === 'M'} /> Moral
                      </label>
                      <label className='radio-inline'>
                        <input type='radio' name='regimen' onChange={(e) => setRegimen(e.target.value)} value={'I'} checked={regimen === 'I'} />{' '}
                        Informal
                      </label>
                      <label className='radio-inline'>
                        <input type='radio' name='regimen' onChange={(e) => setRegimen(e.target.value)} value={'E'} checked={regimen === 'E'} />{' '}
                        Extranjera
                      </label>
                    </div>
                  </div>
                </Col>
                <Col sm='7'>
                  <Row>
                    <Col sm='6'>
                      <DivFormGroup name='rfc' title='RFC'>
                        <Input name='rfc' onChange={(e) => setRfc(e.target.value)} value={rfc} maxLength={13} />
                      </DivFormGroup>
                    </Col>
                    <Col sm='6'>
                      <DivFormGroup name='numero_cliente' title='Número de cliente'>
                        <Input name='numero_cliente' onChange={(e) => setNumeroCliente(e.target.value)} value={numero_cliente} maxLength={13} />
                      </DivFormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div className='panel panel-default'>
            <div className='panel-body'>
              <Row>
                <Col>
                  <FormInput
                    name='razon_social'
                    title='Razon social'
                    onChange={(e) => setRazonSocial(e.target.value)}
                    value={razon_social}
                    required
                    disabled={regimen == 'F'}
                  />
                </Col>
              </Row>
              {regimen == 'F' && (
                <>
                  <Row>
                    <Col sm='4'>
                      <FormInput name='name' title='Nombre(s)' onChange={(e) => setName(e.target.value)} value={name} required />
                    </Col>
                    <Col sm='4'>
                      <FormInput name='paterno' title='Apellido Paterno' onChange={(e) => setPaterno(e.target.value)} value={paterno} required />
                    </Col>
                    <Col sm='4'>
                      <FormInput name='materno' title='Apellido Materno' onChange={(e) => setMaterno(e.target.value)} value={materno} />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col sm='6'>
                  <FormInput
                    name='nombre_comercial'
                    title='Nombre Comercial'
                    onChange={(e) => setNombreComercial(e.target.value)}
                    value={nombre_comercial}
                  />
                </Col>
                <Col sm='3'>
                  <DivFormGroup name='tipoEstablecimiento' title='Tipo de Establecimiento'>
                    <SelectTipoEstablecimiento
                      name='tipoEstablecimiento'
                      value={tipoEstablecimiento}
                      onChange={(value) => setTipoEstablecimiento(value)}
                    />
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <DivFormGroup name='rubro' title='Rubros'>
                    <SelectRubro
                      name='rubro'
                      value={rubro}
                      onChange={(value) => setRubro(value)}
                      disabled={!tipoEstablecimiento}
                      filter={(v) => v.tipoEstablecimientoId == tipoEstablecimiento?._id}
                    />
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='4'>
                  <FormInput type='email' name='email' title='Correo electrónico' onChange={(e) => setEmail(e.target.value)} value={email} />
                </Col>
                <Col sm='4'>
                  <FormInput name='phone' title='Teléfono' onChange={(e) => setPhone(e.target.value)} value={phone} maxLength={10} />
                </Col>
                <Col sm='4'>
                  <FormInput name='mobile' title='Celular' onChange={(e) => setMobile(e.target.value)} value={mobile} maxLength={10} />
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <DivFormGroup name='clasification' title='Clasificacion de cliente'>
                    <SelectClasificacionCliente name='clasification' value={clasification} onChange={(value) => setClasification(value)} />
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    type='textarea'
                    name='comentarios'
                    title='Comentarios'
                    onChange={(e) => setComentarios(e.target.value)}
                    value={comentarios}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div>
            <Row>
              <Col sm='6'>
                <div className='panel panel-default'>
                  <div className='panel-heading'>
                    <h2 className='panel-title'>
                      <Icon icon='usd' style={{ fontSize: '0.8em' }} /> Créditos
                    </h2>
                  </div>
                  <div className='panel-body'>
                    <div className='list-group'>
                      {listCreditos.map((credito, idx) => (
                        <RowCredito
                          key={idx}
                          credito={credito}
                          onChangeCredito={(value) => onChangeCredito(idx, value)}
                          onRemoveCredito={() => removeItemCredito(idx)}
                        />
                      ))}
                      <Button
                        onClick={addItemCredito}
                        style={{ textAlign: 'center', fontSize: '1.1em', fontWeight: '600' }}
                        type='button'
                        variant='outline-primary'
                      >
                        <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Agregar crédito
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm='6'>
                <div className='panel panel-default'>
                  <div className='panel-heading'>
                    <h2 className='panel-title'>
                      <Icon icon='tag' style={{ fontSize: '0.8em' }} /> Referencias Bancarias
                    </h2>
                  </div>
                  <div className='panel-body'>
                    <>
                      <div className='list-group'>
                        {listReferencias.map((x, idx) => (
                          <RowReferencia
                            key={idx}
                            referencia={x}
                            onChangeReferencia={(value) => onChangeReferencia(idx, value)}
                            onRemoveReferencia={() => removeItemReferencia(idx)}
                          />
                        ))}
                        <Button
                          onClick={addItemReferencia}
                          style={{ textAlign: 'center', fontSize: '1.1em', fontWeight: '600' }}
                          type='button'
                          variant='outline-primary'
                        >
                          <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Agregar referencia bancaria
                        </Button>
                      </div>
                    </>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <div className='panel panel-default'>
              <div className='panel-footer'>
                <Row>
                  <Col sm='12'>
                    <Saving saving={isSaving} />
                    <Button onClick={handleEdit} type='button' className='btn btn-success pull-right' disabled={isSaving}>
                      <Icon icon='floppy-disk' /> Guardar
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </form>
      </Loading>
    </Module>
  );
};

EditCustomer.propTypes = {};

export default EditCustomer;
