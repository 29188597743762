import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ clave, id, load }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`soluciones_solicitadas/${id}`}
      description={`¿Confirma que desea eliminar la solución solicitada <strong><u> ${clave}</u> </strong>?`}
      loadData={load}
    />
  );
};

Delete.propTypes = {
  clave: PropTypes.string.isRequired,
  definicion: PropTypes.string.isRequired,
  req_panel: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Delete;
