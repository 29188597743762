import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';
import { ButtonGroup } from 'react-bootstrap';

const List = ({ list, load }) => {
  const renderRows = () => {
    return list.map((row, item) => {
      const { _id, nombre } = row;
      return (
        <tr key={item}>
          <td>{nombre}</td>
          <td>
            <ButtonGroup>
              <IfPermission action='editar'>
                <Edit id={_id} nombre={nombre} load={load} />
              </IfPermission>
              <IfPermission action='eliminar'>
                <Delete id={_id} nombre={nombre} load={load} />
              </IfPermission>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='80%'>Nombre</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default List;
