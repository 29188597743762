import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import FormContacto from './FormContacto';
import { useStoreNotification } from '@stores/catalogs.store';

const EditContacto = (props) => {
  const { addNotification: notification } = useStoreNotification();

  const [state, setState] = useState({
    maximo_descuento: 0,
    showModal: false,
    isLoading: false,
    name: '',
    tipos: [],
    paterno: '',
    materno: '',
    phone: '',
    mobile: '',
    email: '',
    sexo: '',
    fecha_nacimiento: null,
  });

  const close = () => {
    setState({
      ...state,
      showModal: false,
      condicion: '',
      nombre: '',
    });
  };

  const open = () => {
    const { name, tipos, paterno, materno, phone, mobile, email, sexo, fecha_nacimiento } = props;
    setState({
      ...state,
      showModal: true,
      name,
      tipos: tipos.filter((x) => x.tipoContactoId).map((x) => ({ value: x.tipoContactoId._id, label: x.tipoContactoId.tipo })),
      paterno,
      materno,
      phone,
      mobile,
      email,
      sexo: sexo ? (sexo === 'M' ? { value: 'M', label: 'Masculino' } : { value: 'F', label: 'Femenino' }) : null,
      fecha_nacimiento: fecha_nacimiento ? moment(fecha_nacimiento).format('YYYY-MM-DD') : null,
    });
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const { contactoId, loadContactos } = props;
    const { name, tipos, paterno, materno, phone, mobile, email, sexo, fecha_nacimiento } = state;

    const data = {
      name: name.trim(),
      tipos: tipos.map((x) => x.value),
      paterno,
      materno,
      phone,
      mobile,
      email,
      sexo: sexo.value,
      fecha_nacimiento,
    };

    if (validations(data)) {
      await putRequest({ url: `contactos/${contactoId}`, body: data });
      loadContactos();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.tipos + '')) {
      validate.success = false;
      validate.message = 'Tipos es requerido.';
    } else if (isEmpty(params.name + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.phone + '')) {
      validate.success = false;
      validate.message = 'Teléfono es requerido.';
    } else if (isEmpty(params.email + '')) {
      validate.success = false;
      validate.message = 'Correo electrónico es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const { showModal, isLoading } = state;
  const { tiposContactos } = props;

  return (
    <React.Fragment>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <FormContacto {...state} tiposContactos={tiposContactos} setState={setState} />
      </CbModal>
    </React.Fragment>
  );
};

EditContacto.propTypes = {
  id: PropTypes.number.isRequired,
  loadContactos: PropTypes.func.isRequired,
  tiposContactos: PropTypes.array.isRequired,
  tipos: PropTypes.array.isRequired,
  contactoId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  materno: PropTypes.string.isRequired,
  paterno: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  sexo: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  fecha_nacimiento: PropTypes.string.isRequired,
};

export default EditContacto;
