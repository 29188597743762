import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ nombre: initialNombre, clave: initialClave, itemId, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [clave, setClave] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
  };

  const open = () => {
    setNombre(initialNombre);
    setClave(initialClave);
    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = { nombre, clave };

    if (validations(data)) {
      await putRequest({ url: `tipos_pedidos/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.clave + '')) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} title='Editar' onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='clave' required title='Clave'>
              <input
                type='text'
                id='clave'
                name='clave'
                className='form-control'
                placeholder='Clave'
                onChange={(e) => setClave(e.target.value)}
                value={clave}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='nombre' required title='Nombre'>
              <input
                type='text'
                id='nombre'
                name='nombre'
                className='form-control'
                placeholder='Nombre'
                onChange={(e) => setNombre(e.target.value)}
                value={nombre}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  clave: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Edit;
