import PropTypes from 'prop-types';
import React from 'react';

const BadgeCurrency = ({ children }) => {
  const texto = children === 'DOLAR' ? 'USD' : 'MXN';
  return (
    <span className='badge badge-phoenix badge-phoenix-primary' title={children} style={{ fontSize: '9px' }}>
      {texto}
    </span>
  );
};

BadgeCurrency.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BadgeCurrency;
