import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import { Card } from 'react-bootstrap';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import SelectTipoRecibo from '@components/forms/SelectTipoRecibo';
import Select from '@components/forms/Select';

const Recibos = (props) => {
  const [state, setState] = useState({
    quotations: [],
    activePage: 1,
    count: 0,
    pagination: 20,
    search: '',
    fechaInicio: moment().format('YYYY-MM-01'),
    fechaFin: moment().format('YYYY-MM-DD'),
    storehouses: [],
    sucursales: [],
    clientesAEnviar: [],
    showModal: false,
    enviados: false,
    descuentos: [],
    orden: 'numero_recibo',
    sucursalId: '0',
    agenteId: '0',
    agentes: [],
    loading: false,
    tipo: -1,
    estatus_recibos: {
      INICIADA: 'INICIADA',
      ACEPTADA: 'ACEPTADA',
      DECLINADA: 'DECLINADA',
      AUTORIZADA: 'AUTORIZADA',
      CANCELADA: 'CANCELADA',
      EN_PROCESO: 'EN PROCESO',
    },
    estatus: [],
    estatus_recibo: '0',
    tipos_recibos: [],
    tipoReciboId: '0',
  });

  useEffect(() => {
    loadCatalogos();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [state.estatus_recibo, state.tipoReciboId, state.sucursalId, state.agenteId, state.activePage, state.fechaInicio, state.fechaFin]);

  const loadCatalogos = async () => {
    const {
      data: { sucursales, agente, agentes, almacenes, tipos_recibos },
    } = await getRequest({ url: `recibos-catalogos` });
    setState((prevState) => ({
      ...prevState,
      listSucursales: sucursales,
      listAlmacenes: almacenes,
      tipos_recibos: [
        {
          value: '0',
          label: 'Todos los tipos de recibos',
        },
        ...tipos_recibos.map((tipo_recibo) => ({
          value: tipo_recibo._id,
          label: `${tipo_recibo.nombre}`,
        })),
      ],
      sucursales: [
        {
          value: '0',
          label: 'Todas las sucursales',
        },
        ...sucursales.map((sucursal) => ({
          value: sucursal._id,
          label: `${sucursal.name}`,
        })),
      ],
      agentes: [
        {
          value: '0',
          label: 'Todos los agentes',
        },
        ...agentes.map((agente) => ({
          value: agente._id,
          label: `${agente.nombre}`,
        })),
      ],
      estatus: [
        {
          value: '0',
          label: 'Todos los estatus',
        },
        ...Object.entries(prevState.estatus_recibos).map(([key, value]) => ({
          value: key,
          label: value,
        })),
      ],
      agente: agente,
      agenteId: agente ? agente._id : null,
      sucursalId: agente ? agente.sucursalId : null,
      almacenId: agente ? agente.almacenId : null,
    }));
  };

  const handleSearch = useCallback(async () => {
    const { pagination, activePage, search, fechaFin, fechaInicio, orden, tipo, sucursalId, agenteId, estatus_recibo, tipoReciboId } = state;
    const page = activePage * pagination - pagination;
    setState((prevState) => ({ ...prevState, loading: true }));
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
      orden,
      sucursalId,
      tipoReciboId,
      agenteId,
      tipo,
      estatus: estatus_recibo,
    };
    const { data, count } = await postRequest({ url: `recibos/search`, params: { page, limit: pagination }, body });
    setState((prevState) => ({
      ...prevState,
      quotations: data,
      count: count,
      loading: false,
    }));
  }, [state.estatus_recibo, state.tipoReciboId, state.sucursalId, state.agenteId, state.activePage, state.fechaInicio, state.fechaFin]);

  const onSearch = (params = { search: '' }) => {
    setState((prevState) => ({
      ...prevState,
      search: params.search,
      activePage: 1,
      fechaInicio: params.fechaInicio,
      fechaFin: params.fechaFin,
    }));
  };

  const handlePagination = (eventKey) => {
    setState((prevState) => ({ ...prevState, activePage: eventKey }));
  };

  const renderList = () => {
    const { quotations } = state;
    return <List {...state} {...props} list={quotations} loadData={() => handleSearch()} />;
  };

  const onSelect = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const { loading, pagination, count, activePage, sucursalId, agenteId, estatus, estatus_recibo, tipoReciboId } = state;
  const listView = !loading ? renderList() : <Loading />;

  return (
    <Module title='Recibos'>
      <Search onSearch={onSearch} withDatetime blockOne='col-sm-12' blockSecond='col-sm-12'>
        <div className='row'>
          <div className='col-sm-2'>
            <div className='input-group'>
              <span className='input-group-text'>
                <Icon icon='tags' title='Tipos de recibos' />
              </span>
              <SelectTipoRecibo value={tipoReciboId} onChange={(value) => onSelect('tipoReciboId', value)} />
            </div>
          </div>
          <div className='col-sm-2'>
            <IfPermission action='TodasSucursales'>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='home' title='sucursales' />
                </span>
                <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
              </div>
            </IfPermission>
          </div>
          <div className='col-sm-2'>
            <IfPermission action='TodosAgentes'>
              <div className='input-group'>
                <span className='input-group-text'>
                  <Icon icon='user' title='Agentes' />
                </span>
                <SelectAgente value={agenteId} onChange={(value) => onSelect('agenteId', value)} />
              </div>
            </IfPermission>
          </div>
          <div className='col-sm-2'>
            <div className='input-group'>
              <span className='input-group-text'>
                <Icon icon='tags' title='Estatus' />
              </span>
              <Select value={estatus_recibo} options={estatus} onChange={(value) => onSelect('estatus_recibo', value)} />
            </div>
          </div>
          <div className='col-sm-4'>
            <Link to='/recibos/nuevo' className='btn btn-success pull-right'>
              <Icon icon='plus' /> Nuevo
            </Link>
          </div>
        </div>
      </Search>
      <Card>
        {listView}
        <Card.Footer>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </Card.Footer>
      </Card>
    </Module>
  );
};

export default Recibos;
