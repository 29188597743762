import React, { useState, useCallback, Fragment } from 'react';
import Search from '@controls/Search';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';

const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const Remisiones = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(async () => {
    setLoading(true);
    const { data } = await postRequest({ url: `indicadores`, body: { search } });
    setData(data);
    setLoading(false);
  }, [search]);

  const onSearch = useCallback(
    (params) => {
      setSearch(params.search);
      handleSearch();
    },
    [handleSearch]
  );

  const renderList = () => {
    const datos = [];
    for (let anio in data) datos.push({ anio, datos: data[anio] });
    for (let i = 0; i < datos.length; i++) {
      const anio = datos[i];
      datos[i].meses = [];
      datos[i].suma = 0;
      datos[i].mesesConVenta = 0;
      datos[i].promedio = 0;
      for (let j = 0; j < meses.length; j++) {
        const mes = meses[j];
        const total = anio.datos
          .filter((d) => d._id.mes === j + 1)
          .map((x) => x.total)
          .reduce((a, b) => a + b, 0);
        datos[i].meses.push({ numero: j + 1, mes, total: total || 0 });
        if (total > 0) {
          datos[i].suma += total;
          datos[i].mesesConVenta++;
        }
      }
      if (datos[i].suma != 0) datos[i].promedio = datos[i].suma / datos[i].mesesConVenta;
    }
    return (
      <Fragment>
        <div className='panel panel-default'>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th width='5%'>Año</th>
                {meses.map((mes, i) => (
                  <th width='5%' className='text-right' key={i}>
                    {mes}
                  </th>
                ))}
                <th width='5%'>Promedio</th>
              </tr>
            </thead>
            <tbody>
              {datos.map((row) => (
                <tr key={row.anio}>
                  <th width='5%'>{row.anio}</th>
                  {row.meses.map((mes, i) => {
                    return (
                      <td width='5%' className='text-right' key={i}>
                        {mes.total}
                      </td>
                    );
                  })}
                  <th width='5%'>{row.promedio}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='panel panel-default'>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th width='5%' className='text-right'>
                  Minimos
                </th>
                {meses.map((mes, i) => {
                  const datosMes = datos.map((x) => x.meses[i].total).filter((x) => x != 0);
                  return (
                    <th width='5%' className='text-right' key={i}>
                      {datosMes.length > 0 ? Math.min(...datosMes) : 0}
                    </th>
                  );
                })}
                <th width='5%'>0</th>
              </tr>
              <tr>
                <th width='5%' className='text-right'>
                  Maximos
                </th>
                {meses.map((mes, i) => {
                  const datosMes = datos.map((x) => x.meses[i].total).filter((x) => x != 0);
                  return (
                    <th width='5%' className='text-right' key={i}>
                      {datosMes.length > 0 ? Math.max(...datosMes) : 0}
                    </th>
                  );
                })}
                <th width='5%'>0</th>
              </tr>
            </thead>
          </table>
        </div>
      </Fragment>
    );
  };

  const listView = !loading ? renderList() : <Loading />;
  return (
    <Module title='Maximos y Minimos de ventas'>
      <Search onSearch={onSearch} blockOne='col-sm-7' blockSecond='col-sm-5'></Search>
      {listView}
    </Module>
  );
};

export default Remisiones;
