import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyD_Eg_re4nHLgp05E8bFCFnrDvI6EdteF0');
Geocode.setLanguage('es');
Geocode.setRegion('es');
Geocode.setLocationType('ROOFTOP');
Geocode.enableDebug();

export const searchPosition = async (address) => {
  try {
    const response = await Geocode.fromAddress(address.trim());
    const { geometry, formatted_address } = response.results[0];
    return { data: geometry, ubicacion: formatted_address };
  } catch {
    return null;
  }
};

export const searchAddress = async (lat, lng) => {
  try {
    const response = await Geocode.fromLatLng(lat, lng);
    const { formatted_address } = response.results[0];
    return formatted_address;
  } catch {
    return null;
  }
};
