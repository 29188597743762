import React, { useState, useEffect } from 'react';
import Panel from '@controls/Panel';
import { Module, Icon } from '@stateless';

import { getRequest } from '@utils/api';

import PersonaFisica from './PersonaFisica';
import TableQuotations from './TableQuotations';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetailCustomer = () => {
  const { prospectoId } = useParams();
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      const { data } = await getRequest({ url: `prospectos/${prospectoId}` });
      setCustomer(data);
      setLoading(false);
    };

    loadData();
  }, [prospectoId]);

  const { name, paterno, materno } = customer;
  const customeName = `${name} ${paterno} ${materno}`;

  return (
    <Module onClickBack={window.history.back} parent='Prospectos' title={`Detalle de ${customeName}`} loading={loading}>
      <Panel
        type='primary'
        header={
          <div>
            <Icon icon='home' /> Información
          </div>
        }
      >
        <PersonaFisica {...customer} />
      </Panel>

      <Row>
        <TableQuotations {...customer} />
      </Row>
    </Module>
  );
};

DetailCustomer.propTypes = {};

export default DetailCustomer;
