import React from 'react';
import PropTypes from 'prop-types';
import CbBadgeMoneda from '@cbcomponents/CbBadgeMoneda';
import { TextCurrency } from '@controls';
import { MONEDA } from '@config/constants';

const RowBuscadorProducto = ({ x, onClickEquipo, monedaId, tipoCambio }) => {
  let precio = x.precio;
  let precio_original = precio;
  let moneda = x.moneda;
  if (monedaId !== x.moneda) {
    if (monedaId === MONEDA.PESO && x.moneda === MONEDA.DOLAR) {
      precio = precio_original * tipoCambio;
    } else if (monedaId === MONEDA.DOLAR && x.moneda === MONEDA.PESO) {
      precio = precio_original / tipoCambio;
    }
    moneda = monedaId;
  }
  return (
    <tr key={x._id} onDoubleClick={() => onClickEquipo(x)} style={{ cursor: 'pointer' }}>
      <td>{x.code}</td>
      <td>{x.description}</td>
      <td>{x.equipmentsMeasureId?.measure}</td>
      <td>{x.clasificacionCotizacionId?.nombre}</td>
      <td>{x.marcaId?.nombre}</td>
      <td className='text-right'>
        <TextCurrency>{precio}</TextCurrency> <CbBadgeMoneda moneda={moneda} />
      </td>
    </tr>
  );
};

RowBuscadorProducto.propTypes = {
  x: PropTypes.object.isRequired,
  onClickEquipo: PropTypes.func.isRequired,
  format: PropTypes.func.isRequired,
  isURL: PropTypes.func.isRequired,
  urlPhoto: PropTypes.func.isRequired,
  monedaId: PropTypes.number.isRequired,
  tipoCambio: PropTypes.number.isRequired,
};

export default RowBuscadorProducto;
