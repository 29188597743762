import React, { useState, useEffect } from 'react';
import Panel from '@controls/Panel';
import { Module, Icon } from '@stateless';
import { getRequest } from '@utils/api';
import { TIPOS_DOMICILIOS } from '@config/constants';
import PersonaFisica from './PersonaFisica';
import PersonaMoral from './PersonaMoral';
import TableContracts from './TableContracts';
import TableSaldos from './TableSaldos';
import TableFacturas from './TableFacturas';
import TableQuotations from './TableQuotations';
import TableContactos from './TableContactos';
import TableDomicilios from './TableDomicilios';
import TableCreditos from './TableCreditos';
import TableReferencias from './TableReferencias';
import PanelAdjuntos from './PanelAdjuntos';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetailCustomer = () => {
  const params = useParams();
  const [customer, setCustomer] = useState({});
  const [markers, setMarkers] = useState([]);
  const [facturas, setFacturas] = useState([]);
  const [adjuntos, setAdjuntos] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { customerId } = params;
    const { data, facturas, url, adjuntos, documentos } = await getRequest({ url: `customer/${customerId}/detail` });
    const markers = [];

    data.listDomicilios.map((d) => {
      if (d.latitud !== 0 && d.longitud !== 0) {
        const icon = d.tipo === TIPOS_DOMICILIOS.SUCURSAL ? 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' : '';
        markers.push({
          position: {
            lat: d.latitud,
            lng: d.longitud,
          },
          showInfo: false,
          name: d.tipo,
          address: (
            <div>
              {d.calle} {d.exterior} {d.interior},
              <br />
              Colonia {d.colonia} C.P. {d.codigo_postal}
              <br />
              {d.municipio} {d.estado}, {d.pais}
            </div>
          ),
          icon,
        });
      }
    });
    setCustomer(data);
    setMarkers(markers);
    setFacturas(facturas);
    setUrl(url);
    setAdjuntos(adjuntos);
    setDocumentos(documentos);
    setLoading(false);
  };

  const handleMarkerClick = (targetMarker) => {
    setMarkers(markers.map((marker) => (marker === targetMarker ? { ...marker, showInfo: true } : marker)));
  };

  const handleMarkerClose = (targetMarker) => {
    setMarkers(markers.map((marker) => (marker === targetMarker ? { ...marker, showInfo: false } : marker)));
  };

  const { numero_cliente, name, paterno, materno, regimen, razon_social } = customer;

  const customeName = regimen === 'F' ? `${name} ${paterno} ${materno}` : razon_social;

  return (
    <Module onClickBack={window.history.back} parent='Clientes' title={`Detalle #${numero_cliente}: ${customeName}`} loading={loading}>
      <Panel
        type='primary'
        header={
          <div>
            <Icon icon='home' /> Información
          </div>
        }
      >
        {regimen === 'F' ? (
          <PersonaFisica {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} />
        ) : (
          <PersonaMoral {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} />
        )}
      </Panel>
      <Row>
        <TableFacturas {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} loadData={loadData} />

        <TableSaldos {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} loadData={loadData} />

        <TableCreditos {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} />

        <TableReferencias {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} />

        <PanelAdjuntos {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} />

        <TableContactos {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} />

        <TableDomicilios
          {...{ customer, markers, facturas, adjuntos, documentos, url, loading }}
          onMarkerClick={handleMarkerClick}
          onMarkerClose={handleMarkerClose}
        />

        <TableContracts {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} />

        <TableQuotations {...{ customer, markers, facturas, adjuntos, documentos, url, loading }} />
      </Row>
    </Module>
  );
};

DetailCustomer.propTypes = {};

export default DetailCustomer;
