import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import SelectCliente from '@components/forms/SelectCliente';
import { useStoreNotification } from '@stores/catalogs.store';
import { DOLAR, EURO, PESO } from '@config/constants';


const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [codigo, setCodigo] = useState('');
  const [nombre, setNombre] = useState('');
  const [empresaBanco, setEmpresaBanco] = useState('');
  const [moneda, setMoneda] = useState('');
  const [plazo, setPlazo] = useState('');
  const [segmento, setSegmento] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'codigo') {
      setCodigo(value);
    }
    if (name === 'nombre') {
      setNombre(value);
    }
    if (name === 'empresaBanco') {
      setEmpresaBanco(value);
    }
    if (name === 'moneda') {
      setMoneda(value);
    }
    if (name === 'plazo') {
      setPlazo(value);
    }
    if (name === 'segmento') {
      setSegmento(value);
    }
    if (name === 'comentarios') {
      setComentarios(value);
    }
  };
  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      setEmpresaBanco(itemSelect);   
    } else {
      setEmpresaBanco(null);
    }
  };
  const handleRegister = async (event) => {
    event.preventDefault();
    const data = { 
      codigo: codigo.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      empresaBanco, 
      moneda, 
      plazo: plazo.trim().toUpperCase(), 
      segmento, 
      comentarios : comentarios.trim().toUpperCase()};

    if (validations(data)) {
      await postRequest({ url: 'acreedores', body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.codigo + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.empresaBanco + '')) {
      validate.success = false;
      validate.message = 'Empresa Banco es requerido.';
    } else if (isEmpty(params.segmento + '')) {
      validate.success = false;
      validate.message = 'Segmento es requerido.';
    } else if (isEmpty(params.plazo + '')) {
      validate.success = false;
      validate.message = 'Plazo es requerido.';
    } else if (isEmpty(params.moneda + '')) {
      validate.success = false;
      validate.message = 'Moneda es requerido.';
    }
    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }
    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='codigo' title='Código' required>
              <input type='text' id='codigo' name='codigo' className='form-control' onChange={onChange} value={codigo} placeholder='Codigo' />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='nombre' title='Nombre' required>
              <input type='text' id='nombre' name='nombre' className='form-control' onChange={onChange} value={nombre} placeholder='Nombre' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='empresaBanco' title='Empresa Banco' required>
              <SelectCliente value={empresaBanco} onChange={onChangeCliente} name='empresaBanco' />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='moneda' title='Moneda' required>
            <select name='moneda' id='moneda' className='form-control' onChange={(e) => setMoneda(e.target.value)} value={moneda}>
                <option value=''>Seleccionar...</option>
                <option value={PESO}>Pesos</option>
                <option value={DOLAR}>Dolares</option>
                <option value={EURO}>Euros</option>
              </select>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='plazo' title='Plazo' required>
              <input type='text' id='plazo' name='plazo' className='form-control' onChange={onChange} value={plazo} placeholder='Plazo' />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='segmento' title='Segmento' required>
              <input type='number' id='segmento' name='segmento' className='form-control' onChange={onChange} value={segmento} placeholder='Segmento' />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <DivFormGroup name='comentarios' title='Comentarios' required>
              <input
                type='text'
                id='comentarios'
                name='comentarios'
                className='form-control'
                onChange={onChange}
                value={comentarios}
                placeholder='Comentarios'
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  nombre: PropTypes.string.isRequired,
  codigo: PropTypes.string.isRequired,
  segmento: PropTypes.string.isRequired,
  empresaBanco: PropTypes.string.isRequired,
  moneda: PropTypes.string.isRequired,
  plazo: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default New;
