import { create } from 'zustand';
import CustomLocalStorage from '@utils/localStorage.js';
import { io } from 'socket.io-client';

const SICOB_APP_TOKEN = 'SICOB_APP_TOKEN';
const SICOB_APP_USER = 'SICOB_APP_USER';
const SICOB_APP_AGENTE = 'SICOB_APP_AGENTE';
const SICOB_APP_MONEDA = 'SICOB_APP_MONEDA';
const SICOB_APP_TASKS = 'SICOB_APP_TASKS';
const SICOB_APP_MESSAGES = 'SICOB_APP_MESSAGES';
const SICOB_APP_NOTIFICATIONS = 'SICOB_APP_NOTIFICATIONS';

export const useAuthStore = create((set, get) => ({
  user: CustomLocalStorage.getItem(SICOB_APP_USER, null),
  token: CustomLocalStorage.getItem(SICOB_APP_TOKEN),
  agente: CustomLocalStorage.getItem(SICOB_APP_AGENTE, null),
  moneda: CustomLocalStorage.getItem(SICOB_APP_MONEDA, null),
  tasks: CustomLocalStorage.getItem(SICOB_APP_TASKS, []),
  notifications: CustomLocalStorage.getItem(SICOB_APP_NOTIFICATIONS, []),
  messages: CustomLocalStorage.getItem(SICOB_APP_MESSAGES, []),
  geolocation: { lat: 0, lng: 0 },
  loading: false,
  hasPermisionPosition: false,
  socket: null,
  socketConnecting: false,
  setSocket: (socket) => set({ socket }),
  connectSocket: () => {
    let socket = get().socket;
    let token = get().token;
    if (!socket?.connected && !get().socketConnecting) {
      set({ socketConnecting: true });
      // console.log('Connecting socket...');
      socket = io(process.env.REACT_APP_URL_WS, {
        path: '/ws',
        reconnectionDelayMax: 10000,
        transports: ['websocket'],
        autoConnect: false,
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        auth: {
          token,
        },
      });
      socket?.connect();
      socket?.on('connect', () => {
        // console.log('Socket connected');
        set({ socketConnecting: false });
      });
      socket?.on('disconnect', () => {
        // console.log('Socket disconnected');
        set({ socketConnecting: false });
      });
      socket?.on('connect_error', (error) => {
        console.error('Socket connect error:', error);
        set({ socketConnecting: false });
      });
      set({ socket });
    }
  },
  disconnectSocket: () => {
    const socket = get().socket;
    if (socket) socket.disconnect();
    set({ socket: null });
    set({ socketConnecting: false });
  },
  setUser: (user) => {
    set({ user });
    CustomLocalStorage.setItem(SICOB_APP_USER, user);
  },
  setToken: (token) => {
    set({ token });
    CustomLocalStorage.setItem(SICOB_APP_TOKEN, token);
  },
  setAgente: (agente) => {
    set({ agente });
    CustomLocalStorage.setItem(SICOB_APP_AGENTE, agente);
  },
  setMoneda: (moneda) => {
    set({ moneda });
    CustomLocalStorage.setItem(SICOB_APP_MONEDA, moneda);
  },
  setTasks: (tasks) => {
    set({ tasks });
    CustomLocalStorage.setItem(SICOB_APP_TASKS, tasks);
  },
  setMessages: (messages) => {
    set({ messages });
    CustomLocalStorage.setItem(SICOB_APP_MESSAGES, messages);
  },
  setNotifications: (notifications) => {
    set({ notifications });
    CustomLocalStorage.setItem(SICOB_APP_NOTIFICATIONS, notifications);
  },
  setLoading: (loading) => set({ loading }),
  setGeolocation: (geolocation) => set({ geolocation }),
  isLogged: () => get().token !== null && get().user !== null,
}));
