import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';

const Detail = ({ codigo, nombre, lista }) => {
  const [showModal, setShowModal] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
  };

  const type = lista.filter((x) => x?.equipoId?.status !== 'ACTIVO').length > 0 ? 'danger' : 'success';

  return (
    <>
      <CbButtonOver onClick={open} title='Ver detalle' icon='list' type={type} size='sm' />
      <CbModal show={showModal} onClose={close} title={`Equipos del kit - ${codigo} - ${nombre}`} size='xl'>
        <table className='table table-condensed table-hover table-striped'>
          <thead>
            <tr>
              <th width='5%'>Cantidad</th>
              <th width='10%'>Unidad de medida</th>
              <th width='10%'>Código</th>
              <th width='30%'>Equipo</th>
            </tr>
          </thead>
          <tbody>
            {lista.map((e, i) => {
              const { cantidad } = e;
              let { equipoId } = e;
              if (!equipoId) equipoId = {};
              return (
                <tr key={i} className={equipoId.status !== 'ACTIVO' && 'danger'} title={equipoId.status !== 'ACTIVO' && 'Dado de baja o eliminado'}>
                  <td style={{ textAlign: 'right' }}>
                    <u>{cantidad > 0 ? cantidad : ''}</u>
                  </td>
                  <td>{equipoId.equipmentsMeasureId.measure}</td>
                  <td>
                    <Link to={`/equipos/detalle/${equipoId._id}`}>{equipoId.code}</Link>
                  </td>
                  <td>
                    {equipoId.name}{' '}
                    {equipoId.status !== 'ACTIVO' && (
                      <CbBadge type='danger pull-right' title='Dado de baja o Eliminado'>
                        <Icon icon='warning-sign'></Icon>
                      </CbBadge>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CbModal>
    </>
  );
};

Detail.propTypes = {
  id: PropTypes.number.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  lista: PropTypes.array.isRequired,
};

export default Detail;
