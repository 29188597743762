import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Edit from './Edit';

const List = ({ data, loadData }) => {
  if (!data) {
    return <p>Cargando datos...</p>;
  }
  const renderRows = () => {
    return data.map((row, i) => {
      const { _id, nombre, codigo, empresaBanco, moneda, plazo, segmento, comentarios } = row;
      return (
        <tr key={i}>
          <td>{nombre}</td>
          <td>{codigo}</td>
          <td>{empresaBanco?.razon_social}</td>
          <td>{moneda}</td>
          <td>
            <IfPermission action='eliminar'>
              <Delete id={i} itemId={_id} nombre={nombre} loadData={loadData}/> 
            </IfPermission>
            <IfPermission action='editar'>
              <Edit id={i} itemId={_id} codigo={codigo} nombre={nombre} empresaBanco={empresaBanco} moneda={moneda} plazo={plazo} segmento={segmento} comentarios={comentarios} loadData={loadData}/>
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Acreedor</th>
          <th width='20%'>Código</th>
          <th width='20%'>Empresa</th>
          <th width='20%'>Moneda</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired
};

export default List;
