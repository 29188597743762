import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectEstado from '@components/forms/SelectEstado';
import SelectPais from '@components/forms/SelectPais';

const Edit = ({ municipioId, load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [clave, setClave] = useState('');
  const [nombre, setNombre] = useState('');
  const [estadoId, setEstadoId] = useState(null);
  const [paisId, setPaisId] = useState(null);
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setClave('');
    setNombre('');
    setEstadoId(null);
  };

  const open = async () => {
    const {
      data: { clave, nombre, estadoId },
    } = await getRequest({ url: `municipios/${municipioId}` });
    setClave(clave);
    setNombre(nombre);
    setEstadoId(estadoId._id);
    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      clave,
      nombre,
      estadoId,
      paisId: paisId?._id,
    };

    if (validations(data)) {
      await putRequest({ url: `municipios/${municipioId}`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.estadoId + '')) {
      validate.success = false;
      validate.message = 'Estado es requerido.';
    } else if (isEmpty(params.clave + '')) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='pais' title='País'>
              <SelectPais name='paisId' value={paisId} onChange={(value) => setPaisId(value)} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='estadoId' title='Estado' required>
              <SelectEstado name='estadoId' value={estadoId} onChange={setEstadoId} filter={(value) => value.paisId == paisId?._id} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <DivFormGroup name='clave' title='Clave ' required>
              <Input name='clave' onChange={(e) => setClave(e.target.value)} value={clave} />
            </DivFormGroup>
          </Col>
          <Col sm='6'>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  municipioId: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  estados: PropTypes.array.isRequired,
};

export default Edit;
