import { DirectionsRenderer, GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { CULIACAN } from '@config/constants';
import { DivFormGroup, Input, Label } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import useAuth from '@hooks/useAuth';
import { useStoreNotification } from '@stores/catalogs.store';
import { searchPosition } from '@utils/googleMaps';
import MultiSelectAgentes from '@components/forms/MultiSelectAgentes';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const New = ({ ordenServicioId, loadData, horas, agentes_asignados }) => {
  const { addNotification: notification } = useStoreNotification();
  const { geolocation } = useAuth();
  const [state, setState] = useState({
    ordenServicioId: '',
    fecha_hora: moment().format('DD/MM/YYYY HH:mm'),
    horas_servicio: 1,
    agentes_asignados: [],
    observaciones: '',
    agente_id: [],
    domicilios: [],
    directions: [],
    showModal: false,
  });

  const close = () => {
    setState({
      ...state,
      showModal: false,
      ordenServicioId: '',
      fecha_hora: moment().format('YYYY-MM-DD HH:mm'),
      horas_servicio: 1,
      observaciones: '',
      agente_id: [],
    });
  };

  const onLoad = async () => {
    const { domicilios } = state;
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    const directions = [];
    let ubicacionAnterio = '';
    for (let i = 0; i < domicilios.length; i++) {
      const { ubicacion } = domicilios[i];
      if (ubicacionAnterio) {
        const response = await directionsService.route({
          origin: ubicacionAnterio,
          destination: ubicacion,
          travelMode: 'DRIVING',
        });
        directions.push(response);
      }
      ubicacionAnterio = ubicacion;
    }
    setState({ ...state, directions: [...directions] });
  };

  const onSelect = (campo, value) => {
    setState({ ...state, [campo]: value }, obtenerDomicilios);
  };

  const open = async () => {
    const responseOrdenServicio = await getRequest({ url: `ordenes-servicio/${ordenServicioId}` });
    const domicilios = [];
    const listDomicilios = [responseOrdenServicio.data.domicilio_entrega];
    for (let i = 0; i < listDomicilios.length; i++) {
      const x = listDomicilios[i];
      let ubicacionActual = x.tiene_posicion ? x.ubicacion : '';
      let posicionActual = x.tiene_posicion ? { lat: x.latitud, lng: x.longitud } : '';
      if (!x.tiene_posicion) {
        const address = `${x.calle} ${x.exterior} ${x.interior} ${x.colonia} ${x.municipio} ${x.estado}`;
        const { data, ubicacion } = await searchPosition(address);
        ubicacionActual = ubicacion;
        posicionActual = { lat: data.location.lat, lng: data.location.lng };
        await postRequest({
          url: `customers/${responseOrdenServicio.data.cliente._id._id}/domicilios/posicion/${x._id}`,
          body: { location: data.location, ubicacion },
        });
      }
      domicilios.push({
        position: posicionActual,
        address: (
          <div>
            {x.calle} {x.exterior} {x.interior},
            <br />
            Colonia {x.colonia} C.P. {x.codigo_postal}
            <br />
            {x.municipio} {x.estado}, {x.pais}
          </div>
        ),
        name: x.nombre,
        ubicacion: ubicacionActual,
      });
    }
    const google = window.google;
    const directions = [];
    if (google) {
      const directionsService = new google.maps.DirectionsService();
      let ubicacionAnterio = '';
      for (let i = 0; i < domicilios.length; i++) {
        const { ubicacion } = domicilios[i];
        if (ubicacionAnterio) {
          const response = await directionsService.route({
            origin: ubicacionAnterio,
            destination: ubicacion,
            travelMode: 'DRIVING',
          });
          directions.push({ ...response });
        }
        ubicacionAnterio = ubicacion;
      }
    }
    setState({
      ...state,
      domicilios,
      directions: [...directions],
      showModal: true,
      horas_servicio: horas,
      agente_id: agentes_asignados,
      ordenServicioId: ordenServicioId,
      fecha_hora: moment().format('YYYY-MM-DD HH:mm'),
    });
  };

  const obtenerDomicilios = async () => {
    const { fecha_hora, agente_id } = state;
    const body = { fecha_hora, agentes_asignados: agente_id.map((x) => x._id) };
    const { data } = await postRequest({ url: `ordenes-servicios-visitas/visitasDelDia`, body });
    const domicilios = state.domicilios;
    for (let i = 0; i < data.length; i++) {
      const agente = data[i];
      for (let j = 0; j < agente.visitas.length; j++) {
        const visita = agente.visitas[j];
        const x = visita.ordenServicioId.domicilio_entrega;
        if (x) {
          let ubicacionActual = x.tiene_posicion ? x.ubicacion : '';
          let posicionActual = x.tiene_posicion ? { lat: x.latitud, lng: x.longitud } : '';
          if (!x.tiene_posicion) {
            const address = `${x.calle} ${x.exterior} ${x.interior} ${x.colonia} ${x.municipio} ${x.estado}`;
            const { data, ubicacion } = await searchPosition(address);
            ubicacionActual = ubicacion;
            posicionActual = { lat: data.location.lat, lng: data.location.lng };
            await postRequest({ url: `customers/${visita._id}/domicilios/posicion/${x._id}`, body: { location: data.location, ubicacion } });
          }
          domicilios.push({
            position: posicionActual,
            address: (
              <div>
                {x.calle} {x.exterior} {x.interior},
                <br />
                Colonia {x.colonia} C.P. {x.codigo_postal}
                <br />
                {x.municipio} {x.estado}, {x.pais}
              </div>
            ),
            name: x.nombre,
            ubicacion: ubicacionActual,
          });
        }
      }
    }
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    const directions = [];
    let ubicacionAnterio = '';
    for (let i = 0; i < domicilios.length; i++) {
      const { ubicacion } = domicilios[i];
      if (ubicacionAnterio) {
        const response = await directionsService.route({
          origin: ubicacionAnterio,
          destination: ubicacion,
          travelMode: 'DRIVING',
        });
        directions.push(response);
      }
      ubicacionAnterio = ubicacion;
    }
    setState({
      ...state,
      domicilios,
      directions: [...directions],
    });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleAddVisita = async () => {
    const { ordenServicioId, fecha_hora, horas_servicio, agente_id, observaciones } = state;
    const fech_hor = moment(Date.parse(fecha_hora)).utc();
    const data = { fecha_hora: fech_hor, horas_servicio, agente_id: agente_id.map((x) => x._id), observaciones };
    if (validations(data)) {
      await postRequest({ url: `ordenes-servicios-visitas/agregar/${ordenServicioId}`, body: data });
      close();
      loadData();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.observaciones + '')) {
      validate.success = false;
      validate.message = 'Observaciones son requeridas.';
    } else if (params.agente_id.length <= 0) {
      validate.success = false;
      validate.message = 'Agentes son requeridos.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onMarkerClose = () => {};
  const onMarkerClick = () => {};

  const { showModal, agente_id, fecha_hora, horas_servicio, observaciones, domicilios, directions } = state;

  return (
    <>
      <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Nueva visita</Tooltip>}>
        <button onClick={open} className='btn btn-outline-success btn-sm pull-right' type='button'>
          <Icon icon='plus' />
        </button>
      </OverlayTrigger>
      <Modal size='large' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Nueva visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='row'>
              <div className='col-sm-6'>
                <DivFormGroup>
                  <Label name='fecha_hora' title='Fecha y hora' />
                  <Input type='datetime-local' name='fecha_hora' onChange={onChange} value={fecha_hora} />
                </DivFormGroup>
              </div>
              <div className='col-sm-6'>
                <DivFormGroup>
                  <Label name='horas_servicio' title='Horas de servicio' />
                  <Input type='number' name='horas_servicio' onChange={onChange} value={horas_servicio} />
                </DivFormGroup>
              </div>
              <div className='col-sm-12'>
                <DivFormGroup>
                  <Label name='observaciones' title='Observaciones' required />
                  <textarea className='form-control' placeholder='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
                </DivFormGroup>
              </div>
              <div className='col-sm-12'>
                <DivFormGroup>
                  <Label name='agente_id' required title='Agente(s)' />
                  <MultiSelectAgentes value={agente_id} onChange={(value) => onSelect('agente_id', value)} filter={(x) => x.es_tecnico} />
                </DivFormGroup>
              </div>
            </div>
          </form>
          <LoadScript googleMapsApiKey='AIzaSyD_Eg_re4nHLgp05E8bFCFnrDvI6EdteF0' onLoad={onLoad} libraries={['places']}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{
                lat: geolocation?.latitude || CULIACAN.LATITUDE,
                lng: geolocation?.longitude || CULIACAN.LONGITUDE,
              }}
              zoom={11}
            >
              {directions.map((x, i) => (
                <DirectionsRenderer key={i} directions={x} />
              ))}
              {domicilios.map((x, i) => (
                <Marker key={i} title={x.title} position={x.position} draggable={false} onClick={() => onMarkerClick(x)}>
                  {x.showInfo && (
                    <InfoWindow onCloseClick={() => onMarkerClose(x)}>
                      <div>
                        <strong>{x.name}</strong>
                        <div>{x.address}</div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </GoogleMap>
          </LoadScript>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleAddVisita} type='button' className='btn btn-success pull-right'>
            Agregar
          </button>
          <span className='pull-right'>&nbsp;</span>
          <button onClick={close} className='btn btn-default pull-right' type='button'>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

New.propTypes = {
  ordenServicioId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  horas: PropTypes.number,
  agentes_asignados: PropTypes.array,
};

export default New;
