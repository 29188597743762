import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';

const ContactoPrincipal = ({ type, value, principal, clienteId, callback }) => {
  const [showIcon, setShowIcon] = useState(false);

  const handlePrincipal = async (event) => {
    event.preventDefault();
    const body = { type, value };
    await postRequest({ url: `contactoprincipal/${clienteId}`, body });
    callback();
    setShowIcon(false);
  };

  const handleMouseOver = () => {
    setShowIcon(true);
  };

  const handleMouseLeave = () => {
    setShowIcon(false);
  };

  let isPrincipal = false;

  if (principal !== null) {
    if (type === 'email' && value === principal.email) {
      isPrincipal = true;
    } else if (type === 'mobile' && value === principal.mobile) {
      isPrincipal = true;
    } else if (type === 'phone' && value === principal.phone) {
      isPrincipal = true;
    }
  }

  return isPrincipal ? (
    <span className='text-success'>
      <strong>{value}</strong>
    </span>
  ) : (
    <a
      href='#'
      style={{ textDecoration: 'none', color: '#333333' }}
      onClick={handlePrincipal}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {value}{' '}
      <small style={showIcon ? { display: 'block' } : { display: 'none' }} className='text-danger'>
        <em>
          <Icon icon='check' /> marcar principal
        </em>
      </small>
    </a>
  );
};

ContactoPrincipal.propTypes = {
  type: PropTypes.oneOf(['email', 'mobile', 'phone']),
  value: PropTypes.string,
  principal: PropTypes.object,
  clienteId: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};


export default ContactoPrincipal;
