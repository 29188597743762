import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import New from './New';
import { Col, Row } from 'react-bootstrap';
import Select from '@components/forms/Select';

const FormaPago = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');
  const [tipo, setTipo] = useState('0');
  const tipos_formas_pago = [
    { value: '0', label: 'TODOS LOS TIPOS' },
    { value: 'EQUIPO', label: 'EQUIPO' },
    { value: 'SERVICIO', label: 'SERVICIO' },
  ];

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      tipo,
    };
    const { data, count } = await postRequest({ url: 'formapago/search', params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search, tipo]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setActivePage(1);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
  };

  const onSelect = (name, value) => {
    if (name === 'tipo') {
      setTipo(value);
    }
    handlePagination(1);
  };

  return (
    <Module title='Formas de pago'>
      <Search onSearch={onSearch} blockOne='col-sm-6' blockSecond='col-sm-6'>
        <Row>
          <Col sm='8'>
            <div className='input-group'>
              <span className='input-group-addon'>
                <Icon icon='tags' title='Tipo' />
              </span>
              <Select value={tipo} options={tipos_formas_pago} onChange={(value) => onSelect('tipo', value)} />
            </div>
          </Col>
          <Col sm='4'>
            <IfPermission action='crear'>
              <New load={handleSearch} />
            </IfPermission>
          </Col>
        </Row>
      </Search>
      <Loading loading={loading}>
        <List list={list} load={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

FormaPago.propTypes = {};

export default FormaPago;
