import { ALLOWED } from '@config/constants';
import Panel from '@controls/Panel';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';
import RowSaldo from './RowSaldo';

const TableSaldos = ({ customer: { numero_cliente, listSaldos }, loadData }) => {
  const renderTableSaldos = () => {
    const rows = listSaldos.map((s, i) => <RowSaldo key={i} saldo={s} {...{ loadData }} />);
    return (
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th>Factura</th>
            <th>Moneda</th>
            <th>Agente</th>
            <th>Fecha de Factura</th>
            <th>Vencimiento</th>
            <th>Días</th>
            <th>Saldo por vencer</th>
            <th>Saldo vencido</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{listSaldos.length > 0 && ALLOWED.indexOf(parseInt(numero_cliente)) !== -1 ? rows : <RowsNotFound colSpan={9} />}</tbody>
      </table>
    );
  };

  const tableSaldos = renderTableSaldos();

  return (
    <div className='col-sm-12' style={{ display: 'none' }}>
      <Panel
        type='primary'
        header={
          <div>
            <Icon icon='warning-sign' /> Saldos
          </div>
        }
      >
        {tableSaldos}
      </Panel>
    </div>
  );
};

TableSaldos.propTypes = {
  loadData: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

export default TableSaldos;
