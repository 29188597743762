import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ proveedores, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [proveedorId, setProveedorId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
    setProveedorId('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nombre') setNombre(value);
    if (name === 'proveedorId') setProveedorId(value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const data = {
      nombre: nombre.trim(),
      proveedorId,
    };

    if (validations(data)) {
      await postRequest({ url: 'servicios-terceros', body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (validate.success && !isMongoId(params.proveedorId + '')) {
      validate.success = false;
      validate.message = 'Proveedor es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal title='Agregar' show={showModal} onClose={close} onSubmit={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <FormInput title='Nombre' required name='nombre' onChange={onChange} value={nombre} />
          </Col>
          <Col>
            <DivFormGroup name='proveedorId' title='Proveedor'>
              <select id='proveedorId' name='proveedorId' className='form-control' onChange={onChange} value={proveedorId}>
                <option value=''>Seleccione</option>
                {proveedores.map((c, i) => (
                  <option key={i} value={c._id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  proveedores: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default New;
