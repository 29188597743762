import * as SECRET from '@config/apiKey';
import jwt from 'jwt-simple';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Saving } from '@stateless';
import '@styles/btn-login.css';
import { getRequest, postUploadRequest, URL_FILES } from '@utils/api';
import { Col, Row } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';

export default function FirmarPublica() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [url, setUrl] = useState(null);
  const cotizacionId = jwt.decode(params.hash, SECRET.API_KEY);
  const canvas = useRef(null);
  const [codigos] = useState(searchParams.get('codigos') === 'true');
  const [desgloseKits] = useState(searchParams.get('desgloseKits') === 'true');
  const [horasServicio] = useState(searchParams.get('horasServicio') === 'true');
  const [partidasConcentradas] = useState(searchParams.get('partidasConcentradas') === 'true');
  useEffect(() => {
    getPdfCotizacion();
  }, []);

  const getPdfCotizacion = async () => {
    setLoading(true);
    setUrl('');
    const { data } = await getRequest({
      url: `cotizaciones/pdf/${cotizacionId}`,
      params: { codigos, desgloseKits, horasServicio, partidasConcentradas },
    });
    if (data) {
      setUrl(`${URL_FILES}/${data.url}`);
    }
    setLoading(false);
  };

  const handleLimpiar = () => {
    canvas.current.clear();
  };

  const handleFirmaCliente = async (event) => {
    event.preventDefault();
    setSaving(true);
    const blob = DataURIToBlob(canvas.current.toDataURL('image/png'));
    const form = new FormData();
    form.append('image', blob, 'firma.png');
    await postUploadRequest({ url: `cotizaciones/firma_cliente/${cotizacionId}`, form });
    handleLimpiar();
    getPdfCotizacion();
    setSaving(false);
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  return (
    <Fragment>
      <div className='container-fluid' style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col sm='12' style={{ height: '100%' }}>
            <iframe src={url} width='100%' height='600px'></iframe>
          </Col>
        </Row>
        <Row>
          <Col sm='12' style={{ textAlign: 'center' }}>
            <h3>Firme aquí</h3>
          </Col>
        </Row>
        <Row>
          <Col sm='12' style={{ textAlign: 'center' }}>
            <div style={{ border: '1px solid black', display: 'inline-block', textAlign: 'center' }}>
              <SignatureCanvas ref={canvas} penColor='black' canvasProps={{ width: 300, height: 150, className: 'sigCanvas', margin: '0 auto' }} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm='12' style={{ textAlign: 'center', margin: '1em' }}>
            <Saving saving={saving} />
            <button className='btn btn-warning mr-5' onClick={handleLimpiar} disabled={loading || saving}>
              Limpiar
            </button>
            <button className='btn btn-primary mr-5' onClick={handleFirmaCliente} disabled={loading || saving}>
              Guardar
            </button>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

FirmarPublica.propTypes = {};
