import { DivFormGroup, FormInput, Input, TextArea } from '@controls';
import Panel from '@controls/Panel';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import useAuth from '@hooks/useAuth';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Observaciones = () => {
  const { addNotification: notification } = useStoreNotification();

  const params = useParams();
  const navigate = useNavigate();

  const { geolocation } = useAuth();
  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    isLoadingForm: true,
    fecha_hora_inicio: moment().format('YYYY-MM-DD HH:mm'),
    fecha_hora_fin: moment().format('YYYY-MM-DD HH:mm'),
    observaciones: '',
    no_concluyo_trabajo: '',
    comentarios_adicionales_cliente: '',
    ordenServicioId: null,
    observacionId: null,
    numero_orden_servicio: '',
    orden_servicio: null,
    actualizar: false,
    evidencias: [],
    se_concluyo: false,
  });

  useEffect(() => {
    loadData(params.visitaId);
  }, [params.visitaId]);

  const loadData = async (visitaId) => {
    const { data } = await getRequest({ url: `ordenes-servicios-visitas/${visitaId}` });
    setState((prevState) => ({
      ...prevState,
      isLoadingForm: false,
      actualizar: data.observacion ? true : false,
      observacionId: data.observacion ? data.observacion._id : '',
      ordenServicioId: data.ordenServicioId._id,
      fecha_hora_inicio: data.observacion
        ? moment(data.observacion.fecha_hora_inicio).format('YYYY-MM-DD HH:mm')
        : moment().format('YYYY-MM-DD HH:mm'),
      fecha_hora_fin: data.observacion ? moment(data.observacion.fecha_hora_fin).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'),
      observaciones: data.observacion ? data.observacion.observaciones : '',
      se_concluyo: data.observacion ? data.observacion.se_concluyo : false,
      no_concluyo_trabajo: data.observacion ? data.observacion.no_concluyo_trabajo : '',
      comentarios_adicionales_cliente: data.observacion ? data.observacion.comentarios_adicionales_cliente : '',
      orden_servicio: data.ordenServicioId,
      numero_orden_servicio: data.ordenServicioId.numero_orden_servicio,
    }));
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!params.se_concluyo) {
      if (isEmpty(params.no_concluyo_trabajo + '')) {
        validate.success = false;
        validate.message = 'Explicación es requerida.';
      }
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChange = (event) => {
    const { name, checked, type, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    const { no_concluyo_trabajo, se_concluyo } = state;
    const data = {
      no_concluyo_trabajo,
      se_concluyo,
    };
    if (validations(data)) {
      await postRequest({
        url: `ordenes-servicios-visitas/comentarios/${params.visitaId}`,
        params: {
          latitude: geolocation?.latitude,
          longitude: geolocation?.longitude,
        },
        body: data,
      });
      setTimeout(() => {
        navigate(`/dashboard`);
      }, 1000);
    }
  };

  const renderView = () => {
    const { isLoading, fecha_hora_inicio, no_concluyo_trabajo, numero_orden_servicio, se_concluyo } = state;

    return (
      <>
        <Panel
          type='primary'
          header={
            <div>
              <Icon icon='wrench' /> Conclusión del trabajo
            </div>
          }
        >
          <div className='row'>
            <div className='col-sm-6'>
              <FormInput
                title='Numero de orden de servicio'
                placeholder='Numero de orden de servicio'
                disabled
                name='numero_orden_servicio'
                value={numero_orden_servicio}
              />
            </div>
            <div className='col-sm-6'>
              <DivFormGroup name='fecha_hora_inicio' title='Fecha y hora de inicio' required>
                <Input type='datetime-local' name='fecha_hora_inicio' onChange={onChange} disabled value={fecha_hora_inicio} />
              </DivFormGroup>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <DivFormGroup name='no_concluyo_trabajo' title='¿No se concluyó el trabajo? Explique por qué' required={!se_concluyo}>
                <TextArea
                  id='no_concluyo_trabajo'
                  name='no_concluyo_trabajo'
                  rows={5}
                  value={no_concluyo_trabajo}
                  disabled={se_concluyo}
                  onChange={onChange}
                />
              </DivFormGroup>
            </div>
          </div>
        </Panel>
        <div className='panel panel-default'>
          <div className='panel-footer'>
            <div className='row'>
              <div className='col-sm-12'>
                <Saving saving={isLoading} />
                <Button onClick={handleAdd} className='btn btn-primary pull-right mr-5' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} parent='Ordenes de servicios' title='Detalles del trabajo'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Observaciones.propTypes = {};

export default Observaciones;
