import React from 'react';
import RowWorkforce from './RowWorkforce';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableFooterBotton from '@cbcomponents/CbTableFooterBotton';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';

const TableWorkForces = ({ listLevels, onChangeWorkforces }) => {
  const onChangeWorkforce = (idx, workforce) => {
    listLevels[idx] = workforce;
    onChangeWorkforces([...listLevels]);
  };

  const removeItemLevel = (idx) => {
    listLevels.splice(idx, 1);
    onChangeWorkforces([...listLevels]);
  };

  const addItemWorkforce = () => {
    listLevels.push({ level: listLevels.length + 1, workforce: 0 });
    onChangeWorkforces([...listLevels]);
  };

  return (
    <CbPanelTableResponsive
      title={
        <>
          <Icon icon='screenshot' /> Mano de obra nivel (Hr)
        </>
      }
    >
      <thead>
        <tr>
          <th>Nivel</th>
          <th>Valor</th>
          <th></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>
        {listLevels.map((workforce, i) => (
          <RowWorkforce
            key={i}
            workforce={workforce}
            onChangeWorkforce={(workforce) => onChangeWorkforce(i, workforce)}
            onRemoveItemWorkforce={() => removeItemLevel(i)}
          />
        ))}
        <CbTableFooterBotton colSpan={3} title='Agregar nivel' onClick={addItemWorkforce} />
      </CbTableBody>
    </CbPanelTableResponsive>
  );
};

TableWorkForces.propTypes = {
  listLevels: PropTypes.array,
  onChangeWorkforces: PropTypes.func,
};

export default TableWorkForces;
