import { TYPE_CONFIGURATIONS } from '@config/constants';
import { RedirectTooltip } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteConfiguration from './delete/DeleteConfiguration';
import EditConfiguration from './edit/EditConfiguration';

const ListConfigurations = ({ catalogConfigurations, loadConfigurations }) => {
  const renderRows = () => {
    return catalogConfigurations.map((row, item) => {
      const { _id, name, note, type, listRecipes, esquemaId } = row;

      let labelType = '';

      if (type === TYPE_CONFIGURATIONS.PRODUCT) {
        labelType = <span className='label label-danger'>Productos</span>;
      } else if (type === TYPE_CONFIGURATIONS.SERVICE) {
        labelType = <span className='label label-warning'>Servicios</span>;
      }

      const esquema = typeof esquemaId !== 'undefined' && esquemaId !== null ? esquemaId.esquema : null;

      return (
        <tr key={item}>
          {note ? (
            <td>
              <h3>{name}</h3>
              <br />
              <small>{note}</small>
            </td>
          ) : (
            <td>
              <h3>{name}</h3>
            </td>
          )}
          <td>{labelType}</td>
          <td>
            <h4>
              <span className='label label-primary'>{listRecipes.length}</span>
            </h4>
          </td>
          <td>{esquema}</td>
          <td>
            <DeleteConfiguration
              id={item}
              configurationId={_id}
              name={name}
              {...{ catalogConfigurations, loadConfigurations }}
              btnClass='btn btn-outline-danger'
            />
            <EditConfiguration id={item} configurationId={_id} {...{ catalogConfigurations, loadConfigurations }} btnClass='btn btn-outline-info' />
            <RedirectTooltip
              id={1}
              icon='list-alt'
              url={`/configuraciones/${_id}`}
              labelTooltip='Detallado de la configuración'
              className='btn btn-outline-primary'
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='30%'>Nombre</th>
          <th width='20%'>Clasificación</th>
          <th width='10%'>Tipos</th>
          <th width='30%'>Esquema de pago</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListConfigurations.propTypes = {
  catalogConfigurations: PropTypes.array.isRequired,
  loadConfigurations: PropTypes.func.isRequired,
};

export default ListConfigurations;
