import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Loading, Module } from '@stateless';
import ListEquipments from './ListEquipments';

import { getRequest, postRequest } from '@utils/api';

const StoreEquipments = () => {
  const [catalogEquipments, setCatalogEquipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  useEffect(() => {
    loadEquipments();
  }, []);

  const loadEquipments = async () => {
    const { data } = await getRequest({ url: `store/equipments` });
    setCatalogEquipments(data);
    setLoading(false);
  };

  const handleSearch = async (params = {}) => {
    const body = { search: params.search };
    const { data } = await postRequest({ url: `store/equipments/search`, body });
    setCatalogEquipments(data);
    setSearch(params.search);
  };

  return (
    <Module title='Almacén de Equipos'>
      <Search onSearch={handleSearch} blockOne='col-sm-4' blockSecond='col-sm-8' />
      <Loading loading={loading}>
        <ListEquipments catalogEquipments={catalogEquipments} loadEquipments={loadEquipments} search={search} />
      </Loading>
    </Module>
  );
};

StoreEquipments.propTypes = {};

export default StoreEquipments;
