import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';

const RedirectTooltip = ({ id, labelTooltip, url, icon, text, className, hiddenXs }) => {
  const linkElement =
    typeof icon === 'string' ? (
      <Link to={url} className={className}>
        <Icon icon={icon} />
        {text && <span className={hiddenXs ? 'hidden-xs' : ''}>{text}</span>}
      </Link>
    ) : (
      <Link to={url} className={className}>
        <span className={hiddenXs ? 'hidden-xs' : ''}>{text}</span>
      </Link>
    );

  return (
    <OverlayTrigger placement='top' overlay={<Tooltip id={id}>{labelTooltip}</Tooltip>}>
      {linkElement}
    </OverlayTrigger>
  );
};

RedirectTooltip.propTypes = {
  id: PropTypes.number.isRequired,
  labelTooltip: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  hiddenXs: PropTypes.bool,
  text: PropTypes.string,
};

export default RedirectTooltip;
