import Confirmation from '@controls/Confirmation';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Edit from './Edit';
import Subclasificadores from './Subclasificadores';
import { ButtonGroup } from 'react-bootstrap';

const List = ({ data, loadData }) => {
  const renderRows = () => {
    return data.map(({ _id, orden, nombre}, item) => (
        <tr key={_id}>
          <td>#{orden}</td>
          <td>{nombre}</td>
          <td>
            <ButtonGroup>
              <IfPermission action='editar'>
                <Subclasificadores id={item} itemId={_id} nombre={nombre} orden={orden} {...{ loadData }} />
              </IfPermission>
              <IfPermission action='editar'>
                <Edit id={item} itemId={_id} nombre={nombre} orden={orden} {...{ loadData }} />
              </IfPermission>
              <IfPermission action='eliminar'>
                <Confirmation
                  btnIcon='trash'
                  typeRequest='deleteRequest'
                  btnType='outline-danger btn-sm'
                  action='Eliminar'
                  url={`clasificadores-levantamientos/${_id}`}
                  description={`¿Confirma que desea eliminar la clasificación de levantamientos <strong><u> ${nombre}</u> </strong>?`}
                  loadData={loadData}
                />
              </IfPermission>
            </ButtonGroup>
          </td>
        </tr>
      ));
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='5%'>#</th>
          <th>Nombre</th>
          <th width='10%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={3}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default List;
