import React from 'react';
import DetailCustomer from '@components/customers/DetailCustomer';
import { Icon } from '@stateless';
import { Badge } from 'react-bootstrap';
import CbBadge from '@cbcomponents/CbBadge';
import PropTypes from 'prop-types';

const RowSeguimiento = ({ r, onSelectContacto }) => {
  return (
    <>
      <tr className='warning'>
        <th>&nbsp;</th>
        <td>{r.customerId.numero_cliente}</td>
        <td>
          <DetailCustomer customer={r.customerId} />
        </td>
      </tr>
      <tr>
        <td style={{ margin: 0, padding: 0, verticalAlign: 'middle' }} colSpan={3}>
          <table className='table table-striped table-condensed table-hover'>
            <thead>
              <tr>
                <th width='5%'>&nbsp;</th>
                <th width='10%'>Tipo</th>
                <th width='30%'>Contacto</th>
                <th width='30%'>Telefonos</th>
                <th>Correos</th>
              </tr>
            </thead>
            <tbody>
              {r.contactos.map((c, i) => {
                const contacto = `${c.name} ${c.paterno} ${c.materno}`;
                const telefonos = [];
                const correos = [];
                if (c.phone) telefonos.push(c.phone);
                if (c.email) correos.push(c.email);
                return (
                  <tr key={i} style={{ cursor: 'pointer' }} onDoubleClick={() => onSelectContacto(r, c, telefonos, correos)}>
                    <td>&nbsp;</td>
                    <td>
                      {c.tipos
                        .filter((x) => x.tipoContactoId)
                        .map((x, j) => (
                          <CbBadge key={j} className='badge-phoenix badge-phoenix-primary'>
                            {x.tipoContactoId.tipo}
                          </CbBadge>
                        ))}
                    </td>
                    <th>{contacto}</th>
                    <td>
                      {telefonos.map((x, j) => (
                        <Badge key={j} className='badge-phoenix badge-phoenix-primary'>
                          {x}
                        </Badge>
                      ))}
                    </td>
                    <td>
                      {correos.map((x, j) => (
                        <Badge key={j} className='badge-phoenix badge-phoenix-primary'>
                          {x}
                        </Badge>
                      ))}
                    </td>
                  </tr>
                );
              })}
              <tr key='new-contact' style={{ cursor: 'pointer' }} onClick={() => onSelectContacto(r)} className='active'>
                <td colSpan={5} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  <Icon icon='plus' style={{ fontSize: '0.8em' }} /> Nuevo contacto
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

RowSeguimiento.propTypes = {
  r: PropTypes.object.isRequired,
  onSelectContacto: PropTypes.func.isRequired,
};

export default RowSeguimiento;
