import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Button, DropdownItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';
import Swal from 'sweetalert2';
import * as api from '@utils/api';

const Confirmation = ({
    isMenu = false,
    action = 'Confirmación',
    typeConfirm = 'warning',
    title = 'Confirmar',
    typeRequest = 'postRequest',
    btnAccept = 'Aceptar',
    btnType = 'success',
    btnClassAccept = 'btn-primary',
    btnClassCancel = 'btn-default',
    btnCancel = 'Cerrar',
    btnSize = '',
    onClick = () => { },
    description,
    url,
    btnIcon,
    id,
    btnTitle,
    loadData = () => { },
    callbackAcept = () => {},
}) => {
  const close = () => {};

  const open = (e) => {
    e && e.preventDefault();
    onClick && onClick();
    confirmation({
      title: title,
      message: description,
      btnAccept, 
      btnClassAccept,
      btnCancel,
      btnClassCancel,
      btnType,
      typeConfirm,
      btnTitle,
      btnIcon,
      url,
      id,
      success: (result) => {
        handleClickAccept(result);
      },
    });
  };

  const confirmation = (params) => {
    Swal.fire({
      html: `<div style="font-size: 1.5em!important;">${params.message}</div>`,
      icon: params.type || 'warning',
      showCancelButton: true,
      confirmButtonColor: '#25b003',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, confirmo',
      cancelButtonText: 'Cancelar',
      ...params,
    }).then((result) => {
      if (result.isConfirmed) {
        params.success(result);
      } else if (result.isDenied) {
        params.cancel(result);
      }
    });
  };

  const handleClickAccept = async (event) => {
    if (url) {
      const { data } = await api[typeRequest]({ url: url.indexOf('?') > -1 ? `${url}&texto=${event.value}` : `${url}?texto=${event.value}` });
      loadData(data);
      close();
    } else {
      callbackAcept();
    }
  };

  return (
    <Fragment>
      {isMenu ? (
        <DropdownItem onClick={open}>
          <Icon icon={btnIcon} /> {action}
        </DropdownItem>
      ) : (
        <OverlayTrigger placement='top' overlay={<Tooltip id={id}>{action}</Tooltip>}>
          <Button onClick={open} variant={btnType} size={btnSize} type='button'>
            <Icon icon={btnIcon} /> {btnTitle}
          </Button>
        </OverlayTrigger>
      )}
    </Fragment>
  );
};

Confirmation.propTypes = {
  id: PropTypes.string.isRequired,
  isMenu: PropTypes.bool.isRequired,
  btnIcon: PropTypes.string.isRequired,
  typeRequest: PropTypes.string,
  typeConfirm: PropTypes.string,
  btnTitle: PropTypes.string,
  btnType: PropTypes.string,
  action: PropTypes.string.isRequired,
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  btnAccept: PropTypes.string,
  btnClassAccept: PropTypes.string,
  btnCancel: PropTypes.string,
  btnClassCancel: PropTypes.string,
  callbackAcept: PropTypes.func,
  onClick: PropTypes.func,
  btnSize: PropTypes.string,
};


export default Confirmation;
