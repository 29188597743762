import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import { getRequest, postRequest } from '@utils/api';
import CbModal from '@cbcomponents/CbModal';
import { Icon } from '@stateless';
import { useStoreTiposLevantamientos } from '@stores/catalogs.store';

const TiposCotizaciones = ({ equipmentId }) => {
  const [showModal, setShowModal] = useState(false);
  const [tiposCotizaciones, setTiposCotizaciones] = useState([]);
  const getTiposLevantamientos = useStoreTiposLevantamientos();

  useEffect(() => {
    getTiposLevantamientos.execute();
  }, []);

  const close = () => setShowModal(false);

  const open = async () => {
    const { data } = await getRequest({ url: `equipments/${equipmentId}` });
    setShowModal(true);
    setTiposCotizaciones(data.tiposCotizacionesId);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const data = {
      tiposCotizacionesId: tiposCotizaciones,
    };
    await postRequest({ url: `equipments/tiposCotizaciones/${equipmentId}`, body: data });
    close();
  };

  const onSelect = (name, value) => {
    setTiposCotizaciones(value);
  };

  return (
    <>
      <Dropdown.Item onClick={open}>
        <Icon icon='tags' /> Tipos de cotizaciones
      </Dropdown.Item>
      <CbModal show={showModal} onClose={close} title='Editar' size='lg' onSave={handleSave}>
        <Row>
          <Col>
            <DivFormGroup name='tiposCotizaciones' title='Tipos cotizaciones' required>
              <Select
                value={tiposCotizaciones}
                name='tiposCotizaciones'
                placeholder='Selecciona opciones'
                options={getTiposLevantamientos.data}
                onChange={(value) => onSelect('tiposCotizaciones', value)}
                isMulti
                getOptionLabel={(x) => x.nombre}
                getOptionValue={(x) => x._id}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

TiposCotizaciones.propTypes = {
  equipmentId: PropTypes.string.isRequired,
  tipos_levantamientos: PropTypes.array.isRequired,
};

export default TiposCotizaciones;
