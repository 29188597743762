import React from 'react';
import moment from 'moment';
import { ESTATUS_SEGUIMIENTOS } from '@config/constants';
import PropTypes from 'prop-types';

const RowSeguimientosPorAtender = ({ seguimiento }) => (
  <tr>
    <td>{seguimiento.titulo}</td>
    <th>{moment(seguimiento.fecha).local().format('YYYY-MM-DD')}</th>
    <td>{seguimiento.customerId && seguimiento.customerId.razon_social}</td>
    <td>
      <span className='label' style={{ background: ESTATUS_SEGUIMIENTOS.find((x) => x.estatus == seguimiento.estatus)?.color || '#fff' }}>
        {seguimiento.estatus}
      </span>
    </td>
  </tr>
);

RowSeguimientosPorAtender.propTypes = {
  seguimiento: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    fecha: PropTypes.string.isRequired,
    customerId: PropTypes.shape({
      razon_social: PropTypes.string.isRequired,
    }),
    estatus: PropTypes.string.isRequired,
  }).isRequired,
};

export default RowSeguimientosPorAtender;
