import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableTodosTotales from '@cbcomponents/CbTableTodosTotales';
import Required from '@controls/Required';
import RowDetalle from './components/RowDetalle';
import { validateUpdate } from '@validations/compras';
import SelectTipoCompra from '@components/forms/SelectTipoCompra';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectMoneda from '@components/forms/SelectMoneda';
import SelectAgente from '@components/forms/SelectAgente';
import SelectProveedor from '@components/forms/SelectProveedor';
import SelectUsoCFDI from '@components/forms/SelectUsoCFDI';
import SelectEquipoServicio from '@components/forms/SelectEquipoServicio';
import InputCurrency from '@components/forms/InputCurrency';
import Select from '@components/forms/Select';

const Edit = ({ params, notification, navigate }) => {
  const [state, setState] = useState({
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fecha_entrega: moment().format('YYYY-MM-DD'),
    monedaId: MONEDA.DOLAR,
    measures: [],
    proveedorObj: null,
    detalles: [],
    usoCfdiId: null,
    observaciones: '',
    estatus: 'INICIADA',
    sucursalId: null,
    proveedor: null,
    productoObj: null,
    agenteObj: null,
    agenteId: null,
    tipoCambio: 0,
    usa_almacen: false,
    usa_servicios: false,
    usa_gastos: false,
    tipo_partida: 'EQUIPO',
    estatus_compra: [
      { value: 'INICIADA', label: 'INICIADA' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
    ],
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSelect = useCallback(
    async (campo, value) => {
      setState((prevState) => ({ ...prevState, [campo]: value }));
      if (campo === 'tipoCompraId') {
        const tipoCompra = state.tipos_compras.find((x) => x._id === value) || {};
        const { data } = await getRequest({ url: `compras/siguienteNumero`, params: { tipoCompraId: tipoCompra._id } });
        setState((prevState) => ({
          ...prevState,
          usa_almacen: tipoCompra.tipoPartidaId ? tipoCompra.tipoPartidaId.usa_almacen : false,
          usa_gastos: tipoCompra.tipoPartidaId ? tipoCompra.tipoPartidaId.usa_gastos : false,
          usa_servicios: tipoCompra.tipoPartidaId ? tipoCompra.tipoPartidaId.usa_servicios : false,
          sucursalId: tipoCompra.tiene_sucursal ? tipoCompra.sucursalId : null,
          almacenId: tipoCompra.tiene_almacen ? tipoCompra.almacenId : null,
          numeroOrden: data.numero_compra,
        }));
      }
    },
    [state.tipos_compras]
  );

  const onChangeProducto = (itemSelect) => {
    const { tipo_partida, detalles } = state;
    if (itemSelect) {
      const producto = itemSelect.row;
      let precio = producto.last_cost || 0;
      let measure = null,
        measureId = null,
        measures = [];
      if (tipo_partida === 'EQUIPO') {
        measure =
          producto.equipmentsMeasureId && producto.equipmentsMeasureId.measure ? producto.equipmentsMeasureId.measure : producto.equipmentsMeasureId;
      } else {
        if (producto.listCost.length > 0) {
          measures = state.measures.filter((x) => {
            const existe = producto.listCost.map((x) => x.measureId).includes(x.value);
            return existe;
          });
        } else {
          measures = state.measures.filter((x) => x.tipo === 'SERVICIO');
        }
      }
      setState((prevState) => ({
        ...prevState,
        productoName: '',
        producto: '',
        productoObj: '',
        detalles: [
          ...detalles,
          {
            ...producto,
            relacionadoId: producto._id,
            descripcion: producto.name.toUpperCase(),
            tipo_partida: tipo_partida,
            noIdentificador: producto.code,
            moneda_original: producto.moneda,
            precio_original: producto.last_cost || 0,
            measure,
            measures,
            measureId,
            precio,
            cantidad: 1,
            descuento: 0,
            impuesto: producto.iva || 16,
            importe: precio,
          },
        ],
      }));
    }
  };

  const onChangeDetalle = (name, index, event) => {
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => {
        if (i === index) {
          x[name] = event.target.value;
        }
        return x;
      }),
    }));
  };

  const onClickRemoveDetalle = (index, event) => {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, detalles: prevState.detalles.filter((x, i) => i !== index) }));
  };

  const renderDomicilio = (proveedor) => {
    if (!proveedor) return '';
    const { street, numberExt, numberInt, colony, city, postalCode, state } = proveedor.address;
    return `${street} #${numberExt}-${numberInt},${postalCode} ${colony} ${city}, ${state}`;
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Error',
        message: error.details[0].message,
        type: 'error',
      });
    }
    return !error;
  };

  const handleRegisterCompra = async (event) => {
    event.preventDefault();
    const {
      tipoCompraId,
      numeroOrden,
      fecha,
      fecha_entrega,
      estatus,
      sucursalId,
      almacenId,
      monedaId,
      tipoCambio,
      total,
      subtotal,
      descuento,
      impuestos,
      proveedor,
      usoCfdiId,
      agenteId,
      observaciones,
      detalles,
    } = state;
    const data = {
      tipoCompraId: tipoCompraId,
      numero_compra: numeroOrden,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      estatus: estatus,
      sucursalId,
      almacenId,
      monedaId,
      tipoCambio: cleanNumber(tipoCambio),
      fecha_entrega: moment(fecha_entrega, 'YYYY-MM-DD').utc(),
      total: total || 0,
      subtotal: subtotal || 0,
      descuento: descuento || 0,
      iva: impuestos || 0,
      proveedorId: proveedor ? proveedor._id : '',
      usoCfdiId,
      agenteId,
      observaciones,
      detalles: detalles.map((detalle) => ({
        ...detalle,
        porcentaje_iva: detalle.impuesto,
        porcentaje_descuento: detalle.descuento,
        iva: detalle.impuesto_total,
        descuento: detalle.descuento_total,
      })),
    };
    if (validations(data)) {
      await putRequest({ url: `compras/${params.compraId}`, body: data });
      setTimeout(() => navigate('/compras'), 1000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { encabezado, detalle },
      } = await getRequest({ url: `compra/${params.compraId}` });
      const tipoCompra = state.tipos_compras.find((x) => x._id === encabezado.tipoCompraId._id) || {};
      setState((prevState) => ({
        ...prevState,
        tipoCambio: 0,
        isLoadingForm: false,
        agenteObj: { value: encabezado.agente._id, label: encabezado.agente.nombre },
        agenteId: encabezado.agente._id,
        agenteName: encabezado.agente.nombre,
        usa_almacen: tipoCompra.tipoPartidaId ? tipoCompra.tipoPartidaId.usa_almacen : false,
        usa_gastos: tipoCompra.tipoPartidaId ? tipoCompra.tipoPartidaId.usa_gastos : false,
        usa_servicios: tipoCompra.tipoPartidaId ? tipoCompra.tipoPartidaId.usa_servicios : false,
        tipoCompraId: encabezado.tipoCompraId._id,
        numeroOrden: encabezado.numero_compra,
        fecha: moment(encabezado.fecha).local().format('YYYY-MM-DD'),
        estatus: encabezado.estatus_compra,
        sucursalId: encabezado.sucursal._id,
        almacenId: encabezado.almacen._id,
        monedaId: encabezado.moneda,
        fecha_entrega: encabezado.fecha_entrega ? moment(encabezado.fecha_entrega).local().format('YYYY-MM-DD') : null,
        total: encabezado.importe,
        subtotal: encabezado.sub_total,
        descuento: encabezado.descuento,
        iva: encabezado.iva,
        proveedorId: encabezado.provider._id._id,
        proveedorName: encabezado.provider.name,
        proveedor: encabezado.provider._id,
        proveedorObj: { value: encabezado.provider._id._id, label: encabezado.provider.name },
        usoCfdiId: encabezado.usoCfdiId ? encabezado.usoCfdiId._id : null,
        observaciones: encabezado.observaciones,
        detalles: detalle.map((x) => {
          let measure = null,
            measureId = null,
            measures = [];
          measure = x.unidadMedida;
          measureId = x.unidadMedidaId._id;
          if (x.tipo_partida === 'EQUIPO') {
            // TODO: revisar
          } else {
            if (x.relacionadoId && x.relacionadoId.listCost && x.relacionadoId.listCost.length > 0) {
              measures = state.measures.filter((y) => x.relacionadoId.listCost.map((z) => z.measureId).includes(y.value));
            } else {
              measures = state.measures.filter((y) => y.tipo === 'SERVICIO');
            }
          }

          return {
            ...x,
            measure,
            measureId,
            measures,
            impuesto: x.porcentaje_iva || 0,
            descuento: x.porcentaje_descuento || 0,
            tipo_partida: x.tipo_partida,
            noIdentificador: x.codigo,
            moneda_original: x.moneda,
            precio_original: x.last_cost,
          };
        }),
      }));
    };

    fetchData();
  }, [params.compraId, state.tipos_compras]);

  const renderView = () => {
    const {
      tipoCompraId,
      numeroOrden,
      fecha,
      fecha_entrega,
      estatus,
      sucursalId,
      monedaId,
      isLoading,
      agenteId,
      proveedor,
      productoObj,
      tipoCambio,
      usoCfdiId,
      detalles,
      observaciones,
      estatus_compra,
    } = state;

    return (
      <form onSubmit={handleRegisterCompra}>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <Row>
              <Col sm='3 col-xs-6'>
                <DivFormGroup name='tipoCompraId' required title='Tipo compra'>
                  <SelectTipoCompra name='tipoCompraId' value={tipoCompraId} onChange={onSelect} />
                </DivFormGroup>
              </Col>
              <Col sm='2 col-xs-6'>
                <FormInput title='Num. de orden' placeholder='Num. de orden' disabled name='numeroOrden' onChange={onChange} value={numeroOrden} />
              </Col>
              <Col sm='2 col-xs-6'>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </Col>
              <Col sm='2 col-xs-6'>
                <FormInput title='Fecha de entrega' type='date' name='fecha_entrega' onChange={onChange} value={fecha_entrega} />
              </Col>
              <Col sm='3 col-xs-6'>
                <DivFormGroup name='estatus' required title='Estatus'>
                  <Select name='estatus' value={estatus} options={estatus_compra} onChange={onSelect.bind(this, 'estatus')} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='3 col-xs-6'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal name='sucursalId' value={sucursalId} onChange={onSelect} />
                </DivFormGroup>
              </Col>
              <Col sm='2 col-xs-6'>
                <DivFormGroup name='monedaId' required title='Moneda'>
                  <SelectMoneda name='monedaId' value={monedaId} onChange={onSelect} />
                </DivFormGroup>
              </Col>
              <Col sm='2 col-xs-6'>
                <DivFormGroup name='tipoCambio' title='Tipo de cambio'>
                  <InputCurrency name='tipoCambio' value={tipoCambio} onChange={onChange} placeholder='Tipo de cambio' />
                </DivFormGroup>
              </Col>
              <Col sm='5'>
                <DivFormGroup name='agenteId' required title='Agente'>
                  <SelectAgente name='agenteId' value={agenteId} onChange={onSelect} />
                </DivFormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='9'>
                <DivFormGroup name='proveedorName' required title='Proveedor'>
                  <SelectProveedor name='proveedorId' value={proveedor} onChange={onSelect} />
                </DivFormGroup>
              </Col>
              <Col sm='3'>
                <FormInput title='RFC' disabled name='proveedorRfc' onChange={onChange} value={proveedor ? proveedor.rfc : ''} />
              </Col>
            </Row>
            <Row>
              <Col sm='9'>
                <FormInput title='Domicilio' disabled name='proveedorDomicilio' onChange={onChange} value={renderDomicilio(proveedor)} />
              </Col>
              <Col sm='3'>
                <DivFormGroup name='usoCfdi' required title='Uso de CFDI'>
                  <SelectUsoCFDI name='usoCfdiId' value={usoCfdiId} onChange={onSelect} />
                </DivFormGroup>
              </Col>
            </Row>
          </div>
        </div>
        <div className='panel panel-default panel-table'>
          <div className='panel-body'>
            <Row>
              <Col sm='10'>
                <DivFormGroup>
                  <div className='input-group'>
                    <SelectEquipoServicio name='producto' value={productoObj} onChange={onChangeProducto} />
                  </div>
                </DivFormGroup>
              </Col>
            </Row>
          </div>
          <CbPanelTableResponsive>
            <thead>
              <tr>
                <th width='10%'>#</th>
                <th>
                  Descripción <Required />
                </th>
                <th>
                  Unidad <Required />
                </th>
                <th>
                  Cantidad <Required />
                </th>
                <th>Cant. Recibida</th>
                <th>
                  Precio <Required />
                </th>
                <th>% Descuento</th>
                <th>% IVA</th>
                <th>Importe</th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <CbTableBody colSpan={10}>
              {state.detalles.map((detalle, i) => (
                <RowDetalle key={i} detalle={detalle} i={i} onChangeDetalle={onChangeDetalle} onClickRemoveDetalle={onClickRemoveDetalle} />
              ))}
              <CbTableTodosTotales colSpan={10} detalles={detalles} />
            </CbTableBody>
          </CbPanelTableResponsive>
          <div className='panel-body'>
            <Row>
              <Col sm='12'>
                <FormInput title='Observaciones:' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </div>
          <div className='panel-footer'>
            <Row>
              <Col sm='12'>
                <Saving saving={isLoading} />
                <button onClick={handleRegisterCompra} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    );
  };

  return (
    <Module onClickBack={window.history.back} parent='Ordenes de compras' title='Editar orden de compra'>
      {!state.isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

Edit.propTypes = {
  params: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default Edit;
