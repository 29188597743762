import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ itemId, loadData, nombre: initialNombre, orden: initialOrden }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [orden, setOrden] = useState(1);
  const [nombre, setNombre] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setOrden(1);
    setNombre('');
  };

  const open = () => {
    setShowModal(true);
    setNombre(initialNombre);
    setOrden(initialOrden);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      orden,
      nombre: nombre.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `clasificadores-levantamientos/${itemId}`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.orden + '')) {
      validate.success = false;
      validate.message = 'Orden es requerido .';
    } else if (params.orden <= 0) {
      validate.success = false;
      validate.message = 'Orden debe ser mayor a cero.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} type='success' icon='pencil' title='Editar' />
      <CbModal title='Editar' show={showModal} onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='orden' title='Orden' required>
              <input
                type='number'
                className='form-control'
                name='orden'
                placeholder='Orden'
                onChange={(e) => setOrden(e.target.value)}
                value={orden}
              />
            </DivFormGroup>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} placeholder='Nombre' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

Edit.propTypes = {
  itemId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  orden: PropTypes.number,
  loadData: PropTypes.func.isRequired,
};

export default Edit;
