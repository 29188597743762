import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { renderNombreCliente } from '@utils/general';
import { format } from '@utils/parseCost';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { useStoreNotification } from '@stores/catalogs.store';

const EnviarReferencias = ({ obtenerFacturasSeleccionadas }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkEnviarCopia, setCheckEnviarCopia] = useState(true);
  const [clientesAEnviar, setClientesAEnviar] = useState([]);
  const [enviados, setEnviados] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    const facturasEnviar = obtenerFacturasSeleccionadas();
    if (facturasEnviar.length === 0) {
      notification({
        title: 'Advertencia',
        message: 'Es necesario seleccionar almenos una factura.',
        type: 'warning',
      });
      return;
    }

    const clientes = [];
    for (let i = 0; i < facturasEnviar.length; i++) {
      const factura = facturasEnviar[i];
      const cliente = clientes.find((x) => x._id === factura.customerId._id);
      if (cliente) {
        cliente.facturas.push(factura);
      } else {
        let correos = [];
        let telefonos = [];
        if (factura.customerId.email) {
          correos.push(factura.customerId.email);
        } else {
          correos = factura.customerId.listContactos.filter((x) => x.email).map((x) => x.email);
        }
        if (factura.customerId.mobile) {
          telefonos.push(factura.customerId.mobile);
        } else if (factura.customerId.phone) {
          telefonos.push(factura.customerId.phone);
        } else {
          telefonos = [
            ...factura.customerId.listContactos.filter((x) => x.phone).map((x) => x.phone),
            ...factura.customerId.listContactos.filter((x) => x.mobile).map((x) => x.mobile),
          ];
        }
        clientes.push({
          ...factura.customerId,
          correos,
          telefonos,
          facturas: [factura],
        });
      }
    }
    setShowModal(true);
    setLoading(false);
    setClientesAEnviar(clientes);
  };

  const handleSendFacturas = async () => {
    let facturas = [];
    for (let i = 0; i < clientesAEnviar.length; i++) {
      const clienteAEnviar = clientesAEnviar[i];
      if (clienteAEnviar.correos.length > 0 && clienteAEnviar.telefonos.length > 0)
        facturas = [...facturas, ...clienteAEnviar.facturas.map((x) => x._id)];
    }
    if (facturas.length === 0) {
      notification({
        title: 'Advertencia',
        message: 'Es necesario que los datos del cliente esten completos para el envío de la referencia.',
        type: 'warning',
      });
      return;
    }
    const body = {
      facturas,
      enviarCopia: checkEnviarCopia,
    };
    const { data } = await postRequest({ url: `facturas/references/oxxo`, body });
    const clientesEnviados = clientesAEnviar.map((x) => {
      const respuesta = data.find((r) => r.numero_cliente === x.numero_cliente);
      if (respuesta) {
        return { ...x, resultado: respuesta.mensaje, resultadoType: respuesta.type };
      }
      return { ...x };
    });
    setClientesAEnviar(clientesEnviados);
    setEnviados(true);
  };

  const onChangeCheck = (event) => {
    const { name, checked } = event.target;
    if (name === 'checkEnviarCopia') {
      setCheckEnviarCopia(checked);
    }
  };

  const sinCorreo = () => (
    <div>
      <span className='label label-danger ' style={{ padding: '5px 6px' }} title='Sin correo'>
        <Icon icon='warning-sign' />
      </span>{' '}
      Sin correos asociado.
    </div>
  );

  const sinTelefonos = () => (
    <div>
      <span className='label label-danger ' style={{ padding: '5px 6px' }} title='Sin telefonos'>
        <Icon icon='warning-sign' />
      </span>{' '}
      Sin telefonos asociado.
    </div>
  );

  const renderRow = (x, classRow, enviados) => (
    <tr key={x.numero_cliente} className={classRow}>
      <td>{renderNombreCliente(x)}</td>
      <td>{x.facturas.map((f) => (f.serie ? `${f.serie}-${f.numero}` : f.numero)).join(', ')}</td>
      <td>
        ${' '}
        {format(
          x.facturas
            .map((f) => {
              let descuento = 0;
              if (f.descuento_pago && f.descuento_pago.percentage > 0) descuento = (f.descuento_pago.percentage / 100) * f.saldo;
              return f.saldo - descuento;
            })
            .reverse((a, b) => a + b, 0)
        )}
      </td>
      <td>{x.correos.length > 0 ? x.correos.map((c) => c).join(', ') : sinCorreo()}</td>
      <td>{x.telefonos.length > 0 ? x.telefonos.map((c) => c).join(', ') : sinTelefonos()}</td>
      {enviados ? <td>{x.resultado}</td> : null}
    </tr>
  );

  const tieneClientesSinDatosDeEnvio = clientesAEnviar.filter((x) => x.correos.length === 0 && x.telefonos.length === 0).length > 0;
  const rows = clientesAEnviar.map((x) =>
    renderRow(x, enviados ? x.resultadoType : x.correos.length === 0 || x.telefonos.length === 0 ? 'danger' : '', enviados)
  );

  return (
    <>
      <CbButtonOver title='Cobrar' icon='usd' onClick={openModal} hiddenXs type='default btn-sm' />
      <CbModal title='Envio de correos' show={showModal} onClose={closeModal} onSave={handleSendFacturas} isLoading={loading}>
        <table className='table table-condensed table-hover table-striped'>
          <thead>
            <tr>
              <th width='35%'>Cliente</th>
              <th width='10%'>Facturas</th>
              <th width='10%'>Importe</th>
              <th width='15%'>Correos electrónicos</th>
              <th width='15%'>Teléfonos</th>
              {enviados ? <th width='15%'>Estatus</th> : null}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        {tieneClientesSinDatosDeEnvio && (
          <div className='alert alert-warning' role='alert'>
            <strong>Atención: </strong> Los registros que se encuentren marcados en rojo no cuentan con un correo o un teléfono asociado, por lo tanto
            no se podra generar una referencia en OXXO.
          </div>
        )}
        <div className='checkbox'>
          <label>
            <input type='checkbox' name='checkEnviarCopia' onChange={onChangeCheck} checked={checkEnviarCopia} /> Enviar a copia a correo de cobranza
          </label>
        </div>
      </CbModal>
    </>
  );
};

EnviarReferencias.propTypes = {
  obtenerFacturasSeleccionadas: PropTypes.func.isRequired,
};

export default EnviarReferencias;
