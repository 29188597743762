import { PropTypes } from 'prop-types';
import React from 'react';
import TextCurrency from './TextCurrency'; // Adjust the import path as necessary

const CbTableTotales = ({ colSpan, title, value }) => {
  return (
    <tr>
      <td colSpan={colSpan - 2}></td>
      <th>{title}</th>
      <td>
        <TextCurrency>{value}</TextCurrency>
      </td>
    </tr>
  );
};

CbTableTotales.propTypes = {
  colSpan: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default CbTableTotales;
