import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Loading } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableFooterBotton from '@cbcomponents/CbTableFooterBotton';
import CbTableTodosTotales from '@cbcomponents/CbTableTodosTotales';
import Required from '@controls/Required';
import RowDetalle from './RowDetalle';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectTipoMonitoreo from '@components/forms/SelectTipoMonitoreo';
import SelectCliente from '@components/forms/SelectCliente';
import SelectCreateOrUpdateDomicilio from '@components/forms/SelectCreateOrUpdateDomicilio';
import SelectDealer from '@components/forms/SelectDealer';
import SelectListaPrecios from '@components/forms/SelectListaPrecios';
import SelectMedioComunicacion from '@components/forms/SelectMedioComunicacion';

const INIT_DETALLE = { servicioId: null, measureId: null, cantidad: 1, name: '', listaPreciosId: null, obj: null };

const New = ({ loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    showModal: false,
    isLoading: false,
    progresss: false,
    loading: false,
    numero: '',
    nombre: '',
    contrato: '',
    cliente_id: null,
    domicilio_monitoreo_id: null,
    dealer_id: null,
    tipo_monitoreo_id: null,
    lista_precios_id: null,
    medio_comunicacion_id: null,
    datos_domicilio_texto: '',
    domicilios: [],
    listas_precios: [],
    dealers: [],
    medios_comunicaciones: [],
    tipos_monitoreos: [],
    observaciones: '',
    detalles: [{ ...INIT_DETALLE }],
  });

  useEffect(() => {
    ActualizarDetalles();
  }, [state.tipo_monitoreo_id, state.lista_precios_id]);

  const close = () => {
    setState({
      ...state,
      showModal: false,
      detalles: [{ ...INIT_DETALLE }],
      numero: '',
      nombre: '',
      contrato: '',
      observaciones: '',
      cliente_id: null,
      domicilio_monitoreo_id: null,
      dealer_id: null,
      tipo_monitoreo_id: null,
      lista_precios_id: null,
      medio_comunicacion_id: null,
    });
  };

  const open = async () => {
    setState({
      ...state,
      showModal: true,
    });
  };

  const onSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const onSelectTipoMonitoreo = async (value) => {
    setState((prevState) => ({
      ...prevState,
      tipo_monitoreo_id: value,
    }));
  };

  const ActualizarDetalles = async () => {
    if (!state.tipo_monitoreo_id || !state.lista_precios_id) return;
    setState((prevState) => ({ ...prevState, loadingDetails: true }));
    const { data } = await getRequest({ url: `tipos-monitoreos/${state.tipo_monitoreo_id._id}` });
    setState((prevState) => ({
      ...prevState,
      loadingDetails: false,
      detalles:
        data?.detalles?.map((detalle) => ({
          servicio: detalle.servicioId,
          descripcion: detalle.descripcion,
          cantidad: detalle.cantidad,
          unidad: detalle.measureId,
          precio: (detalle.listasPrecios || []).find((y) => y?.listaPrecioId?._id === prevState.lista_precios_id?._id)?.precio || 0,
          descuento: (detalle.listasPrecios || []).find((y) => y?.listaPrecioId?._id === prevState.lista_precios_id?._id)?.descuento || 0,
          iva: detalle.servicioId.iva || 16,
        })) || [],
    }));
  };

  const onSelectListaPrecios = (value) => {
    setState((prevState) => ({
      ...prevState,
      lista_precios_id: value,
    }));
  };
  const handleRegister = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {
      numero,
      nombre,
      tipo_monitoreo_id,
      contrato,
      cliente_id,
      domicilio_monitoreo_id,
      dealer_id,
      lista_precios_id,
      medio_comunicacion_id,
      detalles,
      observaciones,
    } = state;

    const data = {
      numero: numero.trim().toUpperCase(),
      nombre: nombre.trim().toUpperCase(),
      tipo_monitoreo_id: tipo_monitoreo_id?._id,
      contrato: contrato.trim().toUpperCase(),
      cliente_id: cliente_id?._id,
      domicilio_monitoreo_id: domicilio_monitoreo_id?._id,
      dealer_id: dealer_id?._id,
      lista_precios_id: lista_precios_id?._id,
      medio_comunicacion_id: medio_comunicacion_id?._id,
      observaciones,
      detalles: detalles.map((x) => ({
        servicioId: x.servicio?._id,
        measureId: x.unidad?._id,
        descripcion: x.descripcion || '',
        precio: cleanNumber(x.precio),
        descuento: cleanNumber(x.descuento),
        cantidad: cleanNumber(x.cantidad),
      })),
    };

    if (validations(data)) {
      await postRequest({ url: `cuentas-monitoreos`, body: data });
      loadData();
      close();
    }
  };

  const addItemServicio = () => {
    setState({ ...state, detalles: [...state.detalles, { ...INIT_DETALLE }] });
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.numero)) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre)) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else {
      for (let i = 0; i < params.detalles.length; i++) {
        const servicio = params.detalles[i];
        if (!isMongoId(servicio.servicioId + '')) {
          validate.success = false;
          validate.message = 'Servicio en servicios es requerido.';
          break;
        } else if (servicio.cantidad <= 0) {
          validate.success = false;
          validate.message = 'Cantidad en servicios, debe ser mayor a cero.';
          break;
        } else if (!isMongoId(servicio.measureId + '')) {
          validate.success = false;
          validate.message = 'Unidad en servicios es requerida.';
          break;
        }
      }
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }
    return validate.success;
  };

  const onChangeServicioDetalle = (index, servicio) => {
    setState({
      ...state,
      detalles: state.detalles.map((x, i) => (i === index ? servicio : x)),
    });
  };

  const onRemoveItemServicio = (index) => {
    setState({ ...state, detalles: state.detalles.filter((x, i) => i !== index) });
  };

  const onChangeCliente = (itemSelect) => {
    if (itemSelect) {
      const domicilios = itemSelect.listDomicilios;
      const domicilioId = domicilios.length === 1 ? domicilios[0] : null;
      const contactos = itemSelect.listContactos;
      const contactoId = contactos.length === 1 ? contactos[0] : null;
      setState({
        ...state,
        cliente_id: itemSelect,
        domicilioId,
        contactoId,
        domicilios: domicilios,
        contactos: contactos,
      });
    } else {
      setState({
        ...state,
        cliente_id: null,
        domicilioId: null,
        domicilios: [],
      });
    }
  };

  const {
    showModal,
    isLoading,
    numero,
    nombre,
    contrato,
    cliente_id,
    domicilio_monitoreo_id,
    dealer_id,
    tipo_monitoreo_id,
    lista_precios_id,
    medio_comunicacion_id,
    domicilios,
    detalles,
    observaciones,
  } = state;

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' size='xl' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col xs={2}>
            <FormInput title='Numero' name='numero' required onChange={(e) => setState({ ...state, numero: e.target.value })} value={numero} />
          </Col>
          <Col xs={4}>
            <FormInput title='Nombre' name='nombre' required onChange={(e) => setState({ ...state, nombre: e.target.value })} value={nombre} />
          </Col>
          <Col xs={3}>
            <DivFormGroup name='tipo_monitoreo_id' required title='Tipo monitoreo'>
              <SelectTipoMonitoreo onChange={(value) => onSelectTipoMonitoreo(value)} value={tipo_monitoreo_id} />
            </DivFormGroup>
          </Col>
          <Col xs={3}>
            <FormInput
              title='Contrato'
              name='contrato'
              required
              onChange={(e) => setState({ ...state, contrato: e.target.value })}
              value={contrato}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <DivFormGroup name='clienteName' required title='Cliente'>
              <SelectCliente value={cliente_id} onChange={onChangeCliente} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SelectCreateOrUpdateDomicilio
              name='domicilio_monitoreo_id'
              customerId={cliente_id}
              value={domicilio_monitoreo_id}
              domicilios={domicilios}
              setDomicilios={(domicilios) => setState({ ...state, domicilios })}
              onChange={(value) => onSelect('domicilio_monitoreo_id', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <DivFormGroup name='medio_comunicacion_id' required title='Medio comunicación'>
              <SelectMedioComunicacion value={medio_comunicacion_id} onChange={(value) => onSelect('medio_comunicacion_id', value)} />
            </DivFormGroup>
          </Col>
          <Col xs={4}>
            <DivFormGroup name='dealer_id' required title='Dealer'>
              <SelectDealer name='dealer_id' onChange={(value) => onSelect('dealer_id', value)} value={dealer_id} />
            </DivFormGroup>
          </Col>
          <Col xs={4}>
            <DivFormGroup name='lista_precios_id' required title='Lista de precios'>
              <SelectListaPrecios name='lista_precios_id' value={lista_precios_id} onChange={onSelectListaPrecios} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              title='Obervaciones'
              name='observaciones'
              required
              onChange={(e) => setState({ ...state, observaciones: e.target.value })}
              value={observaciones}
              type='textarea'
              row='3'
            />
          </Col>
        </Row>
        <br />
        {tipo_monitoreo_id && lista_precios_id && (
          <Row>
            <Col>
              <Loading loading={state.loadingDetails}>
                <CbPanelTableResponsive
                  title={
                    <>
                      Servicios <Required />
                    </>
                  }
                >
                  <thead>
                    <tr>
                      <th width='15%'>Código</th>
                      <th>Servicio</th>
                      <th width='15%'>Medida</th>
                      <th width='5%'>Cant.</th>
                      <th width='10%'>Precio</th>
                      <th width='5%'>Desc.</th>
                      <th width='10%'>&nbsp;</th>
                    </tr>
                  </thead>
                  <CbTableBody colSpan={7}>
                    {detalles.map((servicio, i) => (
                      <RowDetalle
                        key={i}
                        servicio={servicio}
                        onChangeServicioDetalle={(servicio) => onChangeServicioDetalle(i, servicio)}
                        onRemoveItemServicio={() => onRemoveItemServicio(i)}
                      />
                    ))}
                    <CbTableTodosTotales colSpan={7} detalles={detalles} />
                    <CbTableFooterBotton colSpan={7} onClick={addItemServicio} title='Agregar servicio' />
                  </CbTableBody>
                </CbPanelTableResponsive>
              </Loading>
            </Col>
          </Row>
        )}
      </CbModal>
    </>
  );
};

New.propTypes = {
  unidades: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default New;
