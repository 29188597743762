import { DivFormGroup, Input, Label } from '@controls';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
const IS_NEW = 'NEW';

const EditCharacteristics = () => {
  const { addNotification: notification } = useStoreNotification();

  const { characteristicsId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoading: false,
    configurations: [],
    configurationId: '',
    recipes: [],
    recipeId: '',
    recipe: null,
    characteristics: null,
  });

  const frmEditCharacteristics = useRef(null);

  const loadData = async () => {
    const { data, compile } = await getRequest({ url: `characteristics/${characteristicsId}` });
    setState((prevState) => ({
      ...prevState,
      characteristics: data,
      recipe: compile,
      configurationId: data.characteristicsConfigurationId._id,
      recipeId: data.characteristicsRecipeId._id,
    }));
  };

  useEffect(() => {
    loadData();
  }, []);

  const resetForm = () => {
    setTimeout(() => navigate(0), 1000);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const {
      recipe: { _id, listSteps },
      configurationId,
      characteristics: { listFieldsValues },
    } = state;

    const catalogFields = [];

    listSteps.map((row) => {
      if (row.addProcess) {
        const { _id, addNew } = row;

        const field = addNew ? `new-${_id}` : _id;
        const value = row.capturable ? '' : frmEditCharacteristics.current[field].value.trim();

        const original = listFieldsValues.find((x) => x.stepId._id === _id);

        catalogFields.push({
          fieldsValuesId: typeof original !== 'undefined' ? original._id : null,
          value,
          stepId: _id,
          original: typeof original !== 'undefined' ? original.value : null,
          isNew: addNew,
        });
      }
    });

    const data = {
      characteristicsId,
      configurationId,
      recipeId: _id,
      catalogFields,
    };

    const allFieldsEmpty = catalogFields.filter((f) => isEmpty(f.value));

    if (allFieldsEmpty.length !== catalogFields.length) {
      if (validations(data, frmEditCharacteristics.current)) {
        confirmation({
          type: 'warning',
          title: 'Confirmación',
          message:
            '¿Confirma que se modificarán las características? ...Cualquier modificación se vera reflejada en los equipos previamente configurados.',
          autoDismiss: 0,
          done: async () => {
            await putRequest({ url: `characteristics/${characteristicsId}`, body: data });
            resetForm();
          },
        });
      }
    } else {
      notification({
        title: 'Información incompleta',
        message: 'Favor de especificar valores para los campos de Procedimiento',
        type: 'error',
      });
    }
  };

  const onChangeValueStep = (stepId) => {
    const { recipe } = state;
    const isNew = frmEditCharacteristics.current[stepId].value.trim() === IS_NEW;

    recipe.listSteps.map((step) => {
      if (step._id.toString() === stepId.toString()) {
        step.addNew = isNew;
      }
    });

    setState((prevState) => ({ ...prevState, recipe }));

    setTimeout(() => {
      if (isNew) {
        frmEditCharacteristics.current[`new-${stepId}`].focus();
      }
    }, 10);
  };

  const renderForm = () => {
    const {
      isLoading,
      recipe,
      characteristics: { listFieldsValues },
    } = state;

    const listStepsProcessEdit = listFieldsValues.filter((step) => step.stepId.addProcess && !step.stepId.capturable);

    const listStepsProcess = recipe.listSteps.filter((step) => step.addProcess && !step.capturable);
    const listStepsCapturables = recipe.listSteps.filter((step) => step.capturable);

    const formStepsProcess = listStepsProcess.map((itemStep, indexStep) => {
      const { step, note, required, options, addNew, defaultNew } = itemStep;
      const stepId = itemStep._id;

      const currentStep = listStepsProcessEdit.find((x) => x.stepId._id === stepId);

      const optionsValue = Object.keys(options).map((key) => {
        return (
          <option key={key} value={options[key]}>
            {options[key]}
          </option>
        );
      });

      return (
        <Col sm='6' key={indexStep}>
          <DivFormGroup>
            <Label name={stepId} title={required ? `* ${step}` : step} />

            {defaultNew ? (
              <Input name={'new-' + stepId} disabled={!addNew} />
            ) : (
              <div>
                <select
                  id={stepId}
                  name={stepId}
                  ref={stepId}
                  className='form-control'
                  onChange={() => onChangeValueStep(stepId)}
                  defaultValue={typeof currentStep !== 'undefined' ? currentStep.value : ''}
                >
                  <option value=''>Seleccione</option>
                  {optionsValue}
                  <option value={IS_NEW}>Otro ...</option>
                </select>

                {addNew ? (
                  <Input name={'new-' + stepId} disabled={!addNew} style={{ marginTop: '5px' }} placeholder='Favor de proporcionar el nuevo valor' />
                ) : null}
              </div>
            )}

            <small>{note}</small>
          </DivFormGroup>
        </Col>
      );
    });

    const formListCapturables = listStepsCapturables.map((step, i) => {
      return (
        <span key={i}>
          <h3 className='lead'>
            <Icon icon='minus' /> {step.step}
          </h3>
        </span>
      );
    });

    return (
      <div>
        <div className='alert alert-danger'>
          <p>
            <strong>Aviso</strong> Cualquier modificación se vera reflejada en los equipos previamente configurados.
          </p>
        </div>

        {listStepsProcess.length > 0 ? (
          <Row>
            <br />
            <Col>
              <legend>
                <Icon icon='cog' /> Procedimiento
              </legend>
            </Col>
            {formStepsProcess}
          </Row>
        ) : null}

        {formListCapturables.length > 0 ? (
          <Row>
            <br />
            <Col>
              <legend>
                <Icon icon='cog' /> Campos de capturables en carros
              </legend>
            </Col>
            {formListCapturables}
          </Row>
        ) : null}

        <div>
          <Saving saving={isLoading} />
          <Button type='submit' className='btn btn-lg btn-primary pull-right' disabled={isLoading}>
            <Icon icon='floppy-disk' /> Guardar
          </Button>
        </div>
      </div>
    );
  };

  const renderTypeServicesRecipe = () => {
    const {
      characteristics: { characteristicsConfigurationId, characteristicsRecipeId, listFieldsValues },
    } = state;

    return (
      <Module onClickBack={window.history.back} title={`Edición de ${characteristicsConfigurationId.name} / ${characteristicsRecipeId.recipe}`}>
        {listFieldsValues.length > 0 ? (
          <form ref={frmEditCharacteristics} onSubmit={handleRegister}>
            {renderForm()}
          </form>
        ) : (
          <div className='alert alert-success'>
            <p>
              El apartado{' '}
              <strong>
                {characteristicsConfigurationId.name} / {characteristicsRecipeId.recipe}
              </strong>{' '}
              no está debidamente configurado, falta especificar el nombre de los campos de las características.{' '}
              <Link to={`/configuraciones/${characteristicsConfigurationId._id}`}>Click aqui</Link> agregar los campos
            </p>
          </div>
        )}
      </Module>
    );
  };

  const validations = (params, form) => {
    let validate = {
      success: true,
      message: '',
    };

    const {
      recipe: { listSteps },
    } = state;

    listSteps.map((row) => {
      if (row.addProcess) {
        const field = row.addNew ? `new-${row._id}` : row._id;

        if (!row.capturable) {
          if (validate.success && isEmpty(form[field].value) && row.required) {
            validate.success = false;
            validate.message = `El campo ${row.step} es requerido.`;
          }
        }
      }
    });

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const confirmation = (params = {}) => {
    notification({
      title: params.title,
      message: params.message,
      level: params.type,
      position: 'tc',
      autoDismiss: params.autoDismiss,
      action: {
        label: 'Aceptar',
        callback: params.done,
      },
    });
  };

  const { characteristics } = state;

  return characteristics ? renderTypeServicesRecipe() : <Loading />;
};

EditCharacteristics.propTypes = {};

export default EditCharacteristics;
