import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ coloniaId, d_asenta, load }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`colonia/${coloniaId}`}
      description={`¿Confirma que desea eliminar la colonia <strong><u> ${d_asenta}</u> </strong>?`}
      loadData={load}
    />
  );
};

Delete.propTypes = {
  id: PropTypes.number.isRequired,
  d_asenta: PropTypes.string.isRequired,
  coloniaId: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Delete;
