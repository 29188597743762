import PropTypes from 'prop-types';
import React from 'react';

const RowReferencia = ({ referencia }) => {
  const { bancoId } = referencia;
  return (
    <tr>
      <td>{bancoId?.nombre}</td>
      <td>{referencia?.referencia}</td>
    </tr>
  );
};

RowReferencia.propTypes = {
  referencia: PropTypes.object.isRequired,
};

export default RowReferencia;
