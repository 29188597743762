import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useCallback, Fragment } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Icon, Loading, RowsNotFound, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { format } from '@utils/parseCost';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableResponsive from '@cbcomponents/CbTableResponsive';
import CbTableTodosTotales from '@cbcomponents/CbTableTodosTotales';
import InputCurrency from '@components/forms/InputCurrency';
import InputPercentage from '@components/forms/InputPercentage';
import InputNumber from '@components/forms/InputNumber';
import SelectMoneda from '@components/forms/SelectMoneda';
import SelectTipoCompra from '@components/forms/SelectTipoCompra';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAgente from '@components/forms/SelectAgente';
import SelectProveedor from '@components/forms/SelectProveedor';
import SelectUsoCFDI from '@components/forms/SelectUsoCFDI';
import { validateUpdate } from '@validations/compras';

const CompletarOrdenDeCompraConDetalles = ({ solicitudId, loadData, notification, detalles }) => {
  const [state, setState] = useState({
    showModal: false,
    nombre: '',
    isLoading: false,
    detalles: [],
    sucursales: [],
    sucursalId: null,
    remisionar: false,
    tipos_compras: [],
    tipoCompraId: null,
    usoCfdiId: null,
    usos_cfdi: [],
    proveedores: [],
    proveedorName: '',
    proveedorObj: null,
    proveedor: null,
    fecha: moment().format('YYYY-MM-DD'),
    monedaId: MONEDA.DOLAR,
  });

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      condicion: '',
      nombre: '',
      remisionar: false,
    }));
  };

  const open = useCallback(async () => {
    const fecha = moment().format('YYYY-MM-DD');
    const { data } = await getRequest({ url: `tipo-cambio/fecha`, params: { fecha } });
    if (!data?._id) {
      return notification({
        title: 'Advertencia',
        message: 'Se debe capturar el tipo de cambio del dia. Catálogos -> Tipos de cambio',
        type: 'warning',
      });
    }
    const {
      data: { sucursales, monedas, usos_cfdi, ubicaciones, tipos_compras, measures, agente },
    } = await getRequest({ url: `compras-catalogos` });
    setState((prevState) => ({
      ...prevState,
      sucursales: sucursales,
      measures: measures,
      tipos_compras,
      ubicaciones,
      usos_cfdi: usos_cfdi,
      monedas: monedas,
      agenteObj: { value: agente._id, label: agente.nombre },
      agenteId: agente._id,
      maximo_descuento: agente.maximo_descuento,
      sucursalId: agente.sucursalId,
      agenteName: agente.nombre,
      isLoadingForm: false,
      showModal: true,
      tipoCambio: data.valor,
    }));
    obtenerSolicitud(solicitudId);
  }, [solicitudId, notification]);

  const obtenerSolicitud = () => {
    setState((prevState) => ({
      ...prevState,
      isLoadingForm: false,
      detalles: detalles.map((x) => {
        let measure = null,
          measureId = null,
          measures = [];
        measure = x.unidadMedida;
        measureId = x.unidadMedidaId;
        if (x.tipo_partida !== 'EQUIPO') {
          if (x.relacionadoId && x.relacionadoId.listCost && x.relacionadoId.listCost.length > 0) {
            measures = prevState.measures.filter((y) => {
              const existe = x.relacionadoId.listCost.map((z) => z.measureId).includes(y.value);
              return existe;
            });
          } else {
            measures = prevState.measures.filter((y) => y.tipo === 'SERVICIO');
          }
        }

        return {
          ...x,
          measure,
          measureId,
          measures,
          tipo_partida: x.tipo_partida,
          noIdentificador: x.codigo,
          moneda_original: x.relacionadoId.moneda_compra,
          precio_original: x.relacionadoId.cost,
          precio: x.relacionadoId.cost || 0,
          descuento: x.descuento || 0,
          impuesto: x.relacionadoId.iva || 16,
        };
      }),
    }));
  };

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));
  };

  const renderDomicilio = (proveedor) => {
    if (!proveedor) return '';
    const { street, numberExt, numberInt, colony, city, postalCode, state } = proveedor.address;
    return `${street} #${numberExt}-${numberInt},${postalCode} ${colony} ${city}, ${state}`;
  };

  const handleCompletarOrdenDeCompraConDetalles = async (event) => {
    event.preventDefault();
    const {
      tipoCompraId,
      numeroOrden,
      fecha,
      fecha_entrega,
      estatus,
      sucursalId,
      monedaId,
      tipoCambio,
      total,
      subtotal,
      descuento,
      impuestos,
      proveedor,
      usoCfdiId,
      observaciones,
      detalles,
      agenteId,
    } = state;
    const data = {
      tipoCompraId: tipoCompraId,
      numero_compra: numeroOrden,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      estatus_compra: estatus,
      sucursalId,
      monedaId,
      agenteId,
      tipoCambio: cleanNumber(tipoCambio),
      fecha_entrega,
      total: total || 0,
      subtotal: subtotal || 0,
      descuento: descuento || 0,
      iva: impuestos || 0,
      proveedorId: proveedor ? proveedor._id : '',
      usoCfdiId,
      observaciones,
      detalles: detalles.map((detalle) => ({
        ...detalle,
        relacionadoId: detalle.relacionadoId._id,
      })),
    };
    if (validations(data)) {
      await postRequest({ url: `solicitudes-compras/generar-compra`, body: data });
      setState((prevState) => ({ ...prevState, showModal: false }));
      loadData();
    }
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({ title: 'Advertencia', message: error, type: 'warning' });
    }
    return !error;
  };

  const onChangeDetalle = (name, index, event) => {
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => {
        if (i === index) x[name] = cleanNumber(event.target.value);
        return { ...x };
      }),
    }));
  };

  const renderEquipos = () => {
    if (state.detalles.length === 0) return <RowsNotFound message='No se han agregado equipos.' colSpan={12} />;
    return state.detalles.map((detalle, i) => (
      <tr key={i}>
        <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.codigo}</th>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.descripcion}</td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.unidadMedida}</td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
          <InputNumber
            className='form-control input-sm'
            style={{ width: '80px' }}
            value={detalle.cantidad}
            onChange={(e) => onChangeDetalle('cantidad', i, e)}
          />
        </td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
          <InputCurrency
            className='form-control input-sm'
            style={{ width: '80px' }}
            value={detalle.precio}
            onChange={(e) => onChangeDetalle('precio', i, e)}
          />
        </td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
          <InputPercentage
            className='form-control input-sm'
            style={{ width: '80px' }}
            value={detalle.descuento}
            onChange={(e) => onChangeDetalle('descuento', i, e)}
          />
        </td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
          <InputPercentage
            className='form-control input-sm'
            value={detalle.impuesto}
            style={{ width: '80px' }}
            onChange={(e) => onChangeDetalle('impuesto', i, e)}
          />
        </td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>$ {format(detalle.importe, 2)}</td>
        <td>&nbsp;</td>
      </tr>
    ));
  };

  const { showModal, sucursalId, isLoading, tipoCompraId, usoCfdiId, proveedorObj, proveedor, numeroOrden, monedaId, tipoCambio, agenteId } = state;

  return (
    <Fragment>
      <Button variant='outline-primary' onClick={open}>
        <Icon icon='pencil' /> Generar compra
      </Button>
      <Modal size='xl' show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Generar compra</Modal.Title>
        </Modal.Header>
        {!isLoading ? (
          <Fragment>
            <Modal.Body>
              <Row>
                <Col sm='3'>
                  <DivFormGroup name='tipoCompraId' required title='Tipo compra'>
                    <SelectTipoCompra name='tipoCompraId' value={tipoCompraId} onChange={(value) => onSelect('tipoCompraId', value)} />
                  </DivFormGroup>
                </Col>
                <Col xs='3'>
                  <FormInput title='Num. de orden' placeholder='Num. de orden' disabled name='numeroOrden' onChange={onChange} value={numeroOrden} />
                </Col>
                <Col xs='3'>
                  <DivFormGroup name='monedaId' required title='Moneda'>
                    <SelectMoneda name='monedaId' value={monedaId} onChange={(value) => onSelect('monedaId', value)} />
                  </DivFormGroup>
                </Col>
                <Col xs='3'>
                  <DivFormGroup name='tipoCambio' title='Tipo de cambio'>
                    <InputCurrency className='form-control' name='tipoCambio' value={tipoCambio} onChange={onChange} />
                  </DivFormGroup>
                </Col>
                <Col sm='6'>
                  <DivFormGroup name='sucursalId' required title='Sucursal'>
                    <SelectSucursal name='sucursalId' value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
                  </DivFormGroup>
                </Col>
                <Col sm='5' xs='6'>
                  <DivFormGroup name='agenteName' required title='Agente'>
                    <SelectAgente value={agenteId} onChange={(value) => onSelect('agenteId', value)} />
                  </DivFormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='9'>
                  <DivFormGroup name='proveedorName' required title='Proveedor'>
                    <SelectProveedor value={proveedorObj} onChange={(value) => onSelect('proveedorId', value)} />
                  </DivFormGroup>
                </Col>
                <Col sm='3'>
                  <FormInput title='RFC' disabled name='proveedorRfc' onChange={onChange} value={proveedor ? proveedor.rfc : ''} />
                </Col>
              </Row>
              <Row>
                <Col sm='9'>
                  <FormInput title='Domicilio' disabled name='proveedorDomicilio' onChange={onChange} value={renderDomicilio(proveedor)} />
                </Col>
                <Col sm='3'>
                  <DivFormGroup name='usoCfdiId' required title='Uso de CFDI'>
                    <SelectUsoCFDI name='usoCfdiId' value={usoCfdiId} onChange={(value) => onSelect('usoCfdiId', value)} />
                  </DivFormGroup>
                </Col>
              </Row>
            </Modal.Body>
            <CbTableResponsive>
              <thead>
                <tr>
                  <th width='10%'>#</th>
                  <th>Descripción</th>
                  <th>Unidad</th>
                  <th>Cantidad</th>
                  <th>Costo</th>
                  <th>% Descuento</th>
                  <th>% IVA</th>
                  <th>Importe</th>
                  <th width='5%'>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                </tr>
              </thead>
              <CbTableBody colSpan={9}>
                {renderEquipos()}
                <CbTableTodosTotales colSpan={9} detalles={detalles} />
              </CbTableBody>
            </CbTableResponsive>
          </Fragment>
        ) : (
          <Loading />
        )}
        <Modal.Footer>
          <Saving saving={isLoading} />
          <button className='btn btn-primary pull-right' onClick={handleCompletarOrdenDeCompraConDetalles} disabled={isLoading}>
            Generar
          </button>
          <button onClick={close} disabled={isLoading} className='btn btn-default pull-right mr-5' type='button'>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

CompletarOrdenDeCompraConDetalles.propTypes = {
  solicitudId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
  detalles: PropTypes.array.isRequired,
};

export default CompletarOrdenDeCompraConDetalles;
