import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import { clean } from '@utils/formatter';
import InputNumber from '@components/forms/InputNumber';

const RowWorkforce = ({ workforce, onChangeWorkforce, onRemoveItemWorkforce }) => {
  const onChangeWorkforceInputMask = (name, e) => {
    const { value } = e.target;
    onChangeWorkforce({
      ...workforce,
      [name]: clean(value),
    });
  };

  const onRemoveItemWorkforceHandler = () => {
    onRemoveItemWorkforce();
  };

  return (
    <tr>
      <td>
        <DivFormGroup>
          <InputNumber placeholder='Nivel' className='form-control' value={workforce.level} />
        </DivFormGroup>
      </td>
      <td>
        <DivFormGroup>
          <InputNumber
            placeholder='Horas'
            className='form-control'
            onChange={(e) => onChangeWorkforceInputMask('value', e)}
            value={workforce.value}
          />
        </DivFormGroup>
      </td>
      <td>
        <button onClick={onRemoveItemWorkforceHandler} className='btn btn-danger pull-right' type='button'>
          <Icon icon='trash' />
        </button>
      </td>
    </tr>
  );
};

RowWorkforce.propTypes = {
  workforce: PropTypes.object,
  onChangeWorkforce: PropTypes.func,
  onRemoveItemWorkforce: PropTypes.func,
};

export default RowWorkforce;
