import React from 'react';
import PropTypes from 'prop-types';

const Panel = ({ type, header, classHeader, color, children }) => {
  const componentType = type ? type : 'default';
  const componentHeader = header ? header : '';

  return (
    <div className={`panel panel-${componentType}`} style={{ borderColor: color }}>
      {componentHeader && (
        <div className={`panel-heading`} style={{ borderColor: color, backgroundColor: color }}>
          <h3 className={`panel-title ${classHeader}`}>{componentHeader}</h3>
        </div>
      )}
      <div className={`panel-body`}>{children}</div>
    </div>
  );
};

Panel.propTypes = {
  type: PropTypes.string,
  header: PropTypes.node,
  classHeader: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
};


export default Panel;
