import { DivFormGroup, FormInput, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Icon, Saving } from '@stateless';
import { postRequest } from '@utils/api';
import { isEmpty } from 'validator';
import { socketManager } from '../../../../socketManager';
import { useStoreNotification } from '@stores/catalogs.store';

const Agregar = ({ usuarios }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [asunto, setAsunto] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [receptores, setReceptores] = useState([]);
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setAsunto('');
    setMensaje('');
    setReceptores([]);
  };

  const open = () => {
    setShowModal(true);
  };

  const handleAgregar = async (event) => {
    event.preventDefault();
    const data = {
      asunto,
      mensaje,
      receptores: receptores.map((x) => x.value),
    };
    if (validations(data)) {
      await postRequest({ url: `user/notificaciones`, body: data });
      for (let i = 0; i < data.receptores.length; i++) {
        const receptor = data.receptores[i];
        socketManager.emit('NOTIFICATION', { receptor, asunto, mensaje });
      }
      setShowModal(false);
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.asunto + '')) {
      validate.success = false;
      validate.message = 'Asunto es requerido.';
    } else if (isEmpty(params.mensaje + '')) {
      validate.success = false;
      validate.message = 'Mensaje es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'asunto') setAsunto(value);
    if (name === 'mensaje') setMensaje(value);
  };

  const onChangeMultiUsuario = (value) => {
    setReceptores(value);
  };

  return (
    <>
      <Button onClick={open} className='btn btn-success pull-right' type='button'>
        <Icon icon='plus' /> Nueva
      </Button>
      <Modal show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Nueva notificacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-sm-12'>
              <DivFormGroup>
                <Label name='receptores' title='Destinatarios' required />
                <Select
                  defaultValue={receptores}
                  isMulti
                  name='receptores'
                  placeholder='Destinatarios'
                  options={usuarios}
                  onChange={onChangeMultiUsuario}
                  className='basic-multi-select'
                  classNamePrefix='select'
                />
              </DivFormGroup>
            </div>
            <div className='col-sm-12'>
              <FormInput required title='Asunto' name='asunto' onChange={onChange} value={asunto} />
            </div>
            <div className='col-sm-12'>
              <FormInput required type='textarea' title='Mensaje' name='mensaje' onChange={onChange} value={mensaje} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Saving saving={isLoading} />
          <Button className='btn btn-primary pull-right mr-5' onClick={handleAgregar} disabled={isLoading}>
            Guardar
          </Button>
          <Button onClick={close} disabled={isLoading} className='btn btn-default pull-right mr-5' type='button'>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Agregar.propTypes = {
  id: PropTypes.number.isRequired,
  usuarios: PropTypes.array.isRequired,
};

export default Agregar;
