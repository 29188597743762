import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const RemoverClienteAgente = ({ agenteId, clienteId, nombre, loadData }) => {
  return (
    <Confirmation
      btnIcon='remove'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`agente-cliente/${agenteId}/${clienteId}`}
      description={`¿Confirma que desea eliminar el cliente <strong><u> ${nombre}</u> </strong>?`}
      loadData={loadData}
    />
  );
};

RemoverClienteAgente.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  agenteId: PropTypes.string.isRequired,
  clienteId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default RemoverClienteAgente;
