import Confirmation from '@controls/Confirmation';
import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbBadge from '@cbcomponents/CbBadge';
import Edit from './Edit';
import { ButtonGroup } from 'react-bootstrap';

const RowTable = ({ _id, codigo, nombre, sucursalId, en_equipos, en_servicios, en_monitoreos, loadData }) => {
  return (
    <tr>
      <td>{codigo}</td>
      <td>{nombre}</td>
      <td>{sucursalId?.name}</td>
      <td>
        {en_equipos && <CbBadge type='primary mr-5'>Equipos</CbBadge>}
        {en_servicios && <CbBadge type='primary mr-5'>Servicios</CbBadge>}
        {en_monitoreos && <CbBadge type='primary mr-5'>Monitoreos</CbBadge>}
      </td>
      <td>
        <ButtonGroup>
          <IfPermission action='editar'>
            <Edit
              id={_id}
              codigo={codigo}
              nombre={nombre}
              sucursalId={sucursalId}
              en_equipos={en_equipos}
              en_servicios={en_servicios}
              en_monitoreos={en_monitoreos}
              loadData={loadData}
              btnClass='btn btn-sm btn-outline-success'
            />
          </IfPermission>
          <IfPermission action='eliminar'>
            <Confirmation
              btnIcon='trash'
              typeRequest='deleteRequest'
              btnType='outline-danger btn-sm'
              action='Eliminar'
              url={`bancos/${_id}`}
              description={`¿Confirma que desea eliminar el banco <strong><u> ${nombre}</u> </strong>?`}
              loadData={loadData}
            />
          </IfPermission>
        </ButtonGroup>
      </td>
    </tr>
  );
};

RowTable.propTypes = {
  id: PropTypes.string.isRequired,
  codigo: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  sucursalId: PropTypes.object.isRequired,
  en_equipos: PropTypes.bool.isRequired,
  en_servicios: PropTypes.bool.isRequired,
  en_monitoreos: PropTypes.bool.isRequired,
  loadData: PropTypes.func.isRequired,
  _id: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
};

export default RowTable;
