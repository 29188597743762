import Joi from 'joi';
import { MESSAGES } from '@utils/validations';
const R_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const kitComercialSchemaUpdate = Joi.object({
  codigo: Joi.string().label('Código').required(),
  nombre: Joi.string().label('Nombre').required(),
  tipoCotizacionId: Joi.string().label('Linea').required().regex(R_MONGO_ID),
  clase: Joi.string().label('Clase').required(),
  tipo: Joi.string().label('Tipo').required(),
  lista: Joi.array().items(
    Joi.object({
      equipoId: Joi.string().label('Equipo').required().regex(R_MONGO_ID),
      cantidad: Joi.number().label('Cantidad').required(),
    })
  ),
  listaServicios: Joi.array().items(
    Joi.object({
      servicioId: Joi.string().label('Servicio').required().regex(R_MONGO_ID),
      measureId: Joi.string().label('Unidad de medida').required().regex(R_MONGO_ID),
      cantidad: Joi.number().label('Cantidad').required(),
    })
  ),
}).messages(MESSAGES);

export function validateUpdate(kitComercial) {
  return kitComercialSchemaUpdate.validate(kitComercial);
}
