import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import SelectBanco from '@components/forms/SelectBanco';
import { Input } from '@controls';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';

const RowReferencia = ({ referencia, onChangeReferencia, onRemoveReferencia }) => {
  const handleSelectReferencia = (field, selectedOption) => {
    onChangeReferencia({
      ...referencia,
      [field]: selectedOption,
    });
  };

  const handleChangeReferencia = (field, event) => {
    onChangeReferencia({
      ...referencia,
      [field]: event.target.value,
    });
  };

  return (
    <div className='list-group-item'>
      <Row>
        <Col sm='6'>
          <SelectBanco onChange={(value) => handleSelectReferencia('bancoId', value)} name='bancoId' value={referencia.bancoId} />
        </Col>
        <Col sm='5'>
          <Input name='referencia' value={referencia.referencia} onChange={(event) => handleChangeReferencia('referencia', event)} />
        </Col>
        <Col sm='1'>
          <Button onClick={onRemoveReferencia} className='btn btn-danger pull-right' type='button'>
            <Icon icon='trash' />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

RowReferencia.propTypes = {
  referencia: PropTypes.object,
  onChangeReferencia: PropTypes.func,
  onRemoveReferencia: PropTypes.func,
};

export default RowReferencia;
