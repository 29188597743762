import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableFooterBotton from '@cbcomponents/CbTableFooterBotton';
import PropTypes from 'prop-types';
import RowProveedor from './RowProveedor';
import Required from '@controls/Required';

const TableProveedores = ({ proveedores, onChangeProveedores }) => {
  const onChange = (idx, proveedor) => {
    proveedores[idx] = proveedor;
    onChangeProveedores([...proveedores]);
  };

  const onRemove = (idx) => {
    proveedores.splice(idx, 1);
    onChangeProveedores([...proveedores]);
  };

  const onAdd = () => {
    proveedores.push({
      proveedorId: null,
      codigo: '',
      codigo_barras: '',
      date_last_cost: null,
      ultimoCosto: 0,
    });
    onChangeProveedores([...proveedores]);
  };

  return (
    <CbPanelTableResponsive
      title={
        <>
          Proveedores <Required />
        </>
      }
    >
      <thead>
        <tr>
          <th>Proveedor</th>
          <th>Código</th>
          <th>Código barras</th>
          <th>Fecha último costo</th>
          <th>Último costo</th>
          <th></th>
        </tr>
      </thead>
      <CbTableBody colSpan={6}>
        {proveedores.map((proveedor, i) => (
          <RowProveedor key={i} proveedor={proveedor} onChangeProveedor={(prv) => onChange(i, prv)} onRemoveItemProveedor={() => onRemove(i)} />
        ))}
        <CbTableFooterBotton colSpan={6} title='Agregar proveedor' onClick={onAdd} />
      </CbTableBody>
    </CbPanelTableResponsive>
  );
};

TableProveedores.propTypes = {
  proveedores: PropTypes.array,
  onChangeProveedores: PropTypes.func,
};

export default TableProveedores;
