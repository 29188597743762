import { DivFormGroup } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { putRequest } from '@utils/api';
import { isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

function EditModuleOfSubModule({ idCatSubModule, loadCatalog, modules }) {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [module, setModule] = useState(null);
  const [isLoading] = useState(false);

  const close = () => setShowModal(false);
  const open = () => setShowModal(true);

  const handleEdit = async (event) => {
    event.preventDefault();
    const data = {
      moduleID: module._id,
    };

    if (validations(data)) {
      await putRequest({ url: `submodule/update-module/${idCatSubModule}`, body: data });
      loadCatalog();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.moduleID.toString())) {
      validate.success = false;
      validate.message = 'Modulo es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver title='' icon='transfer' onClick={open} hiddenXs type='success btn-sm' />
      <CbModal title='Agregar Módulo' show={showModal} onClose={close} onSave={handleEdit} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='module' title='Module'>
              <Select
                value={module}
                name='module'
                placeholder='Selecciona opciones'
                options={modules}
                onChange={(value) => setModule(value)}
                className='basic-multi-select'
                classNamePrefix='select'
                getOptionLabel={(x) => x.module}
                getOptionValue={(x) => x._id}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
}

EditModuleOfSubModule.propTypes = {
  idCatSubModule: PropTypes.string.isRequired,
  loadCatalog: PropTypes.func.isRequired,
  modules: PropTypes.array.isRequired,
};

export default EditModuleOfSubModule;
