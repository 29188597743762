import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Input, DivFormGroup } from '@controls';
import { Col, Row } from 'react-bootstrap';

const FormRuletaAsesores = (props) => {
  const {
    nombre,
    tipo,
    agentes,
    fecha,
    fecha_visita,
    medios_comunicaciones,
    soluciones_solicitadas,
    asesor_asignado,
    comparte_comision_con,
    soluciones_solicitadas_id,
    telefono,
    correo_electronico,
    asesor_atendio,
    medio_comunicacion,
    comentarios,
    producto_interes,
    giro,
    setState,
  } = props;

  const handleChange = (event) => {
    setState({ [event.target.name]: event.target.value });
  };

  const handleSelect = (name, value) => {
    setState({ [name]: value });
  };

  return (
    <Fragment>
      <Row>
        <Col sm='4'>
          <DivFormGroup name='tipo' title='Tipo cliente' required>
            <Select
              value={tipo}
              name='tipo'
              placeholder='Selecciona opciones'
              options={[
                { value: 'C', label: 'Cliente' },
                { value: 'P', label: 'Prospecto' },
              ]}
              onChange={(value) => handleSelect('tipo', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
        <Col sm='8'>
          <DivFormGroup name='nombre' title='Nombre' required>
            <Input name='nombre' onChange={handleChange} placeholder='Nombre' value={nombre} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='giro' title='Giro'>
            <Input name='giro' onChange={handleChange} placeholder='Nombre' value={giro} />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='medio_comunicacion' title='Medio comunicación'>
            <Select
              value={medio_comunicacion}
              name='medio_comunicacion'
              placeholder='Selecciona opciones'
              options={medios_comunicaciones}
              onChange={(value) => handleSelect('medio_comunicacion', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='4'>
          <DivFormGroup name='telefono' title='Teléfono'>
            <Input name='telefono' onChange={handleChange} placeholder='Teléfono' value={telefono} />
          </DivFormGroup>
        </Col>
        <Col sm='8'>
          <DivFormGroup name='correo_electronico' title='Correo electrónico'>
            <Input name='correo_electronico' onChange={handleChange} placeholder='Correo electrónico' value={correo_electronico} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='soluciones_solicitadas_id' title='Soluciones solicitadas' required>
            <Select
              value={soluciones_solicitadas_id}
              isMulti
              name='soluciones_solicitadas_id'
              placeholder='Selecciona soluciones solicitadas'
              options={soluciones_solicitadas}
              onChange={(value) => handleSelect('soluciones_solicitadas_id', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='producto_interes' title='Producto o servicio de interes'>
            <Input name='producto_interes' onChange={handleChange} placeholder='Nombre' value={producto_interes} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='asesor_asignado' title='Asesor asignado'>
            <Select
              value={asesor_asignado}
              name='asesor_asignado'
              placeholder='Selecciona asesores'
              options={agentes}
              onChange={(value) => handleSelect('asesor_asignado', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='comparte_comision_con' title='Comparte comisión con'>
            <Select
              value={comparte_comision_con}
              name='comparte_comision_con'
              placeholder='Selecciona asesores'
              options={agentes}
              onChange={(value) => handleSelect('comparte_comision_con', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='12'>
          <DivFormGroup name='asesor_atendio' title='Asesor atendió' required>
            <Select
              value={asesor_atendio}
              name='asesor_atendio'
              placeholder='Selecciona opciones'
              options={agentes}
              onChange={(value) => handleSelect('asesor_atendio', value)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <DivFormGroup name='fecha' title='Fecha' required>
            <Input type='date' name='fecha' onChange={handleChange} placeholder='Fecha' value={fecha} />
          </DivFormGroup>
        </Col>
        <Col sm='6'>
          <DivFormGroup name='fecha_visita' title='Fecha visita'>
            <Input type='date' name='fecha_visita' onChange={handleChange} placeholder='Fecha visita' value={fecha_visita} />
          </DivFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='12'>
          <DivFormGroup name='comentarios' title='Comentarios'>
            <Input name='comentarios' onChange={handleChange} placeholder='Comentarios' value={comentarios} />
          </DivFormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

FormRuletaAsesores.propTypes = {
  id: PropTypes.number,
  tipo: PropTypes.string,
  nombre: PropTypes.string,
  agentes: PropTypes.array,
  asesor_asignado: PropTypes.array,
  comparte_comision_con: PropTypes.array,
  soluciones_solicitadas_id: PropTypes.array,
  soluciones_solicitadas: PropTypes.array,
  medios_comunicaciones: PropTypes.array,
  tipos: PropTypes.array,
  telefono: PropTypes.string,
  fecha: PropTypes.string,
  comentarios: PropTypes.string,
  giro: PropTypes.string,
  producto_interes: PropTypes.string,
  fecha_visita: PropTypes.string,
  correo_electronico: PropTypes.string,
  asesor_atendio: PropTypes.string,
  medio_comunicacion: PropTypes.string,
  setState: PropTypes.func.isRequired,
};

export default FormRuletaAsesores;
