import { DivFormGroup, Input, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useStoreNotification } from '@stores/catalogs.store';

const EditProviders = ({ providerId, loadProviders, clasificaciones, usosCfdis }) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    showModal: false,
    nameProvider: '',
    nombre_comercial: '',
    rfc: '',
    code: '',
    phone: '',
    email: '',
    street: '',
    numberInt: '',
    numberExt: '',
    colony: '',
    city: '',
    state: '',
    postalCode: '',
    contactName: '',
    contactPhone: '',
    contactCellular: '',
    contactEmail: '',
    clasificacionProveedorId: null,
    usoCfdiId: null,
    isLoading: false,
  });

  const close = () => {
    setState({
      showModal: false,
      nameProvider: '',
      code: '',
      nombre_comercial: '',
      rfc: '',
      phone: '',
      email: '',
      street: '',
      numberInt: '',
      numberExt: '',
      colony: '',
      city: '',
      state: '',
      postalCode: '',
      contactName: '',
      contactPhone: '',
      contactCellular: '',
      contactEmail: '',
      clasificacionProveedorId: null,
      usoCfdiId: null,
      isLoading: false,
    });
  };

  const open = async () => {
    const {
      data: { name, code, rfc, phone, email, address, contact, clasificacionProveedorId, usoCfdiId, nombre_comercial },
    } = await getRequest({ url: `provider/${providerId}` });
    setState({
      showModal: true,
      nameProvider: name,
      code: code || '',
      rfc,
      phone,
      email,
      clasificacionProveedorId,
      usoCfdiId,
      street: address.street,
      nombre_comercial,
      numberInt: address.numberInt,
      numberExt: address.numberExt,
      colony: address.colony,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      contactName: contact.name,
      contactPhone: contact.phone,
      contactCellular: contact.cellular,
      contactEmail: contact.email,
    });
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const {
      nameProvider,
      rfc,
      phone,
      email,
      street,
      numberInt,
      numberExt,
      colony,
      city,
      state,
      postalCode,
      contactName,
      contactPhone,
      code,
      contactCellular,
      contactEmail,
      nombre_comercial,
      clasificacionProveedorId,
      usoCfdiId,
    } = state;

    const data = {
      nameProvider: nameProvider.toUpperCase().trim(),
      nombre_comercial: nombre_comercial.toUpperCase().trim(),
      code: code.toUpperCase().trim(),
      rfc: rfc.toUpperCase().trim(),
      phone: phone.toUpperCase().trim(),
      email: email.trim(),
      street: street.toUpperCase().trim(),
      numberExt: numberExt.toUpperCase().trim(),
      numberInt: numberInt.toUpperCase().trim(),
      colony: colony.toUpperCase().trim(),
      city: city.toUpperCase().trim(),
      state: state.toUpperCase().trim(),
      postalCode: postalCode.toUpperCase().trim(),
      contactName: contactName.toUpperCase().trim(),
      contactPhone: contactPhone.toUpperCase().trim(),
      contactCellular: contactCellular.toUpperCase().trim(),
      contactEmail: contactEmail.trim(),
      clasificacionProveedorId: clasificacionProveedorId?._id,
      usoCfdiId: usoCfdiId?._id,
    };

    if (validations(data)) {
      await putRequest({ url: `provider/${providerId}`, body: data });
      loadProviders();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    if (isEmpty(params.code + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.nameProvider + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.clasificacionProveedorId + '')) {
      validate.success = false;
      validate.message = 'Clasificación es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelect = (campo, value) => {
    setState((prevState) => ({ ...prevState, [campo]: value }));
  };

  const {
    showModal,
    nameProvider,
    rfc,
    phone,
    email,
    clasificacionProveedorId,
    street,
    numberInt,
    numberExt,
    colony,
    city,
    state: estado,
    postalCode,
    code,
    contactName,
    contactPhone,
    contactCellular,
    contactEmail,
    usoCfdiId,
    isLoading,
    nombre_comercial,
  } = state;

  return (
    <>
      <CbButtonOver icon='pencil' title='Editar' onClick={open} hiddenXs type='primary' />
      <CbModal show={showModal} onClose={close} title='Agregar rubro' isLoading={isLoading} onSave={handleEdit}>
        <Row>
          <Col>
            <FormInput name='code' title='Código' required onChange={handleChange} value={code} />
          </Col>
          <Col>
            <FormInput name='nameProvider' title='Nombre' required onChange={handleChange} value={nameProvider} />
          </Col>
          <Col>
            <FormInput name='rfc' title='RFC' onChange={handleChange} value={rfc} maxLength={13} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput name='nombre_comercial' title='Nombre comercial' onChange={handleChange} value={nombre_comercial} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='clasificacionProveedorId' title='Clasificación' required>
              <Select
                value={clasificacionProveedorId}
                placeholder='Selecciona una opcion'
                options={clasificaciones}
                getOptionLabel={(option) => option.nombre}
                getOptionValue={(option) => option._id}
                onChange={(value) => handleSelect('clasificacionProveedorId', value)}
              />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='usoCfdiId' title='Uso de CFDI'>
              <Select
                value={usoCfdiId}
                placeholder='Selecciona una opcion'
                options={usosCfdis}
                getOptionLabel={(option) => option.descripcion}
                getOptionValue={(option) => option._id}
                onChange={(value) => handleSelect('usoCfdiId', value)}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput name='phone' title='Teléfono' onChange={handleChange} value={phone} />
          </Col>
          <Col>
            <FormInput name='email' title='Correo electrónico' onChange={handleChange} value={email} />
          </Col>
        </Row>
        <Row>
          <Col>
            <legend>
              <Icon icon='home' /> Domicilio
            </legend>
          </Col>
          <Col>
            <FormInput name='street' title='Calle' onChange={handleChange} value={street} />
          </Col>
          <Col>
            <DivFormGroup name='numberExt' title='Número'>
              <br />
              <Row>
                <Col>
                  <Input name='numberExt' onChange={handleChange} value={numberExt} placeholder='Exterior' />
                </Col>
                <Col>
                  <Input name='numberInt' onChange={handleChange} value={numberInt} placeholder='Interior' />
                </Col>
              </Row>
            </DivFormGroup>
          </Col>
          <Col>
            <FormInput name='colony' title='Colonia' onChange={handleChange} value={colony} />
          </Col>
          <Col>
            <FormInput name='postalCode' title='Código postal' onChange={handleChange} value={postalCode} />
          </Col>
          <Col>
            <FormInput name='city' title='Municipio' onChange={handleChange} value={city} />
          </Col>
          <Col>
            <FormInput name='state' title='Estado' onChange={handleChange} value={estado} />
          </Col>
        </Row>
        <Row>
          <Col>
            <legend>
              <Icon icon='user' /> Contacto
            </legend>
          </Col>
          <Col>
            <FormInput name='contactName' title='Nombre' onChange={handleChange} value={contactName} />
          </Col>
          <Col>
            <FormInput name='contactPhone' title='Teléfono' onChange={handleChange} value={contactPhone} />
          </Col>
          <Col>
            <FormInput name='contactCellular' title='Celular' onChange={handleChange} value={contactCellular} />
          </Col>
          <Col>
            <FormInput name='contactEmail' title='Correo electrónico' onChange={handleChange} value={contactEmail} />
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditProviders.propTypes = {
  providerId: PropTypes.string.isRequired,
  loadProviders: PropTypes.func.isRequired,

  clasificaciones: PropTypes.array.isRequired,
  usosCfdis: PropTypes.array.isRequired,
};

export default EditProviders;
