import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ButtonGroup, DropdownButton, DropdownItem } from 'react-bootstrap';
import { Icon } from '@stateless';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from '../Delete';
import Editar from '../Editar';
import Viabilidad from '../Viabilidad';
import ConvertProspectosClientes from '../convert/index';
import IconDropdown from '@controls/IconDropdown';

const List = ({ list, loadList }) => {
  const [estatusProcesoVentasLabel] = useState([
    { type: 'secondary', value: 'POR_CALIFICAR', label: 'POR CALIFICAR' },
    { type: 'success', value: 'CONTACTADO', label: 'CONTACTADO' },
    { type: 'primary', value: 'PROPUESTA', label: 'PROPUESTA' },
    { type: 'warning', value: 'NEGOCIACION', label: 'NEGOCIACION' },
  ]);

  const [viabilidadesLabel] = useState([
    { type: 'secondary', value: 'POR_CALIFICAR', label: 'POR CALIFICAR' },
    { type: 'warning', value: 'BAJA', label: 'BAJA' },
    { type: 'primary', value: 'MEDIA', label: 'MEDIA' },
    { type: 'success', value: 'ALTA', label: 'ALTA' },
  ]);

  const renderDomicilio = (address) => {
    if (address && Object.getOwnPropertyNames(address).length > 0) {
      const { calle, exterior, interior, colonia, coloniaId, codigo_postal, municipio, estado, estadoId, pais } = address;

      let domicilio = `${calle} ${exterior}`;
      let coloniaCP = '';
      let ciudad = '';

      if (interior !== '') {
        domicilio += `, ${interior}`;
      }

      if (colonia !== '' && coloniaId !== 0) {
        coloniaCP += colonia;
      }

      if (codigo_postal) {
        coloniaCP += `, C.P. ${codigo_postal}`;
      }

      if (municipio !== '') {
        ciudad += `${municipio}`;
      }

      if (estado !== '' && estadoId !== 0) {
        ciudad += `, ${estado}`;
      }

      return (
        <small>
          <p>
            {domicilio}
            {colonia !== '' ? <br /> : null}
            {coloniaCP}
            {municipio !== '' ? <br /> : null}
            {ciudad} {pais}
          </p>
        </small>
      );
    } else {
      return <div></div>;
    }
  };

  const renderRows = () => {
    return list.map((row, key) => {
      const { name, paterno, materno, phone, mobile, email, domicilio, _id, estatus_proceso_venta, viabilidad, observaciones } = row;
      const estatus_proceso_label =
        estatusProcesoVentasLabel.find((x) => x.value === estatus_proceso_venta) ||
        estatusProcesoVentasLabel.find((x) => x.value === 'POR_CALIFICAR');
      const viabilidad_label = viabilidadesLabel.find((x) => x.value === viabilidad) || viabilidadesLabel.find((x) => x.value === 'POR_CALIFICAR');
      return (
        <tr key={key}>
          <td>
            <span style={{ display: 'inline-block' }}>
              <b style={{ display: 'block' }}>{row.razon_social}</b>
              <small style={{ display: 'block' }}>{row.nombre_comercial}</small>
            </span>
          </td>
          <td>
            <small>
              <u>{mobile}</u>
            </small>{' '}
            <small>
              <u>{phone}</u>
            </small>
            <br />
            <small>
              <u>{email}</u>
            </small>
          </td>
          <td>{typeof domicilio !== 'undefined' ? renderDomicilio(domicilio) : ''}</td>
          <td>{estatus_proceso_label && <CbBadge type={estatus_proceso_label.type}>{estatus_proceso_label.label}</CbBadge>}</td>
          <td>{viabilidad_label && <CbBadge type={viabilidad_label.type}>{viabilidad_label.label}</CbBadge>}</td>
          <td>{observaciones}</td>
          <td>
            <ButtonGroup>
              <Editar id={_id} {...row} loadData={loadList} />
              <Delete id={_id} {...row} btnClass='btn btn-outline-danger' refresh={loadList} name={`${name} ${paterno} ${materno}`} />
              <DropdownButton as={ButtonGroup} title={<IconDropdown />} variant='outline-primary'>
                <DropdownItem href={`/prospectos/detalle/${row._id}`}>
                  <Icon icon='zoom-in' /> Detalle
                </DropdownItem>
                <Viabilidad
                  id={_id}
                  viabilidad={viabilidad}
                  refresh={loadList}
                  observaciones={observaciones}
                  name={`${name} ${paterno} ${materno}`}
                />
                <DropdownItem href={`/cotizaciones/nuevo/prospecto/${row._id}`}>
                  <Icon icon='file' /> Cotizar
                </DropdownItem>
                <ConvertProspectosClientes id={_id} refresh={loadList} prospectoId={_id} />
              </DropdownButton>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Nombre</th>
          <th width='20%'>Contacto</th>
          <th width='30%'>Domicilio</th>
          <th width='10%'>Estatus</th>
          <th width='10%'>Viabilidad</th>
          <th>Observaciones</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={7}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  loadList: PropTypes.func.isRequired,
};

export default List;
