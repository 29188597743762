import { MONEDA } from '@config/constants';
import { FormInput } from '@controls';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, postRequest, URL_FILES } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import AgregarDetalle from './AgregarDetalle';
import ModalImprimir from './components/ModalImprimir';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableResponsive from '@cbcomponents/CbTableResponsive';
import RowDetalle from './components/RowDetalle';
import { validateUpdate } from '@validations/pedidos';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const New = () => {
  const { addNotification: notification } = useStoreNotification();

  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fechaEntrega: moment().format('YYYY-MM-DD'),
    ubicaciones: [],
    monedaId: MONEDA.DOLAR,
    sucursales: [],
    almacenes: [],
    tipos_pedidos: [],
    monedas: [],
    measures: [],
    providers: [],
    storehouses: [],
    proveedores: [],
    proveedorObj: null,
    proveedorName: '',
    detalles: [],
    usos_cfdi: [],
    usoCfdiId: null,
    subtotal: 0,
    total: 0,
    impuestos: 0,
    descuento: 0,
    observaciones: '',
    estatus: 'INICIADO',
    sucursalId: null,
    almacenId: null,
    sucursalDestinoId: null,
    almacenDestinoId: null,
    proveedor: null,
    productoName: '',
    productoObj: null,
    productos: [],
    tipoCambio: 0,
    agenteSolicitanteObj: null,
    agenteSolicitanteName: '',
    clienteObj: null,
    cliente: null,
    clienteName: '',
    referencia: '',
    domicilios: [],
    domicilioId: null,
    showModalDownload: false,
    idPedido: null,
    numeroCotizacion: 0,
  });

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const onClickDownloadPdf = (id) => {
    setState((prevState) => ({
      ...prevState,
      showModalDownload: true,
      idPedido: id,
    }));
  };

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModalDownload: false,
    }));
    setTimeout(() => navigate('/pedidos'), 1000);
  };

  const agregarOtra = () => {
    setState((prevState) => ({
      ...prevState,
      showModalDownload: false,
    }));
    setTimeout(() => navigate(0), 1000);
  };

  const onAceptar = async (event) => {
    event.preventDefault();
    const { idPedido } = state;
    const { data } = await getRequest({ url: `pedido/pdf/${idPedido}` });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
      close();
    }
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Advertencia',
        message: error.details[0].message,
        type: 'warning',
      });
      return false;
    }
    return true;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      numeroCotizacion,
      agenteSolicitanteId,
      monedaId,
      tipoCambio,
      almacenId,
      cliente,
      domicilioId,
      referencia,
      numeroOrden,
      fecha,
      estatus,
      sucursalId,
      observaciones,
      detalles,
      tipoPedidoId,
    } = state;

    const data = {
      tipoPedidoId,
      numero_pedido: numeroOrden,
      numero_cotizacion: numeroCotizacion,
      agenteSolicitanteId,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      monedaId,
      almacenId,
      clienteId: cliente ? cliente._id : '',
      domicilioId: domicilioId ? domicilioId._id : '',
      referencia,
      tipo_cambio: tipoCambio,
      estatus_pedido: estatus,
      sucursalId,
      observaciones,
      detalles: detalles.map((x) => ({
        existenciaId: x.existenciaId._id,
        ubicacionId: x.existenciaId.ubicacionId._id,
        ...x,
      })),
    };

    if (validations(data)) {
      const response = await postRequest({ url: `pedidos`, body: data });
      onClickDownloadPdf(response.data._id);
    }
  };

  const onChangeDetalle = (index, event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => {
        if (i === index) {
          x[name] = ['cantidad'].includes(name) ? cleanNumber(value) : value;
        }
        return { ...x };
      }),
    }));
  };

  const handleDupliciateRow = (index, detalle) => {
    const newDetalles = [...state.detalles];
    newDetalles.splice(index + 1, 0, {
      ...detalle,
      existenciaId: null,
      _id: undefined,
    });
    setState((prevState) => ({
      ...prevState,
      detalles: newDetalles,
    }));
  };

  const handleDeleteRow = (index) => {
    const newDetalles = [...state.detalles];
    newDetalles.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      detalles: newDetalles,
    }));
  };

  const onAgregarDetalle = (existencias) => {
    const { detalles } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [
        ...detalles,
        ...existencias.map((itemSelect) => {
          const producto = itemSelect.equipmentId;
          let measure = null,
            measureId = null;
          measure =
            producto.equipmentsMeasureId && producto.equipmentsMeasureId.measure
              ? producto.equipmentsMeasureId.measure
              : producto.equipmentsMeasureId;
          measureId =
            producto.equipmentsMeasureId && producto.equipmentsMeasureId._id ? producto.equipmentsMeasureId._id : producto.equipmentsMeasureId;
          return {
            ...producto,
            equipmentId: producto._id,
            descripcion: producto.name.toUpperCase(),
            noIdentificador: producto.code,
            measure,
            measureId,
            ubicacionOrigen: itemSelect.ubicacionId.nombre,
            ubicacionOrigenId: itemSelect.ubicacionId._id,
            existenciaId: itemSelect,
            existencia: itemSelect.stock,
            entregado: 0,
            cantidad: 1,
            listaExistencias: itemSelect.listaExistencias,
          };
        }),
      ],
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const fecha = moment().format('YYYY-MM-DD');
      const { data } = await getRequest({ url: `tipo-cambio/fecha`, params: { fecha } });
      if (!data?._id) {
        return notification({
          title: 'Advertencia',
          message: 'Se debe capturar el tipo de cambio del dia. Catálogos -> Tipos de cambio',
          type: 'warning',
        });
      }
      setState((prevState) => ({
        ...prevState,
        tipoCambio: data.valor || 0,
      }));
      inicializar();
    };

    const inicializar = async () => {
      const {
        data: { sucursales, almacenes, ubicaciones, agente, monedas, tipos_pedidos },
      } = await getRequest({ url: `pedidos-catalogos` });
      setState((prevState) => ({
        ...prevState,
        tipos_pedidos: tipos_pedidos.map((tipo_pedido) => ({
          value: tipo_pedido._id,
          label: `${tipo_pedido.nombre}`,
        })),
        listAlmacenes: almacenes,
        sucursales: sucursales.map((sucursal) => ({
          value: sucursal._id,
          label: `${sucursal.name}`,
        })),
        almacenes,
        monedas: monedas.map((moneda) => ({
          value: moneda.enumerador,
          label: `${moneda.descripcion}`,
        })),
        agenteSolicitanteObj: { value: agente._id, label: agente.nombre },
        agenteSolicitanteId: agente._id,
        maximo_descuento: agente.maximo_descuento,
        sucursalId: agente.sucursalId,
        almacenId: agente ? agente.almacenId : null,
        agenteName: agente.nombre,
        ubicaciones,
        isLoadingForm: false,
      }));
    };

    fetchData();
  }, []);

  const renderView = () => {
    const { isLoading, observaciones, almacenId, detalles } = state;
    return (
      <div>
        <div className='panel panel-default'>
          <div className='panel-footer'>
            <Row>
              <Col xs='12'>
                <AgregarDetalle onAgregarDetalle={onAgregarDetalle} almacenId={almacenId} />
              </Col>
            </Row>
          </div>
        </div>
        <div className='panel panel-default panel-table'>
          <CbTableResponsive>
            <thead>
              <tr>
                <th width='10%'>#</th>
                <th>Descripción</th>
                <th width='10%'>Unidad</th>
                <th>Cantidad</th>
                <th>Ubicación / Serie</th>
                <th>Entregado</th>
                <th>Existencia</th>
                <th>Apartado</th>
                <th>Por Recibir</th>
                <th width='5%'>&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <CbTableBody colSpan={12}>
              {detalles.map((detalle, i) => (
                <RowDetalle
                  key={i}
                  detalle={detalle}
                  onChangeDetalle={onChangeDetalle.bind(null, i)}
                  handleDupliciate={handleDupliciateRow.bind(null, i)}
                  handleDeleteRow={handleDeleteRow.bind(null, i)}
                />
              ))}
            </CbTableBody>
          </CbTableResponsive>
          <div className='panel-body'>
            <Row>
              <Col xs='12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </div>
          <div className='panel-footer'>
            <Row>
              <Col xs='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='btn btn-primary pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const { isLoadingForm, showModalDownload } = state;
  return (
    <Module onClickBack={window.history.back} parent='Pedidos' title='Nuevo pedido'>
      {!isLoadingForm ? renderView() : <Loading />}
      <ModalImprimir showModalDownload={showModalDownload} close={close} agregarOtra={agregarOtra} onAceptar={onAceptar} />
    </Module>
  );
};

New.propTypes = {};

export default New;
