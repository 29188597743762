import React from 'react';
import { Icon } from '@stateless';
import PropTypes from 'prop-types';
import ColClasificacionZona from './ColClasificacionZona';

const RowZona = ({ i, j, zona, activeClasificacion, onChangeZona, onClickQuitarZona }) => {
  const handleChangeAreaZona = (i, j, event) => {
    console.log(i, j, event.target.value);
    onChangeZona(i, j, event.target.value);
  };

  const handleChangeAreaZonaSubclasificacion = (i, j, k, event) => {
    console.log(i, j, k, event.target.value);
    onChangeZona(i, j, k, event.target.value);
  };

  const subclasificaciones =
    (activeClasificacion &&
      zona.clasificaciones.find((x) => activeClasificacion && x.clasificacionId === activeClasificacion._id)?.subclasificaciones) ||
    [];

  return (
    <tr key={j}>
      <td width='10%' style={{ padding: '0px' }}></td>
      <td width='20%' style={{ padding: '0px' }}>
        <input
          className='form-control'
          style={{ padding: '.2em' }}
          placeholder='Sección'
          name='nombre'
          value={zona.nombre}
          onChange={(e) => handleChangeAreaZona(i, j, e)}
        />
      </td>
      {subclasificaciones.map((sb, k) => (
        <ColClasificacionZona sb={sb} key={k} onChangeClasificacionZona={(value) => handleChangeAreaZonaSubclasificacion(k, value)} />
      ))}
      <td width='3%' style={{ padding: '0px' }}>
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault();
            onClickQuitarZona(i, j);
          }}
        >
          <Icon icon='remove' style={{ color: 'red' }} />
        </a>
      </td>
    </tr>
  );
};

RowZona.propTypes = {
  i: PropTypes.number.isRequired,
  j: PropTypes.number.isRequired,
  zona: PropTypes.object.isRequired,
  activeClasificacion: PropTypes.object,
  handleChangeAreaZona: PropTypes.func.isRequired,
  handleChangeAreaZonaSubclasificacion: PropTypes.func.isRequired,
  onClickQuitarZona: PropTypes.func.isRequired,
  onChangeZona: PropTypes.func.isRequired,
};

export default RowZona;
