import { MONEDA } from '@config/constants';
import { FormInput } from '@controls';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Icon, Loading, Module, Saving } from '@stateless';
import { getRequest, putRequest, URL_FILES } from '@utils/api';
import { textDomicilio } from '@utils/cotizaciones';
import GenerarSolicitudCompra from './GenerarSolicitudCompra';
import ModalImprimir from '../components/ModalImprimir';
import RowDetalle from '../components/RowDetalle';
import CbTableBody from '@cbcomponents/CbTableBody';
import CbTableResponsive from '@cbcomponents/CbTableResponsive';
import { validateUpdate } from '@validations/pedidos';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const params = useParams();
  const [state, setState] = useState({
    reset: false,
    tipoPedidoId: null,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fechaEntrega: moment().format('YYYY-MM-DD'),
    ubicaciones: [],
    monedaId: MONEDA.DOLAR,
    sucursales: [],
    almacenes: [],
    monedas: [],
    measures: [],
    providers: [],
    storehouses: [],
    proveedores: [],
    proveedorObj: null,
    proveedorName: '',
    detalles: [],
    usos_cfdi: [],
    usoCfdiId: null,
    subtotal: 0,
    total: 0,
    impuestos: 0,
    descuento: 0,
    observaciones: '',
    estatus: 'INICIADO',
    sucursalId: null,
    almacenId: null,
    sucursalDestinoId: null,
    almacenDestinoId: null,
    proveedor: null,
    productoName: '',
    productoObj: null,
    productos: [],
    tipoCambio: 0,
    agenteSolicitanteObj: null,
    agenteSolicitanteId: null,
    agenteSolicitanteName: '',
    clienteObj: null,
    cliente: null,
    clienteName: '',
    referencia: '',
    domicilios: [],
    domicilioId: null,
    showModalDownload: false,
    idPedido: null,
    numeroCotizacion: 0,
  });

  const { pedidoId } = params;

  const onChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'principal' || name === 'caducidad' ? checked : value,
    }));
  };

  const handleRegister = async (event) => {
    event && event.preventDefault();
    const {
      pedidoId,
      numeroCotizacion,
      agenteSolicitanteId,
      monedaId,
      tipoCambio,
      almacenId,
      cliente,
      domicilioId,
      referencia,
      numeroOrden,
      fecha,
      estatus,
      sucursalId,
      observaciones,
      detalles,
      tipoPedidoId,
    } = state;

    const data = {
      tipoPedidoId,
      numero_pedido: numeroOrden,
      numero_cotizacion: numeroCotizacion,
      agenteSolicitanteId,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      monedaId,
      almacenId,
      clienteId: cliente ? cliente._id : '',
      domicilioId,
      referencia,
      tipo_cambio: tipoCambio,
      estatus_pedido: estatus,
      sucursalId,
      observaciones,
      detalles: detalles.map((x) => ({
        existenciaId: x.existenciaId._id,
        ubicacionId: x.existenciaId.ubicacionId._id,
        ...x,
      })),
    };

    if (validations(data)) {
      await putRequest({ url: `pedido/${pedidoId}`, body: data });
      onClickDownloadPdf(pedidoId);
    }
  };

  const onChangeDetalle = (index, detalle) => {
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => (i === index ? { ...x, ...detalle } : x)),
    }));
  };

  const handleDupliciateRow = (index, detalle) => {
    const newDetalles = [...state.detalles];
    newDetalles.splice(index + 1, 0, {
      ...detalle,
      existenciaId: null,
      _id: undefined,
    });
    setState((prevState) => ({
      ...prevState,
      detalles: newDetalles,
    }));
  };

  const handleDeleteRow = (index) => {
    const newDetalles = [...state.detalles];
    newDetalles.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      detalles: newDetalles,
    }));
  };

  const onClickDownloadPdf = (id) => {
    setState((prevState) => ({
      ...prevState,
      showModalDownload: true,
      idPedido: id,
    }));
  };

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModalDownload: false,
    }));
    setTimeout(() => navigate('/pedidos'), 1000);
  };

  const agregarOtra = () => {
    setState((prevState) => ({
      ...prevState,
      showModalDownload: false,
    }));
    setTimeout(() => navigate(0), 1000);
  };

  const onAceptar = async (event) => {
    event.preventDefault();
    const { idPedido } = state;
    const { data } = await getRequest({ url: `pedido/pdf/${idPedido}` });
    if (data) {
      window.open(`${URL_FILES}/${data.url}`, '_blank');
      close();
    }
  };

  const validations = (params) => {
    const { error } = validateUpdate(params);
    if (error) {
      notification({
        title: 'Información incompleta',
        message: error.details[0].message,
        type: 'error',
      });
      return false;
    }
    return true;
  };

  const ObtenerPedido = useCallback(async (pedidoId, callback = () => {}) => {
    const {
      data: { encabezado, detalle },
    } = await getRequest({ url: `pedido/${pedidoId}` });
    try {
      setState((prevState) => ({
        ...prevState,
        ...encabezado,
        seEntrego: detalle.filter((x) => x.entregado).length > 0,
        pedidoId,
        tipoPedidoId: encabezado.tipoPedidoId ? encabezado.tipoPedidoId._id : null,
        agenteSolicitanteObj: { value: encabezado.agente_solicitante._id, label: encabezado.agente_solicitante.nombre },
        agenteSolicitanteId: encabezado.agente_solicitante._id,
        agenteSolicitanteName: encabezado.agente_solicitante.nombre,
        monedaId: encabezado.moneda,
        sucursalId: encabezado.sucursal._id,
        almacenId: encabezado.almacen._id,
        numeroOrden: encabezado.numero_pedido,
        numeroCotizacion: encabezado.numero_cotizacion,
        fecha: moment(encabezado.fecha).local().format('YYYY-MM-DD'),
        clienteName: encabezado.customer.razon_social,
        cliente: encabezado.customer,
        clienteObj: {
          label: encabezado.customer.razon_social,
          value: encabezado.customer._id,
          ...encabezado.customer,
        },
        domicilioId: encabezado.customer.domicilio._id,
        estatus: encabezado.estatus_pedido,
        domicilios: [
          {
            value: encabezado.customer.domicilio._id || 1,
            label: textDomicilio(encabezado.customer.domicilio),
          },
        ],
        detalles: detalle.map((det) => {
          let measure = null,
            measureId = null;
          measure = det.unidadMedidaId.measure;
          measureId = det.unidadMedidaId._id;
          return {
            ...det,
            entregado_anterior: det.entregado,
            equipment: det.equipmentId,
            equipmentId: det.equipmentId._id,
            measure,
            measureId,
            existencia: det.existencia || 0,
            moneda: encabezado.moneda,
            noIdentificador: det.codigo,
            precio_original: det.precio,
            impuesto: det.iva,
            importe: det.importe,
            observaciones: det.observaciones,
          };
        }),
      }));
      callback();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const ObtenerTipoCambio = useCallback(async (fecha, callback = () => {}) => {
    const { data } = await getRequest({ url: `tipo-cambio/fecha`, params: { fecha } });
    if (!data?._id) {
      return notification({
        title: 'Advertencia',
        message: 'Se debe capturar el tipo de cambio del dia. Catálogos -> Tipos de cambio',
        type: 'warning',
      });
    }
    setState((prevState) => ({
      ...prevState,
      tipoCambio: data.valor || 0,
    }));
    callback();
  }, []);

  const Inicializar = useCallback(async () => {
    const {
      data: { sucursales, almacenes, ubicaciones, monedas, tipos_pedidos },
    } = await getRequest({ url: `pedidos-catalogos` });
    setState((prevState) => ({
      ...prevState,
      tipos_pedidos: tipos_pedidos,
      sucursales: sucursales,
      listAlmacenes: almacenes,
      almacenes,
      monedas: monedas,
      ubicaciones,
      isLoadingForm: false,
    }));
  }, []);

  useEffect(() => {
    const fecha = moment().format('YYYY-MM-DD');
    ObtenerPedido(pedidoId, () => {
      ObtenerTipoCambio(fecha, Inicializar);
    });
  }, [ObtenerPedido, ObtenerTipoCambio, Inicializar, pedidoId]);

  const renderView = () => {
    const { isLoading, observaciones, detalles } = state;
    return (
      <div>
        <div className='panel panel-default panel-table'>
          <CbTableResponsive>
            <thead>
              <tr>
                <th width='10%'>#</th>
                <th>Descripción</th>
                <th width='10%'>Unidad</th>
                <th>Cantidad</th>
                <th>Ubicación / Serie</th>
                <th>Entregado</th>
                <th>Existencia</th>
                <th>Apartado</th>
                <th>Por Recibir</th>
                <th width='5%'>&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <CbTableBody colSpan={12}>
              {detalles.map((detalle, i) => (
                <RowDetalle
                  key={i}
                  detalle={detalle}
                  onChangeDetalle={onChangeDetalle.bind(this, i)}
                  handleDupliciate={handleDupliciateRow.bind(this, i)}
                  handleDeleteRow={handleDeleteRow.bind(this, i)}
                />
              ))}
            </CbTableBody>
          </CbTableResponsive>
          <div className='panel-body'>
            <Row>
              <Col xs='12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </Col>
            </Row>
          </div>
          <div className='panel-footer'>
            <Row>
              <Col xs='12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='btn btn-primary pull-right  mr-5' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
                <GenerarSolicitudCompra pedidoId={params.pedidoId} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const { isLoadingForm, showModalDownload } = state;
  return (
    <Module onClickBack={window.history.back} parent='Pedidos' title='Editar pedido'>
      {!isLoadingForm ? renderView() : <Loading />}
      <ModalImprimir showModalDownload={showModalDownload} close={close} agregarOtra={agregarOtra} onAceptar={onAceptar} />
    </Module>
  );
};

Edit.propTypes = {};

export default Edit;
