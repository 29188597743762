import { IfPermission, RedirectTooltip } from '@controls';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { Icon, Loading, Module } from '@stateless';
import { postRequest, URL_FILES } from '@utils/api';
import { CbPagination } from '@controls';
import TableKits from './TableKits';

const KitsComerciales = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');

  useEffect(() => {
    handlePagination(1);
  }, []);

  const handleSearch = async (params = {}) => {
    setLoading(true);
    setActivePage(params.origin === 'SEARCH' ? 1 : activePage);
    const page = activePage * pagination - pagination;
    const body = { search: params.search };
    const { data, count } = await postRequest({ url: `kits-comerciales/search`, params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setSearch(params.search);
    setLoading(false);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    const page = eventKey * pagination - pagination;
    handleSearch({ page, pagination, search });
  };

  const handleExcelEncabezado = async () => {
    const { file } = await postRequest({ url: `kits-comerciales/exportar` });
    window.open(URL_FILES + 'excel/' + file, '_blank');
  };

  return (
    <Module title='Kits comerciales'>
      <Search onSearch={handleSearch} blockOne='col-sm-6' blockSecond='col-sm-6'>
        <IfPermission action='crear'>
          <RedirectTooltip
            id={1}
            labelTooltip='Nuevo'
            url='/kits-comerciales/nuevo'
            icon='plus'
            text='Nuevo'
            className='btn btn-primary pull-right mr-5'
          />
        </IfPermission>
        <IfPermission action='importar'>
          <RedirectTooltip
            id={1}
            labelTooltip='Importar'
            url='kits-comerciales/importar'
            icon='import'
            text='Importar'
            className='btn btn-primary pull-right mr-5'
            hiddenXs
          />
        </IfPermission>
        <button onClick={handleExcelEncabezado} className='btn btn-success pull-right mr-5' type='button'>
          <Icon icon='save-file' /> Exportar
        </button>
      </Search>
      <Loading loading={loading}>
        <TableKits
          list={list}
          loading={loading}
          activePage={activePage}
          count={count}
          pagination={pagination}
          search={search}
          loadData={handleSearch}
        />
        <div className='text-center'>
          <CbPagination activePage={activePage} items={count} itemsPerPage={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

KitsComerciales.propTypes = {};

export default KitsComerciales;
