import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@stateless';
import { Button } from 'react-bootstrap';
import SelectProveedor from '@components/forms/SelectProveedor';
import InputCurrency from '@components/forms/InputCurrency';

const RowProveedor = ({ proveedor, onChangeProveedor, onRemoveItemProveedor }) => {
  const handleChangeProveedor = (itemSelect) => {
    onChangeProveedor({
      ...proveedor,
      proveedorId: itemSelect,
    });
  };

  const handleChangeProveedorInput = (key, e) => {
    const { value } = e.target;
    onChangeProveedor({
      ...proveedor,
      [key]: value,
    });
  };

  const handleRemoveItemProveedor = () => {
    onRemoveItemProveedor();
  };

  return (
    <tr>
      <td>
        <DivFormGroup>
          <SelectProveedor name='proveedorId' value={proveedor.proveedorId} onChange={handleChangeProveedor} />
        </DivFormGroup>
      </td>
      <td>
        <DivFormGroup>
          <FormInput withoutLabel name='codigo' title='Código' value={proveedor.codigo} onChange={(e) => handleChangeProveedorInput('codigo', e)} />
        </DivFormGroup>
      </td>
      <td>
        <DivFormGroup>
          <FormInput
            withoutLabel
            name='codigo_barras'
            title='Código barras'
            value={proveedor.codigo_barras}
            onChange={(e) => handleChangeProveedorInput('codigo_barras', e)}
          />
        </DivFormGroup>
      </td>
      <td>
        <FormInput
          withoutLabel
          name='date_last_cost'
          title='Fecha de último costo'
          type='date'
          value={proveedor.date_last_cost}
          onChange={(e) => handleChangeProveedorInput('date_last_cost', e)}
        />
      </td>
      <td>
        <DivFormGroup>
          <InputCurrency name='ultimoCosto' value={proveedor.ultimoCosto} onChange={(e) => handleChangeProveedorInput('ultimoCosto', e)} />
        </DivFormGroup>
      </td>
      <td>
        <Button onClick={handleRemoveItemProveedor} className='btn btn-danger pull-right' type='button'>
          <Icon icon='trash' />
        </Button>
      </td>
    </tr>
  );
};

RowProveedor.propTypes = {
  proveedor: PropTypes.object,
  onChangeProveedor: PropTypes.func,
  onRemoveItemProveedor: PropTypes.func,
};

export default RowProveedor;
