import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { clean } from '@utils/formatter';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const New = ({ controles, piezas, fabricantes, loadData }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [serie, setSerie] = useState('');
  const [nombre, setNombre] = useState('');
  const [controlMantenimientoId, setControlMantenimientoId] = useState('');
  const [tipoPiezaId, setTipoPiezaId] = useState('');
  const [fabricanteId, setFabricanteId] = useState('');
  const [minimo, setMinimo] = useState(0);
  const [maximo, setMaximo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setSerie('');
    setNombre('');
    setControlMantenimientoId('');
    setTipoPiezaId('');
    setFabricanteId('');
    setMinimo(0);
    setMaximo(0);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const data = {
      serie: serie.trim(),
      nombre: nombre.trim(),
      minimo: clean(minimo),
      maximo: clean(maximo),
      controlMantenimientoId,
      tipoPiezaId,
      fabricanteId,
    };

    if (validations(data)) {
      await postRequest({ url: `piezas`, body: data });
      loadData();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.serie + '')) {
      validate.success = false;
      validate.message = 'Nº Parte es requerido.';
    } else if (validate.success && isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre de la Pieza es requerido.';
    } else if (validate.success && isEmpty(params.controlMantenimientoId + '')) {
      validate.success = false;
      validate.message = 'Control de Mantenimiento es requerido.';
    } else if (validate.success && isEmpty(params.tipoPiezaId + '')) {
      validate.success = false;
      validate.message = 'Tipo es requerido.';
    } else if (validate.success && isEmpty(params.fabricanteId + '')) {
      validate.success = false;
      validate.message = 'Fabricante es requerido.';
    } else if (validate.success && !isNaN(params.minimo) && params.minimo <= 0) {
      validate.success = false;
      validate.message = 'Stock Mínimo es requerido.';
    } else if (validate.success && !isNaN(params.maximo) && params.maximo <= 0) {
      validate.success = false;
      validate.message = 'Stock Máximo es requerido.';
    } else if (validate.success && (params.minimo === params.maximo || params.minimo >= params.maximo)) {
      validate.success = false;
      validate.message = 'Favor de verificar los stock.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='serie' title='Nº Parte'>
              <Input name='serie' onChange={(e) => setSerie(e.target.value)} value={serie} />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='nombre' title='Nombre'>
              <Input name='nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='controlMantenimientoId' title='Control de Mantenimiento'>
              <select
                id='controlMantenimientoId'
                name='controlMantenimientoId'
                className='form-control'
                onChange={(e) => setControlMantenimientoId(e.target.value)}
                value={controlMantenimientoId}
              >
                <option value=''>Seleccione</option>
                {controles.map((c, i) => (
                  <option key={i} value={c._id}>
                    {c.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='tipoPiezaId' title='Tipo'>
              <select
                id='tipoPiezaId'
                name='tipoPiezaId'
                className='form-control'
                onChange={(e) => setTipoPiezaId(e.target.value)}
                value={tipoPiezaId}
              >
                <option value=''>Seleccione</option>
                {piezas.map((c, i) => (
                  <option key={i} value={c._id}>
                    {c.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='fabricanteId' title='Fabricante'>
              <select
                id='fabricanteId'
                name='fabricanteId'
                className='form-control'
                onChange={(e) => setFabricanteId(e.target.value)}
                value={fabricanteId}
              >
                <option value=''>Seleccione</option>
                {fabricantes.map((c, i) => (
                  <option key={i} value={c._id}>
                    {c.nombre}
                  </option>
                ))}
              </select>
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <legend>Stock</legend>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='minimo' title='Mínimo'>
              <InputNumber name='minimo' value={minimo} onChange={(e) => setMinimo(e.target.value)} />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='maximo' title='Máximo'>
              <InputNumber name='maximo' value={maximo} onChange={(e) => setMaximo(e.target.value)} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  controles: PropTypes.array.isRequired,
  piezas: PropTypes.array.isRequired,
  fabricantes: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default New;
