import { FormGruopIcon } from '@controls';
import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect } from 'react';
import { ButtonGroup, Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Icon, Loading, Module } from '@stateless';
import { postRequest, URL_FILES } from '@utils/api';
import { CbPagination } from '@controls';
import List from './components/List';
import New from './components/New';
import IconDropdown from '@controls/IconDropdown';
import usePagination from '@hooks/usePagination';
import SelectDealer from '@components/forms/SelectDealer';
import SelectTipoMonitoreo from '@components/forms/SelectTipoMonitoreo';
import { fetchAll } from '@services/cuentasMonitoreos.service';

const CuentasMonitoreos = () => {
  const [pagination, setPagination] = useState(100);
  const [search, setSearch] = useState('');
  const [estado, setEstado] = useState({ _id: '0', nombre: 'Todos' });
  const [dealerId, setDealerId] = useState(null);
  const [tipoMonitoreoId, setTipoMonitoreoId] = useState(null);

  const estados = [
    { _id: '0', nombre: 'Todos' },
    { _id: '1', nombre: 'Sin cliente' },
    { _id: '2', nombre: 'Sin domicilio' },
    { _id: '3', nombre: 'Sin contrato' },
    { _id: '4', nombre: 'Sin tipo' },
    { _id: '5', nombre: 'Sin agenda' },
    { _id: '11', nombre: 'Sin lista de precios' },
    { _id: '6', nombre: 'Con cliente' },
    { _id: '7', nombre: 'Con domicilio' },
    { _id: '8', nombre: 'Con contrato' },
    { _id: '9', nombre: 'Con tipo' },
    { _id: '10', nombre: 'Con agenda' },
    { _id: '12', nombre: 'Con lista de precios' },
  ];

  const { datos, paginaActual, count, cargando, cambiarPagina, setParams, refresh } = usePagination(fetchAll, pagination, 'numero');
  useEffect(() => {
    setParams({
      search,
      estado: estado?._id,
      dealerId: dealerId?._id,
      tipoMonitoreoId: tipoMonitoreoId?._id,
    });
  }, [search, estado, dealerId, tipoMonitoreoId]);

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
  };

  const onClickExportar = async () => {
    const { file } = await postRequest({ url: `cuentas-monitoreos/exportar` });
    window.open(URL_FILES + 'excel/' + file, '_blank');
  };

  const onClickActualizarTotales = async () => {
    await postRequest({ url: `cuentas-monitoreos/actualizar-totales` });
  };

  const moduleList = !cargando ? <List {...{ data: datos, loading: cargando, dealerId, estado }} loadData={refresh} /> : <Loading />;

  return (
    <Module title='Cuentas de Monitoreos'>
      <Search onSearch={onSearch} searchOnStopType blockOne='col-sm-12' blockSecond='col-sm-12'>
        <Row>
          <Col sm='12'>
            <Row>
              <Col md='3'>
                <FormGruopIcon title='Estado' icon='tag'>
                  <Select
                    value={estado}
                    isClearable
                    placeholder='Seleccione'
                    options={estados}
                    getOptionLabel={(x) => x.nombre}
                    getOptionValue={(x) => x._id}
                    onChange={(value) => setEstado(value)}
                  />
                </FormGruopIcon>
              </Col>
              <Col md='3'>
                <FormGruopIcon title='Dealer' icon='home'>
                  <SelectDealer onChange={(value) => setDealerId(value)} value={dealerId} />
                </FormGruopIcon>
              </Col>
              <Col md='3'>
                <FormGruopIcon title='Tipo monitoreo' icon='tag'>
                  <SelectTipoMonitoreo onChange={(value) => setTipoMonitoreoId(value)} value={tipoMonitoreoId} />
                </FormGruopIcon>
              </Col>
              <Col md='3'>
                <ButtonGroup className='pull-right'>
                  <IfPermission action='crear'>
                    <New loadData={refresh} />
                  </IfPermission>
                  <DropdownButton title={<IconDropdown />} as={ButtonGroup} variant='outline-primary'>
                    <DropdownItem href={`/cuentas-monitoreos/importar`}>
                      <Icon icon='zoom-in' /> Importar
                    </DropdownItem>
                    <DropdownItem onClick={onClickExportar}>
                      <Icon icon='export' /> Exportar
                    </DropdownItem>
                    <DropdownItem onClick={onClickActualizarTotales}>
                      <Icon icon='export' /> Actualizar totales
                    </DropdownItem>
                  </DropdownButton>
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Search>
      <Row>
        <Col sm='12'>
          {moduleList}
          <div className='text-center'>
            <DropdownButton title={pagination}>
              <DropdownItem onClick={() => setPagination(10)}>10</DropdownItem>
              <DropdownItem onClick={() => setPagination(50)}>50</DropdownItem>
              <DropdownItem onClick={() => setPagination(100)}>100</DropdownItem>
              <DropdownItem onClick={() => setPagination(1000)}>1000</DropdownItem>
              <DropdownItem onClick={() => setPagination(count)}>{count}</DropdownItem>
            </DropdownButton>
            <CbPagination activePage={paginaActual} count={count} pagination={pagination} onSelect={cambiarPagina} />
          </div>
        </Col>
      </Row>
    </Module>
  );
};

CuentasMonitoreos.propTypes = {};

export default CuentasMonitoreos;
