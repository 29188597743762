import { IfPermission, RedirectTooltip } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Delete from './Delete';
import Detail from './Detail';

const Table = ({ list }) => {
  const renderRows = () => {
    return list.map((k, i) => {
      const { _id, codigo, nombre, entradas, salidas } = k;
      return (
        <tr key={i}>
          <td>{codigo}</td>
          <td>{nombre}</td>
          <td>{entradas && entradas.length > 0 && <Detail id={i} {...k} lista={entradas} title='materiales' btnClass='btn btn-sm btn-success' />}</td>
          <td>{salidas && salidas.length > 0 && <Detail id={i} {...k} lista={salidas} title='salidas' btnClass='btn btn-sm btn-success' />}</td>
          <td className='with-actions'>
            <span className='actions'>
              <IfPermission action='eliminar'>
                <Delete id={_id} name={`${codigo} - ${nombre}`} btnClass='btn btn-sm btn-danger mr-5' />
              </IfPermission>
              <IfPermission action='editar'>
                <RedirectTooltip id={1} icon='pencil' url={`/recetas/edicion/${_id}`} labelTooltip='Editar' className='btn btn-sm btn-info mr-5' />
              </IfPermission>
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='10%'>Código</th>
          <th width='25%'>Descripción</th>
          <th>Materiales</th>
          <th>Salidas</th>
        </tr>
      </thead>
      <CbTableBody colSpan={4}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
};

export default Table;
