import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteAnticipos from './DeleteAnticipos';
import EditAnticipos from './EditAnticipos';

const ListAnticipos = ({ catalogAnticipos, loadAnticipos }) => {
  const renderRows = () => {
    return catalogAnticipos.map((row, item) => {
      const { _id, anticipo, tipo } = row;
      return (
        <tr key={item}>
          <td>
            {anticipo}
            {tipo === 'PORCENTAJE' ? '%' : null}
          </td>
          <td>
            <IfPermission action='eliminar'>
              <DeleteAnticipos id={item} anticiposId={_id} anticipo={anticipo} tipo={tipo} {...{ catalogAnticipos, loadAnticipos }} />
            </IfPermission>
            <IfPermission action='editar'>
              <EditAnticipos id={item} anticiposId={_id} anticipo={anticipo} {...{ catalogAnticipos, loadAnticipos }} />
            </IfPermission>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='80%'>Anticipo</th>
          <th width='20%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={2}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListAnticipos.propTypes = {
  catalogAnticipos: PropTypes.array.isRequired,
  loadAnticipos: PropTypes.func.isRequired,
};

export default ListAnticipos;
