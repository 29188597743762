import React from 'react';
import moment from 'moment';
import { format } from '@utils/parseCost';
import { ESTATUS_COTIZACIONES } from '@config/constants';
import PropTypes from 'prop-types';

const RowCotizacionesProximasAVencer = ({ cotizacion }) => (
  <tr>
    <td>{cotizacion.numero_levantamiento}</td>
    <th>{moment(cotizacion.fecha).local().format('YYYY-MM-DD')}</th>
    <th>{moment(cotizacion.fecha_vencimiento).local().format('YYYY-MM-DD')}</th>
    <td>{cotizacion.customer && cotizacion.customer.razon_social}</td>
    <td>
      <span className='label' style={{ background: ESTATUS_COTIZACIONES.find((x) => x.estatus == cotizacion.estado)?.color || '#fff' }}>
        {cotizacion.estado}
      </span>
    </td>
    <td>{format(cotizacion.importe)}</td>
  </tr>
);

RowCotizacionesProximasAVencer.propTypes = {
  cotizacion: PropTypes.object.isRequired,
};

export default RowCotizacionesProximasAVencer;
