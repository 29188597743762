import { RowItem } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const SellerSelected = ({ sellerSelected: { name, fatherLastName, motherLastName } }) => {
  return (
    <div>
      <RowItem label=''>
        {name} {fatherLastName} {motherLastName}
      </RowItem>

      <div className='col-sm-6'>
        <span>
          <strong>Teléfono</strong>{' '}
        </span>
        <br />
        <span>
          <strong>Correo electrónico</strong>{' '}
        </span>
      </div>
      <div className='col-sm-6'>
        <span>
          <strong>Celular</strong>
        </span>
      </div>
    </div>
  );
};

SellerSelected.propTypes = {
  sellerSelected: PropTypes.object.isRequired,
};

export default SellerSelected;
