import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './List';
import { Col, Row } from 'react-bootstrap';

const Contactos = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(200);
  const [search, setSearch] = useState('');

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count } = await postRequest({ url: 'customers-saldos/search', params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const onSearch = (params) => {
    setSearch(params.search);
    setActivePage(1);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  const renderList = () => (
    <div>
      <List {...{ list, loadContactos: handleSearch }} />
    </div>
  );

  const moduleList = !loading ? renderList() : <Loading />;

  return (
    <Module title='Clientes saldos'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8' />
      <Row>
        <Col>
          {moduleList}
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </Col>
      </Row>
    </Module>
  );
};

Contactos.propTypes = {};

export default Contactos;
