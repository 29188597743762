import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import React, { useEffect, useState, Fragment } from 'react';
import { Icon, Module, RowsNotFound, Saving } from '@stateless';
import { getRequest, putRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Required from '@controls/Required';
import RowDetalle from './components/RowDetalle';
import { Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectTipoCompra from '@components/forms/SelectTipoCompra';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import Select from 'react-select';

const EditarRecepcion = () => {
  const { addNotification: notification } = useStoreNotification();

  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoadingForm: true,
    isLoading: true,
    fecha: moment().format('YYYY-MM-DD'),
    fecha_entrega: moment().format('YYYY-MM-DD'),
    estatus: 'INICIADO',
    tipos_compras: [],
    tipoCompraId: null,
    detalles: [],
    sucursales: [],
    almacenes: [],
    ubicaciones: [],
    agente: null,
    agenteObj: null,
    agenteName: null,
    agenteId: null,
    sucursalId: null,
    almacenId: null,
    estatus_recepciones: [
      { value: 'INICIADO', label: 'INICIADO' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'FINALIZADO', label: 'FINALIZADO' },
    ],
    observaciones: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const { recepcionCompraId } = params;
      const { data } = await getRequest({ url: `recepcionescompras/${recepcionCompraId}` });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isLoadingForm: false,
        fecha: moment(data.fecha).format('YYYY-MM-DD'),
        fecha_entrega: moment(data.fecha_entrega).format('YYYY-MM-DD'),
        numero_compra: data.numero_compra,
        numero_recepcion: data.numero_recepcion_compra,
        estatus: data.estatus,
        tipoCompraId: data.tipoCompraId._id,
        compraId: data.compraId,
        sucursalId: data.sucursal._id,
        almacenId: data.almacen._id,
        agenteId: data.agente._id,
        agenteName: data.agente.nombre,
        agente: data.agente._id,
        observaciones: data.observaciones,
        agenteObj: { value: data.agente._id, label: data.agente.nombre },
        detalles: data.detalles.map((x) => {
          let measure = null,
            measureId = null,
            measures = [];
          measure = x.unidadMedida;
          measureId = x.unidadMedidaId;
          return {
            ...x,
            measure,
            measureId,
            measures,
            ubicacion: x.ubicacionId,
            ubicacionId: x.ubicacionId._id,
            tipo_partida: x.tipo_partida,
            noIdentificador: x.codigo,
            moneda_original: x.moneda,
            precio_original: x.last_cost,
            impuesto: x.iva,
          };
        }),
      }));
    };

    fetchData();
  }, [params]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const {
      compraId,
      tipoCompraId,
      numero_compra,
      numero_recepcion,
      estatus,
      fecha,
      fecha_entrega,
      agenteId,
      almacenId,
      sucursalId,
      detalles,
      observaciones,
    } = state;

    const data = {
      compraId,
      tipoCompraId: tipoCompraId,
      numero_compra,
      numero_recepcion: numero_recepcion,
      estatus: estatus,
      fecha: moment(fecha, 'YYYY-MM-DD').utc(),
      fecha_entrega: moment(fecha_entrega, 'YYYY-MM-DD').utc(),
      agenteId,
      almacenId,
      sucursalId,
      observaciones,
      detalles: detalles.map((x) => ({
        _id: x._id,
        compraDetalleId: x.compraDetalleId._id,
        equipmentId: x.equipmentId._id,
        unidadMedidaId: x.unidadMedidaId._id,
        codigo: x.codigo,
        descripcion: x.descripcion,
        ubicacionId: x.ubicacionId,
        numero_serial: x.numero_serial,
        pedimento: x.pedimento,
        observaciones: '',
        cantidad: x.cantidad,
        cantidad_comprada: x.cantidad,
      })),
    };

    if (validations(data)) {
      const { recepcionCompraId } = params;
      await putRequest({ url: `recepcionescompras/${recepcionCompraId}`, body: data });
      setTimeout(() => navigate('/recepciones-compras'), 1000);
    }
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value,
    }));

    if (campo === 'sucursalId') {
      setState((prevState) => ({
        ...prevState,
        almacenId: null,
        detalles: prevState.detalles.map((x) => ({
          ...x,
          ubicacionId: null,
          ubicacion: null,
        })),
      }));
    }
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        agente: itemSelect,
        agenteId: itemSelect._id,
        agenteObj: itemSelect,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        agenteName: '',
        agenteId: null,
        agente: null,
        agenteObj: null,
      }));
    }
  };

  const onChangeDetalle = (name, index, event) => {
    if (['cantidad', 'descuento', 'impuesto', 'precio'].includes(name)) {
      setState((prevState) => ({
        ...prevState,
        detalles: prevState.detalles.map((x, i) => {
          if (i === index) x[name] = cleanNumber(event.target.value);
          return { ...x };
        }),
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => {
        if (i === index) {
          x[name] = event.target.value;
        }
        return { ...x };
      }),
    }));
  };

  const onClickRemoveDetalle = (index) => {
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.filter((x, i) => i !== index),
    }));
  };

  const renderDetalles = () => {
    if (state.detalles.length === 0) return <RowsNotFound message='No se han agregado detalles.' colSpan={12} />;
    return state.detalles.map((detalle, i) => (
      <RowDetalle key={i} detalle={detalle} index={i} onChangeDetalle={onChangeDetalle} onClickRemoveDetalle={onClickRemoveDetalle} />
    ));
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    const now = new Date();

    if (!isMongoId(params.tipoCompraId + '')) {
      validate.success = false;
      validate.message = 'Tipo compra es requerido.';
    } else if (!moment(params.fecha).isValid()) {
      validate.success = false;
      validate.message = 'Fecha es requerida.';
    } else if (validate.success && moment(now).isSameOrBefore(params.fecha)) {
      validate.success = false;
      validate.message = 'Fecha no puede ser mayor al día de hoy.';
    } else if (validate.success && moment(params.fecha_entrega).isBefore(params.fecha)) {
      validate.success = false;
      validate.message = 'Fecha de entrega no puede ser menor a fecha.';
    } else if (!isMongoId(params.sucursalId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerido.';
    } else if (!isMongoId(params.almacenId + '')) {
      validate.success = false;
      validate.message = 'Almacen es requerido.';
    } else if (!isMongoId(params.agenteId + '')) {
      validate.success = false;
      validate.message = 'Agente es requerido.';
    } else if (isEmpty(params.estatus + '')) {
      validate.success = false;
      validate.message = 'Estatus es requerido.';
    } else {
      params.detalles.forEach((detalle) => {
        if (isEmpty(detalle.cantidad + '') || detalle.cantidad <= 0) {
          validate.success = false;
          validate.message = 'Cantidad es requerida.';
        } else if (detalle.cantidad > detalle.cantidad_comprada) {
          validate.success = false;
          validate.message = 'Cantidad no puede ser mayor a cantidad comprada.';
        } else if (!isMongoId(detalle.ubicacionId + '')) {
          validate.success = false;
          validate.message = 'Ubicación es requerida.';
        }
      });
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const {
    isLoading,
    tipoCompraId,
    numero_compra,
    numero_recepcion,
    fecha,
    fecha_entrega,
    observaciones,
    sucursalId,
    almacenId,
    agenteObj,
    estatus,
    estatus_recepciones,
  } = state;

  return (
    <Fragment>
      <Module onClickBack={window.history.back} title='Editar recepción de compra' loading={isLoading}>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-4 col-xs-6'>
                <DivFormGroup name='tipoCompraId' required title='Tipo compra'>
                  <SelectTipoCompra value={tipoCompraId} onChange={(value) => onSelect('tipoCompraId', value)} name='tipoCompraId' />
                </DivFormGroup>
              </div>
              <div className='col-sm-2 col-xs-6'>
                <FormInput
                  title='Num. de compra'
                  disabled
                  placeholder='Num. de compra'
                  name='numero_compra'
                  onChange={onChange}
                  value={numero_compra}
                />
              </div>
              <div className='col-sm-2 col-xs-6'>
                <FormInput
                  title='Num. de orden'
                  disabled
                  placeholder='Num. de orden'
                  name='numero_recepcion'
                  onChange={onChange}
                  value={numero_recepcion}
                />
              </div>
              <div className='col-sm-2 col-xs-6'>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </div>
              <div className='col-sm-2 col-xs-6'>
                <FormInput title='Fecha de entrega' type='date' name='fecha_entrega' onChange={onChange} value={fecha_entrega} />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup name='sucursalId' required title='Sucursal'>
                  <SelectSucursal value={sucursalId} onChange={(value) => onSelect('sucursalId', value)} />
                </DivFormGroup>
              </div>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup name='almacenId' required title='Almacen'>
                  <SelectAlmacen
                    value={almacenId}
                    onChange={(value) => onSelect('almacenId', value)}
                    filter={(x) => x.sucursalId === sucursalId?._id}
                  />
                </DivFormGroup>
              </div>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup name='agenteName' required title='Agente'>
                  <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                </DivFormGroup>
              </div>
              <div className='col-sm-3 col-xs-6'>
                <DivFormGroup name='estatus' required title='Estatus'>
                  <Select name='estatus' value={estatus} options={estatus_recepciones} onChange={(value) => onSelect('estatus', value)} />
                </DivFormGroup>
              </div>
            </div>
          </div>
        </div>
        <CbPanelTableResponsive>
          <thead>
            <tr>
              <th width='10%'>#</th>
              <th>Descripción</th>
              <th width='10%'>Unidad</th>
              <th>
                Cantidad <Required />
              </th>
              <th>Cant. Comprada</th>
              <th>Cant. Recibida</th>
              <th>
                Ubicación <Required />
              </th>
              <th># Serial</th>
              <th>Pedimento</th>
              <th width='5%'>&nbsp;&nbsp;&nbsp;&nbsp;</th>
            </tr>
          </thead>
          <CbTableBody colSpan={10}>{renderDetalles()}</CbTableBody>
        </CbPanelTableResponsive>
        <div className='panel panel-default panel-table'>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </div>
            </div>
          </div>
        </div>
        <div className='panel panel-default'>
          <div className='panel-footer'>
            <div className='row'>
              <div className='col-sm-12'>
                <Saving saving={isLoading} />
                <Button type='button' onClick={handleRegister} className='btn btn-success pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Module>
    </Fragment>
  );
};

EditarRecepcion.propTypes = {};

export default EditarRecepcion;
