import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteTipoEstablecimientos = ({ tipoestablecimientoId, name, loadTipoEstablecimientos }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Eliminar'
      url={`tipoestablecimientos/${tipoestablecimientoId}`}
      description={`¿Confirma que desea eliminar el tipo de establecimiento <strong><u> ${name}</u> </strong>?`}
      loadData={loadTipoEstablecimientos}
    />
  );
};

DeleteTipoEstablecimientos.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  tipoestablecimientoId: PropTypes.string.isRequired,
  loadTipoEstablecimientos: PropTypes.func.isRequired,
};

export default DeleteTipoEstablecimientos;
