import ConfiguracionCorreo from '@components/application/users/ConfiguracionCorreo';
import ResetPassword from '@components/application/users/ResetPassword';
import { Confirmation, If } from '@controls/index';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, DropdownButton, DropdownItem } from 'react-bootstrap';
import { Icon } from '@stateless';
import { urlPhoto } from '@utils/api';
import { isURL } from 'validator';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import DeleteUser from './DeleteUser';
import EditUser from './EditUser';
import IconDropdown from '@controls/IconDropdown';

const ListUsers = ({ users, loadUsers }) => {
  const getSrcImg = (avatar) => {
    if (isURL(avatar + '')) return avatar;
    if (avatar) return urlPhoto() + avatar;
    return 'https://img2.freepng.es/20180612/ih/kisspng-computer-icons-avatar-user-profile-clip-art-5b1f69f0e68650.4078880515287853929442.jpg';
  };

  // const handleImageChange = async (idUser, event) => {
  //   event.preventDefault();
  //   const file = event.target.files[0];
  //   const form = new FormData();
  //   form.append('image', file);
  //   await postUploadRequest({ url: `users/image/${idUser}`, form });
  //   loadUsers();
  // };

  const renderRows = () => {
    return users.map((user, item) => (
      <tr key={item}>
        <td>
          <img src={getSrcImg(user.avatar)} alt='' className='img-circle img-size-32 mr-2' style={{ display: 'inline-block', float: 'left' }}></img>
          <span style={{ display: 'inline-block' }}>
            <b style={{ display: 'block' }}>{user.name}</b>
            <small style={{ display: 'block' }}>{user.email}</small>
          </span>
        </td>
        <td>{user.cellPhone}</td>
        <td>{user.agenteId && user.agenteId.nombre}</td>
        <td>
          <CbBadge type={user.status === 'ACTIVO' ? 'primary' : 'secondary'}>{user.status}</CbBadge>
        </td>
        <td>
          <ButtonGroup>
            <EditUser id={item} idUser={user._id} {...{ users, loadUsers }} />
            <ResetPassword id={item} idUser={user._id} {...{ users, loadUsers }} />
            <DropdownButton title={<IconDropdown />} as={ButtonGroup} variant='outline-dark'>
              <DeleteUser id={item} idUser={user._id} {...{ users, loadUsers }} nombre={user.name} />
              <ConfiguracionCorreo id={item} idUser={user._id} configuration_email={user.configuration_email} {...{ users, loadUsers }} />
              <If condition={user.status === 'ACTIVO'}>
                <If.Then>
                  <Confirmation
                    id={user._id}
                    btnIcon='arrow-down'
                    typeConfirm='danger'
                    btnType='danger'
                    action='Inactivar'
                    isMenu
                    typeRequest='postRequest'
                    url={`users/inactivar/${user._id}`}
                    description={`¿Confirma que desea inactivar el <strong>usuario: <u>${user.name}</u></strong> ?`}
                    loadData={loadUsers}
                  />
                </If.Then>
                <If.Else>
                  <Confirmation
                    id={user._id}
                    btnIcon='arrow-up'
                    typeConfirm='primary'
                    btnType='primary'
                    action='Activar'
                    isMenu
                    typeRequest='postRequest'
                    url={`users/activar/${user._id}`}
                    description={`¿Confirma que desea activar el <strong>usuario: <u>${user.name}</u></strong>?`}
                    loadData={loadUsers}
                  />
                </If.Else>
              </If>
              <DropdownItem href={`/usuarios/actividad/${user._id}`}>
                <Icon icon='folder-open' /> Actividades del usuario
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.preventDefault();
                  this['image' + user._id].click();
                }}
              >
                <Icon icon='picture' /> Cambiar imagen
              </DropdownItem>
            </DropdownButton>
          </ButtonGroup>
        </td>
      </tr>
    ));
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th>Usuario</th>
          <th>Celular</th>
          <th>Agente</th>
          <th>Estatus</th>
        </tr>
      </thead>
      <CbTableBody colSpan={5}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListUsers.propTypes = {
  users: PropTypes.array.isRequired,
  loadUsers: PropTypes.func.isRequired,
};

export default ListUsers;
