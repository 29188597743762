import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import Select from 'react-select';
import { useStoreNotification } from '@stores/catalogs.store';

const New = ({ load, estados, paises }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [clave, setClave] = useState('');
  const [nombre, setNombre] = useState('');
  const [estadoId, setEstadoId] = useState(null);
  const [paisId, setPaisId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setClave('');
    setNombre('');
    setEstadoId(null);
    setPaisId(null);
    setIsLoading(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      clave: clave.trim(),
      nombre: nombre.trim(),
      estadoId: estadoId?._id,
    };

    if (validations(data)) {
      await postRequest({ url: `municipios`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (!isMongoId(params.estadoId + '')) {
      validate.success = false;
      validate.message = 'Estado es requerido.';
    } else if (isEmpty(params.clave + '')) {
      validate.success = false;
      validate.message = 'Clave es requerida.';
    } else if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' size='lg' onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='paisId' title='País' required>
              <Select
                name='paisId'
                onChange={(value) => setPaisId(value)}
                value={paisId}
                options={paises}
                placeholder='País'
                getOptionLabel={(option) => option.pais}
                getOptionValue={(option) => option._id}
              />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='estadoId' title='Estado' required>
              <Select
                name='estadoId'
                onChange={(value) => setEstadoId(value)}
                value={estadoId}
                options={estados.filter((x) => x.paisId === paisId?._id)}
                placeholder='Estado'
                getOptionLabel={(option) => option.estado}
                getOptionValue={(option) => option._id}
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivFormGroup name='clave' title='Clave ' required>
              <Input name='clave' onChange={(e) => setClave(e.target.value)} value={clave} placeholder='Clave' />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} placeholder='Nombre' />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

New.propTypes = {
  load: PropTypes.func.isRequired,

  estados: PropTypes.array.isRequired,
  paises: PropTypes.array.isRequired,
};

export default New;
