import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@stateless';
import moment from 'moment';
import { getRequest } from '@utils/api';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';

const Actividades = ({ id }) => {
  const [showModal, setShowModal] = useState(false);
  const [actividadesAgentePorDia, setActividadesAgentePorDia] = useState([]);

  const close = () => {
    setShowModal(false);
  };

  const open = async () => {
    const { data } = await getRequest({ url: `customer/actividades_seguimientos/${id}` });
    const actividadesAgentePorDia = Object.values(
      data.reduce((a, f) => {
        const fecha = moment(f.created).format('YYYY-MM-DD');
        if (!a[fecha]) {
          a[fecha] = {
            fecha,
            actividades: [f],
          };
        } else {
          a[fecha].actividades.push(f);
        }
        return a;
      }, {})
    );
    setActividadesAgentePorDia(actividadesAgentePorDia);
    setShowModal(true);
  };

  return (
    <>
      <CbButtonOver title='Actividades' icon='time' onClick={open} type='warning' />
      <CbModal title='Busqueda de cliente y aplicación de deposito' show={showModal} onClose={close} onSave={() => {}}>
        <div className='timeline'>
          {actividadesAgentePorDia.map((x) => (
            <React.Fragment key={x.fecha}>
              <div className='time-label'>
                <span className='bg-green'>{x.fecha}</span>
              </div>
              {x.actividades.map((y) => (
                <div key={y._id}>
                  <Icon icon='time bg-blue fas' />
                  <div className='timeline-item'>
                    <span className='time'>
                      <Icon icon='time' /> {moment(y.created).local().format('HH:mm')}
                    </span>
                    <h3 className='timeline-header'>
                      <a href='#'>{y.userId ? y.userId.name : y.agenteId.nombre}</a> {y.titulo}
                    </h3>
                    <div className='timeline-body'>{y.descripcion}</div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))}
          <div>
            <Icon icon='time bg-blue fas' />
          </div>
        </div>
      </CbModal>
    </>
  );
};

Actividades.propTypes = {
  id: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Actividades;
