import React, { Suspense } from 'react';
import { RoutesApp } from './app/routes';
import NotificationComponent from '@components/NotificationsComponent';
import { initializeNotifications } from '@stores/catalogs.store';
import { Loading } from '@stateless';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '@components/ErrorBoundary';

const App = () => {
  initializeNotifications();
  return (
    <>
      <ErrorBoundary>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>{RoutesApp}</Suspense>
        </BrowserRouter>
        <NotificationComponent />
      </ErrorBoundary>
    </>
  );
};

export default App;
