import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from '@utils/parseCost';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';

const List = ({ list }) => {
  const renderRows = () => {
    return list.map((row, item) => (
      <tr key={item}>
        <td>
          <strong>{row._id.numero_cliente}</strong> <br />
          <span>{row._id.razon_social}</span> <br />
          <b>{row.rfc}</b>
        </td>
        <td>{format(row.cargo, 2)}</td>
        <td>{format(row.abono, 2)}</td>
        <td>{format(row.diferencia, 2)}</td>
        <td>{row.numero_cargo}</td>
        <td>{row.numero_abono}</td>
        <td>{moment(row.min_fecha).local().format('YYYY-MM-DD')}</td>
        <td>{moment(row.max_fecha).local().format('YYYY-MM-DD')}</td>
      </tr>
    ));
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='10%'>Cliente</th>
          <th width='5%'>Cargo</th>
          <th width='5%'>Abono</th>
          <th width='5%'>Diferencia</th>
          <th width='5%'># Cargo</th>
          <th width='5%'># Abono</th>
          <th width='5%'>Fecha inicio</th>
          <th width='5%'>Fecha fin</th>
        </tr>
      </thead>
      <CbTableBody colSpan={8}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

List.propTypes = {
  tiposContactos: PropTypes.array.isRequired,
  almacenes: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired,
  loadContactos: PropTypes.func.isRequired,
};

export default List;
