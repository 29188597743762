import React, { useState, useEffect } from 'react';
import { Card, Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import TableCharacteristics from './TableCharacteristics';

export const FILTER_DEFAULT_CONFIGURATION = { name: 'ALL', text: 'Todas', className: 'default', active: true };

const ListCharacteristics = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search] = useState('');
  const [filterSelectedConfigurations, setFilterSelectedConfigurations] = useState(FILTER_DEFAULT_CONFIGURATION);
  const [catalogFilterConfigurations, setCatalogFilterConfigurations] = useState([]);

  useEffect(() => {
    handlePagination(1);
  }, []);

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    const service = filterSelectedConfigurations;
    const body = { search, service: service.name };
    const { data, count } = await postRequest({ url: `characteristics/search`, params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    const page = eventKey * pagination - pagination;
    handleSearch({ page, pagination, search });
  };

  const handleFilterService = (itemMenu) => {
    const itemSelected = itemMenu - 1;
    setActivePage(1);
    setFilterSelectedConfigurations(catalogFilterConfigurations[itemSelected]);
    handleSearch({
      page: 0,
      search,
      pagination,
      filterConfiguration: catalogFilterConfigurations[itemSelected],
    });
  };

  const handleItemService = (item) => {
    const updatedConfigurations = catalogFilterConfigurations.map((typeFilter) => {
      if (typeFilter.name === item) {
        typeFilter.active = true;
      } else {
        typeFilter.active = false;
      }
      return typeFilter;
    });
    setCatalogFilterConfigurations(updatedConfigurations);
  };

  const renderItemsFilterService = () => {
    const items = catalogFilterConfigurations.map((typeFilter, rowFilter) => {
      const key = rowFilter + 1;
      return (
        <DropdownItem key={key} eventKey={key} onClick={() => handleItemService(typeFilter.name)} active={typeFilter.active}>
          {typeFilter.text}
        </DropdownItem>
      );
    });

    return (
      <DropdownButton title='Configuraciones' key={1} id={`dropdown-basic-1`} onSelect={handleFilterService}>
        {items}
      </DropdownButton>
    );
  };

  const filterConfigurations = renderItemsFilterService();

  return (
    <Module title='Características'>
      <Card>
        <Card.Body>
          <Row>
            <Col sm='3'>
              {filterConfigurations}
              <span className={`label label-${filterSelectedConfigurations.className}`}>{filterSelectedConfigurations.text}</span>
            </Col>
            <Col sm='9'>
              <Link to='/caracteristicas/nueva' className='btn btn-success pull-right'>
                <Icon icon='plus' /> Detallado de Características
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Loading loading={loading}>
        <TableCharacteristics list={list} loadCharacteristics={handleSearch} />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

ListCharacteristics.propTypes = {};

export default ListCharacteristics;
