import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SelectCreateOrUpdateContacto from '@components/forms/SelectCreateOrUpdateContacto';

const DetalleContacto = ({ tipo, label, contactos, contacto, setContacto, setContactos, customerId }) => {
  const onChangeContacto = (updatedContacto) => {
    setContacto(updatedContacto);
  };
  return (
    <Row>
      <Col>
        <SelectCreateOrUpdateContacto
          name={tipo}
          title={label}
          contactos={contactos}
          value={contacto}
          setContactos={setContactos}
          onChange={onChangeContacto}
          customerId={customerId}
        />
      </Col>
    </Row>
  );
};

DetalleContacto.propTypes = {
  tipo: PropTypes.string,
  label: PropTypes.string,
  contactos: PropTypes.array,
  contacto: PropTypes.object,
  setContacto: PropTypes.func,
  customerId: PropTypes.string,
  setContactos: PropTypes.func,
};

export default DetalleContacto;
