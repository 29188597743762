import { TIPO_SERVICIOS } from '@config/constants';
import { IfPermission, RedirectTooltip } from '@controls';
import Confirmation from '@controls/Confirmation';
import PropTypes from 'prop-types';
import React from 'react';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import { ButtonGroup } from 'react-bootstrap';

const TableServices = ({ listServices, loadServices }) => {
  const getTipo = (tipo) => {
    let labelTipo = '';
    if (tipo === TIPO_SERVICIOS.MONITOREO) {
      labelTipo = <span className='label label-success'>Monitoreo</span>;
    } else if (tipo === TIPO_SERVICIOS.INTERACTIVO) {
      labelTipo = <span className='label label-primary'>Interactivo</span>;
    } else if (tipo === TIPO_SERVICIOS.MANO_OBRA) {
      labelTipo = <span className='label label-default'>Mano de obra</span>;
    }
    return labelTipo;
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='15%'>Código</th>
          <th width='10%'>Servicio</th>
          <th width='50%'>Nombre</th>
          <th width='15%'></th>
        </tr>
      </thead>
      <CbTableBody colSpan={4}>
        {listServices.map(({ _id, code, tipo, name }, i) => (
          <tr key={i}>
            <td>{code}</td>
            <td>{getTipo(tipo)}</td>
            <td>
              <strong>{name}</strong>
            </td>
            <td>
              <ButtonGroup>
                <IfPermission action='detalle'>
                  <RedirectTooltip
                    id={1}
                    icon='search'
                    url={`/servicios/detalle/${_id}`}
                    labelTooltip='Ver Servicio Completo'
                    className='btn btn-sm btn-outline-primary'
                  />
                </IfPermission>
                <IfPermission action='editar'>
                  <RedirectTooltip
                    id={1}
                    icon='pencil'
                    url={`/servicios/edicion/${_id}`}
                    labelTooltip='Editar'
                    className='btn btn-sm btn-outline-success'
                  />
                </IfPermission>
                <IfPermission action='eliminar'>
                  <Confirmation
                    btnIcon='trash'
                    typeRequest='deleteRequest'
                    btnType='outline-danger btn-sm'
                    action='Eliminar'
                    url={`services/${_id}`}
                    description={`¿Confirma que desea eliminar el servicio <strong><u> ${code} - ${name}</u> </strong>?`}
                    loadData={() => {
                      loadServices();
                    }}
                  />
                </IfPermission>
              </ButtonGroup>
            </td>
          </tr>
        ))}
      </CbTableBody>
    </CbPanelTableResponsive>
  );
};

TableServices.propTypes = {
  listServices: PropTypes.array.isRequired,
  loadServices: PropTypes.func.isRequired,
};

export default TableServices;
