import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import Table from './Table';

const List = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');
  const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = {
      search,
      fechaFin: moment(fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(fechaInicio, 'YYYY-MM-DD').utc(),
    };
    const { data, count } = await postRequest({ url: `traspasos/search`, params: { page, limit: pagination }, body });
    setList(data);
    setCount(count);
    setLoading(false);
  };

  const onSearch = (params = { search: '' }) => {
    setSearch(params.search);
    setFechaFin(params.fechaFin);
    setFechaInicio(params.fechaInicio);
    setActivePage(1);
    handleSearch();
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    handleSearch();
  };

  return (
    <Module title='Ordenes de traspasos'>
      <Search onSearch={onSearch} withDatetime>
        <Link to='/ordenes-traspasos/nuevo' className='btn btn-success pull-right'>
          <Icon icon='plus' /> Nuevo
        </Link>
      </Search>
      <Loading loading={loading}>
        <Table
          {...{
            list,
            loading,
            activePage,
            count,
            pagination,
            search,
          }}
          loadData={handleSearch}
        />
        <div className='text-center'>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      </Loading>
    </Module>
  );
};

List.propTypes = {};

export default List;
