import React from 'react';
import List from './List';

const Pedidos = () => {
  return <List />;
};

Pedidos.propTypes = {};

export default Pedidos;
