import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@stateless';

const EquipoPrincipal = () => {
  return (
    <OverlayTrigger placement='top' overlay={<Tooltip id={1}>Equipo principal</Tooltip>}>
      <span className='label label-warning'>
        <Icon icon='star' />
      </span>
    </OverlayTrigger>
  );
};

export default EquipoPrincipal;
