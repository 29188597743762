import { CANCELADA, PAGADA, PAGADA_MANUAL } from '@config/constants';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Module, RowsNotFound } from '@stateless';
import { getRequest } from '@utils/api';
import Concepto from './Concepto';
import { useParams } from 'react-router-dom';

const DetalleTraspaso = () => {
  const { traspasoId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRequest({ url: `traspaso/${traspasoId}` });
      setData(data);
    };
    fetchData();
  }, [traspasoId]);

  const renderDetalles = () => {
    const { detalle } = data;
    const detalles = detalle.map((d, i) => {
      const {
        codigo,
        descripcion,
        unidadMedidaId: { measure },
        cantidad,
        ubicacionDestinoId,
        ubicacionOrigenId,
      } = d;
      return (
        <tr key={i}>
          <td>{cantidad}</td>
          <td>{codigo}</td>
          <td>{descripcion}</td>
          <td>{measure}</td>
          <td>{ubicacionOrigenId.nombre}</td>
          <td>{ubicacionDestinoId.nombre}</td>
        </tr>
      );
    });
    return (
      <table className='table table-condensed'>
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Código</th>
            <th>Descripción</th>
            <th>Medida</th>
            <th>Ubicación Origen</th>
            <th>Ubicación Destino</th>
          </tr>
        </thead>
        <tbody>{detalle.length > 0 ? detalles : <RowsNotFound message='Sin detalle' colSpan={11} />}</tbody>
      </table>
    );
  };

  const renderDetalle = () => {
    const {
      encabezado: {
        fecha,
        fecha_entrega,
        estado,
        cancelacion,
        fecha_pago,
        pago_manual,
        numero_traspaso,
        sucursal_origen,
        sucursal_destino,
        almacen_destino,
        almacen_origen,
        userId,
        agente,
      },
    } = data;
    return (
      <Module title={`Traspaso #${numero_traspaso}`} onClickBack={window.history.back} loading={!data}>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-primary'>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <Concepto label='Fecha' valor={moment(fecha).local().format('DD/MM/YYYY')} />
                    {fecha_entrega !== null ? <Concepto label='Fecha de Entrega' valor={moment(fecha_entrega).local().format('DD/MM/YYYY')} /> : null}
                    <Concepto label='Usuario' valor={userId.name} />
                    <Concepto label='Agente' valor={agente ? agente.name : ''} />
                  </div>
                  <div className='col-sm-6'>
                    <div className='pull-right'>
                      <Concepto label='Sucursal origen' valor={sucursal_origen.name} />
                      <Concepto label='Almacene origen' valor={almacen_origen.name} />
                      <Concepto label='Sucursal destino' valor={sucursal_destino.name} />
                      <Concepto label='Almacene destino' valor={almacen_destino.name} />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    {estado === CANCELADA ? (
                      <div>
                        <p>{cancelacion.nota}</p>
                        <small>
                          Fecha de cancelación <em>{moment(cancelacion.fecha).local().format('DD/MM/YYYY HH:mm')}</em>
                        </small>
                      </div>
                    ) : null}
                    {estado === PAGADA || estado === PAGADA_MANUAL ? (
                      <div>
                        {estado === PAGADA_MANUAL ? <p>{pago_manual.nota}</p> : ''}
                        <small>
                          <em>{moment(fecha_pago).local().format('DD/MM/YYYY HH:mm')}</em>
                        </small>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {renderDetalles()}
            </div>
          </div>
        </div>
      </Module>
    );
  };

  return data && data.encabezado ? renderDetalle() : null;
};

DetalleTraspaso.propTypes = {};

export default DetalleTraspaso;
