import React, { useEffect } from 'react';
import Select from 'react-select';
import { useStoreClasificacionesClientes } from '@stores/catalogs.store';
import PropTypes from 'prop-types';

const SelectClasificacionCliente = ({ onChange, name, value }) => {
  const { data, execute } = useStoreClasificacionesClientes();
  useEffect(() => {
    execute();
  }, []);
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      value={value}
      name={name}
      placeholder='Seleccione'
      options={data}
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='basic-multi-select'
      getOptionLabel={(x) => x.description}
      getOptionValue={(x) => x._id}
      classNamePrefix='select'
      isClearable
    />
  );
};


SelectClasificacionCliente.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
};

export default SelectClasificacionCliente;
