import IfPermission from '@controls/IfPermission';
import Search from '@controls/Search';
import React, { useState, useEffect, useCallback } from 'react';
import { Loading, Module } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import List from './components/List';
import New from './components/New';
import { Col, Row } from 'react-bootstrap';

const TiposMonitoreos = () => {
  const [data, setData] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [listasPrecios, setListasPrecios] = useState([]);
  const [search, setSearch] = useState('');

  const loadCatalogos = useCallback(async (callback) => {
    setLoading(true);
    const { unidades, listas_precios } = await getRequest({ url: `tipos-monitoreos/catalogos` });
    setUnidades(unidades);
    setListasPrecios(listas_precios);
    if (callback) callback();
  }, []);

  const handleSearch = useCallback(async () => {
    const page = activePage * pagination - pagination;
    setLoading(true);
    const body = { search };
    const { data, count } = await postRequest({ url: `tipos-monitoreos/search`, params: { page, limit: pagination }, body });
    setData(data);
    setCount(count);
    setLoading(false);
  }, [activePage, pagination, search]);

  const onSearch = useCallback(
    (params = { search: '' }) => {
      setSearch(params.search);
      setActivePage(1);
      handleSearch();
    },
    [handleSearch]
  );

  const handlePagination = useCallback(
    (eventKey) => {
      setActivePage(eventKey);
      handleSearch();
    },
    [handleSearch]
  );

  useEffect(() => {
    loadCatalogos(handleSearch);
  }, [loadCatalogos, handleSearch]);

  return (
    <Module title='Tipos de Monitoreos'>
      <Search onSearch={onSearch} blockOne='col-sm-4' blockSecond='col-sm-8'>
        <IfPermission action='crear'>
          <New unidades={unidades} listas_precios={listasPrecios} loadData={handleSearch} />
        </IfPermission>
      </Search>
      <Row>
        <Col>
          <Loading loading={loading}>
            <List data={data} unidades={unidades} listas_precios={listasPrecios} loadData={handleSearch} />
          </Loading>
          <div className='text-center'>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        </Col>
      </Row>
    </Module>
  );
};

TiposMonitoreos.propTypes = {};

export default TiposMonitoreos;
