import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getRequest } from '@utils/api';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const FilterSelect = ({
  multi = false,
  disabled = false,
  async = false,
  options = [],
  url = '',
  value,
  fields = ['_id', 'name'],
  onChangeOption
}) => {
  const [optionsSelected, setOptionsSelected] = useState({});

  useEffect(() => {
    if (optionsSelected !== null) {
      if (value === '' && Object.getOwnPropertyNames(optionsSelected).length > 0) {
        setOptionsSelected({});
      }
    }
  }, [value, optionsSelected]);

  const changeOptions = (val = { value: '' }) => {
    setOptionsSelected(val);
    onChangeOption(val);
  };

  const loadData = useCallback(
    async (input, callback) => {
      if (!input || input.trim().length < 3) {
        callback(null, { options: [], complete: true });
        return;
      }
      const { data } = await getRequest({ url: `${url}?query=${input}` });
      if (data.length > 0) {
        const options = renderOptions(data);
        callback(null, { options: options, complete: true });
      } else {
        callback(null, { options: [], complete: true });
      }
    },
    [url]
  );

  const renderOptions = (data) => {
    const options = [];
    const value = fields[0];
    const label = fields[1].split(',');

    data.map((option) => {
      let tag = '';

      label.map((t) => {
        tag += ' ' + option._id[t];
      });

      options.push({ value: option._id[value], label: tag });
    });

    return options;
  };

  const syncSelect = () => (
    <Select
      placeholder='Buscar...'
      name='options'
      value={optionsSelected}
      options={renderOptions(options)}
      onChange={changeOptions}
      isClearable
      isMulti={multi}
    />
  );

  const asyncSelect = () => (
    <AsyncSelect
      placeholder='Buscar...'
      searchPromptText='Resultados...'
      noOptionsMessage='Sin resultados...'
      name='options'
      isMulti={multi}
      value={optionsSelected}
      loadOptions={loadData}
      onChange={changeOptions}
      isClearable
      isDisabled={disabled}
      isLoading={true}
    />
  );

  const render = async ? asyncSelect() : syncSelect();

  return <div>{render}</div>;
};

FilterSelect.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string,
  options: PropTypes.array,
  onChangeOption: PropTypes.func,
  multi: PropTypes.bool,
  async: PropTypes.bool,
  url: PropTypes.string,
  fields: PropTypes.array,
};


export default FilterSelect;
