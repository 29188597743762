import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const EditEquipoSeguridad = ({ equiposeguridadId, loadEquipoSeguridad }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [precio, setPrecio] = useState('');
  const [isLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setNombre('');
    setPrecio('');
  };

  const open = async () => {
    const {
      data: { nombre, precio },
    } = await getRequest({ url: `equiposeguridad/${equiposeguridadId}` });
    setShowModal(true);
    setNombre(nombre);
    setPrecio(precio.toString());
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const data = {
      nombre: nombre.trim(),
      precio: precio.trim(),
    };

    if (validations(data)) {
      await putRequest({ url: `equiposeguridad/${equiposeguridadId}`, body: data });
      loadEquipoSeguridad();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.precio + '')) {
      validate.success = false;
      validate.message = 'Precio es requerido.';
    } else if (isNaN(parseFloat(params.precio))) {
      validate.success = false;
      validate.message = 'Precio debe ser numérico.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col>
            <DivFormGroup name='nombre' title='Nombre' required>
              <Input name='nombre' onChange={(e) => setNombre(e.target.value)} defaultValue={nombre} />
            </DivFormGroup>
            <DivFormGroup name='precio' title='Precio' required>
              <Input name='precio' onChange={(e) => setPrecio(e.target.value)} defaultValue={precio} maxLength={13} />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

EditEquipoSeguridad.propTypes = {
  equiposeguridadId: PropTypes.string.isRequired,
  loadEquipoSeguridad: PropTypes.func.isRequired,
};

export default EditEquipoSeguridad;
