import { MONEDA } from '@config/constants';
import { DivFormGroup, FormInput, Input, Label } from '@controls';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Icon, Loading, Module, RowsNotFound, Saving } from '@stateless';
import { getRequest, postRequest } from '@utils/api';
import { cleanNumber } from '@utils/formatter';
import { isEmpty, isMongoId } from 'validator';
import AgregarDetalle from '../pedidos/AgregarDetalle';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectAgente from '@components/forms/SelectAgente';
import InputNumber from '@components/forms/InputNumber';
import SelectSucursal from '@components/forms/SelectSucursal';
import SelectAlmacen from '@components/forms/SelectAlmacen';
import Select from 'react-select';
import SelectUbicacion from '@components/forms/SelectUbicacion';

const New = () => {
  const { addNotification: notification } = useStoreNotification();
  const navigate = useNavigate();
  const [state, setState] = useState({
    reset: false,
    isLoadingForm: true,
    isLoading: false,
    fecha: moment().format('YYYY-MM-DD'),
    fechaEntrega: moment().format('YYYY-MM-DD'),
    ubicaciones: [],
    monedaId: MONEDA.DOLAR,
    sucursales: [],
    almacenes: [],
    monedas: [],
    measures: [],
    providers: [],
    storehouses: [],
    proveedores: [],
    proveedorObj: null,
    proveedorName: '',
    detalles: [],
    usos_cfdi: [],
    usoCfdiId: null,
    subtotal: 0,
    total: 0,
    impuestos: 0,
    descuento: 0,
    observaciones: '',
    estatus: 'FINALIZADA',
    sucursalOrigenId: null,
    almacenOrigenId: null,
    sucursalDestinoId: null,
    almacenDestinoId: null,
    proveedor: null,
    tipoCambio: 0,
    estatus_traspasos: [
      { value: 'INICIADA', label: 'INICIADA' },
      { value: 'EN_PROCESO', label: 'EN PROCESO' },
      { value: 'FINALIZADA', label: 'FINALIZADA' },
    ],
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSelect = (campo, value) => {
    setState((prevState) => ({ ...prevState, [campo]: value }));
  };

  const onChangeDetalle = (name, index, event) => {
    if (['cantidad'].includes(name)) {
      setState((prevState) => ({
        ...prevState,
        detalles: prevState.detalles.map((x, i) => {
          if (i === index) x[name] = cleanNumber(event.target.value);
          return { ...x };
        }),
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => {
        if (i === index) {
          x[name] = event.target.value;
        }
        return { ...x };
      }),
    }));
  };

  const onChangeUbicacion = (index, value) => {
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.map((x, i) => {
        if (i === index) {
          x.ubicacionDestinoId = value;
        }
        return { ...x };
      }),
    }));
  };

  const onClickRemoveDetalle = (index, event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      detalles: prevState.detalles.filter((x, i) => i !== index),
    }));
  };

  const onChangeAgente = (itemSelect) => {
    if (itemSelect) {
      setState((prevState) => ({
        ...prevState,
        agente: itemSelect,
        agenteId: itemSelect._id,
        agenteObj: itemSelect,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        agenteName: '',
        agenteId: null,
        agente: null,
        agenteObj: null,
      }));
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.estatus_traspaso + '')) {
      validate.success = false;
      validate.message = 'Estatus es requerido.';
    } else if (!isMongoId(params.sucursalOrigenId + '')) {
      validate.success = false;
      validate.message = 'Sucursal origen es requerido.';
    } else if (!isMongoId(params.almacenOrigenId + '')) {
      validate.success = false;
      validate.message = 'Almácen origen es requerido.';
    } else if (!isMongoId(params.sucursalDestinoId + '')) {
      validate.success = false;
      validate.message = 'Sucursal destino es requerido.';
    } else if (!isMongoId(params.almacenDestinoId + '')) {
      validate.success = false;
      validate.message = 'Almácen destino es requerido.';
    } else if (!isMongoId(params.agenteId + '')) {
      validate.success = false;
      validate.message = 'Agente es requerido.';
    } else if (params.detalles.length === 0) {
      validate.success = false;
      validate.message = 'No hay partidas asociados al traspaso';
    }
    params.detalles.forEach((detalle) => {
      if (isEmpty(detalle.descripcion + '')) {
        validate.success = false;
        validate.message = 'Descripción es requerido.';
      } else if (isEmpty(detalle.cantidad + '') || detalle.cantidad <= 0) {
        validate.success = false;
        validate.message = 'Cantidad es requerido.';
      } else if (detalle.cantidad > detalle.existencia) {
        validate.success = false;
        validate.message = 'Cantidad, debe ser menor o igual a la existencia.';
      } else if (isEmpty(detalle.precio + '') || detalle.precio <= 0) {
        validate.success = false;
        validate.message = 'Precio es requerido.';
      } else if (!isMongoId(detalle.ubicacionDestinoId + '')) {
        validate.success = false;
        validate.message = 'Ubicación es requerido.';
      }
    });

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { numeroOrden, fecha, estatus, sucursalOrigenId, almacenOrigenId, sucursalDestinoId, almacenDestinoId, agenteId, observaciones, detalles } =
      state;

    const data = {
      numero_traspaso: numeroOrden,
      fecha,
      estatus_traspaso: estatus,
      sucursalOrigenId,
      almacenOrigenId,
      sucursalDestinoId,
      almacenDestinoId,
      observaciones,
      agenteId,
      detalles: detalles.map((detalle) => {
        return {
          ...detalle,
        };
      }),
    };
    if (validations(data)) {
      await postRequest({ url: `traspasos`, body: data });
      setTimeout(() => navigate(0), 1000);
    }
  };

  const onAgregarDetalle = (existencias) => {
    const { detalles } = state;
    setState((prevState) => ({
      ...prevState,
      detalles: [
        ...detalles,
        ...existencias.map((itemSelect) => {
          const producto = itemSelect.equipmentId;
          let measure = null,
            measureId = null;
          measure =
            producto.equipmentsMeasureId && producto.equipmentsMeasureId.measure
              ? producto.equipmentsMeasureId.measure
              : producto.equipmentsMeasureId;
          measureId =
            producto.equipmentsMeasureId && producto.equipmentsMeasureId._id ? producto.equipmentsMeasureId._id : producto.equipmentsMeasureId;
          return {
            ...producto,
            equipmentId: producto._id,
            descripcion: producto.name.toUpperCase(),
            noIdentificador: producto.code,
            measure,
            measureId,
            ubicacionOrigen: itemSelect.ubicacionId.nombre,
            ubicacionOrigenId: itemSelect.ubicacionId._id,
            existenciaId: itemSelect._id,
            existencia: itemSelect.stock,
            cantidad: 1,
          };
        }),
      ],
    }));
  };

  const renderDetalles = () => {
    const { almacenDestinoId, detalles } = state;
    if (detalles.length === 0) return <RowsNotFound message='No se han agregado detalles.' colSpan={8} />;
    return detalles.map((detalle, i) => (
      <tr key={i}>
        <th style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.noIdentificador}</th>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
          <Input className='input-sm' value={detalle.descripcion} style={{ width: '250px' }} />
        </td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.measure}</td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.existencia}</td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>
          <InputNumber className='input-sm' value={detalle.cantidad} onChange={(e) => onChangeDetalle('cantidad', i, e)} style={{ width: '80px' }} />
        </td>
        <td style={{ padding: '2px 8px', verticalAlign: 'middle' }}>{detalle.ubicacionOrigen}</td>
        <td>
          <SelectUbicacion
            onChange={(value) => onChangeUbicacion(i, value)}
            name='ubicacionDestinoId'
            value={detalle.ubicacionDestinoId}
            filter={(x) => x.almacenId === almacenDestinoId?._id && x._id !== detalle.ubicacionOrigenId?._id}
          />
        </td>
        <td>
          <a href='#' onClick={(e) => onClickRemoveDetalle(i, e)} style={{ color: 'red' }}>
            <Icon icon='remove' />
          </a>
        </td>
      </tr>
    ));
  };

  const renderView = () => {
    const {
      numeroOrden,
      fecha,
      estatus,
      sucursalOrigenId,
      sucursalDestinoId,
      almacenOrigenId,
      almacenDestinoId,
      agenteObj,
      isLoading,
      observaciones,
      estatus_traspasos,
    } = state;
    return (
      <>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-3'>
                <FormInput
                  title='Número de orden'
                  placeholder='Número de orden'
                  disabled
                  name='numeroOrden'
                  onChange={onChange}
                  value={numeroOrden}
                />
              </div>
              <div className='col-sm-3'>
                <FormInput title='Fecha' type='date' required name='fecha' onChange={onChange} value={fecha} />
              </div>
              <div className='col-sm-3'>
                <DivFormGroup>
                  <Label name='estatus' required title='Estatus' />
                  <Select name='estatus' value={estatus} disabled options={estatus_traspasos} onChange={(value) => onSelect('estatus', value)} />
                </DivFormGroup>
              </div>
              <div className='col-sm-3'>
                <DivFormGroup>
                  <Label name='agenteName' required title='Agente' />
                  <SelectAgente value={agenteObj} onChange={onChangeAgente} name='agenteObj' />
                </DivFormGroup>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-3'>
                <DivFormGroup>
                  <Label name='sucursalOrigenId' required title='Sucursal origen' />
                  <SelectSucursal value={sucursalOrigenId} onChange={(value) => onSelect('sucursalOrigenId', value)} name='sucursalOrigenId' />
                </DivFormGroup>
              </div>
              <div className='col-sm-3'>
                <DivFormGroup>
                  <Label name='almacenOrigenId' required title='Almacen origen' />
                  <SelectAlmacen
                    value={almacenOrigenId}
                    onChange={(value) => onSelect('almacenOrigenId', value)}
                    name='almacenOrigenId'
                    filter={(x) => x.sucursalId === sucursalOrigenId?._id}
                  />
                </DivFormGroup>
              </div>
              <div className='col-sm-3'>
                <DivFormGroup>
                  <Label name='sucursalDestinoId' required title='Sucursal destino' />
                  <SelectSucursal value={sucursalDestinoId} onChange={(value) => onSelect('sucursalDestinoId', value)} name='sucursalDestinoId' />
                </DivFormGroup>
              </div>
              <div className='col-sm-3'>
                <DivFormGroup>
                  <Label name='almacenDestinoId' required title='Almacen destino' />
                  <SelectAlmacen
                    value={almacenDestinoId}
                    onChange={(value) => onSelect('almacenDestinoId', value)}
                    name='almacenDestinoId'
                    filter={(x) => x.sucursalId === sucursalDestinoId?._id}
                  />
                </DivFormGroup>
              </div>
            </div>
          </div>
          <div className='panel-footer'>
            <div className='row'>
              <div className='col-sm-12'>
                <AgregarDetalle onAgregarDetalle={onAgregarDetalle} almacenId={almacenOrigenId} />
              </div>
            </div>
          </div>
        </div>
        <div className='panel panel-default'>
          <div className='table-responsive'>
            <table className='table table-striped table-condenced'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Descripción</th>
                  <th>Unidad</th>
                  <th>Existencia</th>
                  <th>Cantidad</th>
                  <th>Ubicacion origen</th>
                  <th>Ubicacion destino</th>
                  <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                </tr>
              </thead>
              <tbody>{renderDetalles()}</tbody>
            </table>
          </div>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-12'>
                <FormInput title='Observaciones' name='observaciones' onChange={onChange} value={observaciones} />
              </div>
            </div>
          </div>
          <div className='panel-footer'>
            <div className='row'>
              <div className='col-sm-12'>
                <Saving saving={isLoading} />
                <Button onClick={handleRegister} className='pull-right' disabled={isLoading}>
                  <Icon icon='floppy-disk' /> Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { numero_traspaso },
      } = await getRequest({ url: `traspasos/siguienteNumero` });
      const {
        data: { sucursales, almacenes, ubicaciones, agente },
      } = await getRequest({ url: `traspasos-catalogos` });
      setState((prevState) => ({
        ...prevState,
        sucursales: sucursales.map((sucursal) => {
          return {
            value: sucursal._id,
            label: `${sucursal.name}`,
          };
        }),
        agenteObj: { value: agente._id, label: agente.nombre },
        agenteId: agente._id,
        agenteName: agente.nombre,
        sucursalOrigenId: agente.sucursalId,
        almacenOrigenId: agente.almacenId,
        almacenes,
        ubicaciones,
        isLoadingForm: false,
        numeroOrden: numero_traspaso,
      }));
    };
    fetchData();
  }, []);

  const { isLoadingForm } = state;
  return (
    <Module onClickBack={window.history.back} title='Nueva orden de traspaso'>
      {!isLoadingForm ? renderView() : <Loading />}
    </Module>
  );
};

New.propTypes = {};

export default New;
