import Joi from 'joi';
import { MESSAGES } from '@utils/validations';
const R_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const compraSchemaUpdate = Joi.object({
  tipoCompraId: Joi.string().label('Tipo de compra').required().regex(R_MONGO_ID),
  fecha: Joi.date().label('Fecha').required(),
  fecha_entrega: Joi.date().label('Fecha de entrega').required(),
  estatus: Joi.string().label('Estatus').required(),
  sucursalId: Joi.string().label('Sucursal').required().regex(R_MONGO_ID),
  monedaId: Joi.string().label('Moneda').required(),
  agenteId: Joi.string().label('Agente').required().regex(R_MONGO_ID),
  proveedorId: Joi.string().label('Proveedor').required().regex(R_MONGO_ID),
  usoCfdiId: Joi.string().label('Uso de CFDI').required().regex(R_MONGO_ID),
  detalles: Joi.array().items(
    Joi.object({
      descripcion: Joi.string().label('Descripción').required(),
      measureId: Joi.string().label('Unidad').required().regex(R_MONGO_ID),
      cantidad: Joi.number().label('Cantidad').required(),
      cantidad_recibida: Joi.number().label('Cantidad recibida').required(),
      precio: Joi.number().label('Precio').required(),
      descuento: Joi.number().label('Descuento').required(),
      impuesto: Joi.number().label('Impuesto').required(),
      importe: Joi.number().label('Importe').required(),
    })
  ),
}).messages(MESSAGES);

export function validateUpdate(compra) {
  return compraSchemaUpdate.validate(compra);
}
