import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ id, nombre, load }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger btn-sm'
      action='Eliminar'
      url={`tipos-compras/${id}`}
      description={`¿Confirma que desea eliminar el tipo de compra <strong><u> ${nombre}</u> </strong>?`}
      loadData={load}
    />
  );
};

Delete.propTypes = {
  tipoCompraId: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default Delete;
