import { FormInput, Label } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbModal from '@cbcomponents/CbModal';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import SelectPais from '@components/forms/SelectPais';

const New = ({ load }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [estado, setEstado] = useState('');
  const [estadoId, setEstadoId] = useState('');
  const [paisId, setPaisId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
    setEstado('');
    setEstadoId('');
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      estado: estado.trim().toUpperCase(),
      estadoId: estadoId.trim(),
      paisId,
    };

    if (validations(data)) {
      await postRequest({ url: `estados`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.estadoId + '')) {
      validate.success = false;
      validate.message = 'Código es requerido.';
    } else if (isEmpty(params.estado + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (!isMongoId(params.paisId + '')) {
      validate.success = false;
      validate.message = 'Pais es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onChangePais = (value) => {
    setPaisId(value);
  };

  return (
    <div>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} title='Agregar estado' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col sm='4'>
            <Label name='paisId' title='País' required />
            <SelectPais name='paisId' value={paisId} onChange={onChangePais} />
          </Col>
          <Col sm='4'>
            <FormInput title='Código' placeholder='Código' required name='estadoId' onChange={(e) => setEstadoId(e.target.value)} value={estadoId} />
          </Col>
          <Col sm='4'>
            <FormInput title='Nombre' placeholder='Nombre' required name='estado' onChange={(e) => setEstado(e.target.value)} value={estado} />
          </Col>
        </Row>
      </CbModal>
    </div>
  );
};

New.propTypes = {
  paises: PropTypes.array.isRequired,
  load: PropTypes.func.isRequired,
};

export default New;
