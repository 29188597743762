import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRequest } from '@utils/api';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';

const DetailTerceros = ({ planMantenimientoId, nombre }) => {
  const [showModal, setShowModal] = useState(false);
  const [listaTerceros, setListaTerceros] = useState([]);

  const close = () => {
    setShowModal(false);
    setListaTerceros([]);
  };

  const open = async () => {
    const {
      data: { listaTerceros },
    } = await getRequest({ url: `planes-terceros/${planMantenimientoId}` });
    setListaTerceros(listaTerceros);
    setShowModal(true);
  };

  const renderKits = () => {
    return listaTerceros.map((k, i) => {
      const { serviciosTercerosId } = k;

      return (
        <div className='panel panel-primary' key={i}>
          <div className='panel-heading'>
            <h3 className='panel-title'>{serviciosTercerosId.nombre}</h3>
          </div>
          <div className='panel-body'>
            <h4>
              Proveedor <small>{serviciosTercerosId.proveedorId.name}</small>
            </h4>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='zoom-in' title='Ver detalles' />
      <CbModal
        show={showModal}
        title={
          <>
            Servicios de terceros asignados a <strong>{nombre}</strong>
          </>
        }
        onClose={close}
      >
        <div className='row'>
          <div className='col-sm-12'>{renderKits()}</div>
        </div>
      </CbModal>
    </>
  );
};

DetailTerceros.propTypes = {
  planMantenimientoId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
};

export default DetailTerceros;
