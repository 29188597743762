import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, RowsNotFound } from '@stateless';
import Delete from './Delete';

const List = ({ catalogCanastas }) => {
  const renderRows = () => {
    return catalogCanastas.map((row, item) => (
      <tr key={item}>
        <td>{row.nombre}</td>
        <td>
          <Delete id={item} itemId={row._id} label={` ${row.nombre}`} {...this.props} btnClass='btn btn-outline-danger' />
          <Link to={`/kit-servicios/edicion/${row._id}`} className='btn btn-outline-info'>
            <Icon icon='pencil' />
          </Link>
        </td>
      </tr>
    ));
  };

  const rows = catalogCanastas.length > 0 ? renderRows() : <RowsNotFound colSpan={3} />;

  return (
    <div className='panel panel-default panel-table'>
      <div className='table-responsive'>
        <table className='table table-condensed table-hover table-striped'>
          <thead>
            <tr>
              <th width='70%'>Nombre del Kit</th>
              <th width='20%'></th>
              <th width='10%'></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

List.propTypes = {
  catalogCanastas: PropTypes.array.isRequired,
};

export default List;
