import { RowItem } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Domicilio from '@components/customers/Domicilio';

const PersonaMoral = ({ customer: { rfc, razon_social, nombre_comercial, tipoEstablecimientoId, rubroId } }) => {
  return (
    <Row>
      <Col sm='4'>
        <RowItem label='Razón Social'>{razon_social}</RowItem>
        <RowItem label='RFC'>{rfc}</RowItem>
        <RowItem label='Nombre Comercial'>{nombre_comercial}</RowItem>
      </Col>
      <Col sm='3'>
        <RowItem label='Establecimiento'>
          {typeof tipoEstablecimientoId !== 'undefined' && tipoEstablecimientoId !== null && tipoEstablecimientoId !== ''
            ? tipoEstablecimientoId.nombre
            : ''}
        </RowItem>
        <RowItem label='Rubro'>{typeof rubroId !== 'undefined' && rubroId !== null && rubroId !== '' ? rubroId.rubro : ''}</RowItem>
      </Col>
      <Col sm='5'>
        <Domicilio {...{ customer: { rfc, razon_social, nombre_comercial, tipoEstablecimientoId, rubroId } }} />
      </Col>
    </Row>
  );
};

PersonaMoral.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default PersonaMoral;
