export const cleanNumber = (number) => {
  let value = 0;
  if (number) {
    value = number
      .toString()
      .trim()
      .replace(/%|\$|,/gi, '');
    if (isNaN(value)) value = 0;
  }
  return value;
};

export const clean = (number) => {
  let value = 0;
  if (number) {
    value = number
      .toString()
      .trim()
      .replace(/%|\$|,/gi, '');
    if (isNaN(value)) value = 0;
  }
  return parseFloat(parseFloat(value).toFixed(2));
};
