import { DivFormGroup, FormInput } from '@controls';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { postRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const NewStoreHouse = ({ loadStoreHouse, sucursales, agentes }) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [nameStoreHouse, setNameStoreHouse] = useState('');
  const [sucursalId, setSucursalId] = useState(null);
  const [address, setAddress] = useState('');
  const [selectedAgentes, setSelectedAgentes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);
    setIsLoading(false);
    setNameStoreHouse('');
    setSucursalId(null);
    setAddress('');
    setSelectedAgentes([]);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'nameStoreHouse') setNameStoreHouse(value);
    if (name === 'address') setAddress(value);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const data = {
      nameStoreHouse: nameStoreHouse.toUpperCase().trim(),
      address: address.toUpperCase().trim(),
      sucursalId: sucursalId?._id,
      agentes: selectedAgentes.map((x) => x._id),
    };

    if (validations(data)) {
      await postRequest({ url: 'storehouse', body: data });
      loadStoreHouse();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nameStoreHouse + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (!isMongoId(params.sucursalId + '')) {
      validate.success = false;
      validate.message = 'Sucursal es requerida.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onSelect = (campo, value) => {
    if (campo === 'sucursalId') setSucursalId(value);
  };

  const onChangeMultiAgentes = (campo, value) => {
    if (campo === 'agentes') setSelectedAgentes(value);
  };

  return (
    <>
      <CbButtonOver icon='plus' title='Nuevo' onClick={open} hiddenXs type='success' />
      <CbModal show={showModal} onClose={close} title='Agregar' isLoading={isLoading} onSave={handleRegister}>
        <Row>
          <Col>
            <FormInput title='Nombre' name='nameStoreHouse' required onChange={onChange} value={nameStoreHouse} />
          </Col>
          <Col>
            <FormInput title='Domicilio' name='address' required onChange={onChange} value={address} type='textarea' rows={3} />
          </Col>
          <Col>
            <DivFormGroup name='sucursalId' title='Sucursal' required>
              <Select
                value={sucursalId}
                placeholder='Selecciona una opcion'
                options={sucursales}
                getOptionLabel={(option) => option.nombre}
                getOptionValue={(option) => option._id}
                onChange={(value) => onSelect('sucursalId', value)}
              />
            </DivFormGroup>
          </Col>
          <Col>
            <DivFormGroup name='agentes' title='Agentes encargados'>
              <Select
                value={selectedAgentes}
                isMulti
                placeholder='Selecciona opciones'
                options={agentes}
                getOptionLabel={(option) => option._id}
                getOptionValue={(option) => option.nombre}
                onChange={(value) => onChangeMultiAgentes('agentes', value)}
                className='basic-multi-select'
                classNamePrefix='select'
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

NewStoreHouse.propTypes = {
  loadStoreHouse: PropTypes.func.isRequired,
  sucursales: PropTypes.array.isRequired,
  agentes: PropTypes.array,
};

export default NewStoreHouse;
