import If from '@controls/If';
import Search from '@controls/Search';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loading, Module } from '@stateless';
import { postRequest } from '@utils/api';
import { CbPagination } from '@controls';
import Table from './Table';

const List = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [pagination] = useState(20);
  const [search, setSearch] = useState('');
  const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-01'));
  const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));
  const [enviados] = useState(false);

  useEffect(() => {
    handleSearch({ page: 0, pagination, fechaInicio, fechaFin });
  }, []);

  const handleSearch = async (params = {}) => {
    const page = activePage * pagination - pagination;

    setLoading(true);

    const body = {
      search: params.search,
      fechaFin: moment(params.fechaFin, 'YYYY-MM-DD').utc(),
      fechaInicio: moment(params.fechaInicio, 'YYYY-MM-DD').utc(),
    };

    const { data, count } = await postRequest({ url: `nomina/search`, params: { page, limit: pagination }, body });

    setList(data);
    setCount(count);
    setLoading(false);
    setSearch(params.search);
    setFechaFin(params.fechaFin);
    setFechaInicio(params.fechaInicio);
  };

  const handlePagination = (eventKey) => {
    setActivePage(eventKey);
    const page = eventKey * pagination - pagination;
    handleSearch({ page, pagination, search, fechaInicio, fechaFin });
  };

  const renderList = () => {
    return <Table {...{ list, loading, activePage, count, pagination, search, fechaInicio, fechaFin, enviados }} loadData={handleSearch} />;
  };

  return (
    <Module title='Nomina'>
      <Search onSearch={handleSearch} withDatetime>
        <Link to='/nomina/importar' className='btn btn-success pull-right mr-5'>
          <Icon icon='import' /> Importar
        </Link>
      </Search>
      <If condition={!loading}>
        <If.Then>
          <form>
            {renderList()}
            <div className='text-center'>
              <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
            </div>
          </form>
        </If.Then>
        <If.Else>
          <br />
          <Loading />
        </If.Else>
      </If>
    </Module>
  );
};

List.propTypes = {};

export default List;
