import { RedirectTooltip } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { renderNombreCliente } from '@utils/general';
import CbBadge from '@cbcomponents/CbBadge';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';
import Actividades from './Actividades';
import Editar from './Editar';
import { ButtonGroup } from 'react-bootstrap';
import Evidencias from './Evidencias';

const Table = ({ list, loadData }) => {
  const [estatusSeguimientos] = useState({
    INICIADO: 'INICIADO',
    EN_PROCESO: 'EN PROCESO',
    PENDIENTE: 'PENDIENTE',
    FINALIZADO: 'FINALIZADO',
  });

  const renderRows = () => {
    return list.map((r, i) => {
      const clienteObj = r.customerId ? r.customerId : r.prospectoId;
      let cliente = renderNombreCliente(clienteObj);
      return (
        <tr key={i}>
          <td>
            <b>{r.titulo}</b> <br />
            <span style={{ fontSize: '10px' }}>
              {r.observaciones.length > 100 ? <span title={r.observaciones}>{r.observaciones.substring(0, 100)}...</span> : r.observaciones}
            </span>
          </td>
          <td>
            <b>{cliente}</b> <br />
            <span style={{ fontSize: '10px' }}>{clienteObj ? clienteObj.nombre_comercial : null}</span>
          </td>
          <td>
            {r.contactoId ? (
              <span>
                <b>{`${r.contactoId.name} ${r.contactoId.paterno} ${r.contactoId.materno}`}</b> <br />
                Teléfono: <br />
                <b>
                  {r.contactoId.phone.split(',').map((x) => (
                    <CbBadge key={x} type='primary'>
                      {x}
                    </CbBadge>
                  ))}
                </b>{' '}
                <br />
                Correo: <br />
                <b>
                  {r.contactoId.email.split(',').map((x) => (
                    <CbBadge key={x} type='primary'>
                      {x}
                    </CbBadge>
                  ))}
                </b>
              </span>
            ) : (
              'Sin contacto asignado'
            )}
          </td>
          <td>
            <span>
              <b>
                Solicitante: <br /> {r.agenteId ? <CbBadge type='primary'>{r.agenteId.name}</CbBadge> : null}
              </b>{' '}
              <br />
              <b>
                Responsable(s): <br />{' '}
                {r.agentes_asignados
                  ? r.agentes_asignados
                      .map((x) => x.nombre)
                      .map((x) => (
                        <CbBadge key={x} type='primary'>
                          {x}
                        </CbBadge>
                      ))
                  : 'Sin agente asignado'}
              </b>
            </span>
          </td>
          <td>
            <b>Creación:</b>
            <br />
            {r.fecha ? moment(r.fecha).local().format('YYYY/MM/DD HH:mm') : null}
            <br />
            <span>
              {r.fechaFinalizacion ? (
                <>
                  <b>Finalización:</b>
                  <br /> {moment(r.fechaFinalizacion).local().format('YYYY/MM/DD HH:mm')}
                </>
              ) : null}
            </span>
          </td>
          <td>{r.motivoId ? r.motivoId.nombre : null}</td>
          <td>{r.resultadoVisitaId ? r.resultadoVisitaId.nombre : null}</td>
          <td>
            <CbBadge type={r.estatus === 'INICIADO' ? 'primary' : 'secondary'}>{estatusSeguimientos[r.estatus]}</CbBadge>
          </td>
          <td>
            <ButtonGroup>
              <Editar seguimientoId={r._id} loadData={loadData} />
              <Evidencias seguimientoId={r._id} loadData={loadData} />
              <Actividades id={r._id} />
              <RedirectTooltip
                id={1}
                icon='search'
                url={
                  r.tipo === 'CLIENTE' && r.customerId
                    ? `/clientes/detalle/${r.customerId._id}`
                    : `/prospectos/detalle/${r.prospectoId ? r.prospectoId._id : ''}`
                }
                labelTooltip='Detalle de cliente'
                className='btn btn-outline-info'
              />
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='20%'>Titulo</th>
          <th width='20%'>Cliente/Prospecto</th>
          <th width='10%'>Contacto</th>
          <th width='20%'>Agentes</th>
          <th width='10%'>Fecha</th>
          <th width='10%'>Motivo</th>
          <th width='10%'>Resultado</th>
          <th width='10%'>Estatus</th>
          <th width='10%'>&nbsp;</th>
        </tr>
      </thead>
      <CbTableBody colSpan={9}>{renderRows()}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

Table.propTypes = {
  list: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default Table;
