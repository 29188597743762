import PropTypes from 'prop-types';
import React, { useState, useRef, Fragment } from 'react';
import { Col, DropdownItem, Row } from 'react-bootstrap';
import { Icon } from '@stateless';
import { postUploadRequest, urlPhoto } from '@utils/api';

import SignatureCanvas from 'react-signature-canvas';
import CbModal from '@cbcomponents/CbModal';

const EditFirma = (props) => {
  const [showModal, setShowModal] = useState(false);
  const canvasRef = useRef(null);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    const { firma } = props;
    setShowModal(true);
    if (firma) {
      setTimeout(() => {
        const canvas = canvasRef.current.getCanvas();
        const context = canvas.getContext('2d');
        const base_image = new Image();
        base_image.crossOrigin = 'Anonymous';
        base_image.src = urlPhoto() + firma.photo;
        base_image.onload = () => {
          context.drawImage(base_image, 0, 0);
        };
      }, 100);
    }
  };

  const handleLimpiar = () => {
    canvasRef.current.clear();
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const { agenteId } = props;
    const blob = DataURIToBlob(canvasRef.current.toDataURL('image/png'));
    const form = new FormData();
    form.append('image', blob, 'firma.png');
    await postUploadRequest({ url: `agentes/firma/${agenteId}`, form });
    setShowModal(false);
    props.loadAgentes();
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  return (
    <Fragment>
      <DropdownItem onClick={open}>
        <Icon icon='pencil' /> Firma
      </DropdownItem>
      <CbModal
        show={showModal}
        title='Firma del agente'
        onClose={close}
        onSave={handleEdit}
        extraButtons={[{ text: 'Limpiar', onClick: handleLimpiar, type: 'danger' }]}
      >
        <Row>
          <Col>
            <div style={{ border: '1px solid black', display: 'inline-block', textAlign: 'center' }}>
              <SignatureCanvas ref={canvasRef} penColor='black' canvasProps={{ width: 300, height: 150, className: 'sigCanvas', margin: '0 auto' }} />
            </div>
          </Col>
        </Row>
      </CbModal>
    </Fragment>
  );
};

EditFirma.propTypes = {
  id: PropTypes.number.isRequired,
  agenteId: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  almacenId: PropTypes.string.isRequired,
  sucursalId: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  maximo_descuento: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  loadAgentes: PropTypes.func.isRequired,
  sucursales: PropTypes.array.isRequired,
  firma: PropTypes.object,
  almacenes: PropTypes.array.isRequired,
};

export default EditFirma;
