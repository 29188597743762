import { DivFormGroup, Input } from '@controls';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import Select from 'react-select';
import { getRequest, putRequest } from '@utils/api';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import AsyncSelect from 'react-select/async';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';

const Edit = ({ coloniaId, estados, load }) => {
  const { addNotification: notification } = useStoreNotification();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    D_mnpio: '',
    c_CP: '',
    c_cve_ciudad: '',
    c_estado: '',
    c_mnpio: '',
    c_oficina: '',
    c_tipo_asenta: '',
    d_CP: '',
    d_asenta: '',
    d_ciudad: '',
    d_codigo: '',
    d_estado: '',
    d_tipo_asenta: '',
    d_zona: '',
    id_asenta_cpcons: '',
    municipioObject: null,
  });
  const [isLoading] = useState(false);
  const [estado, setEstado] = useState(null);

  const close = () => {
    setShowModal(false);
    setFormData({
      D_mnpio: '',
      c_CP: '',
      c_cve_ciudad: '',
      c_estado: '',
      c_mnpio: '',
      c_oficina: '',
      c_tipo_asenta: '',
      d_CP: '',
      d_asenta: '',
      d_ciudad: '',
      d_codigo: '',
      d_estado: '',
      d_tipo_asenta: '',
      d_zona: '',
      id_asenta_cpcons: '',
      municipioObject: null,
    });
  };

  const open = async () => {
    const {
      data: {
        D_mnpio,
        c_CP,
        c_cve_ciudad,
        c_estado,
        c_mnpio,
        c_oficina,
        c_tipo_asenta,
        d_CP,
        d_asenta,
        d_ciudad,
        d_codigo,
        d_estado,
        d_tipo_asenta,
        d_zona,
        id_asenta_cpcons,
      },
    } = await getRequest({ url: `colonia/${coloniaId}` });
    setFormData({
      estados,
      estado: { value: c_estado, label: d_estado },
      showModal: true,
      D_mnpio,
      c_CP,
      c_cve_ciudad,
      c_estado: c_estado.toString(),
      d_estado,
      c_mnpio,
      c_oficina,
      c_tipo_asenta,
      d_CP,
      d_asenta: d_asenta.toUpperCase(),
      d_ciudad: d_ciudad.toUpperCase(),
      d_codigo,
      d_tipo_asenta: d_tipo_asenta.toUpperCase(),
      d_zona,
      id_asenta_cpcons,
      municipioObject: {
        label: D_mnpio.toUpperCase(),
        value: c_mnpio,
      },
    });
    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const {
      D_mnpio,
      c_CP,
      c_cve_ciudad,
      c_estado,
      c_mnpio,
      c_oficina,
      c_tipo_asenta,
      d_CP,
      d_asenta,
      d_ciudad,
      d_codigo,
      d_estado,
      d_tipo_asenta,
      d_zona,
      id_asenta_cpcons,
    } = formData;

    const data = {
      D_mnpio: D_mnpio.trim(),
      c_CP: c_CP,
      c_cve_ciudad: c_cve_ciudad,
      c_estado: c_estado,
      c_mnpio: c_mnpio,
      c_oficina: c_oficina,
      c_tipo_asenta: c_tipo_asenta,
      d_CP: d_CP,
      d_asenta: d_asenta.trim(),
      d_ciudad: d_ciudad.trim(),
      d_codigo: d_codigo,
      d_estado: d_estado.trim(),
      d_tipo_asenta: d_tipo_asenta.trim(),
      d_zona: d_zona.trim(),
      id_asenta_cpcons: id_asenta_cpcons,
    };

    if (validations(data)) {
      await putRequest({ url: `colonia/${coloniaId}`, body: data });
      load();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.c_estado + '')) {
      validate.success = false;
      validate.message = 'Estado es requerido.';
    } else if (!params.c_mnpio) {
      validate.success = false;
      validate.message = 'Municipio es requerido.';
    } else if (isEmpty(params.d_ciudad + '')) {
      validate.success = false;
      validate.message = 'Localidad es requerida.';
    } else if (isEmpty(params.d_asenta + '')) {
      validate.success = false;
      validate.message = 'Colonia es requerida.';
    } else if (isEmpty(params.d_codigo + '')) {
      validate.success = false;
      validate.message = 'Código postal es requerido.';
    }

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onChangeEstado = (value) => {
    setEstado(value);
    setFormData((prevState) => ({
      ...prevState,
      estado: value,
      c_estado: value.value,
      d_estado: value.label,
      D_mnpio: '',
      c_mnpio: 0,
      c_cve_ciudad: 0,
      d_asenta: '',
      d_ciudad: '',
      d_codigo: '',
    }));
  };

  const onChangeMunicipio = (itemSelect) => {
    if (itemSelect !== null) {
      setFormData((prevState) => ({
        ...prevState,
        D_mnpio: itemSelect.label,
        c_mnpio: itemSelect.value,
        municipioObject: itemSelect,
        c_cve_ciudad: 0,
        d_asenta: '',
        d_ciudad: '',
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        D_mnpio: '',
        municipioObject: null,
        c_mnpio: 0,
        c_cve_ciudad: 0,
        d_asenta: '',
        d_ciudad: '',
      }));
    }
  };

  const loadOptions = async (inputValue, callback) => {
    const { c_estado } = formData;
    if (c_estado !== '') {
      const { data } = await getRequest({ url: `municipios/autocomplete/${c_estado}?search=${inputValue}` });
      return callback(data);
    }
    return callback([]);
  };

  return (
    <Fragment>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' size='sm' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <Row>
          <Col sm='4'>
            <DivFormGroup name='pais' title='País'>
              <Input name='pais' value='MÉXICO' disabled={true} />
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='estado' title='Estado' required>
              <Select name='estado' value={estado} onChange={onChangeEstado} options={estados} />
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='municipio' title='Municipio' required>
              <AsyncSelect
                name='municipio'
                value={formData.municipioObject}
                isDisabled={formData.c_estado !== '' ? false : true}
                onChange={onChangeMunicipio}
                loadOptions={loadOptions}
                getOptionValue={(option) => option.clave}
                getOptionLabel={(option) => option.nombre}
                placeholder='Municipio'
              />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='4'>
            <DivFormGroup name='d_ciudad' title='Localidad' required>
              <Input
                name='d_ciudad'
                placeholder='Localidad'
                onChange={(e) => setFormData({ ...formData, d_ciudad: e.target.value })}
                value={formData.d_ciudad}
              />
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='d_asenta' title='Colonia' required>
              <Input
                name='d_asenta'
                placeholder='Colonia'
                onChange={(e) => setFormData({ ...formData, d_asenta: e.target.value })}
                value={formData.d_asenta}
              />
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='d_codigo' title='Código postal' required>
              <Input
                name='d_codigo'
                placeholder='Código postal'
                onChange={(e) => setFormData({ ...formData, d_codigo: e.target.value })}
                value={formData.d_codigo !== null ? formData.d_codigo.toString() : ''}
                maxLength={5}
              />
            </DivFormGroup>
          </Col>
        </Row>
      </CbModal>
    </Fragment>
  );
};

Edit.propTypes = {
  coloniaId: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  estados: PropTypes.array.isRequired,
};

export default Edit;
