import { DivFormGroup, FormInput } from '@controls';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';
import { Icon } from '@stateless';
import { postRequest } from '@utils/api';
import { hasPermission } from '@utils/permissions';
import { isEmpty } from 'validator';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { Col, Row } from 'react-bootstrap';
import { useStoreNotification } from '@stores/catalogs.store';
import InputNumber from '@components/forms/InputNumber';

const AsignarPeriodosVacaciones = (props) => {
  const { addNotification: notification } = useStoreNotification();
  const [state, setState] = useState({
    fecha_final: '',
    fecha_inicial: '',
    dias_solicitados: 0,
    dias_disponibles: 0,
    dias_correspondientes: 0,
    dias_adicionales: 0,
    rangos: [],
    valor: '',
    showModal: false,
    isLoading: false,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {};

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  const open = () => {
    const { dias_correspondientes, dias_ya_solicitados, rangos, dias_adicionales } = props;
    const dias_disponibles = dias_correspondientes - dias_ya_solicitados;
    setState({
      showModal: true,
      isLoading: false,
      fecha_inicial: moment().format('YYYY-MM-DD'),
      fecha_final: moment().add(dias_disponibles, 'days').format('YYYY-MM-DD'),
      dias_correspondientes,
      dias_adicionales: dias_adicionales || 0,
      dias_disponibles,
      dias_solicitados: dias_disponibles,
      rangos: rangos.map((x) => ({
        ...x,
        fecha_inicial: moment(x.fecha_inicial.substring(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD'),
        fecha_final: moment(x.fecha_final.substring(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      })),
    });
  };

  const obtenerSolicitados = () => {
    return state.rangos.map((x) => Number(x.dias_solicitados)).reduce((a, b) => a + b, 0);
  };

  const obtenerDisponibles = () => {
    const diferencia = Number(state.dias_correspondientes) + Number(state.dias_adicionales) - obtenerSolicitados();
    return diferencia < 0 ? 0 : diferencia;
  };

  const obtenerDisponiblesSinRedondeo = () => {
    return state.dias_correspondientes + Number(state.dias_adicionales) - obtenerSolicitados();
  };

  const onChange = (index, event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      rangos: prevState.rangos.map((x, i) => {
        if (i === index) return { ...x, [name]: value };
        return x;
      }),
    }));
  };

  const onChangeDiasAdicionales = (e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      dias_adicionales: value,
      dias_disponibles: Number(prevState.dias_correspondientes) + Number(value),
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const { rangos } = state;
    const { _id, anio, antiguedad } = props;
    const { dias_correspondientes, dias_adicionales } = state;
    const data = {
      empleadoId: _id,
      anio,
      antiguedad,
      dias_correspondientes,
      dias_adicionales,
      rangos,
    };

    if (validations(data)) {
      await postRequest({ url: 'empleados-vacaciones', body: data });
      close();
      props.load();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };
    params.rangos.forEach((rango) => {
      const diferencias = moment(rango.fecha_final).diff(moment(rango.fecha_inicial), 'days');
      if (isEmpty(rango.fecha_inicial + '')) {
        validate.success = false;
        validate.message = 'Fecha inicial es requerida.';
      } else if (isEmpty(rango.fecha_final + '')) {
        validate.success = false;
        validate.message = 'Fecha final es requerida.';
      } else if (diferencias < 1) {
        validate.success = false;
        validate.message = 'La fecha final es igual o menor a la fecha inicial.';
      } else if (isEmpty(rango.dias_solicitados + '')) {
        validate.success = false;
        validate.message = 'Días solicitados es requerido.';
      } else if (rango.dias_solicitados <= 0) {
        validate.success = false;
        validate.message = 'Días solicitados debe ser mayor a cero.';
      } else if (diferencias < rango.dias_solicitados) {
        validate.success = false;
        validate.message = 'Los días solicitados deben ser menor o igual los días entre los rangos.';
      }
    });

    if (!validate.success) {
      notification({ title: 'Información incompleta', message: validate.message, type: 'error' });
    }

    return validate.success;
  };

  const onClickEditar = (index) => {
    setState((prevState) => ({
      ...prevState,
      rangos: prevState.rangos.map((x, i) => {
        if (i === index) return { ...x, editando: true };
        return x;
      }),
    }));
  };

  const onClickRemover = (index) => {
    setState((prevState) => ({
      ...prevState,
      rangos: prevState.rangos.filter((x, i) => i !== index),
    }));
  };

  const onClickCancelar = (index) => {
    setState((prevState) => ({
      ...prevState,
      rangos: prevState.rangos.map((x, i) => {
        if (i === index) return { ...x, editando: false };
        return x;
      }),
    }));
  };

  const agregarRango = () => {
    setState((prevState) => ({
      ...prevState,
      rangos: [
        ...prevState.rangos,
        {
          fecha_solicitud: moment(),
          fecha_inicial: moment().format('YYYY-MM-DD'),
          fecha_final: moment().format('YYYY-MM-DD'),
          dias_solicitados: 0,
          editando: true,
          observaciones: '',
        },
      ],
    }));
  };

  const { showModal, isLoading, rangos } = state;
  return (
    <>
      <CbButtonOver onClick={open} icon='calendar' title='Desglosar' />
      <CbModal show={showModal} title='Agregar periodo de vacaciones' onClose={close} onSave={handleRegister} isLoading={isLoading}>
        <Row>
          <Col sm='4'>
            <DivFormGroup name='dias_correspondientes' title='Días correspondientes'>
              <InputNumber name='dias_correspondientes' value={state.dias_correspondientes} placeholder='Días correspondientes' disabled />
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='dias_adicionales' title='Días adeudados'>
              <InputNumber name='dias_adicionales' value={state.dias_adicionales} onChange={onChangeDiasAdicionales} />
            </DivFormGroup>
          </Col>
          <Col sm='4'>
            <DivFormGroup name='dias_disponibles' title='Días disponibles'>
              <InputNumber name='dias_disponibles' disabled value={obtenerDisponibles()} />
            </DivFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <ul className='list-group'>
              {rangos.map((x, i) => (
                <li className='list-group-item' key={i}>
                  {x.editando ? (
                    <Fragment>
                      <Row>
                        <Col sm='5'>
                          <FormInput
                            type='date'
                            title='Fecha inicial'
                            name='fecha_inicial'
                            required
                            onChange={(e) => onChange(i, e)}
                            value={x.fecha_inicial}
                          />
                        </Col>
                        <Col sm='5'>
                          <FormInput
                            type='date'
                            title='Fecha final'
                            name='fecha_final'
                            required
                            onChange={(e) => onChange(i, e)}
                            value={x.fecha_final}
                          />
                        </Col>
                        <Col sm='2'>
                          <a href='#' onClick={() => onClickRemover(i)} className='pull-right'>
                            <Icon icon='remove' style={{ color: 'red' }} />
                          </a>
                          <a href='#' onClick={() => onClickCancelar(i)} className='pull-right'>
                            <Icon icon='minus' />
                          </a>
                          <DivFormGroup name='dias_diferencia' title='Días'>
                            <InputNumber
                              name='dias_diferencia'
                              disabled
                              value={moment(x.fecha_inicial).local().diff(moment(x.fecha_final).local(), 'days')}
                              onChange={(e) => onChange(i, e)}
                            />
                          </DivFormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='12'>
                          <DivFormGroup name='dias_solicitados' title='Días a solicitar' required>
                            <InputNumber name='dias_solicitados' value={x.dias_solicitados} onChange={(e) => onChange(i, e)} />
                          </DivFormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='12'>
                          <FormInput title='Observaciones' name='observaciones' required onChange={(e) => onChange(i, e)} value={x.observaciones} />
                        </Col>
                      </Row>
                    </Fragment>
                  ) : (
                    <Row>
                      <Col sm='3'>
                        <b>Días solicitados: </b>
                        {x.dias_solicitados}
                      </Col>
                      <Col sm='3'>
                        <b>Fechas: </b> {moment(x.fecha_inicial).local().format('DD-MM-YYYY')} - {moment(x.fecha_final).local().format('DD-MM-YYYY')}
                      </Col>
                      <Col sm='3'>
                        <b>Observaciones: </b> {x.observaciones}
                      </Col>
                      <Col sm='2'>
                        <a href='#' disabled={!hasPermission('eliminar-periodos')} onClick={() => onClickRemover(i)} className='pull-right'>
                          <Icon icon='remove' style={{ color: 'red' }} />
                        </a>
                        <a href='#' disabled={!hasPermission('modificar-periodos')} onClick={() => onClickEditar(i)} className='pull-right'>
                          <Icon icon='edit' />
                        </a>
                      </Col>
                    </Row>
                  )}
                </li>
              ))}
              {obtenerDisponibles() > 0 && hasPermission('agregar-periodo') ? (
                <li className='list-group-item text-center'>
                  <a href='#' onClick={agregarRango}>
                    <Icon icon='plus' /> Agregar periodo
                  </a>
                </li>
              ) : null}
            </ul>
            {obtenerDisponiblesSinRedondeo() < 0 ? (
              <div className='alert alert-danger' role='alert'>
                La suma de los días solicitados es mayor a los días correspondientes
              </div>
            ) : null}
          </Col>
        </Row>
      </CbModal>
    </>
  );
};

AsignarPeriodosVacaciones.propTypes = {
  _id: PropTypes.number.isRequired,
  dias_correspondientes: PropTypes.number.isRequired,
  dias_adicionales: PropTypes.number.isRequired,
  dias_tomados: PropTypes.number.isRequired,
  dias_ya_solicitados: PropTypes.number.isRequired,
  rangos: PropTypes.array.isRequired,
  anio: PropTypes.number.isRequired,
  antiguedad: PropTypes.number.isRequired,
  load: PropTypes.func.isRequired,
};

export default AsignarPeriodosVacaciones;
