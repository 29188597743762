import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { putRequest } from '@utils/api';
import { isEmpty, isMongoId } from 'validator';
import FormRuletaAsesores from './FormRuletaAsesores';
import CbButtonOver from '@cbcomponents/CbButtonOver';
import CbModal from '@cbcomponents/CbModal';
import { socketManager } from '../../../../socketManager';
import { useStoreNotification } from '@stores/catalogs.store';

const EditarAsesor = (props) => {
  const { addNotification: notification } = useStoreNotification();
  const [showModal, setShowModal] = useState(false);
  const [isLoading] = useState(false);
  const [formData, setFormData] = useState({
    nombre: '',
    tipo: '',
    giro: '',
    fecha: moment().format('YYYY-MM-DD'),
    fecha_visita: moment().format('YYYY-MM-DD'),
    asesor_asignado: null,
    comparte_comision_con: null,
    soluciones_solicitadas_id: [],
    telefono: '',
    comentarios: '',
    correo_electronico: '',
    asesor_atendio: null,
    medio_comunicacion: null,
  });

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    const {
      nombre,
      tipo,
      fecha,
      fecha_visita,
      asesor_asignado,
      comparte_comision_con,
      soluciones_solicitadas_id,
      telefono,
      correo_electronico,
      asesor_atendio,
      medio_comunicacion,
      comentarios,
      giro,
    } = props;

    setFormData({
      showModal: true,
      nombre,
      tipo: tipo === 'C' ? { value: 'C', label: 'Cliente' } : { value: 'P', label: 'Prospecto' },
      fecha: moment(fecha).format('YYYY-MM-DD'),
      fecha_visita: moment(fecha_visita).format('YYYY-MM-DD'),
      asesor_asignado: asesor_asignado
        ? {
            value: asesor_asignado._id,
            label: asesor_asignado.nombre,
          }
        : null,
      comparte_comision_con: comparte_comision_con
        ? {
            value: comparte_comision_con._id,
            label: comparte_comision_con.nombre,
          }
        : null,
      soluciones_solicitadas_id: soluciones_solicitadas_id.map((solucion) => ({
        value: solucion._id,
        label: solucion.definicion,
      })),
      telefono,
      comentarios,
      giro,
      correo_electronico,
      asesor_atendio: asesor_atendio
        ? {
            value: asesor_atendio._id,
            label: asesor_atendio.nombre,
          }
        : null,
      medio_comunicacion: medio_comunicacion
        ? {
            value: medio_comunicacion._id,
            label: medio_comunicacion.nombre,
          }
        : null,
    });

    setShowModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const { ruletaId, loadRuleta } = props;
    const {
      nombre,
      tipo,
      fecha,
      fecha_visita,
      asesor_asignado,
      soluciones_solicitadas_id,
      telefono,
      correo_electronico,
      asesor_atendio,
      comparte_comision_con,
      medio_comunicacion,
      comentarios,
      producto_interes,
      giro,
    } = formData;

    const data = {
      nombre,
      tipo: tipo ? tipo.value : '',
      medio_comunicacion: medio_comunicacion ? medio_comunicacion.value : null,
      telefono,
      correo_electronico,
      comentarios,
      giro,
      producto_interes,
      soluciones_solicitadas: soluciones_solicitadas_id.map((x) => x.value),
      asesor_asignado: asesor_asignado ? asesor_asignado.value : null,
      comparte_comision_con: comparte_comision_con ? comparte_comision_con.value : null,
      asesor_atendio: asesor_atendio ? asesor_atendio.value : null,
      fecha: fecha ? moment(fecha, 'YYYY-MM-DD').utc() : '',
      fecha_visita: fecha_visita ? moment(fecha_visita, 'YYYY-MM-DD').utc() : null,
    };

    if (validations(data)) {
      const { notificacion } = await putRequest({
        url: `ruleta-asesores/${ruletaId}`,
        body: data,
      });
      if (notificacion) {
        socketManager.emit('NOTIFICATION', notificacion);
      }
      loadRuleta();
      close();
    }
  };

  const validations = (params) => {
    let validate = {
      success: true,
      message: '',
    };

    if (isEmpty(params.nombre + '')) {
      validate.success = false;
      validate.message = 'Nombre es requerido.';
    } else if (isEmpty(params.tipo + '')) {
      validate.success = false;
      validate.message = 'Tipo cliente es requerido.';
    } else if (isEmpty(params.soluciones_solicitadas + '')) {
      validate.success = false;
      validate.message = 'Soluciones solicitadas es requerido.';
    } else if (!isMongoId(params.asesor_atendio + '')) {
      validate.success = false;
      validate.message = 'Asesor atendió es requerido.';
    } else if (isEmpty(params.fecha + '')) {
      validate.success = false;
      validate.message = 'Fecha es requerido.';
    }

    if (!validate.success) {
      notification({
        title: 'Información incompleta',
        message: validate.message,
        type: 'error',
      });
    }

    return validate.success;
  };

  return (
    <>
      <CbButtonOver onClick={open} icon='pencil' title='Editar' />
      <CbModal show={showModal} onClose={close} onSave={handleEdit} title='Editar' isLoading={isLoading}>
        <FormRuletaAsesores {...props} {...formData} setState={setFormData} />
      </CbModal>
    </>
  );
};

EditarAsesor.propTypes = {
  id: PropTypes.number,
  loadRuleta: PropTypes.func.isRequired,
  ruletaId: PropTypes.string.isRequired,
  tipo: PropTypes.string,
  comentarios: PropTypes.string,
  giro: PropTypes.string,
  producto_interes: PropTypes.string,
  nombre: PropTypes.string,
  agentes: PropTypes.array,
  asesor_asignado: PropTypes.array,
  comparte_comision_con: PropTypes.array,
  soluciones_solicitadas_id: PropTypes.array,
  soluciones_solicitadas: PropTypes.array,
  medios_comunicaciones: PropTypes.array,
  tipos: PropTypes.array,
  telefono: PropTypes.string,
  fecha: PropTypes.string,
  fecha_visita: PropTypes.string,
  correo_electronico: PropTypes.string,
  asesor_atendio: PropTypes.string,
  medio_comunicacion: PropTypes.string,
};

export default EditarAsesor;
