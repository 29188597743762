import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@stateless';
import ItemBuscadorProducto from './ItemBuscadorProducto';
import RowBuscadorProducto from './RowBuscadorProducto';
import CbPanelTableResponsive from '@controls/CbPanelTableResponsive';
import CbTableBody from '@controls/CbTableBody';
import CbPagination from '@controls/CbPagination';
import { Row } from 'react-bootstrap';

const EquiposBuscadorProducto = ({
  equipos,
  loading,
  inList,
  monedaId,
  tipoCambio,
  onClickEquipo,
  activePage,
  count,
  pagination,
  handlePagination,
}) => {
  if (loading) return <Loading />;
  if (!inList) {
    return (
      <Row>
        {equipos.map((x) => (
          <ItemBuscadorProducto key={x._id} x={x} onClickEquipo={onClickEquipo} monedaId={monedaId} tipoCambio={tipoCambio} />
        ))}
        {equipos.length > 0 && (
          <div style={{ textAlign: 'center' }}>
            <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
          </div>
        )}
      </Row>
    );
  }
  return (
    <>
      <CbPanelTableResponsive>
        <thead>
          <tr>
            <th width='5%'>Codigo</th>
            <th>Descripción</th>
            <th width='5%'>Unidad</th>
            <th width='5%'>Linea</th>
            <th width='5%'>Marca</th>
            <th width='10%' className='text-right'>
              Precio
            </th>
          </tr>
        </thead>
        <CbTableBody colSpan={6}>
          {equipos.map((x) => (
            <RowBuscadorProducto key={x._id} x={x} onClickEquipo={onClickEquipo} monedaId={monedaId} tipoCambio={tipoCambio} />
          ))}
        </CbTableBody>
      </CbPanelTableResponsive>
      {equipos.length > 0 && (
        <div style={{ textAlign: 'center' }}>
          <CbPagination activePage={activePage} count={count} pagination={pagination} onSelect={handlePagination} />
        </div>
      )}
    </>
  );
};

EquiposBuscadorProducto.propTypes = {
  equipos: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  inList: PropTypes.bool.isRequired,
  monedaId: PropTypes.number.isRequired,
  tipoCambio: PropTypes.number.isRequired,
  onClickEquipo: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
};

export default EquiposBuscadorProducto;
