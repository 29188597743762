import IfPermission from '@controls/IfPermission';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';
import DeleteProviders from './DeleteProviders';
import EditProviders from './EditProviders';
import { ButtonGroup } from 'react-bootstrap';

const ListProviders = ({ catalogProviders }) => {
  const renderRows = () => {
    return catalogProviders.map((row, item) => {
      const { _id, name, rfc, address, contact, clasificacionProveedorId, nombre_comercial, code } = row;

      let dataAddress = null;

      if (typeof address === 'object') {
        dataAddress = (
          <div>
            <small>
              {address.street ? address.street : ''}
              {address.numberExt ? ` ext. ${address.numberExt}` : ''}
              {address.numberInt ? ` int. ${address.numberInt}` : ''}
              {address.colony ? `, Colonia ${address.colony}` : ''}
              {address.postalCode ? `, CP. ${address.postalCode}` : ''} {address.city} {address.state}
            </small>
          </div>
        );
      }

      let dataContact = null;

      if (typeof contact === 'object') {
        dataContact = (
          <div>
            <small>
              {contact.name ? (
                <span>
                  <Icon icon='user' /> {contact.name}
                </span>
              ) : null}
              {contact.phone ? (
                <span>
                  <br />
                  <a title='Correo electrónico' href={`mailto:${contact.phone}`}>
                    {contact.phone}
                  </a>{' '}
                </span>
              ) : null}
              {contact.cellular ? (
                <span>
                  ,{' '}
                  <a title='Correo electrónico' href={`mailto:${contact.cellular}`}>
                    {contact.cellular}
                  </a>{' '}
                </span>
              ) : null}
              {contact.email ? (
                <span>
                  <br />
                  <Icon icon='envelope' />{' '}
                  <a title='Correo electrónico' href={`mailto:${contact.email}`}>
                    {contact.email}
                  </a>
                </span>
              ) : null}
            </small>
          </div>
        );
      }

      let clasificacion = '';
      if (typeof clasificacionProveedorId !== 'undefined') {
        if (clasificacionProveedorId !== null) {
          clasificacion = clasificacionProveedorId.nombre;
        }
      }
      return (
        <tr key={item}>
          <td>{code}</td>
          <td>
            <strong>{name}</strong> <span style={{ fontSize: '10px' }}>{nombre_comercial}</span>
            {rfc ? (
              <span>
                <br />
                RFC: {rfc}
              </span>
            ) : null}
          </td>
          <td>{clasificacion}</td>
          <td>{dataAddress}</td>
          <td className='with-actions'>{dataContact}</td>
          <td>
            <ButtonGroup>
              <IfPermission action='editar'>
                <EditProviders id={item} providerId={_id} />
              </IfPermission>
              <IfPermission action='eliminar'>
                <DeleteProviders id={item} providerId={_id} name={name} />
              </IfPermission>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  let rows = catalogProviders.length > 0 ? renderRows(catalogProviders) : <RowsNotFound message='Aún no hay proveedores registrados.' colSpan={6} />;

  return (
    <div className='panel panel-default panel-table table-responsive'>
      <table className='table table-condensed table-hover table-striped table-with-row-buttons'>
        <thead>
          <tr>
            <th width='10%'>Código</th>
            <th width='30%'>Proveedor</th>
            <th width='10%'>Clasificación</th>
            <th width='20%'>Domicilio</th>
            <th width='20%'>Contacto</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

ListProviders.propTypes = {
  catalogProviders: PropTypes.array.isRequired,
  usosCfdis: PropTypes.array.isRequired,
};

export default ListProviders;
