import { Confirmation } from '@controls';
import PropTypes from 'prop-types';
import React from 'react';

const LogoutCarroJuan = ({ userId, loadSellers }) => {
  return (
    <Confirmation
      btnIcon='trash'
      typeRequest='deleteRequest'
      btnType='outline-danger'
      action='Liberar sesión'
      url={`sellers-logout/${userId}`}
      description={`¿Confirma que desea liberar la sesión?`}
      loadData={loadSellers}
    />
  );
};

LogoutCarroJuan.propTypes = {
  userId: PropTypes.string.isRequired,
  loadSellers: PropTypes.func.isRequired,
};

export default LogoutCarroJuan;
