import PropTypes from 'prop-types';
import React from 'react';

const CbBadge = ({ type, children, style }) => {
  return (
    <span className={`badge badge-phoenix badge-phoenix-${type}`} style={{ fontSize: '10px', ...style }}>
      {children}
    </span>
  );
};

CbBadge.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default CbBadge;
