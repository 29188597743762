import React, { useEffect } from 'react';
import Select from 'react-select';
import { useStoreUbicaciones } from '@stores/catalogs.store';
import PropTypes from 'prop-types';

const SelectUbicacion = ({ onChange, name, value, disabled, filter }) => {
  const { data, execute } = useStoreUbicaciones();
  useEffect(() => {
    execute();
  }, []);
  const onSelect = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <Select
      value={value}
      name={name}
      placeholder='Seleccione'
      options={data.filter((x) => (filter ? filter(x) : true))}
      isDisabled={disabled}
      onChange={(selectedOption) => onSelect(selectedOption)}
      className='from-control basic-multi-select'
      getOptionLabel={(x) => x.name}
      getOptionValue={(x) => x._id}
      classNamePrefix='select'
    />
  );
};

SelectUbicacion.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  filter: PropTypes.func,
};

export default SelectUbicacion;
