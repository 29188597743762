import { platforms } from '@config/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from '@utils/parseDates';
import CbPanelTableResponsive from '@cbcomponents/CbPanelTableResponsive';
import CbTableBody from '@cbcomponents/CbTableBody';

const ListLogs = ({ logs }) => {
  const switchPlatform = (platform) => {
    let icon = 'error.png';
    if (platform === platforms.BACKEND || platform === platforms.SPECIAL) {
      icon = 'backend.png';
    } else if (platform === platforms.ANDROID) {
      icon = 'android.png';
    } else if (platform === platforms.IOS) {
      icon = 'ios.png';
    }
    return icon;
  };

  const switchMethod = (method) => {
    let color = null;

    if (method === 'GET') {
      color = 'success';
    } else if (method === 'POST') {
      color = 'primary';
    } else if (method === 'PUT') {
      color = 'warning';
    } else if (method === 'DELETE') {
      color = 'danger';
    } else {
      color = 'info';
    }

    return color;
  };

  const switchCodeResponse = (method) => {
    let color = null;
    if (method === '100') {
      color = 'success';
    } else if (method === '500') {
      color = 'danger';
    } else {
      color = 'info';
    }
    return color;
  };

  const renderRows = (logs) => {
    return logs.map((log, item) => {
      const icon = switchPlatform(log.platform);
      const url = log.url.split('?');
      const styleMethod = switchMethod(log.method);
      const date = formatDate(log.created);

      let styleRow = '';

      if (log.responseCode === 401) {
        styleRow = 'danger';
      }

      return (
        <tr key={item} className={styleRow}>
          <td className='text-center'>
            <img src={`/img/${icon}`} width='40px' />
          </td>
          <td>
            <pre>{url[0]}</pre>
          </td>
          <td>
            <label className={`label label-${styleMethod} mr-5`}>{log.method}</label>
            <strong>
              <label className={`label label-${switchCodeResponse(log.responseCode)}`}>{log.responseCode}</label>
            </strong>{' '}
            {log.messageError}
          </td>
          <td>
            <small>{log.body}</small>
          </td>
          <td>
            <small>{log.params}</small>
          </td>
          <td>{log.ip}</td>
          <td>
            <span className='pull-right'>{date}</span>
          </td>
        </tr>
      );
    });
  };

  const rows = renderRows(logs);
  return (
    <CbPanelTableResponsive>
      <thead>
        <tr>
          <th width='5%' className='text-center'>
            &nbsp;
          </th>
          <th width='10%'>URL</th>
          <th width='10%'>METODO</th>
          <th width='10%'>BODY</th>
          <th width='10%'>PARAMS</th>
          <th width='10%'>IP</th>
          <th width='10%'>
            <span className='pull-right'>FECHA</span>
          </th>
        </tr>
      </thead>
      <CbTableBody colSpan={7}>{rows}</CbTableBody>
    </CbPanelTableResponsive>
  );
};

ListLogs.propTypes = {
  logs: PropTypes.array.isRequired,
};

export default ListLogs;
