import Panel from '@controls/Panel';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, RowsNotFound } from '@stateless';

import RowQuotation from './RowQuotation';
import { Col } from 'react-bootstrap';

const TableQuotations = ({ customer: { _id, listQuotations } }) => {
  const renderTableQuotations = () => {
    const rows = listQuotations.map((q, i) => <RowQuotation key={i} customerId={_id} quotation={q} />);

    return (
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th width='20%'>#</th>
            <th width='20%'>Vendedor</th>
            <th width='20%'>Fecha</th>
            <th width='20%'>Total</th>
            <th width='20%'></th>
          </tr>
        </thead>
        <tbody>{listQuotations.length > 0 ? rows : <RowsNotFound colSpan={5} />}</tbody>
      </table>
    );
  };

  const tableQuotations = renderTableQuotations();

  return (
    <Col sm='12'>
      <Panel
        type='primary'
        header={
          <div>
            <Icon icon='check' /> Cotizaciones
          </div>
        }
      >
        {tableQuotations}
      </Panel>
    </Col>
  );
};

TableQuotations.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default TableQuotations;
